import api from "../../../service/api";
import API_BOOK from "../../../service/endpoints";
import ActionTypes from "../../actionTypes";
import { getFormattedDate, fileDownloader } from "../../../utils/common-methods";
const getAllGEoFenceList = ActionTypes.GEO_FENCE;
const getGeoFanceApiConfig = API_BOOK.GEO_FENCE.DASHBOARD_GEO_FENCE;

const getGeoFanceType = (payload) => ({
  type: getAllGEoFenceList.GET_ALL_GEO_FANCE_SUCCESS,
  payload,
});
const getGeoFanceLabel = (payload) => ({
  type: getAllGEoFenceList.GET_GEO_FANCE_LABEL_SUCCESS,
  payload,
});
const getGeoFanceLabelOwner = (payload) => ({
  type: getAllGEoFenceList.GET_GEO_FANCE_LABEL_OWNER_SUCCESS,
  payload,
});
const getGeoFanceCity = (payload) => ({
  type: getAllGEoFenceList.GET_GEO_FANCE_CITY_SUCCESS,
  payload,
});
const getFenceVehicleByFenceId = (payload) => ({
  type: getAllGEoFenceList.GEO_FENCE_VEHICLE_BY_FENCE_ID_SUCCESS,
  payload,
});
const imageUpload = (payload) => ({
  type: getAllGEoFenceList.GEO_FENCE_IMAGE_UPLOAD_SUCCESS,
  payload,
});

export const ToggleSelectGeoFenceCard = (payload) => ({
  type: getAllGEoFenceList.TOGGLE_SELECT_GEO_FENCE_CARD,
  payload,
});

/**
 *  @desc         Get Geofence Type
 *  @author       Bhavin Patel
 *  @api          /geo-fence/get-all-fences
 *  @method       POST
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/

export const GetGeoFanceType = ({data}) => async (dispatch) => {
  try {
    const res = await api({
      url: getGeoFanceApiConfig.GET_ALL_GEO_FENCE.url,
      method: getGeoFanceApiConfig.GET_ALL_GEO_FENCE.method,
      data: data,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getGeoFanceType(response));
    }
  } catch (error) {
    console.log("Error getting ", error);
  }
};

/**
 *  @desc         Get Geofence label
 *  @author       Bhavin Patel
 *  @api          /geo-fence/configuration/labels
 *  @method       GET
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/
export const GetGeoFanceLabel = () => async (dispatch) => {
  try {
    const res = await api({
      url: getGeoFanceApiConfig.GET_GEO_FENCE_LABEL.url,
      method: getGeoFanceApiConfig.GET_GEO_FENCE_LABEL.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getGeoFanceLabel(response));
    }
  } catch (error) {
    console.log("Error getting ", error);
  }
};

/**
 *  @desc         Get Geofence label owner
 *  @author       Bhavin Patel
 *  @api          /geo-fence/configuration/label-owners
 *  @method       GET
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/
export const GetGeoFanceLabelOwner = () => async (dispatch) => {
  try {
    const res = await api({
      url: getGeoFanceApiConfig.GET_GEO_FENCE_LABEL_OWNER.url,
      method: getGeoFanceApiConfig.GET_GEO_FENCE_LABEL_OWNER.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getGeoFanceLabelOwner(response));
    }
  } catch (error) {
    console.log("Error getting ", error);
  }
};

/**
 *  @desc         Get Geofence city
 *  @author       Bhavin Patel
 *  @api          /geo-fence/get-distinct-cities
 *  @method       GET
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/
export const GetGeoFanceCity = () => async (dispatch) => {
  try {
    const res = await api({
      url: getGeoFanceApiConfig.GET_GEO_FENCE_CITY.url,
      method: getGeoFanceApiConfig.GET_GEO_FENCE_CITY.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getGeoFanceCity(response));
    }
  } catch (error) {
    console.log("Error getting ", error);
  }
};

/**
 *  @desc         Get Geofence fence vehicles by fenceId
 *  @author       Bhavin Patel
 *  @api          /geo-fence/operation/vehicle-by-fence-id
 *  @method       POST
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/

export const GetFenceVehicleByFenceId =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await api({
        method: getGeoFanceApiConfig.GEO_FENCE_VEHICLE_BY_FENCE_ID.method,
        url: getGeoFanceApiConfig.GEO_FENCE_VEHICLE_BY_FENCE_ID.url,
        data: data,
      });

      if (resp.status === 200) {
        const response = resp?.data?.data;
        dispatch(getFenceVehicleByFenceId(response));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
 *  @desc         image upload for geofence
 *  @author       Bhavin Patel
 *  @api          /geo-fence/image-upload/:_id
 *  @method       POST
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/

export const ImageUpload =
  ({ data, _id }) =>
  async (dispatch) => {
    try {
      const resp = await api({
        method: getGeoFanceApiConfig.IMAGE_UPLOAD.method,
        url: getGeoFanceApiConfig.IMAGE_UPLOAD.url.replace(":_id", _id),
        data: data,
      });

      if (resp.status === 200) {
        const response = resp?.data?.data;
        dispatch(imageUpload(response));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
 *  @desc         Get Geofence Vehicle exports
 *  @author       Bhavin Patel
 *  @api          /geo-fence/operation/ingress-vehicle-export
 *  @method       POST
 *  @createdDate  30-AUG-2023
 *  @modifiedDate 30-AUG-2023
 **/

export const GeofenceVehicleExport =
  ({ data }) =>
  async () => {
    try {
      const resp = await api({
        method: getGeoFanceApiConfig.EXPORT_GEO_FENCE_VEHICLES.method,
        url: getGeoFanceApiConfig.EXPORT_GEO_FENCE_VEHICLES.url,
        responseType: "arraybuffer",
        data: data,
        timezoneOffset: new Date().getTimezoneOffset()
      });

      if (resp.status === 200) {
        const response = resp?.data
        fileDownloader({ arrayBuffer: response, fileName: `geofence_vehicle${getFormattedDate(new Date()).replace(/,/g, '' ).replace(':', ' ').split(' ').join('_')}.xlsx` })
      }
    } catch (error) {
      console.log("error", error);
    }
  };
