// External packages import
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Internal components import
import BaseCard from '../../../../shared/cards/BaseCard/BaseCard';
import Popover from '../../../../shared/modals/Popover/Popover'

// Style import
import styles from './AccessTemplateListCard.module.css'

const AccessTemplateListCard = (props) => {
    const {
        templateId,
        templateName,
        modulesList,
        onClick,
        isActive,
        classnames = {},
    } = props

    return (
        <BaseCard
            className={cx(styles.container, {[styles.containerActive]: isActive, [classnames.container]: classnames.container})}
            onClick={onClick}
            variant='secondary'
        >
            <p className={styles.heading}>{templateName}</p>

            <div className={styles.moduleList}>
                {modulesList.map(module => (
                    <p
                        key={`${templateId}_module_${module.moduleId}`}
                        className={styles.moduleListItem}
                    >
                        <span>{module.module}</span>
                        {!!module.subModules.length && (
                            <Popover
                                trigger='hover'
                                triggerComponent={
                                    <button className={styles.popoverIcon}>
                                        <img src='/images/tooltipInfo.svg' alt='info' />
                                    </button>
                                }
                                bodyComponent={
                                    <div className={styles.popoverModules}>
                                        {module.subModules.map(submodule => (
                                            <p key={`${templateId}_module_${submodule.value}`}>{submodule.label}</p>
                                        ))}
                                    </div>
                                }
                            />
                        )}
                    </p>
                ))}
            </div>
        </BaseCard>
    )
}

AccessTemplateListCard.defaultProps = {
    onClick: () => {},
    isActive: false,
    classnames: {
        container: ''
    }
}

AccessTemplateListCard.propTypes = {
    templateId: PropTypes.string.isRequired,
    templateName: PropTypes.string.isRequired,
    modulesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    classnames: PropTypes.shape({
        container: PropTypes.string,
    })
}

export default AccessTemplateListCard;