import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { BsChevronDown } from 'react-icons/bs';

import "./ra-actions.css";
import { declineReason } from '../../../utils/constants';

const DeclineRARequisition = (props) => {
    const { declineFormData, declineFormDataError, onDeclineChangeHandler, onSubmit } = props
    const handleOnChange = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const { name, value } = e.target
        onDeclineChangeHandler({ name, value })
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='decline-ra-modal'
        >
            <Modal.Header className='modal-header' closeButton>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div id="decline-requisition-form" className="form-label-group required mb-3 mt-3">
                    <label
                        htmlFor="remarks"
                        className="formLabel ml-5"
                    >
                        Remarks
                    </label>
                    <div className="dropdown">
                        <select
                            name="remarks"
                            className={`form-control inputfields ${declineFormDataError.remarks ? 'is-invalid' : ''}`}
                            value={declineFormData.remarks}
                            onChange={handleOnChange}
                        >
                            <option value="">Select Remarks</option>
                            {declineReason?.map((option, index) => (
                                <option
                                    key={`option_${index}`}
                                    value={option.label}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <div className="dropdown-arrow">
                            <BsChevronDown />
                        </div>
                    </div>
                    {declineFormDataError.remarks && (
                        <div className="text-danger error-message">
                            {declineFormDataError.remarks}
                        </div>
                    )}
                </div>
                {declineFormData.remarks === "Other" && (
                    <div className='mb-3'>
                        <label
                            htmlFor="comments"
                            className="formLabel ml-5"
                        >
                            Comments
                        </label>
                        <textarea
                            type="text"
                            name="comments"
                            className={`form-control textarea-height ${declineFormDataError.comments ? 'is-invalid' : ''}`}
                            value={declineFormData.comments}
                            onChange={handleOnChange}
                        />
                        {declineFormDataError.comments && (
                            <div className="text-danger error-message">
                                {declineFormDataError.comments}
                            </div>
                        )}
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <div className='d-flex justify-content-center align-items-center'>
                    <button
                        type="button"
                        className="approve-btn btn-sm"
                        onClick={onSubmit}
                    >Submit</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default DeclineRARequisition