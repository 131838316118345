import React, {useCallback, useState} from 'react'
import { Modal } from "react-bootstrap";
import '../../Geo-fencing/geoFencDashboard/child-geofence/imageUploadModel/imageUploadModel.css'
import ImageUploadProgressBar from '../../Geo-fencing/geoFencDashboard/child-geofence/imageUploadProgressBar/imageUploadProgressBar';
import ImageDrawer from '../../Geo-fencing/geoFencDashboard/child-geofence/imageDrawer/imageDrawer'; 
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { customToast } from '../../../utils/toasts'; 

const UploadUserImageModal = ({isModalOpen, setIsModalOpen, setImageUrl}) => {
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
        
	
    //redux hook 
    const userId = JSON.parse(localStorage.getItem('user'))?._id  
    const dispatch = useDispatch()

    const handleClose = () => {
        setIsModalOpen(false)
    }

    const onSaveHandler = useCallback(async() => {
        if (file) {
          if (file.size <= 1 * 1024 * 1024) {
            let formData = new FormData();
            formData.append("file", file);
            try {
              const res = await axios({
                method:"POST",
                url:`user/image-upload/${userId}`,
                headers:{Authorization: `Bearer ${localStorage.token}`},
                data: formData
              })
              if(res) {
                localStorage.setItem('profileImage', res?.data?.data || '')
                dispatch({type: 'profileImage', payload:res?.data?.data })
                setImageUrl(res.data.data)
                setTimeout(() => {
                  customToast({ message: res?.data?.message });
                }, 400);
                handleClose();
              }
            } catch (error) {
              setTimeout(() => {
                customToast({ message: 'Please select files with .png, .jpg, .JPEG, .PNG only!' });
              }, 500);
              console.log('onSaveHandler error', error)
            }
          } else {
            // File size exceeds 2 MB, show an error message
            setErrorMessage(
              "File size exceeds 1 MB. Please upload a smaller file."
            );
          }
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [file, handleClose]);

    
    return(
        <Modal
        show={isModalOpen}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Upload photos</Modal.Title>
          <div className="d-flex justify-content-center">
            <ImageDrawer setFile={(file) => { setFile(file); setErrorMessage(null); }} data-testid='upload-photo-drawer' />
          </div>
          {file && <ImageUploadProgressBar setFile={setFile} file={file} data-testid='upload-photo-progress' />}
          {errorMessage && <div className="error-message-geofence-image-upload ml-5">{errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center flex-row  ">
            <button
              onClick={() => {
                onSaveHandler();
              }}
              type="submit"
              className="modal-btn save-btn-geofence "
              data-testid='upload-photo-save-button'
            >
              Save
            </button>
            <button
              onClick={() => {
                handleClose();
              }}
              className="modal-btn cancel-btn-geofence"
              data-testid='upload-photo-cancel-button'
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
}

export default UploadUserImageModal