import React from "react";
import { connect } from "react-redux";
import { ImLocation } from "react-icons/im";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import "./dashboardCard.css";
import VehiclesDetails from "../../../geoFencDashboard/child-geofence/vehicleDetailsModel/vehicleDetailsModel";
import { GetFenceVehicleByFenceId } from "../../../../../store/actions/geo-fence/dashboardGeoFence";

function DashBoardCard({
  DetailsCardData,
  onCardClick,
  toggleVehicleDetialModal
}) {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <div className="row mt-1 mb-1 ">
      <div className="col-12  container-fluid">
        <div className=" col-10 pt-1 pb-1 geofence-card">
          <div className="card-body-details-geofence mt-2" onClick={(e) => {
            onCardClick(e,DetailsCardData)
            }}
            data-testid={`fence-card-${DetailsCardData?.fence_name}`}
          >
            <div className="d-flex justify-content-end text-style-geofence-icon">
              <ImLocation />
              <div className="text-style-geofence-location">{DetailsCardData?.fence_identifiers.city}</div>
            </div>
            <Row className="geofence-row">
              <Col xs={5}>
                <label htmlFor="name" className="text-style-geofence">
                  Fence Name
                </label>
              </Col>
              <Col xs={1}>:</Col>
              <Col xs={6} className="text-style-geofence-value">
                {DetailsCardData?.fence_name}
              </Col>
            </Row>

            <Row>
              <Col xs={5}>
                <label htmlFor="name" className="text-style-geofence">
                  Label
                </label>
              </Col>
              <Col xs={1}>:</Col>
              <Col xs={6} className="text-style-geofence-value">
                {DetailsCardData.populated_label?.label}
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <label htmlFor="name" className="text-style-geofence">
                  Fence Owner
                </label>
              </Col>
              <Col xs={1}>:</Col>
              <Col xs={6} className="text-style-geofence-value">
                {DetailsCardData.populated_fence_owner?.label}
              </Col>
            </Row>
            <Row>
              <Col xs={5} >
                <label htmlFor="name" className="text-style-geofence">
                  Vehicle Count
                </label>
              </Col>
              <Col xs={1}>:</Col>
              <Col xs={6} className="d-flex text-style-geofence-i-icon">
                {DetailsCardData?.vehicleCount}
                <div
                  className="i-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleVehicleDetialModal(e,DetailsCardData )
                  }}
                  date-testid={`fence-card-info-${DetailsCardData?.fence_name}`}
                >
                  <img src='/images/svgicon/info.svg' alt='info'  />
                
                </div>
                <div>
                  {isShowModal && (
                    <VehiclesDetails
                      fenceId={DetailsCardData._id}
                      fenceNameData={DetailsCardData?.fence_name}
                      showMoal={isShowModal}
                      setIsShowModal={setIsShowModal}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fenceVehicleByFenceIdData:state.dashBoardGeoFance.fenceVehicleByFenceIdData,
  fenceVehicleCount:state.dashBoardGeoFance.fenceVehicleCount,
});

export default connect(mapStateToProps, {
  GetFenceVehicleByFenceId,
})(DashBoardCard);
