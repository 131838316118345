import React, {useState, useEffect} from 'react'
import { DateRangePicker } from 'rsuite'
import './DatePicker.css'


export default function DatePicker({
  setNoDataWarning = false,
  onDateChange,
  allowedMaxDaysInSelection = 7,
  disbaleFutureDate = false,
  isDateSelected = false,
  modelPlacement='bottomStart',
  clearDateSelectionOnToggle = false,
  setCalendarDate = [],   // [ startDate, endDate ]
  placeholderDefaultText = 'Start Date & Time - End Date & Time',
  placeholderDefaultTextColor = '#1F2A5D',
  paddingLeft = "25px",
  startDate,
  endDate,
  isShowOneCalendar = false,
  dateFormat = "dd-MMM-yyyy, HH:mm",
  isTodayDisable = false, 
  disableHours = 0,
  ...props
}) {
  const baseTestId = props['data-testid'] || 'datepicker'
  const [selectedValue, setSelectedValue] = useState([])
  const { after, combine, allowedMaxDays, afterToday } = DateRangePicker
  useEffect(() => {
    if (clearDateSelectionOnToggle) {
      setSelectedValue([])
    }
  },[clearDateSelectionOnToggle])

  useEffect(() => {
    if (setCalendarDate.length) {
      setSelectedValue(setCalendarDate)
    }
  },[setCalendarDate])
  

  function onDateChangeHandler (value) {
    const updatedValues = value
    if(isShowOneCalendar) {
      const sDate = value[0] ? new Date(value[0].setHours(0,0,0,0)) : value[0]
      const eDate = value[1] && !isEndDateEqualtoToday(value[1]) ? new Date(value[1].setHours(23, 59, 59, 999)) : value[1]
      updatedValues[0] = sDate
      updatedValues[1] = eDate
    } else {
      let endDate = value[1]
      if (endDate) {
      if (new Date(endDate) > new Date() && disableHours <= 0) {
        endDate = new Date()
        updatedValues[1] = endDate
      } else if (disableHours > 0) {
        const today = new Date()
        const calEndDate = new Date().setHours(today.getHours() - disableHours)
        if (new Date(endDate) > new Date(calEndDate)) {
          updatedValues[1] = new Date(calEndDate)
        }
      }
     }
    }
    setSelectedValue(updatedValues)
    onDateChange(updatedValues)
  } 
  useEffect(() => {
    if(startDate && endDate) {
      onDateChangeHandler([startDate, endDate])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  document
    .querySelector(':root')
    .style.setProperty(
      '--rs-text-secondary',
      `${setNoDataWarning ? 'red' :placeholderDefaultTextColor}`
    )

  let className = 'date-picker-rs ';

  if(setNoDataWarning){
    className += 'box-shadow-danger '
  }

  if(!isDateSelected) {
    className += 'no-date-selected '
  }

  const onDatePickerOpen = () => {
    const proceedButtonElement = document.querySelector('.rs-picker-toolbar-right button')
    if (proceedButtonElement && !proceedButtonElement.getAttribute('data-testid')) {
      proceedButtonElement.setAttribute('data-testid', `${baseTestId}-proceed-button`)
    }

    const selectMonthElement = document.querySelector('.rs-calendar-header-month-toolbar .rs-calendar-header-title')
    if (selectMonthElement && !selectMonthElement.getAttribute('data-testid')) {
      selectMonthElement.setAttribute('data-testid', `${baseTestId}-select-month-button`)
    }

    const previousMonthElement = document.querySelector('.rs-calendar-header-month-toolbar .rs-calendar-header-backward')
    if (previousMonthElement && !previousMonthElement.getAttribute('data-testid')) {
      previousMonthElement.setAttribute('data-testid', `${baseTestId}-previous-month-button`)
    }
    
    const nextMonthElement = document.querySelector('.rs-calendar-header-month-toolbar .rs-calendar-header-forward')
    if (nextMonthElement && !nextMonthElement.getAttribute('data-testid')) {
      nextMonthElement.setAttribute('data-testid', `${baseTestId}-next-month-button`)
    }
    
    const timePickerButtonElement = document.querySelectorAll('.rs-calendar-header-time-toolbar .rs-btn-subtle')
    if(timePickerButtonElement && timePickerButtonElement.length === 2) {
      timePickerButtonElement[0].addEventListener('click', function(event) {
        const closeButtonsElement = document.querySelectorAll('.rs-calendar-btn-close')
        if(closeButtonsElement && closeButtonsElement.length === 1) {
          closeButtonsElement[0].click()
        }
      })

      timePickerButtonElement[1].addEventListener('click', function(event) {
        const closeButtonsElement = document.querySelectorAll('.rs-calendar-btn-close')
        if(closeButtonsElement && closeButtonsElement.length === 1) {
          closeButtonsElement[0].click()
        }
      })
    }
  } 

  const isEndDateEqualtoToday = (date1) => {
    const date2 = new Date()
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  const getDate = () => {
    if (isTodayDisable) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1)
      return yesterday
    } else if (disableHours > 0){
      // Get the current date and time
      const currentDate = new Date();
      // Subtract the specified number of hours
      currentDate.setHours(currentDate.getHours() - disableHours);
      // Return the updated date
      return new Date(currentDate)
    }
    else {
      return new Date()
    }
  }
  return (
    <div>
      <DateRangePicker
        format={dateFormat}
        shouldDisableDate={disbaleFutureDate ? afterToday(): combine(allowedMaxDays(allowedMaxDaysInSelection) ,after(getDate()))}
        defaultCalendarValue={[
          new Date(Date.now() - 1000 * 60 * 1440),
          new Date(),
        ]}
        editable={false}
        character = ' to '
        onChange={onDateChangeHandler}
        ranges={[]}
        value={selectedValue}
        cleanable={false}
        
        placeholder={
          setNoDataWarning
            ? 'Enter valid date & time'
            : placeholderDefaultText
        }
        style={{
          width: 358,
          paddingLeft: `${setNoDataWarning ? '80px' :   paddingLeft}`,
       
        }}
        placement={modelPlacement}
        className={className}
        onOpen={onDatePickerOpen}
        data-testid={`${baseTestId}-picker`}
        showOneCalendar = {isShowOneCalendar}
      />
    </div>
  )
}
