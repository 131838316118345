import React, { useEffect } from 'react'
import Sidecard from '../child-components/fence-side-card/fenceSideCard'
import SideMap from '../child-components/side-map/SideMap'
import PlacesSearchBar from '../child-components/places-searchbar/PlacesSearchBar'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom"; 
import {
  GetGeoFanceOwners,
  GetGeoFanceLabelOwners,
  GetGeoFanceLabel,
} from '../.../../../../store/actions/geo-fence/report'
import { GetFenceById } from '../../../store/actions/geo-fence/createfence'
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping'

const CreateFence = ({
  //Actions
  GetGeoFanceOwners,
  GetGeoFanceLabelOwners,
  GetGeoFanceLabel,
  GetFenceById,
  GetUserRoleList,

  //States
  geoFanceOwnersData,
  geoFanceLabelOwnersData,
  geoFanceLabelData,
  fenceDetail,
  isFenceApiOperationSuccess,
  geoFenceLocationData
}) => {
  const id = useParams().id;

  useEffect(() => {
    if (id) {
      GetFenceById({ _id: id })
    }
  }, [GetFenceById, id])

  useEffect(() => {
    const fetchData = async () => {
      await GetUserRoleList()
      await GetGeoFanceOwners()
      await GetGeoFanceLabelOwners()
      await GetGeoFanceLabel()
    }
    fetchData()
  }, [GetGeoFanceOwners, GetGeoFanceLabelOwners, GetGeoFanceLabel, GetUserRoleList])

  return (
    <div
      className="row "
      style={{
        height: "Calc(100vh - 72px)",
        width: "100%",
        overflow: 'hidden',
        margin: 0
      }}
    >
      <Sidecard
        geoFanceOwnersData={geoFanceOwnersData}
        geoFanceLabelOwnersData={geoFanceLabelOwnersData}
        geoFanceLabelData={geoFanceLabelData}
        fenceDetail = {fenceDetail}
        fenceId={id}
        geoFenceLocationData = {geoFenceLocationData}
      />
      <div className="col-8 col-md-7 col-lg-8 p-0">
  
        <SideMap  fenceDetail = {fenceDetail} fenceId={id}  resetMap={isFenceApiOperationSuccess} />
        <PlacesSearchBar />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  geoFanceOwnersData: state.geoFanceReportReducer.geoFanceOwnersData,
  geoFanceLabelOwnersData: state.geoFanceReportReducer.geoFanceLabelOwnersData,
  geoFanceLabelData: state.geoFanceReportReducer.geoFanceLabelData,
  fenceDetail: state.fence.getFenceByIdDetails,
  isFenceApiOperationSuccess: state.fence.isFenceApiOperationSuccess,
  geoFenceLocationData: state.getAllUserRoleReducer.geoFenceLocationData
})

export default connect(mapStateToProps, {
  GetGeoFanceOwners,
  GetGeoFanceLabelOwners,
  GetGeoFanceLabel,
  GetFenceById,
  GetUserRoleList
})(CreateFence)
