import React, {useState } from "react";
import "../../RaUser.css";
import "./SingleUser.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { CreateUser } from "../../../../../../store/actions/ra_user_management";
import { connect} from "react-redux";
import PropTypes from "prop-types";
const NewSingleUserModal = ({
  //actions 
  CreateUser,

}) => {
  const [isActive, setIsActive] = useState(false);

  const [dates, setDates] = useState({
    dateOfBirth: new Date(),
    dateOfJoining: new Date(),
  });
  const [usersDetails, setUsersDetails] = useState({
    employeeName: "",
    empEmail: "",
    raEmail: "",
    raName: "",
    operatingOffice: "",
    designation: "",
    department: "",
    empId: "",
  });
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      usersDetails.employeeName !== "" &&
      usersDetails.empEmail !== "" &&
      usersDetails.raEmail !== "" &&
      usersDetails.raName !== "" &&
      usersDetails.operatingOffice !== "" &&
      usersDetails.designation !== "" &&
      usersDetails.department !== "" &&
      usersDetails.empId !== "" &&
      dates.dateOfBirth !== "" &&
      dates.dateOfJoining !== ""
    ) {
      // call the api
      CreateUser({
        data: {
          employeeName: usersDetails.employeeName,
          empEmail: usersDetails.empEmail,
          raEmail: usersDetails.raEmail,
          raName: usersDetails.raName,
          operatingOffice: usersDetails.operatingOffice,
          designation: usersDetails.designation,
          department: usersDetails.department,
          empId: usersDetails.empId,
          dateOfBirth: new Date(dates.dateOfBirth),
          dateOfJoining: new Date(dates.dateOfJoining),
          isActive
        },
        navigateTo: handleNavigate
      })

    }
    else {
      alert("Please fill all Details!");
    }
  };

  const [errors, setErrors] = useState({});
  const fieldNamesToLabels = {
    employeeName: "Name",
    empEmail: "Email",
    raEmail: "RA Mail",
    raName: "RA Name",
    operatingOffice: "Operating Office",
    designation: "Designation",
    department: "Department",
    empId: "Employee ID",
    dateOfBirth: "DOB",
    dateOfJoining: "DOJ",
  };

  const handleDateChange = (type, date) => {
    const newDate = new Date(date);
    setDates({
      ...dates,
      [type]: newDate,
    });
    if (!date) {
      setErrors({
        [type]: `${fieldNamesToLabels[type]} Is Required!`,
      });
    } else {
      setErrors({
        [type]: "",
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUsersDetails({
      ...usersDetails,
      [name]: value,
    });
    if (value.trim() === "") {
      setErrors({
        ...errors,
        [name]: `${fieldNamesToLabels[name]} Is Required!`,
      });
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };
  return (
    <>
      <div className="row float-left">
        <div className="col-12">
          <div className="p-4">
            <button className="close-button" onClick={() => navigate(-1)}>
              <BsArrowLeft />
            </button>
            <span className="main-text-add ml-2">Usermanagement</span>
          </div>
        </div>
      </div>
      <div className="profile-container container-fluid">
        <div className="row" style={{width:'70%'}}>
          <div className="col-12">
            <div className="card pl-5 pr-5" style={{ width: "100%" }}>
              <div className="profile-card-body card-body mt-4">
                <form className="profile-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label htmlFor="name" className="ra-add-label">
                          Employee Name:
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="employeeName"
                          className={`form-control inputfields add-ra-input`}
                          placeholder="Enter Employee Name"
                          value={usersDetails.employeeName}
                          onChange={handleChange}
                        />
                        {errors.employeeName && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.employeeName}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="name" className="ra-add-label">
                          Employee Email:
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="empEmail"
                          className={`form-control inputfields add-ra-input`}
                          placeholder="Enter Employee Email"
                          value={usersDetails.empEmail}
                          onChange={handleChange}
                        />
                        {errors.empEmail && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.empEmail}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="employeeId" className="ra-add-label">
                          Employee ID:
                        </label>
                        <input
                          type="text"
                          id="employeeId"
                          name="empId"
                          className={`form-control inputfields add-ra-input`}
                          placeholder="Enter Employee ID"
                          value={usersDetails.empId}
                          onChange={handleChange}
                        />
                        {errors.empId && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.empId}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="operatingOffice" className="ra-add-label">
                          Department:
                        </label>
                        <select
                          name="department"
                          id="operatingOffice"
                          className={`form-control inputfields add-ra-input`}
                          value={usersDetails.department}
                          onChange={handleChange}
                        >
                          <option>Select Department</option>
                          <option>Product Service lead</option>
                          <option>Business Development Head</option>
                          <option>Infomatics</option>
                        </select>
                        {errors.department && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.department}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <br />
                        <label htmlFor="dateOfBirth" className="ra-add-label">
                          Date of Birth:
                        </label>
                        <div className="input-group date__picker">
                        <DatePicker
                          id="dateOfBirth"
                          name="dateOfBirth"
                          className={`form-control inputfields add-ra-input`}
                          placeholderText="Select Date of Birth"
                          selected={dates?.dateOfBirth}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) =>
                            handleDateChange("dateOfBirth", date)
                          }
                        />
                        </div>
                        {errors.dateOfBirth && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.dateOfBirth}
                          </span>
                        )}
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="isActive" className="ra-add-label">
                          IsActive:
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            id="isActiveTrue"
                            name="isActive"
                            className="form-check-input"
                            value="true"
                            checked={isActive === true}
                            onChange={() => setIsActive(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isActiveTrue"
                          >
                            True
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            id="isActiveFalse"
                            name="isActive"
                            className="form-check-input"
                            value="false"
                            checked={isActive === false}
                            onChange={() => setIsActive(false)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isActiveFalse"
                          >
                            False
                          </label>
                        </div>
                      </div>

                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="operatingOffice" className="ra-add-label">
                          Operating Office:
                        </label>
                        <select
                          name="operatingOffice"
                          id="operatingOffice"
                          className={`form-control inputfields add-ra-input`}
                          value={usersDetails.operatingOffice}
                          onChange={handleChange}
                        >
                          <option>Select Operating Office</option>
                          <option>Bangalore</option>
                          <option>Mumbai</option>
                          <option>Delhi</option>
                          <option>Hyderabad</option>
                        </select>
                        {errors.operatingOffice && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.operatingOffice}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="operatingOffice" className="ra-add-label">
                          Designation:
                        </label>
                        <select
                          name="designation"
                          id="operatingOffice"
                          className={`form-control inputfields add-ra-input`}
                          value={usersDetails.designation}
                          onChange={handleChange}
                        >
                          <option>Select designation</option>
                          <option>Product Service</option>
                          <option>Business Development</option>
                          <option>Developer</option>
                        </select>
                        {errors.designation && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.designation}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="raEmail" className="ra-add-label">
                          RA Email:
                        </label>
                        <input
                          type="email"
                          id="raEmail"
                          name="raEmail"
                          className={`form-control inputfields add-ra-input`}
                          placeholder="Enter RA Email"
                          value={usersDetails.raEmail}
                          onChange={handleChange}
                        />
                        {errors.raEmail && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.raEmail}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="raName" className="ra-add-label">
                          RA Name:
                        </label>
                        <input
                          type="text"
                          id="raName"
                          name="raName"
                          className={`form-control inputfields add-ra-input`}
                          placeholder="Enter RA Name"
                          value={usersDetails.raName}
                          onChange={handleChange}
                        />
                        {errors.raName && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.raName}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <br />
                        <label htmlFor="dateOfJoining" className="ra-add-label">
                          Date of Joining:
                        </label>

                        <div className="input-group date__picker">
                          <DatePicker
                            id="dateOfJoining"
                            name="dateOfJoining"
                            className={`form-control inputfields add-ra-input`}
                            placeholderText="Select Date of Birth"
                            selected={dates?.dateOfJoining}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>
                              handleDateChange("dateOfJoining", date)
                            }
                          />
                        </div>
                        {errors.doj && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {errors.doj}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="passform-label-group text-center mt-4">
                    <button
                      className="btn btn-primary ra__user__add__btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
NewSingleUserModal.propTypes = {
  //action 
  CreateUser: PropTypes.func.isRequired,
  userCreated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  userCreated: state.user_management.userCreated
})
export default connect(mapStateToProps, {
  CreateUser
})(NewSingleUserModal)
