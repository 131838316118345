import React from 'react';

const NoRecordFound = () => {
  return (
    <>
      <div className="no-record-found-div d-flex flex-row justify-content-center align-items-center mt-5">
        <img
          src="/images/svgicon/noDataFound.svg"
          alt="file"
          height={147}
          width={147}
        />
      </div>

      <div className="no-record-found-label d-flex flex-row justify-content-center align-items-center">
        <span>No data found</span>
      </div>
    </>
  );
};

export default NoRecordFound;