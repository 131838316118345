import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { BsChevronDown } from 'react-icons/bs';

import MultiSelect from '../../helpers/hoc/multi-select/MultiSelect';

import "./ra-actions.css";
import { connect } from 'react-redux'
import { useEffect } from 'react';
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping';
import { UserRolesKeys } from '../../../utils/constants';

const EditRARequisition = (props) => {
    const { isMultiLocation, editFormData, editFormDataError, onEditChangeHandler, onSubmit, GetUserRoleList, masterLocationData, masterRoleData } = props;
    const handleOnChange = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const { name, value } = e.target
        const selectedValueText = name === 'userRole' ? e.target[e.target.selectedIndex].text : ''
        onEditChangeHandler({ name, value, selectedValueText })
    }

    useEffect(()=>{
		GetUserRoleList()
	},[GetUserRoleList])
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='edit-ra-modal'
        >
            <Modal.Header className='modal-header' closeButton>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div className="form-label-group required mb-3 mt-3">
                    <label
                        htmlFor="userRole"
                        className="formLabel ml-5"
                    >
                        Role
                    </label>
                    <div className="dropdown">
                        <select
                            name="userRole"
                            className={`form-control inputfields ${editFormDataError.userRole ? 'is-invalid' : ''}`}
                            value={editFormData.userRole}
                            onChange={handleOnChange}
                        >
                            <option value="">Select Role</option>
                            {masterRoleData?.filter(option => option.label !== UserRolesKeys.ADMIN).map((option, index) => (
                                <option
                                    key={`role_option_${index}`}
                                    value={option._id}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <div className="dropdown-arrow">
                            <BsChevronDown />
                        </div>
                    </div>
                    {editFormDataError.userRole && (
                        <div className="text-danger error-message">
                            {editFormDataError.userRole}
                        </div>
                    )}
                </div>
                <div className="form-label-group required mb-3">
                    <label
                        htmlFor="location"
                        className="formLabel ml-5"
                    >
                        Location
                    </label>

                    <div id="edit-location" className="location-multi-select-width">
                        <MultiSelect
                            placeholder='Select Location'
                            placeholderOnError={editFormDataError.location}
                            setNoDataWarning={editFormDataError.location !== ""}
                            valueContainerWidthInPx='250px'
                            valueContainerMaxWidthInPx='250px'
                            menuListPaddingX='px-3'
                            okButtonPaddingEnd='pe-2'
                            optionPosition='ps-2 justify-content-start'
                            options={masterLocationData?.map(x => {return {label: x.label, value: x.value}})}
                            isMultiSelect={isMultiLocation}
                            selectedOptions={editFormData.location}
                            setSelectedOptions={(locations) => onEditChangeHandler({ name: 'location', value: locations })}
                            customBackgroundColorReq={true}
                            customBackgroundColor='#F5F7F9'
                        />
                    </div>

                </div>
                <div className='mb-3'>
                    <label
                        htmlFor="comments"
                        className="formLabel ml-5"
                    >
                        Comments
                    </label>
                    <textarea
                        type="text"
                        name="comments"
                        className={`form-control textarea-height ${editFormDataError.comments ? 'is-invalid' : ''}`}
                        value={editFormData.comments}
                        onChange={handleOnChange}
                    />
                    {editFormDataError.comments && (
                        <div className="text-danger error-message">
                            {editFormDataError.comments}
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <div className='d-flex justify-content-center align-items-center'>
                    <button
                        type="button"
                        className="approve-btn btn-sm"
                        onClick={onSubmit}
                    >Submit</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    masterRoleData: state.getAllUserRoleReducer.masterRoleData,
	masterLocationData: state.getAllUserRoleReducer.masterLocationData
})

export default connect(mapStateToProps, {
	GetUserRoleList
})(EditRARequisition)