const initialState = {
    cities:[],
    labels:[],
    labelOwners:[],
    fenceOwners:[],
    selectedCities:[],
    selectedLabels:[],
    selectedLabelOwners:[],
    selectedFenceOwners:[],
    fencesData:[],
    allSelected: false,
    allVehicles: [],
    ingressSettings:{
        isIngressApplicable: false,
        isIngressTimeFullDay: true,
        isIngressTimeCustomized: false,
        startTimeHour: '',
        startTimeMinute: '',
        endTimeHour: '',
        endTimeMinute: '',
        isIngressForAllVehicles: true,
        isIngressForCustomizedVehicles: false,
        selectedVehicles: []
    },
    egressSettings:{
        isEgressApplicable: false,
        isEgressTimeFullDay: true,
        isEgressTimeCustomized: false,
        startTimeHour: '',
        startTimeMinute: '',
        endTimeHour: '',
        endTimeMinute: '',
        isEgressForAllVehicles: true,
        isEgressForCustomizedVehicles: false,
        selectedVehicles: []
    },
    dwellTimeSettings: {
        isDwellTimeApplicable: false,
        selectedConditions: {
            isLTE6Hrs: true,
            isIn6To12Hrs: true,
            isIn12To24Hrs: true,
            isIn24To48Hrs: true,
            isGT48Hrs: true,
          }
    },
    emailsForNotification: []
}

const alertConfigurationScreenReducer = (state = initialState, action) => {
    const { type, payload } = action;

    
    switch (type) {
        case "geofenceAlertConfig.citiesLoaded":
          return {
              ...state,
              cities: payload,
          }
          case "geofenceAlertConfig.labelsLoaded":
          return {
              ...state,
              labels: payload,
          }
          case "geofenceAlertConfig.labelOwnersLoaded":
          return {
              ...state,
              labelOwners: payload,
          }  
        case "geofenceAlertConfig.fenceOwnersLoaded":
            return{
              ...state,
              fenceOwners: payload
            }
        case "geofenceAlertConfig.allVehiclesLoaded":
            return{
                ...state,
                allVehicles: payload
            }
        case "geofenceAlertConfig.setSelectedCities":
            return{
                ...state,
                selectedCities: payload
            }
        case "geofenceAlertConfig.setSelectedLabels":
            return{
                ...state,
                selectedLabels: payload
            }
        case "geofenceAlertConfig.setSelectedLabelOwners":
            return{
                ...state,
                selectedLabelOwners: payload
            }
        case "geofenceAlertConfig.setSelectedFenceOwners":
            return{
                ...state,
                selectedFenceOwners: payload
            }
        case "geofenceAlertConfig.fencesLoaded":
            return{
                ...state,
                fencesData: payload
            }
        case "geofenceAlertConfig.selectAll":
            let fencesDataCopy = [...state.fencesData]
            fencesDataCopy = fencesDataCopy.map(x=>{
                return{
                    ...x,
                    isSelected: true
                }
            })
            return {
                ...state,
                fencesData: fencesDataCopy,
                allSelected: true
            }
        case "geofenceAlertConfig.unselectAll":
            let fencesDataCopyForUnselect = [...state.fencesData]
            fencesDataCopyForUnselect = fencesDataCopyForUnselect.map(x=>{
                return{
                    ...x,
                    isSelected: false
                }
            })
            return {
                ...state,
                fencesData: fencesDataCopyForUnselect,
                allSelected: false
            }
        case "geofenceAlertConfig.setEmailsToNoify":
            return{
                ...state,
                emailsForNotification: payload
            }
        
        
        //Ingress actions
        
        case "geofenceAlertConfig.ingressSettings.ingressSettingsApplicable":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.isIngressApplicable = payload
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressTimeForFullDay":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.isIngressTimeFullDay = true
            ingressSettingsCopy.isIngressTimeCustomized = false
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressTimeCustomized":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.isIngressTimeFullDay = false
            ingressSettingsCopy.isIngressTimeCustomized = true
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressStartTimeHr":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.startTimeHour = payload
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressStartTimeMinute":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.startTimeMinute = payload
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressEndTimeHr":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.endTimeHour = payload
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressEndTimeMinute":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.endTimeMinute = payload
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressForAllVehicles":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.isIngressForAllVehicles = true
            ingressSettingsCopy.isIngressForCustomizedVehicles = false
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setIngressForCustomizedVehicles":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.isIngressForAllVehicles = false
            ingressSettingsCopy.isIngressForCustomizedVehicles = true
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        }

        case "geofenceAlertConfig.ingressSettings.setSelectedVehiclesForIngress":{
            let ingressSettingsCopy = {...state.ingressSettings}
            ingressSettingsCopy.selectedVehicles = payload
            return {
                ...state,
                ingressSettings: ingressSettingsCopy
            }
        } 


        //Egress actions
        
        case "geofenceAlertConfig.egressSettings.egressSettingsApplicable":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.isEgressApplicable = payload
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressTimeForFullDay":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.isEgressTimeFullDay = true
            egressSettingsCopy.isEgressTimeCustomized = false
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressTimeCustomized":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.isEgressTimeFullDay = false
            egressSettingsCopy.isEgressTimeCustomized = true
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressStartTimeHr":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.startTimeHour = payload
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressStartTimeMinute":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.startTimeMinute = payload
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressEndTimeHr":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.endTimeHour = payload
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressEndTimeMinute":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.endTimeMinute = payload
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressForAllVehicles":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.isEgressForAllVehicles = true
            egressSettingsCopy.isEgressForCustomizedVehicles = false
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setEgressForCustomizedVehicles":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.isEgressForAllVehicles = false
            egressSettingsCopy.isEgressForCustomizedVehicles = true
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        }

        case "geofenceAlertConfig.egressSettings.setSelectedVehiclesForEgress":{
            let egressSettingsCopy = {...state.egressSettings}
            egressSettingsCopy.selectedVehicles = payload
            return {
                ...state,
                egressSettings: egressSettingsCopy
            }
        } 

        //Dwell Time Settings

        case "geofenceAlertConfig.dwellTimeSetting.setDwellTimeApplicable":{
            let settingsCopy = {...state.dwellTimeSettings}
            settingsCopy.isDwellTimeApplicable = payload
            return {
                ...state,
                dwellTimeSettings: settingsCopy
            }
        } 

        case "geofenceAlertConfig.dwellTimeSetting.setSelectedConditions":{
            let settingsCopy = {...state.dwellTimeSettings}
            settingsCopy.selectedConditions = payload
            return {
                ...state,
                dwellTimeSettings: settingsCopy
            }
        } 

        case "geofenceAlertConfig.resetAlertConfiguration": {
            return {
                ...state,
                fencesData: [],
                ingressSettings: {...initialState.ingressSettings},
                egressSettings: {...initialState.egressSettings},
                dwellTimeSettings: {...initialState.dwellTimeSettings},
                emailsForNotification: [],
                selectedCities:[],
                selectedLabels:[],
                selectedLabelOwners:[],
                selectedFenceOwners:[]
            }
        }
        default:
          return state;
    }
}

export default alertConfigurationScreenReducer;