// External packages import
import React, { useEffect, useState} from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';


// Internal components import
import Button from '../../../shared/form/Button/Button';
import SearchInput from '../../../shared/form/SearchInput/SearchInput';
import BaseModal from '../../../shared/modals/BaseModal/BaseModal';
import Pagination from '../../../shared/pagination/Pagination';
import Popover from '../../../shared/modals/Popover/Popover';
import TableC from '../../../shared/table/Table';
import Loader from '../../../helpers/hoc/loader/Loader';
import Modal from './modal/Modal';
import InformativeModal from '../../../shared/modals/InformativeModal/InformativeModel';
import CustomerForm from './CustomerForm';
import Dropdown from '../../../shared/ui/Dropdown/Dropdown';
// Services Utils and Store import

import validationService from '../../../../service/validationService';
import { ActiveStatusCustomer, CreateCustomer, EditCustomer, exportExcelCustomer, fetchCustomerList } from '../../../../store/actions/administration/Administration';
import { getFormattedOnlyDate } from '../../../../utils/common-methods';

// Style import
import adminStyles from './SAAdmin.module.css'
import PageHeader from '../../../shared/ui/PageHeader/PageHeader';



const Administration = ({
  //action
  setCustomerAction,
  //state
  customerListData,
  

}) => {
  //states
  const isLoading = useSelector(state => state.loader.isLoading)
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearchKey] = useState();
  const [sortField, setSortfield] = useState({ sortBy: '', sortOrder: '' })
  const ITEMS_PER_PAGE = 8;
  const [id, setId] = useState(null)
  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: '',
    variant: ''
  })

  const [statusModalData, setStatusModalData]=useState({
   isOpen:false,
   customerId:'',
   customerName:'',
   status:'',
 });

  const [editIndex, seteditIndex] = useState(0)

  const [formEditData, setFormEditData] = useState({
    customerName: '',
    adminName: '',
    email: '',
    contactNumber: '',
    address: ''
  })

  const [formEditError, setFormEditError] = useState({
    customerName: '',
    adminName: '',
    email: '',
    contactNumber: '',
    address: ''
  })

  const [formData, setFormData] = useState({
    customerName: '',
    adminName: '',
    email: '',
    contactNumber: '',
    address: '',
  })

  const [formError, setFormError] = useState({
    customerName: '',
    adminName: '',
    email: '',
    contactNumber: '',
    address: ''
  })

  const fetchData = async () => {
    await setCustomerAction({
      fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      toDate: new Date(new Date().setTime(new Date().getTime() - 60000)),
      searchText: search,
      pageSize: ITEMS_PER_PAGE,
      pageIndex: currentPage - 1,
      sortBy: sortField.sortBy,
      sortOrder: sortField.sortOrder,
    });

  };
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCustomerAction, search, sortField, currentPage]);


  useEffect(() => {
    if (customerListData && customerListData.rows && customerListData.rows.length > 0 && editIndex !== undefined) {
      const { rows } = customerListData;
      const { companyName, name, mobileNumber, email, address, subDomain, _id } = rows[editIndex];
      setFormEditData(prevData => ({
        ...prevData,
        customerName: companyName,
        adminName: name,
        contactNumber: mobileNumber,
        email: email,
        address: address,
        subDomain: subDomain
      }));
      setId(_id)
    }
  }, [customerListData, editIndex]);


  //handle for create customer open
  const handleCreateCustomerOpen = () => {
    if (isOpenCreate) {
      setFormData(prevState => ({ ...prevState, email: '' }))
      setFormError(prevState => ({ ...prevState, email: '' }))
    }
    setFormData({
      customerName: '',
      adminName: '',
      email: '',
      contactNumber: '',
      address: '',
      subDomain: ''
    })
    setFormError({
      customerName: '',
      adminName: '',
      email: '',
      contactNumber: '',
      address: '',
      subDomain: ''
    })
    setIsOpenCreate(prevState => !prevState)
    setIsOpenCreate(true)

  }
  const handleCreateCustomerClose = () => {
    setIsOpenCreate(false)

  }

  // handle Edit submit

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (handleValidationEdit() > 0) {
      return;
    }
    try {
      const res = await EditCustomer({
        data: {
          customerId: id,
          customerName: formEditData.customerName,
          adminName: formEditData.adminName,
          contactNumber: formEditData.contactNumber,
          email: formEditData.email,
          address: formEditData.address,
          subDomain: formEditData.subDomain
        }
      })


      if (res.status === 200) {
   
        setFormEditData({
          customerName: '',
          adminName: '',
          email: '',
          contactNumber: '',
          address: '',
        })

        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: res.data.message,
          variant: 'success'
        })



        setIsEdit(false);
        fetchData();

      }
      if(res.status !== 200) {
        setApiResponse({
            ...apiResponse,
            isOpen: true,
            message: res.data?.error[0]?.message,
            variant: 'error'
        })
    }
    } catch (error) {
      console.log(error)
    }
  }

  //handle export excel

  const exportToExcel = () => {
    exportExcelCustomer({
      data: {
        searchText: search,
        sortBy: sortField.sortBy,
        sortOrder: sortField.sortOrder,
      }
    })
  }


  // handle create  submit

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation() > 0) {
      return;
    }
    try {
      const res = await CreateCustomer({
        data: {
          customerName: formData.customerName,
          adminName: formData.adminName,
          contactNumber: formData.contactNumber,
          email: formData.email,
          address: formData.address,
          subDomain: formData.subDomain
        }
      })

      
      if (res.status === 200) {

        setApiResponse({
          isOpen: true,
          message: res.data.message,
          variant: 'success'
        })

        setFormData({
          customerName: '',
          adminName: '',
          email: '',
          contactNumber: '',
          address: '',
        })


        handleCreateCustomerClose()
        fetchData();
      }

          
      if(res.status !== 200) {
        setApiResponse({
            ...apiResponse,
            isOpen: true,
            message: res.data?.error[0]?.message,
            variant: 'error'
        })
    }
    } catch (error) {
      console.log(error)
    }
  }

  // handle submit Active status

  const handleUpdateStatus = async () => {
    try {
      const res = await ActiveStatusCustomer({
        data: {
          userId: statusModalData?.customerId,
          isActiveStatus: (statusModalData?.status === 'Active' ? false : true),
        }
      })
      
      if (res.status === 200) {
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: statusModalData?.status === 'Active'?'Deactivated successfully':'Activated successfully',
          variant: 'success'
        });
        fetchData();
        handleStatusModalClose();

   
      }
    } catch (error) {
      console.log(error)
    }
  }




  //handle for edit customer open
  const handleEditOpen = (customerId, index) => {

    if (customerId) {
      setIsEdit(true)
    }
    seteditIndex(index)
  }

  // handle for handleActivateOpen 

  const handleStatusOpen = (customerId, customerName, status) => {
    if (customerId) {
      setStatusModalData({
        isOpen:true,
        customerId:customerId,
        customerName:customerName,
        status:status,
   
         })
    }



  }
  const handleStatusModalClose = () => {
    setStatusModalData({
      // ...statusModalData,
      isOpen: false,
   
    })
  }

  // handle validation create customer
  const handleValidation = () => {
    const validationCustomerName = validationService.required(formData.customerName);
    const validationAdminName = validationService.required(formData.adminName);
    let validationEmail = validationService.email(formData.email);  
    const validationContactNumber = validationService.mobile(formData.contactNumber);
    const validationAddress = validationService.required(formData.address);

    const errors = {
      customerName: validationCustomerName.message,
      adminName: validationAdminName.message,
      email: validationEmail.message,
      contactNumber: validationContactNumber.message,
      address: validationAddress.message
    };

    setFormError(errors);
    const errorCount = Object.values(errors).filter(message => message).length;
    return errorCount;
  };


  // handle validation edit customer
  const handleValidationEdit = () => {
    const validationCustomerName = validationService.required(formEditData.customerName);
    const validationAdminName = validationService.required(formEditData.adminName);
    const validationEmail = validationService.email(formEditData.email);
    const validationContactNumber = validationService.mobile(formEditData.contactNumber);
    const validationAddress = validationService.required(formEditData.address);

    const errors = {
      customerName: validationCustomerName.message,
      adminName: validationAdminName.message,
      email: validationEmail.message,
      contactNumber: validationContactNumber.message,
      address: validationAddress.message
    };

    setFormEditError(errors);
    const errorCount = Object.values(errors).filter(message => message).length;
    return errorCount;
  };


 

  const sortHandler = (sortBy, sortOrder) => {
    setSortfield({
      sortBy,
      sortOrder
    })
  }


  const columns = [
    {
      key: 'ci',
      header: 'CUSTOMER CODE',
    },
    {
      key: 'companyName',
      header: 'CUSTOMER NAME',
      sortable: true,
      sortFieldName: 'companyName',
      renderCell: (row) => {
        return (
          <>
            {row.companyName}
            <Popover
              triggerComponent={
                <button className="row-btn">
                  <img src='/images/tooltipInfo.svg' alt='info' className='alert-history-info' />
                </button>
              }
              bodyComponent={
                <div className={`${adminStyles.admin_tooltip_inner_container}`}>
                  <span > Registered Address :</span>
                    <br/>
                  {row.address}
                </div>
              }
              trigger="hover" 
              placement="left-start" 
            />
          </>
        );
      },
    },    
    {
      key: 'name',
      header: 'ADMIN NAME',
      sortFieldName: 'an',
      sortable: true,
    },
    {
      key: 'email',
      header: 'ADMIN EMAIL ID',

    },
    {
      key: 'mobileNumber',
      header: 'CONTACT NUMBER',
    },
    {
      key: 'createdAt',
      header: 'DATE OF ONBOARDING',
      sortFieldName: 'createdAt',
      sortable: true,
      renderCell: (row) => (
        row.createdAt ? getFormattedOnlyDate(row.createdAt) : 'NA'
      )
    },
    {
      key: 'status',
      header: 'STATUS',
      sortFieldName: 'isActive',
      sortable: true,
      renderCell: (row) => (
        <span style={{ color: row.status === "Active" ? '#69FE97' : '#F29900' }}>
          {row.status === "Active" ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      key: 'action',
      header: 'ACTION',
      renderCell: (row, index) => (
        <Dropdown
          triggerComponent={<img src="/images/verticalThreedots.svg" alt="more menu icon" />}
          dropdownItems={[{
            label: 'Edit',
            value: 'EDIT',
            keyExtractor: () => `edit_${index}`,
            onClick: () => handleEditOpen(row?._id, index)
          }, {
            label: row?.status === 'Active' ? 'Deactivate' : 'Activate',
            value: row?.status === 'Active' ? 'Deactivate' : 'Activate',
            keyExtractor: () => `deactivate_${index}`,
            onClick: () => handleStatusOpen(row?._id, row?.companyName, row?.status)
          }]}
        />
      ),
    },
  ];


  return (
    <>
      <div className={`${adminStyles.admin__container}`}>
      <div className="d-flex justify-content-between align-items-center flex-row mt-4">
        <PageHeader heading="Administration" />
        </div>
        <div  className={`${adminStyles.admin__table__container} d-flex col-12 flex-column h-70 mt-2 px-4`}>
          <div className={`${adminStyles.admin__cta__container} d-flex justify-content-between align-items-center flex-row`}> 
            <div>
              <SearchInput
              setSearchKey={setSearchKey}
              setCurrentPage={setCurrentPage}
              placeholder="Search"
              />
            </div>

            <div className="d-flex flex-row justify-content-end ">
              <div className="me-2">
                <Button variant="BLUE" onClick={handleCreateCustomerOpen}>Create Customer</Button>

              </div>

              <div className="me-2">
                
              <Button
                variant="BLUE_OUTLINED"
                onClick={exportToExcel}
                disabled={!customerListData?.rows?.length}
                style={{'--outlined-background': '#060B28' }}
              >
                Download
             </Button>

              </div>

            </div>

          </div>

          <TableC
            columns={columns}
            data={customerListData?.rows}
            handleSortHandler={(e, v) => sortHandler(e, v)}
            message="CREATE CUSTOMER"
          />

          {customerListData?.rows?.length ? (<div className="mb-1 position-relative">
            <Pagination
              disabled={false}
              totalPages={Math.ceil(customerListData?.count / ITEMS_PER_PAGE)}
              currentPage={currentPage}
              setPage={setCurrentPage}
            />

          </div>
          ) : (
            <></>
          )}
          <>

          <BaseModal title='Create New Customer' isOpen={isOpenCreate} onClose={handleCreateCustomerClose}>
            <CustomerForm
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            onSubmit={handleSubmit}
            setFormError={setFormError}
            />
            </BaseModal>
          </>

        </div>

        {/* Edit Modal */}
        <>
          <BaseModal title='Edit Customer Details' isOpen={isEdit} onClose={() => setIsEdit(false)}>
            <CustomerForm
            formData={formEditData}
            setFormData={setFormEditData}
            formError={formEditError}
            onSubmit={handleEditSubmit}
            />
          </BaseModal>


        </>
        <Modal
          isVisible={statusModalData?.isOpen}
          closeModal={handleStatusModalClose}
          name={statusModalData?.customerName}
          handleUpdateStatus={handleUpdateStatus}
          status={statusModalData?.status}
        />

        <Loader isLoading={isLoading} />
        <InformativeModal
          isOpen={apiResponse.isOpen} 
          onClose={() => setApiResponse({
            ...apiResponse,
            isOpen: false
        })}
          message={apiResponse.message}
          variant={apiResponse.variant}
        />
      </div>
    </>

  )
}


const mapStateToProps = (state) => ({
  customerListData: state.administrationReducer.customerListData,
  isLoading: state.loader.isLoading,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCustomerAction: fetchCustomerList,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
/*conflict-resolution-will be removed later*/