import React, { useEffect, useState } from 'react';
import { Table, Button, OverlayTrigger } from 'react-bootstrap';
import Pagination from '../../../helpers/hoc/paginator/Pagination';
import MultiSelect from '../../../helpers/hoc/mult-select/MultiSelect';
import DatePicker from '../../../helpers/hoc/date-picker/DatePicker';
import { BsDownload } from 'react-icons/bs';
import './geofanceAlert.css';
import { getFormattedDate, secondsToHoursAndMinutes } from '../../../../utils/common-methods';
import NoRecordFound from '../../../helpers/hoc/noRecordFound';
import {
	GetUniqueGeoFanceName,
	GetUniqueGeoFanceLabel,
	GetUniqueGeoFanceLabelOwners,
	GetUniqueGeoFanceOwners,
} from '../../../../store/actions/geo-fence/report';
import { useDispatch, useSelector } from 'react-redux';

const RowButton = ({ renderTooltip, fenceOwner, labelOwner, label }) => (
	<OverlayTrigger
		placement='right'
		delay={{ show: 0, hide: 0 }}
		overlay={renderTooltip({ parameter1: fenceOwner, parameter2: labelOwner, parameter3: label })}
	>
		<button className='row-btn alert-recent-btn'>
			<img src='/images/svgicon/info.svg' alt='info' className='alert-history-info' />
		</button>
	</OverlayTrigger>
);

const Row = ({ renderTooltip, recent, index }) => (
	<tr>
		<td className='alert-recent-td'>
			<span className='alert-recent-td-span'>{index + 1}.</span>
		</td>
		<td className='alert-recent-td'>{recent.registration_number}</td>
		<td className='alert-recent-td'>{`${recent.oem} ${recent.model}`}</td>
		<td className='alert-recent-td'>
			{recent.fence_name}
			<RowButton
				renderTooltip={renderTooltip}
				fenceOwner={recent.fenceOwner}
				labelOwner={recent.labelOwner}
				label={recent.label}
			/>
		</td>
		<td className='alert-recent-td'>{recent.alertType}</td>
		<td className='alert-recent-td'>
			<span
				className='alert-recent-td-span'
				style={{
					color: recent.alertType === 'Ingress' ? '#E20909' : '#1F2A5D',
				}}
			>
				{recent.ingress_date_time ? getFormattedDate(recent.ingress_date_time) : 'NA'}
			</span>
		</td>
		<td className='alert-recent-td'>
			<span
				className='alert-recent-td-span'
				style={{
					color: recent.alertType === 'Egress' ? '#E20909' : '#1f2a5d  ',
				}}
			>
				{recent.egress_date_time ? getFormattedDate(recent.egress_date_time) : 'NA'}
			</span>
		</td>
		<td className='alert-recent-td'>
			<span
				className='alert-recent-td-span'
				style={{
					color: recent.alertType === 'Dwell Time' ? '#E20909' : '#1f2a5d',
				}}
			>
				{recent.dwell_time_in_sec ? secondsToHoursAndMinutes(recent.dwell_time_in_sec) : 'NA'}
			</span>
		</td>
	</tr>
);

const TableHeader = () => (
	<thead>
		<tr className='table-header alert-recent-history-tr  '>
			<th>
				<span className='alert-recent-th'>S.No.</span>
			</th>
			<th>
				<span className='alert-recent-th'>Vehicle No.</span>
			</th>
			<th>
				<span className='alert-recent-th'>OEM & Model</span>
			</th>
			<th>
				<span className='alert-recent-th'>Fence Name</span>
			</th>
			<th>
				<span className='alert-recent-th'>Alert Type</span>
			</th>
			<th>
				<span className='alert-recent-th'>
					Ingress <br></br> Date & Time
				</span>
			</th>
			<th>
				<span className='alert-recent-th'>
					Egress <br></br> Date & Time
				</span>
			</th>
			<th>
				<span className='alert-recent-th'>Dwell Time</span>
			</th>
		</tr>
	</thead>
);

const HistoryReport = ({
	onDateChangeHandler,
	endDate,
	startDate,
	geoFanceReportData,
	renderTooltip,
	onGetReportsClick,
	onExportReportClick,
	cities,
	setCities,
	labelIds,
	setLabelIds,
	labelOwnerIds,
	setLabelOwnerIds,
	fenceOwnerIds,
	setFenceOwnerIds,
	page,
	setPage,
	geoFanceCityData,
	isLoading,
	radioSelected,
	setRadioSelected,
	subcribedRnNums,
	vehicleRnNumbers,
	setVehicleRnNumbers,
	geoFanceReportHistoryData,
	fenceIds,
	setFenceIds,
	ITEMS_PER_PAGE,
	geoFanceReportHistoryDataCount,
}) => {
	const onRadioChangeHandler = () => {
		setCities([]);
		setLabelIds([]);
		setVehicleRnNumbers([]);
		setFenceIds([]);
		setFenceOwnerIds([]);
		setLabelOwnerIds([]);
		setClearDateSelection(true);
		onDateChangeHandler(['', '']);
		setIsClicked(false);
		setTimeout(() => {
			setClearDateSelection(false);
		}, 500);
		setPage(1);
		geoFanceReportHistoryData.length = 0;
		geoFanceReportData.length = 0;
	};

 
	let isDisabled = () => {
		if (radioSelected === 'location_filter') {
			if (cities.length > 0 && startDate !== '' && endDate !== '') {
				return false;
			} else {
				return true;
			}
		} else if (radioSelected === 'fence_filter') {
			if (labelIds.length > 0 && startDate !== '' && endDate !== '') {
				return false;
			} else {
				return true;
			}
		} else if (radioSelected === 'vehicle_filter') {
			if (vehicleRnNumbers.length > 0 && startDate !== '' && endDate !== '') {
				return false;
			} else {
				return true;
			}
		}
	};
	const [clearDateSelection, setClearDateSelection] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	const dispatch = useDispatch();

	const uniqueGeoFanceNameData = useSelector((s) => s?.geoFanceReportReducer?.uniqueGeoFanceNameData);
	const uniqueGeoFanceOwnersData = useSelector((s) => s?.geoFanceReportReducer?.uniqueGeoFanceOwnersData);
	const uniqueGeoFanceLabelsData = useSelector((s) => s?.geoFanceReportReducer?.uniqueGeoFanceLabelsData);
	const uniqueGeoFanceLabelOwnersData = useSelector((s) => s?.geoFanceReportReducer?.uniqueGeoFanceLabelOwnersData);

	useEffect(() => {
		dispatch(GetUniqueGeoFanceName());
		dispatch(GetUniqueGeoFanceLabel());
		dispatch(GetUniqueGeoFanceLabelOwners());
		dispatch(GetUniqueGeoFanceOwners());
	}, [dispatch]);
	return (
		<>
			<div className='col-12 col-md-12 col-lg-12   bg-white'>
				<div className=' bg-white mb-1 position-relative  '>
					<div className='alert-history-radio-container'>
						<div className='mt-1'>
							<input
								type='radio'
								className='alert-radio-input'
								id={'location_filter'}
								checked={radioSelected === 'location_filter'}
								onChange={(e) => {
									onRadioChangeHandler();
									setRadioSelected('location_filter');
								}}
								data-testid='history-location-filter-radio'
							/>
							<label className='ps-2 alert-radio-label   ' htmlFor='location_filter'>
								Location Based
							</label>
						</div>

						<div className='mt-1'>
							<input
								type='radio'
								className='alert-radio-input'
								id={'fence_filter'}
								checked={radioSelected === 'fence_filter'}
								onChange={(e) => {
									onRadioChangeHandler();
									setRadioSelected('fence_filter');
								}}
								data-testid='history-fence-filter-radio'
							/>
							<label className='ps-2 alert-radio-label' htmlFor='fence_filter'>
								Fence Based
							</label>
						</div>

						<div className='mt-1'>
							<input
								type='radio'
								className='alert-radio-input'
								id={'vehicle_filter'}
								checked={radioSelected === 'vehicle_filter'}
								onChange={(e) => {
									onRadioChangeHandler();
									setRadioSelected('vehicle_filter');
								}}
								data-testid='history-vehicle-filter-radio'
							/>
							<label className='ps-2 alert-radio-label' htmlFor='vehicle_filter'>
								Vehicle Based
							</label>
						</div>
					</div>
				</div>
				<div className='row alert-history-select-container '>
					{radioSelected === 'location_filter' && (
						<>
							<div className={`col-2  alert-history-select ${!cities[0] ? 'no-value-selected' : ''}`}>
								<MultiSelect
									placeholder='Select City'
									options={geoFanceCityData}
									valueContainerWidthInPx='200px'
									valueContainerMaxWidthInPx='200px'
									menuListPaddingX='px-2'
									okButtonPaddingEnd='pe-2'
									optionPosition='justify-content-start'
									selectedOptions={cities}
									isMultiSelect={true}
									setSelectedOptions={setCities}
									customBackgroundColor='#EFF3F7'
									webkitScrollbarWidth='5px'
									webkitScrollbarHeight='5px'
									minHeight='45px'
									placeholderAlignment='flex-start'
									indicatorSeparatorDisplay='none'
									placeholderColor='#C6C6C6'
									valueContainerColor='#797694'
									data-testid='history-city-select'
								/>
							</div>
						</>
					)}

					{radioSelected === 'vehicle_filter' && (
						<>
							<div
								className={`col-2 alert-history-select ${
									!vehicleRnNumbers[0] ? 'no-value-selected' : ''
								}`}
							>
								<MultiSelect
									placeholder='Select Vehicle No'
									options={subcribedRnNums}
									valueContainerWidthInPx='200px'
									valueContainerMaxWidthInPx='200px'
									menuListPaddingX='px-2'
									okButtonPaddingEnd='pe-2'
									optionPosition='justify-content-start'
									selectedOptions={vehicleRnNumbers}
									isMultiSelect={true}
									setSelectedOptions={setVehicleRnNumbers}
									customBackgroundColor='#EFF3F7'
									minHeight='45px'
									webkitScrollbarWidth='5px'
									webkitScrollbarHeight='5px'
									placeholderAlignment='flex-start'
									indicatorSeparatorDisplay='none'
									placeholderColor='#C6C6C6'
									valueContainerColor='#797694'
									data-testid='history-vehicle-select'
								/>
							</div>
						</>
					)}

					<div className={`col-2 alert-history-select ${!labelIds[0] ? 'no-value-selected' : ''}`}>
						<MultiSelect
							placeholder='Select Label'
							valueContainerWidthInPx='200px'
							valueContainerMaxWidthInPx='200px'
							menuListPaddingX='px-2'
							okButtonPaddingEnd='pe-2'
							optionPosition='justify-content-start'
							options={uniqueGeoFanceLabelsData}
							selectedOptions={labelIds}
							isMultiSelect={true}
							setSelectedOptions={setLabelIds}
							customBackgroundColor='#EFF3F7'
							minHeight='45px'
							webkitScrollbarWidth='5px'
							webkitScrollbarHeight='5px'
							placeholderAlignment='flex-start'
							indicatorSeparatorDisplay='none'
							placeholderColor='#C6C6C6'
							valueContainerColor='#797694'
							data-testid='history-label-select'
						/>
					</div>

					<div className={`col-2  alert-history-select ${!labelOwnerIds[0] ? 'no-value-selected' : ''}`}>
						<MultiSelect
							placeholder='Select Label Owner'
							valueContainerWidthInPx='250px'
							valueContainerMaxWidthInPx='250px'
							menuListPaddingX='px-2'
							okButtonPaddingEnd='pe-2'
							optionPosition='justify-content-start'
							options={uniqueGeoFanceLabelOwnersData}
							selectedOptions={labelOwnerIds}
							isMultiSelect={true}
							setSelectedOptions={setLabelOwnerIds}
							customBackgroundColor='#EFF3F7'
							minHeight='45px'
							webkitScrollbarWidth='5px'
							webkitScrollbarHeight='5px'
							placeholderAlignment='flex-start'
							indicatorSeparatorDisplay='none'
							placeholderColor='#C6C6C6'
							valueContainerColor='#797694'
							data-testid='history-labelowner-select'
						/>
					</div>
					<div className={`col-2  alert-history-select ${!fenceOwnerIds[0] ? 'no-value-selected' : ''}`}>
						<MultiSelect
							placeholder='Select Fence Owner'
							valueContainerWidthInPx='200px'
							valueContainerMaxWidthInPx='200px'
							menuListPaddingX='px-2'
							okButtonPaddingEnd='pe-2'
							optionPosition='justify-content-start'
							options={uniqueGeoFanceOwnersData}
							selectedOptions={fenceOwnerIds}
							isMultiSelect={true}
							setSelectedOptions={setFenceOwnerIds}
							customBackgroundColor='#EFF3F7'
							minHeight='45px'
							webkitScrollbarWidth='5px'
							webkitScrollbarHeight='5px'
							placeholderAlignment='flex-start'
							indicatorSeparatorDisplay='none'
							placeholderColor='#C6C6C6'
							valueContainerColor='#797694'
							data-testid='history-fenceowner-select'
						/>
					</div>

					{radioSelected === 'fence_filter' && (
						<>
							<div className={`col-2 alert-history-select ${!fenceIds[0] ? 'no-value-selected' : ''}`}>
								<MultiSelect
									placeholder='Select Fence Name'
									options={uniqueGeoFanceNameData}
									valueContainerWidthInPx='200px'
									valueContainerMaxWidthInPx='200px'
									menuListPaddingX='px-2'
									okButtonPaddingEnd='pe-2'
									optionPosition='justify-content-start'
									selectedOptions={fenceIds}
									isMultiSelect={true}
									setSelectedOptions={setFenceIds}
									customBackgroundColor='#EFF3F7'
									minHeight='45px'
									webkitScrollbarWidth='5px'
									webkitScrollbarHeight='5px'
									placeholderAlignment='flex-start'
									indicatorSeparatorDisplay='none'
									placeholderColor='#C6C6C6'
									valueContainerColor='#797694'
									data-testid='history-fencename-select'
								/>
							</div>
						</>
					)}

					<div className='col-2 alert-history-select date__picker'>
						<DatePicker
							clearDateSelectionOnToggle={clearDateSelection}
							onDateChange={onDateChangeHandler}
							allowedMaxDaysInSelection={30}
							paddingLeft='0'
							placeholderDefaultTextColor='#C6C6C6'
							modelPlacement='bottomStart'
							isDateSelected={startDate && endDate}
							data-testid='history-datepicker'
						/>
					</div>

					<div
						className='col-2   alert-history-getReport'
						style={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<Button
							className='bttn height'
							id='getReport'
							type='submit'
							disabled={isDisabled()}
							onClick={() => {
								onGetReportsClick(setIsClicked(true));
							}}
							data-testid='history-get-report-button'
						>
							Get Report
						</Button>
						<div className='alert-history-dwn'>
							<button
								className={
									!geoFanceReportHistoryData.length ? 'dwn-history-btn-disabled' : 'dwn-history-btn'
								}
								onClick={onExportReportClick}
								disabled={!geoFanceReportHistoryData}
								data-testid='history-export-button'
							>
								<BsDownload style={{width:'22px',height:'22px'}} />
							</button>
						</div>
					</div>
				</div>
			</div>

			{geoFanceReportHistoryData.length > 0 ? (
				<div className=' bg-white mb-1 position-relative pt-2 mt-1   alert-history-table-container'>
					<Table
						responsive={true}
						borderless
						className='mb-0'
						style={{
							minHeight: '230px',
						}}
					>
						<TableHeader />
						{/* {loading && <Loader isLoading={loading} />} */}
						{geoFanceReportHistoryData?.length > 0 && (
							<>
								{geoFanceReportHistoryData?.map((item, index) => (
									<Row
										key={index}
										recent={item}
										index={page * ITEMS_PER_PAGE + index - ITEMS_PER_PAGE}
										renderTooltip={renderTooltip}
									/>
								))}
							</>
						)}
					</Table>

					<Pagination
						disabled={false}
						currentPage={page}
						setPage={setPage}
						totalPages={Math.ceil((geoFanceReportHistoryDataCount || 0) / ITEMS_PER_PAGE)}
						data-testid='history-pagination'
					/>
				</div>
			) : (
				isClicked  &&  !isLoading  && <NoRecordFound />
			)}
		</>
	);
};

export default HistoryReport;
