import React, { useEffect } from "react";
import { Row, Col} from 'react-bootstrap'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io'
import {
    setSelectedConditions,
    setDwellTimeApplicable
} from '../../../../store/actions/geo-fence/configuration/alertsConfiguration/alertConfigurationScreenActions'

const DwellTimeSettingsDiv = ({
    isDwellTimeApplicable,
    setDwellTimeApplicable,
    setSelectedConditions,
    selectedConditions,
    fencesData
})=> { 

    const { isLTE6Hrs, isIn6To12Hrs, isIn12To24Hrs, isIn24To48Hrs, isGT48Hrs } = selectedConditions

      // function for handling dwell time checkbox changes
  function handleCdnsCheckBoxChange(field) {
    const data = {
      ...selectedConditions,
      [field]: !selectedConditions[field]
    }
    if (Object.values(data).filter((val)=> val === true).length < 1){
      return
    }
    setSelectedConditions(data)
  }

    useEffect(()=>{
    },[selectedConditions])


    const isDwellTimeSettingsDisabled = ()=> {
        const selectedFences = fencesData.filter((fence)=> fence.isSelected)
        if(selectedFences.length === 0) {
            return true
        }
    }

    return (
        <Row>
            <Col className="dwelltime-configure p-3 mb-5 bg-white">

              <Row>
                <Col xs={2}>
                <div className="d-flex align-items-center flex-row justify-content-between ">
                <div className="alerts-operation alert_text">Dwell Time</div>
                <div className="material-switch ">
                  <input
                    id="switch-dwell-time"
                    name="switch-dwell-time"
                    type="checkbox"
                    disabled={isDwellTimeSettingsDisabled()}
                    checked={!isDwellTimeSettingsDisabled() && isDwellTimeApplicable}
                    onChange={() => {
                        setDwellTimeApplicable(!isDwellTimeApplicable);
                    }}
                    data-testid='dwell-time-checkbox'
                  />
                  <label htmlFor="switch-dwell-time"></label>
                </div>
              </div>
                </Col>
             </Row>
              
                <br></br>

              {
                !isDwellTimeSettingsDisabled() && isDwellTimeApplicable &&

                <div className="col-8">
                <Row>
                  <Row className="col-12 p-0">
                    <div className="col-5  ps-3 pe-0 ms-1">
                      {!isLTE6Hrs && (
                        <IoIosSquareOutline
                          color="#707070"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isLTE6Hrs')
                          }}
                          data-testid='islte-6hrs-checkbox'
                        />
                      )}
                      {isLTE6Hrs && (
                        <IoIosCheckbox
                          color="#1f2a5d"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isLTE6Hrs')
                          }}
                          data-testid='islte-6hrs-checkbox'
                        />
                      )}

                      <label className="dwell-time-cdns-label">
                        {' '}
                        {'<='} 6hrs
                      </label>
                    </div>
                    <div className="col-7 pe-0 ps-3 negative-ml">
                      {!isIn6To12Hrs && (
                        <IoIosSquareOutline
                          color="#707070"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isIn6To12Hrs')
                          }}
                          data-testid='isin-6to12hrs-checkbox'
                        />
                      )}
                      {isIn6To12Hrs && (
                        <IoIosCheckbox
                          color="#1f2a5d"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isIn6To12Hrs')
                          }}
                          data-testid='isin-6to12hrs-checkbox'
                        />
                      )}

                      <label className="dwell-time-cdns-label">
                      {' '}
                        6-12hrs
                      </label>
                    </div>
                  </Row>
                </Row>
                <Row className="mt-2">
                  <Row className="col-12 p-0">
                    <div className="col-5  ps-3 pe-0 ms-1">
                      {!isIn12To24Hrs && (
                        <IoIosSquareOutline
                          color="#707070"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isIn12To24Hrs')
                          }}
                          data-testid='isin-12to24hrs-checkbox'
                        />
                      )}
                      {isIn12To24Hrs && (
                        <IoIosCheckbox
                          color="#1f2a5d"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isIn12To24Hrs')
                          }}
                          data-testid='isin-12to24hrs-checkbox'
                        />
                      )}
                      <label className="dwell-time-cdns-label">
                      {' '}
                        12-24hrs
                      </label>
                    </div>
                    <div className="col-7 pe-0 ps-3 negative-ml">
                      {!isIn24To48Hrs && (
                        <IoIosSquareOutline
                          color="#707070"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isIn24To48Hrs')
                          }}
                          data-testid='isin-24to48hrs-checkbox'
                        />
                      )}
                      {isIn24To48Hrs && (
                        <IoIosCheckbox
                          color="#1f2a5d"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isIn24To48Hrs')
                          }}
                          data-testid='isin-24to48hrs-checkbox'
                        />
                      )}
                      <label className="dwell-time-cdns-label">
                      {' '}
                        24-48hrs
                      </label>
                    </div>
                  </Row>
                </Row>
                <Row className="mt-2">
                  <Row className="col-12 p-0">
                    <div className="col-5  ps-3 pe-0 ms-1">
                      {!isGT48Hrs && (
                        <IoIosSquareOutline
                          color="#707070"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isGT48Hrs')
                          }}
                          data-testid='isgt-48hrs-checkbox'
                        />
                      )}
                      {isGT48Hrs && (
                        <IoIosCheckbox
                          color="#1f2a5d"
                          size={25}
                          onClick={() => {
                            handleCdnsCheckBoxChange('isGT48Hrs')
                          }}
                          data-testid='isgt-48hrs-checkbox'
                        />
                      )}
                      <label className="dwell-time-cdns-label">
                        {' '}
                        {'>'} 48hrs
                      </label>
                    </div>
                  </Row>
                </Row>
              </div>
              }
            </Col>
        </Row>
    )
}

DwellTimeSettingsDiv.propTypes = {
    isDwellTimeApplicable: PropTypes.bool.isRequired,
    selectedConditions: PropTypes.array.isRequired,
    setDwellTimeApplicable: PropTypes.func.isRequired,
    setSelectedConditions: PropTypes.func.isRequired,
    fencesData: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
	isDwellTimeApplicable: state.alertConfigurationScreen.dwellTimeSettings.isDwellTimeApplicable,
    selectedConditions: state.alertConfigurationScreen.dwellTimeSettings.selectedConditions,
    fencesData: state.alertConfigurationScreen.fencesData,
    setDwellTimeApplicable: PropTypes.array.isRequired,
    setSelectedConditions: PropTypes.func.isRequired
});

export default connect(mapStateToProps, {
	setDwellTimeApplicable,
    setSelectedConditions
})(DwellTimeSettingsDiv)