import React, { memo, useEffect, useState } from "react";
import cx from 'classnames';
import Prototype from "prop-types";
import styles from "./BaseModal.module.css";

const BaseModal = ({ isOpen, onClose, title, children, variant, classes }) => {
  const [hoverCloseIcon, setHoverCloseIcon] = useState(false);
  
  useEffect(() => {
    if (!isOpen) {
      setHoverCloseIcon(false)
    }
  }, [isOpen])

  if(!isOpen){
    return <></>
  }
  if (variant === 'auth') {
    return (
      <div className={cx(styles.modalContiner, {[classes.modalContainer]: classes.modalContainer})}>
        <div className={cx(styles.modalWrapper, {[classes.modalWrapper]: classes.modalWrapper})}>
          <div className={`${styles.modalHeader}`}>
            <div className={`${styles.modalTitle}`}><span className="fw-medium">{title}</span></div>
            <div className={`${styles.modalCloseContainer}`}>
              <img
                className={`${styles.modalCrossIcon}`}
                onClick={onClose}
                src={
                  hoverCloseIcon
                    ? '/images/svgicon/trackingScreen/close_active.svg'
                    : '/images/svgicon/trackingScreen/Close_fad.svg'
                }
                alt='close-btn'
                onMouseOver={() => setHoverCloseIcon(true)}
                onMouseOut={() => setHoverCloseIcon(false)}
              />
            </div>
          </div>
          <div className={`${styles.content}`}>
            {children}
          </div>
        </div>
      </div>
    )
  }

 
  if (variant === 'immobilize') {
    return (
      <div className={cx(styles.immobilizeModalContiner, {[classes.modalContainer]: classes.modalContainer})}>
        <div className={cx(styles.immobilizeModalWrapper, {[classes.modalWrapper]: classes.modalWrapper})}>
          <div className={`${styles.immobilizeModalHeader}`}>
              <div className={`${styles.immobilizeModalTitle}`}><span className="fw-medium">{title}</span><span style={{color:'#FF2D55'}}>*</span></div>
              <div className={`${styles.immobilizeModalCloseContainer}`}>
                  <img
                      className={`${styles.immobilizeModalCrossIcon}`}
                      onClick={onClose}
                      src={'/images/immobilizeCrossIcon.svg'}
                      alt='close-btn'
                  />
                 
              </div>
          </div>
          <div className={`${styles.immobilizeModalContent}`}>
            {children}
          </div>
        </div>
      </div>
    );

  } 

  return (
    <div className={cx(styles.defaultModalContiner, {[classes.modalContainer]: classes.modalContainer})}>
      <div className={cx(styles.defaultModalWrapper, {[classes.modalWrapper]: classes.modalWrapper})}>
        <div className={`${styles.defaultModalHeader}`}>
            <div className={`${styles.defaultModalTitle}`}>{title}</div>
            <div className={`${styles.defaultModalCloseContainer}`}>
                <img
                    className={`${styles.defaultModalCrossIcon}`}
                    onClick={onClose}
                    src={
                        hoverCloseIcon
                            ? '/images/svgicon/trackingScreen/close_active.svg'
                            : '/images/svgicon/trackingScreen/Close_fad.svg'
                    }
                    alt='close-btn'
                    onMouseOver={() => setHoverCloseIcon(true)}
                    onMouseOut={() => setHoverCloseIcon(false)}
                />
            </div>
        </div>
        <div className={`${styles.defaultModalContent}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

BaseModal.defaultProps = {
  onClose: () => {},
  isOpen: false,
  title: '',
  variant: 'default',
  classes: {
    modalContainer: '',
    modalWrapper: '',
  }
};
  
BaseModal.prototype = {
  onClose: Prototype.func.isRequired,
  isOpen: Prototype.bool.isRequired,
  title: Prototype.string,
  variant: Prototype.string,
  classes: Prototype.shape({ modalContainer: Prototype.string, modalWrapper: Prototype.string }),
};

export default memo(BaseModal);
/*conflict-resolution-will be removed later*/