import React, { memo, useEffect, useState } from "react";
// import PropTypes from 'prop-types'
import styles from "./userControl.module.css";
import moment from "moment";
import Table from "../../../../shared/table/Table";
import Pagination from "../../../../shared/pagination/Pagination";
import Popover from "../../../../shared/modals/Popover/Popover";
import {
  GetAllDepartmentAdminUsers,
  GetAllDepartmentAdminUsersActiveCount,
  GetAllDepartmentAdminUsersDeactivatedCount,
} from "../../../../../store/actions/department/department";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../../shared/form/SearchInput/SearchInput";
import EditUserControl from "./EditUserControl";
import Dropdown from "../../../../shared/ui/Dropdown/Dropdown";

const UserControl = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortfield] = useState({ sortBy: "", sortOrder: "" });
  const [searchedTerm, setSearchedTerm] = useState("");

  // redux hook
  const dispatch = useDispatch();

  const setPageNumber = (page) => {
    setCurrentPage(page);
  };

  // eslint-disable-next-line
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    editDetails: {}
  })
  const ITEMS_PER_PAGE = 8;

  const DAUserData = useSelector(s => s?.department?.departmentAdminUsers)
  const DAUserDataCount = useSelector(s => s?.department?.departmentAdminUsersCount)

  const DAUserActiveCount = useSelector(s => s?.department?.departmentAdminUsersActiveCount)
  const DAUserDeactivatedCount = useSelector(s => s?.department?.departmentAdminUsersDeactivatedCount)


  // const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;

  const handleCloseEditModal = () => {
    setIsEdit({
      ...isEdit,
      isOpen: false
    })
  }

 

  const sortHandler = (sortBy, sortOrder) => {
    setSortfield({
      sortBy,
      sortOrder,
    });
  };

  const getDepartmentAdminUsers = () => {
    dispatch(
      GetAllDepartmentAdminUsers({
        data: {
          searchKey: searchedTerm,
          pageNumber: currentPage,
          pageSize: ITEMS_PER_PAGE,
          sortByColumn: sortField.sortBy,
          sortOrder: sortField.sortOrder,
        },
      })
    );
  };

  const columns = [
    {
      key: "rId",
      header: "USER ID",
      sortable: false,
      sortFieldName: "rId",
      renderCell: (row) => <>{row?.rId}</>,
    },
    {
      key: "name",
      header: "NAME",
      sortable: true,
      sortFieldName: "name",
      renderCell: (row) => <>{row?.name}</>,
    },
    {
      key: "createdAt",
      header: "CREATED ON",
      sortable: true,
      sortFieldName: "createdAt",
      renderCell: (row) => <>{moment(row?.createdAt).format("DD MMM YYYY")}</>,
    },
    {
      key: "email",
      header: "EMAIL ID",
      sortable: true,
      sortFieldName: "email",
      renderCell: (row) => <>{row?.email}</>,
    },
    {
      key: "departmentName",
      header: "DEPARTMENT",
      sortable: true,
      sortFieldName: "departmentName",
      renderCell: (row) => <>{row?.departmentName}</>,
    },
    {
      key: "location",
      header: "LOCATION",
      sortable: true,
      sortFieldName: "location",
      renderCell: (row) => <>{row?.location}</>,
    },
    {
      key: "accessTemplate",
      header: "ACCESS",
      sortable: false,
      sortFieldName: "accessTemplate",
      renderCell: (row) => {
        return (
          <>
            <span
              style={{
                color: "#39FFFF",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {row?.assignedTemplate}
            </span>
            <Popover
  triggerComponent={
    <button className="row-btn">
      <img
        src="/images/tooltipInfo.svg"
        alt="info"
        className="alert-history-info"
      />
    </button>
  }
  bodyComponent={
    <div className={`${styles.tooltip_inner_container}`}>
      {row?.modules?.map((module) =>  <div key={module?.moduleId}> {module?.moduleName} </div>   )}
    </div>
  }
  trigger={["hover", "focus"]} // Include both "hover" and "focus" triggers
  placement="right-start"
/>

          </>
        );
      },
    },
    {
      key: "isActive",
      header: "STATUS",
      sortable: true,
      sortFieldName: "isActive",
      renderCell: (row) => (
        <span style={{ color: row.isActive === true ? "#69FE97" : "#F29900" }}>
          {row.isActive ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      key: "action",
      header: "ACTION",
      sortable: false,
      renderCell: (row, index) => (
        <Dropdown
          triggerComponent={<img src="/images/verticalThreedots.svg" alt="more menu icon" />}
          dropdownItems={[{
            label: 'Edit',
            value: 'EDIT',
            keyExtractor: () => `edit_${index}`,
            onClick: () => setIsEdit({
              ...isEdit,
              isOpen: true,
              editDetails: row
               })
          }]}
        />
      ),
    },
  ];

  useEffect(() => {
    getDepartmentAdminUsers();
    // eslint-disable-next-line
  }, [searchedTerm, currentPage, sortField]);

  useEffect(() => {

    dispatch(GetAllDepartmentAdminUsersActiveCount())
    dispatch(GetAllDepartmentAdminUsersDeactivatedCount())
  }, [dispatch]);

  return (
    <>
      <div
        className={`${styles.department_container} d-flex col-12 flex-column h-70`}
        style={{
          marginTop: -30,
        }}
      >
        <div className="d-flex justify-content-between  flex-row my-3">
          <div>
            <SearchInput
              setCurrentPage={setCurrentPage}
              setSearchKey={setSearchedTerm}
              placeholder="Search"
            />
          </div>
          <div >
            <div className={styles.usersInfoContainer}>
              <div className={styles.usersInnerText}>
                <div className={styles.usersInfoText}>Active Users</div>
                <div className={styles.usersInfoText}>: {DAUserActiveCount} Nos.</div>
              </div>
              <div className={styles.usersInnerText}  style={{ marginTop:2 }} >
                <div className={styles.usersInfoText}>Deactivated Users</div>
                <div className={styles.usersInfoText}>: {DAUserDeactivatedCount} Nos.</div>
              </div>
            </div>
          </div>
        </div>
        <Table
          data={DAUserData}
          columns={columns}
          handleSortHandler={(e, v) => sortHandler(e, v)}
        />
      </div>
      {DAUserDataCount !== 0 ? (
        <div className={`${styles.paginationContainer} mb-1 position-relative`}>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(DAUserDataCount / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setPageNumber}
          />
        </div>
      ) : (
        <></>
      )}

      <EditUserControl
      isOpen={isEdit.isOpen}
      onClose={() => { setIsEdit({...isEdit, isOpen: false }) }}
      editData={isEdit.editDetails}
      handleCloseEditModal={handleCloseEditModal}
      />

    </>

  );
};
export default memo(UserControl);
