import axios from "axios";
import api from "../../../service/api";
import API_BOOK from "../../../service/endpoints";
import { fileDownloader } from "../../../utils/common-methods";
import ActionTypes from "../../actionTypes";
import { customToast } from '../../../utils/toasts'


export const getImmobilize = (payload) => ({
  type: ActionTypes.IMMOBILIZE.IMMOBILIZE_LIST_SUCCESS,
  payload,
});

export const getMobilize = (payload) => ({
  type: ActionTypes.IMMOBILIZE.MOBILIZE_LIST_SUCCESS,
  payload,
});
export const getHistoryImmobilize = (payload) => ({
  type: ActionTypes.IMMOBILIZE.IMMOBILIZE_HISTORY_LIST_SUCCESS,
  payload,
});

/**
 *  @desc         Immobilize List Data
 *  @author       Ankit Kushwaha
 *  @api
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/
export const fetchImmobilize =
  ({
    searchKey,
    pageIndex,
    pageSize,
    registrationNumber,
    status,
    location,
    nonComIssue,
  }) =>
  async (dispatch) => {
    try {
      const res = await api({
        url: API_BOOK.IMMOBILIZE.IMMOBILIZE_LIST.url,
        method: API_BOOK.IMMOBILIZE.IMMOBILIZE_LIST.method,
        data: {
          searchKey,
          pageNumber: pageIndex,
          registrationNumber,
          pageSize,
          nonCommIssue: nonComIssue,
          status,
          location,
        },
      });

      if (res) {
        dispatch(getImmobilize(res?.data?.data || []));
      }
    } catch (error) {
      console.log("immobilize error", error);
    }
  };

/**
 *  @desc         Export summary immobilize mapping
 *  @author       Ankit Kushwaha
 *  @api         device/mapping/export-summary
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/

export const ExportImmobilize =
  ({ data }) =>
  async () => {
    try {
      const resp = await api({
        method: API_BOOK.IMMOBILIZE.EXPORT_IMMOBILIZE_UPLOAD.method,
        url: API_BOOK.IMMOBILIZE.EXPORT_IMMOBILIZE_UPLOAD.url,
        responseType: "arraybuffer",

        data: {
          timezoneOffset: new Date().getTimezoneOffset(),
          searchKey: data.searchKey,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
          registrationNumber: data.registrationNumber,
          locations: data.location,
          status: data.status,
          nonCommIssue: data.nonComIssue,
        },
      });

      if (resp.status === 200) {
        const response = resp?.data;
        fileDownloader({
          arrayBuffer: response,
          fileName: "Immobilize_logs.xlsx",
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

//   Mobilize start

/**
 *  @desc         mobilize List Data
 *  @author       Ankit Kushwaha
 *  @api
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/
export const fetchMobilize =
  ({
    searchKey,
    pageIndex,
    pageSize,
    registrationNumber,
    status,
    location,
    nonComIssue,
  }) =>
  async (dispatch) => {
    try {
      const res = await api({
        url: API_BOOK.IMMOBILIZE.MOBILIZE_LIST.url,
        method: API_BOOK.IMMOBILIZE.MOBILIZE_LIST.method,
        data: {
          searchKey,
          pageNumber: pageIndex,
          registrationNumber,
          pageSize,
          nonCommIssue: nonComIssue,
          status,
        },
      });

      if (res) {
        dispatch(getMobilize(res?.data?.data || []));
      }
    } catch (error) {
      console.log("mobilize error", error);
    }
  };

/**
 *  @desc         Export mobilize mapping
 *  @author       Ankit Kushwaha
 *  @api         device/mapping/export-summary
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/

export const ExportMobilize =
  ({ data }) =>
  async () => {
    try {
      const resp = await api({
        method: API_BOOK.IMMOBILIZE.EXPORT_MOBILIZE_UPLOAD.method,
        url: API_BOOK.IMMOBILIZE.EXPORT_MOBILIZE_UPLOAD.url,
        responseType: "arraybuffer",
        data: {
          timezoneOffset: new Date().getTimezoneOffset(),
          searchKey: data.searchKey,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
          registrationNumber: data.registrationNumber,
          locations: data.location,
          status: data.status,
          nonCommIssue: data.nonComIssue,
          vehicleStatuses: data.vehicleStatuses
        },
      });

      if (resp.status === 200) {
        const response = resp?.data;
        fileDownloader({
          arrayBuffer: response,
          fileName: "Mobilize_logs.xlsx",
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

//  History  imMobilize start

/**
 *  @desc         mobilize List Data
 *  @author       Ankit Kushwaha
 *  @api
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/
export const fetchHistoryImmobilize =
  ({ searchKey, pageIndex, pageSize, registrationNumber, status }) =>
  async (dispatch) => {
    try {
      const res = await api({
        url: API_BOOK.IMMOBILIZE.IMMOBILIZE_HISTORY_LIST.url,
        method: API_BOOK.IMMOBILIZE.IMMOBILIZE_HISTORY_LIST.method,
        data: {
          searchKey,
          pageNumber: pageIndex,
          registrationNumber,
          pageSize,
          mobilizationStatus: status,
        },
      });

      if (res) {
        dispatch(getHistoryImmobilize(res?.data?.data || []));
      }
    } catch (error) {
      console.log("mobilize error", error);
    }
  };

/**
 *  @desc         Export mobilize mapping
 *  @author       Ankit Kushwaha
 *  @api         device/mapping/export-summary
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/

export const ExportHistoryImmobilize =
  ({ data }) =>
  async () => {
    try {
      const resp = await api({
        method: API_BOOK.IMMOBILIZE.EXPORT_IMMOBILIZE_HISTORY_UPLOAD.method,
        url: API_BOOK.IMMOBILIZE.EXPORT_IMMOBILIZE_HISTORY_UPLOAD.url,
        responseType: "arraybuffer",

        data: {
          timezoneOffset: new Date().getTimezoneOffset(),
          searchKey: data.searchKey,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
          registrationNumber: data.registrationNumber,
          locations: data.location,
          status: data.status,
          nonComIssue: data.status,
        },
      });

      if (resp.status === 200) {
        const response = resp?.data;
        fileDownloader({
          arrayBuffer: response,
          fileName: "History_logs.xlsx",
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

// Reason immobilize

/**
 *  @desc         Reason mobilize
 *  @author       Ankit Kushwaha
 *  @api
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/



 export const fetchReasonImmobilize =({data}) => async() => {
    try {
      
      const res = await axios({
        url:  API_BOOK.IMMOBILIZE.REASON_IMMOBILIZE_ACTION.url,
        method: API_BOOK.IMMOBILIZE.REASON_IMMOBILIZE_ACTION.method,
        data: {
          reasonType: data.reasonType,
          reasonMessage: data.reasonOtherInput,
          IMEI: data.imei,
          command: "Immobilize",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      if(res.status === 200){
        console.log('Success', res)
      }

    } catch (error) {
      if(error.response.data.message === "Remote device address is not found!") {
        setTimeout(() => {
          customToast({ message:error.response.data.message , svgImageName: 'decline' })
        }, 400)
      }
      
    }
  };

// Reason immobilize

/**
 *  @desc         Reason mobilize
 *  @author       Ankit Kushwaha
 *  @api
 *  @method       POST
 *  @createdDate  08-JULY-2024
 *  @modifiedDate
 **/


  export const fetchReasonMobilize =({data}) => async() => {
    try {
      
      const res = await axios({
        url:  API_BOOK.IMMOBILIZE.REASON_IMMOBILIZE_ACTION.url,
        method: API_BOOK.IMMOBILIZE.REASON_IMMOBILIZE_ACTION.method,
        data: {
          reasonType: data.reasonType,
          reasonMessage: data.reasonOtherInput,
          IMEI: data.imei,
          command: "Mobilize",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      if(res.status === 200){
        console.log('Success', res)
      }

    } catch (error) {
      if(error.response.data.message === "Remote device address is not found!") {
        setTimeout(() => {
          customToast({ message:error.response.data.message , svgImageName: 'decline' })
        }, 400)
      }
      
    }
  };



  
