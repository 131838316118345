import { Row, Col, Form } from 'react-bootstrap'
import SingleSelect from '../../../helpers/hoc/single-select/SingleSelect'
import { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

const FenceOwnerEditRow = (
        {
            rowKey,
            labelOwnersSaved,
            preSelectedLabelOwnerId, 
            fenceOwnerName,
            labelOwnerOnChange,
            fenceOwnerNameOnChange,
            onDelete,
            isEditable,
            setEdit,
            isLabelOwnerSelectionValid,
            isFenceOwnerNameValid,
            labelOwnerSelectionValidationMessage,
            fenceOwnerNameValidationMessage
        }
    )=> {

    const [labelOwnersDropdownValues, setLabelOwnersDropdownValues] = useState([])

    useEffect(()=>{
        const dropdownValues = labelOwnersSaved.map(labelOwner => { 
            return {
            label: labelOwner.label,
            value: labelOwner._id
            }
        })
        setLabelOwnersDropdownValues(dropdownValues)
    }, [labelOwnersSaved])

    const selectHandler = (data) => {
        labelOwnerOnChange(data.value, rowKey)
    }

    const handleLabelOwnerNameChange =(e)=> {
        fenceOwnerNameOnChange(e.target.value, rowKey)
    }

    const deleteFenceOwner = ()=> {
        onDelete(rowKey)
    }

    return (
        <Row style={{marginBottom:10}}>
            <Col className='geofence-configure-sub-text-fence-owner-text' xs={2}>
                Label Owner
            </Col>
            <Col xs={2}>
                <SingleSelect
                data-testid='fenceowner-edit-labelowner-select'
                disabled = {!isEditable}
                placeholder=""
                placeholderOnError=""
                valueContainerWidthInPx="75px"
                valueContainerMaxWidthInPx="75px"
                optionPosition="ps-2 justify-content-start"
                customFontsize='12px'
                options={labelOwnersDropdownValues}
                selectedOptions={
                    labelOwnersDropdownValues && labelOwnersDropdownValues.filter(option => 
                       option.value === preSelectedLabelOwnerId)
                 }
                onSelectHandler={selectHandler}
                maxMenuHeight={140}
                customBackgroundColor="#F5F7F9"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="flex-start"
                />
                {
                    !isLabelOwnerSelectionValid &&
                    <div className='text-danger error-message'>{labelOwnerSelectionValidationMessage}</div>
                }
            </Col>

            <Col className='geofence-configure-sub-text-fence-owner-text' xs={2}>
                Fence Owner Name
            </Col>
            <Col xs={2}>
                <Form.Group controlId="textBox">
                    <Form.Control
                    className='geofence-configure-placeholder-fence-owner'
                    disabled = {!isEditable}
                    value={fenceOwnerName}
                    onChange={handleLabelOwnerNameChange}
                    type="text" 
                    placeholder="Enter text"
                    data-testid='fenceowner-edit-name-input'
                    />
                </Form.Group>
                {
                    !isFenceOwnerNameValid &&
                    <div className='text-danger error-message'>{fenceOwnerNameValidationMessage}</div>
                }
            </Col>
            <Col xs={4} className="d-flex geofence-configure-sub-text-edit-delete-fence-owner">


            <div className='geofence-configure-sub-text-edit-delete-fence-owner' data-testid='fenceowner-delete-button' onClick={deleteFenceOwner}>
                <img
                alt={'delete'}
                src='/images/svgicon/delete_button.svg'
                style={{width:"25%", marginLeft:10, marginRight:5}}
                ></img>
                Delete          
            </div>
                 
            <div className='geofence-configure-sub-text-edit-delete-fence-owner' data-testid='fenceowner-edit-button' onClick={() => {setEdit(rowKey)}}>
                <img
                alt={'delete'}
                src='/images/svgicon/edit_button.svg'
                style={{width:"25%", marginLeft: 10, marginRight:5}}
                ></img>
                Edit        
            </div>
            </Col>
        </Row>
    )
}

FenceOwnerEditRow.propTypes = {
	// Actions
	// States
    labelOwnersSaved: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    labelOwnersSaved: state.parameterConfiguration.labelOwnersSaved,
});

export default connect(mapStateToProps, {
})(FenceOwnerEditRow)