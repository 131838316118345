import api from "../../../../../service/api"
import API_BOOK from "../../../../../service/endpoints"
import { customToast } from "../../../../../utils/toasts"

const geoFenceGetAllCitiesConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_CITIES
const geoFenceGetAllLabelsConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_LABELS
const geoFenceGetAllLabelOwnerConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_LABEL_OWNERS
const geoFenceGetAllFenceOwnerConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_FENCE_OWNERS
const getAllFencesConfig = API_BOOK.GEO_FENCE.GET_ALL_FENCES
const saveAlertsConfigurationConfig = API_BOOK.GEO_FENCE.SAVE_ALERTS_CONFIGURATION
const { GET_SUBSCRIBED_RN_NUMS} = API_BOOK.VEHICLE



 /**
 *  @desc         Telematics API for loading all cities
 *  @author       Kailash Desiti
 *  @api          /geo-fence/get-distinct-cities
 *  @method       GET
 *  @createdDate   AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadAllCities = () => async (dispatch) => {
    try {
        const resp = await api({
            method: geoFenceGetAllCitiesConfig.method,
            url: geoFenceGetAllCitiesConfig.url,
          })
        if (resp.status === 200) {
          const response = resp?.data.data;
          const dropdownData = response.map((city)=>{
            return {
                label: city,
                value: city
            }
          })
          dispatch({type: 'geofenceAlertConfig.citiesLoaded', payload: dropdownData})
        }
      } catch (error) {
        console.log("error:", error);
    }
}


/**
 *  @desc         Telematics API for loading all labels
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/labels
 *  @method       GET
 *  @createdDate   AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadAllLabels = () => async (dispatch) => {
    try {
        const resp = await api({
            method: geoFenceGetAllLabelsConfig.method,
            url: geoFenceGetAllLabelsConfig.url,
          })
        if (resp.status === 200) {
          const response = resp?.data.data;
          const dropdownData = response.map(labelMaster=> {
            return {
                label: labelMaster.label,
                value: labelMaster._id
            }
          })
          dispatch({type: 'geofenceAlertConfig.labelsLoaded', payload: dropdownData})
        }
      } catch (error) {
        console.log("error:", error);
    }
}


/**
 *  @desc         Telematics API for loading all label owners
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/labels
 *  @method       GET
 *  @createdDate   AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadAllLabelOwners = () => async (dispatch) => {
    try {
        const resp = await api({
            method: geoFenceGetAllLabelOwnerConfig.method,
            url: geoFenceGetAllLabelOwnerConfig.url,
          })
        if (resp.status === 200) {
          const response = resp?.data.data;
          const dropdownData = response.map(labelOwner=> {
            return {
                label: labelOwner.label,
                value: labelOwner._id
            }
          })
          dispatch({type: 'geofenceAlertConfig.labelOwnersLoaded', payload: dropdownData})
        }
      } catch (error) {
        console.log("error:", error);
    }
}


/**
 *  @desc         Telematics API for loading all fence owners
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/fence-owners
 *  @method       GET
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadAllFenceOwners = () => async (dispatch) => {
    try {
        const resp = await api({
            method: geoFenceGetAllFenceOwnerConfig.method,
            url: geoFenceGetAllFenceOwnerConfig.url,
          })
        if (resp.status === 200) {
          const response = resp?.data.data;
          const dropdownData = response.map(fenceOwnerMaster=> {
            return {
                label: fenceOwnerMaster.label,
                value: fenceOwnerMaster._id
            }
          })
          dispatch({type: 'geofenceAlertConfig.fenceOwnersLoaded', payload: dropdownData})
        }
      } catch (error) {
        console.log("error:", error);
    }
}


/**
 *  @desc         Telematics API for loading all vehicles
 *  @author       Kailash Desiti
 *  @api          /vehicle/tracking/subscribed
 *  @method       GET
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/


export const loadAllVehicles = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_SUBSCRIBED_RN_NUMS.method,
      url: GET_SUBSCRIBED_RN_NUMS.url,
    })

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data?.map((val) => {
        const obj = {
          value: val._id,
          label: val.registration_number,
        }
        return obj
      })
      dispatch({type: 'geofenceAlertConfig.allVehiclesLoaded', payload: response})
    }
  } catch (error) {
    console.log('error:', error)
  }
}

export const setSelectedCities = (selectedCities) => (dispatch) => {
    dispatch({type: 'geofenceAlertConfig.setSelectedCities', payload: selectedCities})
}

export const setSelectedLabels = (selectedLabels) => (dispatch) => {
    dispatch({type: 'geofenceAlertConfig.setSelectedLabels', payload: selectedLabels})
}

export const setSelectedLabelOwners = (selectedLabelOwners) => (dispatch) => {
    dispatch({type: 'geofenceAlertConfig.setSelectedLabelOwners', payload: selectedLabelOwners})
}

export const setSelectedFenceOwners = (setSelectedFenceOwners) => (dispatch) => {
    dispatch({type: 'geofenceAlertConfig.setSelectedFenceOwners', payload: setSelectedFenceOwners})
}

export const selectFence = ({fenceId, fencesData}) => (dispatch) => {
    const fenceDataCopy = [...fencesData]
    const fence = fenceDataCopy.find(x=>x.fenceId === fenceId)
    fence.isSelected = true
    dispatch({type: 'geofenceAlertConfig.fencesLoaded', payload: fenceDataCopy})
}

export const unselectFence = ({fenceId, fencesData}) => (dispatch) => {
    const fenceDataCopy = [...fencesData]
    const fence = fenceDataCopy.find(x=>x.fenceId === fenceId)
    fence.isSelected = false
    dispatch({type: 'geofenceAlertConfig.fencesLoaded', payload: fenceDataCopy})
}

export const selectAll = (flag) => (dispatch) => {
    if(flag)
        dispatch({type: 'geofenceAlertConfig.selectAll', payload: setSelectedFenceOwners})
    else
        dispatch({type: 'geofenceAlertConfig.unselectAll', payload: setSelectedFenceOwners})
}

/**
 *  @desc         Telematics API for getting all fences
 *  @author       Kailash Desiti
 *  @api          /geo-fence/getAllFences
 *  @method       POST
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const getAllFences = ({ 
        selectedCities, 
        selectedLabels, 
        selectedLabelOwners, 
        selectedFenceOwners, 
        fencesData
    }) => async (dispatch) => {
    try {
      const resp = await api({
        method: getAllFencesConfig.method,
        url: getAllFencesConfig.url,
        data: {
          cities: selectedCities.map(x=> x.value),
          labels: selectedLabels.map(x=> x.value),
          labelOwners: selectedLabelOwners.map(x=> x.value),
          fenceOwners: selectedFenceOwners.map(x=> x.value)
         }
      });
    
      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data
        const data = response.map(fenceData=> {
            const existing = fencesData.find(x=>x.fenceId === fenceData._id)
            let isSelected = false
            let label = ""
            let labelOwner = ""
            let fenceOwner = ""

            if(existing)
                isSelected = existing.isSelected

            if(fenceData.populated_label && fenceData.populated_label.length > 0)
                label = fenceData.populated_label[0].label
            
            if(fenceData.populated_label_owner && fenceData.populated_label_owner.length > 0)
                labelOwner = fenceData.populated_label_owner[0].label
            
            if(fenceData.populated_fence_owner && fenceData.populated_fence_owner.length > 0)
                fenceOwner = fenceData.populated_fence_owner[0].label
            

            return {
                isSelected,
                fenceId: fenceData._id,
                name: fenceData.fence_name,
                city: fenceData.fence_identifiers.city,
                label,
                labelOwner,
                fenceOwner
            }
        })
        dispatch({type: 'geofenceAlertConfig.fencesLoaded', payload: data})
      } else {
        //dispatch(getAllChargeSessionsFailAD());
      }
    } catch (error) {
      //dispatch(getAllChargeSessionsFailAD());
      console.log("error:", error);
    }
}

export const setEmailsForNotification = (emails) => (dispatch)=> {
  dispatch({type: 'geofenceAlertConfig.setEmailsToNoify', payload: emails})
}

export const setSelectedConditions = (conditions) => (dispatch) => {
  dispatch({type: 'geofenceAlertConfig.dwellTimeSetting.setSelectedConditions', payload: conditions})
}

export const setDwellTimeApplicable = (flag) => (dispatch) => {
  dispatch({type: 'geofenceAlertConfig.dwellTimeSetting.setDwellTimeApplicable', payload: flag})
}


/**
 *  @desc         Telematics API for Saving alerts Config
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/alerts
 *  @method       POST
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const saveAlertsConfiguration = ({stateData}) => async (dispatch) => {
    const postData = {
      fenceIds: stateData.fencesData.filter(x=>x.isSelected).map(x=> x.fenceId),
      ingressSettings: stateData.ingressSettings,
      egressSettings: stateData.egressSettings,
      dwellTimeSettings: stateData.dwellTimeSettings,
      emailsForNotification: stateData.emailsForNotification
    }
    
    try {
      const resp = await api({
        method: saveAlertsConfigurationConfig.method,
        url: saveAlertsConfigurationConfig.url,
        data: postData
      });
      if (resp.status === 200) {
          setTimeout(() => {
              customToast({ message: "Successfully Saved", svgImageName: 'promptSuccess' })
          }, resp.status)
          dispatch({type: 'geofenceAlertConfig.resetAlertConfiguration', payload: false})
      }
      else{
          setTimeout(() => {
              customToast({ message: resp.data.message, svgImageName: 'decline' })
          }, resp.status)
      }
    } catch (error) {
      console.log("error:", error);
    }
};


