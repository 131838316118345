import api from "../../service/api";
import ActionTypes from "../actionTypes";
import API_BOOK from "../../service/endpoints";
import { fileDownloader, getFormattedDate } from "../../utils/common-methods";

const notificationPanelDispatcher = (actionType, payload) => ({
    type: actionType,
    payload,
});

export const closeModal = () => ({
    type: ActionTypes.NOTIFICATION_PANEL.SET_MODAL_CLOSE,
})


  export const openModal = (name) => ({
    type: ActionTypes.NOTIFICATION_PANEL.SET_MODAL_OPEN,
    payload:  name 
})

const actions = ActionTypes.NOTIFICATION_PANEL;
const actionTypes = API_BOOK.NOTIFICATION_PANEL;

/**
 *  @desc         get user unread notification data
 *  @author       Parvez Siddique
 *  @api          /notifications/getAllUnreadNotifications
 *  @method       Get
 *  @createdDate  13-Oct-2023
 *  @modifiedDate 13-Oct-2023
 **/

export const GetUnreadNotificationData = () => async (dispatch) => {
    try {
        const resp = await api({
            method: actionTypes.GET_NOTICATION_DATA_BY_USER_UNREAD.method,
            url: actionTypes.GET_NOTICATION_DATA_BY_USER_UNREAD.url,
            manageLoader : false
          })
        if (resp.status === 200 && resp.data) {
            const response = resp?.data
            dispatch(notificationPanelDispatcher(actions.GET_NOTIFICATION_DATA_BY_USER, response.data))
        }
    } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         get user all notification data
 *  @author       Parvez Siddique
 *  @api          /notifications/getAllNotifications 
 *  @method       Get
 *  @createdDate  13-Oct-2023
 *  @modifiedDate 13-Oct-2023
 **/
export const GetAllNotificationData = () => async (dispatch) => {
    try {
        const resp = await api({
            method: actionTypes.GET_NOTICATION_DATA_BY_USER_ALL.method,
            url: actionTypes.GET_NOTICATION_DATA_BY_USER_ALL.url,
            manageLoader : false
          })
        if (resp.status === 200 && resp.data) {
            const response = resp?.data
            dispatch(notificationPanelDispatcher(actions.GET_NOTIFICATION_DATA_BY_USER, response.data))
        }
    } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         Download export file 
 *  @author       Parvez Siddique
 *  @api          /serviceHistory/downloadDoc
 *  @method       POST
 *  @createdDate  13-Oct-2023
 *  @modifiedDate 13-Oct-2023
 **/
export const DownloadFile = ({fileKey,fileName}) => async(dispatch) =>{
    try {
        const resp = await api({
            method: actionTypes.DOWNLOAD_FILE.method,
            url: actionTypes.DOWNLOAD_FILE.url,
            responseType: 'arraybuffer',
            manageLoader : false,
            data : {
                dmsKey : fileKey
            },
            onDownloadProgress : (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(notificationPanelDispatcher(actions.FILE_DOWNLOAD_PROGRESS, {percentCompleted, fileKey }))
            }
          })
        if (resp.status === 200 && resp.data) {
            const response = resp?.data
            fileDownloader({ arrayBuffer: response, fileName: `${fileName}${getFormattedDate(new Date()).replace(/,/g, '' ).replace(':', ' ').split(' ').join('_')}.xlsx` })
        }
    } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         Mark notifications as read
 *  @author       Parvez Siddique
 *  @api          /notifications/markNotificationAsRead 
 *  @method       POST
 *  @createdDate  13-Oct-2023
 *  @modifiedDate 13-Oct-2023
 **/
export const MarkNotificationAsRead = ({ ids }) => async (dispatch) => {
    try {
        if (ids) {
            const resp = await api({
                method: actionTypes.MARK_AS_READ.method,
                url: actionTypes.MARK_AS_READ.url,
                manageLoader : false,
                data: {
                    notificationIds: ids
                }
            })
            if (resp.status === 200) {
                setTimeout(()=>{
                    dispatch(notificationPanelDispatcher(actions.MARK_AS_READ_SUCCESS, ids))
                },4000)
            }
        }

    } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         Show notification panel
 **/
 export const CurrentExportedDataNotification = (notification) => async(dispatch) =>{
    dispatch(notificationPanelDispatcher(actions.CURRENT_EXPORTED_DATA_NOTIFICATION,notification))
}

/**
 *  @desc         Show notification panel
 **/
export const ShowNotificationPanel = () => async(dispatch) =>{
    dispatch(notificationPanelDispatcher(actions.SHOW_NOTIFICATION_PANEL))
}

/**
 *  @desc         START NOTIFICATION CHECK
 **/
export const StartNotification = () => async(dispatch) =>{
    dispatch(notificationPanelDispatcher(actions.START_NOTIFICATION))
}

/**
 *  @desc         STOP NOTIFICATION CHECK
 **/
export const StopNotification = () => async(dispatch) =>{
    dispatch(notificationPanelDispatcher(actions.STOP_NOTIFICATION))
}