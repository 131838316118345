import { FileUploader } from "react-drag-drop-files";
import "./imageDrawer.css";
import AddPhotoIcon from "../../../../../assets/topPaneIcons/geofenceAddPhoto.svg";
const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "BMP", "WEBP", "TIFF"];

const ImageDrawer = ({ setFile, ...props }) => {
  const baseTestId = props['data-testid'] || 'image-drawer'
  const handleChange = (file) => {
    setFile(file);
  };

  const onFileDropCss = {
    display: "none",
  };

  return (
    <>
      <FileUploader
        data-testid={baseTestId}
        multiple={false}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        dropMessageStyle={onFileDropCss}
        children={
          <div className="file-drawer-geofence">
            <div className="d-flex flex-row align-items-center ">
              <img src={AddPhotoIcon} alt="" title="" />
            </div>
            <div className="d-flex flex-row align-items-center">
              Drag & Drop or <div className="browse-div-geofence">Browse</div> your files
            </div>
          </div>
        }
      />
    </>
  );
};

export default ImageDrawer;
