import React from "react";
import "./NDVehicleInfoCanStatusDark.css";

const NDVehicleInfoCanStatus = (props) => {
  const {
    gpsSyncTime,
    canSyncTime,
    // batteryTemperature,
    nonCommunication,
    soh,
    motorTemperature,
    // batteryCurrent,
    currentBatteryCapacity,
    controllerTemperature,
  } = props;

  const vehicleInfoState = (value) => {
    if ([null, undefined].includes(value)) {
      return "NA";
    }
    if (value === 0) {
      return "EMPTY";
    }
    if (value < 100) {
      return "PARTIAL";
    }
    if (value === 100) {
      return "FULL";
    }
  };

  

  const vehicleInfo = {
    headers: [
      {
        title: "GPS Sync Time: " + (gpsSyncTime ? gpsSyncTime : "NA"),
        color: nonCommunication ? "#E40B0B" : "",
        borderRight: "1px solid #9E9E9E",
        fontSize: "10px",
      },
      {
        title: "CAN Sync Time: " + (canSyncTime ? canSyncTime : "NA"),
        color: nonCommunication ? "#E40B0B" : "",
        fontSize: "10px",
      },
    ],
    data: {
      information: [
        {
          src: "/images/svgicon/vehicleDetailsIcons/stateofhealthfull.svg",
          value:
            vehicleInfoState(soh) === "NA"
              ? "NA"
              : `${soh ? Math.trunc(soh) : soh}`,
          color: "nd__green",
          title: "State Of Health (%)",
        },
        // {
        //   src: "/images/svgicon/vehicleDetailsIcons/batteryTempfull.svg",
        //   value:
        //     vehicleInfoState(batteryTemperature) === "NA"
        //       ? "NA"
        //       : `${
        //           batteryTemperature
        //             ? Math.trunc(batteryTemperature)
        //             : batteryTemperature
        //         }℃`,
        //   color: "nd__red",
        //   title: "Battery Temperature",
        // },
        {
          src: "/images/svgicon/vehicleDetailsIcons/motorTempfull.svg",
          value:
            vehicleInfoState(motorTemperature) === "NA"
              ? "NA"
              : `${
                  motorTemperature
                    ? Math.trunc(motorTemperature)
                    : motorTemperature
                }`,
          color: "nd__yellow",
          title: "Motor Temperature (℃)",
        },
        // {
        //   src: "/images/svgicon/vehicleDetailsIcons/BatteryCurrentfull.svg",
        //   value:
        //     vehicleInfoState(batteryCurrent) === "NA"
        //       ? "NA"
        //       : `${
        //           batteryCurrent ? Math.trunc(batteryCurrent) : batteryCurrent
        //         }A`,
        //   color: "nd__blue",
        //   title: "Battery Current",
        // },
        {
          src: "/images/svgicon/vehicleDetailsIcons/CurrentBatteryCapacityfull.svg",
          value:
            !currentBatteryCapacity
              ? "NA"
              : `${
                  parseFloat(currentBatteryCapacity).toFixed(1)
                }`,
          color: "nd__blue",
          title: "Battery Capacity (kWh)",
        },
        {
          src: "/images/svgicon/vehicleDetailsIcons/bmsTemperaturefull.svg",
          value:
            vehicleInfoState(controllerTemperature) === "NA"
              ? "NA"
              : `${
                  controllerTemperature
                    ? Math.trunc(controllerTemperature)
                    : controllerTemperature
                }`,
          color: "nd__blue",
          title: "BMS Temperature (℃)",
        },
      ],
    },
  };

  return (
    <>
      <div
        className={"nd__dark-vehicle-info-can-status-card  nd__dark-info-can-status-card-half-border" }
      >
        <div className="nd__vehicle-info-can-status-header container-fuild  ">
          <div className="row ">
            {vehicleInfo.headers.map((h, i) => (
              <div
                className="col-6"
                key={"col-3-key-header" + i}
                style={{
                  color: h.color,
                  borderRight: h.borderRight,
                  fontSize: h.fontSize,
                }}
              >
                {h.title}
              </div>
            ))}
          </div>
        </div>

        <div className="nd__dark-vi-line"></div>

        <div className="nd__dark-vehicle-info-can-status-content container-fuild">
          <div
           className="row align-items-stretch"
           style={{
            display:'flex',
            justifyContent:'center'
           }}
          >
            {vehicleInfo.data.information.map((item, index) => (
              <div className="col-5 px-2 mt-2" key={`vehicle-info-${index}`}>
                <div className="nd-vc-info-status-perc-in-container">
                  <div className="nd-vc-info-status-perc-in ">
                    <img
                      className="nd-vc-info-status-image"
                      src={item.src}
                      alt={item.title}
                    />
                    <div className="ndvi__can-box-body">
                      <span
                        style={{
                          color: "rgba(51, 232, 255, 1)",
                          fontSize:'10px',
                        }}
                      >
                        {item.value}
                      </span>
                    </div>
                  </div>
                  </div>
                <div className="ndvi__can__title" >
                  <span className="ndvi__can__title_span" >{item.title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NDVehicleInfoCanStatus;
