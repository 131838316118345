import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./imageCarouselComponent.css";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import GeofenceImageUploadModel from "../imageUploadModel/imageUploadModel.js";

function ImageSrc({ imageUrl, index }) {
  const [loading, setLoading] = useState(true);
  const [setImgSrc] = useState(null);


  const fetchImage = React.useCallback(
    (imageUrl) => {
      axios({
        url: `/geo-fence/image-get/${imageUrl}`,
        method: "GET",
        headers: { Authorization: `Bearer ${localStorage.token}` },
      })
        .then((response) => {
          setLoading(false);

          setImgSrc(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [setImgSrc]
  );

  React.useEffect(() => {
    fetchImage(imageUrl);
  }, [fetchImage, imageUrl]);

  if (loading) return <></>;

  return (
    <>
      <img
        className="d-block w-100"
        src={`${process.env.REACT_APP_HOST_API_URL}/geo-fence/image-get/${imageUrl}`}
        alt={`Slide ${index}`}
        width="100%"
        height="200px"
      />
    </>
  );
}

function ImageCarousel({ fence_images,fenceId }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };
  useEffect(() => {
    setImageCount(fence_images.length);
  }, [fence_images]);
  return (
    <>
      <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
        {fence_images.map((imageUrl, index) => (
          <Carousel.Item key={index}>
            <ImageSrc key={imageUrl} index={index} imageUrl={imageUrl} fenceId={fenceId} />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="image-count" >
        {activeIndex + 1}/{imageCount}
      </div>
      <div
        className="plus-icon d-flex justify-content-end"
        onClick={() => {
          isShowModal ? setIsShowModal(false) : setIsShowModal(true);
        }}
        data-testid='details-add-photo-button'
      >
        <FaPlus className="fa-icon-plus" />
      </div>
      {isShowModal && (
        <GeofenceImageUploadModel
          fenceId={fenceId}
          showMoal={isShowModal}
          setIsShowModal={setIsShowModal}
        />
      )}
    </>
  );
}

export default ImageCarousel;
