import React from 'react';
import Form from 'react-bootstrap/Form';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const SubMenuPopup = ({ onMouseEnter, onMouseLeave, id, items, showPopup }) => {
	const menuTopMargin = 100;
	const menuBoxHeight = 58;


	const dispatch = useDispatch();
	const selectedCity = useSelector((s) => s?.cityLocationSelectReducer?.selectedCity);
	const activePagePath = useLocation();
	const calculateArrowPosition = (id) => {
		const position = menuTopMargin + (id - 1) * menuBoxHeight;
		return position;
	};

	const calculateDivHeight = (items) => {
		return (items.length <= 1 ? 2 : items.length) * 30;
	};

	const calculateDivWidth = () => {
		return 'max-content';
	};

	const calculateDivPosition = (id) => {
		const position = menuTopMargin + (id * menuBoxHeight - 22.5);
		return position - 26;
	};

	let subMenuState = {
		arrowPosition: calculateArrowPosition(id),
		showPopup: showPopup,
		subMenuDivHeight: calculateDivHeight(items),
		subMenuWidth: calculateDivWidth(),
		divTopPosition: calculateDivPosition(id),
		items: items,
	};

	return (
		<>		
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className='popover-container'
				style={{
					top: subMenuState.arrowPosition + 'px',
					height: subMenuState.subMenuDivHeight,
					display: subMenuState.showPopup ? 'block' : 'none',
				}}
			>
				<div className='arrow' style={{ display: subMenuState.showPopup ? 'block' : 'block' }}></div>
				<div
					className='custom-div'
					style={{
						height: subMenuState.subMenuDivHeight + 'px',
						display: subMenuState.showPopup ? 'block' : 'none',
						width: subMenuState.subMenuWidth,
					}}
				>
					<ul style={{ paddingLeft: '5px' }}>
						{items.map((item, index) => {
							return (
								<React.Fragment key={index}>
									{item.isSelect ? (
										<li>
											<Form.Check
												className='location-checkbox'
												type='radio'
												label={<span className='submenu-label'>{item.name}</span>}
												id={index + 1}
												name='selectCity'
												value={item.name}
												checked={selectedCity === item.name}
												onChange={() => {
													dispatch({ type: 'location_select', payload: item.name });
												}}
											/>
										</li>
									) : (
										<NavLink
											to={item.path}
											key={index + 1}
											className={`sub-link ${
												activePagePath.path === item.path ? 'active-submenu' : ''
											}`}
											{...{ 'data-testid': item.name !== 'Logout' ? `sidebar-submenu-link-${item.name}` : undefined }}
										>
											{item.name === 'Logout' ? 
										<li
										className={`submenu-label ${
											activePagePath.pathname === item.path ? 'active-submenu' : ''
										}`}
										style={{ padding: '2px', fontSize: '11px' }}
										onClick={() => dispatch({type:'logoutClicked', payload:true})}
										data-testid='sidebar-submenu-logout'
									>
										{item.name}
									</li>
									:
									<li
												className={`submenu-label ${
													activePagePath.pathname === item.path ? 'active-submenu' : ''
												}`}
												style={{ padding: '2px', fontSize: '11px' }}
											>
												{item.name}
											</li>	
										}
										</NavLink>
									)}
								</React.Fragment>
							);
						})}
					</ul>
				</div>
			</div>
		</>
	);
};

export default SubMenuPopup;
