// External packages import
import React, { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io'
import RSelect, { components } from 'react-select'
import { Close } from '@rsuite/icons'
import DownArrow from '../../../../assets/darkThemeAssets/ChevrondownArrow.svg'
import UpArrow from '../../../../assets/darkThemeAssets/ChevronUpArrow.svg'

// Internal components import
// import Input from '../Input/Input'

// Style import
import styles from './Select.module.css'

const Select = ({ options, value, onChange, label, required, error, placeholder, isMultiSelect, isSearchable, labelStyle, isClearable, isDusignUp = false, isDepart=false, menuMaxheight='185px', ...props }) => {

    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)

    const selectRef = useRef(null)

    const baseTestId = props['data-testid'] || 'multiselect'

    const allSelected = value?.length === options?.length;


    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])


    const DropdownIndicator = () => {
        return <></>
    }



    const handleSelectAllToggle = () => {
        if (allSelected) {
            onChange([]);
        } else {
            onChange(options);
        }
    };


    const toggleSelectMenuOpenClose = (bool) => {
        setIsSelectMenuOpen(bool)
    }


    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            toggleSelectMenuOpenClose(false)
            return
        }
    }

    const handleClick = (event) => {
        if (isMultiSelect) {
            event.stopPropagation()
        }
        toggleSelectMenuOpenClose(true)
    }

    const Control = useCallback(({ children, ...props }) => {
        return (
            <components.Control {...{ ...props, innerProps: { ...props.innerProps, 'data-testid': `${baseTestId}-control` } }}>
                <img src='/images/searchIcon.svg' alt='searchIcon' className={cx(styles.searchIcon)} />
                {children}
            </components.Control>
        )
    }, [baseTestId])

    const Option = useCallback((props) => {
        return (
            <div className={cx("select-option-div", styles.optionWrapper)}>
                <components.Option {...{ ...props, innerProps: { ...props.innerProps, 'data-testid': `${baseTestId}-option-${props.label}` } }}>
                    {isMultiSelect ? (
                        <div
                            className={cx(`d-flex flex-row align-items-center justify-content-start gap-2 w-100 pb-1`, styles.optionMultiSelect)}
                        >
                            <div
                                className={`d-flex flex-row align-items-center ps-2  justify-content-start gap-2 w-50`}
                            >
                                {!props.isSelected && (
                                    <div style={{ cursor: 'pointer', marginBottom: 5 }}>
                                        <IoIosSquareOutline color="#fff" size={20} />
                                    </div>
                                )}
                                {props.isSelected && (
                                    <div style={{ cursor: 'pointer', marginBottom: 5 }}>
                                        <IoIosCheckbox color="#268FE9" size={20} />
                                    </div>
                                )}
                                <label
                                    style={{
                                        fontWeight: 500,
                                    }}
                                    className={cx('text-white')}
                                >
                                    {props.label}
                                </label>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={cx(`d-flex flex-row align-items-center justify-content-start gap-2 w-100 pb-1`, styles.optionSingleSelect)}
                        >
                            <div
                                className={`d-flex flex-row align-items-center ps-2  justify-content-start gap-2 w-50`}
                            >
                                <label className={cx('text-white')}>{props.label}</label>
                            </div>
                        </div>
                    )}
                </components.Option>
            </div>
        )
        // eslint-disable-next-line
    }, [isMultiSelect])

    const MenuList = useCallback((props) => {
        return (
            <>
                {isMultiSelect ? (
                    <div
                        className={cx(`d-flex flex-row align-items-center justify-content-start gap-2 w-100 pb-1`, styles.optionSelectAll)}
                        style={{
                            background: '#242644',
                            cursor: 'pointer',
                            paddingLeft: 17
                        }}
                        onClick={handleSelectAllToggle}
                    >
                        <div
                            className={`d-flex flex-row align-items-center ps-2  justify-content-start gap-2`}>
                            {value?.length !== options?.length && (
                                <div style={{ cursor: 'pointer', marginBottom: 5 }}>
                                    <IoIosSquareOutline color="#fff" size={20} />
                                </div>
                            )}
                            {value?.length === options?.length && (
                                <div style={{ cursor: 'pointer', marginBottom: 5 }}>
                                    <IoIosCheckbox color="#268FE9" size={20} />
                                </div>
                            )}
                            <label
                                style={{
                                    fontWeight: 500,
                                }}
                                className={cx('text-white')}
                            >
                                Select all
                            </label>
                        </div>
                    </div>
                ) : null}
                <components.MenuList {...props}>
                    {props.children}
                </components.MenuList>

            </>
        )
        // eslint-disable-next-line
    }, [isMultiSelect, value])

    const getClassName = (isDusignUp, isDepart, styles) => {
        if (isDusignUp) {
          return cx(styles.locationSelectContainer);
        } else if (isDepart) {
          return cx(styles.departmentSelectContainer);
        } else {
          return cx(styles.selectContainer);
        }
      };

    return (
        <div className={styles.selectWrapper}>
            <div ref={selectRef}>
                <label className={styles.label} style={labelStyle}>{label} {required ? <span style={{ color: '#C417E0' }}>*</span> : null}</label>

                <div
                    className={cx(styles.dropdownContainer)}
                    onClick={() => {
                        setIsSelectMenuOpen(!isSelectMenuOpen)
                    }}
                >
                    {isMultiSelect ? (
                        <span className={styles.multiSelectPlaceholder}>{placeholder}</span>
                    ) : (
                        <span className={styles.selectPlaceholder}>{value?.label || placeholder}</span>
                    )}
                    <img src={isSelectMenuOpen ? UpArrow : DownArrow} alt="Arrow" />
                </div>

                {isSelectMenuOpen &&
                    <div
                        onClick={handleClick}
                        className={getClassName(isDusignUp, isDepart, styles)}>
                        <RSelect
                            isMulti={isMultiSelect}
                            value={value}
                            options={options}
                            onChange={onChange}
                            placeholder={placeholder}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            allowSelectAll={isMultiSelect}
                            menuIsOpen={true}
                            hideSelectedOptions={!isMultiSelect}
                            components={{
                                Control: Control,
                                Option: Option,
                                MenuList: MenuList,
                                DropdownIndicator,
                                MultiValueContainer: (props) => {
                                    return ''
                                }
                            }}
                            styles={{
                                control: (styles) => {
                                    return {
                                        ...styles,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingLeft: '0px',
                                        backgroundColor: '#1C1F28',
                                        borderRadius: '10px',
                                        height: '45px',
                                        background: '#1C1F28 !important',
                                        border: '3px solid #5D5F86',
                                        filter: 'drop-shadow(0 0 0.65rem #2E1364)',
                                        minWidth: '292px',

                                        ':hover': {
                                            background: 'linear-gradient(0deg, rgba(96, 46, 166, 0.2) 0%, rgba(201, 119, 214, 0.2) 88.64%)',
                                            boxShadow: '0px 0px 10px 0px rgba(118, 61, 176, 1)',
                                            border: '1px solid #4b47b8'
                                        },
                                        ':focus': {
                                            background: 'transparent !important',
                                            boxShadow: 'none !important',
                                        },
                                        caretColor: '#fff',
                                        ...props.controlStyle,
                                        width: '65%',
                                    }
                                },
                                placeholder: (styles, state) => {

                                    return {
                                        ...styles,
                                        color: 'rgba(255, 255, 255, 0.30)',
                                    };
                                },
                                indicatorSeparator: (styles) => {
                                    return {
                                        ...styles,
                                        display: 'none',
                                    }
                                },
                                menu: (styles) => {
                                    return {
                                        ...styles,
                                        textWrap: 'nowrap',
                                        boxShadow: 'none'
                                    }
                                },
                                menuList: (styles) => {
                                    return {
                                        ...styles,

                                        '::-webkit-scrollbar': {
                                            width: '5px',
                                            height: '50px',
                                            background: '#0B0C12 !important'
                                        },
                                        '::-webkit-scrollbar-thumb': {
                                            background: '#0B0C12 !important',
                                            border: '1px solid transparent !importan',
                                            borderRadius: '10px !important',
                                            backgroundClip: 'content-box !important',
                                            height: '50px',
                                        },
                                        '::-webkit-scrollbar-track': {
                                            background: '#393939 !important',
                                        },
                                        background: '#242644',
                                        maxHeight: menuMaxheight,
                                        border: 'none',
                                        borderColor: 'transparent'

                                    }
                                },
                                option: () => {
                                    return {
                                        cursor: 'pointer',
                                        marginLeft: 10,
                                        padding: 6,
                                        width: '60%',
                                        ':hover': {
                                            background: '#17152B',
                                            width: '60%',
                                            opacity: '0.56',
                                            color: 'white !important',
                                            padding: 6

                                        }
                                    }
                                },
                                singleValue: (styles, state) => {
                                    return {
                                        ...styles,
                                        color: '#fff',
                                    }
                                },
                                input: (styles) => ({
                                    ...styles,
                                    color: '#fff',
                                }),
                                dropdownIndicator: (styles) => ({
                                    ...styles,
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor:'red'
                                }),
                                multiValue: (styles) => ({
                                    ...styles,
                                    display: 'none'  
                                })
                            }}
                        />

                    </div>

                }
                {error && <div className={cx(styles.errorMessage)}>{error}</div>}
            </div>
            {isMultiSelect && (
                <div className={styles.selectedOptionMainContainer}>
                    {(value)?.map((item) => (
                        <div key={item.value} className={cx(styles.optionSelectedContainer)}>
                            <span className={cx(styles.selectedValue, 'text-white')}>{item.label}</span>
                            <Close width={10} className={cx(styles.removeIcon)} onClick={() => onChange(value?.filter(fd => fd.value !== item.value))} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

Select.defaultProps = {
    label: '',
    error: '',
    placeholder: '',
    isMultiSelect: false,
    isSearchable: true,
    isClearable: false,
    labelStyle: {},
}

Select.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType(PropTypes.array, PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    isMultiSelect: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    labelStyle: PropTypes.object,
}

export default Select
/*conflict-resolution-will be removed later*/