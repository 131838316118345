import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Alert.css';
import Geofance from './Geofance';
import Vehicle from './Vehicle';
import AlertHome from './AlertHome';
import ConnectivityAlert from './Connectivity';
import AnalyticsAlert from './AnalyticsAlert';


const AlertContainer = () => {
	const items = [ 
		{
			name: 'Overview',
			url: '/alert/home',
		},
		{
			name: 'Geofence',
			url: '/alert/geofence',
		},
        {
			name: 'Vehicle',
			url: '/alert/vehicle',
		},
		{
			name: 'Connectivity',
			url: '/alert/connectivity',
		},
		{
			name: 'Analytics',
			url: '/alert/analytics',
		},
		
	];
	const location = useLocation();
	const currentRoute = location.pathname;

	return (
		<>
			<div className='alert__container'>
				<div className='alert__tab__container'>
					{items.map((item) => (
						<>
							<Link
								to={item.url}
								className={`alert-title alert__vertical__line alert__tabs ${
									currentRoute === item.url ? ' active__tab' : ''
								}`}
								data-testid={`tab-link-${item.name}`}
							>
								{item.name}
							</Link>
						</>
					))}
				</div>

				<div className='reporting__body_wrapper'>
					{(() => {
						switch (currentRoute) {
							case '/alert/home':
								return <AlertHome/>;
							case '/alert/geofence':
								return <Geofance/>
							case '/alert/vehicle':
								return <Vehicle/>;
							case '/alert/connectivity':
								return <ConnectivityAlert/>;
							case '/alert/analytics':
								return <AnalyticsAlert/>;
							default:
								return null;
						}
					})()}
				</div>
			</div>
		</>
	);
};

export default AlertContainer;
