import React, { memo } from 'react';
import PropTypes from 'prop-types'; 
import inputStyles from './Textarea.module.css';

const Textarea = ({ type, error, label, required, labelStyle, disable = false, ...props }) => {


  return (
    <div className={inputStyles.inputWrapper}>
      {label && (
        <label className={inputStyles.label}>
          {label} {required && <span className={inputStyles.labelRequired}>*</span>}
        </label>
      )}
     <textarea
          className={`${inputStyles.root} ${error ? inputStyles.error : ''}`}
          {...props}
          disabled={disable}
        />
     
      {error && <div className={inputStyles.errorMessage}>{error}</div>}
    </div>
  );
};

Textarea.defaultProps = {
  onChange: () => {},
  error: '',
  type: 'text',
  value: '',
  label: '',
  required: false,
  labelStyle: {},
  readOnly: false,
};

Textarea.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  labelStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  disable: PropTypes.bool,
};

export default memo(Textarea);
/*conflict-resolution-will be removed later*/