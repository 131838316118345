import api from "../../../service/api";
import API_BOOK from "../../../service/endpoints";
import { CipherGenerator } from "../../../utils/crypto";
import ActionTypes from "../../actionTypes";
const {
  SA_SIGNUP: { SA_SIGNUP_SUCCESS, SA_SIGNUP_FAILED, SA_VERIFY_OTP_SUCCESS, SA_VERIFY_OTP_FAILED },
} = ActionTypes;

export const SA_VerifyEmailSuccessAD = (payload) => ({
  type: SA_SIGNUP_SUCCESS,
  payload
});
export const SA_VerifyEmailFailedAD = () => ({
  type: SA_SIGNUP_FAILED,
});

const SA_VerifyOTP_SuccessAD = () => ({
  type: SA_VERIFY_OTP_SUCCESS
})
const getDepartmentNameList= (payload) => ({
  type: ActionTypes.AUTH.GET_DEPARTMENT_SUCCESS,
  payload,
});
const getDuLocationList = (payload) => ({
  type: ActionTypes.AUTH.GET_DU_LOCATION_SUCCESS,
  payload,
});
const getDuLocationListFaliure = (payload) => ({
  type: ActionTypes.AUTH.GET_DU_LOCATION_FAILURE,
  payload,
});

const getDuTemplateList = (payload) => ({
  type: ActionTypes.AUTH.GET_DU_TEMPLATE_SUCCESS,
  payload,
});
const getDuTemplateListFaliure = (payload) => ({
  type: ActionTypes.AUTH.GET_DU_TEMPLATE_FAILURE,
  payload,
});

export const SA_VerifyOTP_FailedAD = () => ({
  type: SA_VERIFY_OTP_FAILED
})

/**
 *  @desc         Norminc SA Verify Email API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /user/verify-email?purpose=signup
 *  @method       POST
 *  @createdDate  13-March-2024
 *  @modifiedDate 13-March-2024
 **/

export const SA_VerifyEmail = async (email) => {
  try {
    const res = await api({
      url: API_BOOK.SA_VERIFY_EMAIL_SIGNUP.url,
      method: API_BOOK.SA_VERIFY_EMAIL_SIGNUP.method,
      data: {
        email,
      },
    });
    return res

  } catch (error) {
    console.log("SA_VerifyEmail error", error);
  }
};

/**
 *  @desc         Norminc SA Send OTP API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /otp/send-otp
 *  @method       POST
 *  @createdDate  13-March-2024
 *  @modifiedDate 13-March-2024
 **/

export const SA_SendOTP = (email, userId) => async () => {
  try {
    await api({
      url: API_BOOK.SA_SEND_OTP.url,
      method: API_BOOK.SA_SEND_OTP.method,
      data: {
        purpose: 'signup',
        channel: 'email',
        data: {
          email
        },
        userId
      }
    });

  } catch (error) {
    console.log("SA_SendOTP error", error);
  }
};

/**
 *  @desc         Norminc SA Verify OTP API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /otp/verify-otp
 *  @method       POST
 *  @createdDate  13-March-2024
 *  @modifiedDate 13-March-2024
 **/

export const SA_VerifyOTP = (otp, email) => async (dispatch) => {
  try {
    const res = await api({
      url: API_BOOK.SA_VERIFY_OTP.url,
      method: API_BOOK.SA_VERIFY_OTP.method,
      data: {
        otp,
        channel: 'email',
        data: {
          email
        }
      }
    });
    if (res.status === 200) {
      dispatch(SA_VerifyOTP_SuccessAD())
    }

    else {
      dispatch(SA_VerifyOTP_FailedAD())
    }


  } catch (error) {
    console.log("SA_VerifyOTP error", error);
  }
};

/**
 *  @desc         Norminc SA Set Password API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /user/set-password
 *  @method       POST
 *  @createdDate  13-Mar-2024
 *  @modifiedDate 13-Mar-2024
 **/
export const SASetPassword = async ({ email, password }) => {
  try {
    const hashPassword = CipherGenerator(password)
    const res = await api({
      url: API_BOOK.SA_SET_PASSWORD.url,
      method: API_BOOK.SA_SET_PASSWORD.method,
      data: {
        email,
        password: hashPassword,
        confirmPassword: hashPassword
      }
    });
    return res
  } catch (error) {
    console.log("error:", error);
  }
};



/**
*  @desc         DU Department name
*  @author       Ankit Kushwaha
*  @api         /department/names
*  @method       GET
*  @createdDate  09-April-2024
*  @modifiedDate 09-April-2024
**/
export const GetDepartmentName = ({
  ci,
  searchText
}) =>
  async (dispatch) => {
    try {
      const res = await api({
        url: API_BOOK.DU_DEPARTMENT.url,
        method: API_BOOK.DU_DEPARTMENT.method,
        data: {

          ci,
          searchText
        },
      });

      if (res.status === 200 && res?.data?.data) {
        const response = res?.data?.data;
        dispatch(getDepartmentNameList(response));
      }
    } catch (error) {
      console.error("Customers list fetch failed", error);
    }
  };

/**
*  @desc         DU Department Location
*  @author       Ankit Kushwaha
*  @api         /customeradmin/get-all-locations
*  @method       GET
*  @createdDate  09-April-2024
*  @modifiedDate 09-April-2024
**/
export const GetDuLocation = ({ ci }) => async (dispatch) => {
  try {
    const urlSearchParams = new URLSearchParams({ ci })
    const res = await api({
      url: `${API_BOOK.DU_LOCATION.url}?${urlSearchParams.toString()}`,
      method: API_BOOK.DU_LOCATION.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getDuLocationList(response));
    }
  } catch (error) {
    dispatch(getDuLocationListFaliure(error))
    console.log("Error getting ", error);
  }
};

/**
*  @desc         Create Department User
*  @author       Ankit Kushwaha
*  @api         /department-user/create
*  @method       POST
*  @createdDate  09-April-2024
*  @modifiedDate 09-April-2024
**/

export const createDepartmentUser = async ({ name, email, ci, departmentId, locationId }) => {
  try {
    const response = await api({
      url: API_BOOK.DU_CREATE_USER.url,
      method: API_BOOK.DU_CREATE_USER.method,
      data: { name, email, ci, departmentId, locationId },
    })
    return response
  } catch (error) {
    console.log('error', error)
  }
}


/**
*  @desc         DU Department Location
*  @author       Ankit Kushwaha
*  @api         /customeradmin/get-all-locations
*  @method       GET
*  @createdDate  09-April-2024
*  @modifiedDate 09-April-2024
**/
export const GetDuTemplate = () => async (dispatch) => {
  try {
    const res = await api({
      url: API_BOOK.DU_TEMPLATE.url,
      method: API_BOOK.DU_TEMPLATE.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getDuTemplateList(response));
    }
  } catch (error) {
    dispatch(getDuTemplateListFaliure(error))
    console.log("Error getting ", error);
  }
};

/**
*  @desc         DU Department name
*  @author       Ankit Kushwaha
*  @api         /department/update-user
*  @method       PUT
*  @createdDate  06-May-2024
*  @modifiedDate 06-May-2024
**/

  export const UpdateUser = async ({userId, departmentId, locationId, assignedAccessTemplateId }) => {
    try {
      const response = await api({
        url: API_BOOK.DU_UPDATE_USER.url,
        method: API_BOOK.DU_UPDATE_USER.method,
        data: {userId, departmentId, locationId, assignedAccessTemplateId },
      })
      return response
    } catch (error) {
      console.log('error', error)
    }
  }
  /*conflict-resolution-will be removed later*/