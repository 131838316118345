import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import './VTLineChart.css'
import { ResponsiveContainer, CartesianGrid, XAxis, LineChart, YAxis, Line, } from 'recharts'
import { BsChevronDown } from 'react-icons/bs'


const VTLineChart = (props) => {
    const dropdownRef = useRef(null)
    const chartExportDropdownRef = useRef(null)
    const chartDivRef = useRef(null)
    const [isShowExportMenu, setIsShowExportMenu] = useState(false)
    
    useEffect(() => {
        const handleChartExportDropdownOutsideClick = (event) => {
            const clickedElement = event.target
            const classesToCheck = ['vt__chart-download-container', 'vt__chart-download-main', 'vt__chart-download', 'vt__bold-chevron']
            if (
                chartExportDropdownRef.current &&
                !chartExportDropdownRef.current.contains(event.target) &&
                !(classesToCheck.some(className => clickedElement.classList.contains(className)))
            ) {
                setIsShowExportMenu(false)
            }
        }
        document.addEventListener('click', handleChartExportDropdownOutsideClick)
        return () => {
            document.removeEventListener('click', handleChartExportDropdownOutsideClick)
        }
    }, [])
    
    const onExportToImage = (e) => {
        e.stopPropagation()
        setIsShowExportMenu(false)
        props.onExportToImage(chartDivRef.current, props.title, [dropdownRef.current])
    }

    const onExportToExcel = (e) => {
        e.stopPropagation()
        setIsShowExportMenu(false)
        const chart_headers = [
            { header: props.xHeader, key: 'x' },
            { header: props.yHeader, key: 'y' }
        ]
        const chart_data = filterChartData(props.data).map(item => ({
            x:  moment(item.timestamp).format('DD-MMM-YYYY, HH:mm:ss'),
            y: item.y ? item.y + ' ' + props.units : ''
        }))
        const fileName = props.title;
        const sheetName = props.title;
        props.onExportToExcel({ headers: chart_headers, data: chart_data, fileName: fileName, sheetName: sheetName })
    }

    const filterChartData = (data) => {
        if (props.chartVariant === 'VBV_VS_TIME') {
            return data.filter(d => d.y <= 100)
        }
        return data
    }

    if (props.data) {
        const data = filterChartData(props.data)
        return (
            <div className='vt-chart-content-frame' ref={chartDivRef}>
                <div className='vt-chart-content'>
                    <div className='container-fuild'>
                        <div className='row vt_chart_download_row'>
                            <div className='col-8'>
                                <div
                                    className='vt-chart-title'
                                    style={{ color: props.themeColor }}
                                >{props.title}
                                </div>
                            </div>
                            <div className='col-4'>
                                <div
                                    style={{ float: 'right' }}
                                    ref={dropdownRef}
                                    className='vt__chart-download-container'
                                >
                                    <div className='vt__chart-download-main'>
                                        <div
                                            className='vt__chart-download'
                                            onClick={() => setIsShowExportMenu(true)}
                                            data-testid='vt-chart-export-menu-button'
                                        >
                                            <BsChevronDown
                                                className='vt__bold-chevron'
                                            />
                                            <div
                                                style={{ display: isShowExportMenu ? 'block' : 'none' }}
                                                className='vt__chart-export-dropdown'
                                                ref={chartExportDropdownRef}
                                            >
                                                <div
                                                    className='d-flex vt__chart-export-dropdown-menu'
                                                    onClick={onExportToImage}
                                                    data-testid='vt-chart-export-image-button'
                                                >
                                                    Image
                                                </div>
                                                <div
                                                    className='d-flex vt__chart-export-dropdown-menu'
                                                    onClick={onExportToExcel}
                                                    data-testid='vt-chart-export-sheet-button'
                                                >
                                                    Export Sheet
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ResponsiveContainer
                        width={props.chartConfig.width}
                        height={props.chartConfig.height} >

                        <LineChart
                            data={data}
                            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                            <XAxis
                                dy={props.chartConfig.xAxis.dy}
                                dataKey={props.chartConfig.xAxis.dataKey}
                                tick={{ fill: props.chartConfig.xAxis.tickColor }}
                                minTickGap={35}
                            />
                            <YAxis
                                dx={props.chartConfig.yAxis.dx[props.index]}
                                dataKey={props.chartConfig.yAxis.dataKey}
                                unit={props.units}
                                axisLine={props.chartConfig.yAxis.isLineVisible}
                                tick={{ fill: props.chartConfig.yAxis.tickColor }}
                                ticks={props.yRange} />

                            <CartesianGrid
                                stroke={props.chartConfig.grid.lineColor}
                                horizontal={props.chartConfig.grid.hlineVisible}
                                vertical={props.chartConfig.grid.vlineVisible} />

                            <Line
                                type="linear"
                                dataKey={props.chartConfig.yAxis.dataKey}
                                stroke={props.themeColor}
                                strokeWidth={props.chartConfig.lineWidth}
                                dot={props.chartConfig.isDotVisible} />

                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='vt-chart-content-frame'>
                <div className='vt-chart-content'>
                    <div className='container-fuild'>
                        <div className='row vt_chart_download_row'>
                            <div className='col-10'>
                                <div
                                    className='vt-chart-title-disable'
                                >{props.title}
                                </div>
                            </div>
                            <div className='col-2'>
                                <div
                                    style={{ float: 'right' }}
                                    ref={dropdownRef}
                                    className='vt__chart-download-container'
                                >
                                    <div className='vt__chart-download-main'>
                                        <div
                                            className='vt__chart-download'
                                        >
                                            <BsChevronDown
                                                className='vt__bold-chevron-disabled'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ResponsiveContainer
                        width={props.chartConfig.width}
                        height={props.chartConfig.height}>

                        <LineChart
                            data={[{ y: 0, x: "1:00" }, { y: 0, x: "2:00" }, { y: 0, x: "3:00" }, { y: 0, x: "4:00" }, { y: 0, x: "5:00" }, { y: 0, x: "6:00" }, { y: 0, x: "7:00" }, { y: 0, x: "8:00" }, { y: 0, x: "9:00" }, { y: 90, x: "10:00" }]}
                            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                            <XAxis
                                dy={props.chartConfig.xAxis.dy}
                                dataKey={props.chartConfig.xAxis.dataKey}
                                tick={{ fill: "#5B5959" }}
                                ticks={["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00"]} />
                            <YAxis
                                dx={props.chartConfig.yAxis.dx[props.index]}
                                dataKey={props.chartConfig.yAxis.dataKey}
                                unit={props.units}
                                axisLine={true}
                                tick={{ fill: "#5B5959" }}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
                            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill="#5B5959" fontSize="18">
                                No Data
                            </text>

                        </LineChart>

                    </ResponsiveContainer>

                </div>
            </div>
        )
    }
}

export default VTLineChart;