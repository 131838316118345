import ActionTypes from "../../actionTypes";



const initialState = {
    immobilizeListData: [],
    mobilizeListData: [],
    historyImmobilizeListData: [],
  };

  const ImmobilizeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case ActionTypes.IMMOBILIZE.IMMOBILIZE_LIST_SUCCESS:
        return {
          ...state,
          immobilizeListData: payload,
        };
        case ActionTypes.IMMOBILIZE.MOBILIZE_LIST_SUCCESS:
            return {
              ...state,
              mobilizeListData: payload,
            };
            case ActionTypes.IMMOBILIZE.IMMOBILIZE_HISTORY_LIST_SUCCESS:
              return {
                ...state,
                historyImmobilizeListData: payload,
              };
     
      default:
        return state;
    }
  };




  export default ImmobilizeReducer
