import React, { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import ImmobilizeTab from "../../immobilize/ImmobilizeTab";
import "../immobilize/Immobilize.css";
import Mobilize from "../../mobilize/Mobilize";
import ImmobilizeHistory from "../../immobilizeHistory/ImmobilizeHistory";

const Immobilize = () => {
  const [selectedTab, setSelectedTab] = useState("immobilize");

  const onTabSelectHandler = (event) => {
    setSelectedTab(event);
    if (event === "immobilize") {
      return;
    }
  };
  return (
    <Container fluid>
      <div className="immobilize-tab-container">
        <Tabs
          defaultActiveKey="immobilize"
          id="immobilize-tab-example"
          className="immobilize-nav-link"
          onSelect={(event) => {
            onTabSelectHandler(event);
          }}
        >
          <Tab eventKey="immobilize" title="Immobilize" data-testid="noncomm">
            <div className="tab-container d-flex col-12 flex-column ">
              {selectedTab === "immobilize" && (
                <ImmobilizeTab selectedTab={selectedTab} />
              )}
            </div>
          </Tab>
          <Tab eventKey="non_comm_log" title="Mobilize">
            <div className="tab-container d-flex col-12 flex-column ">
              {selectedTab === "non_comm_log" && (
                <Mobilize selectedTab={selectedTab} />
              )}
            </div>
          </Tab>

          <Tab eventKey="history" title="History">
            <div className="tab-container d-flex col-12 flex-column ">
              {selectedTab === "history" && (
                <ImmobilizeHistory selectedTab={selectedTab} />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default Immobilize;
