import React from 'react'
import { Modal } from 'react-bootstrap'

import './GPCreateGroupModal.css'

const GPCreateGroupModal = ({
    isOpen,
    formData,
    formError,
    onChange,
    onSave,
    onClose,
    groupData,
    isRename
}) => {
    return (
        <Modal
            dialogClassName="border-radius-group_create"
            show={isOpen}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Body>
                <div className="d-flex flex-row justify-content-center px-4 py-4">
                    <div className="col-12">
                    {
                        !isRename && groupData &&
                        <div className="d-flex flex-column col-12 ">
                            <div className="d-flex flex-row justify-content-center  ">
                            <div className='col-12 form-label-group'>
                                    <label className="create__group__label" style={{padding: '2px 0px 2px 0px'}}>
                                        {groupData?.name}
                                    </label>
                                </div>
                            </div>
                    </div>
                    }
                    
                        <div className="d-flex flex-column col-12 ">
                            <div className="d-flex flex-row justify-content-center  ">
                                
                                
                                <div className="col-12 form-label-group ">
                                    {
                                        !isRename && groupData &&
                                        <label htmlFor="groupname" className="create__group__label">
                                            Create sub group for selected vehicles
                                            <span className='asterisk-red'>*</span>
                                        </label>
                                    }

                                    {
                                        !isRename && !groupData && 
                                        <label htmlFor="groupname" className="create__group__label">
                                            Enter group name
                                            <span className='asterisk-red'>*</span>
                                        </label>
                                    }

                                    {
                                        isRename &&
                                        <label htmlFor="groupname" className="create__group__label">
                                            Rename group
                                            <span className='asterisk-red'>*</span>
                                        </label>
                                    }
                                    

                                    <div className={`form-label-group required   ${!formData.groupName ? 'no-value-selected-input' : ''}`}>
                                        <input
                                            type="text"
                                            id="groupsName"
                                            placeholder='Enter group name'
                                            name="groupsName"
                                            className="form-control group__create__input"
                                            maxLength={40}
                                            value={formData.groupName}
                                            onChange={(e) => onChange({ name: 'groupName', value: e.target.value })}
                                            onBlur={(e) => onChange({ name: 'groupName', value: e.target.value })}
                                            data-testid='modal-group-name-input'
                                        />
                                        {formError.groupName && (
                                            <div data-testid='modal-group-name-error-message' className="text-danger error-message">
                                                {formError.groupName}
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-row  group__create__btn ">
                                <button
                                    disabled={!formData.groupName}
                                    onClick={onSave}
                                    className="create-btn me-4"
                                    data-testid='modal-group-continue-button'
                                >
                                    Continue
                                </button>

                                <button
                                    onClick={onClose}
                                    className=" groups-cancel-btn"
                                    data-testid='modal-group-cancel-button'
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}

export default GPCreateGroupModal

