import React from "react";
import {
  Container,
  Table
} from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import Pagination from "../../../helpers/hoc/paginator/Pagination";
import { FaSearch } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import { getFormattedDate } from "../../../../utils/common-methods";
import { useDispatch, useSelector } from "react-redux";
import { ExportToExcelActivities, GetAllActivities } from "../../../../store/actions/userActivity/userActivity";
import Loader from "../../../helpers/hoc/loader/Loader";
import NoRecordFound from "../../../helpers/hoc/noRecordFound";


const UserActivity = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 8;

  //redux hooks
  const dispatch = useDispatch()
  const allActivities = useSelector(s => s?.userActivityReducer?.allActivities) 
  const allActivitiesCount = useSelector(s => s?.userActivityReducer?.dataCount) 
  const isLoading = useSelector((s) => s?.loader?.isLoading)

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  let _id = JSON.parse(localStorage?.user)?._id

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;

  const exportToExcel = () => {
    dispatch(ExportToExcelActivities({
      data: {
        searchKey: searchQuery,
        _id
      }
    }))
  };


  useEffect(() => {
    if (_id) {
      dispatch(GetAllActivities({
        data: {
          _id,
          searchKey: searchQuery,
          pageNumber: currentPage,
          pageSize: ITEMS_PER_PAGE
        }
      }))
    }
  }, [_id, searchQuery, currentPage, dispatch])

  return (
    <Container fluid>
      {isLoading && <Loader isLoading={isLoading} />}     
      <h5>Activity Log</h5>
      <div className="d-flex flex-row align-item-center justify-content-start">
      </div>

      <div className="d-flex col-12 flex-column h-100 mt-3">
        <div className="d-flex justify-content-start align-items-center flex-row pb-1 gap-4">
          <div
            className={`col-12 mt-3 ${!searchQuery? 'no-value-selected-input' : ''}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              className="searchBar search-input"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 50,
                paddingRight: 150,
                backgroundColor: "#EFF3F8",
                borderRadius: 2,
                paddingTop: 5,
                height: 38,
                width: 470,
                overflow: "hidden",
                marginBottom: 10,
              }}
            >
              <FaSearch
                style={{
                  marginBottom: 15,
                  marginRight: 20,
                  marginTop: 10,
                  color:'#797694',
                }}
              />
              <input  className="user__activity__search search-input"
                onChange={(e) => {
                  setSearchQuery(e?.target?.value);
                }}
                style={{
                  backgroundColor: "#EFF3F8",
                  borderRadius: 10,
                  color:'#1F2a5d',
                  border: "none",
                  width: 260,
                  paddingLeft: 10,
                  paddingBottom: 6,
                  outline: "none",
                }}
                placeholder="Search"
                data-testid='search-input'
              />
            </div>

            <button
              className={
                "dwn-btn"
              }
              disabled={!allActivitiesCount}
              onClick={() => {
                exportToExcel();
              }}
              data-testid='export-button'
            >
              <BsDownload style={{width:'22px',height:'22px'}} />
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white mb-2 position-relative">
        <Table
          id="myTable"
          className="user_activity_table mb-0"
          responsive={true} borderless >
          <thead>
            <tr className="th-border-bottom">
              <th><span className='fw500'>S.No</span></th>
              <th><span className='fw500'>Date & Time</span></th>
              <th><span className='fw500'>User Name</span></th>
              <th><span className='fw500'>User Role</span></th>
              <th><span className='fw500'>Action Type</span></th>
              <th><span className='fw500'>Description</span></th>
              <th><span className='fw500'>Device Address</span></th>
            </tr>
          </thead>
          {allActivities
            ?.map((user, index) => {
              return (
                <tr>
                  <td > {startIndex + index + 1}.</td>
                  <td>{getFormattedDate(user?.activityCreated)}</td>
                  <td> {user?.userName}</td>
                  <td> {user?.roleName}</td>
                  <td> {user?.actionType}</td>
                  <td> {user?.message}</td>
                  <td> {user?.ip}</td>
                </tr>
              );
            })}
        </Table>
        <Pagination
          disabled={false}
          totalPages={Math.ceil((allActivitiesCount || 0) / ITEMS_PER_PAGE)}
          currentPage={currentPage}
          setPage={handlePageChange}
          data-testid='activity-pagination'
        />
      </div>
      {
        !isLoading && !allActivitiesCount && <NoRecordFound />
      }
    </Container>
  );
};

export default UserActivity;