import ActionTypes from '../../actionTypes'
const { GET_ALL_DATA_SUCCESS, GET_ALL_DATA_FAIL, GET_ALL_IMMOBILE_FAIL, GET_ALL_IMMOBILE_SUCCESS, GET_ALL_NON_COMM_LOGS_SUCCESS,
   GET_ALL_NON_COMM_LOGS_FAIL, GET_ALL_ANALYTICS_DATA_SUCCESS, GET_ALL_ANALYTICS_DATA_FAIL, GET_ALL_DATA_RESET, SET_SHOW_CURRENT_IMMOBILE_DATA,
    SET_SHOW_CURRENT_NON_COMM_DATA, GET_IMMOBILE_COUNT_SUCCESS, SET_IMMOBILE_TABLE_DATA, GET_NON_COMM_COUNT_SUCCESS, SET_NON_COMM_TABLE_DATA,
    SET_ALERT_SCREEN_NON_COMM_TABLE_PAGE, SET_ALERT_SCREEN_IMMOBILE_TABLE_DURATION, SET_ALERT_SCREEN_NON_COMM_TABLE_DURATION,  SET_ALARM_TYPE_FOR_TABLE,
    SET_ALERT_SCREEN_ALARM_TABLE_PAGE, SET_ALARM_TABLE_DATA, SET_ALERT_SCREEN_FROM_DATE_AND_TO_DATE,
    SET_ALERT_SCREEN_IMMOBILE_TABLE_PAGE,SET_ALERT_SCREEN_DRIVING_TABLE_PAGE  } = ActionTypes.REPORTS.ALARM

const initialState = {
  allAlarmsData: [],
  dataCount: 0,
  allImmobileData: [],
  allNonCommLogsData: [],
  allAnalyticsAlarmsData: [],
  showCurrentImmobileData: true,
  showCurrentNonCommLogsData: true,
  selectedFromDate: null,
  selectedToDate: null,
  immobileDataTable: {
    records: [],
    count: 0,
    page: 0,
    pageSize: 8,
    durationInterval: ""
  },
  nonCommDataTable: {
    records: [],
    count: 0,
    page: 0,
    pageSize: 8,
    durationInterval: ""
  },
  alarmTableData: {
    records: [],
    count: 0,
    page: 0,
    pageSize: 8,
    alertType: ""
  },
  nonCommCount: {
    "< 2 Hrs": 0,
    "2-6 Hrs": 0,
    "6-10 Hrs": 0,
    "10-14 Hrs": 0,
    "14-18 hrs": 0,
    "18-22 Hrs": 0,
    "> 22 Hrs": 0
  },
  immobileCount: {
    "< 6 Hrs": 0,
    "6-12 Hrs": 0,
    "12-18 Hrs": 0,
    "18-24 Hrs": 0,
    "24-36 hrs": 0,
    "36-48 Hrs": 0,
    "> 48 Hrs": 0
}
}

const alarmReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_DATA_SUCCESS:
      return {
        ...state,
        allAlarmsData: payload.rows,
        dataCount: payload.count,
      }
    case GET_ALL_DATA_FAIL:
      return {
        ...state,
        allAlarmsData: [],
        dataCount: 0,
      }
      case GET_ALL_DATA_RESET:
        return {
          ...state,
          allAlarmsData: [],
          dataCount: 0,
        }
    case GET_ALL_IMMOBILE_SUCCESS:
      return {
        ...state,
        allImmobileData: payload,
      }
    case GET_NON_COMM_COUNT_SUCCESS:
      return {
        ...state,
        nonCommCount: payload,
      }
    case GET_ALL_IMMOBILE_FAIL:
      return {
        ...state,
        allImmobileData: [],
    }
    case GET_ALL_NON_COMM_LOGS_SUCCESS:
      return {
        ...state,
        allNonCommLogsData: payload,
      }
    case GET_ALL_NON_COMM_LOGS_FAIL:
      return {
        ...state,
        allNonCommLogsData: [],
    }
    case GET_ALL_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        allAnalyticsAlarmsData: payload,
      }
    case GET_IMMOBILE_COUNT_SUCCESS:
      return {
        ...state,
        immobileCount: payload,
      }
    case GET_ALL_ANALYTICS_DATA_FAIL:
      return {
        ...state,
        allAnalyticsAlarmsData: [],
    }
    case SET_SHOW_CURRENT_IMMOBILE_DATA:
      return {
        ...state,
        showCurrentImmobileData: payload,
      }
    case SET_SHOW_CURRENT_NON_COMM_DATA:
      return {
        ...state,
        showCurrentNonCommLogsData: payload
      }
    case SET_IMMOBILE_TABLE_DATA:
      return {
        ...state,
        immobileDataTable: {
          ...state.immobileDataTable,
          records: payload.rows,
          count: payload.count
        },
      }
      case SET_NON_COMM_TABLE_DATA:
        return {
          ...state,
          nonCommDataTable: {
            ...state.nonCommDataTable,
            records: payload.rows,
            count: payload.count
          },
        }
    case SET_ALERT_SCREEN_IMMOBILE_TABLE_PAGE:
      return {
        ...state,
        immobileDataTable: {
          ...state.immobileDataTable,
          page: payload
        },
      }
    case SET_ALERT_SCREEN_NON_COMM_TABLE_PAGE:
        return {
          ...state,
          nonCommDataTable: {
            ...state.nonCommDataTable,
            page: payload
          },
        }
    case SET_ALERT_SCREEN_IMMOBILE_TABLE_DURATION:
      return {
        ...state,
        immobileDataTable: {
          ...state.immobileDataTable,
          durationInterval: payload,
          page: 0
        },
      }
    case SET_ALERT_SCREEN_NON_COMM_TABLE_DURATION:
      return {
        ...state,
        nonCommDataTable: {
          ...state.nonCommDataTable,
          durationInterval: payload,
          page: 0
        },
      }
    case SET_ALARM_TYPE_FOR_TABLE:
      return {
        ...state,
        alarmTableData: {
          ...state.alarmTableData,
          alertType: payload,
          page: 0
        },
      }
    case SET_ALERT_SCREEN_ALARM_TABLE_PAGE:
      return {
        ...state,
        alarmTableData: {
          ...state.alarmTableData,
          page: payload
        },
      }
    case SET_ALERT_SCREEN_DRIVING_TABLE_PAGE:
      return {
        ...state,
        alarmTableData: {
          ...state.alarmTableData,
          page: payload
        },
      }
    case SET_ALARM_TABLE_DATA:
      return {
        ...state,
        alarmTableData: {
          ...state.alarmTableData,
          records: payload.rows,
          count: payload.count
        },
      }
    case SET_ALERT_SCREEN_FROM_DATE_AND_TO_DATE:
      return {
        ...state,
        selectedFromDate: payload.fromDate,
        selectedToDate: payload.toDate
      }
    default:
      return state
  }
}

export default alarmReducer
