import { useLocation, Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const RequireAuth = ({ isAuthenticated, user, sessionExpiry, menuId, isPublic }) => {
    const userRole = user?.userRole
    const location = useLocation();
    const currentPath = location.pathname
    const isRoleAuthorized = () => {
      if(userRole === 'DA' && (currentPath.includes('/ca/') || currentPath.includes('/sa/') )) return false
      if(userRole === 'CA' && (currentPath.includes('/da/') || currentPath.includes('/sa/') )) return false
      if(userRole === 'DU' &&(currentPath.includes('/da/') || currentPath.includes('/sa/') || currentPath.includes('/ca/') )  ) return false
      if(userRole === 'SA' &&(currentPath.includes('/da/') || currentPath.includes('/ca/'))  ) return false
      return true
    }
    return (
      isAuthenticated && isRoleAuthorized() && (isPublic || user?.menus.includes(menuId))
            ? <Outlet />
            : isAuthenticated && sessionExpiry > new Date()
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

RequireAuth.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired
  }
  
  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    sessionExpiry: state.auth.sessionExpiry,
    user: state.auth.user
  })
  
export default connect(mapStateToProps)(RequireAuth)
