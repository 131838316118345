import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Table from 'react-bootstrap/Table';

import styles from './DashboardFleet.module.css';

const FLEET_CONFIG = {
    TWO: {
        label: '2 Wheelers',
        image: '/images/darkSVGIcon/fleet/two_wheeler.svg',
    },
    THREE: {
        label: '3 Wheelers',
        image: '/images/darkSVGIcon/fleet/three_wheeler.svg',
    },
    FOUR: {
        label: '4 Wheelers',
        image: '/images/darkSVGIcon/fleet/four_wheeler.svg',
    },
};

const DashboardFleet = (props) => {
    const { total, data, order, headerComponent } = props;

    return (
        <div className={cx(styles.card)}>
            <div className={cx(styles.cardHeader)}>
                <h3 className={cx(styles.cardHeading)}>Fleet {total}</h3>

                {headerComponent && <div className={cx(styles.cardAction)}>{headerComponent}</div>}
            </div>

            <div className={cx(styles.cardBody)}>
                <Table responsive className={cx(styles.table)}>
                    <thead className={cx(styles.tableHead)}>
                        <tr className={cx(styles.tableHeadRow)}>
                            <th className={cx(styles.tableHeadCell)}></th>
                            <th className={cx(styles.tableHeadCell, 'fw-medium')}>Active</th>
                            <th className={cx(styles.tableHeadCell, 'fw-medium')}>
                                Inactive
                            </th>
                            <th className={cx(styles.tableHeadCell, 'fw-medium')}>Total</th>
                        </tr>
                    </thead>
                    <tbody className={cx(styles.tableBody)}>
                        {order.map((o, i) => (
                            <tr
                                key={`row_${o}`}
                                className={cx(styles.tableBodyRow)}
                            >
                                <td className={cx(styles.tableBodyCell)}>
                                    <div className={cx(styles.vehicleWrapper)}>
                                        <img
                                            className={cx(styles.vehicleImage)}
                                            src={FLEET_CONFIG[o].image}
                                            alt={FLEET_CONFIG[o].label}
                                        />
                                        <span
                                            className={cx(styles.vehicleLabel)}
                                        >
                                            {FLEET_CONFIG[o].label}
                                        </span>
                                    </div>
                                </td>
                                <td className={cx(styles.tableBodyCell)}>
                                    <span
                                        className={cx(
                                            styles.statusLabel,
                                            styles.statusLabelActive
                                        )}
                                    >
                                        {data[o].active}
                                    </span>
                                </td>
                                <td className={cx(styles.tableBodyCell)}>
                                    <span
                                        className={cx(
                                            styles.statusLabel,
                                            styles.statusLabelInactive
                                        )}
                                    >
                                        {data[o].inactive}
                                    </span>
                                </td>
                                <td className={cx(styles.tableBodyCell)}>
                                    <span
                                        className={cx(
                                            styles.statusLabel,
                                            styles.statusLabelTotal
                                        )}
                                    >
                                        {data[o].total}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

const fleetInfoShape = {
    active: PropTypes.number.isRequired,
    inactive: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

DashboardFleet.propTypes = {
    total: PropTypes.number.isRequired,
    data: PropTypes.shape({
        TWO: PropTypes.shape(fleetInfoShape),
        THREE: PropTypes.shape(fleetInfoShape),
        FOUR: PropTypes.shape(fleetInfoShape),
    }).isRequired,
    order: PropTypes.arrayOf(PropTypes.oneOf('TWO', 'THREE', 'FOUR')),
    headerComponent: PropTypes.element,
};

DashboardFleet.defaultProps = {
    order: ['TWO', 'THREE', 'FOUR'],
    headerComponent: <></>,
};

export default React.memo(DashboardFleet);
/*conflict-resolution-will be removed later*/