import React, { memo }  from 'react';
import PropTypes from 'prop-types';
import { range } from '../../../utils/common-methods';
import styles from './Pagination.module.css';

const Pagination = ({ setPage, currentPage, totalPages, disabled, ...props }) => {
  const baseTestId = props['data-testid'] || 'pagination';
  let paginationWidth = 2;
  let pageButtonsStart = Math.max(1, currentPage - paginationWidth);
  let pageButtonsEnd = Math.min(currentPage + paginationWidth, totalPages);

  const pagesLeft = range(pageButtonsStart, currentPage).length - 1;
  const pagesRight = range(currentPage, pageButtonsEnd).length - 1;

  if (pagesLeft < paginationWidth) {
    pageButtonsEnd = Math.min(
      totalPages,
      pageButtonsEnd +
        Math.min(paginationWidth - pagesLeft, totalPages - currentPage)
    );
  } else if (pagesRight < paginationWidth) {
    pageButtonsStart = Math.max(
      1,
      pageButtonsStart -
        Math.max(paginationWidth - pagesRight, totalPages - currentPage)
    );
  }

  return (
    <div className={`${styles.paginationContainer}  d-flex justify-content-end p-2`}>
      <ul className="pagination">
        <li className={`${styles.page_item}`}>
          <button
            className={`${styles.btn_color_second} ${styles.count_btn} page-link `}
            aria-label="Previous"
            onClick={() => setPage(currentPage - 1)}
            disabled={disabled || currentPage === 1}
            data-testid={`${baseTestId}-previous-page`}
          >
            <img className={styles.left_icon} src='/images/leftPagination.svg' alt='left'/>
          </button>
        </li>
        {range(pageButtonsStart, pageButtonsEnd).map((pn) => (
          <li
            key={pn}
            className={`${styles.page_item} ${pn === currentPage ? styles.active : ''}`}
          >
            <button
              className={`${styles.count_btn} `}
              
              disabled={disabled}
              onClick={() => setPage(pn)}
              data-testid={`${baseTestId}-page-${pn}`}
            >
              {pn}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className={`${styles.btn_color_second} ${styles.count_btn}   `}
            aria-label="Next"
            disabled={disabled || currentPage === totalPages}
            onClick={() => setPage(currentPage + 1)}
            data-testid={`${baseTestId}-next-page`}
          >
            <img  className={styles.right_icon} src='/images/rightPagination.svg' alt='right'/>
          </button>
        </li>
      </ul>
    </div>
  );
};


Pagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};


Pagination.defaultProps = {
  disabled: false,
};

export default memo(Pagination);
/*conflict-resolution-will be removed later*/