const formatter = {}

formatter.withUnitOrDefault = ({ value, defaultValue = '-', unit = '%' }) => {
    if ([null, undefined].includes(value)) {
        return defaultValue
    }
    return `${value}${unit}`
}

formatter.vehicleSourceDataMapper = (value = '') => {
    if (value === 'TFT100') {
      return value
    }
  
    if (!value) {
      return 'Not subscribed'
    }
  
    return `${value.charAt(0).toUpperCase()}${value.slice(1)} API`
}

export { formatter }