import { connect, useSelector } from "react-redux";
import {Row, Col } from "react-bootstrap";
import "./ReportTrip.css";
import Analytics from "../../pages/new-report/report-trip/child-components/Analytics"; 
import PropTypes from "prop-types";
import { useEffect } from "react";
import { onFilterChange } from "../../../store/actions/reports/analytics/trip-report"; 
import Loader from "../../helpers/hoc/loader/Loader"; 

const ReportTrip = ({
  isCustomDateSelected,
  selectedDuration,
  customFromDate,
  customToDate,
  onFilterChange,
}) => {
  useEffect(() => {
    onFilterChange();
  }, [selectedDuration, customFromDate, customToDate, onFilterChange]);
  const isLoading = useSelector((s) => s?.loader?.isLoading)
  return (
    <Row>
      <Col>
      <div className="col">
      {/* <div className='heading-gt'style={{font: 'normal normal 600 18px/39px Inter', letterSpacing: '0px', color: '#1F2A5D', marginTop:10, marginLeft:12,
    fontSize: 16, fontWeight: 'medium'
    }}>Analytics</div> */}
      <Analytics data-testid='trip' />
        </div>
        <Loader isLoading={isLoading}></Loader>
      </Col>
    </Row>
  );
};

ReportTrip.propTypes = {
  isCustomDateSelected: PropTypes.bool.isRequired,
  selectedDuration: PropTypes.string.isRequired,
  customFromDate: PropTypes.string.isRequired,
  customToDate: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isCustomDateSelected: state.tripReportAnalytics.isCustomDateSelected,
  selectedDuration: state.tripReportAnalytics.selectedDuration,
  customFromDate: state.tripReportAnalytics.customFromDate,
  customToDate: state.tripReportAnalytics.customToDate,
});

export default connect(mapStateToProps, {
  onFilterChange,
})(ReportTrip);
