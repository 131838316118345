// External packages import
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Internal components import
import Modal from '../../../../shared/modals/BaseModal/BaseModal';
import BasicInput from '../../../../shared/form/Input/Input';
import Button from '../../../../shared/form/Button/Button';
import Select from '../../../../shared/form/Select/Select';
import validationService from '../../../../../service/validationService';

// Style import
import styles from './DepartmentAddEdit.module.css';

const DepartmentAddEdit = ({ isOpen, onClose, onSubmit, moduleList, editData }) => {
    const isEdit = editData?.departmentId
    const moduleOptions = moduleList?.map(d => ({ label: d.module, value: d.moduleId }))
    
    const [formData, setFormData] = useState({
        departmentName: '',
        assignedModules: [],
        departmentAdminName: '',
        departmentAdminEmail: '',
    })

    const [formDataError, setFormDataError] = useState({
        departmentName: '',
        assignedModules: '',
        departmentAdminName: '',
        departmentAdminEmail: '',
    })

    useEffect(() => {
        if (isEdit) {
            setFormData({
                ...editData,
                assignedModules: [...moduleOptions?.filter(d => editData.assignedModules?.includes(d.value))],
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editData])

    useEffect(() => {
        // reset data on close of the modal
        if (isOpen === false) {
            setFormData({
                departmentName: '',
                assignedModules: [],
                departmentAdminName: '',
                departmentAdminEmail: '',
            })
            setFormDataError({
                departmentName: '',
                assignedModules: '',
                departmentAdminName: '',
                departmentAdminEmail: '',
            })
        }
    }, [isOpen]);

    const handleOnChange = ({ name, value }) => {
        let error = '';
    
        if (name === 'departmentAdminEmail') {
            const validation = validationService.email(value);
            error = validation.message;
        } else if (name === 'assignedModules') {
            const validation = validationService.array(value);
            error = validation.message;
        } else if (name === 'departmentName' || name === 'departmentAdminName') {
            const validation = validationService.required(value);
            error = validation.message;
        }
    
        setFormData(prevState => ({ ...prevState, [name]: value }));
    
        // Clear the error if the input is valid
        setFormDataError(prevState => ({
            ...prevState,
            [name]: error ? error : ''
        }));
    };
    const handleClose = () => {
        setFormData({
            departmentName: '',
            assignedModules: [],
            departmentAdminName: '',
            departmentAdminEmail: '',
        })
        setFormDataError({
            departmentName: '',
            assignedModules: '',
            departmentAdminName: '',
            departmentAdminEmail: '',
        })
        onClose()
    }

    const handleValidation = () => {
        let errorCount = 0;

        const departmentName = validationService.required(formData.departmentName);
        if (!departmentName.isValid) {
            errorCount++
        }
        formDataError['departmentName'] = departmentName.message

        const departmentAdminName = validationService.required(formData.departmentAdminName);
        if (!departmentAdminName.isValid) {
            errorCount++
        }
        formDataError['departmentAdminName'] = departmentAdminName.message

        const assignModuleValidations = validationService.array(formData.assignedModules)
        if (!assignModuleValidations.isValid) {
            errorCount++
        }
        formDataError['assignedModules'] = assignModuleValidations.message

        if (!formData.departmentAdminEmail) {
            const departmentAdminEmailValidation = validationService.required(formData.departmentAdminEmail)
            if (!departmentAdminEmailValidation.isValid) {
                errorCount++
            }
            formDataError['departmentAdminEmail'] = departmentAdminEmailValidation.message
        }

        setFormDataError(prevState => ({ ...prevState, formDataError }))
        return errorCount
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation() > 0) {
            return;
        }
        onSubmit(formData)
    }

    const customLabelStyle = {
        fontWeight: 'bold',
      };

    return (
        <Modal
            isOpen={isOpen}
            title={isEdit ? 'Edit Department' : 'Create Department'}
            onClose={handleClose}
        >
            <div>
                <BasicInput
                    label='Department Name'
                    value={formData.departmentName}
                    onChange={(e) => handleOnChange({ name: 'departmentName', value: e.target.value })}
                    error={formDataError.departmentName}
                    placeholder='Enter Department name'
                    required={true}
                    labelStyle={customLabelStyle} 
                />
                <Select
                    label='Assign Modules'
                    options={moduleOptions}
                    value={formData.assignedModules}
                    error={formDataError.assignedModules}
                    onChange={(data) => handleOnChange({ name: 'assignedModules', value: data })}
                    placeholder='Select Modules'
                    required={true}
                    isMultiSelect={true}
                    controlStyle={{ width: '410px' }}
                    labelStyle={customLabelStyle} 
                    menuMaxheight='136px'
                   
                />
                <BasicInput
                    label='Department Admin Name'
                    value={formData.departmentAdminName}
                    onChange={(e) => handleOnChange({ name: 'departmentAdminName', value: e.target.value })}
                    error={formDataError.departmentAdminName}
                    placeholder='Enter Admin Name'
                    required={true}
                    labelStyle={customLabelStyle} 
                />
                <BasicInput
                    label='Department Admin Email'
                    value={formData.departmentAdminEmail}
                    onChange={(e) => handleOnChange({ name: 'departmentAdminEmail', value: e.target.value })}
                    error={formDataError.departmentAdminEmail}
                    placeholder='Enter Email ID'
                    required={true}
                    type='email'
                    labelStyle={customLabelStyle} 
                />

                <div className={styles.modalCTAWrapper}>
                    <Button variant="PINK" onClick={handleSubmit}>Submit</Button>
                </div>
            </div>
        </Modal>
    )
}

DepartmentAddEdit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    moduleList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default DepartmentAddEdit
/*conflict-resolution-will be removed later*/