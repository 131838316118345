// External packages import
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

// Internal components import
import BasicInput from '../../shared/form/Input/Input';
import PasswordInput from '../../shared/form/PasswordInput/PasswordInput';
import Button from '../../shared/form/Button/Button';
import Modal from '../../shared/modals/BaseModal/BaseModal';
import OtpModal from '../../shared/modals/OTPModals/OTPModals';
import Loader from '../../helpers/hoc/loader/Loader';
import InformativeModal from '../../shared/modals/InformativeModal/InformativeModel';
// import Address from './Address';

// Services, Utils and Store import
import { AuthenticateUser, verifyEmail,
    // getUsersAddress
} from '../../../store/actions/auth';
import validationService from '../../../service/validationService';
import { ResetPassword } from '../../../store/actions/forgotpassword';
import { SendForgotPasswordOTP, VerifyForgotPasswordOTP } from '../../../store/actions/otp';
import { encrypt, decrypt } from '../../../utils/crypto';

// Style import
import styles from './Login.module.css';


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loader.isLoading)

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        rememberMe: false,
    })

    const [formError, setFormError] = useState({
        email: '',
        password: '',
    })

    const [resetFormData, setResetFormData] = useState({
        email: '',
        otp: '',
        password: '',
        confirmPassword: '',
        userId: '',
        passwordCheck: {},
    })

    const [resetFormError, setResetFormError] = useState({
        email: '',
        otp: '',
        password: '',
        confirmPassword: '',
    })
    
    const [isOpenForgot, setIsOpenForgot] = useState(false)
    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [showInformativeModal, setShowInformativeModal] = useState(false);
    // const [showAddressComponent, setShowAddressComponent] = useState(false);

    useEffect(() => {
        if(resetFormData.otp.length === 6){
            handleVerifyOtp()
        }
    },[resetFormData.otp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
        if(localStorage.getItem('secretX') && localStorage.getItem('secretY')){
            setFormData(prevState => ({
                ...prevState, 
                email: decrypt(localStorage.getItem('secretX')) ,
                password: decrypt(localStorage.getItem('secretY')),
                rememberMe: true
            }))
        }
    },[])

    const handleForgotModal=()=>{
        if(isOpenForgot){
            setResetFormError(prevState => ({ ...prevState, email: '' }))
            setResetFormData(prevState => ({ ...prevState, email: '' }))
        }
        setFormData({
            email: '',
            password: '',
        })
        setFormError({
            email: '',
            password: '',
        })
        setIsOpenForgot(prevState => !prevState)
    }

    const handleOtpModal = () => {
        setIsOpenForgot(false)
        if(!isOtpModalOpen){
            setResetFormData(prevState => ({ ...prevState, otp: ''  }))
            setResetFormError(prevState => ({ ...prevState, otp: '' }))
        }
        setIsOtpModalOpen(prevState => !prevState)
    }

    const handleOnChange = ({ name, value }) => {
        if (name === 'email') {
            const emailValidaton = validationService.email(value)
            formError[name] = emailValidaton.message
        }
        setFormError(formError)
        setFormData(prevState => ({ ...prevState, [name]: value }))
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    }


    const handleValidation = () => {
        let errorCount = 0;

        const passwordValidaton = validationService.required(formData.password)
        if (!passwordValidaton.isValid) {
            errorCount++
        }
        setFormError(prevState => ({ ...prevState, password: passwordValidaton.message }))

        return errorCount
    }

    const handleSubmit = async (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();
    
        // Validate the form fields
        if (handleValidation() > 0) {
            // If validation errors exist, exit early
            return;
        }
    
        try {
            const response = await dispatch(AuthenticateUser({
                emailOrMobileNumber: formData.email,
                password: formData.password,
                // navigate,
                // navigateFrom: '/', //NOTE: Commented it because earlier it used to navigate directly to dashboard if email and password is verified! now we have to navigate based on the user role
                rememberMe: formData.rememberMe,
            }));
            console.log('AuthenticateUser', response)  
            // Extract the user's role and ID from the response data
            const userRole = response?.data?.data?.user?.userRole
            if(userRole === 'DA') {
                navigate('/da/dashboard')
                return
            }
            if(userRole === 'CA') {
                // If the user role is specific dispatch an action to get the user's address
                // NOTE: Need to validate the below commented logic
                // const addressResponse = await getUsersAddress(formData.email) 
                // if (addressResponse?.status === 200) {
                //     setShowAddressComponent(true);
                //     return
                // }

                navigate('/ca/dashboard')
                return
            }
            if(userRole === 'SA') {
                navigate('/sa/dashboard')
                return
            }
            if(response?.status === 200) {
                navigate('/');
            }
        } catch (error) {
            console.log(error);
        }
    };
    

    const handleResetPasswordOnChange = ({ name, value, passwordCheck = {} }) => {
        if (name === 'email') {
            const emailValidaton = validationService.email(value)
            resetFormError[name] = emailValidaton.message
        }
        if (name === 'password') {
            resetFormError['confirmPassword'] = ''
            resetFormData['confirmPassword'] = ''
        }

        if (name === 'confirmPassword') {
            resetFormError['confirmPassword'] = value !== resetFormData.password ? 'New password & Confirm password should be same' : ''
        }
        
        resetFormData[name] = value
        resetFormData.passwordCheck = passwordCheck
        
        setResetFormError({ ...resetFormError })
        setResetFormData({ ...resetFormData })
    }

    const handleResetPasswordValidation = () => {
        let errorCount = 0;

        const passwordValidaton = validationService.required(resetFormData.password)
        if(!passwordValidaton.isValid){
            errorCount++
        } else {
            if (Object.values(resetFormData.passwordCheck).filter(d => !d).length > 0) {
                errorCount++
                passwordValidaton.message = 'Password checks are missing'
            } else {
                passwordValidaton.message = ''
            }
        }
        setResetFormError(prevState => ({ ...prevState, password: passwordValidaton.message }))

        let confirmPasswordValidaton = validationService.required(resetFormData.confirmPassword)
        if (!confirmPasswordValidaton.isValid) {
            errorCount++
        }
        setResetFormError(prevState => ({ ...prevState, confirmPassword: confirmPasswordValidaton.message }))

        return errorCount
    }

    const handleResetPasswordSubmit = async(e) => {
        e.preventDefault();
        if(handleResetPasswordValidation() > 0){
            return;
        }
        try {
            const res = await ResetPassword({
                email: resetFormData.email,
                password: resetFormData.password,
            })
            if(res.status === 200){
                setFormData({
                    email: '',
                    password: '',
                })
                setResetFormData({
                    email: '',
                    otp: '',
                    password: '',
                    confirmPassword: '',
                    userId: '',
                    passwordCheck: {},
                })
                
                setShowInformativeModal(true)
                setTimeout(() => {
                    setShowInformativeModal(false)
                    setShowResetPassword(false)
                }, 2000)
               
            }else {
                setResetFormError(prevState => ({ ...prevState, confirmPassword: res.data.error }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onResetPasswordKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleResetPasswordSubmit(e);
		}
	}

    const handleSendOtp = async() => {
        try {
            const verifyEmailRes = await verifyEmail({ email: resetFormData.email, purpose: 'forgotPassword' })
            if (verifyEmailRes.status !== 200) {
                setResetFormError(prevState => ({ ...prevState, email: verifyEmailRes.data.error }))
                return
            }
            setResetFormData(prevState => ({ ...prevState, userId: verifyEmailRes.data.data.userId }))
            const res = await SendForgotPasswordOTP({ email: resetFormData.email, userId: verifyEmailRes.data.data.userId })
            if(res.status === 200){
                handleOtpModal()
            } else {
                setResetFormError(prevState => ({ ...prevState, email: res.data.error }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleVerifyOtp = async() => {
        try {
            const res = await VerifyForgotPasswordOTP({ email: resetFormData.email, otp: resetFormData.otp });
            if(res.status === 200){
                handleOtpModal()
                setShowResetPassword(true)
            } else {
                setResetFormError(prevState => ({ ...prevState, otp: res.data.error }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const rememberMeToggle = ({ name, value }) => {
        //set in local storage
        setFormData(prevState => ({ ...prevState, [name]: value }))
        let email = encrypt(formData.email)
        let password = encrypt(formData.password)
        if (value) {
            localStorage.setItem('secretX', email)
            localStorage.setItem('secretY', password)
        }else {
            localStorage.removeItem('secretX')
            localStorage.removeItem('secretY')
        }
    }

    const handleResendOtp = () => {
        setResetFormError(prevState => ({ ...prevState, otp: '' }))
        SendForgotPasswordOTP({ email: resetFormData.email, userId: resetFormData.userId });
    }

    if(showResetPassword){
        return (
        <div className={cx(styles.mainContainer)}>
            <div className={cx(styles.resetContainer)}>
                <h3 className='text-white'>Reset password</h3>
                <div className='flex-fill mt-3'>
                    <form onSubmit={handleResetPasswordSubmit}>
                        <label className='text-white'>New password<span style={{color:'#C417E0'}}>*</span></label>
                        <PasswordInput
                            value={resetFormData.password}
                            onChange={(e, passwordCheck) => handleResetPasswordOnChange({ name: 'password', value: e.target.value, passwordCheck })}
                            error={resetFormError.password}
                            onKeyDown={onResetPasswordKeyDown}
                            enablePasswordValidation={true}
                           
                        />
                        <label className='text-white'>Confirm password</label>
                        <PasswordInput
                            value={resetFormData.confirmPassword}
                            onChange={(e) => handleResetPasswordOnChange({ name: 'confirmPassword', value: e.target.value })}
                            error={resetFormError.confirmPassword}
                            onKeyDown={onResetPasswordKeyDown}
                            success={resetFormData.confirmPassword && !resetFormError.confirmPassword}
                        />
                        <div className='mt-4 mb-2'>
                            <Button type='submit'>Submit</Button>
                        </div>
                        <span className={`${styles.signIn}`}>Back to <span className={`fw-semibold ${styles.backToSignIn}`} onClick={() => setShowResetPassword(false)}>Sign In</span></span>
                    </form>
                </div>
            </div>
            <InformativeModal
                isOpen={showInformativeModal}
                onClose={() => setShowInformativeModal(false)}
                message='Password Changed Successfully'
                variant='success'
            />
        </div>
        )
    }
    return (
        <>
            <div className={`${styles.mainContainer}`}>
                <div className={`${styles.loginContainer}`}>
                    <h3 className='text-white'>Sign In</h3>
                    <div className='flex-fill mt-3 mb-5'>
                        <form onSubmit={handleSubmit}>
                            <label className='text-white'>Email ID <span style={{color:'#C417E0'}}>*</span></label>
                            <BasicInput
                                value={formData.email}
                                onChange={(e) => handleOnChange({ name: 'email', value: e.target.value })}
                                error={formError.email}
                                type='email'
                                placeholder='Enter Email ID'
                                
                            />
                            <label className='text-white'>Password <span style={{color:'#C417E0'}}>*</span></label>
                            <PasswordInput
                                value={formData.password}
                                onChange={(e) => handleOnChange({ name: 'password', value: e.target.value })}
                                error={formError.password}
                                onKeyDown={onKeyDown}
                                errorFields={formError}
                                placeholder='Enter Password'
                            />

                            <div className={`mt-2 d-flex flex-row justify-content-between align-items-center`}>
                                <div className={cx('d-flex flex-row justify-content-center align-item-center gap-2', styles.rememberMeText)}>
                                    <input
                                        checked={formData.rememberMe}
                                        onChange={(e) => rememberMeToggle({ name: 'rememberMe', value: e.target.checked })}
                                        type='checkbox'
                                    />
                                    <span>Remember me</span>
                                </div>
                                <Link data-testid='forgot-password-link' className={cx(styles.forgotPasswordText)} onClick={handleForgotModal}>
                                    Forgot password?
                                </Link>
                            </div>
                            <div className='mt-4 mb-2'>
                                <Button type='submit'>Sign in</Button>
                            </div>
                            <span className={cx(styles.signIn)}>
                            Don’t have an account? <Link data-testid='signup-link' className={cx(styles.signupText)} to='/signup'>Sign Up</Link>
                            </span>
                        </form>
                    </div>
                    <div className={`d-flex flex-row justify-content-between align-items-center`}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <span className={cx(styles.contactUs)}>Contact Us</span>
                            <a
                                data-testid='support-contact-link'
                                href='tel:1-800-123-0267'
                                className={cx(styles.telephoneText)}
                            >
                                1-800-123-0267
                            </a>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <span className={cx(styles.emailUs)}>Email Us</span>
                            <a
                                data-testid='support-email-link'
                                href='mailto:itsupport.team@magentamobility.com'
                                className={cx(styles.emailText)}
                            >
                                support@norminc.in
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isOpenForgot}
                title="Forgot Password"
                onClose={handleForgotModal}
                variant='auth'
            >
                <div className={cx(styles.forgotContainer)}>
                    <span className= {cx(styles.forgotText)}>
                        Enter your email address and you will receive an
                        email with a one-time password
                    </span>
                    <div className={cx(styles.forgotform)}>
                    <label className='text-white'>Email ID <span style={{color:'#C417E0'}}>*</span></label>
                        <BasicInput
                            value={resetFormData.email}
                            onChange={(e) => handleResetPasswordOnChange({ name: 'email', value: e.target.value })}
                            error={resetFormError.email}
                            type='email'
                            placeholder='Enter Email ID'
                        />
                    </div>
                    <Button className={cx(styles.forgotbutton)} type='submit' disabled={ !resetFormData.email || resetFormError.email} onClick={handleSendOtp}>Submit</Button>
                </div>
            </Modal>
            <OtpModal
                isOpen={isOtpModalOpen}
                closeModal={handleOtpModal}
                onChange={(value) => handleResetPasswordOnChange({ name: 'otp', value })}
                value={resetFormData.otp}
                error={resetFormError.otp}
                userEmail={resetFormData.email}
                resendOtpHandler={() => handleResendOtp()}
                onPrevoiusPageClick={handleOtpModal}
            />
            {/* {showAddressComponent ? <Address /> : null} */}
            <Loader isLoading={isLoading} />
        </>

    )
}

export default Login
/*conflict-resolution-will be removed later*/