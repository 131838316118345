import React, { useEffect, useState, memo } from "react";
import cx from "classnames";
import styles from "./CALocation.module.css";
import CACreateLocation from "./LocationAddEdit/CACreateLocation";
import Table from "../../../shared/table/Table";
import Pagination from "../../../shared/pagination/Pagination";
import Button from "../../../shared/form/Button/Button";
import moment from "moment";
import { UpdateLocation, GetAllCustomerAdminLocations } from "../../../../store/actions/CAFlow/CAFlowActions";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../shared/form/SearchInput/SearchInput";
import UpdateLocationModal from './LocationAddEdit/Modal'
import InformativeModal from "../../../shared/modals/InformativeModal/InformativeModel";
import Dropdown from "../../../shared/ui/Dropdown/Dropdown";

const CALocation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchedTerm, setSearchedTerm] = useState("");

  const [sortField, setSortfield] = useState({ sortBy: "", sortOrder: "" });

  const [modal, setModalOptions] = useState({
    isOpen: false,
    name: '',   // location name
    locationId: '', // _id of that row
    status: null,
    row: {}
  })

  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: '',
    variant: ''
})


  const handleCloseClick = () => {
    setModalOptions({
      ...modal,
      isOpen: false,
      name: ''
    })
  }

  const handleLocationUpdate = ({row}) => {
    setModalOptions({
      ...modal,
      isOpen:true,
      name: row.city,
      locationId: row._id,
      status: row.isActive ? false : true ,
      row
    })
  }
  
  const handleUpdateLocationAPI = async() => {
    console.log('modal', modal)
    if(modal.locationId) {
      const locationId = modal.locationId
      const isActive = modal.status
      const message = isActive ? 'Successfully Activated!' : 'Successfully Deactivated!'
      const res = await UpdateLocation({ locationId, isActive })
      if(res.status === 200) {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message,
        variant: 'success'
    })
    setTimeout(() => {
      setModalOptions({
        ...modal,
        isOpen: false,
        name: '',   
        locationId: '',
        row: {},
        status: null
      })
    }, 2002)
    }
    }
    
  }


  const locations = useSelector(
    (s) => s?.customerAdmin?.customerAdminLocations
  );
  const totalDataCount = useSelector(
    (s) => s?.customerAdmin?.customerAdminLocationsDataCount
  );

  const dispatch = useDispatch();

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const ITEMS_PER_PAGE = 8;

  const sortHandler = (sortBy, sortOrder) => {
    setSortfield({
      sortBy,
      sortOrder,
    });
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;

  const columns = [
    {
      key: "serialNo",
      header: "S.No",
      sortable: false,
      renderCell: (row, index) => <>{startIndex + index + 1}</>,
    },
    {
      key: "city",
      header: "LOCATION NAME",
      sortable: true,
      sortFieldName: "city",
      renderCell: (row) => <>{row.city}</>,
    },
    {
      key: "createdAt",
      header: "CREATED ON",
      sortable: true,
      sortFieldName: "createdAt",
      renderCell: (row) => <>{moment(row.createdAt).format("DD MMM YYYY")}</>,
    },
    {
      key: "isActive",
      header: "STATUS",
      sortable: true,
      sortFieldName: "isActive",
      renderCell: (row) => (
        <span style={{ color: row.isActive === true ? "#69FE97" : "#F29900" }}>
          {row.isActive ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      key: "action",
      header: "ACTION",
      sortable: false,
      renderCell: (row, index) => (
        <Dropdown
          triggerComponent={<img src="/images/verticalThreedots.svg" alt="more menu icon" />}
          dropdownItems={[{
            label: row.isActive ? "Deactivate" : "Activate",
            value: row.isActive ? "Deactivate" : "Activate",
            keyExtractor: () => `deactivate_${index}`,
            onClick: () => handleLocationUpdate({row})
          }]}
        />
      ),
    },
  ];
  const getCustomerAdminLocations = () => {
    dispatch(
      GetAllCustomerAdminLocations({
        data: {
          searchKey: searchedTerm,
          pageNumber: currentPage,
          pageSize: ITEMS_PER_PAGE,
          sortByColumn: sortField.sortBy,
          sortOrder: sortField.sortOrder,
        },
      })
    );
  };

  useEffect(() => {
    getCustomerAdminLocations();
    // eslint-disable-next-line
  }, [searchedTerm, currentPage, isOpen, sortField, modal.isOpen]);

  const setPageNumber = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className={cx(styles.department_container, 'd-flex col-12 flex-column h-70 mt-2')}>
        <div className="d-flex justify-content-between  flex-row my-3">
          <div>
            <SearchInput
              setSearchKey={setSearchedTerm}
              setCurrentPage={setCurrentPage}
              placeholder="Search"
            />
          </div>

          <div className="d-flex flex-row justify-content-end ">
            <div
              className={cx(styles.createDepartmentButton)}
            >
              <Button variant="BLUE" onClick={() => setIsOpen(true)}>
                Create Location
              </Button>
            </div>
          </div>
        </div>
        <Table
          data={locations}
          columns={columns}
          handleSortHandler={(e, v) => sortHandler(e, v)}
        />
      </div>
      <UpdateLocationModal
          isVisible={modal.isOpen}
          closeModal={handleCloseClick}
          name={modal?.name}
          handleUpdateStatus={handleUpdateLocationAPI}
          status={modal?.status}
        />
        <InformativeModal
                isOpen={apiResponse.isOpen}
                onClose={() => setApiResponse({
                    ...apiResponse,
                    isOpen: false
                })}
                message={apiResponse.message}
                variant={apiResponse.variant}
            />
      <CACreateLocation isOpen={isOpen} onClose={handleOnClose} />
      {totalDataCount !== 0 ? (
        <div className={cx(styles.paginationContainer, 'mb-1 position-relative')}>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(totalDataCount / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setPageNumber}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(CALocation);
/*conflict-resolution-will be removed later*/