import ActionTypes from "../../actionTypes";
const {
  CA_FLOW: {
    GET_ALL_CUSTOMER_ADMIN_LOCATION_SUCCESS,
    GET_ALL_CUSTOMER_ADMIN_LOCATION_FAILED,
    GET_ALL_MASTER_LOCATIONS_SUCCESS,
    GET_ALL_MASTER_LOCATIONS_FAILED,
  },
} = ActionTypes;

const initialState = {
  masterLocations: [],
  customerAdminLocations: [],
  customerAdminLocationsDataCount: 0,
};

const customerAdmin = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_MASTER_LOCATIONS_SUCCESS:
      return {
        ...state,
        masterLocations: payload,
      };

    case GET_ALL_MASTER_LOCATIONS_FAILED:
      return {
        ...state,
        masterLocations: [],
      };

    case GET_ALL_CUSTOMER_ADMIN_LOCATION_SUCCESS:
      return {
        ...state,
        customerAdminLocations: payload?.data,
        customerAdminLocationsDataCount: payload?.totalCount,
      };
    case GET_ALL_CUSTOMER_ADMIN_LOCATION_FAILED:
      return {
        ...state,
        customerAdminLocations: [],
        customerAdminLocationsDataCount: 0,
      };

    default:
      return state;
  }
};

export default customerAdmin;
/*conflict-resolution-will be removed later*/