import React, { Component, createRef } from 'react'
// import { CloseButton } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import NdMap from '../../NewDashboardDark/nd-map/NdMap_Dark'
import NDVehicleListing from '../../NewDashboardDark/nd-vehicleListing/NDVehicleListing'

import { AddFavoriteVehicle, DeleteFavoriteVehicle, GetAllFavoriteVehicle} from '../../../../store/actions/favoriteVehicle'

import './GroupVehicleListing.css'
import Button from '../../../shared/form/Button/Button'
import { nonCommStatusLabelMapping } from '../../../../utils/constants'

const defaultMapConfig = {
    center: [
        20.5937,
        78.9629,
    ],
    zoom: 5,
    attributionControl: false,
    maxZoom: 22,
    minZoom: 5,
    zoomControl: false,
    enableMarkerCluster: true,
    zoomControlPosition: 'topright'
}

const defaultMarkerClusterConfig = {
    chunkedLoading: true,
    disableClusteringAtZoom: 10,
    maxClusterRadius: 15
}


class GroupVehicleListing extends Component {
    constructor(props) {
        super(props);
        this.mapRef = createRef(null);
        this.state = {
            mapConfig: defaultMapConfig,
            clusterConfig: defaultMarkerClusterConfig,
            filterCount: {
                live: 0,
                // Note: Merge Idle status to Live status
                // idle: 0,
                charging: 0,
                parked: 0,

                poorNetwork: 0,
                pluggedOut: 0,
                deviceFault: 0,
                zeroData: 0,
                inactiveGps: 0,
                all: 0
            },
            activeStatus: 'all',
            activeVehicleData: [],
            hoverCloseIcon: false,
            isSidebarOpen: false,
            filters:{
                location:[],
                vehicleCategory:[],
                oemModel:[],
                dataSource:[],
                groups:[],
                soc:[]
            },
        }
        
    }

    componentDidMount(){
        const { vehicleData } = this.props;
        if(vehicleData.length){

            const vehicleCount = this.filterVehicleDatabasedOnStatus()

            this.setState({
                filterCount: {
                    live: vehicleCount.live.length,
                    // Note: Merge Idle status to Live status
                    // idle: vehicleCount.idle.length,
                    charging: vehicleCount.charging.length,
                    parked: vehicleCount.parked.length,
                    poorNetwork: vehicleCount.poorNetwork.length,
                    pluggedOut: vehicleCount.pluggedOut.length,
                    deviceFault: vehicleCount.deviceFault.length,
                    zeroData: vehicleCount.zeroData.length,
                    inactiveGps: vehicleCount.inactiveGps.length,
                    all: vehicleCount.all.length
                },
                // NOTE: for temporary basis passing complete vehicle data
                activeVehicleData: vehicleCount[this.props.vehicleListGroupDetails.filterStatus],
                activeStatus: this.props.vehicleListGroupDetails.filterStatus
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { vehicleData, vehicleListGroupDetails } = this.props;
    
        // Check if the vehicleData has changed and if we need to update the state
        if (
            vehicleData !== prevProps.vehicleData || 
            vehicleListGroupDetails.filterStatus !== prevProps.vehicleListGroupDetails.filterStatus
        ) {
            const vehicleCount = this.filterVehicleDatabasedOnStatus();
    
            // Update state only if necessary
            if (
                vehicleCount[this?.state.activeStatus]?.length !== this?.state.activeVehicleData?.length ||
                vehicleCount[this?.props?.vehicleListGroupDetails?.filterStatus]?.length !== this?.state?.filterCount[this?.props?.vehicleListGroupDetails?.filterStatus]
            ) {
                this.setState({
                    filterCount: {
                        live: vehicleCount.live?.length,
                        charging: vehicleCount.charging?.length,
                        parked: vehicleCount.parked?.length,
                        poorNetwork: vehicleCount.poorNetwork?.length,
                        pluggedOut: vehicleCount.pluggedOut?.length,
                        deviceFault: vehicleCount.deviceFault?.length,
                        zeroData: vehicleCount.zeroData?.length,
                        inactiveGps: vehicleCount.inactiveGps?.length,
                    },
                    activeVehicleData: vehicleCount[vehicleListGroupDetails?.filterStatus],
                    activeStatus: vehicleListGroupDetails?.filterStatus
                });
            }
        }
    }
    

    onCardClick = (vehicleData) => {
        this.props.handleShowVehicleDetails(vehicleData.registrationNumber, this.state.activeStatus)
    }

    onFavoriteFlagClick = async (registration_number, isFavorite) => {
        if (isFavorite) {
            this.props.DeleteFavoriteVehicle({ registration_number });
        } else {
            this.props.AddFavoriteVehicle({ registration_number });
        }
        this.props.GetAllFavoriteVehicle();
    }

    filterVehicleDatabasedOnStatus = () => {
        const { vehicleData } = this.props;
        if(vehicleData.length){
            const communicatingVehicle = vehicleData?.filter(fd => !fd?.ncom)

            const all = vehicleData
            
            const live  = communicatingVehicle?.filter(fd => ["Live", "Idle"].includes(fd?.canStatus?.vs));
    
            // Note: Merge Idle status to Live status
            // const idle = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Idle");
    
            const parked = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Parked");
    
            const charging = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Charging");

            const nonCommunication = vehicleData?.filter(fd => fd?.ncom)

            const poorNetwork = nonCommunication.filter(fd => fd?.ncomT === 'Poor Network')

            const pluggedOut = nonCommunication.filter(fd => fd?.ncomT === 'Plugged Out')

            const deviceFault = nonCommunication.filter(fd => fd?.ncomT === 'Device Fault')

            const zeroData = nonCommunication.filter(fd => fd?.ncomT === 'Zero Data')

            const inactiveGps = nonCommunication.filter(fd => fd?.ncomT === 'Inactive GPS')

            return { live, parked, charging, poorNetwork, pluggedOut, deviceFault, zeroData, inactiveGps, all  }
        }

        return {}
    }

    handleVehicleStatus = (status) => {
        const vehicle = this.filterVehicleDatabasedOnStatus()
        this.setState({ activeStatus: status, activeVehicleData: vehicle[status] })
    }

    // handle soc table vehicle gps click.
    handleGpsClick = (vehicle) => {
        const mapConfigObj = JSON.parse(JSON.stringify(this.state.mapConfig))
        this.setState({ selectedVehicleNumber: vehicle, detailsPanelOpen: 1, mapConfig: mapConfigObj })
    }

    // handle location select
    handleLocationClick = (location) => {
        this.props.setLocationFilterData(location)
        const filterLocation =this.props.masterLocationData?.filter(x => x.value.toLowerCase() === location.toLowerCase())
        if(filterLocation && filterLocation.length === 1) {
            this.mapRef.current.setView([filterLocation[0].meta?.lat, filterLocation[0].meta?.long], 10)
        }
        this.props.setLocationFilterData(location)
    }

    handleVehicleMarkerClick = ({ registrationNumber, lat, long }) => {
        this.mapRef.current.setView([lat, long],10)
        this.props.handleShowVehicleDetails(registrationNumber, this.state.activeStatus)
    }

    mapReset = () => {
        this.resetState();
    }

    // reset state
    resetState = () => {
        this.setState({
        mapConfig: { ...defaultMapConfig },
        clusterConfig: { ...defaultMarkerClusterConfig },
        },() =>
        this.mapRef?.current?.setView(this.state.mapConfig.center,this.state.mapConfig.zoom)
        )
    }

    handleUpdateMapConfigForZoom = (key) => {
        const mapConfigObj = JSON.parse(JSON.stringify(this.state.mapConfig))
        if(key === 'zoomIn') {
          this.mapRef.current.setZoom(this.mapRef.current.getZoom() + 1)
          mapConfigObj.zoom = this.state.mapConfig.zoom + 1
        }
        if(key === 'zoomOut') {
          this.mapRef.current.setZoom(this.mapRef.current.getZoom() - 1)
          mapConfigObj.zoom = this.state.mapConfig.zoom - 1
        }
        this.setState({ mapConfig: { ...mapConfigObj } })
    }

    handleClusterClick = (e) => {
           const center = [e.latlng.lat, e.latlng.lng];
            if (this.mapRef && this.mapRef.current) {
                this.mapRef.current.setView(center, 10);
                
            }
    };
    
    
    render() {
        const { favouriteVehicles, handleOnCloseSidebar,masterLocationData, vehicleListGroupDetails } = this.props;
        const { filterCount, activeStatus, activeVehicleData, mapConfig, clusterConfig } = this.state;
        return (
            <div className='group-listing group-listing-dark'>
                <div className='gl__sidebar gl__sidebar-space px-3'>
                    <div className='gl__sidebar-header'>
                        <span className='gl__sidebar-title'>{vehicleListGroupDetails?.groupName}</span>
                        {/* <CloseButton data-testid='close-panel-icon' onClick={handleOnCloseSidebar} /> */}

                        <img 
                            data-testid='close-panel-icon'
                            style={{ height:'35px', width: 'auto', cursor:"pointer" }}
                            onClick={() => this.setState({ hoverCloseIcon: false }, handleOnCloseSidebar)}
                            src={this.state.hoverCloseIcon ? '/images/svgicon/trackingScreen/close_active.svg' : "/images/svgicon/trackingScreen/Close_fad.svg"}
                            alt='close-btn'
                            onMouseOver={() => this.setState({hoverCloseIcon : true})}
                            onMouseOut={() => this.setState({hoverCloseIcon : false})}
                        />
                    </div>
                    <div className='group_vehicle_listing'>
                        <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'all' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('all')}
                            style={{
                                padding: '8px 16px',
                                fontSize: '0.75rem'
                            }}
                            >
                        <span>All ({filterCount.all})</span>
                        </Button>
                        <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'live' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('live')}
                            style={{
                                padding: '8px 16px',
                                fontSize: '0.75rem'
                            }}
                            >
                        <span>Live ({filterCount.live})</span>
                        </Button>
                        <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'charging' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('charging')}
                        style={{
                            padding: '8px 10px',
                            fontSize: '0.75rem'
                        }}
                            >
                        <span>Charging ({filterCount.charging})</span>
                        </Button>
                        <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'parked' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('parked')}
                        style={{
                            padding: '8px 10px',
                            fontSize: '0.75rem'
                        }}
                            >
                        <span>Parked ({filterCount.parked})</span>
                        </Button>
                    </div>
                    <div className='group_vehicle_listing'>
                    <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'poorNetwork' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('poorNetwork')}
                        style={{
                            padding: '8px 10px',
                            fontSize: '0.75rem',
                            lineHeight: '1.36'
                        }}
                        >
                        <span>{nonCommStatusLabelMapping['Poor Network']} ({filterCount.poorNetwork})</span>
                        </Button>
                        <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'pluggedOut' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('pluggedOut')}
                        style={{
                            padding: '8px 10px',
                            fontSize: '0.75rem',
                            lineHeight: '1.36'
                        }}
                            >
                        <span>{nonCommStatusLabelMapping['Plugged Out']} ({filterCount.pluggedOut})</span>
                        </Button>
                        
                     
                        <Button data-testid='filter-vehicle-button-charging' variant={activeStatus === 'inactiveGps' ? 'PINK' : 'BLUE'} onClick={() => this.handleVehicleStatus('inactiveGps')}
                        style={{
                            padding: '8px 10px',
                            fontSize: '0.75rem',
                            lineHeight: '1.36'
                        }}
                            >
                        <span>{nonCommStatusLabelMapping['Inactive GPS']} ({filterCount.inactiveGps})</span>
                        </Button>
                    </div>
                    <div className='gl__sidebar-body-list'>
                        <NDVehicleListing
                            onCardClick={this.onCardClick}
                            onFavoriteFlagClick={this.onFavoriteFlagClick}
                            allVehiclesTrackingData={activeVehicleData}
                            favouriteVehicles={favouriteVehicles}
                        />
                    </div>
                </div>
                <div className='gl__map-container'>
                    <NdMap
                        variant="GROUPS_VEHICLE_LIST"
                        mapConfig={mapConfig}
                        mapRef={this.mapRef}
                        clusterConfig={clusterConfig}
                        vehicleData={activeVehicleData}
                        masterLocationData={masterLocationData}
                        isSocSidebarOpen={false}
                        handleGpsClick={this.handleGpsClick}
                        onLocationClick={this.handleLocationClick}
                        onVehicleMarkerClick={this.handleVehicleMarkerClick}
                        reset={this.mapReset}
                        onUpdateMapConfigForZoom={this.handleUpdateMapConfigForZoom}
                        onClusterClick={this.handleClusterClick}
                        filters={this.state.filters}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    masterLocationData: state.getAllUserRoleReducer.masterLocationData,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        AddFavoriteVehicle,
        DeleteFavoriteVehicle,
        GetAllFavoriteVehicle,
    },
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(GroupVehicleListing)