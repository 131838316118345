import ActionTypes from "../../../actionTypes"
import api from "../../../../service/api"
import API_BOOK from "../../../../service/endpoints";
import { customToast } from "../../../../utils/toasts";
import { NotificationMessage } from "../../../../utils/constants";
const actions = ActionTypes.REPORTS_ANALYTICS.TRIP_REPORT_ACTIONS
const getApiList = API_BOOK.TRIP_REPORT_ANALYTICS.TRIP_OEM_MODEL;


export const onDateChangeHandler =
  ({ startDate, endDate }) =>
    (dispatch) => {
      dispatch({
        type: actions.SET_CUSTOM_DURATION,
        payload: { startDate, endDate },
      });
    };

export const setSelectedDuration = (duration) => (dispatch) => {
  dispatch({ type: actions.SET_SELECTED_DURATION, payload: duration });
};

export const setTripReportData = (payload) => (dispatch) => {
  dispatch({ type: actions.SET_TRIP_REPORT_DATA, payload: payload });
};

const getTripReportModelData = (payload) => ({
  type: actions.TRIP_REPORT_MODEL_SUCCESS,
  payload,
});


  /**
 *  @desc         Get city wise trip report
 *  @author       Parvez Siddique
 *  @api          /reports/analytics/trip/by-city-oem-model
 *  @method       POST
 *  @createdDate  OCT 16, 2023
 *  @modifiedDate OCT 18, 2023
 **/



export const getOemCityWiseTripReport = ({ filter, filterType }) => async (dispatch, state) => {
  const { isCustomDateSelected, customFromDate, customToDate, selectedDuration, currentPage } = state().tripReportAnalytics
  const dates = isCustomDateSelected ? { from: customFromDate, to: customToDate } : getFromAndToDate(selectedDuration)
  if (dates.from === null || dates.to === null) return

  if(!filterType || filterType === "") return

  try {
    if (filterType !== "City") {
      const splitArray = filter.split(' ');
      const oem = splitArray[0];
      const model = splitArray.slice(1).join(' ');
      filter = { oem, model }
    }
    else {
      filter = { city: filter }
    }
    const resp = await api({
      method: 'POST',
      url: '/reports/analytics/trip/by-city-oem-model',
      data: {
        fromDate: dates.from,
        toDate: dates.to,
        pageIndex: currentPage - 1,
        pageSize: 8,
        filterType: filterType === "City" ? "city" : "oemModel",
        filter: filter
      },
    })

    if (resp.status === 200 && resp?.data?.data) {
      const respData = resp?.data?.data
      dispatch({ type: actions.SET_OEM_CITY_WISE_TRIP_REPORT_DATA, payload: respData });

    }
  } catch (error) {
    console.log('error:', error)
  }
}


 /**
 *  @desc         Export city wise trip report
 *  @author       Parvez Siddique
 *  @api          /reports/analytics/trip/by-city-oem-model/export
 *  @method       POST
 *  @createdDate  OCT 16, 2023
 *  @modifiedDate OCT 18, 2023
 **/



export const exportOemCityWiseTripReport = () => async (dispatch, state) => {
  let { isCustomDateSelected, customFromDate, customToDate, selectedDuration, currentPage, filter, filterType } = state().tripReportAnalytics
  const dates = isCustomDateSelected ? { from: customFromDate, to: customToDate } : getFromAndToDate(selectedDuration)
  if (dates.from === null || dates.to === null) return

  try {
    if (filterType !== "City") {
      const splitArray = filter.split(' ');
      const oem = splitArray[0];
      const model = splitArray.slice(1).join(' ');
      filter = { oem, model }
    }
    else {
      filter = { city: filter }
    }

    const resp = await api({
      method: 'POST',
      url: '/reports/analytics/trip/by-city-oem-model/export',
      data: {
        fromDate: dates.from,
                toDate: dates.to,
                pageIndex: currentPage,
                pageSize: 8,
                filterType: filterType === "City" ? "city" : "oemModel",
                filter: filter
      },
    });

    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  }
  catch (error) {
    console.log('error:', error)
  }
}


 /**
 *  @desc         Telematics API for Filter change
 *  @author       Parvez Siddique
 *  @api          /reports/analytics/trip
 *  @method       POST
 *  @createdDate  OCT 16, 2023
 *  @modifiedDate OCT 18, 2023
 **/


export const onFilterChange = () => async (dispatch, state) => {
  const { isCustomDateSelected, customFromDate, customToDate, selectedDuration } = state().tripReportAnalytics
  const dates = isCustomDateSelected ? { from: customFromDate, to: customToDate } : getFromAndToDate(selectedDuration)
  if (dates.from === null || dates.to === null) return

  try {
    const resp = await api({
      method: 'POST',
      url: '/reports/analytics/trip',
      data: {
        fromDate: dates.from,
        toDate: dates.to,
        intervalDuration: calculateIntervalDuration(dates.from, dates.to)
      },
    })

    if (resp.status === 200 && resp?.data?.data) {
      const respData = resp?.data?.data
      dispatch({ type: actions.SET_AREA_CHART_DATA_BY_CITY, payload: respData?.areaChartDataByCity })
      dispatch({ type: actions.SET_AREA_CHART_DATA_BY_OEM_MODEL, payload: respData?.areaChartDataByOemAndModel })
      dispatch({ type: actions.SET_AREA_CHART_OEM_MODEL_LEVEL, payload: getOemLevelForAreaChart(respData?.countByOemAndModel) })
      dispatch({ type: actions.SET_AREA_CHART_CITY_LEVEL, payload: getCityLevelForAreaChart(respData?.countByCity) })
      dispatch(setTripReportData(resp.data.data));

    } else {
    }
  } catch (error) {
    console.log('error:', error)
  }
}

const calculateIntervalDuration = (fromDate, toDate) => {
  const from = new Date(fromDate)
  const to = new Date(toDate)
  const diff = Math.abs(to - from)
  const minutes = Math.floor((diff / 1000) / 60)

  //for less than 1 day return 1 hr
  if (minutes < 1440) return 30
  //for less than 3 days return 4 hrs
  else if (minutes < 4320) return 120
  //for less than 7 days return 12 hrs
  else if (minutes < 10080) return 720
  //for less than 16 days return 1 day
  else if (minutes < 23040) return 1440
  //for greater than 16 days return 2 day
  return 2880
}

const getOemLevelForAreaChart = (countByOemAndModel) => {
  const oemLevel = countByOemAndModel?.data?.map((item, index) => {
    return {
      key: item.oemAndModel,
      value: "level-" + index
    }
  })
  return oemLevel || []
}

const getCityLevelForAreaChart = (countByCity) => {
  const cityLevel = countByCity?.data?.map((item, index) => {
    return {
      key: item.city,
      value: "level-" + index
    }
  })
  return cityLevel || []
}


const getFromAndToDate = (duration) => {
  const today = new Date();
  const dates = {
    from: null,
    to: null,
  };
  switch (duration) {
    case "7days": // Last 7 days
      const last7Days = new Date(today);
      const yesterdayy = new Date(today);
      yesterdayy.setDate(today.getDate() - 1);
      last7Days.setDate(today.getDate() - 7);
      dates.from = new Date(
        last7Days.setHours(0, 0, 0, 0))
      dates.to = new Date(
        yesterdayy.setHours(23, 59, 59, 999))
      break;
    case "yesterday": // Yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      dates.from = new Date(
        yesterday.setHours(0, 0, 0, 0))
      dates.to = new Date(
        yesterday.setHours(23, 59, 59, 999))
      break;
    case "today": // Today
      dates.to = new Date(
        today.setHours(today.getHours(), today.getMinutes(), 0, 0))
      dates.from = new Date(
        today.setHours(0, 0, 0, 0))
      break;
    default:
      console.log("dafault filter index not found");
      break;
  }
  return dates;
};


/**
 *  @desc         Telematics API for Get all trips model
 *  @author       Parvez Siddique
 *  @api         /reports/analytics/trip/by-city-oem-model
 *  @method       POST
 *  @createdDate  OCT 16, 2023
 *  @modifiedDate OCT 18, 2023
 **/

export const GetAllTripsModelData =
  ({ data }) =>
    async (dispatch) => {
      try {
        const resp = await api({
          method: getApiList.method,
          url: getApiList.url,
          data: data,
        });

        if (resp.status === 200) {
          const response = resp?.data?.data;
          dispatch(getTripReportModelData(response));
        }
      } catch (error) {
        console.log("error", error);
      }
    };

export const setReportCurrentPage = (page) => (dispatch) => {
  dispatch({ type: actions.SET_REPORTS_CURRENT_PAGE, payload: page })
}

export const setOemCityWiseTripReportFilter = (filter) => (dispatch) => {
  dispatch({ type: actions.SET_OEM_CITY_WISE_TRIP_REPORT_FILTER, payload: filter })
}


