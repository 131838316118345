import React from 'react'
import { range } from '../../../../utils/common-methods'
import './Pagination.css'

const Pagination = ({ setPage, currentPage, totalPages, disabled = false, ...props }) => {
  const baseTestId = props['data-testid'] || 'pagination'
  let paginationWidth = 2
  let pageButtonsStart = Math.max(1, currentPage - paginationWidth)
  let pageButtonsEnd = Math.min(currentPage + paginationWidth, totalPages)

  const pagesLeft = range(pageButtonsStart, currentPage).length - 1
  const pagesRight = range(currentPage, pageButtonsEnd).length - 1

  if (pagesLeft < paginationWidth) {
    pageButtonsEnd = Math.min(
      totalPages,
      pageButtonsEnd +
        Math.min(paginationWidth - pagesLeft, totalPages - currentPage)
    )
  } else if (pagesRight < paginationWidth) {
    pageButtonsStart = Math.max(
      1,
      pageButtonsStart -
        Math.max(paginationWidth - pagesRight, totalPages - currentPage)
    )
  }

  return (
    <div className="d-flex justify-content-end p-2">
      <ul className="pagination m-1">
        <li className="page-item">
          <button
            className="page-link btn-color-first"
            aria-label="First"
            onClick={() => setPage(1)}
            data-testid={`${baseTestId}-first-page`}
            disabled={disabled || currentPage === 1}
          >
          
            <img src='/images/svgicon/pagination-arrow-right.svg' alt='last' className='last-arrow' />
          </button>
        </li>
        <li className="page-item ">
          <button
            className="page-link btn-color-second  count-btn"
            aria-label="Previous"
            onClick={() => setPage(currentPage - 1)}
            disabled={disabled || currentPage === 1}
            data-testid={`${baseTestId}-previous-page`}
          >
            <span aria-hidden="true" className='second-arrow'>&lt;</span>
            <span className='page-item-line'></span>
          </button>
           
        </li>
        
        {range(pageButtonsStart, pageButtonsEnd).map((pn) => (
          <li
            key={pn}
            className={`page-item ${pn === currentPage ? 'active' : ''}`}
          >
            <button
              className="page-link  count-btn"
              disabled={disabled}
              onClick={() => setPage(pn)}
              data-testid={`${baseTestId}-page-${pn}`}
            >
              {pn}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link btn-color-second  count-btn "
            aria-label="First"
            disabled={disabled || currentPage === totalPages}
            onClick={() => setPage(currentPage + 1)}
            data-testid={`${baseTestId}-next-page`}
          >
            <span aria-hidden="true" className='second-arrow'>&gt;</span>
            <span className='page-item-line-second'></span>
          </button>
        </li>
        <li className="page-item">
          <button
            className="page-link btn-color-first"
            aria-label="Previous"
            disabled={disabled || currentPage === totalPages}
            onClick={() => setPage(totalPages)}
            data-testid={`${baseTestId}-last-page`}
          >
           
            <img src='/images/svgicon/pagination-arrow-rightt.svg' alt='last' className='last-arrow' />
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
