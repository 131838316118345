import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();
        const routeParams = useParams();
        const location = useLocation();
        return <Component navigate={navigate} routeParams={routeParams} location={location} {...props} />;
    };

    return Wrapper;
};

export { withRouter };