import { Row, Col, Form } from 'react-bootstrap'
import { connect} from 'react-redux'
const LabelOwnerEditRow = (
        {
            rowKey,
            labelOwnerName,
            labelOwnerNameOnChange,
            onDelete,
            isEditable,
            setEdit,
            isValid,
            validationMessage
        }
    )=> {

    const handleLabelOwnerNameChange =(e)=> {
        labelOwnerNameOnChange(e.target.value, rowKey)
    }

    const deleteLabelOwner = ()=> {
        onDelete(rowKey)
    }

    return (
        <Row style={{marginBottom:10}}>
            <Col className='geofence-configure-sub-text-label-owner-text' xs={2}>
                Label Owner Name
            </Col>
            <Col xs={2}>
                <Form.Group controlId="textBox">
                    <Form.Control
                    className='geofence-configure-placeholder-label-owner' 
                    disabled = {!isEditable}
                    value={labelOwnerName}
                    onChange={handleLabelOwnerNameChange}
                    type="text" 
                    placeholder="Enter text"
                    data-testid='labelowner-edit-name-input'
                />
                </Form.Group>
                {
                    !isValid &&
                    <div className='text-danger error-message'>{validationMessage}</div>
                }
            </Col>
            <Col xs={4} className="d-flex geofence-configure-sub-text-edit-delete-label-owner">


            <div className='geofence-configure-sub-text-edit-delete-label-owner' data-testid='labelowner-delete-button' onClick={deleteLabelOwner}>
                <img
                alt={'delete'}
                src='/images/svgicon/delete_button.svg'
                style={{width:"25%", marginLeft:10, marginRight:5}}
                ></img>
                Delete          
            </div>
                 
            <div className='geofence-configure-sub-text-edit-delete-label-owner' data-testid='labelowner-edit-button' onClick={() => {setEdit(rowKey)}}>
                <img
                alt={'delete'}
                src='/images/svgicon/edit_button.svg'
                style={{width:"25%", marginLeft: 10, marginRight:5}}
                ></img>
                Edit        
            </div>
            </Col>
        </Row>
    )
}

LabelOwnerEditRow.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
})(LabelOwnerEditRow)