import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BsFillCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import { ForgotPasswordUser } from '../../store/actions/forgotpassword';
import { SendOTP, VerifyOTP, SetIsOtpVerifiedState } from '../../store/actions/otp';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './forgotpassword.css';
import { CheckFieldErrors, countFormData } from '../../utils/validation/form-validator';
import { OtpSettings } from '../../utils/constants';
import ActionTypes from '../../store/actionTypes';
import AuthSidebar from '../authSidebar/authSidebar';
import PopOverComponentContainer from '../signup/PopOverComponent';
import Loader from '../helpers/hoc/loader/Loader';

// TODO: field error handling, api configs, api error handling

const Forgotpassword = ({
	ForgotPasswordUser,
	SendOTP,
	VerifyOTP,
	SetIsOtpVerifiedState,
	isOtpVerified,
	isLoading,
	otpTimerKey,
	isOtpSend,
	otpFieldDisabled,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const navigateFrom = location.state?.from?.pathname || '/login';
	const [isInitialRender, setIsInitialRender] = useState(true);
	const dispatch = useDispatch();
	const otpActionTypes = ActionTypes.OTP;
	const [otpTimer, setOtpTimer] = useState(OtpSettings.resendOtpDelayInSeconds);

	useEffect(() => {
		dispatch({ type: otpActionTypes.OTP_STATE_RESET });
	}, [dispatch, otpActionTypes.OTP_STATE_RESET]);

	useEffect(() => {
		if (isInitialRender) {
			SetIsOtpVerifiedState(false);
			setIsInitialRender(false);
		}
	}, [isOtpVerified, SetIsOtpVerifiedState, isInitialRender]);

	const initialFieldValues = {
		email: '',
	};
	const initialFieldErrors = {
		...initialFieldValues,
		password: '',
		confirmPassword: '',
		empty: false,
	};
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

	const [formData, setFormData] = useState(initialFieldValues);
	const [passwordFormData, setPasswordFormData] = useState({
		password: '',
		confirmPassword: '',
	});
	const [errorFields, setErrorFields] = useState(initialFieldErrors);
	const [isPasswordIntructNoteHidden, setIsPasswordIntructNoteHidden] = useState(true);
	const [isSendOtpButtonHidden, setIsSendOtpButtonHidden] = useState(false);
	const [isFocus, setIsFocus] = useState(false);
	const [otp, setOtp] = useState('');
	const [isEnterOTPFieldHidden, setIsEnterOTPFieldHidden] = useState(true);
	const [sendOTPButtonText, setSendOTPButtonText] = useState('Send OTP');
	const [passwordCriteria, setPasswordCriteria] = useState({
		'length >= 8': false,
		'length <= 31': false,
		'must contain digit': false,
		'must contain at least 1 lowercase': false,
		'must contain at least 1 uppercase': false,
		'must have at least 1 special character': false,
	});

	//validate password criteria
	const validatedPasswordCriteria = (password) => {
		const criteria = {
			'length > 8': password.length >= 8,
			'length < 31': password.length <= 31,
			'must contain digit': /\d/.test(password),
			'must contain at least 1 lowercase': /[a-z]/.test(password),
			'must contain at least 1 uppercase': /[A-Z]/.test(password),
			'must have at least 1 special character': /[!@#$%^&*]/.test(password),
		};

		const fulfilledCriteria = Object.keys(criteria).filter((criterion) => criteria[criterion]);

		let check = {};
		Object.keys(criteria).forEach((key) => {
			check[key] = !!fulfilledCriteria.includes(key);
		});

		return check;
	};

	const { email } = formData;
	const { password, confirmPassword } = passwordFormData;

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit(e);
		}
	};

	//handle restricted action
	const handleRestrictedAction = (e) => {
		e.preventDefault();
	};
	const onInputChange = (evt) => {
		const name = evt.target?.name;
		const value = evt.target?.value?.trim();
		const passwordFormDataObj = JSON.parse(JSON.stringify(passwordFormData));

		passwordFormDataObj[name] = value;
		if (name === 'password' && passwordFormDataObj.confirmPassword) {
			passwordFormDataObj.confirmPassword = '';
			setErrorFields({
				...errorFields,
				confirmPassword: '',
			});
		}

		setPasswordFormData({
			...passwordFormDataObj,
		});
		setPasswordCriteria(validatedPasswordCriteria(value));
	};

	// fields onblur handler
	const onBlurHandler = (evt) => {
		const name = evt.target?.name;
		const value = evt.target?.value?.trim();
		setIsFocus(false);

		if (Object.keys(passwordFormData).includes(name)) {
			CheckFieldErrors({ fieldName: name, value }, passwordFormData, errorFields, setErrorFields);
		} else {
			CheckFieldErrors({ fieldName: name, value }, {}, errorFields, setErrorFields);
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	const isValidForm = (form) => {
		// fill up all the fields

		if (!(countFormData(form) === Object.keys(form).length)) {
			setErrorFields({
				...errorFields,
				empty: true,
			});
			setTimeout(() => {
				setErrorFields({
					...errorFields,
					empty: false,
				});
			}, 2000);
			return false;
		}

		if (countFormData(form) === Object.keys(form).length && countFormData(errorFields) === 0 && isOtpVerified) {
			return true;
		} else if (countFormData(form) === Object.keys(form).length) {
			if (form.password !== form.confirmPassword) {
				setErrorFields({
					...errorFields,
					confirmPassword: 'Password does not match',
				});
			}
		}
	};

	const intervalRef = useRef(null);

	//sendotp button handler
	const sendOtpHandler = (event) => {
		event.preventDefault();
		dispatch({ type: otpActionTypes.OTP_STATE_RESET });
		setIsEnterOTPFieldHidden(false);
		setIsSendOtpButtonHidden(true);

		document.getElementById('email').disabled = true;

		setOtpTimer(OtpSettings.resendOtpDelayInSeconds);
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		intervalRef.current = setInterval(() => {
			setOtpTimer((prev) => {
				const newTimer = prev - 1;
				return newTimer >= 10 ? newTimer : `0${newTimer}`;
			});
		}, 1000);

		setTimeout(() => {
			setSendOTPButtonText('Resend OTP');
			setIsSendOtpButtonHidden(false);
		}, OtpSettings.resendOtpDelayInSeconds * 1000);
		SendOTP({ email, forgotPassword: 'Y' });
	};

	//change otp handler
	const otpChangeHandler = (event) => {
		const name = event.target?.name;
		const value = event.target?.value?.trim();

		CheckFieldErrors({ fieldName: name, value }, {}, errorFields, setErrorFields);
		setFormData({
			...formData,
			[name]: value,
		});

		if (event.target.value?.match(/^[0-9]+$/) != null || event.target.value.length === 0) {
			const otp = event.target.value?.trim();
			setOtp(otp);
			if (otp?.toString()?.length === 6) {
				VerifyOTP({ email, otp });
			}
		}
	};

	//forgot pass submit button 
	const onSubmit = (e) => {
		e.preventDefault();
		if (isValidForm(passwordFormData)) {
			ForgotPasswordUser({
				email,
				password,
				navigate,
				navigateFrom,
			});
		}
	};

	//valid password instructions
	const showPasswordIntruct = () => {
		setIsPasswordIntructNoteHidden(false);
	};

	const hidePasswordIntruct = () => {
		setIsPasswordIntructNoteHidden(true);
	};

	const togglePasswordVisibility = () => {
		setIsPasswordVisible((prev) => !prev);
	};

	const toggleConfirmPasswordVisibility = () => {
		setIsConfirmPasswordVisible((prev) => !prev);
	};

	return (
		<div
			className='row text-center '
			style={{
				width: '99%',
			}}
		>
			<AuthSidebar width='51.3%' />
			<div
				className=' mt-3'
				style={{
					width: '48.7%',
				}}
			>
				<div
					style={{
						marginTop: '35px',
					}}
				>
					<div className='col-12'>
						<div
							className='heading-container   forgot-heading  auth-page-top-heading-forgot-password'
							style={{ fontWeight: '600' }}
						>
							Forgot Password
						</div>
						<div className='forgot-password-note' style={{ color: '#C7C7C7', fontSize: '14px' }}>
							{!isEnterOTPFieldHidden && isOtpSend ? (
								<>The forget password will be sent to the mailbox.</>
							) : (
								<>Please enter your registered email address</>
							)}
						</div>
					</div>
					<div className='col-12'>
						<div className='row d-flex flex-row justify-content-center'>
							<div className='col-8'>
								<div className='forgot-form-container'>
									<form
										style={{ padding: '2.7rem' }}
										className='forgot-form  pb-5'
										onSubmit={onSubmit}
										onKeyDown={handleKeyDown}
									>
										<div className='text-start'>
											<label
												className='form-label'
												htmlFor='username'
												style={{ fw: '500 !important' }}
											>
												Email
											</label>
										</div>
										<div 
									
										className={`form-label-group required ${!email ? 'no-value-selected-input' : ''}`}
										>
											<input
												data-testid='email-input'
												type='text'
												id='email'
												name='email'
												className='form-control inputfield'
												onBlur={onBlurHandler}
											/>
										</div>
										<div className='pt-2 text-left'>
											{!errorFields.email &&
												email.length > 0 &&
												!isOtpVerified &&
												!isSendOtpButtonHidden && (
													<div className='col-6'>
														<div style={{ width: 100 }}>
															<span data-testid='send-otp-button' onClick={sendOtpHandler}>
																<p className='send-otp '>{sendOTPButtonText}</p>
															</span>
														</div>
													</div>
												)}
										</div>

										{isSendOtpButtonHidden && isOtpSend && (
											<>
												<span
													data-testid='otp-timer'
													className='otp-timer'
													style={{ marginLeft: '-165px' }}
												>
													Resend OTP  <span style={{ color: '#1F2A5D' }}>in 00:{otpTimer}</span> 
												</span>
											</>
										)}

										{errorFields.email && (
											<div className='col-6 pe-2'>
												<div  data-testid='email-error-message' className='text-danger error-message'>{errorFields.email}</div>
											</div>
										)}

										{!isEnterOTPFieldHidden && isOtpSend && (
											<div className='d-flex flex-row'>
												<div className='col-12 pe-2 mt-3'>
													<div className='text-start'>
														<label className='form-label ' htmlFor='username'>
															Enter Email OTP
														</label>
													</div>
													<div
													
													 className={`input-group mt-2 ${!otp[0] ? 'no-value-selected-input' : ''}`}
													 >
														<input
															type='text'
															id='otp'
															name='otp'
															className='form-control inputfields inputfields-forgot '
															placeholder='Enter OTP'
															disabled={otpFieldDisabled || isOtpVerified}
															onBlur={otpChangeHandler}
															maxLength={6}
															data-testid='otp-input'
														/>
														{isOtpVerified && (
															<div data-testid='otp-verified-icon' className='otp-success-icon'>
																<BsFillCheckCircleFill />
															</div>
														)}

														{errorFields.otp && (
															<div className='col-12 mt-3'>
																<div data-testid='otp-error-message' className='text-danger error-message'>
																	{errorFields.otp}
																</div>
															</div>
														)}
														{!isOtpVerified && otp.length > 0 && (
															<div className='col-12 mt-3'>
																<div data-testid='otp-error2-message' className='text-danger error-message'>
																	Please enter valid OTP
																</div>
															</div>
														)}
													</div>
												</div>
												<div className='float-end col-1 pe-2 otp-timer-div'></div>
											</div>
										)}

										{isOtpVerified && (
											<>
											<div className='form-label-group required mt-3 '>
												<label className='formLabel ' htmlFor='Password'>
													Password
												</label>
												<div className='position-relative'>
													<div
													 className={`password-input-container ${!passwordFormData.password ? 'no-value-selected-input' : ''}`}
													 >
														<input
															type={isPasswordVisible ? 'text' : 'password'}
															name='password'
															id='password'
															className={`form-control inputfields-forgot inputfields  ${
																errorFields.password ? 'is-invalid' : ''
															}`}
															onBlur={onBlurHandler}
															onCopy={(e) => {
																handleRestrictedAction(e);
															}}
															onPaste={(e) => {
																handleRestrictedAction(e);
															}}
															onChange={onInputChange}
															value={passwordFormData.password}
															onFocus={() => {
																setIsFocus(true);
															}}
															data-testid='password-input'
														/>
														<span
															className={`password-visibility-icon-forgotpassword ${
																isPasswordVisible ? 'visible' : 'hidden'
															}`}
															onClick={togglePasswordVisibility}
															style={{
																right: '0px  !important',
															}}
															data-testid='password-visibility-toggle'
														>
															{isPasswordVisible ? (
																<BsEyeFill className='fa-eye-slash_forgetpass_eye_icon' />
															) : (
																<BsEyeSlashFill className='fa-eye-slash_forgetpass_eye_icon' />
															)}
														</span>
													</div>

													{passwordFormData.password.length > 0 && isFocus && (
														<>
															<PopOverComponentContainer
																passwordCriteria={passwordCriteria}
																hidePasswordIntruct={hidePasswordIntruct}
																showPasswordIntruct={showPasswordIntruct}
																passwordFormData={passwordFormData}
																errorFields={errorFields}
																tootTipAlighmenClass='create-password-tooltip-signup'
																positionStyle={{ top: '0px', left: '173px' }}
																data-testid='forgot-password'
															/>
														</>
													)}

													{errorFields.password && (
														<div data-testid='password-error-message' className='error-message'>{errorFields.password}</div>
													)}

													{!errorFields.password && !isPasswordIntructNoteHidden && (
														<div data-testid='password-tooltip-icon' className='password-tooltip-icon'></div>
													)}
												</div>
											</div>
											<div className='form-label-group required mt-3'>
												<label htmlFor='confirm-password' className='formLabel '>
													Confirm Password
												</label>
												<div 
												
												className={`position-relative ${!passwordFormData.confirmPassword[0] ? 'no-value-selected-input' : ''}`}
												>
													<input
														type={isConfirmPasswordVisible ? 'text' : 'password'}
														id='confirmPassword'
														name='confirmPassword'
														className={`form-control inputfields inputfields-forgot  ${
															errorFields.confirmPassword ? 'is-invalid' : ''
														}`}
														onCopy={(e) => {
															handleRestrictedAction(e);
														}}
														onPaste={(e) => {
															handleRestrictedAction(e);
														}}
														onBlur={onBlurHandler}
														onChange={onInputChange}
														value={passwordFormData.confirmPassword}
														data-testid='confirm-password-input'
													/>
													<span
														className={`password-visibility-icon-forgotpassword  ${
															isConfirmPasswordVisible ? 'visible' : 'hidden'
														}`}
														onClick={toggleConfirmPasswordVisibility}
														style={{
															right: '0px !important',
														}}
														data-testid='confirm-password-visibility-toggle'
													>
														{isConfirmPasswordVisible ? (
															<BsEyeFill className='fa-eye-slash_forgetpass_eye_icon' />
														) : (
															<BsEyeSlashFill className='fa-eye-slash_forgetpass_eye_icon' />
														)}
													</span>

													{errorFields.confirmPassword && (
														<div
															onMouseLeave={hidePasswordIntruct}
															onMouseEnter={showPasswordIntruct}
															className='password-exclamation-icon password-exclamation-icon-forgotpassword'
															style={{
																right: '0px !important',
																marginTop: '0px !important',
															}}
															data-testid='confirm-password-error-icon'
														>
															<BsFillExclamationCircleFill />
														</div>
													)}
													{!errorFields.confirmPassword && confirmPassword.length > 0 && (
														<div
															data-testid='confirm-password-success-icon'
															id='password-success-icon'
															className=' password-exclamation-icon-forgotpassword'
														>
															<BsFillCheckCircleFill />
														</div>
													)}
													{errorFields.confirmPassword && (
														<div data-testid='confirm-password-error-message' className='error-message'>
															{errorFields.confirmPassword}
														</div>
													)}
												</div>
											</div>
										</>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className='d-flex flex-column gap-2 justify-content-center align-items-center  forgot-submit-container'>
						{isOtpSend && !isOtpVerified && <div data-testid='email-otp-success-message' className='has-success'>Email OTP sent successfully</div>}
						<button
							data-testid='forgot-password-submit-button'
							disabled={!isOtpVerified}
							onClick={onSubmit}
							className=' btn-primary forgot-pass-btn   btn-forgot-submit'
							style={{
								fontWeight: '500 !important',
								fontSize: '15px !important',
								borderRadius: '5px',
							}}
						>
							Submit
						</button>
					</div>
					<div className='row mt-4'>
						<div
							className='col-12'
							style={{
								marginTop: '4px',
							}}
						>
							<span className='login-account-label '>
								Already a User? &nbsp;
								<Link data-testid='login-link' className='forgot-account-link' to='/login'>
									Login Now
								</Link>
							</span>
						</div>
					</div>
				</div>
			</div>
			<Loader isLoading={isLoading}/>
		</div>
	);
};

Forgotpassword.propTypes = {
	// Actions
	ForgotPasswordUser: PropTypes.func.isRequired,
	SendOTP: PropTypes.func.isRequired,
	VerifyOTP: PropTypes.func.isRequired,
	SetIsOtpVerifiedState: PropTypes.func.isRequired,
	// States
	isOtpVerified: PropTypes.bool.isRequired,
	otpTimerKey: PropTypes.number.isRequired,
	isOtpSend: PropTypes.bool.isRequired,
	otpFieldDisabled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
	ForgotPasswordUser,
	SendOTP,
	VerifyOTP,
	isOtpVerified: state.otp.isOtpVerified,
	otpTimerKey: state.otp.otpTimerKey,
	isOtpSend: state.otp.isOtpSend,
	otpFieldDisabled: state.otp.otpFieldDisabled,
	isLoading: state.loader.isLoading
});

export default connect(mapStateToProps, {
	ForgotPasswordUser,
	SendOTP,
	VerifyOTP,
	SetIsOtpVerifiedState,
})(Forgotpassword);
