import React from "react";
import { Modal } from "react-bootstrap";
import "./deleteUserModel.css";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DeleteUser } from "../../../../../../store/actions/ra_user_management";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../helpers/hoc/loader/Loader";

const DeleteUserModel = ({ showModal, setIsShowModal, DeleteUser, _id }) => {
  const isLoading = useSelector((s) => s?.loader?.isLoading);
  const handleClose = () => setIsShowModal(false);
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleDeleted = () => {
    DeleteUser({
      data: { _id },
      navigateTo: handleNavigate,
    });
    handleClose()
  };
  return (
    <Modal
      onHide={handleClose}
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      size="l"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="mt-4">
        <div>
          <div className="d-flex justify-content-center">
            <span className="delete-user-text">
              Are you sure you want to delete user?
            </span>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn-cancle-user"
              onClick={() => {
                handleClose();
              }}
            >
              Cancle
            </button>
            <button className=" btn-delete-user" onClick={handleDeleted}>
              Delete
            </button>
          </div>
        </div>
        <Loader isLoading={isLoading}></Loader>
      </Modal.Body>
    </Modal>
  );
};
DeleteUserModel.propTypes = {
  DeleteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  DeleteUser,
})(DeleteUserModel);