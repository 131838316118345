import api from '../../../service/api';
import API_BOOK from '../../../service/endpoints';
import { customToast } from '../../../utils/toasts';
import ActionTypes from '../../actionTypes';
import { NotificationMessage } from '../../../utils/constants';

const recordsApis = API_BOOK.RECORD;
/**
 *  @desc         Telematics API for getting vehicle status report
 *  @author       Ankit Kushwaha
 *  @api          /reports/status-records/count
 *  @method       POST
 *  @createdDate  18-dec-2023
 *  @modifiedDate 18-dec-2023
 **/

	export const GetVehicleStatusReport = async ({
		registrationNumbers = [],
		countForStatus = [] ,
		fromDate = new Date(),
		toDate = new Date(),
		pageSize = 8,
		pageIndex = 0,
	}) => {
		try {
			const res = await api({
				url: recordsApis.VEHICLE_STATUS.url,
				method: recordsApis.VEHICLE_STATUS.method,
				data: {
					fromDate,
					toDate,
					pageSize,
					pageIndex,
					registrationNumbers,
					countForStatus
				},
			});
			if (res.status === 200) {
				const response = res?.data;
				return response;
			}
		} catch (error) {
			console.log('Error getting ', error);
		}
	}

	/**
 *  @desc         Export trips report api action
 *  @author       Ankit Kushwaha
 *  @api          /tripReports/export
 *  @method       POST
 *  @createdDate  18-dec-2023
 *  @modifiedDate 18-dec-2023
 **/
export const ExportVehicleStatusReport = ({ registrationNumbers, fromDate, toDate,countForStatus }) => async (dispatch) => {
	try {
	  const resp = await api({
		method: recordsApis.EXPORT_VEHICLE_STATUS_DATA.method,
		url: recordsApis.EXPORT_VEHICLE_STATUS_DATA.url,
		data: {
		  registrationNumbers: registrationNumbers?.map((val)=> val.label),
		  fromDate,
		  toDate,
		  countForStatus,
		  timezoneOffset: new Date().getTimezoneOffset()
		 }
	  });
	  if (resp.status === 200 && resp?.data) {
		const respData = resp?.data?.data
		dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
		customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
	  } else {
		customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
	  }
	} catch (error) {
	  console.log("error:", error);
	}
  };
	

