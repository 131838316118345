import { VehicleStatusAndMetaDataDark } from '../../../../utils/constants';
import './NDVehicleListDetailCardDark.css';
// import Tooltip from 'react-bootstrap/Tooltip';
import { GetAddressByCoordinates } from '../../../../store/actions/map/latlng-to-address';
import { useState } from 'react';

const NDVehicleListDetailCard = (props) => {
	const [address, setAddress] = useState();
	const [isHovered, setIsHovered] = useState(false);

	const getSOCClass = (status, soc, socClass, nonCommunication) => {
		return props.getSocClass(status, soc, socClass, nonCommunication);
	};

	const getSOCRotationStyle = (soc) => {
		return props.getSOCRotationStyle(soc);
	};

	const getSOCOuterStyle = (soc) => {
		return props.getSOCOuterStyle(soc);
	};

	const statusColor = (status) => {
		return VehicleStatusAndMetaDataDark.filter((vehicleStatus) => vehicleStatus.name === status)[0]?.color;
	};

	const getFavoriteIcon = (fav) => {
		return props.getFavoriteIcon(fav);
	};

	const handleCardClick = () => {
		props.onCardClick(props.registration_number);
	};

	const handleFavoriteFlagClick = (event, registration_number, isFavorite) => {
		event.stopPropagation();
		props.onFavoriteFlagClick(registration_number, isFavorite);
	};

	const fetchAddressData = async () => {
		let res = await GetAddressByCoordinates({ lat: props.lat, lng: props.lng });
		setAddress(res?.data?.data?.address);
	};

	// const renderTooltip = (props) => (
	//     <Tooltip id="nd-button-tooltip"
	//     bsPrefix='tooltip nd-custom-tooltip show'
	//      {...props}
	//      >
	//       Click to get address
	//     </Tooltip>
	//   );

	return (
		<div
		 className={`new-dark-dashboard nd__dark-vehicle-detail-card ${isHovered ? 'nd__dark-vehicle-detail-card-half-border' :''}`}
			data-testid={`vehicle-list-card-${props.registration_number}`}
			onClick={handleCardClick}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className='nd-vc-row-1'>
				<div className='nd-vc-icon-col'>
					<img src='/images/svgicon/Ellipse.svg' alt='vehicle'></img>

					<img
						src='/images/svgicon/static-vehicle-dark.svg'
						alt='vehicle'
						className='nd-static-vehicle-icon'
					></img>
				</div>

				<div className='nd-vc-oem-div'>
					<div className='nd-vc-title'>
						<p className='nd__dark-vc-regi-no'>{props.registration_number}</p>
						<p className='nd__dark-vc-oem'>{props.OEM}</p>
					</div>

					<img
						style={{ margin: '6px 0 0 -15px' }}
						src={getFavoriteIcon(props.favorite)}
						alt='favorite'
						data-testid={`vehicle-list-card-favourite-${props.registration_number}`}
						onClick={(event) => handleFavoriteFlagClick(event, props.registration_number, props.favorite)}
					/>
				</div>

				<div className='nd-vc-battery'>
					<div className={getSOCClass(props.status, props.soc, 'c100', props.nonCommunication)}>
						{/* <div className='nd-vc-battery-perc-in' ></div> */}

						<span style={{ color: '#fff' }}>{Math.trunc(props.soc)}%</span>
						<div style={getSOCOuterStyle(props.soc)}>
							<div className='bar' style={getSOCRotationStyle(props.soc)}></div>
							<div className='fill'></div>
						</div>
					</div>
					<div className='nd__dark-vc-soc'>State of Charge</div>
				</div>
			</div>

			<div className='nd__dark-vc-line'></div>

			<div className='nd-vc-row-2'>
				<img
					data-testid={`vehicle-list-card-location-${props.registration_number}`}
					onClick={(e) => {
						fetchAddressData();
						e.stopPropagation();
					}}
					className='nd__dark-vc-gmarker'
					src='/images/svgicon/map-maker-dark.svg'
					alt='map marker'
				/>
				{!address ? (
					<p
						className='nd-vc-click'
						onClick={(e) => {
							fetchAddressData();
							e.stopPropagation();
						}}
					>
						Click to view{' '}
					</p>
				) : (
					' '
				)}

				<p className='nd__dark-vc-address'>{address}</p>

				<div
					className='nd-vc-status'
					style={{
						background: props.nonCommunication
							? '#7D7D7D'
							: statusColor(props.status === 'Idle' ? 'Live' : props?.status),
					}}
				>
					<p>{props?.status === 'Idle' ? 'Live' : props?.status}</p>
				</div>
			</div>
		</div>
	);
};

export default NDVehicleListDetailCard;
