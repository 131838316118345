import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';
const defaultSecretKey = process.env.REACT_APP_HASH_SECRET

/* Get secret key for hashing */
const GetSecretKey = () => {
    if (!defaultSecretKey) {
        console.log('Hash error')
    }
    return defaultSecretKey?.toString()
}

/* Generate hash */
export const GetRandomHash = () => {
    const date = new Date()
    const d = date.getUTCDate().toString().padEnd(2, '0')
    const h = date.getUTCHours().toString().padEnd(2, '0')
    const m = date.getUTCMinutes().toString().padEnd(2, '0')
    const key = m+h+d
    const sha256 = CryptoJS.algo.SHA256.create()
    sha256.update(key);
    const hashMessage = sha256.finalize()
    return hashMessage.toString(CryptoJS.enc.Hex)
}

/* Cipher Generator */
export const CipherGenerator = (message) => {
    const secretKey = GetSecretKey()
    const plainTextIV = 'aAB1jhPQ89o=f619'
    const sha1 = CryptoJS.algo.SHA1.create().update(secretKey).finalize()
    const hashSecretKey = CryptoJS.enc.Utf8.parse(sha1.toString(CryptoJS.enc.Base64).slice(0, 16))
    const initialVector = CryptoJS.enc.Utf8.parse(plainTextIV);

    const cipherText = AES.encrypt(message, hashSecretKey, {
        iv: initialVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
    
    const randomHash = GetRandomHash()
    return `${randomHash.toString()}${cipherText.toString()}`
}

export const encrypt = (message) => {
    const secretKey = GetSecretKey()
    const plainTextIV = 'aAB1jhPQ89o=f619'
    const sha1 = CryptoJS.algo.SHA1.create().update(secretKey).finalize()
    const hashSecretKey = CryptoJS.enc.Utf8.parse(sha1.toString(CryptoJS.enc.Base64).slice(0, 16))
    const initialVector = CryptoJS.enc.Utf8.parse(plainTextIV);

    const cipherText = AES.encrypt(message, hashSecretKey, {
        iv: initialVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
    return cipherText.toString()
}

export const decrypt = (message) => {
    const secretKey = GetSecretKey()
    const plainTextIV = 'aAB1jhPQ89o=f619'
    const sha1 = CryptoJS.algo.SHA1.create().update(secretKey).finalize()
    const hashSecretKey = CryptoJS.enc.Utf8.parse(sha1.toString(CryptoJS.enc.Base64).slice(0, 16))
    const initialVector = CryptoJS.enc.Utf8.parse(plainTextIV);
    const cipherText = AES.decrypt(message, hashSecretKey, {
        iv: initialVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
    return cipherText.toString(CryptoJS.enc.Utf8)
}