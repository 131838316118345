import React, { useEffect } from 'react'

import './NDClusterMeter.css'

import darkBackgroundImage from './images/dark_background.png'
import darkSpeedBackgroundImage from './images/dark_speed_background.svg'
import darkIgnitionOnImage from './images/dark_ignition_on.svg'
import darkIgnitionOffImage from './images/dark_ignition_off_disabled.svg'
import darkOdometerBackgroundImage from './images/dark_odometer_background.png'
import darkBatteryVoltageImage from './images/dark_battery_voltage.svg'
import darkDTEImage from './images/dark_dte.svg'
import darkBatteryChargePartialImage from './images/dark_battery_charge_partial.svg'
import darkBatteryChargeEmptyImage from './images/dark_battery_charge_empty.svg'
import darkBatteryChargeImage from './images/dark_battery_charge.svg'

const SpeedProgress = (props) => {
    const {
        id,
        className,
        progressValue,
    } = props;

   useEffect(() => {
            if (id) {
              const canvas = document.getElementById(id);
              const ctx = canvas.getContext('2d');
          
              const centerX = canvas.width / 2;
              const centerY = canvas.height / 2;
              const radius = 50;
              const lineWidth = 3;
        
              ctx.clearRect(0, 0, canvas.width, canvas.height);
        
              // Calculate the angle based on the speed/value and custom range
              const minSpeed = 0; // Minimum value
              const maxSpeed = 200; // Maximum value
              const range = maxSpeed - minSpeed;
              const angle = Math.PI * ((progressValue - minSpeed) / range) - Math.PI / 2;
        
              // Draw the speedometer needle
              ctx.strokeStyle = '#C441F4'; // Color of the needle
              ctx.lineWidth = lineWidth;
              ctx.lineCap = 'round';
              ctx.beginPath();
              ctx.moveTo(centerX, centerY);
              ctx.lineTo(centerX + (radius - 10) * Math.cos(angle), centerY - (radius ) * Math.sin(angle));
              ctx.stroke();
            }
          }, [id, progressValue])

    return (
        <>
            <canvas id={id} className={className} width="160" height="160"></canvas>
            <span className={`${className}--center`}></span>
        </>
    )
}

const NDClusterMeter = (props) => {
    const {
        batteryVoltage,
        batterySOC,
        vehicleSpeed,
        odometerValue,
        dte,
        ignitionStatus,
        vehicleStatus,
    } = props

    const formatOdometerValue = (value = 0) => {
        value = Math.round(value).toString();
        const lengthDiff = 6 - value.length;
        const missingZeros = new Array(lengthDiff).fill(0);
        const odometerString = `${missingZeros.join('')}${value}`;
        return odometerString.split("").map((d, i) => (
            <div key={`span_${i}`}>
                <img src={darkOdometerBackgroundImage} alt='odometer background' />
                <span>{d}</span>
            </div>
        ))
    }

    let batteryChargeImage = darkBatteryChargeEmptyImage
    if (batterySOC > 0 && batterySOC < 100) {
        batteryChargeImage = darkBatteryChargePartialImage
    }
    if (batterySOC >= 100) {
        batteryChargeImage = darkBatteryChargeImage
    }

    let mappedIgnitionStatus = ignitionStatus;
    if (['Live', 'Idle'].includes(vehicleStatus)) {
        mappedIgnitionStatus = true
    }

    return (
        <div className='ndcm__wrapper'>
            <div className='ndcm__container'>
                {/* background placeholder */}
                <img className='ndcm__background' src={darkBackgroundImage} alt='background' />

                {/* battery voltage section */}
                <div className='ndcm__battery-voltage ndcm__tooltip' data-label='Battery Voltage'>
                    <img src={darkBatteryVoltageImage} alt='battery voltage' />
                    <span className='ndcm__battery-voltage--value'>{![null, undefined].includes(batteryVoltage) ? Math.trunc(batteryVoltage) + 'V' : 'NA'}</span>
                </div>

                {/* dte section */}
                <div className='ndcm__dte ndcm__tooltip' data-label='DTE'>
                    <img src={darkDTEImage} alt='DTE' />
                    <span className='ndcm__dte--value'>{![null, undefined].includes(dte) ? Math.trunc(dte) + 'km' : 'NA'}</span>
                </div>

                {/* speed section */}
                <img className='ndcm__speed-background' src={darkSpeedBackgroundImage} alt='speed background' />
                <span className='ndcm__speed-value'>{![null, undefined].includes(vehicleSpeed) ? Math.trunc(vehicleSpeed) + ' kmph' : '0 kmph'}</span>
                <SpeedProgress id='ndcm-speed' className='ndcm__speed__needle' progressValue={vehicleSpeed} />

                {/* ignition section: ON */}
                <div className={`ndcm__ignition-on ${![true, false].includes(mappedIgnitionStatus) ? 'ndcm__ignition--na' : ''} active ndcm__tooltip`} data-label='Ignition Status'>
                    <img src={mappedIgnitionStatus === true ? darkIgnitionOnImage : darkIgnitionOffImage} alt={`ignition ${mappedIgnitionStatus === true ? 'on' : 'off'}`} />
                </div>
                
                {/* odometer section */}
                <div className='ndcm__odometer-count ndcm__tooltip' data-label='Odometer'>{formatOdometerValue(odometerValue)}</div>

                {/* battery soc section */}
                <div className='ndcm__battery-charge ndcm__tooltip' data-label='State of Charge'>
                    <img src={batteryChargeImage} alt='battery charge' />
                </div>
                <span className='ndcm__battery-charge--value'>{![null, undefined].includes(batterySOC) ? Math.trunc(batterySOC) + '%' : 'NA'}</span>

            </div>
        </div>

    )
}

export default React.memo(NDClusterMeter);