// External packages import
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Internal components import
import AccessTemplateAddEditForm from './AccessTemplateAddEditForm/AccessTemplateAddEditForm'
import AccessTemplateDetailModal from './AccessTemplateDetailModal/AccessTemplateDetailModal'
import AccessTemplateList from './AccessTemplateList/AccessTemplateList'
import PageHeader from '../../../shared/ui/PageHeader/PageHeader'
import BaseCard from '../../../shared/cards/BaseCard/BaseCard'
import InformativeModal, { VARIANTS } from '../../../shared/modals/InformativeModal/InformativeModel'

// Internal utils and constants import
import departmentTemplateAction from '../../../../store/actions/department/departmentTemplate'

// Style import
import styles from './AccessTemplate.module.css'

const VIEWS = {
    LIST_TEMPLATE_VIEW: 'LIST_TEMPLATE_VIEW',
    CREATE_TEMPLATE_VIEW: 'CREATE_TEMPLATE_VIEW',
    UPDATE_TEMPLATE_VIEW: 'EDIT_TEMPLATE_VIEW',
}

const ACTIONS = {
    CREATE_TEMPLATE: 'CREATE_TEMPLATE',
    UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
}

const AccessTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const masterModules = useSelector(state => state.department.masterModuleList)
    const templates = useSelector(state => state.department.templateList)
    const user = useSelector(state => state.auth.user)
    
    const [activeView, setActiveView] = React.useState(VIEWS.LIST_TEMPLATE_VIEW)
    const [editTemplateId, setEditTemplateId] = React.useState(null)
    const [viewTemplateData, setViewTemplateData] = React.useState(null)
    const [openViewTemplateModal, setOpenViewTemplateModal] = React.useState(false)

    const [informativeModalData, setInformativeModalData] = React.useState({
        message: '',
        isOpen: false,
        variant: VARIANTS.SUCCESS,
        action: '',
    })

    // generated mapped template list by combining template list and master module list
    const mapTemplateList = () => {
        let templateList = [...templates]
        
        if (templateList.length && masterModules.length) {
            templateList = templateList.map(template => {
                return {
                    id: template.id,
                    name: template.name,
                    modules: template.modules.map(module => {
                        const moduleData = masterModules.find(mm => mm.moduleId === module.id)
                        return {
                            module: moduleData.module,
                            moduleId: moduleData.moduleId,
                            subModules: module.subModules.map(subModule => {
                                const subModuleData = moduleData.subModules.find(sm => sm.value === subModule.value)
                                return {
                                    label: subModuleData.label,
                                    value: subModuleData.value,
                                    actionTypes: subModule.actionTypes.map(actionType => {
                                        const actionTypeData = subModuleData.actionTypes.find(at => at.value === actionType.value)
                                        return {
                                            label: actionTypeData.label,
                                            value: actionTypeData.value,
                                            isApplicable: actionTypeData.isApplicable,
                                        }
                                    })
                                }
                            })
                        }
                    }),
                }
            })
        }

        return templateList
    }
    const templateListMapped = mapTemplateList();

    React.useEffect(() => {
        dispatch(departmentTemplateAction.getMasterModulesList())
        dispatch(departmentTemplateAction.getTemplatesList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // handle template detail view modal open
    const handleTemplateViewClick = ({ templateData }) => {
        setViewTemplateData({ ...templateData })
        setOpenViewTemplateModal(true)
    }

    // handle template detail view modal close
    const handleTemplateViewClose = () => {
        setViewTemplateData(null)
        setOpenViewTemplateModal(false)
    }

    // on edit click, set edit id, close detail modal and open edit template view
    const handleTemplateEdit = ({ templateId }) => {
        setEditTemplateId(templateId)
        setActiveView(VIEWS.UPDATE_TEMPLATE_VIEW)
        handleTemplateViewClose()
    }

    // handle both template create and edit API calls
    const handleSaveTemplateClick = async ({ templateName, moduleData }) => {
        const isEdit = editTemplateId ? true : false
        try {
            let response
            let requestData = {
                customerId: user._id,
                departmentId: user.departmentId,
                templateName,
                moduleData,
            }

            // parse data to api request body format
            requestData.moduleData = requestData.moduleData.map(m => {
                return {
                    modulesid: m.moduleId,
                    submodules: m.subModules.map(sm => {
                        return {
                            submodulesValue: sm.submoduleValue,
                            actionTypes: sm.actionTypes
                        }
                    })
                }
            })

            if (isEdit) {
                // trigger update API when edit is enabled
                response = await departmentTemplateAction.updateAccessTemplate({
                    ...requestData,
                    templateId: editTemplateId,
                })
            } else {
                // trigger create API when edit is not enabled
                response = await departmentTemplateAction.createAccessTemplate(requestData)
            }

            // handle success of template save
            if (response?.data?.status === 200){
                dispatch(departmentTemplateAction.getTemplatesList())
                setInformativeModalData({
                  message: isEdit ? 'Successfully Updated' : 'Successfully Created',
                  isOpen: true,
                  variant: VARIANTS.SUCCESS,
                  action: isEdit ? ACTIONS.UPDATE_TEMPLATE : ACTIONS.CREATE_TEMPLATE,
                })
                return
            }

            // handle failure of template save
            let errorMessage = 'Something went wrong'
            if (typeof response?.data?.error === 'string') {
                errorMessage = response?.data?.error
            }
            if (typeof response?.data?.error === 'object') {
                errorMessage = response?.data?.error[0]?.message
            }
            setInformativeModalData({
                message: errorMessage,
                isOpen: true,
                variant: VARIANTS.ERROR,
                action: isEdit ? ACTIONS.UPDATE_TEMPLATE : ACTIONS.CREATE_TEMPLATE,
            })
        } catch (error) {
            console.log("Save template has error", error)
            setInformativeModalData({
                message: 'Something went wrong!!!',
                isOpen: true,
                variant: VARIANTS.ERROR,
                action: isEdit ? ACTIONS.UPDATE_TEMPLATE : ACTIONS.CREATE_TEMPLATE,
            })
        }
    }

    const handleInformativeModalClose = () => {
        // if template save has success response then switch back to list view
        if (informativeModalData.variant === VARIANTS.SUCCESS) {
            setActiveView(VIEWS.LIST_TEMPLATE_VIEW)
        }
        
        setInformativeModalData({
          message: '',
          isOpen: false,
          variant: '',
          action: '',
        })
    }

    const templateNames = editTemplateId ? templateListMapped?.filter(t => t.id !== editTemplateId)?.map(t => t.name) : templateListMapped.map(t => t.name)

    const renderView = () => {
        if (activeView === VIEWS.CREATE_TEMPLATE_VIEW) {
            return (
                <>
                    <PageHeader
                        variant='SECONDARY_PAGE'
                        heading='Template > Create'
                        enableNavigateBack
                        onNavigateBack={() => setActiveView(VIEWS.LIST_TEMPLATE_VIEW)}
                    />
    
                    <AccessTemplateAddEditForm
                        masterModules={masterModules}
                        onSaveTemplateClick={handleSaveTemplateClick}
                        templateNames={templateNames}
                    />
                </>
            )
        }
    
        if (activeView === VIEWS.UPDATE_TEMPLATE_VIEW) {
            const templateData = templateListMapped.find(t => t.id === editTemplateId)
            return (
                <>
                    <PageHeader
                        variant='SECONDARY_PAGE'
                        heading='Template > Update'
                        enableNavigateBack
                        onNavigateBack={() => {
                            setActiveView(VIEWS.LIST_TEMPLATE_VIEW)
                            setEditTemplateId(null)
                        }}
                    />
    
                    <AccessTemplateAddEditForm
                        isEditEnabled
                        masterModules={masterModules}
                        templateData={templateData}
                        templateNames={templateNames}
                        onSaveTemplateClick={handleSaveTemplateClick}
                    />
                </>
            )
        }

        if (activeView === VIEWS.LIST_TEMPLATE_VIEW) {
            return (
                <>
                    <PageHeader
                        variant='SECONDARY_PAGE'
                        heading='Template'
                        enableNavigateBack
                        onNavigateBack={() => {
                            navigate("/da/dashboard")
                        }}
                    />

                    <div style={{ flex: '0 0 auto' }}>
                        <BaseCard
                            className={styles.cardCreateTemplate}
                            onClick={() => setActiveView(VIEWS.CREATE_TEMPLATE_VIEW)}
                        >
                            <img src='/images/darkSVGIcon/plus_circle.svg' alt='plus' />
                            <p>Create Template</p>
                        </BaseCard>
                    </div>

                    <AccessTemplateList
                        templateList={templateListMapped}
                        onTemplateClick={handleTemplateViewClick}
                    />

                    <AccessTemplateDetailModal
                        isEditEnabled
                        isOpen={openViewTemplateModal}
                        onClose={handleTemplateViewClose}
                        templateData={viewTemplateData}
                        onEdit={handleTemplateEdit}
                    />
                </>
            )
        }

        return <>Loading...</>
    }
    
    return (
        <div className={styles.container}>
            {renderView()}

            <InformativeModal
                isOpen={informativeModalData.isOpen}
                message={informativeModalData.message}
                variant={informativeModalData.variant}
                onClose={() => handleInformativeModalClose(informativeModalData.action)}
            />
        </div>
    )
}

export default AccessTemplate