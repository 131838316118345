import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NDVehicleInfoDark from '../../NewDashboardDark/nd-vehicleInfoDark/NDVehicleInfoDark';

import { AddFavoriteVehicle, DeleteFavoriteVehicle } from '../../../../store/actions/favoriteVehicle'

import './GroupVehicleDetails.css'
import Loader from '../../../helpers/hoc/loader/Loader';

const defaultMapConfig = {
  center: [
    20.5937,
    78.9629,
  ],
  zoom: 18,
  attributionControl: false,
  maxZoom: 22,
  minZoom: 5,
  zoomControl: false,
  enableMarkerCluster: true,
  zoomControlPosition: 'topright'
}

const defaultMarkerClusterConfig = {
  chunkedLoading: true,
  disableClusteringAtZoom: 10,
  maxClusterRadius: 15
}

class GroupVehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef(null);
    this.state = {
      mapConfig: defaultMapConfig,
      clusterConfig: defaultMarkerClusterConfig,
      activeAlert: null,
      hoverCloseIcon: false,
      filters:{
        location:[],
        vehicleCategory:[],
        oemModel:[],
        dataSource:[],
        groups:[],
        soc:[]
    },
    }
  }

  onFavoriteFlagClick = async (registration_number, isFavorite) => {
    if (isFavorite) {
      this.props.DeleteFavoriteVehicle({ registration_number });
    } else {
      this.props.AddFavoriteVehicle({ registration_number });
    }
  }

  mapReset = () => {
    this.resetState();
  }

  // reset state
  resetState = () => {
    this.setState({
      mapConfig: { ...defaultMapConfig },
      clusterConfig: { ...defaultMarkerClusterConfig },
    }, () =>
      this.mapRef?.current?.setView(this.state.mapConfig.center, this.state.mapConfig.zoom)
    )
  }

  handleActiveAlert = (activeAlert) => {
    this.setState({ activeAlert })
  }

  handleUpdateMapConfigForZoom = (key) => {
    const mapConfigObj = JSON.parse(JSON.stringify(this.state.mapConfig))
    if(key === 'zoomIn') {
      this.mapRef.current.setZoom(this.mapRef.current.getZoom() + 1)
      mapConfigObj.zoom = this.state.mapConfig.zoom + 1
    }
    if(key === 'zoomOut') {
      this.mapRef.current.setZoom(this.mapRef.current.getZoom() - 1)
      mapConfigObj.zoom = this.state.mapConfig.zoom - 1
    }
    this.setState({ mapConfig: { ...mapConfigObj } })
  }

  render() {
    const { handleOnCloseSidebar, selectedVehicleData, vehicleIgnitionData, vehicleStatusData, dashboardCamUrl, favouriteVehicles } = this.props;
    
    if (!selectedVehicleData) {
      return (
        <div className='group-details group-details-dark group-listing-dark pt-2'>
          <Loader />
        </div>
      )
    }
    
    const detailsMapConfig = {
      ...this.state.mapConfig,
      center: [selectedVehicleData.lt, selectedVehicleData.lng]
    }
    return (
      <div className='group-details group-details-dark group-listing-dark'>
        <NDVehicleInfoDark
          variant="GROUPS_VEHICLE_INFO"
          selectedVehicleData={selectedVehicleData}
          activeAlert={this.state.activeAlert}
          handleActiveAlert={this.handleActiveAlert}
          dashboardCamUrl={dashboardCamUrl}
          detailsMapConfig={detailsMapConfig}
          favouriteVehicles={favouriteVehicles}
          handleOnCloseSidebar={handleOnCloseSidebar}
          mapRef={this.mapRef}
          mapReset={this.mapReset}
          onFavoriteFlagClick={this.onFavoriteFlagClick}
          vehicleIgnitionData={vehicleIgnitionData}
          vehicleStatusData={vehicleStatusData}
          onHeaderClose={handleOnCloseSidebar}
          isFavourite={favouriteVehicles?.includes(selectedVehicleData?.rn)}
          onUpdateMapConfigForZoom={this.handleUpdateMapConfigForZoom}
          filters={this.state.filters}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  location: state.user.location,
  topPaneStatus: state.vehicle.topPaneStatus,
  masterLocationData: state.getAllUserRoleReducer.masterLocationData,
  searchQuery: state.dashboardReducer.topPanelSearchQuery,
  allDashboardVehicleData: state.dashboardReducer.allDashboardVehicleData,
  filterVehicleDataObj: state.dashboardReducer.filterVehicleData,
  favouriteVehicles: state.favoriteVehicle.favouriteVehicles,
  vehicleChartData: state.dashboardReducer.vehicleChartData,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    AddFavoriteVehicle,
    DeleteFavoriteVehicle,
  },
  dispatch
)


export default connect(mapStateToProps, mapDispatchToProps)(GroupVehicleDetails)