import React from 'react'
import PropTypes from 'prop-types'
import DropdownB from 'react-bootstrap/Dropdown'

import styles from './Dropdown.module.css'

const Dropdown = (props) => {
    const { triggerComponent, dropdownItems } = props
    const defaultKeyExtractor = (item) => {
        return `dropdown_item_${item.label}`
    }

    return (
        <DropdownB>
            <DropdownB.Toggle className={styles.dropdownToggle}>
                {triggerComponent}
            </DropdownB.Toggle>

            <DropdownB.Menu className={styles.dropdownMenu}>
                {dropdownItems.map(item => (
                    <DropdownB.Item
                        className={styles.dropdownMenuItem}
                        as="button"
                        key={item.keyExtractor ? item.keyExtractor(item) : defaultKeyExtractor(item)}
                        eventKey={item.value}
                        onClick={item.onClick}
                    >
                        {item.label}
                    </DropdownB.Item>
                ))}
            </DropdownB.Menu>
        </DropdownB>
    )
}

Dropdown.propTypes = {
    triggerComponent: PropTypes.node.isRequired,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        keyExtractor: PropTypes.func,
    })).isRequired
}

Dropdown.defaultProps = {

}

export default React.memo(Dropdown)
