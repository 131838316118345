import ActionTypes from "../../actionTypes";
const geofenceActionTypes = ActionTypes.GEO_FENCE;

const initialState = {
  geoFenceTypeData: [],
  geoFenceLabelData: [],
  geoFenceLabelOwnerData: [],
  geoFenceCityData: [],
  isGeofenceVehiclesByfenceIdApiError: null,
  isGeofenceVehiclesByfenceIdSuccess: false,
  isGeofenceVehicleexportApiError: null,
  isGeofenceVehicleexportApiSuccess: false,
  isGeofenceIamgeUploadApiError: null,
  isGeofenceIamgeUploadApiSuccess: false,
  fenceVehicleByFenceIdData: [],
  fenceVehicleCount: 0,
  selectedGeoFenceCard: null,
  refetchGeoFenceDataCount: 0,
};
const dashBoardGeoFance = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case geofenceActionTypes.GET_ALL_GEO_FANCE_SUCCESS:
      return {
        ...state,
        geoFenceTypeData: payload,
      };
    case geofenceActionTypes.GET_GEO_FANCE_LABEL_SUCCESS:
      return {
        ...state,
        geoFenceLabelData: payload,
      };
    case geofenceActionTypes.GET_GEO_FANCE_LABEL_OWNER_SUCCESS:
      return {
        ...state,
        geoFenceLabelOwnerData: payload,
      };
    case geofenceActionTypes.GET_GEO_FANCE_CITY_SUCCESS:
      return {
        ...state,
        geoFenceCityData: payload,
      };
    case geofenceActionTypes.GEO_FENCE_VEHICLE_BY_FENCE_ID_ERROR:
      return {
        ...state,
        isGeofenceVehiclesByfenceIdApiError: payload,
        fenceVehicleByFenceIdData: [],
        fenceVehicleCount: 0,
      };
    case geofenceActionTypes.GEO_FENCE_VEHICLE_BY_FENCE_ID_SUCCESS:
      return {
        ...state,
        isGeofenceVehiclesByfenceIdSuccess: payload,
        fenceVehicleByFenceIdData: payload.rows,
        fenceVehicleCount: payload.count,
      };
    case geofenceActionTypes.GEO_FENCE_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        isGeofenceIamgeUploadApiError: payload,
      };
    case geofenceActionTypes.GEO_FENCE_IMAGE_UPLOAD_SUCCESS:
      if (
        state.selectedGeoFenceCard &&
        state.selectedGeoFenceCard._id === payload._id
      ) {
        return {
          ...state,
          selectedGeoFenceCard: {
            ...state.selectedGeoFenceCard,
            ...payload
          },
          isGeofenceIamgeUploadApiSuccess: payload,
          refetchGeoFenceDataCount: ++state.refetchGeoFenceDataCount
        };
      } else {
        return {
          ...state,
          isGeofenceIamgeUploadApiSuccess: payload,
          refetchGeoFenceDataCount: ++state.refetchGeoFenceDataCount
        };
      }

    case geofenceActionTypes.GEO_FENCE_EXPORT_VEHICLE_ERROR:
      return {
        ...state,
        isGeofenceVehicleexportApiError: payload,
      };
    case geofenceActionTypes.GEO_FENCE_EXPORT_VEHICLE_SUCCESS:
      return {
        ...state,
        isGeofenceVehicleexportApiSuccess: payload,
      };
    case geofenceActionTypes.TOGGLE_SELECT_GEO_FENCE_CARD:
      return {
        ...state,
        selectedGeoFenceCard: payload,
      };
    default:
      return state;
  }
};

export default dashBoardGeoFance;
