const endpoints = {
  //User
  GET_TOKEN: 'auth/gettoken',
  GET_REFRESH_TOKEN: 'auth/refresh',
  RA_ACTION: 'user/ra-action',
  FORGOT_PASSWORD: 'user/forgot-password',
  UPDATE_PASSWORD: 'user/change-password',
  USER: 'user',
  SA_VERIFY_EMAIL_SIGNUP: '/user/verify-email?purpose=signup',
  GET_ALL_MASTER_LOCATIONS: '/customeradmin/get-master-location',
  GET_ALL_CUSTOMER_ADMIN_LOCATIONS: '/customeradmin/get-all-location',
  GET_ALL_DEPART_ADMIN_USERS: '/department/du-user-list',
  GET_ALL_DEPART_ADMIN_USERS_ACTIVE_COUNT: '/department/da-user-active-count',
  PENDING_USERS: '/department/pending-approval-requests',
  GET_ALL_DEPART_ADMIN_USERS_DEACTIVATED_COUNT: '/department/da-user-deactive-count',
  APPROVE_USER_REQUEST: '/department/approve-department-user-request',
  DECLINE_USER_REQUEST: '/department/decline-department-user-request',
  UPDATE_USER_APPROVAL_REQ: '/department/update-approval-request',
  ASSIGN_TEMPLATE: '/department/assign-access-template',
  CREATE_CUSTOMER_ADMIN_LOCATIONS: '/customeradmin/createlocation',
  CUSTOMER_ADMIN_UPDATE_LOCATION: '/customers/update-location-status',
  SA_SEND_OTP: '/otp/send-otp',
  SA_VERIFY_OTP: '/otp/verify-otp',
  SA_SET_PASSWORD: '/user/set-password',
  GET_CUSTOMER_ADDRESS:'/user/get-address',
  CONFIRM_CUSTOMER_ADDRESS:'/customers/confirm-customer-address',

  VERIFY_EMAIL: '/user/verify-email',
  DU_DEPARTMENT:'/department/names',
  DU_LOCATION:'/customeradmin/get-all-locations',
  DU_CREATE_USER:'/department-user/create',
  DU_TEMPLATE:'/department-template/get-access-template',
  DU_UPDATE_USER:'/department/update-user',

  //otp
  OTP: 'otp',
  //Favorite-Vehicle
  USER_FAVORITE_VEHICLE : {
    ADD_FAVORITE_VEHICLE : "user/favourite-vehicle/:registrationNumber",
    REMOVE_FAVORITE_VEHICLE : "user/favourite-vehicle/:registrationNumber",
    GET_ALL_FAVORITE_VEHICLE : "user/favourite-vehicle" 
  },
  NOTIFICATION_PANEL : {
    GET_NOTICATION_DATA_BY_USER_UNREAD : '/notifications/getAllUnreadNotifications',
    GET_NOTICATION_DATA_BY_USER_ALL : '/notifications/getAllNotifications',
    DOWNLOAD_FILE : '/serviceHistory/downloadDoc',
    MARK_AS_READ : '/notifications/markNotificationAsRead'
  },
  //Reports
  USER_ROLE_AND_MENU_MAPPING:{
    ROLE_AND_MENU:'masters/getAllTypes',
    USER_MENU:'roleAndMenu/user-menu/:role_value',
    UPDATE_USER_ROLE_MENU:'roleAndMenu/update-role-menu'
  },

  REPORTS: {
    NON_COMM_ANALYTICS_DAY_WISE: {
      GET_NON_COMM_ANALYTICS_DAY_WISE: '/reports/non-comm-analytics-day-wise',
    },
    NON_COMM_ANALYTICS_SOURCE_WISE: {
      GET_NON_COMM_ANALYTICS_SOURCE_WISE: 'reports/non-comm-analytics-source-wise',
    },
    NON_COMM_LOGS: {
      GET_ALL_NON_COMM_LOGS: '/reports/non-comm-logs',
      EXPORT_NON_COMM_LOGS: '/reports/non-comm-logs/export',
    },
    TRIP: {
      GET_ALL_TRIPS: '/reports/trip',
      EXPORT_TRIPS_DATA: '/reports/trip/export',
      GET_STOPPAGE_DATA: '/reports/stoppage/:_id'
    },
    CHARGING: {
      GET_ALL_CHARGING_SESSIONS: '/reports/charging-sessions',
      EXPORT_CHARGING_DATA: '/reports/charging-sessions/export',
    },
    CHARGING_ANALYTICS :{
      GET_CHARGING_ANALYTICS_DATA : 'reports/analytics/charging',
      GET_ALL_CHARGING_SESSION_BY_OEM_MOEDL_CITY : 'reports/analytics/charging/by-city-oem-model',
      EXPORT_ALL_CHARGING_SESSION_BY_OEM_MOEDL_CITY : 'reports/analytics/charging/by-city-oem-model/export'
    },
    ALARM: {
      GET_ALL_ALARMS: '/reports/alarm',
      EXPORT_ALARMS_DATA: '/reports/alarm/export',
      GET_ALL_ANALYTICS_ALARMS: '/reports/allAlarms',
      GET_ALL_ALARMS_BY_TYPE: '/reports/alarm/by-type',
      GET_ALL_IMMOBILE: '/reports/current-immobile/list',
      EXPORT_PARKED_VEHICLES_FOR_DURATION: '/reports/current-immobile/export',
      EXPORT_NON_COMM_LOGS_FOR_DURATION: '/reports/non-comm-logs/by-duration/export',
      GET_ALL_IMMOBILE_COUNT: '/reports/current-immobile/count',
      GET_ALL_NON_COMM_LOGS: '/reports/all-non-comm-logs',
      GET_ALL_NON_COMM_COUNT: '/reports/non-comm-logs/count',
      GET_NON_COMM_LIST: '/reports/non-comm-logs/list',
      GET_CURRENT_IMMOBILE: '/reports/current-immobile',
      GET_CURRENT_NON_COMM_LOGS: '/reports/current-non-comm-sessions'
    },
    HISTORIC_DATA: {
      GET_ALL_HISTORIC_DATA: '/reports/historic-data',
      GET_ALL_HISTORIC_ANALYTICS_DATA: '/reports/historic-data-analytics',
      EXPORT_HISTORIC_DATA: '/reports/historic-data/export',
      EXPORT_HISTORIC_ANALYTICS_DATA: '/reports/historic-data-analytics/export'
    },

  },
  /* vehicle onboarding */
  VEHICLE: {
    VEHICLE_ONBOARDING: {
      VEHICLE_ONBOARDING_UPLOAD: '/vehicle/onboarding/upload',
      GET_ALL_ONBOARDING_LOGS: '/vehicle/onboarding/logs',
      EXPORT_ONBOARDING_LOGS: '/vehicle/onboarding/export',
      SINGLE_VEHICLE_ONBOARD: '/vehicle/onboarding/',
      VEHICLES_ONBOARD: '/vehicle/onboarding/:onboardingId',
      BULK_VEHICLES_ONBOARDED: '/vehicle/onboarding/bulk-update',
      DELETE_ONBOARDED_VEHICLES: '/vehicle/onboarding/delete-vehicles',
      EXPORT_VEHICLES_BY_ONBOARDINGID: '/vehicle/onboarding/export-by-oid',
    },
    GET_ALL_VEHICLES_TRACKING_DETAILS: '/vehicle/tracking',
    GET_SINGLE_VEHICLES_TRACKING_DETAILS: '/vehicle/tracking/:registration_number',
    GET_SUBSCRIBED_RN_NUMS: '/vehicle/tracking/subscribed',
    GET_VEHICLE_TRACKING_HISTORY_API: '/vehicle/tracking/:registration_number/history',
    GET_VEHICLE_TRACKING_CHARGING_DETAILS: '/vehicle/tracking/:registration_number/status-and-alarm',
    GET_VEHICLE_TRACKING_CAN_DATA_DETAILS: '/vehicle/tracking/:registration_number/can-data'
  },
  USERS: {
    LIST: '/user',
    GET_ALL_USERS: 'rauser/users',
    GET_USER_BY_ID: 'rauser/:_id',
    CREATE_USER : 'rauser/create',
    EDIT_USER: 'rauser/edituser/:_id',
    DELETE_USER: 'rauser/deleteuser/:_id',
    BULK_UPLOAD_USERS: 'rauser/upload',
    PENDING_SIGNUP:'/user/pending-signup',
    RESEND_EMAIL:'user/resend-email/:_id',
    APPROVAL_USER_BY_ADMIN:'user/ra-action-by-admin',
    GET_USER_ACTIVITIES: 'user-activity/get-all-activities',
    EXPORT_TO_EXCEL_ACTIVITIES: 'user-activity/export-to-excel'
  },
  MAP:{
    GET_ADDRESS_BY_LOCATION : '/map/address-by-location'
  },
  RA_STATUS_CHECK: 'user/ra-action-status-check',
  VEHICLE_SERVICE: {
    DUE: 'serviceDue/getVehicleServiceDueData',
    DUE_EXPORT: 'serviceDue/export',
    HISTORY: 'serviceHistory',
    HISTORY_EXPORT: 'serviceHistory/export',
    DOWNLOAD_DOC: 'serviceHistory/downloadDoc',
    SAVE: 'serviceDue/saveVehicleServiceDetails',
    HISTORY_FILTER: 'serviceHistory/getFilters'
  },
  GET_IP_IPIFY_API: 'https://api.ipify.org?format=json',
  MASTERS: {
    GET_ALL_MASTERS: 'masters/getAllTypes',
    CREATE_MASTER_TYPE: 'masters/createMasterType',
    GET_MASTER_BY_ID: 'masters/:_id',
    UPDATE_MASTER_TYPE: 'masters/update/:_id'
  },
  GEOFENCE: {
    GET_FENCE_BY_ID: '/geo-fence/get-fence-by-id/:_id',
    UPDATE_FENCE: '/geo-fence/update-fence/:_id',
    MASTERS: {
      LABEL: '/geo-fence/configuration/labels',
      LABEL_OWNER: '/geo-fence/configuration/label-owners',
      FENCE_OWNERS: '/geo-fence/configuration/fence-owners',
      CITIES: '/geo-fence/get-distinct-cities',
      CHECK_IF_LABEL_IS_USED: '/geo-fence/configuration/labels/check-if-used',
      CHECK_IF_LABEL_OWNER_IS_USED: '/geo-fence/configuration/label-owners/check-if-used',
      CHECK_IF_FENCE_OWNER_IS_USED: '/geo-fence/configuration/fence-owners/check-if-used'
    },
    GET_ALL_FENCES: '/geo-fence/getAllFences',
    SAVE_ALERTS_CONFIGURATION: '/geo-fence/configuration/alerts',
  },
  CREATE_FENCE : {
    CREATE_FENCE: 'geo-fence/createfence'
  },
  DASHBOARD_FENCE : {
    GET_ALL_GEO_FENCE: '/geo-fence/get-all-fences',
    GEO_FENCE_VEHICLE_BY_FENCE_ID:'/geo-fence/operation/vehicle-by-fence-id',
    GET_GEO_FENCE_LABEL: '/geo-fence/configuration/labels',
    GET_GEO_FENCE_LABEL_OWNER: '/geo-fence/configuration/label-owners',
    GET_GEO_FENCE_CITY: '/geo-fence/get-distinct-cities',
    IMAGE_UPLOAD: '/geo-fence/image-upload/:_id',
    EXPORT_VEHICLES:'/geo-fence/operation/ingress-vehicle-export'
  },
  GEOFENCE_REPORT : {
    GEO_FENCE_TYPE: '/geoFance/get-geofance-type',
    GEO_FENCE_REPORT:'/geo-fence/alert/report',
    GEO_FENCE_OWNERS:'/geo-fence/configuration/fence-owners',
    UNIQUE_GEO_FENCE_OWNERS:'/geo-fence/configuration/unique-fence-owners',
    GEO_FENCE_LABLE_OWNERS:'/geo-fence/configuration/label-owners',
    UNIQUE_GEO_FENCE_LABLE_OWNERS:'/geo-fence/configuration/unique-label-owners',
    GEO_FENCE_LABEL:'/geo-fence/configuration/labels',
    UNIQUE_GEO_FENCE_LABEL: '/geo-fence/configuration/unique-labels',
    GEO_FENCE_CITY:'/geo-fence/get-distinct-cities',
    GEO_FENCE_NAME:'/geo-fence/fence-names',
    GEO_FENCE_UNIQUE_NAME:'/geo-fence/unique-fence-names',
    GEO_FENCE_EXPORT_ALERTS: '/geo-fence/alert/export'
  },
  TRIP_REPORT_ANALYTICS:{
    TRIP_OEM_MODEL:'/reports/analytics/trip/by-city-oem-model'
  },
  GROUPS:{
    GET_GROUPS_LIST:'/group/list/:_id',
    DELETE_GROUP_BY_ID:'/group/delete/:_id',
    RENAME_GROUP_BY_ID:'/group/rename/:_id',
    SAVE_GROUP:'/group/save-group',
    GET_EDIT_GROUP:'/group/edit/:_id',
    GET_ACTIVE_VEHICLE: '/vehicle/onboarding/get-active-vehicle',
    GET_ASSIGN_DEPARTMENT_ADMIN:'/group/get-da',
    GET_ASSIGN_DEPARTMENT_USER:'/group/get-du',
    SUB_GROUPS_SAVE:'/group/save-sub-group',
    GET_ASSIGN_LIST:'/group/get-assigned-list'
  },
  RECORD:{
    VEHICLE_STATUS:'/reports/status-records/count',
    EXPORT_VEHICLE_STATUS_DATA:'reports/status-records/export'
  },
  FLEET:{
    SUBSCRIBE_VEHICLE: '/vehicle-subscription/verify-save-vehicle',
    UNSUBSCRIBE_VEHICLE: '/vehicle-subscription/unsubscribe-vehicle',
    ONBOARDING_ALL_VEHICLES: '/vehicle/onboarding/get-all-vehicles-status',
    EXPORT_ONBOARDING_ALL_VEHICLES: '/vehicle/onboarding/export-to-excel',
  },

  ADMINISTRATION:{
    GET_ADMINISTRATION_CUSTOMERS_LIST:'/customers/list',
    CREATE_CUSTOMERS:'/customers/create',
    UPDATE_CUSTOMERS:'/customers/update',
    CUSTOMERS_EXPORT_LIST:'/customers/list/export',
    UPDATE_STATUS_CUSTOMERS:'/customers/update-status'
  },
  DEPARTMENT: {
    GET_MODULES: '/department/get-modules',
    CREATE_DEPARTMENT: '/department/create',
    EDIT_DEPARTMENT: '/department/update',
    DEPARTMENT_LIST:'/department/list',
    USERS_COUNT:'/department/users/count',
    ACTIVATE_STATUS:'/department/activate',
    DEACTIVATE_STATUS:'/department/deactivate',
    VEHICLES_COUNT:'/department/vehicles/count',
    USER: {
    TEMPLATE_COUNT:'/department-template/get-access-template-count'
    }
  },
  
  DEPARTMENT_TEMPLATE: {
    TEMPLATE_LIST: '/department-template/get-access-template',
    MASTER_MODULE_LIST: '/department-template/get-master-modules',
    CREATE_TEMPLATE: '/department-template/create-template',
    UPDATE_TEMPLATE: '/department-template/update-template/:templateId',
  },


  IMMOBILIZE : {
    IMMOBILIZE_LIST:'/vehicle-control/get-immobilize-status',
    MOBILIZE_LIST:'/vehicle-control/get-mobile-status',
    IMMOBILIZE_HISTORY_LIST:'/vehicle-control/get-status-history',
    EXPORT_IMMOBILIZE_UPLOAD:'/vehicle-control/export-status',
    EXPORT_MOBILIZE_UPLOAD:'/vehicle-control/export-status',
    EXPORT_IMMOBILIZE_HISTORY_UPLOAD:'/vehicle-control/export-history',
    REASON_IMMOBILIZE_ACTION:'/vehicle-control/send-command',
    // REASON_MOBILIZE_ACTION:'/vehicle-control/send-command'
  }
}

const API_BOOK = {
  SA_VERIFY_EMAIL_SIGNUP: {
    url: endpoints.SA_VERIFY_EMAIL_SIGNUP,
    method: 'POST'
  },
  GET_ALL_MASTER_LOCATIONS: {
    url: endpoints.GET_ALL_MASTER_LOCATIONS,
    method: 'GET'
  },
  CUSTOMER_ADMIN: {
    GET_ALL_CUSTOMER_ADMIN_LOCATIONS: {
      url: endpoints.GET_ALL_CUSTOMER_ADMIN_LOCATIONS,
      method: 'POST'
    },
    CREATE_CUSTOMER_ADMIN_LOCATIONS: {
      url: endpoints.CREATE_CUSTOMER_ADMIN_LOCATIONS,
      method: 'POST'
    },
    UPDATE_LOCATION: {
      url: endpoints.CUSTOMER_ADMIN_UPDATE_LOCATION,
      method: 'PUT'
    }
  },  
  DEPARTMENT_ADMIN: {
    GET_ALL_DEPART_ADMIN_USERS: {
      url: endpoints.GET_ALL_DEPART_ADMIN_USERS,
      method: 'POST'
    },
    GET_ALL_DEPART_ADMIN_USERS_ACTIVE_COUNT: {
      url: endpoints.GET_ALL_DEPART_ADMIN_USERS_ACTIVE_COUNT,
      method: 'POST'
    },
    GET_ALL_DEPART_ADMIN_USERS_DEACTIVATED_COUNT: {
      url: endpoints.GET_ALL_DEPART_ADMIN_USERS_DEACTIVATED_COUNT,
      method: 'POST'
    },
    PENDING_USERS: {
      url: endpoints.PENDING_USERS,
      method: 'POST'
    },
    APPROVE_USER_REQUEST: {
      url: endpoints.APPROVE_USER_REQUEST,
      method: 'POST'
    },
    DECLINE_USER_REQUEST: {
      url: endpoints.DECLINE_USER_REQUEST,
      method: 'POST'
    },
    UPDATE_USER_APPROVAL_REQ: {
      url: endpoints.UPDATE_USER_APPROVAL_REQ,
      method: 'POST'
    },
    ASSIGN_TEMPLATE: {
      url: endpoints.ASSIGN_TEMPLATE,
      method: 'POST'
    }
  },  
  VERIFY_EMAIL: {
    url: endpoints.VERIFY_EMAIL,
    method: 'POST',
  },
  SA_SEND_OTP :{ 
    url: endpoints.SA_SEND_OTP,
    method: 'POST'
  },
  SA_VERIFY_OTP :{ 
    url: endpoints.SA_VERIFY_OTP,
    method: 'POST'
  },
  SA_SET_PASSWORD :{ 
    url: endpoints.SA_SET_PASSWORD,
    method: 'POST'
  },
  GET_CUSTOMER_ADDRESS: {
    url: endpoints.GET_CUSTOMER_ADDRESS,
    method: 'POST'
  },
  CONFIRM_CUSTOMER_ADDRESS:{
    url:endpoints.CONFIRM_CUSTOMER_ADDRESS,
    method:'POST'
  },
  TRIP_REPORT_ANALYTICS:{
    TRIP_OEM_MODEL:{
      url:endpoints.TRIP_REPORT_ANALYTICS.TRIP_OEM_MODEL,
      method:'POST',
    }
  },
  GET_TOKEN: {
    url: endpoints.GET_TOKEN,
    method: 'POST',
  },
  GET_REFRESH_TOKEN: {
    url: endpoints.GET_REFRESH_TOKEN,
    method: 'POST',
  },
  REGISTER_USER: {
    url: endpoints.USER,
    method: 'POST',
  },
  RA_ACTION: {
    url: endpoints.RA_ACTION,
    method: 'POST',
  },
  OTP: {
    url: endpoints.OTP,
    method: 'POST',
  },
  FORGOT_PASSWORD: {
    url: endpoints.FORGOT_PASSWORD,
    method: 'POST',
  },
  GET_USER_PROFILE: {
    url: endpoints.USER,
    method: 'GET',
  },
  USER_FAVORITE_VEHICLE:{
    ADD_FAVORITE_VEHICLE:{
      url : endpoints.USER_FAVORITE_VEHICLE.ADD_FAVORITE_VEHICLE,
      method : "PUT"
    },
    REMOVE_FAVORITE_VEHICLE:{
      url : endpoints.USER_FAVORITE_VEHICLE.REMOVE_FAVORITE_VEHICLE,
      method : "DELETE"
    },
    GET_ALL_FAVORITE_VEHICLE:{
      url : endpoints.USER_FAVORITE_VEHICLE.GET_ALL_FAVORITE_VEHICLE,
      method : "GET"
    }
  },
  NOTIFICATION_PANEL:{
    GET_NOTICATION_DATA_BY_USER_UNREAD:{
      url : endpoints.NOTIFICATION_PANEL.GET_NOTICATION_DATA_BY_USER_UNREAD,
      method : "GET"
    },
    GET_NOTICATION_DATA_BY_USER_ALL:{
      url : endpoints.NOTIFICATION_PANEL.GET_NOTICATION_DATA_BY_USER_ALL,
      method : "GET"
    },
    DOWNLOAD_FILE:{
      url : endpoints.NOTIFICATION_PANEL.DOWNLOAD_FILE,
      method : "POST"
    },
    MARK_AS_READ:{
      url : endpoints.NOTIFICATION_PANEL.MARK_AS_READ,
      method : "POST"
    },
  },
  UPDATE_PASSWORD: {
    url: endpoints.UPDATE_PASSWORD,
    method: 'PUT',
  },
  ROLE_AND_MENU_MAPPING: {
    USER_ROLE:{
      url: endpoints.USER_ROLE_AND_MENU_MAPPING.ROLE_AND_MENU,
      method: 'GET',
    },
    USER_MENU:{
      url: endpoints.USER_ROLE_AND_MENU_MAPPING.USER_MENU,
      method: 'GET',
    },
    UPDATE_USER_ROLE_MENU:{
      url: endpoints.USER_ROLE_AND_MENU_MAPPING.UPDATE_USER_ROLE_MENU,
      method: 'POST',
    },
  },

  USER_ACTIVITY : {
    GET_USER_ACTIVITIES : {
      url: endpoints.USERS.GET_USER_ACTIVITIES,
      method: 'POST'
    },
    EXPORT_TO_EXCEL : {
      url : endpoints.USERS.EXPORT_TO_EXCEL_ACTIVITIES,
      method: 'POST'
    }
  },

  USER_MANAGEMENT: {
    GET_ALL_USERS : {
      url:endpoints.USERS.GET_ALL_USERS,
      method: 'GET'
    },
    GET_USER_BY_ID : {
      url:endpoints.USERS.GET_USER_BY_ID,
      method: 'GET'
    },
    CREATE_USER : {
      url:endpoints.USERS.CREATE_USER,
      method: 'POST'
    },
    EDIT_USER : {
      url:endpoints.USERS.EDIT_USER,
      method: 'PUT'
    },
    DELETE_USER : {
      url:endpoints.USERS.DELETE_USER,
      method: 'POST'
    },
    RESEND_EMAIL : {
      url:endpoints.USERS.RESEND_EMAIL,
      method: 'POST'
    },
    BULK_UPLOAD_USERS : {
      url: endpoints.USERS.BULK_UPLOAD_USERS,
      method: 'POST'
    },
    GET_ALL_PENDING_SIGNUP_USERS : {
      url:endpoints.USERS.PENDING_SIGNUP,
      method: 'GET'
    },
    APPROVAL_USER_BY_ADMIN : {
      url:endpoints.USERS.APPROVAL_USER_BY_ADMIN,
      method: 'POST'
    },
  },

  UPDATE_USER: {
    url: endpoints.USER,
    method: 'PUT',
  },
  ALL_VEHICLES_LOCATION: {
    url: endpoints.GET_ALL_VEHICLES_LOCATION,
    method: 'GET',
  },
  SINGLE_VEHICLES_LOCATION: {
    url: endpoints.GET_SINGLE_VEHICLES_LOCATION,
    method: 'GET',
  },
  // Reports
  REPORTS: {
    NON_COMM_ANALYTICS_DAY_WISE:{
      GET_NON_COMM_ANALYTICS_DAY_WISE:{
        url: endpoints.REPORTS.NON_COMM_ANALYTICS_DAY_WISE.GET_NON_COMM_ANALYTICS_DAY_WISE,
        method: 'POST',
      },
    },
    NON_COMM_ANALYTICS_SOURCE_WISE:{
      GET_NON_COMM_ANALYTICS_SOURCE_WISE:{
        url: endpoints.REPORTS.NON_COMM_ANALYTICS_SOURCE_WISE.GET_NON_COMM_ANALYTICS_SOURCE_WISE,
        method: 'POST',
      },
    },
    NON_COMM_LOGS: {
      GET_ALL_NON_COMM_LOGS: {
        url: endpoints.REPORTS.NON_COMM_LOGS.GET_ALL_NON_COMM_LOGS,
        method: 'POST',
      },
      EXPORT_NON_COMM_LOGS: {
        url: endpoints.REPORTS.NON_COMM_LOGS.EXPORT_NON_COMM_LOGS,
        method: 'POST',
      },
    },
    TRIP: {
      GET_ALL_TRIPS: {
        url: endpoints.REPORTS.TRIP.GET_ALL_TRIPS,
        method: 'POST',
      },
      EXPORT_TRIPS_DATA: {
        url: endpoints.REPORTS.TRIP.EXPORT_TRIPS_DATA,
        method: 'POST',
      },
      GET_STOPPAGE_DATA: {
        url: endpoints.REPORTS.TRIP.GET_STOPPAGE_DATA,
        method: 'GET'
      }
    },
    CHARGING: {
      GET_ALL_CHARGING_SESSIONS: {
        url: endpoints.REPORTS.CHARGING.GET_ALL_CHARGING_SESSIONS,
        method: 'POST',
      },
      EXPORT_CHARGING_DATA: {
        url: endpoints.REPORTS.CHARGING.EXPORT_CHARGING_DATA,
        method: 'POST',
      }
    },
    CHARGING_ANALYTICS : {
      GET_CHARGING_ANALYTICS_DATA :{
        url : endpoints.REPORTS.CHARGING_ANALYTICS,
        method: "POST"
      }
    },
    ALARM: {
      GET_ALL_ALARMS: {
        url: endpoints.REPORTS.ALARM.GET_ALL_ALARMS,
        method: 'POST',
      },
      EXPORT_ALARMS_DATA: {
        url: endpoints.REPORTS.ALARM.EXPORT_ALARMS_DATA,
        method: 'POST',
      },
      GET_ALL_ANALYTICS_ALARMS: {
        url: endpoints.REPORTS.ALARM.GET_ALL_ANALYTICS_ALARMS,
        method: 'POST',
      },
      GET_ALL_ALARMS_BY_TYPE: {
        url: endpoints.REPORTS.ALARM.GET_ALL_ALARMS_BY_TYPE,
        method: 'POST'
      },
      EXPORT_PARKED_VEHICLES_FOR_DURATION: {
        url: endpoints.REPORTS.ALARM.EXPORT_PARKED_VEHICLES_FOR_DURATION,
        method: 'POST'
      },
      EXPORT_NON_COMM_LOGS_FOR_DURATION: {
        url: endpoints.REPORTS.ALARM.EXPORT_NON_COMM_LOGS_FOR_DURATION,
        method: 'POST'
      },
      GET_ALL_IMMOBILE: {
        url: endpoints.REPORTS.ALARM.GET_ALL_IMMOBILE,
        method: 'POST',
      },
      GET_ALL_IMMOBILE_COUNT: {
        url: endpoints.REPORTS.ALARM.GET_ALL_IMMOBILE_COUNT,
        method: 'POST',
      },
      GET_ALL_NON_COMM_LOGS: {
        url: endpoints.REPORTS.ALARM.GET_ALL_NON_COMM_LOGS,
        method: 'POST',
      },
      GET_ALL_NON_COMM_COUNT: {
        url: endpoints.REPORTS.ALARM.GET_ALL_NON_COMM_COUNT,
        method: 'POST',
      },
      GET_NON_COMM_LIST: {
        url: endpoints.REPORTS.ALARM.GET_NON_COMM_LIST,
        method: 'POST',
      },
      GET_CURRENT_IMMOBILE: {
        url: endpoints.REPORTS.ALARM.GET_CURRENT_IMMOBILE,
        method: 'GET'
      },
      GET_CURRENT_NON_COMM_LOGS: {
        url: endpoints.REPORTS.ALARM.GET_CURRENT_NON_COMM_LOGS,
        method: 'GET'
      }
    },
    HISTORIC_DATA: {
      GET_ALL_HISTORIC_DATA: {
        url: endpoints.REPORTS.HISTORIC_DATA.GET_ALL_HISTORIC_DATA,
        method: 'POST',
      },
      EXPORT_HISTORIC_DATA: {
        url: endpoints.REPORTS.HISTORIC_DATA.EXPORT_HISTORIC_DATA,
        method: 'POST',
      },
      GET_ALL_HISTORIC_ANALYTICS_DATA: {
        url: endpoints.REPORTS.HISTORIC_DATA.GET_ALL_HISTORIC_ANALYTICS_DATA,
        method: 'POST',
      },
      EXPORT_HISTORIC_ANALYTICS_DATA: {
        url: endpoints.REPORTS.HISTORIC_DATA.EXPORT_HISTORIC_ANALYTICS_DATA,
        method: 'POST',
      }
    },  
  },
  VEHICLE: {
    VEHICLE_ONBOARDING: {
      VEHICLE_ONBOARDING_UPLOAD: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.VEHICLE_ONBOARDING_UPLOAD,
        method: 'POST',
      },
      VEHICLE_ONBOARDING_LOGS: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.GET_ALL_ONBOARDING_LOGS,
        method: 'GET',
      },
      EXPORT_ONBOARDING_LOGS: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.EXPORT_ONBOARDING_LOGS,
        method: 'POST',
      },
      SINGLE_VEHICLE_ONBOARD: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.SINGLE_VEHICLE_ONBOARD,
        method: 'POST',
      },
      VEHICLES_ONBOARD_BY_USER: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.VEHICLES_ONBOARD,
        method: 'GET',
      },
      BULK_VEHICLES_ONBOARDED_UPDATE: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.BULK_VEHICLES_ONBOARDED,
        method: 'POST',
      },
      DELETE_ONBOARDED_VEHICLES_BY_ID: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.DELETE_ONBOARDED_VEHICLES,
        method: 'POST',
      },
      EXPORT_VEHICLES_BY_ONBOARDINGID: {
        url: endpoints.VEHICLE.VEHICLE_ONBOARDING.EXPORT_VEHICLES_BY_ONBOARDINGID,
        method: 'POST',
      }

    },
    GET_SUBSCRIBED_RN_NUMS: {
      url: endpoints.VEHICLE.GET_SUBSCRIBED_RN_NUMS,
      method: 'GET',
    },
    GET_ALL_VEHICLES_TRACKING: {
      url: endpoints.VEHICLE.GET_ALL_VEHICLES_TRACKING_DETAILS,
      method: 'GET',
    },
    GET_SINGLE_VEHICLES_TRACKING: {
      url: endpoints.VEHICLE.GET_SINGLE_VEHICLES_TRACKING_DETAILS,
      method: 'GET',
    },
    GET_VEHICLE_TRACKING_HISTORY: {
      url: endpoints.VEHICLE.GET_VEHICLE_TRACKING_HISTORY_API,
      method: "POST"
    }
    ,
    GET_VEHICLE_TRACKING_CHARGING_DETAILS: {
      url: endpoints.VEHICLE.GET_VEHICLE_TRACKING_CHARGING_DETAILS,
      method: "POST"
    },
    SYNC_VEHICLES: {
      SYNC_VEHICLE:{
        url: 'altigreen-exponent-service/v1/vehicleOem/addVehicle',
        method: 'POST'
      }
    },
    GET_VEHICLE_TRACKING_CAN_DATA_DETAILS: {
      url: endpoints.VEHICLE.GET_VEHICLE_TRACKING_CAN_DATA_DETAILS,
      method: "POST"
    }
  },
  MAP:{
    GET_ADDRESS_BY_LOCATION:{
      url: endpoints.MAP.GET_ADDRESS_BY_LOCATION,
      method: "POST"
    }
  },
  GET_ALL_USERS_LIST : {
    url: endpoints.USERS.LIST,
    method: 'GET'
  },
  RA_STATUS_CHECK: {
    url: endpoints.RA_STATUS_CHECK,
    method: 'POST',
  },
  VEHICLE_SERVICE: {
    DUE: {
      url: endpoints.VEHICLE_SERVICE.DUE,
      method: 'POST'
    },
    DUE_EXPORT: {
      url: endpoints.VEHICLE_SERVICE.DUE_EXPORT,
      method: 'POST'
    },
    HISTORY: {
      url: endpoints.VEHICLE_SERVICE.HISTORY,
      method: 'POST'
    },
    HISTORY_EXPORT: {
      url: endpoints.VEHICLE_SERVICE.HISTORY_EXPORT,
      method: 'POST'
    },
    DOWNLOAD_DOC: {
      url: endpoints.VEHICLE_SERVICE.DOWNLOAD_DOC,
      method: 'POST'
    },
    SAVE: {
      url: endpoints.VEHICLE_SERVICE.SAVE,
      method: 'POST'
    },
    HISTORY_FILTER: {
      url: endpoints.VEHICLE_SERVICE.HISTORY_FILTER,
      method: 'GET'
    }
  },
  GET_IP: {
    url: endpoints.GET_IP_IPIFY_API
  },
  MASTERS: {
    GET_ALL_MASTERS: {
      url: endpoints.MASTERS.GET_ALL_MASTERS,
      method: 'GET',
    },
    GET_MASTER_BY_ID: {
      url: endpoints.MASTERS.GET_MASTER_BY_ID,
      method: 'GET'
    },
    CREATE_MASTER_TYPE: {
      url: endpoints.MASTERS.CREATE_MASTER_TYPE,
      method: 'POST'
    },
    UPDATE_MASTER_TYPE: {
      url: endpoints.MASTERS.UPDATE_MASTER_TYPE,
      method:'PUT'
    }
  },
  
  GEO_FENCE: {
    UPDATE_FENCE: {
      url: endpoints.GEOFENCE.UPDATE_FENCE,
      method: "PUT"
    },
    GET_FENCE_BY_ID: {
      url: endpoints.GEOFENCE.GET_FENCE_BY_ID,
      method: "GET"
    },
    GET_ALL_FENCES: {
      url: endpoints.GEOFENCE.GET_ALL_FENCES,
      method:'POST' 
    },
    MASTERS: {
      SAVE_LABEL: {
        url: endpoints.GEOFENCE.MASTERS.LABEL,
        method: 'POST'
      },
      SAVE_LABEL_OWNER: {
        url: endpoints.GEOFENCE.MASTERS.LABEL_OWNER,
        method: 'POST'
      },
      GET_ALL_LABELS: {
        url: endpoints.GEOFENCE.MASTERS.LABEL,
        method: 'GET'
      },
      GET_ALL_LABEL_OWNERS: {
        url: endpoints.GEOFENCE.MASTERS.LABEL_OWNER,
        method: 'GET'
      },
      GET_ALL_FENCE_OWNERS: {
        url: endpoints.GEOFENCE.MASTERS.FENCE_OWNERS,
        method: 'GET'
      },
      SAVE_FENCE_OWNERS: {
        url: endpoints.GEOFENCE.MASTERS.FENCE_OWNERS,
        method: 'POST'
      },
      GET_ALL_CITIES:{
        url: endpoints.GEOFENCE.MASTERS.CITIES,
        method: 'GET'
      },
      CHECK_IF_LABEL_IS_USED: {
        url: endpoints.GEOFENCE.MASTERS.CHECK_IF_LABEL_IS_USED,
        method: 'GET'
      },
      CHECK_IF_LABEL_OWNER_IS_USED: {
        url: endpoints.GEOFENCE.MASTERS.CHECK_IF_LABEL_OWNER_IS_USED,
        method: 'GET'
      },
      CHECK_IF_FENCE_OWNER_IS_USED: {
        url: endpoints.GEOFENCE.MASTERS.CHECK_IF_FENCE_OWNER_IS_USED,
        method: 'GET'
      }
    },
    SAVE_ALERTS_CONFIGURATION: {
      url: endpoints.GEOFENCE.SAVE_ALERTS_CONFIGURATION,
      method: 'POST'
    },
    CREATE_FENCE : {
      url: endpoints.CREATE_FENCE.CREATE_FENCE,
      method:'POST'    
    },
    
  DASHBOARD_GEO_FENCE:{
    GET_ALL_GEO_FENCE:{
      url: endpoints.DASHBOARD_FENCE.GET_ALL_GEO_FENCE,
      method: 'POST',
    },
    GEO_FENCE_VEHICLE_BY_FENCE_ID:{
      url: endpoints.DASHBOARD_FENCE.GEO_FENCE_VEHICLE_BY_FENCE_ID,
      method: 'POST',
    },
    GET_GEO_FENCE_LABEL:{
      url: endpoints.DASHBOARD_FENCE.GET_GEO_FENCE_LABEL,
      method: 'GET',
    },
    GET_GEO_FENCE_LABEL_OWNER:{
      url: endpoints.DASHBOARD_FENCE.GET_GEO_FENCE_LABEL_OWNER,
      method: 'GET',
    },   
     GET_GEO_FENCE_CITY:{
      url: endpoints.DASHBOARD_FENCE.GET_GEO_FENCE_CITY,
      method: 'GET',
    },
    IMAGE_UPLOAD:{
      url: endpoints.DASHBOARD_FENCE.IMAGE_UPLOAD,
      method: 'POST',
    },  
    EXPORT_GEO_FENCE_VEHICLES:{
      url: endpoints.DASHBOARD_FENCE.EXPORT_VEHICLES,
      method: 'POST',
    }
  },
    GEO_FENCE_REPORTS:{
    
      GEO_FENCE_REPORT:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_REPORT,
        method: 'POST',
      },
      GEO_FENCE_EXPORT_ALERTS:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_EXPORT_ALERTS,
        method: 'POST',
      },
      GEO_FENCE_OWNERS:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_OWNERS,
        method: 'GET',
      },
      UNIQUE_GEO_FENCE_OWNERS:{
        url: endpoints.GEOFENCE_REPORT.UNIQUE_GEO_FENCE_OWNERS,
        method: 'GET',
      },
      GEO_FENCE_LABEL_OWNERS:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_LABLE_OWNERS,
        method: 'GET',
      },
      UNIQUE_GEO_FENCE_LABEL_OWNERS:{
        url: endpoints.GEOFENCE_REPORT.UNIQUE_GEO_FENCE_LABLE_OWNERS,
        method: 'GET',
      },
      GEO_FENCE_LABLE:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_LABEL,
        method: 'GET',
      },
      UNIQUE_GEO_FENCE_LABLE:{
        url: endpoints.GEOFENCE_REPORT.UNIQUE_GEO_FENCE_LABEL,
        method: 'GET',
      },
      GEO_FENCE_CITY:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_CITY,
        method: 'GET',
      },

      GEO_FENCE_NAME:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_NAME,
        method: 'GET',
      },
      GEO_UNIQUE_FENCE_NAME:{
        url: endpoints.GEOFENCE_REPORT.GEO_FENCE_UNIQUE_NAME,
        method: 'GET',
      },
    },

  
},
GROUPS:{
  GROUPS_LIST:{
    url: endpoints.GROUPS.GET_GROUPS_LIST,
    method: 'GET',
  },  
  GROUPS_DELETE:{
    url: endpoints.GROUPS.DELETE_GROUP_BY_ID,
    method: 'GET',
  },
  GROUPS_RENAME:{
    url: endpoints.GROUPS.RENAME_GROUP_BY_ID,
    method: 'PUT',
  },
  GROUPS_SAVE:{
    url: endpoints.GROUPS.SAVE_GROUP,
    method: 'POST',
  },
  SUB_GROUPS_SAVE:{
    url: endpoints.GROUPS.SUB_GROUPS_SAVE,
    method: 'POST',
  },

  GROUPS_EDIT:{
    url: endpoints.GROUPS.GET_EDIT_GROUP,
    method: 'GET',
  },
  GET_ACTIVE_VEHICLE:{
    url: endpoints.GROUPS.GET_ACTIVE_VEHICLE,
    method: 'GET',
  },
  GET_ASSIGN_DEPARTMENT_ADMIN:{
    url:endpoints.GROUPS.GET_ASSIGN_DEPARTMENT_ADMIN,
    method:'GET'
  },
  GET_ASSIGN_DEPARTMENT_USER:{
    url:endpoints.GROUPS.GET_ASSIGN_DEPARTMENT_USER,
    method:'GET'
  },
  GET_ASSIGN_LIST :{
     url:endpoints.GROUPS.GET_ASSIGN_LIST,
    method:'POST'
  }
},
RECORD:{
  VEHICLE_STATUS:{
    url: endpoints.RECORD.VEHICLE_STATUS,
    method: 'POST',
  },  
  EXPORT_VEHICLE_STATUS_DATA:{
    url: endpoints.RECORD.EXPORT_VEHICLE_STATUS_DATA,
    method: 'POST',
  }
},
FLEET: {
  SUBSCRIBE: {
    url: endpoints.FLEET.SUBSCRIBE_VEHICLE,
    method: 'POST'
  },
  UNSUBSCRIBE: {
    url: endpoints.FLEET.UNSUBSCRIBE_VEHICLE,
    method: 'POST'
  },
  ONBOARDING_ALL_VEHICLES: {
    url: endpoints.FLEET.ONBOARDING_ALL_VEHICLES,
    method: 'POST'
  },
  EXPORT_ONBOARDING_ALL_VEHICLES: {
    url: endpoints.FLEET.EXPORT_ONBOARDING_ALL_VEHICLES,
    method: 'POST'
  },
},
ADMINISTRATION:{
  CUSTOMERS_LIST : {
    url: endpoints.ADMINISTRATION.GET_ADMINISTRATION_CUSTOMERS_LIST,
    method:'POST'
  },
  CREATE_CUSTOMERS : {
    url: endpoints.ADMINISTRATION.CREATE_CUSTOMERS,
    method:'POST'
  },
  UPDATE_CUSTOMERS : {
    url: endpoints.ADMINISTRATION.UPDATE_CUSTOMERS,
    method:'POST'
  },

  CUSTOMERS_EXPORT_LIST : {
    url: endpoints.ADMINISTRATION.CUSTOMERS_EXPORT_LIST,
    method:'POST'

  },
  UPDATE_STATUS_CUSTOMERS : {
    url: endpoints.ADMINISTRATION.UPDATE_STATUS_CUSTOMERS,
    method:'POST'

  }
},
DEPARTMENT: {
  MODULES_LIST: {
    url: endpoints.DEPARTMENT.GET_MODULES,
    method: 'GET',
  },
  CREATE_DEPARTMENT: {
    url: endpoints.DEPARTMENT.CREATE_DEPARTMENT,
    method: 'POST',
  },
  EDIT_DEPARTMENT: {
    url: endpoints.DEPARTMENT.EDIT_DEPARTMENT,
    method: 'POST',
  },
  DEPARTMENT_LIST: {
    url: endpoints.DEPARTMENT.DEPARTMENT_LIST,
    method: 'POST',

  },
  USERS_COUNT: {
    url: endpoints.DEPARTMENT.USERS_COUNT,
    method: 'GET',
  },
  ACTIVATE_STATUS: {
    url: endpoints.DEPARTMENT.ACTIVATE_STATUS,
    method: 'POST',
  },
  DEACTIVATE_STATUS: {
    url: endpoints.DEPARTMENT.DEACTIVATE_STATUS,
    method: 'POST',
  },
  DU_TEMPLATE_COUNT: {
    url: endpoints.DEPARTMENT.USER.TEMPLATE_COUNT,
    method: 'GET'
  },
  VEHICLES_COUNT: {
    url: endpoints.DEPARTMENT.VEHICLES_COUNT,
    method: 'GET',
  },
  
},

DEPARTMENT_TEMPLATE: {
  TEMPLATE_LIST: {
    url: endpoints.DEPARTMENT_TEMPLATE.TEMPLATE_LIST,
    method: 'GET',
  },
  MASTER_MODULE_LIST: {
    url: endpoints.DEPARTMENT_TEMPLATE.MASTER_MODULE_LIST,
    method: 'GET',
  },
  CREATE_TEMPLATE: {
    url: endpoints.DEPARTMENT_TEMPLATE.CREATE_TEMPLATE,
    method: 'POST',
  },
  UPDATE_TEMPLATE: {
    url: endpoints.DEPARTMENT_TEMPLATE.UPDATE_TEMPLATE,
    method: 'PUT',
  },
},

DU_DEPARTMENT: {
  url: endpoints.DU_DEPARTMENT,
  method: 'POST',
},
DU_LOCATION: {
  url: endpoints.DU_LOCATION,
  method: 'GET',
},
DU_CREATE_USER :{
  url:endpoints.DU_CREATE_USER,
  method:'POST'
},
DU_TEMPLATE : {
  url: endpoints.DU_TEMPLATE,
  method:'GET'
},
DU_UPDATE_USER : {
  url: endpoints.DU_UPDATE_USER,
  method:'PUT'
},



IMMOBILIZE:{
  IMMOBILIZE_LIST:{
  url: endpoints.IMMOBILIZE.IMMOBILIZE_LIST,
  method:'POST'
  },
  EXPORT_IMMOBILIZE_UPLOAD:{
    url: endpoints.IMMOBILIZE.EXPORT_IMMOBILIZE_UPLOAD,
    method:'POST'
    },

    MOBILIZE_LIST:{
      url: endpoints.IMMOBILIZE.MOBILIZE_LIST,
      method:'POST'
      },

    EXPORT_MOBILIZE_UPLOAD:{
        url: endpoints.IMMOBILIZE.EXPORT_MOBILIZE_UPLOAD,
        method:'POST'
    },

    IMMOBILIZE_HISTORY_LIST:{
       url: endpoints.IMMOBILIZE.IMMOBILIZE_HISTORY_LIST,
        method:'POST'

    },
    EXPORT_IMMOBILIZE_HISTORY_UPLOAD:{
         url: endpoints.IMMOBILIZE.EXPORT_IMMOBILIZE_HISTORY_UPLOAD,
        method:'POST'
    },
    REASON_IMMOBILIZE_ACTION : {
       url: endpoints.IMMOBILIZE.REASON_IMMOBILIZE_ACTION,
        method:'POST'
    },
  //   REASON_MOBILIZE_ACTION : {
  //     url: endpoints.IMMOBILIZE.REASON_IMMOBILIZE_ACTION,
  //      method:'POST'
  //  }

    
}

}


export default API_BOOK
