import React from 'react';
import '../css/VehicleStatus.css';
import VehicleStatusRecordsDetails from './VehicleStatusRecordsDetails';
import { getFormattedOnlyDate } from '../../../../utils/common-methods';
import { secondsToHoursAndMinutesHHMM } from '../../../../utils/common-methods';

const VehicleStatusRecordsDaywise = (props) => {
	const { subrecordsData, onRcVehicleDetailClick, isRcVehicleDetailExpanded, vehicleStatus } = props;

	const renderStatusBlock = (label, color) => {
		const statusCount = subrecordsData?.statusCount?.[label];

		return (
			<td className='ps-3 text-nowrap Rc_record_status_block'>
				<div className='rc__status_block_container'>
					<span>{label} </span>
					<p className='fw500 Rc__records__row__td'>
						{statusCount !== undefined ? secondsToHoursAndMinutesHHMM(statusCount) : 0}
					</p>
					<hr className={`rc__border${color}`} />
				</div>
			</td>
		);
	};

	const getStatusBlocks = () => {
		if (vehicleStatus === 'NonComm') {
			const statusCount = subrecordsData?.statusCount?.NonComm;
			return (
				<>
					<td colSpan={8}>
						<div className='ps-3 text-nowrap rc__status_block_nocom_container'>
							<span>Non Communication </span>
							<p className='fw500 Rc__records__row__td'>
								
								{statusCount !== undefined ? secondsToHoursAndMinutesHHMM(statusCount) : 0}
							</p>
							<hr className={`rc__border_noncommunication`} />
						</div>
					</td>
				</>
			);
		} else {
			return (
				<>
					{renderStatusBlock('Live', 'Green')}
					{/* Note: Merge Idle status to Live status */}
					{/* {renderStatusBlock('Idle', 'Orange')} */}
					{renderStatusBlock('Parked', 'Brown')}
					{renderStatusBlock('Charging', 'Purple')}
				</>
			);
		}
	}

	const filteredRecords = subrecordsData?.records.filter((d) => d.length);

	return (
		<>
			<tr data-testid={`status-record-row-clickable-${subrecordsData?.date.split('T')[0]}`} onClick={onRcVehicleDetailClick}>
				<td
					style={{ maxWidth: 10, padding: '0 !important' }}
					className={
						isRcVehicleDetailExpanded && filteredRecords.length
							? 'Rc__records-line-col Rc__records-line-col--main'
							: ''
					}
				>
					<p className='fw500 Rc__records__row__td'></p>
				</td>

				<td className='ps-3 text-nowrap'>
					<table>
						<tbody>
							<tr className='Rc__records__view__table'>
								<td className='ps-3 text-nowrap'>
									{subrecordsData?.date && (
										<p className='fw500 Rc__records__row__td'>
											{' '}
											{getFormattedOnlyDate(subrecordsData?.date)}
										</p>
									)}
								</td>
								{getStatusBlocks()}

								<td className='ps-3 text-nowrap'></td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
			{isRcVehicleDetailExpanded &&
				filteredRecords.map((recordDetail, outerIndex) =>
					recordDetail?.map((Data, innerIndex) => (
						<VehicleStatusRecordsDetails
							key={`${outerIndex}-${innerIndex}`}
							recordDetail={Data}
							recordDetailIndex={innerIndex}
							lastIndex={
								recordDetail.length - 1 === innerIndex && filteredRecords.length - 1 === outerIndex
							}
						/>
					))
				)}
		</>
	);
};

export default VehicleStatusRecordsDaywise;
