// External packages import
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

// Internal components import
import Button from '../../../shared/form/Button/Button'
import DepartmentAddEdit from './DepartmentAddEdit/DepartmentAddEdit'
import SearchInput from '../../../shared/form/SearchInput/SearchInput';
import Pagination from '../../../shared/pagination/Pagination';
import Table from '../../../shared/table/Table'
import Popover from '../../../shared/modals/Popover/Popover'
import InformativeModal, { VARIANTS } from '../../../shared/modals/InformativeModal/InformativeModel'
import Modal from '../../super-admin/SAAdmin/modal/Modal'
import Dropdown from '../../../shared/ui/Dropdown/Dropdown'

// Services Utils and Store import
import departmentAction from '../../../../store/actions/department/department'
import { getFormattedOnlyDate } from '../../../../utils/common-methods'

// Style import
import styles from './DepartmentList.module.css'


const ITEMS_PER_PAGE = 8;
const ACTIONS = {
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
  UPDATE_DEPARTMENT: 'UPDATE_DEPARTMENT',
}

const DepartmentList = () => {
  const [search, setSearchKey] = useState('');
  const [sortField, setSortfield] = useState({ sortBy: '', sortOrder: '' })
  const [currentPage, setCurrentPage] = useState(1);
  const [statusModalData, setStatusModalData]=useState({
    isOpen:false,
    departmentId:'',
    departmentName:'',
    status:'',
  });
  const [showManageDepartmentModal, setShowManageDepartmentModal] = useState(false)
  const [editDepartmentData, setEditDepartmentData] = useState({
    departmentId: '',
    departmentName: '',
    assignedModules: [],
    departmentAdminName: '',
    departmentAdminEmail: '',
  })
  const [informativeModalData, setInformativeModalData] = useState({
    message: '',
    isOpen: false,
    variant: VARIANTS.SUCCESS,
    action: '',
  })

  const dispatch = useDispatch();
  const moduleList = useSelector(state => state.department?.moduleList)
  const departmentList = useSelector(state => state.department?.departmentList)
  
  useEffect(() => {
    dispatch(departmentAction.getModules())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortField, currentPage]);

  const columns = [
    {
      key: 'rId',
      header: 'DEPARTMENT ID',
    },
    {
      key: 'departmentName',
      header: 'DEPARTMENT',
      sortFieldName: 'departmentName',
      sortable: true,
    },
    {
      key: 'createdOn',
      header: 'CREATED ON',
      sortable: true,
      sortFieldName: 'createdOn',
      renderCell: (row) => (
        row.createdOn ? getFormattedOnlyDate(row.createdOn) : 'NA'
      )
    },
    {
      key: 'assignedModulesCount',
      header: 'MODULES ASSIGNED',
      sortable: true,
      sortFieldName: 'assignedModulesCount',
      renderCell: (row) => {
        return (
          <>
            {row.assignedModulesCount}
            <Popover
              triggerComponent={
                <button className="row-btn">
                  <img src='/images/tooltipInfo.svg' alt='info' className='alert-history-info' />
                </button>
              }
              bodyComponent={
                <div className={`${styles.tooltip_inner_container}`}>
                  {row.modules.map(module => (
                    <div key={module.moduleId}>{module.moduleName}</div>
                  ))}
                </div>
              }
              trigger="hover"
              placement="left-start"
            />
          </>
        );
      },
    },
    {
      key: 'adminName',
      header: 'DEPARTMENT ADMIN',
      sortFieldName: 'adminName',
      sortable: true,
      renderCell: (row) => {
        return (
          <>
            {row.adminName}
            <Popover
              triggerComponent={
                <button className="row-btn">
                  <img src='/images/tooltipInfo.svg' alt='info' className='alert-history-info' />
                </button>
              }
              bodyComponent={
                <div className={`${styles.tooltip_inner_container}`} >
                  <strong>Admin Email ID</strong>
                  <br />
                  {row.adminEmail}
                </div>
              }
              trigger="hover"
              placement="right-start"
            />
          </>
        );
      },
    },
    {
      key: 'status',
      header: 'STATUS',
      sortFieldName: 'status',
      sortable: true,
      renderCell: (row) => (
        <span style={{ color: row.status === "Active" ? '#69FE97' : '#F29900' }}>
          {row.status}
        </span>
      ),
    },
    {
      key: 'action',
      header: 'ACTION',
      renderCell: (row, index) => (
        <Dropdown
          triggerComponent={<img src="/images/verticalThreedots.svg" alt="more menu icon" />}
          dropdownItems={[{
            label: 'Edit',
            value: 'EDIT',
            keyExtractor: () => `edit_${index}`,
            onClick: () => {
              const assignedModuleIds = row?.modules.map(module => module.moduleId);
              setEditDepartmentData({
                departmentId: row?.id,
                departmentName: row?.departmentName,
                assignedModules: assignedModuleIds,
                departmentAdminName: row?.adminName,
                departmentAdminEmail: row?.adminEmail,
              })
              setTimeout(() => setShowManageDepartmentModal(true), 300)
            }
          }, {
            label: row?.status === 'Active' ? 'Deactivate' : 'Activate',
            value: row?.status === 'Active' ? 'Deactivate' : 'Activate',
            keyExtractor: () => `deactivate_${index}`,
            onClick: () => handleStatusOpen(row?.id, row?.departmentName, row?.status)
          }]}
        />
      ),
    },
  ];

  const fetchData = async () => {
    dispatch(departmentAction.getDepartmentList({
      searchText: search,
      pageSize: ITEMS_PER_PAGE,
      pageIndex: currentPage,
      sortBy: sortField.sortBy,
      sortOrder: sortField.sortOrder,
    }))
  };

  const handleUpdateStatus = async () => {
    try {
      let res;
      if (statusModalData?.status === 'Inactive') {
        res = await departmentAction.ActiveStatusDepartment({
          data: {
            departmentId: statusModalData?.departmentId,
          }
        });
      } else if (statusModalData?.status === 'Active') {
        res = await departmentAction.DeActiveStatusDepartment({
          data: {
            departmentId: statusModalData?.departmentId,
          }
        });
      }
  
      if (res && res.status === 200) {
        setInformativeModalData({
          message:  statusModalData?.status==='Active'?'Deactivated department admin and subusers successfully':'Activated department admin and subusers successfully',
          isOpen: true,
          variant: VARIANTS.SUCCESS,
        })
        handleStatusModalClose();
        fetchData();
      }
    } catch (error) {
      console.error(error);
      setInformativeModalData({
        message: 'Something went wrong!!!',
        isOpen: true,
        variant: VARIANTS.ERROR,
      })
    }
  };
  

  
  const sortHandler = (sortBy, sortOrder) => {
    setSortfield({
      sortBy,
      sortOrder
    })
  }

  const handleManageDepartmentModalClose = () => {
    setShowManageDepartmentModal(false)
    setEditDepartmentData({
      departmentId: '',
      departmentName: '',
      assignedModules: [],
      departmentAdminName: '',
      departmentAdminEmail: '',
    })
  }

  const handleDepartmentSave = async (payload) => {
    const isEdit = editDepartmentData?.departmentId ? true : false
    
    try {
      let response
      const requestData = {
        adminEmail: payload.departmentAdminEmail,
        adminName: payload.departmentAdminName,
        departmentName: payload.departmentName,
        assignedModules: payload.assignedModules.map(d => d.value)
      }
      if (isEdit) {
        response = await departmentAction.editDepartment({ ...requestData, departmentId: editDepartmentData.departmentId })
      } else {
        response = await departmentAction.createDepartment(requestData)
      }

      if (response?.data?.status === 200){
        setInformativeModalData({
          message: isEdit ? 'Successfully Updated' : 'Successfully Created',
          isOpen: true,
          variant: VARIANTS.SUCCESS,
          action: isEdit ? ACTIONS.UPDATE_DEPARTMENT : ACTIONS.CREATE_DEPARTMENT,
        })
        setShowManageDepartmentModal(false)
        setSearchKey('')
        setSortfield({ sortBy: '', sortOrder: '' })
        setCurrentPage(1)
        fetchData();
        return
      }

      let errorMessage = 'Something went wrong'
      if (typeof response?.data?.error === 'string') {
        errorMessage = response?.data?.error
      }
      if (typeof response?.data?.error === 'object') {
        errorMessage = response?.data?.error[0]?.message
      }
      setInformativeModalData({
        message: errorMessage,
        isOpen: true,
        variant: VARIANTS.ERROR,
        action: isEdit ? ACTIONS.UPDATE_DEPARTMENT : ACTIONS.CREATE_DEPARTMENT,
      })
    } catch (error) {
      console.log('handleDepartmentSave failed', error)
      setInformativeModalData({
        message: 'Something went wrong!!!',
        isOpen: true,
        variant: VARIANTS.ERROR,
        action: isEdit ? ACTIONS.UPDATE_DEPARTMENT : ACTIONS.CREATE_DEPARTMENT,
      })
    }
  }

  const handleInformativeModalClose = () => {
    setInformativeModalData({
      message: '',
      isOpen: false,
      variant: '',
      action: '',
    })
  }

  const handleStatusModalClose = () => {
    setStatusModalData({
      ...statusModalData,
      isOpen: false,
   
    })
  }



  const handleStatusOpen = (departmentId, departmentName, status) => {
   
    if (departmentId)
    { 
      setStatusModalData({
      isOpen:true,
      departmentId:departmentId,
      departmentName:departmentName,
      status:status,
      activeStatus:(status === 'Active' ? false : true)
       })
    }


  }

  return (
    <>
      <div className={`${styles.department_container} d-flex col-12 flex-column h-70 mt-2`} >
      <div className="d-flex justify-content-between  flex-row my-3">
            <div>
              <SearchInput
              setSearchKey={setSearchKey}
              setCurrentPage={setCurrentPage}
              placeholder="Search"
              />
            </div>

            <div className="d-flex flex-row justify-content-end ">
              <div className={cx(styles.createDepartmentButton)}>
              <Button variant="BLUE" onClick={() => setShowManageDepartmentModal(true)}>
                Create Department
              </Button>
              </div>
            </div>
          </div>
         <Table 
          columns={columns}
          data={departmentList.departmentList}
          handleSortHandler={(e, v) => sortHandler(e, v)}
          message="NO DATA FOUND"
        />

      
      </div>

      {departmentList?.departmentList?.length ? (<div className={`${styles.paginationContainer} mb-1 position-relative`}>
            <Pagination
              disabled={false}
              totalPages={Math.ceil(departmentList?.count / ITEMS_PER_PAGE)}
              currentPage={currentPage}
              setPage={setCurrentPage}
            />

          </div>
          ) : (
            <></>
          )}
      
      <DepartmentAddEdit
        isOpen={showManageDepartmentModal}
        onClose={handleManageDepartmentModalClose}
        onSubmit={handleDepartmentSave}
        moduleList={moduleList}
        editData={editDepartmentData}
      />

      <InformativeModal
        isOpen={informativeModalData.isOpen}
        message={informativeModalData.message}
        variant={informativeModalData.variant}
        onClose={() => handleInformativeModalClose(informativeModalData.action)}
      />

      <Modal
          isVisible={statusModalData?.isOpen}
          closeModal={handleStatusModalClose}
          name={statusModalData?.departmentName}
          handleUpdateStatus={handleUpdateStatus}
          status={statusModalData?.status}
      />
    </>
  )
}

export default DepartmentList
/*conflict-resolution-will be removed later*/