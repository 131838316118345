import { IsValidEmail, IsValidPassword } from "../common-methods";

// Method to  set field error message
const SetErrorMessage = (
  fieldName,
  message = "error field",
  { setState, state }
) => {
  setState({
    ...state,
    [fieldName]: message,
  });
};

// Method to check field errors
export const CheckFieldErrors = (
  { fieldName, value },
  passwordFormData,
  state,
  setState
) => {
  const currentState = { setState, state };
  switch (fieldName) {
    case "firstName":
      const firstNameRegex = /^[A-Za-z]+$/;
      !firstNameRegex.test(value)
        ? SetErrorMessage(
            fieldName,
            "This field must contain only alphabets",
            currentState
          )
        : value.length < 2
        ? SetErrorMessage(
            fieldName,
            "First name must be at least 2 characters",
            currentState
          )
        : true && value.length > 16
        ? SetErrorMessage(
            fieldName,
            "First name must be of maximum 16 characters",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "lastName":
      const lastNameRegex = /^[A-Za-z]+$/;
      !lastNameRegex.test(value)
        ? SetErrorMessage(
            fieldName,
            "This field must contain only alphabets",
            currentState
          )
        : value.length < 2
        ? SetErrorMessage(
            fieldName,
            "Last name must be at least 2 characters",
            currentState
          )
        : true && value.length > 16
        ? SetErrorMessage(
            fieldName,
            "Last name must be of maximum 16 characters",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "email":
      !IsValidEmail(value) && value.length > 0
        ? SetErrorMessage(fieldName, "Enter valid email id", currentState)
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "otp":
        value.length === 0
          ? SetErrorMessage(fieldName, "Please enter otp", currentState)
          : SetErrorMessage(fieldName, "", currentState);
        break;
    case "userRole":
      value.length === 0
        ? SetErrorMessage(fieldName, "User role is required", currentState)
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "vehicleBaseLocation":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Vehicle base location is required",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "oemAndModel":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Vehicle oem and model is required",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "purchaseDate":
      new Date(value) > new Date()
        ? SetErrorMessage(
            fieldName,
            "Purchase date should not be a future date",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "type":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Vehicle type is required",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "model":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Vehicle model is required",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "registrationNumber":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Vehicle number is required",
            currentState
          )
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "chassisNumber":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Chassis number is required",
            currentState
          )
        : value.length !== 17 ? SetErrorMessage(
          fieldName,
          "Chassis number must be 17 characters long",
          currentState
        ) :  SetErrorMessage(fieldName, "", currentState);
      break;
    case "motorNumber":
      value.length === 0
        ? SetErrorMessage(
            fieldName,
            "Motor number is required",
            currentState
          )
        : value.length <= 7 || value.length >= 22 ? 
        SetErrorMessage(
          fieldName,
          "Motor number should be min. 8 characters long",
          currentState
        ) :  SetErrorMessage(fieldName, "", currentState);
      break;
    case "contactNo":
      !IsValidEmail(value)
        ? SetErrorMessage(fieldName, "Invalid contact number", currentState)
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "password":
      !IsValidPassword(value) && value.length > 0
        ? SetErrorMessage(fieldName, "Incorrect password format", currentState)
        : SetErrorMessage(fieldName, "", currentState);
      break;
    case "confirmPassword":
      passwordFormData.password !== value && value.length > 0
        ? SetErrorMessage(fieldName, "Confirm password must be same as password", currentState)
        : SetErrorMessage(fieldName, "", currentState);
      break;
      case "comments":
        value.length < 2
          ? SetErrorMessage(
              fieldName,
              "Please enter comment at least 2 characters long",
              currentState
            )
          : SetErrorMessage(fieldName, "", currentState);
        break;
    default:
      break;
  }
};

// Method to count field length > 0
export const countFormData = (state) => {
  let count = 0;
  Object.values(state).forEach((field) => field.length > 0 && (count += 1));
  return count;
};
