import React from "react";
import "./NDVehicleInfoToggleTabs.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NDVehicleInfoToggleTabs = (props) => {
  const setActiveTabHanddler = (tab) => {
    props.setActiveTabContent(tab);
  };

  const PopoverComponent = ({ iconSrc, altText, value, labelText ,placement}) => (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 0, hide: 0 }}
      overlay={<Tooltip style={{backgroundColor: '#505288'}} className={"nd-dark-tooltip-inner nd-dark-overlay-placement-" + placement}>{labelText}</Tooltip>}>
        <div style={{cursor: 'pointer'}}>
          <img src={iconSrc} alt={altText} width={28} />
          <span
            style={{ color: "#39FFFF", fontSize: "10px", display: "block" , }}
          >
            {value}
          </span>
        </div>
    </OverlayTrigger>
  );
  return (
    <>
      <div className="nd__vehicle-info-toggle-container">

        <div className="nd__tabs-container">
          <div
            data-testid="vehicle-info-tracking-tab"
            onClick={() => setActiveTabHanddler("TRACKING_VIEW")}
          >
            <img
              src="/images/svgicon/trackingScreen/trackingBtn.svg"
              alt="trackingBtn"
            />
          </div>
          <PopoverComponent
            iconSrc="/images/svgicon/trackingScreen/distance.svg"
            altText="distanceBtn"
            value={props?.vehicleTrackingCanData?.odometerDiff >= 0 ? parseFloat(props?.vehicleTrackingCanData?.odometerDiff).toFixed(1) + ' Km' : ''}
            labelText="Distance Covered"
            placement='bottom-start'
           
          />

          {/* <PopoverComponent
            iconSrc="/images/svgicon/trackingScreen/EnergyUtilized.svg"
            altText="EnergyUtilized"
            value={props?.vehicleTrackingCanData?.energyUtilized >= 0 ? parseFloat(props?.vehicleTrackingCanData?.energyUtilized).toFixed(1) + ' kWh' : ''}
            labelText="Energy Utilized"
            placement='top-start'
          />

          <PopoverComponent
            iconSrc="/images/svgicon/trackingScreen/EnergyCharged.svg"
            altText="EnergyChargedBtn"
            value={props?.vehicleTrackingCanData?.energyCharged >= 0 ? parseFloat(props?.vehicleTrackingCanData?.energyCharged).toFixed(1) + ' kWh' : ''}
            labelText="Energy Charged"
            placement='bottom-start'
          /> */}

          
        </div>
      </div>
    </>
  );
};

export default NDVehicleInfoToggleTabs;