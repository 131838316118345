import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BsCircle } from "react-icons/bs";
import "./ChartArea.css";
import { Col } from "react-bootstrap";

const ChartArea = (props) => {
  const baseTestId = props['data-testid'] || 'chartarea';
  const [typeSelected, setTypeSelected] = useState();
  const [selectedData, setSelectedData] = useState([]);


  const CustomXAxisTick = ({ x, y, payload }) => {
    const lines = payload.value.split(" ").reverse();
    const split = lines[1].split("-");
    lines[1] = split[0] + "-" + split[1];

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={8}
          dy={"0"} // Adjust vertical spacing between lines
          textAnchor="end"
          fill="#1F2A5D"
          key={0}
          style={{ font: "normal normal 500 12px Inter" }}
        >
          {lines[0]}
        </text>
        <text
          x={18}
          y={8}
          dy={"1.4em"} // Adjust vertical spacing between lines
          textAnchor="end"
          fill="#1F2A5D"
          key={1}
          style={{ font: "normal normal 500 12px Inter" }}
        >
          {lines[1]}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label, yLabel }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ background: '#939393', opacity: "0.87", borderRadius: '8px', padding: '3px 6px 3px 3px' }}>
          {yLabel && <p className="label" style={{ margin: '2px 0px', textAlign: 'center', color: "#FFFFFF" }}> {yLabel}</p>}
          <p className="label" style={{ margin: '2px 0', textAlign: 'center', color: "#FFFFFF" }}> {`${payload[0].payload.formattedDateTime}`}</p>
          {
            payload?.map(chartData => {
              return <p className="label" key={chartData.dataKey} style={{ margin: '2px 0', color: "#FFFFFF" }}>
                <BsCircle style={{ background: chartData?.color }} className="circular" />{`${chartData.dataKey} : ${chartData.payload[chartData.dataKey]}`}
              </p>
            })
          }
        </div>
      );
    }

    return null;
  };

  const setSelectedDataType = (type) => {
    setTypeSelected(type);
  };

  const SelectAndUnSelectData = (dataKey) => {
    selectedData.some((selected) => selected === dataKey)
      ? setSelectedData(selectedData.filter((x) => x !== dataKey))
      : setSelectedData([...selectedData, dataKey]);
  };

  useEffect(() => {
    if (props.chartData) {
      if (!typeSelected) {
        setTypeSelected(props?.chartData[0]?.dataType);
        setSelectedData(
          props?.chartData[0]?.dataLevel?.map((levelData) => {
            return levelData.key;
          })
        );
      } else {
        setSelectedData(
          props?.chartData
            ?.filter((x) => x.dataType === typeSelected)[0]
            ?.dataLevel?.map((levelData) => {
              return levelData.key;
            })
        );
      }
    }

  }, [props.chartData, typeSelected]);

  return (
    <div className="chart-area-card">
      <div className="radio-container">
        {props?.chartData && props?.chartData?.map((chart, index) => {
          return (
            <>
              <span key={chart?.dataType}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"chartType" + props?.name ? props?.name : ''}
                  id={chart?.dataType}
                  checked={typeSelected === chart?.dataType}
                  onChange={() => { setSelectedDataType(chart?.dataType) }}
                  data-testid={`${baseTestId}-type-radio-${chart?.title}`}
                />
                <text className="radio-checkbox-text-heading">
                  {" "}
                  {chart?.title}{" "}
                </text>
              </span>
            </>
          );
        })}
      </div>

      {props?.chartData && props?.chartData
        ?.filter((chart) => chart?.dataType === typeSelected)
        ?.map((chart) => {
          return (
            <div className="chart-container" key={chart?.dataType}>
              <ResponsiveContainer width={chart?.width} height={chart?.height} >
                {chart?.chartData?.length > 0 ? (
                  <AreaChart
                    data={chart?.chartData}
                    margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
                  >
                    {
                      /* Color shading for chart, start from graph line to bottom */
                      chart?.isColorShadingEnable && (
                        <defs>
                          {chart?.dataLevel?.map((levelData, index) => {
                            return (
                              <linearGradient
                                id={"color" + index}
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                                key={"color" + index}
                              >
                                <stop
                                  offset="5%"
                                  stopColor={levelData?.chartColor ? levelData?.chartColor : chart?.colorLevel[levelData.value]}
                                  stopOpacity={0.3}
                                />
                                <stop
                                  offset="95%"
                                  stopColor={levelData?.chartColor ? levelData?.chartColor : chart?.colorLevel[levelData.value]}
                                  stopOpacity={0.05}
                                />
                              </linearGradient>
                            );
                          })}
                        </defs>
                      )
                    }
                    <XAxis
                      dataKey={chart?.xDataKey}
                      tickCount={10}
                      tick={
                        chart?.customXAxisTick
                          ? chart?.customXAxisTick
                          : CustomXAxisTick
                      }
                    >
                      <Label
                        className="xLabel"
                        value={chart?.xLabel}
                        offset={-30}
                        position="insideBottom"
                      />
                    </XAxis>

                    <YAxis>
                      <Label
                        className="yLabel"
                        value={chart?.yLabel}
                        angle={-90}
                        dy={40}
                        position="insideLeft"
                      />
                    </YAxis>

                    <CartesianGrid
                      strokeDasharray="4 4"
                      horizontal={chart?.gridLine?.horizontal}
                      vertical={chart?.gridLine?.Vertical}
                    />

                    <Tooltip yLabel={chart?.yLabel}
                      content={
                        chart?.customTooltip
                          ? chart?.customTooltip
                          : CustomTooltip
                      }
                    />

                    {
                      /* Graph line */
                      chart?.dataLevel
                        ?.filter((level) =>
                          selectedData?.some((data) => data === level?.key)
                        )
                        .map((levelData, index) => {
                          return (
                            <Area
                              key={levelData?.key}
                              type="monotone"
                              dataKey={levelData?.key}
                              stroke={levelData?.chartColor ? levelData?.chartColor : chart?.colorLevel[levelData?.value]}
                              strokeWidth={1.5}
                              fill={"url(#color" + index + ")"}
                            />
                          );
                        })
                    }
                  </AreaChart>
                ) : (
                  <>
                    <Col xs="12" className="no-data-map-trip" key={"no-data-map-trip"}>
                      <Col xs="9" className="custom-map-hr-trip">
                        <div className="no-data-analytics-trip"> No Data</div>
                        <hr className="hr-line-no-data-trip"></hr>
                        <div className="vertical-line-no-data-trip"></div>
                        <div className="custom-map-time-trip">Time</div>
                        <div className="custom-map-count-trip">Count</div>
                      </Col>
                    </Col>
                  </>
                )}
              </ResponsiveContainer>

              <div className="checkbox-main-container">
                {chart?.dataLevel?.map((levelData) => {
                  return (
                    <span key={levelData?.key}
                      className="checkbox-custom-container"
                      onClick={() => {
                        SelectAndUnSelectData(levelData?.key);
                      }}
                      data-testid={`${baseTestId}-leveltype-checkbox-${levelData?.key}`}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={levelData?.key}
                        id={levelData?.key}
                        checked={selectedData?.some((x) => x === levelData?.key)}
                        onChange={() => { }}
                      />

                      <text className="radio-checkbox-text">
                        <BsCircle
                          style={{
                            background: levelData?.chartColor ? levelData?.chartColor : chart?.colorLevel[levelData.value],
                            color: levelData?.chartColor ? levelData?.chartColor : chart?.colorLevel[levelData.value],
                          }}
                          className="circular"
                        />
                        {levelData?.key}
                      </text>
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ChartArea;

