import { Buffer } from 'buffer';
import ActionTypes from "../actionTypes";
const authActionTypes = ActionTypes.AUTH;

const getTokenExpiry = () => {
  const accessToken = localStorage?.getItem("token")
  if (accessToken) {
    let tokenPayload = accessToken.slice(accessToken?.indexOf('.') + 1)
    tokenPayload = JSON.parse(
      Buffer.from(
        tokenPayload.slice(0, tokenPayload.indexOf('.')),
        'base64'
      ).toString('binary')
    )
    return new Date(tokenPayload.exp *1000)
  }
  return new Date()
}

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated:
    localStorage.getItem("token") && localStorage.getItem("user")
      ? true
      : false,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
    sessionExpiry: getTokenExpiry()
};


const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case authActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        user: payload.user,
        sessionExpiry: payload.sessionExpiryDateTime
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        token: '',
        isAuthenticated: false,
        sessionExpiry: new Date(),
        user: {},
      };
    default:
      return state;
  }
};

export default authReducer;