import { useEffect, useRef, useState } from 'react'
import Select, { components } from 'react-select'

export default function SingleSelect({
  selectedOptions = [],
  onSelectHandler,
  options = [],
  setNoDataWarning = false,
  placeholder = 'Select',
  webkitScrollbarWidth= "10px",
  placeholderOnError = 'Select error',
  valueContainerWidthInPx = '300px',
  valueContainerMaxWidthInPx = '300px',
  optionPosition = 'justify-content-start',
  maxMenuHeight = 240,
  minHeight=38,
  customBackgroundColor = '#E7ECF7',
  customFontsize="14px",
  singleValueColor = "#333333",
  valueContainerJustifyContent = "center",
  placeholderColor='hsl(0, 0%, 50%)',
  valueContainerPadding='none',
  valueContainerRadius='none',
  hoverBackgroundColor='#EFF3F8',
  hoverWidth='none',
  hoverMargin='none',
  hoverpadding='none',
  hoverBorderRadius='none',
  IndicatorsContainerBorderright='none',
  IndicatorsContainerBorderbottom='none',
  IndicatorsContainerBorderTop='none',
  IndicatorsContainerBorderRadius='none',
  IndicatorsContainerborderTopRightRadius='none',
  IndicatorsContainerborderBottomRightRadius='none',
  valueContainerBorderleft='none',
  valueContainerBorderbottom='none',
  valueContainerBorderTop='none',
  valueContainerborderTopLeftRadius='none',
  valueContainerborderBottomLeftRadius='none',
  menuMaxheight='140px',
  ...props
}) {
  const baseTestId = props['data-testid'] || 'single-select'
  const myDivRef = useRef()
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)

  function toggleSelectMenuOpenClose(bool) {
    if (bool !== isSelectMenuOpen) {
      setIsSelectMenuOpen(bool)
    }
  }

  // Function to execute when clicking outside the div
  function handleClickOutside(event) {
    if (myDivRef.current && !myDivRef.current.contains(event.target)) {
      setIsSelectMenuOpen(isSelectMenuOpen)
    }
  }

  function handleClick(event) {
    event.stopPropagation();
    toggleSelectMenuOpenClose(true);
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option
          { ...{
              ...props,
              innerProps: {
                ...props.innerProps,
                'data-testid': `${baseTestId}-option-${props.label}`,
                onClick: (d) => {
                  toggleSelectMenuOpenClose(false);
                  props.innerProps.onClick(d);
                }
              }
            }
          }
        >
          <div
            className={`d-flex flex-row align-items-center ${optionPosition} w-100`}
          >
            <label>{props.label}</label>
          </div>
        </components.Option>
      </div>
    )
  }

  const Control = ({ children, ...props }) => {
    return (
      <components.Control { ...{...props, innerProps: { ...props.innerProps, 'data-testid': `${baseTestId}-control` }} }>
        {children}
      </components.Control>
    )    
  }


  return (
    <div
      ref={myDivRef}
      onClick={handleClick}
    >
      <Select
        options={options}
        id="react-sselect"
        closeMenuOnSelect={true}
        placeholder={setNoDataWarning ? placeholderOnError : placeholder}
        value={selectedOptions}
        maxMenuHeight={maxMenuHeight}
        onChange={onSelectHandler}
        menuIsOpen={isSelectMenuOpen}
        styles={{
          valueContainer: (styles) => {
            return {
              ...styles,
              textOverflow: 'ellipsis',
              width: valueContainerWidthInPx,
              maxWidth: valueContainerMaxWidthInPx,
              backgroundColor: customBackgroundColor,
              fontSize:  customFontsize,
              padding:valueContainerPadding,
              borderRadius:valueContainerRadius,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              justifyContent: valueContainerJustifyContent,
              display: 'flex',
              borderTop:valueContainerBorderTop ,
              borderBottom: valueContainerBorderbottom,
              borderRight: 'none',
              borderLeft: valueContainerBorderleft,
              borderTopLeftRadius:valueContainerborderTopLeftRadius,
              borderBottomLeftRadius:valueContainerborderBottomLeftRadius,
              active: {
                backgroundColor: customBackgroundColor,
              },
            }
          },
          // placeholder: (styles, state) => {
          //   return {
          //     ...styles,
          //     color: `${
          //       state.children === placeholder ? 'hsl(0, 0%, 50%)' : 'red'
          //     }`,
          //   }
          // },
          placeholder: (styles, state) => {
            return {
              ...styles,
              color: placeholderColor,
              marginLeft: '8px',

            }
          },
          singleValue: (styles) => {
            return {
              ...styles,
              color: singleValueColor,
              fontWeight: 500,
            }
          },
          control: (styles, state) => {
            return {
              ...styles,
              minHeight: minHeight,
              height: '38px',
              backgroundColor: customBackgroundColor,
              borderColor: customBackgroundColor,
              boxShadow: 'none',
              ':hover': {
                ...styles[':hover'],
                borderColor: customBackgroundColor,
              }
            }
          },
          menuList: (styles) => {
            return {
              ...styles,
              maxHeight: menuMaxheight,
              '::-webkit-scrollbar': {
                width: webkitScrollbarWidth,
              },
            }
          },
          indicatorSeparator: (styles, ) => {
            return {
              ...styles,
              display: 'none'
            }
          },
          indicatorsContainer : (styles) => {
            return {
              ...styles,
              borderTop:IndicatorsContainerBorderTop ,
              borderBottom: IndicatorsContainerBorderbottom,
              borderRight: IndicatorsContainerBorderright,
              borderLeft: 'none',
              borderBottomRightRadius:IndicatorsContainerborderBottomRightRadius,
              borderTopRightRadius: IndicatorsContainerborderTopRightRadius
            }
          },
          dropdownIndicator: (styles, ) => {
            return {
              ...styles,
              padding: '2px'
            }
          },
          menu: (styles) => {
            return {
              ...styles,
              textWrap: 'nowrap',
            }
          },
          option: (styles, { isDisabled, isSelected }) => {
            return {
              ...styles,
              backgroundColor:
                isDisabled && !isSelected ? '#edf0ef' : '#FFFFFF',
              color: '#1f2a5d',
              textAlign: 'center',
              fontFamily: 'inherit',
              fontSize: '14px',
              cursor: isDisabled ? 'not-allowed' : 'default',
              ':active': {
                ...styles[':active'],
                backgroundColor: '#FFFFFF',
              },
              ':hover': {
                ...styles[':hover'],
                // backgroundColor: '#EFF3F8',
                backgroundColor: hoverBackgroundColor,
                borderRadius:hoverBorderRadius,
                margin:hoverMargin,
                width:hoverWidth,
                padding:hoverpadding,
                
        
                

              }
            }
          },

       
        }}
        components={{
          MultiValueContainer: ({ selectProps, data }) => {
            const label =
              selectProps.value.length > 1 ? `${data.label}, ` : data.label
            const val = `${selectProps.inputValue ? '' : label}`
            return val
          },
          Option: Option,
          Control: Control,
        }}
        isSearchable={true}
        hideSelectedOptions={false}
        className={setNoDataWarning ? 'box-shadow-danger' : ''}
      />
    </div>
  )
}
