import store from '../store/store'
import ActionTypes from '../store/actionTypes'
const { START_LOADING, STOP_LOADING } = ActionTypes.LOADER

export const ToggleLoader = ({ setLoading = false }) => {
  if (setLoading) {
    store.dispatch({ type: START_LOADING })
    return
  } else {
    store.dispatch({ type: STOP_LOADING })
    return
  }
}
