import React, { useEffect, useState, useCallback } from 'react';
import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { GetStoppageDurationData } from '../../../../../store/actions/reports/trip';
import { useDispatch, useSelector } from 'react-redux';
import { BiInfoCircle } from 'react-icons/bi';
import { getFormattedDate } from '../../../../../utils/common-methods';
import Pagination from '../../../../helpers/hoc/paginator/Pagination';
import NoRecordFound from '../../../../helpers/hoc/noRecordFound';
import Loader from '../../../../helpers/hoc/loader/Loader';
import { addEscapeKeyListener } from '../../../../../utils/common-methods';

const TripStopageDetailsModal = (
  { showModal, 
    setIsShowModal,
    tripId
  }) => {

  const handleClose = useCallback(() => {
    setCurrentPage(1)
    setIsShowModal(false)
  }, [setIsShowModal]);
    
  const [currentPage, setCurrentPage] = useState(1);

  //redux hooks
  const dispatch = useDispatch()
  const stoppageData = useSelector(s => s?.trip?.stoppageData)
  const stoppageDataCount = useSelector(s => s?.trip?.stoppageDataCount)
  const ITEMS_PER_PAGE = 8;

  const isLoading = useSelector((s) => s?.loader?.isLoading)

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE

const paginatedStoppageData = stoppageData.slice(startIndex, endIndex)

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    	
    if(tripId.length) {      
      dispatch(
        GetStoppageDurationData({
          _id: tripId
        })
      )
    }
  }, [tripId, dispatch])

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      handleClose();
    });

    return () => {
      removeListener();
    };
  }, [handleClose])

  function renderTooltipLog(props) {
    return (
      <Tooltip className="tooltip" id="button-tooltip" {...props}>
        {props.parameter1}: {props.start} 
        {props?.unit ? props.unit : ""} <br />
        {props.parameter2}: {props.end} 
        {props?.unit ? props.unit : ""}
      </Tooltip>
    );
  }

  return (
    <>
      <Modal
        onHide={handleClose}
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
         <Modal.Header closeButton>
         </Modal.Header>
         <Modal.Body>
         <div className="header-container- d-flex justify-content-between">
            <div className="title w-100 mr-2">
              <div className="row">
                <div className="col-6 d-inline-flex align-items-center">
                  <h5 style={{marginTop:'20px'}}>Stoppage Count</h5>
                </div>
              </div>
            </div>
          </div>
         <Table responsive={true} borderless id="oem-trip-report-model" className="oem-trip-report-table-header">
              <thead className="table-header">
                <th className="ps-3 text-nowrap"><p className='fw500'>S.No</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Duration</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Location</p></th>
              </thead>
              {isLoading && <Loader isLoading={isLoading} />} 
              {
        !stoppageDataCount && <NoRecordFound />
      }
              <tbody>

                  {
                    paginatedStoppageData.map((data, index) => {
                      return (
                        <tr>

                        <td key={data?._id} > {(ITEMS_PER_PAGE * (currentPage - 1)) + index + 1} </td>
                         <td>
                          <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center"> 
                          {data?.duration}
                          <OverlayTrigger
                                placement="auto-end"
                                delay={{ show: 0, hide: 0 }}
                                overlay={renderTooltipLog({
                                  parameter1: "Start Time",
                                  parameter2: "End Time",
                                  start: getFormattedDate(data.startDateTime),
                                  end: getFormattedDate(data.endDateTime),
                                })}
                              >
                                <button className="row-btn">
                                  <BiInfoCircle className="row-i-icon-trip-report" />
                                </button>
                              </OverlayTrigger>
                          </div>
                         </td>
                         <td>
                          {data?.city}
                          </td> 
                        </tr>
                      )
                    })

                  }

              </tbody>
          </Table>
         </Modal.Body>
         <Pagination
										disabled={false}
										totalPages={Math.ceil(stoppageDataCount / ITEMS_PER_PAGE)}
										currentPage={currentPage}
										setPage={handlePageChange}
									/>
      </Modal>
    </>
  );
}

export default TripStopageDetailsModal