import React, { useEffect } from "react";
import messageIcon from "../../../../assets/darkThemeAssets/successMessageBubble.svg"
import cx from "classnames";
import styles from "./SuccessFlashMessage.module.css";
import { BsX } from "react-icons/bs";

const SuccessFlashMessage = ({ isOpen, message, closeHandler }) => {
    useEffect(() => {
        if(isOpen) {
            setTimeout(() => {
                closeHandler()
            },2200)
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])
  return (
    isOpen && (
      <div className={cx(styles.mainContainer)}>
        <img src={messageIcon} className={cx(styles.messageBubble)} alt="messageBubble" />
        <div className={cx(styles.messageContainer)}>
          <span className={cx(styles.messageText)}>{message}</span>
          <BsX
            color="white"
            className={cx(styles.closeIcon)}
            onClick={closeHandler}
            size={30}
          />
        </div>
      </div>
    )
  );
};
export default SuccessFlashMessage;
