import React from "react";
import "./PendingSignUpUser.css";
import { Container, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import Pagination from "../../../helpers/hoc/paginator/Pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  GetAllPendingSignUpUsers,
  ResendEmail,
  ApprovalUserByAdmin,
} from "../../../../store/actions/ra_user_management";
import { useEffect } from "react";
import moment from "moment/moment";
import { useState } from "react";
import ApprovalUserModel from "./child-component/ApprovalUserModel/ApprovalUserModel";
import RejectUserModel from "./child-component/RejectUserModel/RejectUserModel";

const PendingSignUpUser = ({
  GetAllPendingSignUpUsers,
  ResendEmail,
  ApprovalUserByAdmin,
  pendingSignUpUsersList,
  pendingSignUpUsersCount,
  activeTab
}) => {
  const ITEMS_PER_PAGE = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearchQuery] = useState();

  useEffect(() => {
    if (activeTab === "pending_sign_up") {
      GetAllPendingSignUpUsers({
        searchKey: search,
      });
    }
  }, [GetAllPendingSignUpUsers, search, activeTab]);

  const handleReSendEmail = (_id) => {
    ResendEmail({
      data: { _id },
    });
  };
  const [isShowApproveUserModal, setIsShowApproveUserModal] = useState(false);
  const [isShowRejectUserModal, setIsShowRejectUserModal] = useState(false);

  const [selectedRejectUserId, setSelectedRejectUserId] = useState(null);
  const toggleRejectModal = (_id) => {
    setIsShowRejectUserModal((prev) => !prev);
    setSelectedRejectUserId(_id);
  };
  const [selectedApproveUserId, setSelectedApproveUserId] = useState(null);
  const toggleApprovalModal = (_id) => {
    setIsShowApproveUserModal((prev) => !prev);
    setSelectedApproveUserId(_id);
  };
  return (
    <Container fluid className="scrollable-table">
      <div className="d-flex col-12 flex-column h-100 mt-2">
        <div className="d-flex justify-content-between align-items-center flex-row">
          <div 
          className={` d-inline-flex align-items-center  ${!search? 'no-value-selected-input ' : ''}`}
          >
            <div className="search-bar-div search-input">
              <FaSearch className="vob-search-icon" />
              <input
                onChange={(e) => {
                  setCurrentPage(1)
                  setSearchQuery(e.target.value);
                }}
                className="vob-search-input search-input"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div className="bg-white mb-1 position-relative">
          <Table responsive={true} borderless className="mb-0">
            <thead>
              <tr className="th-border-bottom">
                <th className="ps-3 text-nowrap">
                  <span className="fw500">S.No</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">User Name</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Email ID</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Location</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">User Role</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Ra Name</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Registration Date</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              { pendingSignUpUsersList && pendingSignUpUsersList?.slice(
                  (currentPage - 1) * ITEMS_PER_PAGE,
                  currentPage * ITEMS_PER_PAGE
                 )?.map((Obj, index) => (
                <tr key={"row-" + index.toString()}>
                  <td>
                  {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}.
                  </td>
                  <td>
                    {Obj?.firstName} {Obj?.lastName}{" "}
                  </td>
                  <td> {Obj?.email}</td>
                  <td> {Obj?.location.join(", ")}</td>
                  <td> {Obj?.roleName}</td>
                  <td>{Obj?.populated_user_data?.raName} </td>
                  <td> {moment(Obj?.createdAt).format("DD/MM/YYYY")} </td>
                  <td className="ps-3 text-nowrap">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <button
                        className="resend-btn"
                        onClick={() => handleReSendEmail(Obj?._id)}
                      >
                        Resend approval link
                      </button>
                      <button
                        className="resend-btn"
                        onClick={() => toggleApprovalModal(Obj?._id)}
                      >
                        Approve
                      </button>
                      <button
                        className="resend-btn"
                        onClick={() => toggleRejectModal(Obj?._id)}
                      >
                        Reject
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            disabled={false}
            totalPages={Math.ceil((pendingSignUpUsersCount || 0) / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setCurrentPage}
          />
          <div></div>
        </div>
        {isShowApproveUserModal && (
          <ApprovalUserModel
            showModal={isShowApproveUserModal}
            setIsShowModal={setIsShowApproveUserModal}
            _id={selectedApproveUserId}
            data={pendingSignUpUsersList}
          />
        )}
        {isShowRejectUserModal && (
          <RejectUserModel
            showModal={isShowRejectUserModal}
            setIsShowModal={setIsShowRejectUserModal}
            _id={selectedRejectUserId}
            data={pendingSignUpUsersList}
          />
        )}
      </div>
    </Container>
  );
};

PendingSignUpUser.propTypes = {
  // Action props
  GetAllPendingSignUpUsers: PropTypes.func.isRequired,
  pendingSignUpUsersList: PropTypes.array.isRequired,
  pendingSignUpUsersCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  pendingSignUpUsersList: state.user_management.pendingSignUpUsersList,
  pendingSignUpUsersCount: state.user_management.pendingSignUpUsersCount,
});
export default connect(mapStateToProps, {
  GetAllPendingSignUpUsers,
  ResendEmail,
  ApprovalUserByAdmin,
})(PendingSignUpUser);
