import ActionTypes from "../actionTypes";
const {
    GET_ALL_USERS_LIST,
    GET_ALL_USERS_LIST_EXCEPTION,
    GET_USER_BY_ID,
    GET_USER_BY_ID_EXCEPTION,
    CREATE_USER,
    CREATE_USER_EXCEPTION,
    EDIT_USER,
    EDIT_USER_EXCEPTION,
    BULK_USER_UPLOAD,
    BULK_USER_EXCEPTION,
    DELETE_USER,
    DELETE_USER_EXCEPTION,
    GET_ALL_PENDIG_SIGNUP_USER_LIST,
    GET_ALL_PENDIG_SIGNUP_USER_LIST_EXCEPTION,
    RESEND_EMAIL,
    RESEND_EMAIL_EXCEPTION,
    APPROVAL_USER_BY_ADMIN,
    APPROVAL_USER_BY_ADMIN_EXCEPTION
 } = ActionTypes.USERS

 const initialState = {
    usersList: [],
    pendingSignUpUsersList: [],
    pendingSignUpUsersCount: 0,
    userCreated: false,
    userDetails:{},
    updateSuccessful: false,
    deleteSuccessful: false,
    resendSuccessful: false,
    approvalUserByAdminSuccessful: false,
    approvalUserByAdminError: '',
    uploadUsersError : '',
    uploadSuccess: false
}

const user_management = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_ALL_USERS_LIST:
            return {
                ...state,
                usersList: payload
            }
        case GET_ALL_USERS_LIST_EXCEPTION:
            return {
                ...state,
                usersList: []
            }
            case GET_ALL_PENDIG_SIGNUP_USER_LIST:
                return {
                    ...state,
                    pendingSignUpUsersList: payload.rows,
                    pendingSignUpUsersCount: payload.count
                }
            case GET_ALL_PENDIG_SIGNUP_USER_LIST_EXCEPTION:
                return {
                    ...state,
                    pendingSignUpUsersList: [],
                    pendingSignUpUsersCount:0
                }
        case GET_USER_BY_ID:
            return {
                ...state,
                userDetails: payload
            }
        case GET_USER_BY_ID_EXCEPTION:
            return {
                ...state,
                userDetails: {}
            }
        case CREATE_USER:
            return {
                ...state,
                userCreated: true
            }
        case CREATE_USER_EXCEPTION:
            return {
                ...state,
                userCreated: false
            }
        case EDIT_USER:
            return {
                ...state,
                updateSuccessful: true
            }
        case EDIT_USER_EXCEPTION:
            return {
                ...state,
                updateSuccessful: false
            }
        case DELETE_USER:
            return {
                    ...state,
                    deleteSuccessful: true
                }
        case DELETE_USER_EXCEPTION:
            return {
                    ...state,
                    deleteSuccessful: false
                }
                case RESEND_EMAIL:
            return {
                    ...state,
                    resendSuccessful: true
                }
        case RESEND_EMAIL_EXCEPTION:
            return {
                    ...state,
                    resendSuccessful: false
                }
        case BULK_USER_UPLOAD:
            return {
                ...state,
                uploadSuccess: payload
            }
        
        case BULK_USER_EXCEPTION:
            return {
                ...state,
                uploadUsersError: payload
            }
            case APPROVAL_USER_BY_ADMIN:
                return {
                    ...state,
                    approvalUserByAdminSuccessful: payload
                }
            
            case APPROVAL_USER_BY_ADMIN_EXCEPTION:
                return {
                    ...state,
                    approvalUserByAdminError: payload
                }
    
        default:
            return state
    }
}

export default user_management