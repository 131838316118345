import ActionTypes from '../actionTypes'
const { FILE_UPLOAD_PROGRESS } = ActionTypes.FILE

const initialState = {
  uploadProgress: 0,
}

const fileUploadReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: payload,
      }
    default:
      return state
  }
}

export default fileUploadReducer
