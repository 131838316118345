import React from 'react';
import { Container } from 'react-bootstrap';
import AlarmAnayticsGUI from '../../Analytics/AlarmAnalytics/AlarmAnayticsGUI';

const Status = () => {
	return (
		<>
			<div className='col-12 bg-white'>
				<div className='bg-white mb-1 position-relative tabbed-content'>
					
					<div className='col-12'>
						<Container fluid>
							<div className='charging-tab-container'>
								<AlarmAnayticsGUI/>
							</div>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default Status