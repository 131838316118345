import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import "./imageUploadModel.css";
import ImageUploadProgressBar from "../imageUploadProgressBar/imageUploadProgressBar";
import ImageDrawer from "../imageDrawer/imageDrawer";
import { ImageUpload } from "../../../../../store/actions/geo-fence/dashboardGeoFence";
import { useCallback } from "react";
import Loader from "../../../../helpers/hoc/loader/Loader";
import PropTypes from "prop-types";

function GeofenceImageUploadModel({
  fenceId,
  showMoal,
  setIsShowModal,
  ImageUpload,
  isLoading,
}) {
  const handleClose = useCallback(
    () => setIsShowModal(false),
    [setIsShowModal]
  );
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const onSaveHandler = useCallback(() => {
    if (file) {
      if (file.size <= 1 * 1024 * 1024) {
        let formData = new FormData();
        formData.append("file", file);
        ImageUpload({
          data: formData,
          _id: fenceId,
        }).catch((error) => {
          setErrorMessage(error.response.data.message); // Assuming you're using axios or similar for API calls
        });
        handleClose();
      } else {
        // File size exceeds 2 MB, show an error message
        setErrorMessage(
          "File size exceeds 1 MB. Please upload a smaller file."
        );
      }
    }
  }, [ImageUpload, fenceId, file, handleClose]);
  return (
    <>
      <Modal
        show={showMoal}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Upload photos</Modal.Title>
          <div className="d-flex justify-content-center">
            <ImageDrawer setFile={(file) => { setFile(file); setErrorMessage(null); }} data-testid='image-upload' />
          </div>
          {file && <ImageUploadProgressBar setFile={setFile} file={file} data-testid='image-upload' />}
          {errorMessage && <div className="error-message-geofence-image-upload ml-5">{errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-start align-items-center flex-row  ">
            <button
              onClick={() => {
                onSaveHandler();
              }}
              data-testid='image-upload-submit-button'
              type="submit"
              className="modal-btn save-btn-geofence "
            >
              Save
            </button>
            <Loader isLoading={isLoading} />
            <button
              onClick={() => {
                handleClose();
              }}
              className="modal-btn cancel-btn-geofence"
              data-testid='image-upload-cancel-button'
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
GeofenceImageUploadModel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.loader.isLoading,
});
export default connect(mapStateToProps, {
  ImageUpload,
})(GeofenceImageUploadModel);
