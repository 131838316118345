import { useSelector } from "react-redux"
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Label,
  } from "recharts";
  import { BsCircle} from 'react-icons/bs'
  

//operations trend chart for analytics
const OperationsTrendChart = ()=> {
    const operationsTrendData = useSelector((s)=> s?.analytics.operationsTrendData)
    const ingressSelected = useSelector((s)=> s?.analytics.ingressSelected)
    const egressSelected = useSelector((s)=> s?.analytics.egressSelected)
    const customData = [
      { formattedDateTime: '10', value: 10 },
      { formattedDateTime: '10', value: 20 },
      // Add more custom data points as needed
    ];

    const CustomXAxisLabel = ({ x, y, payload }) => {
        const lines = payload.value.split(' ').reverse();

        return (
          <g transform={`translate(${x},${y})`}>
            <text
                x={10}
                y={8}
                dy={'0'} // Adjust vertical spacing between lines
                textAnchor="end"
                fill="#666"
                key={0}
              >
                {lines[0]}
              </text>
              <text
                x={25}
                y={8}
                dy={'1.4em'} // Adjust vertical spacing between lines
                textAnchor="end"
                fill="#666"
                key={1}
              >
                {lines[1]}
              </text>
          </g>
        );
      };
      
      //custom tooltip for charts 
      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload?.length) {
          return (
            <div className="custom-tooltip" style={{background: '#00000035',borderRadius: '8px', padding: '3px 6px 3px 3px'}}>
              <p className="label" style={{margin: '2px 0',textAlign: 'center'}}> {`${payload[0].payload.formattedDateTime}`}</p>
              {
                payload.map((val)=> {
                  return (
                      <p className="label" style={{margin: '2px 0'}}> <BsCircle className={`circle-${val.name === 'ingressCount' ? 'ingress' : 'egress'}`} />{`${val.name === 'ingressCount' ? 'Ingress Count' : 'Egress Count'} : ${ val.payload[val.name]}`}</p>
                  )
                })
              }
            </div>
          );
        }
      
        return null;
      };

    return (
        <ResponsiveContainer>
            <LineChart
            data={operationsTrendData?.length ? operationsTrendData : customData}
            height="45%"
            width={50}
            margin={{
                top: 20,
                right: 300,
                left: 20,
                bottom: 5,
            }}
            >
            <XAxis
                dy={100}
                height={50}
                width={'100%'}
                tickCount={20}
                dataKey="formattedDateTime"
                label={{
                value: "Time",
                position: "insideBottom",
                offset: -5,
                fill: "#1F2A5D",
                style: { fontFamily: 'Inter', fontSize: '14px', fontWeight: 'medium', lineHeight: '25px' }
                }}
                color="#000"
                tick={<CustomXAxisLabel />}
            />
            <Tooltip
             wrapperStyle={{ color: '#ffff'}}
             content={<CustomTooltip />}
            />
            <YAxis>
                <Label fill= "#1F2A5D"   style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 'medium', lineHeight: '25px' }} value="Count" angle={-90} position={{ x: 18, y: 170 }} />
            </YAxis>
            
            { ingressSelected &&
              <Line type="monotone" dataKey="ingressCount" dot={false} stroke="#1F2A5D" />
            }
            
            { egressSelected &&
              <Line type="monotone" dataKey="egressCount" dot={false} stroke="#D17FAE" />
            }

            {
              !ingressSelected && !egressSelected && (
                <>
                <Line type="monotone" dataKey="ingressCount" dot={false} stroke="#1F2A5D" />
                <Line type="monotone" dataKey="egressCount" dot={false} stroke="#D17FAE" />
                </>
              )
            }
            
            </LineChart>
        </ResponsiveContainer>
    )
}

export default OperationsTrendChart