import React from 'react';

import { getFormattedOnlyDate } from '../../../../../utils/common-methods';

const GPSubgroupTableRow = (props) => {
    const { subgroupData, subgroupIndex, expandedMenuId, onMenuExpansionClick, onVehicleCountClick, onRenameClick, onDeleteClick, onEditClick } = props;

    return (
        <>
          <tr style={{height: "10px"}}></tr>
        <tr className='sub__groups__view__table'>
            <td className={`subgroup-line-col subgroup-line-col-${subgroupIndex}`}>
                <p className='fw500 groups__row__td'></p>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td ' style={{color: '#0000EE', textDecoration: 'underline', cursor:"pointer"}}
                onClick={onEditClick}
                >
                    {subgroupData.name}
                </p>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td cursor-pointer' style={{ marginTop: 5 }}
                    onClick={onVehicleCountClick}
                >
                    <span style={{ color: '#22046B', fontSize: '18px' }}>
                        <img src='/images/svgicon/groupsIcon/vehicalGroups.svg' alt='info' />{' '}
                    </span>
                    {subgroupData.count}
                </p>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td'>{getFormattedOnlyDate(subgroupData.createdDate)}</p>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td'>{getFormattedOnlyDate(subgroupData.modifiedDate)}</p>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td'></p>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td'></p>
            </td>
            <td className='ps-3 text-nowrap '>
                <div style={{ position: 'relative' }}>
                    <p className='fw500 groups__row__td'
                        onClick={onMenuExpansionClick}
                        data-testid={`subgroup-action-menu-${subgroupData.name}`}
                    >
                        <span
                            className='groups__view__threedots cursor-pointer'
                        >
                            <img
                                src='/images/svgicon/groupsIcon/verticalThreedots.svg'
                                alt='info'
                                style={{ width: '20px', height: '20px' }}
                                id={'spanThreeDots' + subgroupData?.name}
                            />
                        </span>
                    </p>

                    {expandedMenuId === subgroupData._id && (
                        <div className='popupGroupsData'>
                            <ul className='popupSubGroups_ul'>
                            <li className='popupSubGroups_li' onClick={onRenameClick} data-testid={`subgroup-action-rename-button-${subgroupData.name}`}>Rename</li>
                                <li onClick={onEditClick} data-testid={`subgroup-action-edit-button-${subgroupData.name}`}>Edit</li>
                                <li onClick={onDeleteClick} data-testid={`subgroup-action-delete-button-${subgroupData.name}`}>Delete</li>
                            </ul>
                        </div>
                    )}
                </div>
            </td>
            <td className='ps-3 text-nowrap'>
                <p className='fw500 groups__row__td'></p>
            </td>
        </tr>
        <tr style={{height: "10px"}}></tr>
        </>
    )
}

export default GPSubgroupTableRow;