import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { BiInfoCircle } from 'react-icons/bi'
import { BsDownload } from 'react-icons/bs'
import { connect } from 'react-redux'
import {
    ExportAlarmsReport, getAllAlarms
} from '../../../../../store/actions/reports/alarm'
import { GetSubsRnNums } from '../../../../../store/actions/vehicle/vehicle'
import { getFormattedDate } from '../../../../../utils/common-methods'
import MapAdddressByLocation from "../../../../helpers/hoc/latlong-to-address-map/MapAddressByLocation"
import Loader from '../../../../helpers/hoc/loader/Loader'
import NoRecordFound from '../../../../helpers/hoc/noRecordFound'
import Pagination from '../../../../helpers/hoc/paginator/Pagination'
import './NDVehicleInfoAlarmReport.css'




const ITEMS_PER_PAGE = 8

const NDVehicleInfoAlarmReport = ({
    //actions
    getAllAlarms,
    ExportAlarmsReport,

    //states
    allAlarmsData,
    dataCount,
    isLoading,
    vehicleNumber,
    alarmReportDate,
    selectedAlarmData,
    selectedAlarmLabel,
    closeAlertAlarmaAnalyticsModal
}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [showPopup, setShowPopup] = useState(false);
    const [coordinate, setCoordinate] = useState();
    const registrationNumbers = [{ label: vehicleNumber, value: vehicleNumber }]

    useEffect(() => {
        getAllAlarms({
            registrationNumbers,
            fromDate: alarmReportDate.fromDate,
            toDate: alarmReportDate.toDate,
            pageIndex: currentPage - 1,
            pageSize: ITEMS_PER_PAGE,
            alarmType: selectedAlarmData,
        })
    }, [vehicleNumber, currentPage]) // eslint-disable-line

    function renderTooltipForSingleParameter(props) {
        return (
            <Tooltip className="tooltip vd-alert-tooltip" id="button-tooltip" {...props}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                    {props.parameter1}: {props.start}
                </div>
            </Tooltip>
        )
    }

    function exportToExcel() {
        ExportAlarmsReport({
            registrationNumbers,
            fromDate: alarmReportDate.fromDate,
            toDate: alarmReportDate.toDate,
            alarmType: selectedAlarmData
        })
        // closeAlertAlarmaAnalyticsModal()
    }

    const handleMapCoOrdinateClick = (event) => {
        setShowPopup(true);
        setCoordinate(event);
    };

    const togglePopup = () => {
        setShowPopup((prevState) => !prevState);
    };  

    return (
        <>
            {
                allAlarmsData?.length ? (

                    <div className="header-container d-flex justify-content-between">
                    <div className="title w-100 mr-2">
                      <div className="row" style={{ height: "116px" }}>
                        <div className="col-4 d-inline-flex align-items-center">
                          <h5 style={{marginTop:'-5px', marginLeft:'25px'}}>{selectedAlarmLabel}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="download-btn">
                    <button
          className="dwn-btn al__dwn-btn"
          style={{position:'absolute',    top: '23px',
          right:' 34px'}}
          onClick={() => {
            exportToExcel()
          }}
        >
          <BsDownload style={{width:'22px',height:'22px'}} />
        </button>
                    </div>
                  </div>

                ) : null
            }
            <Container fluid>
                {allAlarmsData?.length ? (
                    <div className="bg-white mb-2 position-relative">
                        <Table responsive={true} borderless className="mb-0" id='vd__vh__table'>
                            <thead>
                                <tr className="th-border-bottom">
                                    <th className="ps-2 text-nowrap"><span className='fw500'>S. No</span></th>
                                    <th className="ps-2 text-nowrap"><span className='fw500'>Vehicle No.</span></th>
                                    <th className="ps-2 text-nowrap"><span className='fw500'>OEM & Model</span></th>
                                    <th className="ps-2 text-nowrap"><span className='fw500'>Date & Time</span></th>
                                    <th className="ps-2 text-nowrap"><span className='fw500'>Alert Type</span></th>
                                    <th className="ps-2 text-nowrap"><span className='fw500'>Alert Value</span></th>
                                    <th className="ps-2 text-nowrap"><span className='fw500'>GPS</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {allAlarmsData?.map((n, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {index +
                                                    1 +
                                                    ((currentPage - 1) * 10 +
                                                        (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                                                    '.'}
                                            </td>
                                            <td>{n?.registration_number}</td>
                                            <td>{n?.oem} {n?.model}</td>
                                            <td>
                                                {n?.data_timestamp
                                                    ? getFormattedDate(n.data_timestamp)
                                                    : n?.data_timestamp}
                                            </td>
                                            <td>{n.alarmType === 'OverSpeed' ? 'Overspeed' : n.alarmType}</td>



                                            <td>
                                                <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                                                    {(n.alarmValue &&  n.alarmValue !== "NA" ? Math.round(n.alarmValue) : (n.alarmValue !== "NA") ? 0 : n.alarmValue) + '' + n.alarmValueUnit}

                                                    <OverlayTrigger
                                                        placement="left"
                                                        delay={{ show: 0, hide: 0 }}
                                                        overlay={renderTooltipForSingleParameter({
                                                            parameter1: 'Threshold Value',
                                                            start: n?.thresholdValue + n.alarmValueUnit,
                                                        })}
                                                    >
                                                        <button className="row-btn">
                                                            <BiInfoCircle className="row-i-icon" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                                                    <img
                                                        src="/images/svgicon/location.svg"
                                                        alt="location"
                                                        style={{
                                                            width: 18,
                                                            cursor:"pointer"
                                                        }}
                                                        onClick={(event)=>handleMapCoOrdinateClick({
                                                            lat : parseFloat(n?.gps?.split(',')[0]),
                                                            lng : parseFloat(n?.gps?.split(',')[1])
                                                            })}
                                                    ></img>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Pagination
                            disabled={false}
                            totalPages={Math.ceil((dataCount || 0) / ITEMS_PER_PAGE)}
                            currentPage={currentPage}
                            setPage={setCurrentPage}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {!allAlarmsData?.length && !isLoading ? (
                    <NoRecordFound></NoRecordFound>
                ) : (
                    <></>
                )}
            </Container>
            <Loader isLoading={isLoading} />
            {
                showPopup
                &&
                <div id="popup1" className={`popup ${showPopup ? "open" : ""}`}>
                    <div className="popup-overlay" onClick={togglePopup}></div>
                    <div className="popup-content">
                        <MapAdddressByLocation isOpen={showPopup} toggleModal={togglePopup} CoOrdinates={coordinate} />
                    </div>
                </div>
            }
        </>
    )
}
NDVehicleInfoAlarmReport.propTypes = {
    allAlarmsData: PropTypes.array.isRequired,
    vehicleNumber: PropTypes.string.isRequired,
    subcribedRnNums: PropTypes.array.isRequired,
    dataCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedAlarmData: PropTypes.string.isRequired,
    selectedAlarmLabel: PropTypes.string.isRequired,

    // Action props
    getAllAlarms: PropTypes.func.isRequired,
    GetSubsRnNums: PropTypes.func.isRequired,
    ExportAlarmsReport: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    allAlarmsData: state.alarm.allAlarmsData,
    subcribedRnNums: state.vehicle.subcribedRnNums,
    dataCount: state.alarm.dataCount,
    isLoading: state.loader.isLoading,
})
export default connect(mapStateToProps, {
    getAllAlarms,
    GetSubsRnNums,
    ExportAlarmsReport,
})(NDVehicleInfoAlarmReport)
