import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ExportHistoricData, GetHistoricData
} from '../../../store/actions/reports/historic-data';
import { GetSubsRnNums } from '../../../store/actions/vehicle/vehicle.js';
import { getFormattedDate } from "../../../utils/common-methods";
import MapAdddressByLocation from "../../helpers/hoc/latlong-to-address-map/MapAddressByLocation";
import Loader from "../../helpers/hoc/loader/Loader";
import MultiSelect from "../../helpers/hoc/multi-select/MultiSelect";
import NoRecordFound from "../../helpers/hoc/noRecordFound";
import Pagination from "../../helpers/hoc/paginator/Pagination";
import "./css/ReportHistoricData.css";
import Filters from '../new-report/report-charging/child-components/Filters';


const HistoricRecord = ({
  //actions
  GetHistoricData,
  GetSubsRnNums,
  ExportHistoricData,

  //states
  subcribedRnNums,
  allHistoricData,
  dataCount,
  isLoading,
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [intervalDuration, setIntervalDuration] = useState()
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDataPoints, setSelectedDataPoints] = useState([]);
  const [isRnFilterWarnEnabled, setIsRnFilterWarnEnabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDtFilterWarnEnabled, setIsDtFilterWarnEnabled] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [coordinate, setCoordinate] = useState();
  const ITEMS_PER_PAGE = 8;

  const location = useLocation();

  const dataPoints = [
    {
      label: "GPS",
      value: "gps",
    },
    {
      label: "State of Charge",
      value: "soc",
    },
    {
      label: "Odometer",
      value: "odometer",
    },
    {
      label: "DTE",
      value: "dte",
    },
    {
      label: "Speed",
      value: "speed",
    },
    {
      label: "Battery Voltage",
      value: "vbv",
    },
    {
      label: "Battery Temp.",
      value: "vbt",
    },
    {
      label: "Sync Date Time",
      value: "createdAt",
    },
    // {
    //   label: 'Ignition Status',
    //   value: 'ig',
    // },
  ];

  useEffect(() => {
    if (subcribedRnNums.length === 0) {
      GetSubsRnNums();
    }

    if (subcribedRnNums && subcribedRnNums.length > 0) {
      const queryParams = new URLSearchParams(location.search);
      let selectedVehicle = subcribedRnNums.filter(
        (x) => x.label === queryParams.get("vehicleNumber")
      );
      setSelectedOptions(selectedVehicle);
    }
  }, [GetSubsRnNums, subcribedRnNums, location.search]);

  useEffect(() => {
    if (dataCount > 0) {
      GetHistoricData({
        registrationNumbers: selectedOptions,
        fromDate: startDate,
        toDate: endDate,
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        intervalDuration: intervalDuration
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const calculateIntervalDuration = (fromDate, toDate) => {
    const from = new Date(fromDate)
    const to = new Date(toDate)
    const diff = Math.abs(to - from)
    const minutes = Math.floor((diff / 1000) / 60)

    //for less than 1 day return 1 hr
    if (minutes < 1440) return 120
    //for less than 3 days return 4 hrs
    else if (minutes < 4320) return 240
    //for less than 7 days return 12 hrs
    else if (minutes < 10080) return 720
    //for less than 16 days return 1 day
    else if (minutes < 23040) return 1440
    //for greater than 16 days return 2 day
    return 2880
  }

  function onDateChangeHandler({ startDate, endDate }) {
    allHistoricData.length = 0;
    setStartDate(startDate)
    setEndDate(endDate)
    setCurrentPage(1)
    setIntervalDuration(calculateIntervalDuration(startDate, endDate))
    if (selectedOptions && selectedOptions.length > 0) {
      GetHistoricData({
        registrationNumbers: selectedOptions,
        fromDate: startDate,
        toDate: endDate,
        pageIndex: 0,
        pageSize: ITEMS_PER_PAGE,
        intervalDuration: intervalDuration
      });
    }
  }

  function onSelectionChange() {
    allHistoricData.length = 0;
  }

  const onSubmitHandler = () => {
    if (!selectedOptions?.length && !endDate && !startDate) {
      setIsRnFilterWarnEnabled(true);
      setIsDtFilterWarnEnabled(true);
      setTimeout(() => {
        setIsRnFilterWarnEnabled(false);
        setIsDtFilterWarnEnabled(false);
      }, 2000);
      return;
    } else if (!selectedOptions?.length) {
      setIsRnFilterWarnEnabled(true);
      setTimeout(() => {
        setIsRnFilterWarnEnabled(false);
      }, 2000);
      return;
    } else if (!(endDate && startDate)) {
      setIsDtFilterWarnEnabled(true);
      setTimeout(() => {
        setIsDtFilterWarnEnabled(false);
      }, 2000);
      return;
    }
    setCurrentPage(1);
    GetHistoricData({
      registrationNumbers: selectedOptions,
      fromDate: startDate,
      toDate: endDate,
      pageIndex: 0,
      pageSize: ITEMS_PER_PAGE,
      intervalDuration: intervalDuration
    });
    setTimeout(() => {
      setIsSubmitClicked(true);
    }, 1000);
  };

  function exportToExcel() {
    ExportHistoricData({
      registrationNumbers: selectedOptions,
      fromDate: startDate,
      toDate: endDate,
    });
  }

  const handleMapCoOrdinateClick = (event) => {
    setShowPopup(true);
    setCoordinate(event);
  };

  const togglePopup = () => {
    setShowPopup((prevState) => !prevState);
  };
  return (
    <>
      <Container fluid>
        <div className="col-12 selectLayout-historical-data">
          <div className={`multi-select-width ${!selectedOptions[0] ? 'no-value-selected' : ''}`}>
            <MultiSelect
              setNoDataWarning={isRnFilterWarnEnabled}
              options={subcribedRnNums}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              onChange={onSelectionChange}
              placeholderColor='#C6C6C6'
              customBackgroundColor={{ backgroundColor: selectedOptions[0] ? "#FFFFFF" : "red" }}
              data-testid='vehicle-filter'
            />
          </div>


          <div className={`data-points-multi-select-width-historical-data ${!selectedDataPoints[0] ? 'no-value-selected-historic' : ''}`}>
            <MultiSelect
              placeholder="Select Data Points"
              placeholderOnError=""
              valueContainerWidthInPx="200px"
              valueContainerMaxWidthInPx="200px"
              menuListPaddingX="px-2"
              okButtonPaddingEnd="pe-2"
              optionPosition="ps-2 justify-content-start"
              placeholderColor='#C6C6C6'
              valueContainerColor="#797694"
              options={dataPoints}
              isMultiSelect={true}
              selectedOptions={selectedDataPoints}
              setSelectedOptions={(dataPoints) => {
                setSelectedDataPoints(dataPoints);
                allHistoricData.length = 0;
              }}
              onChange={onSelectionChange}
              customBackgroundColor={{ backgroundColor: selectedDataPoints[0] ? "#FFFFFF" : "red" }}
              data-testid='data-points-filter'
            />
          </div>

          <div>
            <Button
              data-testid='get-report-button'
              className="bttn height"
              id="getReport"
              type="submit"
              onClick={onSubmitHandler}
              disabled={
                !selectedOptions?.length ||
                !endDate ||
                !startDate ||
                !selectedDataPoints?.length
              }
            >
              Get Report
            </Button>
          </div>

          <div>
            <button
              data-testid='export-button'
              disabled={
                !allHistoricData?.length ||
                !selectedOptions?.length ||
                !endDate ||
                !startDate
              }
              className={
                !allHistoricData?.length ||
                  !selectedOptions?.length ||
                  !endDate ||
                  !startDate
                  ? "dwn-btn dwn-btn-disabled"
                  : "dwn-btn"
              }
              onClick={() => {
                exportToExcel();
              }}
            >
              <BsDownload style={{width:'22px',height:'22px'}} />
            </button>
          </div>
          <div className='col-4 date__picker'>
            <Filters onDateChangeHandler={onDateChangeHandler} data-testid='historic-date-filters'></Filters>
          </div>
        </div>

        {allHistoricData?.length ? (
          <div className="bg-white mb-2 mt-4 position-relative trip__log__table">
            <Table
              responsive={true}
              borderless
              className="mb-0"
              id="history-table-historical-data"
            >
              <thead>
                <tr className="th-border-bottom position-relative">
                  <th className="ps-2 text-nowrap">
                    <p className="fw500">S.No</p>
                  </th>
                  <th className="ps-2 text-nowrap">
                    <p className="fw500">Vehicle No.</p>
                  </th>
                  <th className="ps-2 text-nowrap">
                    <p className="fw500">OEM & Model</p>
                  </th>
                  <th className="ps-2 text-nowrap">
                    <p className="fw500">
                      CAN {selectedDataPoints?.length ? <br /> : ""} Date & Time
                    </p>
                  </th>
                  <th className="ps-2 text-nowrap">
                    <p className="fw500">
                      Vehicle {selectedDataPoints?.length ? <br /> : ""} Status
                    </p>
                  </th>
                  {selectedDataPoints.some((r) =>
                    ["gps"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <p className="fw500">GPS</p>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["soc"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <p className="fw500">State of Charge</p>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["odometer"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <p className="fw500">Odometer</p>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["dte"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <span className="fw500">
                          Distance <br /> Till Empty
                        </span>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["speed"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <p className="fw500">Speed</p>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["vbv"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <span className="fw500">
                          Battery <br />
                          Voltage
                        </span>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["vbt"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <span className="fw500">
                          Battery <br />
                          Temperature
                        </span>
                      </th>
                    )}

                  {selectedDataPoints.some((r) =>
                    ["createdAt"].includes(r.value)
                  ) && (
                      <th className="ps-2 text-nowrap">
                        <span className="fw500">
                          Sync <br /> Date & Time
                        </span>
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                {allHistoricData?.map((n, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {index +
                          1 +
                          ((currentPage - 1) * 10 +
                            (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                          "."}
                      </td>
                      <td>{n?.registration_number}</td>
                      <td>
                        {n?.oem} {n?.model}
                      </td>
                      <td>
                        {n?.data_timestamp
                          ? getFormattedDate(n.data_timestamp)
                          : n?.data_timestamp}
                      </td>
                      {/* Note: Merge Idle status to Live status */}
                      <td>{n.vehicle_status === 'Idle' ? 'Live' : n.vehicle_status}</td>
                      {selectedDataPoints.some((r) =>
                        ["gps"].includes(r.value)
                      ) && (
                          <td>
                            <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                              <img
                                src="/images/svgicon/location.svg"
                                alt="location"
                                style={{
                                  width: 18,
                                  cursor: "pointer"
                                }}
                                onClick={(event) => handleMapCoOrdinateClick({
                                  lat: parseFloat(n?.gps?.split(',')[0]),
                                  lng: parseFloat(n?.gps?.split(',')[1])
                                })}
                                data-testid={`gps-button-${index}`}
                              ></img>
                            </div>
                          </td>
                        )}
                      {selectedDataPoints.some((r) =>
                        ["soc"].includes(r.value)
                      ) && <td>{(n.soc ? Math.round(n.soc) : 0) + "%"}</td>}

                      {selectedDataPoints.some((r) =>
                        ["odometer"].includes(r.value)
                      ) && (
                          <td>
                            {(n.odometer ? Math.round(n.odometer) : 0) + " kms"}
                          </td>
                        )}

                      {selectedDataPoints.some((r) =>
                        ["dte"].includes(r.value)
                      ) && <td>{(n.dte ? Math.round(n.dte) : 0) + " kms"}</td>}

                      {selectedDataPoints.some((r) =>
                        ["speed"].includes(r.value)
                      ) && (
                          <td>{(n.speed ? Math.round(n.speed) : 0) + " kmph"}</td>
                        )}

                      {selectedDataPoints.some((r) =>
                        ["vbv"].includes(r.value)
                      ) && <td>{(n.vbv ? Math.round(n.vbv) : 0) + " V"}</td>}

                      {selectedDataPoints.some((r) =>
                        ["vbt"].includes(r.value)
                      ) && <td>{(n.vbt ? n.vbt : 0) + " °c"}</td>}

                      {selectedDataPoints.some((r) =>
                        ["createdAt"].includes(r.value)
                      ) && (
                          <td>
                            {n?.createdAt ? getFormattedDate(n.createdAt) : "-"}
                          </td>
                        )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              disabled={false}
              totalPages={Math.ceil((dataCount || 0) / ITEMS_PER_PAGE)}
              currentPage={currentPage}
              setPage={setCurrentPage}
              data-testid='historic-pagination'
            />
          </div>
        ) : (
          <></>
        )}
        {!allHistoricData?.length && isSubmitClicked && !isLoading ? (
          <NoRecordFound></NoRecordFound>
        ) : (
          <></>
        )}
      </Container>
      <Loader isLoading={isLoading} />
      {
        showPopup
        &&
        <div id="popup1" className={`popup ${showPopup ? "open" : ""}`}>
          <div className="popup-overlay" data-testid='gps-dialog-toggle' onClick={togglePopup}></div>
          <div className="popup-content">
            <MapAdddressByLocation isOpen={showPopup} toggleModal={togglePopup} CoOrdinates={coordinate} data-testid='historic-gps-dialog' />
          </div>
        </div>
      }
    </>
  );
};
HistoricRecord.propTypes = {
  allHistoricData: PropTypes.array.isRequired,
  subcribedRnNums: PropTypes.array.isRequired,
  dataCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Action props
  GetHistoricData: PropTypes.func.isRequired,
  GetSubsRnNums: PropTypes.func.isRequired,
  ExportHistoricData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allHistoricData: state.historic.allHistoricData,
  subcribedRnNums: state.vehicle.subcribedRnNums,
  dataCount: state.historic.dataCount,
  isLoading: state.loader.isLoading,
});
export default connect(mapStateToProps, {
  GetHistoricData,
  GetSubsRnNums,
  ExportHistoricData,
})(HistoricRecord);