import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Tabs, Tab} from 'react-bootstrap'
import "./geoFenceConfigurationScreen.css"
import AddLabel from "./parameters/AddLabel";
import AddLabelOwner from "./parameters/AddLabelOwner";
import AddFenceOwner from "./parameters/AddFenceOwner";
import GeoFenceAlertConfigurationTab from "./alerts/GeoFenceAlertConfigurationTab";
import Loader from "../../helpers/hoc/loader/Loader";

const GeoFenceConfigurationScreen = ()=> {
    // Redux States
    const isLoading = useSelector((s) => s?.loader?.isLoading)
    const [activeTab, setActiveTab] = useState('add_parameter');
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };
    
    return (
        <>
         <div className="col-12">
              <div className="mt-3 mb-3">
                <div className="main-title-configuraton ">Configure</div>
              </div>
            </div>
        <Container fluid="md" className="geo-fence-config-screen">
            
            <div className="p-3 mb-2 overflow-configure">
                <div className="geo__config-tab-container">
                <Tabs
                    defaultActiveKey="add_parameter"
                    id="uncontrolled-tab-example"
                    className="fence-configuration-tabs alert-nav-link"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    >
                        <Tab eventKey="add_parameter" title="Add Parameter">
                            <AddLabel></AddLabel>
                            <AddLabelOwner></AddLabelOwner>
                            <AddFenceOwner></AddFenceOwner>
                        </Tab>
                        <Tab eventKey="add_alert" title="Add Alert">
                        {activeTab === 'add_alert' && <GeoFenceAlertConfigurationTab />}
                        </Tab>
                </Tabs>
                </div>
                <Loader isLoading={isLoading}></Loader>
            </div>
        </Container>
        </>
    )
}

export default GeoFenceConfigurationScreen