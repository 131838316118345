import ActionTypes from '../actionTypes'

const initialState = {
  vehicles: [],
  vehicleModelWithStatusCount: 0,
}

const getFleetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FLEET.OEM_API:
      return {
        ...state,
        vehicles: payload?.vehicleModelWithStatus,
        vehicleModelWithStatusCount: payload?.totalCount
      };
    default:
      return state;
  }
};

export default getFleetReducer;
