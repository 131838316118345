import React, { useEffect, useState } from 'react'
import {
  Container,
  Table,
  Tab,
  Tabs,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  GetAllNonCommLogs,
  ExportNonCommLogsReport,
} from '../../../store/actions/reports/nonCommLogs'
import Pagination from '../../helpers/hoc/paginator/Pagination'
import './css/nonCommLogs.css'
import { FaSearch } from 'react-icons/fa'
import { BsDownload } from 'react-icons/bs'
import {
  getFormattedDate,
} from '../../../utils/common-methods'
import { BiInfoCircle } from 'react-icons/bi'
import Loader from '../../helpers/hoc/loader/Loader'
import MultiSelect from '../../helpers/hoc/mult-select/MultiSelect'
import NoRecordFound from '../../helpers/hoc/noRecordFound'
import MapAdddressByLocation from "../../helpers/hoc/latlong-to-address-map/MapAddressByLocation";
import { Report_Filter_Type } from '../../../utils/constants'
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping'
import SingleSelect from '../../helpers/hoc/single-select/SingleSelect'
import DatePicker from '../../helpers/hoc/date-picker/DatePicker'
import { mapper } from '../../../utils/mapper'
import { formatter } from '../../../utils/formatter'

const durationOptions = [{
  label: '0-2 Days',
  value: '0_2_DAYS'
}, {
  label: '2-7 Days',
  value: '2_7_DAYS'
}, {
  label: '7-15 Days',
  value: '7_15_DAYS'
}, {
  label: '15-30 Days',
  value: '15_30_DAYS'
}, {
  label: 'Above 30 Days',
  value: 'ABOVE_30_DAYS'
}]

const Connectivity = ({
  // State props
  allNonCommLogsData,
  allNonCommReportData,
  logsDataCount,
  reportDataCount,
  isLoading,

  // Action props
  GetAllNonCommLogs,
  ExportNonCommLogsReport,
  GetUserRoleList,
  subscribedByCI,
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [intervalDuration, setIntervalDuration] = useState()
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('non_comm_report');
  const [showPopup, setShowPopup] = useState(false);
  const [coordinate, setCoordinate] = useState();
  const [filterType, setFilterType] = useState(Report_Filter_Type.VEHICLE)
  const [selectedOemModel, setSelectedOemModel] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [clearDateSelection, setClearDateSelection] = useState(false)

  const [tempUnresolvedFilters, setTempUnresolvedFilters] = useState({
    SEARCH: '',
    DURATION: [],
    DATA_SOURCE: [],
    OEM_MODEL: [],
    LOCATION: []
  })

  const [unresolvedFilters, setUnresolvedFilters] = useState({
    SEARCH: '',
    DURATION: [],
    DATA_SOURCE: [],
    OEM_MODEL: [],
    LOCATION: []
  })

  const MaxVehicleLimit = 51
  const ITEMS_PER_PAGE = 8;

  const mappedOptions = mapper.mapVehicleDataOptions(subscribedByCI);

  useEffect(() => {
    if (selectedTab === 'non_comm_log' && logsDataCount > 0) {
      GetAllNonCommLogs({
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        registrationNumbers: selectedOptions,
        fromDate: startDate,
        toDate: endDate,
        intervalDuration:intervalDuration,
        status: 'Close',
        oemModels: selectedOemModel.map((item)=>item?.label),
        cities: selectedCity?.value ? [selectedCity?.value] : []
      });
    }

    if (selectedTab === 'non_comm_report') {
      GetAllNonCommLogs({
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        status: 'Open',
        searchKey: unresolvedFilters.SEARCH,
        oemModels: unresolvedFilters.OEM_MODEL.map(item => item.value),
        cities: unresolvedFilters.LOCATION.map(item => item.value),
        dataSources: unresolvedFilters.DATA_SOURCE.map(item => item.value),
        durations: unresolvedFilters.DURATION.map(item => item.value),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    GetUserRoleList()
  }, [GetUserRoleList])


  const calculateIntervalDuration = (fromDate, toDate) => {
    const from = new Date(fromDate)
    const to = new Date(toDate)
    const diff = Math.abs(to - from)
    const minutes = Math.floor((diff / 1000) / 60)

    //for less than 1 day return 1 hr
    if (minutes < 1440) return 120
    //for less than 3 days return 4 hrs
    else if (minutes < 4320) return 240
    //for less than 7 days return 12 hrs
    else if (minutes < 10080) return 720
    //for less than 16 days return 1 day
    else if (minutes < 23040) return 1440
    //for greater than 16 days return 2 day
    return 2880
  }

  const onDateChange = (date) => {
    setStartDate(date[0])
    setEndDate(date[1])
    setIntervalDuration(calculateIntervalDuration(startDate, endDate))
  }

  useEffect(() => {
    if(Report_Filter_Type.VEHICLE === filterType) {
      setSelectedOemModel([])
      setSelectedCity(null)
    } else {
      setSelectedOptions([])
    }
    setStartDate(null)
    setEndDate(null)
    setClearDateSelection(true);
		setTimeout(() => {
			setClearDateSelection(false);
		}, 500);

  }, [filterType])

  const isDisabled = () => {
    if(Report_Filter_Type.VEHICLE === filterType) {
      return !(selectedOptions !== null && selectedOptions?.length > 0 && startDate !== null && endDate !== null) || selectedOptions?.length >= MaxVehicleLimit
    } else {
      return !(selectedOemModel !== null && selectedOemModel?.length > 0 && selectedCity !== null && startDate !== null && endDate !== null)
    }
  }

  const onTabSelectHandler = (event) => {
    setSelectedTab(event);
    setCurrentPage(1);
    if (event === 'non_comm_report') {
      setUnresolvedFilters({
        SEARCH: '',
        DURATION: [],
        DATA_SOURCE: [],
        OEM_MODEL: [],
        LOCATION: []
      })

      setTempUnresolvedFilters({
        SEARCH: '',
        DURATION: [],
        DATA_SOURCE: [],
        OEM_MODEL: [],
        LOCATION: []
      })
      return;
    }
  }

  function onSelectionChange(){
    allNonCommLogsData.length = 0;
  }

  function renderTooltipLog(props) {
    return (
      <Tooltip className="tooltip" id="button-tooltip" {...props}>
        {props.parameter1}: {props.start}
        {props?.unit ? props.unit : ''} <br />
        {props.parameter2}: {props.end}
        {props?.unit ? props.unit : ''}
      </Tooltip>
    )
  }

  // Commenting these lines of code as, in future, we might need this.

  // function renderTooltipUnresolved(props) {
  //   return (
  //       <Tooltip className="tooltip" id="button-tooltip" {...props}>
  //       {props.parameter1}: {props.start}
  //       {props?.unit ? props.unit : ''}
  //     </Tooltip>
  //   )
  // }

  const exportToExcel = (status ) =>{
    let value  = {};

    if(status !== "Open"){
      value = {
        status: status,
        fromDate: startDate,
        toDate: endDate,
        registrationNumbers: selectedOptions,
        oemModels: selectedOemModel.map((item)=>item?.label),
        cities: selectedCity?.value ? [selectedCity?.value] : []
      };
    } else {
      setUnresolvedFilters({ ...tempUnresolvedFilters })
      value = {
        status: status,
        searchKey: tempUnresolvedFilters.SEARCH,
        oemModels: tempUnresolvedFilters.OEM_MODEL.map(item => item.label),
        cities: tempUnresolvedFilters.LOCATION.map(item => item.value),
        dataSources: tempUnresolvedFilters.DATA_SOURCE.map(item => item.value),
        durations: tempUnresolvedFilters.DURATION.map(item => item.value),
      }
    }
    ExportNonCommLogsReport(value);
  }

  const onSubmitHandler = () => {
    setCurrentPage(1);
    if (selectedTab === 'non_comm_log') {
      GetAllNonCommLogs({
        pageIndex: 0,
        pageSize: ITEMS_PER_PAGE,
        registrationNumbers: selectedOptions,
        fromDate: startDate,
        toDate: endDate,
        intervalDuration:intervalDuration,
        status: 'Close',
        oemModels: selectedOemModel.map((item)=>item?.label),
        cities: selectedCity?.value ? [selectedCity?.value] : []
      });
    }

    if (selectedTab === 'non_comm_report') {
      setUnresolvedFilters({ ...tempUnresolvedFilters })

      GetAllNonCommLogs({
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        status: 'Open',
        searchKey: tempUnresolvedFilters.SEARCH,
        oemModels: tempUnresolvedFilters.OEM_MODEL.map(item => item.label),
        cities: tempUnresolvedFilters.LOCATION.map(item => item.value),
        dataSources: tempUnresolvedFilters.DATA_SOURCE.map(item => item.value),
        durations: tempUnresolvedFilters.DURATION.map(item => item.value),
      });
    }
  }

  const togglePopup = () => {
    setShowPopup((prevState) => !prevState);
  };
  const handleMapCoOrdinateClick = (event) => {
    setShowPopup(true);
    setCoordinate(event);
  };
  
  useEffect(() => {
    return () => {
      if(allNonCommLogsData?.length) {
        allNonCommLogsData.length = 0
      }
    };
  }, [allNonCommLogsData]);

  const handleFilterChange = ({ name, value }) => {
    setTempUnresolvedFilters((prevState) => (
      { ...prevState, [name]: value }
    ))
  }

  return (
    <>
      <Container fluid>
      <div className='non_comm_report-tab-container'>
        <Tabs
          defaultActiveKey="non_comm_report"
          id="uncontrolled-tab-example"
          className="alert-nav-link"
          onSelect={(event) => {
            onTabSelectHandler(event)
          }}
        >
          <Tab eventKey="non_comm_report" title="Unresolved" data-testid='noncomm'>
            <div className="d-flex flex-row align-item-center justify-content-start">
            </div>

              <div
                style={{
                  marginTop: '-12px'
                }}
                className="d-flex col-12 flex-column h-100"
              >
                <div className="d-flex gap-2">
                  <div className={`d-inline-flex align-items-center gap-3 ${!tempUnresolvedFilters.SEARCH.length ? 'no-value-selected-input' : ''}`}>
                    <div className="search-bar-div search-input connectivity-search-bar-div">
                      <FaSearch className="vob-search-icon" />
                      <input
                        onChange={(e) => handleFilterChange({ value: e.target.value, name: 'SEARCH' })}
                        className="vob-search-input search-input"
                        placeholder="Search"
                        value={tempUnresolvedFilters.SEARCH}
                        data-testid='unresolved-search-input'
                      />
                    </div>
                  </div>

                  <div className={`${!tempUnresolvedFilters.DURATION.length ? 'no-value-selected' : ''}`}>
                    <MultiSelect
                      options={durationOptions}
                      selectedOptions={tempUnresolvedFilters.DURATION}
                      placeholder='Duration'
                      setSelectedOptions={(value) => handleFilterChange({ value, name: 'DURATION' })}
                      isMultiSelect={true}
                      customBackgroundColor='#EFF3F7'
                      webkitScrollbarWidth='5px'
                      webkitScrollbarHeight='5px'
                      minHeight='45px'
                      placeholderAlignment='flex-start'
                      indicatorSeparatorDisplay='none'
                      placeholderColor='#C6C6C6'
                      valueContainerColor="#797694"
                      optionPosition='justify-content-start'
                      valueContainerWidthInPx='200px'
                      valueContainerMaxWidthInPx='200px'
                      menuListPaddingX='px-2'
                      okButtonPaddingEnd='pe-2'
                      data-testid='duration-filter'
                    />
                  </div>

                  <div className={`${!tempUnresolvedFilters.DATA_SOURCE.length ? 'no-value-selected' : ''}`}>
                    <MultiSelect
                      options={mappedOptions.customDataSource}
                      selectedOptions={tempUnresolvedFilters.DATA_SOURCE}
                      placeholder='Data Source'
                      setSelectedOptions={(value) => handleFilterChange({ value, name: 'DATA_SOURCE' })}
                      isMultiSelect={true}
                      customBackgroundColor='#EFF3F7'
                      webkitScrollbarWidth='5px'
                      webkitScrollbarHeight='5px'
                      minHeight='45px'
                      placeholderAlignment='flex-start'
                      indicatorSeparatorDisplay='none'
                      placeholderColor='#C6C6C6'
                      valueContainerColor="#797694"
                      optionPosition='justify-content-start'
                      valueContainerWidthInPx='200px'
                      valueContainerMaxWidthInPx='200px'
                      menuListPaddingX='px-2'
                      okButtonPaddingEnd='pe-2'
                      data-testid='data-source-filter'
                    />
                  </div>

                  <div className={`${!tempUnresolvedFilters.OEM_MODEL.length ? 'no-value-selected' : ''}`}>
                    <MultiSelect
                      options={mappedOptions.oemModel}
                      selectedOptions={tempUnresolvedFilters.OEM_MODEL}
                      placeholder='OEM & Model'
                      setSelectedOptions={(value) => handleFilterChange({ value, name: 'OEM_MODEL' })}
                      isMultiSelect={true}
                      customBackgroundColor='#EFF3F7'
                      webkitScrollbarWidth='5px'
                      webkitScrollbarHeight='5px'
                      minHeight='45px'
                      placeholderAlignment='flex-start'
                      indicatorSeparatorDisplay='none'
                      placeholderColor='#C6C6C6'
                      valueContainerColor="#797694"
                      optionPosition='justify-content-start'
                      valueContainerWidthInPx='200px'
                      valueContainerMaxWidthInPx='200px'
                      menuListPaddingX='px-2'
                      okButtonPaddingEnd='pe-2'
                      data-testid='oem-model-filter'
                    />
                  </div>

                  <div className={`${!tempUnresolvedFilters.LOCATION.length ? 'no-value-selected' : ''}`}>
                    <MultiSelect
                      options={mappedOptions.location}
                      selectedOptions={tempUnresolvedFilters.LOCATION}
                      placeholder='City'
                      setSelectedOptions={(value) => handleFilterChange({ value, name: 'LOCATION' })}
                      isMultiSelect={true}
                      customBackgroundColor='#EFF3F7'
                      webkitScrollbarWidth='5px'
                      webkitScrollbarHeight='5px'
                      minHeight='45px'
                      placeholderAlignment='flex-start'
                      indicatorSeparatorDisplay='none'
                      placeholderColor='#C6C6C6'
                      valueContainerColor="#797694"
                      optionPosition='justify-content-start'
                      valueContainerWidthInPx='200px'
                      valueContainerMaxWidthInPx='200px'
                      menuListPaddingX='px-2'
                      okButtonPaddingEnd='pe-2'
                      data-testid='multi-city-filter'
                    />
                  </div>

                  <Button
                    data-testid='get-report-button'
                    className="bttn height"
                    id="getReport"
                    type="submit"
                    onClick={onSubmitHandler}
                  >
                    Get Report
                  </Button>

                  <button
                    onClick={() => {
                      allNonCommReportData?.length && exportToExcel("Open")
                    }}
                    className={
                      !allNonCommReportData?.length
                        ? 'dwn-btn dwn-btn-disabled'
                        : 'dwn-btn'
                    }
                    data-testid='unresolved-export-button'
                  >
                    <BsDownload style={{ width: '22px', height: '22px' }} />
                  </button>
                </div>
              </div>
            {allNonCommReportData?.length ? (
              <div className="bg-white mb-2 mt-4 position-relative trip__log__table">
                <Table responsive={true} borderless className="mb-0">
                  <thead>
                    <tr className="th-border-bottom position-relative">
                      <th className="ps-2 text-nowrap"><span className='fw500'>S.No</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Vehicle No.</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'> Data Source</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'> City</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'> OEM & Model</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Issue</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Date & Time</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Duration</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>GPS</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allNonCommReportData?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {index +
                              1 +
                              ((currentPage - 1) * 10 +
                                (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                              '.'}
                          </td>
                          <td>{val?.registration_number}</td>
                          <td>{formatter.vehicleSourceDataMapper(val?.canStatus?.src)}</td>
                          <td>{val?.city}</td>
                          <td>{val?.oem} {val?.model}</td>
                          <td>{val?.issue}</td>
                          <td>
                            {val?.startDateTime
                              ? getFormattedDate(val?.startDateTime)
                              : val?.startDateTime}
                          </td>
                          <td>
                            <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                              {val?.duration}

                              {/* <OverlayTrigger
                                placement="auto-end"
                                delay={{ show: 0, hide: 0 }}
                                overlay={renderTooltipUnresolved({
                                  parameter1: 'Start',
                                  start: getFormattedDate(val.startDateTime),
                                })}
                              >
                                <button className="row-btn">
                                  <BiInfoCircle className="row-i-icon" />
                                </button>
                              </OverlayTrigger> */}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                              
                                <img
                                  onClick={(event)=>handleMapCoOrdinateClick({
                                    lat : parseFloat(val?.startCordinates?.split(',')[0]),
                                    lng : parseFloat(val?.startCordinates?.split(',')[1])
                                  })}
                                  src="/images/svgicon/location.svg"
                                  alt="location"
                                  style={{
                                    width: 18,
                                    cursor: "pointer"
                                  }}
                                  data-testid={`unresolved-gps-button-${val?.registration_number}`}
                                ></img>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <Pagination
                  disabled={false}
                  totalPages={Math.ceil(
                    (reportDataCount || 0) / ITEMS_PER_PAGE
                  )}
                  currentPage={currentPage}
                  setPage={setCurrentPage}
                  data-testid='unresolved-pagination'
                />
              </div>
            ) : (
              <></>
            )}
            {!allNonCommReportData?.length && !isLoading ? (
              <NoRecordFound></NoRecordFound>
            ) : (
              <></>
            )}
          </Tab>
          <Tab eventKey="non_comm_log" title="Records">
            <div className="d-flex flex-row align-item-center justify-content-start">
            </div>

            <div style={{
              marginTop: '-15px'
            }} className="d-flex col-12 flex-column h-100">
              <div className='col-12'>
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"nonCommFilterType"}
                  id={"nonCommFilterType"}
                  checked={filterType === Report_Filter_Type.VEHICLE}
                  onChange={() => { setFilterType(Report_Filter_Type.VEHICLE); onSelectionChange(); }}
                  data-testid={`vehicle-type-radio-button`}
                />
                <text className="radio-checkbox-text-heading">
                    {Report_Filter_Type.VEHICLE}
                </text>
              </span>
              &nbsp;&nbsp;
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"nonCommFilterType"}
                  id={"nonCommFilterType"}
                  checked={filterType === Report_Filter_Type.OEM_MODEL_CITY}
                  onChange={() => { setFilterType(Report_Filter_Type.OEM_MODEL_CITY); onSelectionChange(); }}
                  data-testid={`oem-model-radio-button`}
                />
                <text className="radio-checkbox-text-heading">
                    {Report_Filter_Type.OEM_MODEL_CITY}
                </text>
              </span>
              </div>

              <div className="row d-flex nonCommLogsFilter">

              <div 
              className={`col-3 multi-select-width ${!selectedOptions[0]? 'no-value-selected' : ''}`}
              style={{display: filterType === Report_Filter_Type.VEHICLE ? 'block': 'none'}}>
             <MultiSelect
               options={mappedOptions.registrationNumber}
               selectedOptions={selectedOptions}
               setSelectedOptions={setSelectedOptions}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694" 
               data-testid='vehicle-filter'
               maxOptionSelectionLimit={MaxVehicleLimit}
              />
           </div>
           <div 
              className={`col-3 multi-select-width ${!selectedOemModel[0]? 'no-value-selected' : ''}`}
              style={{display: filterType === Report_Filter_Type.OEM_MODEL_CITY ? 'block': 'none'}}>
             <MultiSelect
               options={mappedOptions.oemModel}
               selectedOptions={selectedOemModel}
               placeholder='OEM & Model'
               setSelectedOptions={setSelectedOemModel}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               valueContainerMaxWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='oem-model-filter'

               />
           </div>
           <div className={`col-3 multi-select-width ${!selectedCity? 'no-value-selected' : ''}`}
                style={{display: filterType === Report_Filter_Type.OEM_MODEL_CITY ? 'block': 'none'}}>
                  <SingleSelect
                        placeholder="City"
                        options={mappedOptions.location}
                        selectedOptions={selectedCity}
                        onSelectHandler={(value) => {setSelectedCity(value); onSelectionChange(); }}
                        customBackgroundColor="#F5F7F9"
                        data-testid='city-filter'
                        minHeight={'45px'}
                        optionPosition="ps-2 justify-content-start"
                        valueContainerJustifyContent="start"
                        singleValueColor="#1F2A5D"
                        menuMaxheight="225px"
                  /> 
           </div>
           <div className={`col-3 multi-select-width ${!startDate? 'no-value-selected' : ''}`}>
           <DatePicker
              onDateChange={(dates) => {onDateChange(dates); onSelectionChange();}}
              allowedMaxDaysInSelection={31}
              paddingLeft='0px'
              clearDateSelectionOnToggle={clearDateSelection}
              data-testid='daterange-filter'
              customBackgroundColor='#EFF3F7'
          />
           </div>

                <div className='col-2'>
                  <Button
                    data-testid='get-report-button'
                    className="bttn height"
                    id="getReport"
                    type="submit"
                    onClick={onSubmitHandler}
                    disabled={isDisabled()}
                  >
                    Get Report
                  </Button>
                </div>

                <div className='col-1'>
                  <button
                    data-testid='export-button'
                    disabled={
                      !allNonCommLogsData?.length
                    }
                    className={
                      !allNonCommLogsData?.length
                        ? 'dwn-btn dwn-btn-disabled'
                        : 'dwn-btn'
                    }
                    onClick={() => {
                      exportToExcel("Close")
                    }}
                  >
                    <BsDownload style={{width:'22px',height:'22px'}} />
                  </button>
                </div>
              </div>
            </div>
            {allNonCommLogsData?.length ? (
              <div className="bg-white mb-2 mt-4 position-relative trip__log__table">
                <Table
                  responsive={true}
                  borderless
                  className="mb-0 overflow-hidden"
                >
                  <thead>
                    <tr className="th-border-bottom position-relative">
                      <th className="ps-2 text-nowrap"><span className='fw500'>S.No</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Vehicle No.</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'> OEM & Model</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Issue</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Date & Time</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>Duration</span></th>
                      <th className="ps-2 text-nowrap"><span className='fw500'>GPS</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allNonCommLogsData?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {index +
                              1 +
                              ((currentPage - 1) * 10 +
                                (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                              '.'}
                          </td>
                          <td>{val?.registration_number}</td>
                          <td>{val?.oem} {val?.model}</td>
                          <td>{val?.issue}</td>
                          <td>
                            {val?.startDateTime
                              ? getFormattedDate(val?.startDateTime)
                              : val?.startDateTime}
                          </td>
                          <td>
                            <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                              {val?.duration}

                              <OverlayTrigger
                                placement="auto-end"
                                delay={{ show: 0, hide: 0 }}
                                overlay={renderTooltipLog({
                                  parameter1: 'Start',
                                  parameter2: 'End',
                                  start: getFormattedDate(val.startDateTime),
                                  end: getFormattedDate(val.endDateTime),
                                })}
                              >
                                <button className="row-btn">
                                  <BiInfoCircle className="row-i-icon" />
                                </button>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                                <img
                                  onClick={(event)=>handleMapCoOrdinateClick({
                                    lat : parseFloat(val?.startCordinates?.split(',')[0]),
                                    lng : parseFloat(val?.startCordinates?.split(',')[1])
                                  })}
                                  src="/images/svgicon/location.svg"
                                  alt="location"
                                  style={{
                                    width: 18,
                                    cursor: "pointer"
                                  }}
                                  data-testid={`records-gps-button-${val?.registration_number}`}
                                ></img>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <Pagination
                  disabled={false}
                  totalPages={Math.ceil((logsDataCount || 0) / ITEMS_PER_PAGE)}
                  currentPage={currentPage}
                  setPage={setCurrentPage}
                  data-testid='records-pagination'
                />
              </div>
            ) : (
              <></>
            )}
            {!allNonCommLogsData?.length && !isLoading ? (
              <NoRecordFound></NoRecordFound>
            ) : (
              <></>
            )}
          </Tab>
        </Tabs>

      </div>
        
      </Container>
      <Loader isLoading={isLoading} />

      {
        showPopup
        &&
        <div id="popup1" className={`popup ${showPopup ? "open" : ""}`}>
          <div className="popup-overlay" data-testid='unresolved-gps-dialog-toggle' onClick={togglePopup}></div>
          <div className="popup-content">
            <MapAdddressByLocation isOpen={showPopup} toggleModal={togglePopup} CoOrdinates={coordinate} data-testid='unresolved-gps-dialog' />
          </div>
        </div>
      }

    </>
  );
}

Connectivity.propTypes = {
  allNonCommLogsData: PropTypes.array.isRequired,
  allNonCommReportData: PropTypes.array.isRequired,
  logsDataCount: PropTypes.number.isRequired,
  reportDataCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Action props
  GetAllNonCommLogs: PropTypes.func.isRequired,
  ExportNonCommLogsReport: PropTypes.func.isRequired,
  subscribedByCI: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  allNonCommLogsData: state.nonCommLogs.allNonCommLogsData,
  allNonCommReportData: state.nonCommLogs.allNonCommReportData,
  logsDataCount: state.nonCommLogs.logsDataCount,
  reportDataCount: state.nonCommLogs.reportDataCount,
  isLoading: state.loader.isLoading,
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
});

export default connect(mapStateToProps, {
  GetAllNonCommLogs,
  ExportNonCommLogsReport,
  GetUserRoleList
})(Connectivity);
