// External packages import
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Internal components import
import BaseModal from '../../../../shared/modals/BaseModal/BaseModal'
import BaseCard from '../../../../shared/cards/BaseCard/BaseCard'

// Style import
import styles from './AccessTemplateDetailModal.module.css'
import addEditFormStyles from '../AccessTemplateAddEditForm/AccessTemplateAddEditForm.module.css'

const AccessTemplateDetailModal = (props) => {
    const { isOpen, onClose, templateData, onEdit } = props
    const normalizedTemplateData = templateData === null ? { modules: [], name: '', id: '' } : templateData
    const [moduleId, setModuleId] = React.useState(null);
    
    React.useEffect(() => {
        if (normalizedTemplateData.modules.length) {
            setModuleId(normalizedTemplateData.modules[0].moduleId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData])

    const submodulesList = normalizedTemplateData.modules.find(d => d.moduleId === moduleId)?.subModules || []

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={() => {
                onClose()
                setModuleId(null)
            }}
            title={normalizedTemplateData.name}
            classes={{
                modalWrapper: styles.modalWrapper
            }}
        >
            <div className={addEditFormStyles.contentWrapper} style={{ width: '100%' }}>
                <button onClick={() => onEdit({ templateId: normalizedTemplateData.id })} className={styles.editButton}>
                    <img src="/images/darkSVGIcon/edit.svg" alt="edit icon" />
                </button>
                <BaseCard className={cx(addEditFormStyles.column, addEditFormStyles.columnModuleList)} variant='secondary' style={{ maxWidth: '260px' }}>
                    <div className={addEditFormStyles.columnHeader}>
                        <p>List of Modules</p>
                    </div>

                    <div className={addEditFormStyles.columnBody}>
                        {normalizedTemplateData.modules.map((module, moduleIndex) => (
                            <button
                                key={`view_module_${moduleIndex}`}
                                className={cx(addEditFormStyles.moduleListItem, { [addEditFormStyles.moduleListActiveItem]: moduleId === module.moduleId })}
                                onClick={() => setModuleId(module.moduleId)}
                            >
                                {module.module}
                                <img src="/images/darkSVGIcon/arrow_right.svg" alt="arrow icon" />
                            </button>
                        ))}
                    </div>
                </BaseCard>

                <BaseCard className={cx(addEditFormStyles.column, addEditFormStyles.columnSubmodulesActionTypes)} variant='secondary'>
                    <div className={addEditFormStyles.columnHeader}>
                        <p>Sub Modules</p>
                        <p>Action Type</p>
                    </div>

                    <div className={addEditFormStyles.columnBody}>
                        <div>
                            {submodulesList.map(submodule => (
                                <div
                                    key={`submodule_${submodule.value}`}
                                    className={cx(styles.submoduleRowWrapper, addEditFormStyles.rowWrapper)}
                                >
                                    <div className={cx(addEditFormStyles.submoduleWrapper, styles.submoduleWrapper)}>
                                        <span>{submodule.label}</span>
                                    </div>

                                    <div className={cx(addEditFormStyles.actionTypesWrapper, styles.actionTypesWrapper)}>
                                        {submodule.actionTypes.map((actionType) => (
                                            <span key={`action_types_${submodule.value}_${actionType.value}`}>
                                                {actionType.label}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </BaseCard>
            </div>
        </BaseModal>
    )
}

AccessTemplateDetailModal.defaultProps = {
    onEdit: () => {},
    isEditEnabled: false,
}

AccessTemplateDetailModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    templateData: PropTypes.shape({
        name: PropTypes.string.isRequired,
        modules: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            subModules: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
                actionTypes: PropTypes.arrayOf(PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.string,
                })),
            })).isRequired,
        })).isRequired,
    }),
    onEdit: PropTypes.func,
    isEditEnabled: PropTypes.bool,
}

export default AccessTemplateDetailModal