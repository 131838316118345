import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NavLink } from "react-router-dom";
import "./sidepanel.css";
import "bootstrap/dist/css/bootstrap.css";
import SubMenuPopup from "./subMenuPopup";
import { DEPARTMENT_ADMIN, DEPARTMENT_USER, ProtectedMenus } from "../../utils/constants";

const getInitials = function (name = '') {
	const parts = name.split(' ')
	let initials = ''

	if (parts.length) {
		if (parts.length === 1) {
		initials = parts[0][0]
		} else {
		for (let i = 0; i < 2; i++) {
			if (parts[i].length > 0 && parts[i] !== '') {
			initials += parts[i][0]
			}
		}
		}
	}

	return initials
}

const SideNavPanel = () => {
  const initialSubMenuState = {
    menuIndex: 0,
    items: [],
    showPopup: false,
  };
  const [subMenuState, setSubMenuState] = useState(initialSubMenuState)
  const [menuItems, setMenuItems] = useState([])
  const localUser = JSON.parse(localStorage.getItem('user'))
  const userRole = JSON.parse(localStorage.getItem('user'))?.userRole
  const nameInitials = getInitials(localUser.name)
  const activePagePath = useLocation();

  useEffect(() => {
	let menuItemss = []
	if (userRole === DEPARTMENT_USER) {
		menuItemss = ProtectedMenus.filter((x) => (x.isPublic || localUser?.menus?.includes(x.id)) && x.name !== 'Admin' )
	}
	else if(userRole === DEPARTMENT_ADMIN) {
		menuItemss = ProtectedMenus.filter((x) => (x.isPublic || localUser?.menus?.includes(x.id)) && x.name !== 'Customization')
	}
	else {
		menuItemss = ProtectedMenus.filter(x => x.isPublic || localUser?.menus?.includes(x.id))
	}
	setMenuItems(menuItemss)
    // eslint-disable-next-line
  }, [])
  
  const showSubMenu = (id, items, isDisabled) => {
    if (items && items.length > 0 && !isDisabled) {
      setSubMenuState({
        ...subMenuState,
        menuIndex: id,
        showPopup: true,
        items: items,
      });
    }
  };

	const openSubMenu = () => {
		setSubMenuState({
			...subMenuState,
			showPopup: true,
		});
	};

  const hideSubMenu = () => {
    setSubMenuState({
      ...subMenuState,
      showPopup: false,
    });
  };
  const calculateSubMenuArg = useCallback((item, index) => {
    let subMenuArg;
   // when taskbar is shown screen size 150%
    if (window.innerWidth >= 1280 && window.innerWidth < 1460) {
      subMenuArg = item.name === 'User Profile' ? 8 : index + 1;
    } 
	// when taskbar is shown screen size 125%
	else if (window.innerWidth >= 1500 && window.innerWidth < 1600) {
      subMenuArg = item.name === 'User Profile' ? 10 : index + 1;
    } 
	// when taskbar is shown screen size 100%
	else if (window.innerWidth >= 1700 && window.innerWidth < 1950) {
      subMenuArg = item.name === 'User Profile' ? 11.7 : index + 1;
    }

    return subMenuArg;
  }, []);


  return (
    <>
      <div className={'sidebar close '}>
                {menuItems.map((item, index) => (
                    !item.isHidden &&
					<NavLink
					disabled={item.isDisabled}
					to={item?.submenu ? item?.submenu?.[0]?.path : item?.path}
					key={index}
					onMouseEnter={() => {
						const subMenuArg = calculateSubMenuArg(item, index);
						showSubMenu(subMenuArg, item.submenu?.filter(x => x.isMenu), item.isDisabled);
					}}
					className={`link ${
						activePagePath.pathname === '/' && item.path === '/' ? 'navlink__active' : ''
					} ${
						activePagePath.pathname.includes(item.path) && item.path !== '/'
							? 'navlink__active'
							: ''
					} ${item.name === 'User Profile' ? 'profile-navlink' : ''}`}
					onMouseLeave={() => hideSubMenu()}
					
					data-testid={`sidebar-menu-link-${item.name}`}
				>
					<div className='icon popover-trigger'>
						{item.name === 'User Profile' ? (
							<>
								<span className='profile-avatar'>{nameInitials}</span>
							</>
						) : (
							<img
								alt={item.name}
								src={item.icon}
								className='header-magenta-logo'
								style={{ width: '75%' }}
							></img>
						)}
					</div>
					{item.name !== 'User Profile' && <span className='navlink-text'>{item.name}</span>}
				</NavLink>

         
        ))}
      </div>

			<SubMenuPopup
				onMouseEnter={openSubMenu}
				onMouseLeave={hideSubMenu}
				id={subMenuState.menuIndex}
				items={subMenuState.items}
				showPopup={subMenuState.showPopup}
			></SubMenuPopup>
		</>
	);
};

export default SideNavPanel;
