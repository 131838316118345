import React from 'react';
import { Container } from 'react-bootstrap';
import HistoricAnalytics from '../records/HistoricAnalytics'


const HistoricalAnalytics = () => {
	return (
		<>
			<div className='col-12 bg-white'>
				<div className='bg-white mb-1 position-relative tabbed-content'>
					
					<div className='col-12'>
						<Container fluid>
							<div className='charging-tab-container'>
                                    <HistoricAnalytics />
							</div>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default HistoricalAnalytics