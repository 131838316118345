import { connect } from "react-redux";
import "../ReportTrip.css";
import ChartArea from "../../../../helpers/hoc/chart-area/ChartArea";
import {
  CityChartColorShades,
  OEMChartColorShades,
} from "../../../../../utils/constants";
import { BsCircle } from "react-icons/bs";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

const AreaChart = ({
  areaChartDataByCity,
  areaChartDataByOemModel,
  areaChartOemLevel,
  areaChartCityLevel,
  ...props
}) => {
  const baseTestId = props['data-testid'] || 'chartarea'
  const CustomXAxisTick = ({ x, y, payload }) => {
    const lines = payload.value.split(" ").reverse();
    const split = lines[1].split("-");
    lines[1] = split[0] + "-" + split[1];

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={8}
          dy={"0"} // Adjust vertical spacing between lines
          textAnchor="end"
          fill="#1F2A5D"
          key={0}
          style={{ font: "normal normal 500 12px Inter" }}
        >
          {lines[0]}
        </text>
        <text
          x={18}
          y={8}
          dy={"1.4em"} // Adjust vertical spacing between lines
          textAnchor="end"
          fill="#1F2A5D"
          key={1}
          style={{ font: "normal normal 500 12px Inter" }}
        >
          {lines[1]}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#939393",
            opacity: "0.87",
            borderRadius: "8px",
            padding: "3px 6px 3px 3px",
          }}
        >
          <p
            className="label"
            style={{ margin: "2px 0", textAlign: "center", color: "#FFFFFF" }}
          >
            {" "}
            {`${payload[0].payload.formattedDateTime}`}
          </p>
          {payload.map((chartData) => {
            return (
              <p
                className="label"
                style={{ margin: "2px 0", color: "#FFFFFF" }}
              >
                <BsCircle
                  style={{ background: chartData?.color }}
                  className="circular"
                />
                {`${chartData.dataKey} : ${
                  chartData.payload[chartData.dataKey]
                }`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

 
  const ChartData = [
    {
      dataType: "OEM-MODEL",
      title: "OEM & Model", //can be used as radio button text
      checkBoxTitle: "OEMs & Models",
      dataLevel: areaChartOemLevel,
      colorLevel: OEMChartColorShades,
      chartData: areaChartDataByOemModel,
      xDataKey: "formattedDateTime",
      xLabel: "Time",
      yLabel: "No. of Sessions",
      interval: 0,
      height: "97%",
      width: "80%",
      gridLine: {
        horizontal: false,
        Vertical: true,
      },
      isColorShadingEnable: true,
      customTooltip: CustomTooltip,
      customXAxisTick: CustomXAxisTick,
    },
    {
      dataType: "CITY",
      title: "City", //can be used as radio button text
      checkBoxTitle: "Cities",
      dataLevel: areaChartCityLevel,
      chartData: areaChartDataByCity,
      colorLevel: CityChartColorShades,
      xDataKey: "formattedDateTime",
      xLabel: "Time",
      yLabel: "No. of Sessions",
      interval: 0,
      height: "97%",
      width: "80%",
      gridLine: {
        horizontal: false,
        Vertical: true,
      },
      isColorShadingEnable: true,
      customTooltip: CustomTooltip,
      customXAxisTick: CustomXAxisTick,
    },
  ];

  return (
    <div>
        <Col xs="12">
          <ChartArea name='trip' chartData={ChartData} data-testid={baseTestId}></ChartArea>
        </Col>
    </div>
  );
};

AreaChart.propTypes = {
  areaChartDataByCity: PropTypes.array.isRequired,
  areaChartDataByOemModel: PropTypes.array.isRequired,
  areaChartOemLevel: PropTypes.array.isRequired,
  areaChartCityLevel: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  areaChartDataByCity: state.tripReportAnalytics.areaChartDataByCity,
  areaChartDataByOemModel: state.tripReportAnalytics.areaChartDataByOemModel,
  areaChartOemLevel: state.tripReportAnalytics.areaChartOemModelLevel,
  areaChartCityLevel: state.tripReportAnalytics.areaChartCityLevel,
});

export default connect(mapStateToProps, {})(AreaChart);
