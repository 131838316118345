import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

import VehicleStatusDark from './DarkVehicleFilters/DarkVehicleFilters'
import NotificationPanel from '../../sidebar/notificationPanel'

import { UserProfile } from "../../../store/actions/user";
import { GetAllNotificationData, ShowNotificationPanel } from '../../../store/actions/notificationPanel'
import dashboardAction from '../../../store/actions/dashboard/dashboard';
import { notificationStatus } from '../../../utils/constants';
import { FaSearch } from "react-icons/fa";

const DarkTopbar = ({ UserProfile, getNotificationData, notificationData, showNotificationPanel, isAuthenticated }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const notificationIntervalRef = useRef(null);
  const vehicleSearchRef = useRef(null)
  const [query, setQuery] = useState('')
  const topPanelSearchQuery = useSelector(state => state.dashboardReducer.topPanelSearchQuery)

  useEffect(() => {
    if(notificationIntervalRef.current){
      clearInterval(notificationIntervalRef.current);
    }
    if ((isAuthenticated && notificationData && notificationData.length > 0 && notificationData?.some(x=>x.status === notificationStatus.inProgress || x.status === notificationStatus.queued))) {
      notificationIntervalRef.current = setInterval(() => {
        getNotificationData()
      }, 60000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData,getNotificationData])

  useEffect(() => {
    if (isAuthenticated) {
      getNotificationData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotificationData])

  useEffect(() => {
    if (topPanelSearchQuery.length) {
      setQuery(topPanelSearchQuery)
    }
    return () => { clearTimeout(vehicleSearchRef.current) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on reset update field text
  useEffect(() => {
    if (!topPanelSearchQuery) {
      setQuery('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topPanelSearchQuery])

  return (
    <>
      <div className='dark-topbar-container'>
        <div className='dark-topbar-background'>
        <img className='dark-topbar-background-img' src='/images/darkLayout/topbar.svg' alt='topbarBackgroundImage'/>
        </div>
        <div className='dark-top-pannel'>
          <div className={`d-flex ${["/"].includes(route.pathname) ? 'justify-content-around' : 'justify-content-end'} align-items-center h-100 px-5`}>
            {["/"].includes(route.pathname) && (
              <div className='nd__dark-search-bar'>
                <FaSearch className="nd__dark-search-icon" />

                <input
                  data-testid='header-search-input'
                  className='search-dark-vehicle'
                  placeholder='Search'
                  onChange={(e) => { 
                    if (!route.pathname === "/") {
                      navigate("/")
                    }
                    setQuery(e?.target?.value)
                    
                    vehicleSearchRef.current = setTimeout(() => {
                      dispatch(dashboardAction.setTopPanelSearchQuery({ searchQuery: e.target.value }))
                    }, 1000)
                  }}
                  value={query}
                />
              </div>
            )}
            
            <VehicleStatusDark searchQuery={query} UserProfile={UserProfile} showNotificationPanel={showNotificationPanel} route={route} />
          </div>
        </div>
      </div>
      <NotificationPanel />
    </>
  )
}

const mapStateToProps = (state) => ({
  UserProfile,
  notificationData: state.notificationPanelReducer.notificationData,
  checkForNotification: state.notificationPanelReducer.checkForNotification,
  isAuthenticated: state.auth.isAuthenticated,
  // Logout,
});

export default connect(mapStateToProps, {
  UserProfile,
  getNotificationData: GetAllNotificationData,
  showNotificationPanel :ShowNotificationPanel,
  //  Logout
})(DarkTopbar)
/*conflict-resolution-will be removed later*/