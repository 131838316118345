import React, { useState, 
  // useEffect
 } from 'react'
import './NDVehicleInfoCharging.css'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Text,
  CartesianGrid,
} from 'recharts'
import moment from 'moment'

const CustomTick = ({ x, y, payload }) => {
  return (
    <Text width='5px' x={x} y={y} textAnchor='middle' verticalAnchor='start'>
      {payload.value}
    </Text>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const date = payload[0]?.payload?.timestamp?.split(' ')[1]
    const time = payload[0]?.payload?.timestamp?.split(' ')[0]
    return (
      <div className="nd__dark-custom-tooltip">
				<div className='nd__dark-custom-tooltip-arrow-dark' ></div>
        
        <p className="intro">{date}, {time}</p>
        <p className="label">{payload[0]?.payload?.value}%</p>
      </div>
    );
  }

  return null;
};

const NDVehicleInfoCharging = (props) => {
  const sample_data = props.data
    ? props.data.map((x) => {
        return {
          timestamp:
            moment(x.time).format('HH:mm') +
            ' ' +
            moment(x.time).format('DD-MMM-YYYY'),
          value: x.soc,
        }
      })
    : []
  const numberOfTicksToShow = 4
  const interval = Math.floor(sample_data.length / numberOfTicksToShow)
  // const [chargingState, setChargingState] = useState({
  //   totalSession: '',
  //   totalSessionDuration: '',
  //   totalEnergyAbsorbed: 'NA',
  // })
	const [isHovered, setIsHovered] = useState(false);


  // useEffect(() => {
    // const calculatedData = calculateChargingStats(props.data)
    // const chargingData = {
    //   totalSession: calculatedData.chargingCount,
    //   totalSessionDuration: new Date(calculatedData.chargingDuration * 1000)
    //     .toISOString()
    //     .slice(11, 16),
    //   totalEnergyAbsorbed: 'NA',
    // }
    // setChargingState(chargingData)
  // }, [props.data])

  // const calculateChargingStats = (data) => {
  //   let totalChargingDuration = 0
  //   const chargingSessions = []
  //   let chargingSessionStart = null

  //   for (const status of data) {
  //     if (status.vehicleStatus === 'Charging') {
  //       if (chargingSessionStart === null) {
  //         chargingSessionStart = new Date(status.timestamp)
  //       }
  //     } else {
  //       if (chargingSessionStart !== null) {
  //         const chargingSessionEnd = new Date(status.timestamp)
  //         const chargingDuration =
  //           (chargingSessionEnd - chargingSessionStart) / 1000 // Duration in seconds
  //         totalChargingDuration += chargingDuration
  //         chargingSessions.push({
  //           start_time: chargingSessionStart,
  //           end_time: chargingSessionEnd,
  //           duration: chargingDuration,
  //         })
  //         chargingSessionStart = null
  //       }
  //     }
  //   }

  //   // If the last session was charging, add it as well
  //   if (chargingSessionStart !== null) {
  //     const chargingSessionEnd = new Date(
  //       data[data.length - 1].timestamp
  //     )
  //     const chargingDuration =
  //       (chargingSessionEnd - chargingSessionStart) / 1000 // Duration in seconds
  //     totalChargingDuration += chargingDuration
  //     chargingSessions.push({
  //       start_time: chargingSessionStart,
  //       end_time: chargingSessionEnd,
  //       duration: chargingDuration,
  //     })
  //   }

  //   // Count the number of charging sessions and calculate total duration
  //   const numChargingSessions = chargingSessions.length
  //   return {
  //     chargingDuration: totalChargingDuration,
  //     chargingCount: numChargingSessions,
  //   }
  // }
  return (
    <>
      <div className={`nd__dark-vehicle-info-charging-container ${isHovered ? 'nd__dark-info-can-status-card-half-border' : ''}`}
       onMouseEnter={() => setIsHovered(true)}
       onMouseLeave={() => setIsHovered(false)}
      >
        {/* <div className='container-fuild'>
          <div className='nd__vehicle-info-charging-lables'>
            <div className='col-1'></div>
            <div className='col-3'>Total Charging Sessions</div>
            <div className='col-3'>Total Session Duration</div>
            <div className='col-3'>Total Energy Absorbed</div>
          </div>
          <div className='nd__vehicle-info-charging-green'>
            <div className='col-1'></div>
            <div className='col-3'>{chargingState.totalSession}</div>
            <div className='col-3'>{chargingState.totalSessionDuration}</div>
            <div className='col-3'>{chargingState.totalEnergyAbsorbed}</div>
          </div>
        </div> */}
        <ResponsiveContainer height={250} style={{ marginBottom: '16px' }}>
          <AreaChart
            margin={{ left: -30, top: 10, right: 10 }}
            data={sample_data}
          >
            <defs>
              <linearGradient id='colorGradient' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='7%' stopColor='#C417E0' stopOpacity={1} />
                <stop offset='95%' stopColor='#050505' stopOpacity={1} />
                {/* <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#8884d8' stopOpacity={0} /> */}
              </linearGradient>
            </defs>
            <CartesianGrid horizontal={false} vertical={true}></CartesianGrid>
            <XAxis
              dataKey='timestamp'
              stroke='#C417E0'
              // stroke='#10214B'
              tick={<CustomTick />}
              interval={interval}
              domain={['00:00', '1:00']}
            />
            <YAxis
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              unit={'%'}
              stroke='#C417E0'
              // stroke='#10214B'
            />
            <Tooltip
              content={<CustomTooltip />}
            />
            <Area
              type='monotone'
              dataKey='value'
              stroke='#C417E0'
              fill='url(#colorGradient)'
              // fill='#C417E0'
            />
            {sample_data && sample_data.length === 0 && (
              <text
                x='50%'
                y='50%'
                textAnchor='middle'
                dominantBaseline='middle'
                fill='#5B5959'
                fontSize='18'
              >
                No Data
              </text>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default NDVehicleInfoCharging
