import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import "./GroupedDataCharts.css";
import PieChartComponent from "../../../../helpers/hoc/donut-chart/dountChart";
import {
  CityChartColorShades,
  OEMChartColorShades,
  CHART_COLOR_CODES
} from "../../../../../utils/constants";
import { useEffect, useState } from "react";
import OemModelTripReport from "./oem-model-trip-report/OemModelTripReport";
import {
  getOemCityWiseTripReport,
  setOemCityWiseTripReportFilter,
} from "../../../../../store/actions/reports/analytics/trip-report";
import { secondsToHoursAndMinutesHHMM, secondsToHours, secondsToRemainingMinutesAfterHour } from "../../../../../utils/common-methods";

const GroupedDataCharts = ({
  tripReportData,
  getOemCityWiseTripReport,
  setOemCityWiseTripReportFilter,
  filter,
  filterType,
  currentPage,
  ...props
}) => {
  const [isShowOemTripModal, setisShowOemTripModal] = useState(false);
  const [chartData, setChartData] = useState([]);

  const [ tableModalName, setTableModalName] = useState('')

  const openOemTripReportModal = (filter, filterType) => {
    setisShowOemTripModal(true);
    setOemCityWiseTripReportFilter({ filter, filterType });
  };

  useEffect(() => {
    getOemCityWiseTripReport({ filter, filterType, currentPage });
  }, [filter, filterType, getOemCityWiseTripReport, currentPage]);

  useEffect(() => {
    const mappedChartsOemAndModel =
      tripReportData?.countByOemAndModel?.data?.map((item) => {
        return {
          value: item.nos,
          text: item.nos,
          name: item.oemAndModel,
          pieColor: CHART_COLOR_CODES[item.oemAndModel]
        };
      }) || [];
    const mappedChartsCity =
      tripReportData?.countByCity?.data?.map((item) => {
        return {
          value: item.nos,
          text: item.nos,
          name: item.city,
          pieColor: CHART_COLOR_CODES[item.city]
        };
      }) || [];
    const mappedChartsDistanceByCity =
      tripReportData?.distanceByCity?.data?.map((item) => {
        return {
          value: Math.abs(item.value),
          text: item.value,
          name: item.city,
          pieColor: CHART_COLOR_CODES[item.city]
        };
      }) || [];
    const mappedChartsDistanceByOemAndMOdel =
      tripReportData?.distanceByOemAndModel?.data?.map((item) => {
        return {
          value: Math.abs(item.value),
          text: item.value,
          name: item.oemAndModel,
          pieColor: CHART_COLOR_CODES[item.oemAndModel]
        };
      }) || [];
    const mappedChartsDuretionByOemAndMOdel =
      tripReportData?.durationByOemAndModel?.data?.map((item) => {
        return {
          value: Math.abs(item.value),
          text: secondsToHoursAndMinutesHHMM(item.value),
          name: item.oemAndModel,
          pieColor: CHART_COLOR_CODES[item.oemAndModel]
        };
      }) || [];
    const mappedChartsDurationByCity =
      tripReportData?.durationByCity?.data?.map((item) => {
        return {
          value: Math.abs(item.value),
          text: secondsToHoursAndMinutesHHMM(item.value),
          name: item.city,
          pieColor: CHART_COLOR_CODES[item.city]
        };
      }) || [];
    const mappedChartsEUByOemAndMOdel =
      tripReportData?.euByOemAndModel?.data?.map((item) => {
        return {
          value: Math.abs(item.value),
          text: item.value,
          name: item.oemAndModel,
          pieColor: CHART_COLOR_CODES[item.oemAndModel]
        };
      }) || [];
    const mappedChartsEUByCity =
      tripReportData?.euByCity?.data?.map((item) => {
        return {
          value: Math.abs(item.value),
          text: item.value,
          name: item.city,
          pieColor: CHART_COLOR_CODES[item.city]
        };
      }) || [];

    const chartData = [
      {
        chartName: "Trip Count",
        charts: [
          {
            title: "OEM & Model",
            data: mappedChartsOemAndModel,
            colors: OEMChartColorShades,
            labelType: "",
            selectedLabelType: "NOS",
            totalValue: tripReportData?.countByOemAndModel?.totalValue,
          },
          {
            title: "City",
            data: mappedChartsCity,
            colors: CityChartColorShades,
            labelType: "",
            selectedLabelType: "NOS",
            totalValue: tripReportData?.countByCity?.totalValue,
          },
        ],
      },
      {
        chartName: "Trip Distance",
        charts: [
          {
            title: "OEM & Model",
            data: mappedChartsDistanceByOemAndMOdel,
            colors: OEMChartColorShades,
            labelType: "kms",
            selectedLabelType: "kms",
            totalValue: tripReportData?.distanceByOemAndModel?.totalValue,
          },
          {
            title: "City",
            data: mappedChartsDistanceByCity,
            colors: CityChartColorShades,
            labelType: "kms",
            selectedLabelType: "kms",
            totalValue: tripReportData?.distanceByCity?.totalValue,
          },
        ],
      },
      {
        chartName: "Trip Duration",
        charts: [
          {
            title: "OEM & Model",
            data: mappedChartsDuretionByOemAndMOdel,
            colors: OEMChartColorShades,
            totalValue: secondsToHours(
              tripReportData?.durationByOemAndModel?.totalValue
            ) + " Hrs",
            line2: secondsToRemainingMinutesAfterHour(
              tripReportData?.durationByOemAndModel?.totalValue
            ) + " Mins"
          },
          {
            title: "City",
            data: mappedChartsDurationByCity,
            colors: CityChartColorShades,
            totalValue: secondsToHours(
              tripReportData?.durationByCity?.totalValue
            ) + " Hrs",
            line2: secondsToRemainingMinutesAfterHour(
              tripReportData?.durationByCity?.totalValue
            ) + " Mins"
          },
        ],
      },
      {
        chartName: "Energy Utilized",
        charts: [
          {
            title: "OEM & Model",
            data: mappedChartsEUByOemAndMOdel,
            colors: OEMChartColorShades,
            labelType: "kWh",
            selectedLabelType: "kWh",
            totalValue: tripReportData?.euByOemAndModel?.totalValue,
            noData: true,
          },
          {
            title: "City",
            data: mappedChartsEUByCity,
            colors: CityChartColorShades,
            labelType: "kWh",
            selectedLabelType: "kWh",
            totalValue: tripReportData?.euByCity?.totalValue,
            noData: true,
          },
        ],
      },
    ];

    setChartData(chartData);
  }, [tripReportData]);

  return (
    <Row className="trip-report-main-row mb-4 mt-4">
      {chartData?.map((chartItem, index) => (
        <Col xs={6} key={index}>
          <div className="trip-analytics-box-shadow-donut">
            <label className="chart-item-trip-report">
              {chartItem?.chartName}
            </label>
            <hr />
            <div className="donut-chart-box-trip-report">
              {chartItem?.charts?.map((chart, cIndex) => (
                <div key={cIndex}>
                  <label className="titele-trip-report-dount">
                    {chart?.title}
                  </label>
                  {!(chart?.data?.length > 0) ? (
                    <>
                      <div>
                        <div className="d-flex no-data-trip-analytics">
                          No data
                        </div>
                      </div>
                    </>
                  ) : (
                    <PieChartComponent
                      data={chart?.data}
                    innerRadius={50}
                    outerRadius={80}
                    width={190}
                    height={190}
                      labelType={chart?.labelType}
                      selectedLabelType={chart?.selectedLabelType}
                      totalValue={chart?.totalValue}
                      line2={chart?.line2}
                      colors={chart?.colors}
                      onClick={(args) => {
                        openOemTripReportModal(args?.name, chart?.title);
                        setTableModalName(`${chartItem.chartName} : ${args?.name}`)
                      }}
                      data-testid={`${props['data-testid']}-piechart-${chartItem?.chartName}`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Col>
      ))}
      {isShowOemTripModal && (
        <OemModelTripReport
          showModal={isShowOemTripModal}
          setIsShowModal={setisShowOemTripModal}
          title={tableModalName}
        />
      )}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  tripReportData: state.tripReportAnalytics.tripReportData,
  filter: state.tripReportAnalytics.filter,
  filterType: state.tripReportAnalytics.filterType,
  currentPage: state.tripReportAnalytics.currentPage,
});

export default connect(mapStateToProps, {
  getOemCityWiseTripReport,
  setOemCityWiseTripReportFilter,
})(GroupedDataCharts);
