import API_BOOK from "../../../service/endpoints";
import ActionTypes from "../../actionTypes";
import api from "../../../service/api";

const getMapActionTypes = ActionTypes.MAP;
const getMapConfig = API_BOOK.MAP;

const getAddressByLocationDispatcher = (payload) => ({
    type: getMapActionTypes.GET_ADDRESS_BY_LOCATION,
    payload,
});



/**
 *  @desc         Telematics API for latlong addressaction
 *  @author       Parvez Siddique
 *  @api          /map/address-by-location
 *  @method       POST
 *  @createdDate  01-Sep-2023
 *  @modifiedDate 01-Sep-2023
 **/

export const latlongToAddressAction = (req) => async (dispatch) => {
    try {
      if(!req){
        dispatch(getAddressByLocationDispatcher(req))
        return;
      }
      const resp = await api({
        method: getMapConfig.GET_ADDRESS_BY_LOCATION.method,
        url: getMapConfig.GET_ADDRESS_BY_LOCATION.url,
        data: req
      });
      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data;
        dispatch(getAddressByLocationDispatcher({response: response, coordinates :req}));
      }
    } catch (error) {
        console.log("error:", error);
    }
  };


/**
 *  @desc         Get Address by co ordinates
 *  @author       Parvez Siddique shaikh
 *  @api          /map/address-by-location
 *  @method       POST
 *  @createdDate  25-Aug-2023
 *  @modifiedDate 25-Aug-2023
 **/
 export const GetAddressByCoordinates = async({ lat, lng }) => {
  return await api({
    url: getMapConfig.GET_ADDRESS_BY_LOCATION.url,
    method: getMapConfig.GET_ADDRESS_BY_LOCATION.method,
    data: { lat, lng }
  })
}