import React from 'react';

const NoCreateGroup = () => {
  return (
    <>
      <div className="d-flex flex-row justify-content-center align-items-center mt-5">
        <img
          src="/images/svgicon/groupsIcon/createGroup.svg"
          alt="file"
          height={110}
          width={110}
        />
      </div>

      <div className="d-flex flex-row justify-content-center align-items-center">
        <span
        style={{
            color:'#C6C6C6',
            fontSize:'12px'
        }}
        
        >Create Group</span>
      </div>
    </>
  );
};

export default NoCreateGroup;