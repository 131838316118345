import React, { useState } from 'react'
import PropTypes from 'prop-types';
import styles from './SidebarFilter.module.css';
import GroupDropdown from  "./GroupFilterDropdown";

const SidebarFilter = ({
  isSidebarOpen,
  openFilterMenu,
  filters,
  socData,
  locationData,
  oemModalCountData,
  dataSourceData,
  vehicleCategoryData,
  groupsListData,
  handleFilterClick,
  handleChangeFilter,
  handleClearAll,
  handleApplyFilters,
  toggleSidebar,
  filteredVehicleData
}) => {

  const [hoverCloseIcon, setHoverCloseIcon] = useState(false);

  
  return (
    <>
      {isSidebarOpen ? (
        <>
        <div id="uniqueSidebar" className={`unique-sidebar ${isSidebarOpen ? 'open' : ''}`} style={{display:'flex'}}>
         <div className={`${styles.filterTitle}`}>
         <div className={`nd__soc-sidebar-container`}>
       

         <button
           className="nd__location-container "
           data-testid="dashboard-location-button"
           style={{
            backgroundColor: openFilterMenu === "location" ? "rgba(23, 21, 43, 1)" : "transparent", 
          }}

         >
        <div className="active-group-style" onClick={() => handleFilterClick("location")}>
           <div className={`${styles.activeGroupSection}`}>
             <img
               src="images/rightSideBar/locationImg.png"
               alt="model"
               id="location"
               height="32px"
               style={{zIndex:'9'}}
             />
            <p id="location" className={`${styles.filterTabTitle}`}>Location</p>
           </div>

           <p className={`${ openFilterMenu === "location" ? styles.filterTabCountSelected : styles.filterTabCount} ${filters.location?.length > 0 ? styles.filterTabCountActive : ''}`}>
             {filters.location?.length || 0}
           </p>
         </div>


           {openFilterMenu === "location" ? (
             <>
             <img
               className={`${styles.filterActiveBg}`}
               src="/images/rightSideBar/activeBG.svg"
               alt="activeBg"
             />

             <img
             src="/images/rightSideBar/locationSelected.svg"
             alt="activeBg"
             className={`${styles.filterSelectedCircle}`}
             />

             <img
             src="/images/rightSideBar/activeSelected.svg"
             alt="activeBg"
             className={`${styles.activeBarSelected}`}
             />
         </>
           ) : null}
          
         </button>

         <button
           className="nd__location-container "
           data-testid="dashboard-location-button"
           style={{
            backgroundColor: openFilterMenu === "vehicleCategory" ? "rgba(23, 21, 43, 1)" : "transparent", 
          }}
         >
        <div className={`${styles.ndLocationContainer}`} >
         <div className="active-group-style" onClick={() => handleFilterClick("vehicleCategory")} >
           <div className={`${styles.activeGroupSection}`}>
             <img
               src="images/rightSideBar/vechileCategory.png"
               alt="location"
               id="location"
               height="32px"
             />
             <p id="location" className={`${styles.filterTabTitle}`}>Vehicle Category</p>
           </div>

           <p className={`${ openFilterMenu === "vehicleCategory" ? styles.filterTabCountSelected : styles.filterTabCount} ${filters.vehicleCategory?.length > 0 ? styles.filterTabCountActive : ''}`} >
             {filters.vehicleCategory?.length || 0}
           </p>
         </div>
       </div>

           {openFilterMenu === "vehicleCategory" ? (
             <>
             <img
               src="/images/rightSideBar/activeBG.svg"
               alt="activeBg"
               className={`${styles.filterActiveBg}`}
             />
             <img
             src="/images/rightSideBar/locationSelected.svg"
             alt="activeBg"
             className={`${styles.filterSelectedCircle}`}
           />
           <img
           src="/images/rightSideBar/activeSelected.svg"
           alt="activeBg"
           className={`${styles.activeBarSelected}`}
         />
         </>
           ) : null}

         </button>

         <button  className="nd__location-container "
          style={{
            backgroundColor: openFilterMenu === "oemModel" ? "rgba(23, 21, 43, 1)" : "transparent", 
          }}
         >
          <div className={`${styles.ndLocationContainer}`}>
             <div className="active-group-style" onClick={() => handleFilterClick("oemModel")}>
               <div className={`${styles.activeGroupSection}`}>
                 <img
                   src="images/rightSideBar/oemModel.png"
                   alt="battery"
                   id="location"
                   height="32px"
                 />
                 <p id="location" className={`${styles.filterTabTitle}`}>OEM & Model</p>
               </div>

         <p className={`${ openFilterMenu === "oemModel" ? styles.filterTabCountSelected : styles.filterTabCount} ${filters.oemModel?.length > 0 ? styles.filterTabCountActive : ''}`}>
           {filters.oemModel?.length || 0}
         </p>
             </div>
           </div>


           {openFilterMenu === "oemModel" ? (
             <>
             <img
               src="/images/rightSideBar/activeBG.svg"
               alt="activeBg"
               className={`${styles.filterActiveBg}`}
             />
             <img
             src="/images/rightSideBar/locationSelected.svg"
             alt="activeBg"
             className={`${styles.filterSelectedCircle}`}
           />
           <img
               src="/images/rightSideBar/activeSelected.svg"
               alt="activeBg"
               className={`${styles.activeBarSelected}`}
             />
             </>
           ) : (
             ""
           )}
          
         </button>

         <button
           className="nd__location-container "
           data-testid="dashboard-dataSource-button"
           style={{
            backgroundColor: openFilterMenu === "dataSource" ? "rgba(23, 21, 43, 1)" : "transparent", 
          }}
         >
          <div className={`${styles.ndLocationContainer}`}>
             <div className="active-group-style" onClick={() => handleFilterClick("dataSource")}>
               <div className={`${styles.activeGroupSection}`}>
                 <img
                   src="images/rightSideBar/dataSource.png"
                   alt="battery"
                   id="location"
                   height="32px"
                 />
                 <p id="location" className={`${styles.filterTabTitle}`}>Data Source</p>
               </div>

               <p className={`${ openFilterMenu === "dataSource" ? styles.filterTabCountSelected : styles.filterTabCount} ${filters.dataSource?.length > 0 ? styles.filterTabCountActive : ''}`}>
                 {filters.dataSource?.length || 0}
               </p>
             </div>
           </div>


           {openFilterMenu === "dataSource" ? (
             <>
             <img
               src="/images/rightSideBar/activeBG.svg"
               alt="activeBg"
               className={`${styles.filterActiveBg}`}
             />
              <img
               src="/images/rightSideBar/locationSelected.svg"
               alt="activeBg"
               className={`${styles.filterSelectedCircle}`}
             />
             <img
             src="/images/rightSideBar/activeSelected.svg"
             alt="activeBg"
             className={`${styles.activeBarSelected}`}
           />
           </>
           ) :null}
          
         </button>

         <button
           className="nd__location-container "
           data-testid="dashboard-location-button"
           style={{
            backgroundColor: openFilterMenu === "groups" ? "rgba(23, 21, 43, 1)" : "transparent", 
          }}
         >
        <div className={`${styles.ndLocationContainer}`}>
         <div className="active-group-style" onClick={() => groupsListData.length > 0 ? handleFilterClick("groups") : null}>
           <div className={`${styles.activeGroupSection}`}>
             <img
               src="images/rightSideBar/groupImg.png"
               alt="location"
               id="location"
               height="32px"
             />
             <p id="location" className={`${styles.filterTabTitle}`}>Groups</p>
           </div>

           <p className={`${ openFilterMenu === "groups" ? styles.filterTabCountSelected : styles.filterTabCount} ${filters.groups?.length > 0 ? styles.filterTabCountActive : ''}`}>
           {filters.groups?.length || 0}
         </p>

         </div>
       </div>

           {openFilterMenu === "groups" ? (
             <>
             <img
               src="/images/rightSideBar/activeBG.svg"
               alt="activeBg"
               className={`${styles.filterActiveBg}`}
             />
             <img
             src="/images/rightSideBar/locationSelected.svg"
             alt="activeBg"
             className={`${styles.filterSelectedCircle}`}
           />
           <img
           src="/images/rightSideBar/activeSelected.svg"
           alt="activeBg"
           className={`${styles.activeBarSelected}`}
         />
         </>
           ) : null}
         
         </button>

         <button
           className="nd__soc-container"
           data-testid="dashboard-soc-button"
           style={{
            backgroundColor: openFilterMenu === "soc" ? "rgba(23, 21, 43, 1)" : "transparent", 
          }}
         >
         <div className={`${styles.ndLocationContainer}`}>
         <div className="active-group-style" onClick={() => handleFilterClick("soc")}>
           <div className={`${styles.activeGroupSection}`}>
             <img
               src="images/rightSideBar/batteryEllipse.svg"
               alt="battery"
               id="soc"
               height="32px"
             />
             <p id="soc" className={`${styles.filterTabTitle}`}>SoC</p>
           </div>

           <p className={`${ openFilterMenu === "soc" ? styles.filterTabCountSelected : styles.filterTabCount} ${filters.soc?.length > 0 ? styles.filterTabCountActive : ''}`}>
             {filters.soc?.length || 0}
           </p>
         </div>
       </div>

           {openFilterMenu === "soc" ? (
             <>
             <img
               src="/images/rightSideBar/activeBG.svg"
               alt="activeBg"
               className={`${styles.filterActiveBg}`}
             />
             <img
             src="/images/rightSideBar/locationSelected.svg"
             alt="activeBg"
             className={`${styles.filterSelectedCircle}`}
           />
           <img
           src="/images/rightSideBar/activeSelected.svg"
           alt="activeBg"
           className={`${styles.activeBarSelected}`}
         />
         </>
           ) : null}
         
         </button>
 
         <img
           src="images/rightSideBar/Bar.png"
           alt="rightSide bg img"
           className="rightSideBgImg"
         />
       </div>
         </div>
             <div className="unique-filter-options">
             <div className="unique-sidebar-header">
              <img
                className="unique-closebtn"
                onClick={toggleSidebar}
                src={hoverCloseIcon ? '/images/svgicon/trackingScreen/close_active.svg' : "/images/svgicon/trackingScreen/Close_fad.svg"}
                alt='close-btn'
                onMouseOver={() => setHoverCloseIcon(true)}
                onMouseOut={() => setHoverCloseIcon(false)}width={30} 
            />
             </div> 

           <div className="unique-filter-category">
              {openFilterMenu === "soc" ? (
                     <>
                       <div
                         id="dropdownButton"
                         data-testid="dashboard-soc-panel"
                       >
                         <RenderSocList
                           data={socData}
                           selectedSocRange={filters.soc}
                           onRangeClick={(value)=> handleChangeFilter(value,'soc')}
                         />
                       </div>
                     </>
                   ) : null}

             {openFilterMenu === "location" ? (
                 <div
                   id="dropdownButton"
                   data-testid="dashboard-location-panel"
                 >
                   <div className="nd__map_location">
                     <RenderFilterList  
                       data={locationData}
                       onClick={(value)=> handleChangeFilter(value,'location')}
                       selected={filters.location}
                     />
                   </div>
                 </div>
               ) : null}

             {openFilterMenu === "oemModel" ? (
                 <div
                   id="dropdownButton"
                   data-testid="dashboard-location-panel"
                 >
                   <div className="nd__map_location">
                     <RenderFilterList data={oemModalCountData} 
                     onClick={(value)=> handleChangeFilter(value,'oemModel')}
                     selected={filters.oemModel}
                     />
                   </div>
                 </div>
               ) : null}

             {openFilterMenu === "dataSource" ? (
                 <div
                   id="dropdownButton"
                   data-testid="dashboard-location-panel"
                 >
                   <div className="nd__map_location">
                     <RenderFilterList 
                     data={dataSourceData}
                     onClick={(value)=> handleChangeFilter(value,'dataSource')}
                     selected = {filters.dataSource} 
                     />
                   </div>
                 </div>
               ) : null}


           {openFilterMenu === "vehicleCategory" ? (
             <div
               id="dropdownButton"
               data-testid="dashboard-location-panel"
             >
               <div className="nd__map_location">
                 <RenderFilterList 
                 data={vehicleCategoryData}
                 onClick={(value)=> handleChangeFilter(value,'vehicleCategory')}
                 selected = {filters.vehicleCategory} 
                 />
               </div>
             </div>
           ) : null}

           {openFilterMenu === "groups" ? (
             <div
               id="dropdownButton"
               data-testid="dashboard-location-panel"
             >
               <div className="nd__map_location">
                 <GroupDropdown
                 groups={groupsListData}  
                 onClick={(value)=> handleChangeFilter(value,'groups')}
                 selected = {filters.groups}
                 filteredVehicleData={filteredVehicleData}
                 />
               </div>
             </div>
           ) : null}

                 </div>
                
                 <div className="unique-filter-actions">
                     <button className="unique-clear-btn" onClick={handleClearAll}>Clear All</button>
                     <button className="unique-apply-btn" onClick={handleApplyFilters}>Apply</button>
                 </div>
             </div>
         </div>
           </>
      ) : null}
    </>
  );
};

SidebarFilter.propTypes = {
    isSidebarOpen: PropTypes.bool.isRequired,
    openFilterMenu: PropTypes.string.isRequired,
    filters: PropTypes.shape({
    location: PropTypes.array,
    vehicleCategory: PropTypes.array,
    oemModel: PropTypes.array,
    dataSource: PropTypes.array,
    groups: PropTypes.array,
    soc: PropTypes.array,
  }).isRequired,
    socData: PropTypes.array.isRequired,
    locationData: PropTypes.array.isRequired,
    oemModelData: PropTypes.array.isRequired,
    dataSourceData: PropTypes.array.isRequired,
    vehicleCategoryData: PropTypes.array.isRequired,
    groupsListData: PropTypes.array.isRequired,
    handleFilterClick: PropTypes.func.isRequired,
    handleChangeFilter: PropTypes.func.isRequired,
    handleClearAll: PropTypes.func.isRequired,
    handleApplyFilters: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
};

export default SidebarFilter;

const RenderSocList = ({
  data = [],
  selectedSocRange = [],
  onRangeClick,
}) => {
  const isAllSelected = selectedSocRange.length === data.length;

  const handleRangeClick = (range) => {
    const isSelected = selectedSocRange.includes(range);
    let updatedSelection;

    if (isSelected) {
      updatedSelection = selectedSocRange.filter(item => item !== range);
    } else {
      updatedSelection = [...selectedSocRange, range];
    }

    if (onRangeClick) {
      onRangeClick(updatedSelection);
    }
  };

  const handleSelectAll = () => {
    if (selectedSocRange.length === data.length) {
      onRangeClick([]);
    } else {
      const allRanges = data.map(item => item.label);
      onRangeClick(allRanges);
    }
  };


  return (
    <div>
      <div className="nd__select-all-group" style={{paddingBottom:'8px'}}>
      <input
        type="checkbox"
        name="selectAll"
        checked={isAllSelected}
        onChange={handleSelectAll}
        className={`custom-checkbox ${isAllSelected ? "checked" : ""}`}
        style={{
          margin: "11px 6px",
          width: "11.5px",
          height: "11.5px",
          appearance: "none",
          backgroundColor: isAllSelected ? "#39FFFF" : "transparent",
          border: "1px solid #39FFFF",  
          borderRadius: "2px",
        }}
      />
       <p style={{ color: "#fff",marginBottom:'0px' }} className={`${styles.selectAllLabel}`}>Select All</p>
      </div>
      
      {data.map((item) => (
        <div className="nd__render-soc-list"
         key={item.value}
         >
          <div
            data-testid={`dashboard-soc-range-button-${item.value}`}
            className={`nd__soc-range ${selectedSocRange.includes(item.label) ? 'selected' : ''}`}
            onClick={() => handleRangeClick(item.label)}
           >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                name="socRange"
                checked={selectedSocRange.includes(item.label)}
                onChange={() => handleRangeClick(item.label)}
                className={`custom-checkbox ${selectedSocRange.includes(item.label) ? "checked" : ""}`}
                style={{
                  marginRight: "8px",
                  width: "11.5px",
                  height: "11.5px",
                  appearance: "none",
                  backgroundColor: selectedSocRange.includes(item.label) ? "#39FFFF" : "transparent",
                  border: "1px solid #39FFFF",  
                  borderRadius: "2px",
                }}
              />
              <p style={{ color: item.color }}>{item.label}</p>
            </div>

            <div>
              <p className="location-count"><span style={{ color: '#fff', marginRight: '5px' }}>----</span>{item.count}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};



const RenderFilterList = ({ data = [], onClick, selected = [] }) => {
  const allSelected = data.length > 0 && selected.length === data.length;

  const handleRangeClick = (range) => {
    const isSelected = selected.includes(range);
    if (onClick) {
      if (isSelected) {
        onClick(selected.filter((item) => item !== range));
      } else {
        onClick([...selected, range]);
      }
    }
  };

  const handleSelectAllClick = () => {
    if (onClick) {
      if (allSelected) {
        onClick([]);
      } else {
        onClick(data.map((location) => location.value)); 
      }
    }
  };

  return (
    <div>
      <div className="nd__select-all">
        <div className="nd__select-all-group">
          <input
            type="checkbox"
            name="selectAll"
            checked={allSelected}
            onChange={handleSelectAllClick}
            className={`custom-checkbox ${allSelected ? "checked" : ""}`}
            style={{
              margin: "11px 6px",
              width: "11.5px",
              height: "11.5px",
              appearance: "none",
              backgroundColor: allSelected ? "#39FFFF" : "transparent",
              border: "1px solid #39FFFF",  
              borderRadius: "2px",
            }}
          />
          <p className={`${styles.selectAllLabel}`}>Select All</p>
        </div>
      </div>

      {data.map((location, index) => (
        <div key={index} className="nd__render-location-list">
          <div
            className={`nd__location-range ${selected.includes(location.value) ? 'selected' : ''}`}
            onClick={() => handleRangeClick(location.value)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                name="locationRange"
                checked={true}
                onChange={() => handleRangeClick(location.value)}
                className={`custom-checkbox ${selected.includes(location.value) ? "checked" : ""}`}
                style={{
                  marginRight: "8px",
                  width: "11.5px",
                  height: "11.5px",
                  appearance: "none",
                  backgroundColor: selected.includes(location.value) ? "#39FFFF" : "transparent",
                  border: "1px solid #39FFFF",  
                  borderRadius: "2px",
                }}
              />
              <p style={{ color: selected.includes(location.value) ? "#A6F9FF" : "#fff" }}>
                {location.value}
              </p>
            </div>
            <div>
              <p className="location-count" style={{width:'60px'}}>
                <span style={{ color: "#fff", marginRight: "5px" }}>----</span> {location.count}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
