// RecordsContainer.js
import React, { useState } from 'react';
import MultiSelect from '../../helpers/hoc/multi-select/MultiSelect';
import { Button, Container, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { BsDownload } from 'react-icons/bs';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { GetSubsRnNumsAndOemModel } from '../../../store/actions/vehicle/vehicle';
import PropTypes from 'prop-types';
import VehicleStatusRecordsTable from './rc-records-table/VehicleStatusRecordsTable';
import VehicleSelect from './rc-records-table/vehicleSelect';
import './css/VehicleStatus.css';
import Filters from '../new-report/report-charging/child-components/Filters';
import Pagination from '../../helpers/hoc/paginator/Pagination';
import NoRecordFound from '../../helpers/hoc/noRecordFound';
import { ExportVehicleStatusReport, GetVehicleStatusReport } from '../../../store/actions/records';
import Loader from '../../helpers/hoc/loader/Loader';

const VehicleStatus = ({
	//actions
	GetSubsRnNumsAndOemModel,
	ExportVehicleStatusReport,

	//states
	subcribedRnNumsOemModel,
	vehicleData,
}) => {
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const [vehicleStatusReportData, SetVehicleStatusReportData] = useState([]);
	const [filteredVehicleStatusReportData, setFilteredVehicleStatusReportData] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isCalled, setIsCalled] = useState(false);

	const [selectedRnVehicle, setSelectedRnVehicle] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [vehicleStatus, setVehicleStatus] = useState([]);
	const [selectedVehicleStatus, setSelectedVehicleStatus] = useState(null);

	// eslint-disable-next-line no-unused-vars
	const [isRnFilterWarnEnabled, setIsRnFilterWarnEnabled] = useState(false);
	const [expandedRcVehicle, setExpandedRcVehicle] = React.useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const ITEMS_PER_PAGE = 8;

	const location = useLocation();
	const [isLocalPagination, setIsLocalPagination] = useState(false)

	useEffect(() => {
		if (subcribedRnNumsOemModel.length === 0) {
			GetSubsRnNumsAndOemModel();
		}

		if (subcribedRnNumsOemModel && subcribedRnNumsOemModel.length > 0) {
			const queryParams = new URLSearchParams(location.search);
			let selectedVehicle = subcribedRnNumsOemModel.filter((x) => x.label === queryParams.get('vehicleNumber'));
			setSelectedOptions(selectedVehicle);
		}
	}, [GetSubsRnNumsAndOemModel, subcribedRnNumsOemModel, location.search]);

	function onDateChangeHandler(value) {
		vehicleData.length = 0;
		setStartDate(value.startDate);
		setEndDate(value.endDate);
	}

	function onSelectionChange() {
		vehicleData.length = 0;
	}

	const onSubmitHandler = async () => {
		try {
			setLoading(true);
			let payload = {
				registrationNumbers: selectedOptions.map((x) => x.label),
				fromDate: startDate,
				toDate: endDate,
				countForStatus: vehicleStatus.map((x) => x.value),
				pageIndex: 0,
				pageSize: isLocalPagination ? selectedOptions?.length : ITEMS_PER_PAGE,
			};
			// Note: Merge Idle status to Live status
			if (payload.countForStatus.includes("Live")) {
				payload.countForStatus.push("Idle");
			}
			setSelectedRnVehicle(selectedOptions.map((x) => x.label));
			setSelectedVehicleStatus(vehicleStatus[0]?.value);

			let res = await GetVehicleStatusReport(payload);

			if (res.status === 200) {
				for (const vehicleStatus of res.data) {
					const matchingVehicle = subcribedRnNumsOemModel.find(
						(vehicle) => vehicle.label === vehicleStatus.registrationNumber
					);

					if (matchingVehicle) {
						vehicleStatus.oem = matchingVehicle.oem;
						vehicleStatus.model = matchingVehicle.model;
					}
				}

				if(isLocalPagination) {
					const sortedList = res.data.sort(customSort)
					SetVehicleStatusReportData(sortedList);
					const filteredVehicles = res.data.slice(
						0,
						ITEMS_PER_PAGE
					)
					setFilteredVehicleStatusReportData(filteredVehicles)
				} else {
					SetVehicleStatusReportData(res.data);
				} 
				setCurrentPage(1)
			} else {
				console.error('Error fetching data:', res.statusText);
			}
		} catch (error) {
			console.error('Error:', error.message);
		} finally {
			setLoading(false);
			setIsCalled(true);
		}
	};

	useEffect(() => {
		if (selectedOptions && selectedOptions.length > 0) { 
		const fetchData = async () => {
			try {
				setLoading(true);

				const payload = {
					registrationNumbers: selectedOptions.map((x) => x.label),
					fromDate: startDate,
					toDate: endDate,
					countForStatus: vehicleStatus.map((x) => x.value),
					pageIndex: 0,
					pageSize: isLocalPagination ? selectedOptions?.length : ITEMS_PER_PAGE,
				};

				setSelectedRnVehicle(selectedOptions.map((x) => x.label));

				const res = await GetVehicleStatusReport(payload);

				if (res.status === 200) {
					for (const vehicleStatus of res.data) {
						const matchingVehicle = subcribedRnNumsOemModel.find(
							(vehicle) => vehicle.label === vehicleStatus.registrationNumber
						);

						if (matchingVehicle) {
							vehicleStatus.oem = matchingVehicle.oem;
							vehicleStatus.model = matchingVehicle.model;
						}
					}

					if(isLocalPagination) {
						const sortedList = res.data.sort(customSort)
						SetVehicleStatusReportData(sortedList);
						const filteredVehicles = res.data.slice(
							0,
							ITEMS_PER_PAGE
						)
						setFilteredVehicleStatusReportData(filteredVehicles)
					} else {
						SetVehicleStatusReportData(res.data);
					}
					setCurrentPage(1)
				} else {
					console.error('Error fetching data:', res.statusText);
				}
			} catch (error) {
				console.error('Error:', error.message);
			} finally {
				setLoading(false);
				setIsCalled(true);
				setExpandedRcVehicle([]);
			}
		};

		fetchData();
	}	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, startDate]);

	useEffect(() => {
		// Reset currentPage to 1 when vehicleStatus changes
		setCurrentPage(1);
		setIsLocalPagination(['DEVICE'].includes(process.env.REACT_APP_ENV) && vehicleStatus?.map(x => x.value)?.includes('NonComm'))
		setSelectedVehicleStatus(vehicleStatus?.map(x => x.value)?.includes('NonComm') ? 'NonComm' : vehicleStatus[0]?.value)
	}, [vehicleStatus]);

	const handleRcVehicleExpansionClick = (recordsIndex) => {
		setExpandedRcVehicle((currentValue) => {
			if (currentValue.includes(recordsIndex)) {
				return currentValue.filter((value) => value !== recordsIndex);
			}
			return [...currentValue, recordsIndex];
		});
	};
	function exportToExcel() {
		const payload = {
			registrationNumbers: selectedOptions,
			countForStatus: vehicleStatus.map((x) => x.value),
			fromDate: startDate,
			toDate: endDate,
		}
		// Note: Merge Idle status to Live status
		if (payload.countForStatus.includes("Live")) {
			payload.countForStatus.push("Idle");
		}
		ExportVehicleStatusReport(payload) 
	  }

	function customSort(a, b) {
		const aVal = a?.statusTotalCount?.NonComm || 0; // Default value if nonComm is missing
		const bVal = b?.statusTotalCount?.NonComm || 0; // Default value if nonComm is missing
		return bVal - aVal; // Sort descending (big value first)
	}

	const onPageHandler = (pageNumber) => {
		if (!isLocalPagination && selectedOptions && selectedOptions.length > 0) { 
			const fetchData = async () => {
				try {
					setLoading(true);
	
					const payload = {
						registrationNumbers: selectedOptions.map((x) => x.label),
						fromDate: startDate,
						toDate: endDate,
						countForStatus: vehicleStatus.map((x) => x.value),
						pageIndex: pageNumber - 1,
						pageSize: ITEMS_PER_PAGE,
					};
	
					setSelectedRnVehicle(selectedOptions.map((x) => x.label));
	
					const res = await GetVehicleStatusReport(payload);
	
					if (res.status === 200) {
						for (const vehicleStatus of res.data) {
							const matchingVehicle = subcribedRnNumsOemModel.find(
								(vehicle) => vehicle.label === vehicleStatus.registrationNumber
							);
	
							if (matchingVehicle) {
								vehicleStatus.oem = matchingVehicle.oem;
								vehicleStatus.model = matchingVehicle.model;
							}
						}
	
						SetVehicleStatusReportData(res.data);
					} else {
						console.error('Error fetching data:', res.statusText);
					}
				} catch (error) {
					console.error('Error:', error.message);
				} finally {
					setLoading(false);
					setIsCalled(true);
					setExpandedRcVehicle([]);
				}
			};
	
			fetchData();
		} else {
			const filteredVehicles = vehicleStatusReportData.slice(
				(pageNumber - 1) * ITEMS_PER_PAGE,
				pageNumber * ITEMS_PER_PAGE
			)
			setFilteredVehicleStatusReportData(filteredVehicles)
		}
		setCurrentPage(pageNumber)
	}
	

	return (
		<>
			<Container fluid>
				<div className='col-12 selectLayout-historical-data'>
					<div className={`col-3 multi-select-width ${!selectedOptions[0] ? 'no-value-selected' : ''}`}>
						<MultiSelect
							setNoDataWarning={isRnFilterWarnEnabled}
							options={subcribedRnNumsOemModel}
							selectedOptions={selectedOptions}
							setSelectedOptions={setSelectedOptions}
							isMultiSelect={true}
							onChange={onSelectionChange}
							placeholderColor='#C6C6C6'
							customBackgroundColor={{ backgroundColor: selectedOptions[0] ? '#FFFFFF' : 'red' }}
							data-testid='vehicle-filter'
						/>
					</div>
					<div className={` col-2 multi-select-width ${!vehicleStatus[0] ? 'no-value-selected' : ''}`}>
						<VehicleSelect
							setNoDataWarning={isRnFilterWarnEnabled}
							placeholder='Select Status Type'
							options={subcribedRnNumsOemModel}
							selectedOptions={vehicleStatus}
							setSelectedOptions={setVehicleStatus}
							isMultiSelect={true}
							onChange={onSelectionChange}
							placeholderColor='#C6C6C6'
							menuListPaddingX='px-0'
							customBackgroundColor={{ backgroundColor: vehicleStatus[0] ? '#FFFFFF' : 'red' }}
							data-testid='status-filter'
						/>
					</div>
					<div>
						<Button
							data-testid='get-report-button'
							className='bttn height'
							id='getReport'
							type='submit'
							onClick={onSubmitHandler}
							disabled={!selectedOptions?.length || !startDate || !endDate || !vehicleStatus?.length}
						>
							Get Report
						</Button>
					</div>

					<div>
						<button
							data-testid='export-button'
							disabled={
								!vehicleStatusReportData?.length ||
								!selectedOptions?.length ||
								!endDate ||
								!startDate ||
								!vehicleStatus?.length
							}
							className={
								!vehicleStatusReportData?.length ||
								!selectedOptions?.length ||
								!endDate ||
								!startDate ||
								!vehicleStatus?.length
									? 'dwn-btn dwn-btn-disabled'
									: 'dwn-btn'
							}
							onClick={() => {
								exportToExcel()
							  }}
						>
							<BsDownload style={{width:'22px',height:'22px'}} />
						</button>
					</div>
					<div className='date__picker col-4'>
						<Filters onDateChangeHandler={onDateChangeHandler} data-testid='vehicle-status-date-filters'></Filters>
					</div>
				</div>

				{isCalled && (
					<div className='row p-3 mb-2 mt-2 overflow-group'>
						{!vehicleStatusReportData?.length ? (
							<NoRecordFound></NoRecordFound>
						) : (
							<>
								<Table responsive borderless className='mt-3' id='Rc__Records__table__data'>
									{
										!isLocalPagination && (
											<tbody>
												{
												vehicleStatusReportData.map((records, recordsIndex) => (
													<VehicleStatusRecordsTable
													onRcVehicleClick={() => handleRcVehicleExpansionClick(recordsIndex)}
													isRcVehicleExpanded={expandedRcVehicle.includes(recordsIndex)}
													recordsData={records}
													vehicleStatus={selectedVehicleStatus}
													/>
												))}
											</tbody>
										)
									}

									{
										isLocalPagination && (
											<tbody>
												{
													filteredVehicleStatusReportData.map((records, recordsIndex) => (
													<VehicleStatusRecordsTable
													onRcVehicleClick={() => handleRcVehicleExpansionClick(recordsIndex)}
													isRcVehicleExpanded={expandedRcVehicle.includes(recordsIndex)}
													recordsData={records}
													vehicleStatus={selectedVehicleStatus}
													/>
												))}
											</tbody>
										)
									}
									
								</Table>
								<Pagination
									disabled={false}
									totalPages={Math.ceil((selectedRnVehicle.length || 0) / ITEMS_PER_PAGE)}
									currentPage={currentPage}
									setPage={onPageHandler}
									data-testid='vehicle-status-pagination'
								/>
							</>
						)}
					</div>
				)}
				<Loader isLoading={isLoading} />
			</Container>
		</>
	);
};

VehicleStatus.propTypes = {
	vehicleData: PropTypes.array.isRequired,
	subcribedRnNumsOemModel: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	GetSubsRnNumsAndOemModel: PropTypes.func.isRequired,
	ExportVehicleStatusReport: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
	vehicleData: state.recordReducer.vehicleStatusData,
	subcribedRnNumsOemModel: state.vehicle.subcribedRnNumsOemModel,
	isLoading: state.loader.isLoading,
});
export default connect(mapStateToProps, { GetSubsRnNumsAndOemModel ,ExportVehicleStatusReport})(VehicleStatus);
