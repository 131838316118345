import { Component } from 'react';
import PropTypes from 'prop-types'
import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import ChartArea from '../../helpers/hoc/chart-area/ChartArea'; 
import ChargingOemAndCityChart from '../../pages/new-report/report-charging/child-components/charging-oem-city-chart/ChargingOemCityChart'; 
import { GetChargingAnalytics } from '../../../store/actions/reports/charging-analytics'; 
import { Row, Col, Container } from "react-bootstrap";
import Filters from '../../pages/new-report/report-charging/child-components/Filters';
import Loader from '../../helpers/hoc/loader/Loader'; 


class ChargingAnalyticsGUI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date : null,
        }
    }
    
    onDateChange = async ({ startDate, endDate}) => {
        this.setState({isLoading : true, date :{
            "fromDate": startDate,
            "toDate": endDate
        }})
        this.props.getChargingAnalytics({
            "fromDate": startDate,
            "toDate": endDate,
            intervalDuration: this.calculateIntervalDuration(startDate,endDate)
        })
    }
    
    calculateIntervalDuration = (fromDate, toDate) => {
        const from = new Date(fromDate)
        const to = new Date(toDate)
        const diff = Math.abs(to - from)
        const minutes = Math.floor((diff / 1000) / 60)
        
        //for less than 1 day return 1 hr
        if (minutes < 1440) return 30
        //for less than 3 days return 4 hrs
        else if (minutes < 4320) return 120
        //for less than 7 days return 12 hrs
        else if (minutes < 10080) return 720
        //for less than 16 days return 1 day
        else if (minutes < 23040) return 1440
        //for greater than 16 days return 2 day
        return 2880
    }
    render() {
        return <>
            <Row>
                <Col>
                <div className="col">
                <Container fluid className="analytics-container">
                                {/* <div className='heading-gt'style={{
                                    font: 'normal normal 600 18px/39px Inter',
                                     letterSpacing: '0px', color: '#1F2A5D', fontSize: 16, fontWeight: 'medium'}}>Analytics</div> */}
                                <Row className="trip-analytics-box-shadow">
                                    <Col xs={12} className="row trip-analytics-main-container-main">
                                        <Filters onDateChangeHandler={this.onDateChange} data-testid='charge-date-filters'></Filters>
                                        <br></br>
                                        <ChargingOemAndCityChart chartData={this.props.pieChartData} date={this.state.date} />
                                        <br></br>
                                        <ChartArea name='charging' chartData={this.props.areaChartData} data-testid='charging-chartarea'></ChartArea>
                                    </Col>
                                </Row>
                            </Container>
                    </div>
                    <Loader isLoading={this.props.isLoading}></Loader>
                </Col>
            </Row>

        </>
    }
}

ChargingAnalyticsGUI.propTypes = {
    isLoading: PropTypes.bool.isRequired,
  }

const mapStateToProps = (state) =>
({
    areaChartData: state.chargingAnalytics.areaChartData,
    pieChartData: state.chargingAnalytics.pieChartData,
    isLoading: state.loader.isLoading,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getChargingAnalytics: GetChargingAnalytics
    },
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(ChargingAnalyticsGUI)