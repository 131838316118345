import React, { useState } from 'react'

import { NewDarkDashboard_AlertTypes, 
  // NewDashboard_Battery_status 
} from '../../../../../utils/constants'

import './NDtrackingAlertCard.css'

const NDtrackingAlertCard = ({ data = [], selectedAlert = '', onActiveAlertChange }) => {
  const handleAlertChange = (alert, label) => {
    if (onActiveAlertChange) {
      onActiveAlertChange(alert, label)
    }
  }
	const [isHovered, setIsHovered] = useState(false);


  return (
    <>
    <div className={`nd___dark-tracking-alert-card-container ${isHovered ? 'nd__dark-info-can-status-card-half-border' : '' }`}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
          
    >
      <p style={{ color: '#33E8FF', marginLeft:'25px', fontSize:'14px' }}>Driving Alert</p>

      <div className='nd___dark-tracking-alert-card mb-3'>
        {
          NewDarkDashboard_AlertTypes.map((item, index) => {
            const filteredDataLength = data?.filter(x => item.key === x.alarmType).length;

            return (
              <div key={`alert_card_${index}`} className={`nd__tac-container`}>
                <button
                  className='nd__tac-button'
                  onClick={() => {
                    handleAlertChange(item.key, item.label);
                  }}
                  data-testid={`vehicle-info-alarm-chip-${item.key}`}
                  style={{ cursor: 'pointer' }}
                >
                  <div className='nd__tac-button-container'>
                    <div className='tac__outer-circle'>
                      <div className='tac__inner-circle'>{filteredDataLength}</div>
                    </div>
                    <p className='tac__inner-circle_label'>{item.label}</p>
                  </div>
                </button>
              </div>
            );
          })
        }

      </div>

{/* 
      <p style={{ color: '#33E8FF', marginLeft:'25px', fontSize:'14px' }}>Battery Alert</p>
      <div className='nd___dark-tracking-alert-card mb-3'>
        {
          NewDashboard_Battery_status.map((item, index) => {
            const filteredDataLength = data?.filter(x => item.key === x.alarmType).length;

            return (
              <div key={`alert_card_${index}`} className={`nd__tac-container ${['Harsh Braking', 'Harsh Acceleration'].includes(item.key) ? 'nd__tac-container--disabled' : ''}`}>
                <button
                  className='nd__tac-button'
                  onClick={() => {
                    if (['Harsh Braking', 'Harsh Acceleration'].includes(item.key) || filteredDataLength === 0) {
                      return null;
                    }
                    handleAlertChange(item.key);
                  }}
                  data-testid={`vehicle-info-alarm-chip-${item.key}`}
                  style={{ cursor: ['Harsh Braking', 'Harsh Acceleration'].includes(item.key) || filteredDataLength === 0 ? 'not-allowed' : 'pointer' }}
                >
                  { item.label !== 'Battery Over Heat' && <div className='nd__tac-button-container'>
                    <div className='tac__outer-circle'>
                      <div className='tac__inner-circle'>{ item.label !== 'Battery Over Heat' && filteredDataLength}</div>
                    </div>
                    <p className='tac__inner-circle_label'>{ item.label !== 'Battery Over Heat' && item.label}</p>
                  </div>}
                </button>
              </div>
            );
          })
        }
      </div> */}
      </div>
    </>
  )
}

export default NDtrackingAlertCard