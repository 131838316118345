import React from "react";
import "./TermsandConditions.css";
import { Table } from 'react-bootstrap';

const TermsandConditions = () => {

    const handleAccept = () => {
        window.close()
      };

  return (
    <div className="container">
      <h3 className="heading">Terms and Conditions</h3>
      <div className="content">
      <p>
  <span className="company-heading">
    Magenta EV Solutions Private Limited (Magenta/ Company/ We/ Us/ Our )
  </span>
  is inter-alia engaged in the business of electric vehicles, and EV mobility services through its fleet of EVs, software, and other
  allied clean energy projects through the e-commerce websites and mobile applications (App) (collectively
  the Services) both developed and owned by <span className="company-heading">Magenta</span> and/or its parent company and/or its affiliates
  (Website and App collectively referred to as  <span className="company-heading">“Platform”</span>) to conduct its business.
</p>
  <p>    
  In this Policy, and wherever so relevant, the words ‘we’, ‘us’ and ‘our’ shall be deemed to mean Magenta,
and the words ‘its’, ‘you’ and ‘your’ shall, wherever the context so requires, mean and include the User
using our website.
</p>
   <p>                           
   By using our services, you (the User) are hereby consenting to the collection of your information by us
and to the terms of this Policy.
  </p>
<p>We have designed this privacy policy (Policy) to provide greater transparency into our privacy practices
and principles in a format that is easy to navigate, read and understand. This Policy describes the
treatment of information collected, safeguards, and treats the personal information of its Users through
any usage of the Magenta website:<a href="https://www.magentamobility.com">www.magentamobility.com</a><span className="company-heading">(Website) </span>and/ or app. The term Website
and/ or App (defined below) shall mean and include any service operated by us (or on our behalf) that
may be displayed on various online, mobile, or other platforms and environments, including those
operated by third parties, which permits us to share information with all those who access it on the
internet. This Policy applies to anyone who uses Magenta’s Services. The User is deemed to have given
his/her/its consent to this Policy by using Magenta’s Services.
</p>
<p>By way of this Policy, we request our Users to acquaint themselves with the practices that are followed by
Magenta to safeguard personal information.</p>
<p className="head">ROLES WE PLAY</p>

<p>We play the role of a Data Controller when we collect and process Personal Data about you.
We play the role of a Data Processor when we collect and process Personal Data on behalf of another
Data Controller.
</p>
<p className="head">OUR COMMITMENT</p>
<p>We strive our best to ensure that our Policy is in complete conformity with Applicable Law.</p>
<p className="head">INFORMATION GATHERED BY MAGENTA</p>
<p>By visiting the Website and/or by availing any of Magenta’s Services, you agree that we can collect the
following information from you:</p>
<ol>

  <p className="head">
  A. Personal and Identifiable Information (PII) and Personal Data (PD):</p>
  <li>PII and PD are such information that can be used to identify any person (whether legal or
natural) and shall include without limitation name, email, mobile number, phone number, street,
city, state, pin-code, country, location information, and IP address.
</li>
<p className="head">B. Transactional Information:</p>
<li>Details such as transaction amount, the name of your bank, and the type of card used. It is
clarified that we do not store any details pertaining to your security question for accessing your
bank account, nor the CVV number on your card(s). You are, however, required to confirm the
details stored by any third-party operator whose services you use when accessing this website. We
do not make any representations on behalf of such third parties and your usage of this website is
subject to this specific condition, besides others, that you hold us harmless from any and all harm
suffered by you as a result of using such third-party services when accessing this website, which
usage is voluntary by you
</li>
<p className="head">C. Telematics Information:</p>
<ol>
    <li>Vehicle Registration Number</li>
    <li>OEM & Model</li>
    <li>Chassis number</li>
    <li>Motor Number</li>
    <li>Current location of the vehicle</li>
    <li>State of Charge</li>
    <li>State of Health</li>
    <li>Battery voltage</li>
    <li>Battery Temperature</li>
    <li>Battery Current</li>
    <li>Battery Remaining Capacity</li>
    <li>Range Left/Drivable Kms</li>
    <li>Odometer</li>
    <li>Speed of Vehicle</li>
    <li>Ignition Status</li>
    <li>BFNR Details (Drive Mode)</li>
    <li>Vehicle mode</li>
    <li>Last data received timestamp</li>
    <li>Last Data sent timestamp</li>
    <li>Last Location Updated Timestamp</li>
    <li>Harsh Acceleration Intimation</li>
    <li>Harsh Braking Intimation</li>
    <li>Harsh Cornering Intimation</li>
    <li>Overspeed Intimation</li>
    <li>Other Vehicle Motor & Battery related data</li>
  </ol>
  </ol>
  <p className="head">DIFFERENT WAYS OF COLLECTING INFORMATION</p>
  <li>A. Information Collected Directly From Any User Or Visitor:
We collect information directly from visitors or Users when they visit the Website or avail any
Services from Magenta. Please do note that information de facto collected if you post any
comment on our Website or make an enquiry via email or phone.</li>
<li>B. Information Collected Passively:
We use tracking tools to gather and run analytics data like browser cookies and web beacons for
collecting information about Visitors and Users of Magenta’s Products and Services.</li>
<li>C. Information From Other Sources:
We might receive information about you from other sources that include any usage data details
such as data about how you use our Website or web-based properties, pages viewed, etc.
</li>
  <p className="head">
  PURPOSES OF GATHERING YOUR PERSONAL DATA</p>
  <li>We use your personal information to operate, provide, develop, and improve our products and services.
These purposes include:
  </li>
  <ol type="A">
    <li>Purchase and delivery of products and Services</li>
    <li>Provide functionality, troubleshooting, and improving the Website and our Services</li>
    <li>Recommendations and personalization</li>
    <li>Develop insight for future products</li>
    <li>For brand communications</li>
    <li>Marketing and advertising purposes</li>
    <li>To resolve consumer issues pertaining to warranty and other Services</li>
    <li>For purposes of research and development</li>
    <li>To research and analyze to maintain, protect, develop and improve our Services</li>
    <li>Communicate with you</li>
  </ol>
  <p className="head">LAWFUL BASES OF PROCESSING YOUR PERSONAL DATA</p>
  <p>
    We are permitted to process your personal data in compliance with applicable laws and regulations by relying on one or more of the following lawful bases:
  </p>
  <ul>
    <li>You have explicitly agreed to us processing your Personal Data for a specific reason.</li>
    <li>The processing is necessary to perform the agreement we have with you or to take steps to enter into an agreement with you.</li>
    <li>The processing is necessary to be in compliance with our legal obligations.</li>
    <li>The processing is necessary for the purposes of a “Legitimate Interest” pursued by us, such as “to provide services to you, to evaluate, develop or improve our products and services”.</li>
  </ul>
  <p>By using the website and/ or by providing your information, you consent to the collection and use of the
information you disclose on the website in accordance with this Policy, including but not limited to your
consent for sharing your information as per this Policy.
</p>
<p>Despite the fact that we have developed security procedures and safeguard measures, we cannot guarantee
security of information or data shared by you or any other visitors passively or actively with us. All Users
and visitors agree that they will not hold Magenta and/ or any other person (whether legal or natural)
claiming on its behalf liable or responsible for any breach of security unless it can be prived beyond
reasonable doubt that Magenta has been grossly negligent in performing its obligations under applicable
laws.
</p>

<p>Our Application relies on map versions provided by Google maps as authorized for use by the Government of the Republic of India. However, it is expressly set out that in certain cases, the maps used/provided may not reflect recent country, state and/ or district boundaries globally published or as are under dispute. While we aim to deliver accurate information, hereby clarify that there are certain limitations in this regard which may be faced by us. We are committed to enhancing your mapping experience and options for future updates. Your use of the application is express acceptance of these conditions.</p>

<p className="head">PERSONAL DATA DISCLOSURE</p>
<p>Magenta website contains third-party SDKs that collect personally identifiable information (PII). They
use this information to better target users or provide elements of our products and services on behalf of
us. These third-party sites have separate and independent privacy policies.
</p>
<p className="head">MAGENTA USES THREE TYPES OF SDKS:</p>
<Table className="table-container">
    <thead>
      <tr>
        <th>SDK</th>
        <th>Purpose</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Analytics</td>
        <td>
          <ul>
            <li>Analyze in-depth detail about the visitors on our website</li>
            <li>Settle errors</li>
            <li>Better target users</li>
            <li>Provide push notifications and digital ads to the users</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Payment</td>
        <td>
          <ul>
            <li>Complete customers’ payment transaction</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Login</td>
        <td>
          <ul>
            <li>Help user’s login at MAGENTA</li>
          </ul>
        </td>
      </tr>
    </tbody>
  </Table>
<p>Below mentioned are some of the third-party service providers used by our application:</p>
<ul>
    <li>Firebase Crashlytics</li>
    <li>Facebook</li>
    <li>Google</li>
    <li>Appsflyer</li>
    <li>Gamooga</li>
    <li>Razorpay</li>
    <li>PayU</li>
    <li>Clevertap</li>
    <li>Paytm</li>
    <li>HDFC Bank</li>
    <li>BillDesk</li>
    <li>Kapture CRM</li>
    <li>Netcore</li>
    <li>Daffodil Software Ltd.</li>
    <li>WhatsApp</li>
    <li>Adobe</li>
    <li>Other third-party data or software that may be required for the Products or Services.</li>
  </ul>
  <p>For using any services offered by a third-party service provider on our website, you expressly agree that
the privacy policy of such third-party service provider has been read and agreed by you independently and
that Magenta will not have any liability whatsoever in this regard.</p>
<p>The security, integrity, and confidentiality of your information are extremely important to us. We have
implemented technical, administrative, and physical security measures that are designed to protect your
information from unauthorized access, disclosure, use, and modification. From time to time, we review
our security procedures to consider appropriate new technology and methods. However, please be aware
that despite our best efforts, no security measures are perfect or impenetrable.
</p>
<p>However, we may be required to share the aforesaid information with government authorities and
agencies for the purposes of verification of identity or for prevention, detection, and investigation
including cyber incidents, prosecution, and punishment of offenses. Your use of our website expressly
specifies that you agree and consent that we may disclose your information stored by us in accordance
with this Policy if so required under Applicable Law.
</p>
<p className="head">Reasons for disclosing your personal data to other parties:</p>
  <ul>
    <li>Need to provide you with products or services</li>
    <li>Where you have initiated a payment</li>
    <li>Have a legal obligation to do so, e.g., to assist with detecting and preventing fraud</li>
    <li>Have a requirement in connection with regulatory reporting, litigation, or asserting or defending legal rights and interests</li>
    <li>Have a legitimate business reason for doing so</li>
    <li>Want to send you information and promotional material regarding our products and services</li>
    <li>Have asked you for your consent to share it, and you’ve agreed</li>
    <li>Improve your online experience for using our product</li>
  </ul>
  <p className="head">To whom your personal data may be disclosed:</p>
  <ul>
    <li>Other group companies and any subcontractors, agents, or service providers who work for us or provide services to us or group companies (including their employees, sub-contractors, service providers, directors, and officers)</li>
    <li>Law Enforcement Authorities, Government Authorities, courts, dispute resolution bodies, our regulators, auditors, and any party appointed or requested by our regulators to carry out investigations or audits of our activities</li>
    <li>Statutory and regulatory bodies and authorities (including the government) investigating agencies and entities or persons, to whom or before whom it is mandatory to disclose the personal data as per the applicable law, courts, judicial and quasi-judicial authorities and tribunals, arbitrators and arbitration tribunals</li>
    <li>Overseas regulators</li>
    <li>Anybody else that we’ve been instructed to share your Personal Data with by you</li>
  </ul>
  <p className="head">RETENTION AND DISPOSAL OF DATA</p>
  <p>We keep the personal data we collect about you on our systems or with third parties for as long as it is
required for the purposes set out in this Policy and for legal or regulatory reasons. We shall take
reasonable steps to delete or permanently de-identify Personal Data that is no longer needed.
</p>
<p className="head">GOVERNING LAW AND JURISDICTION</p>
<p>We follow this Policy in accordance with applicable laws, including but not limited to the Information
Technology Act, 2000 and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. It is clarified that for the purposes of this
website, the laws of the Republic of India shall be applicable law and the Courts of Navi Mumbai shall
have exclusive jurisdiction to try any and all matters arising out of or in connection with the Magenta
Policy and/or Website (Applicable Law)
</p>
<p className="head">CHANGES TO THIS POLICY</p>
<p>From time to time, we may change this Policy to accommodate new technologies, industry practices,
regulatory requirements, or for other purposes. This Policy shall be updated accordingly, and we request
you to read it every time before using this website. We take no responsibility whatsoever to apprise you of
any change in this Policy.
</p>
<p className="head">CONTACT US</p>
<p>
  For any further queries and complaints related to privacy under applicable laws and regulations, you could reach us at:
  <br />
  Contact E-mail Address:{" "}
  <a href="mailto:itsupport.team@magentamobility.com">
    itsupport.team@magentamobility.com
  </a>
</p>
<p className="head">
  IN ACCORDANCE WITH INFORMATION TECHNOLOGY ACT 2000 AND RULES MADE
  THERE UNDER, THE NAME AND CONTACT DETAILS OF THE GRIEVANCE OFFICER ARE PROVIDED BELOW
</p>
<ul>
  <li>GRIEVANCE OFFICER</li>
  <li>Name: Shashank Sathe</li>
  <li>
    E-mail:{" "}
    <a href="mailto:shashank.sathe@magentamobility.com">
      shashank.sathe@magentamobility.com
    </a>
  </li>
</ul>
<p className="head">NOTIFICATION OF CHANGES</p>
<p>We keep our Policy under regular review to make sure it is up to date and accurate. Any changes we may
make to this Policy in the future will be posted on this page. We recommend that you re-visit this page
regularly to check for any updates. This Policy is effective from the date of its publication by Magenta.
This Policy is subject to change and amendment from time to time for any reason, including to keep the
Policy in compliance with applicable laws. Users and visitors are requested to visit this page periodically
since we shall not send out any notification of an amendment to this Policy</p>

<button className="accept-button" onClick={handleAccept}>
        Accept
      </button>
      </div>
      
    </div>
  );
};

export default TermsandConditions;
