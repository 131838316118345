import api from "../../../service/api";
import API_BOOK from "../../../service/endpoints";
import ActionTypes from "../../actionTypes";
const {
  CA_FLOW: { GET_ALL_CUSTOMER_ADMIN_LOCATION_SUCCESS, GET_ALL_CUSTOMER_ADMIN_LOCATION_FAILED, GET_ALL_MASTER_LOCATIONS_SUCCESS, GET_ALL_MASTER_LOCATIONS_FAILED },
} = ActionTypes;

const { CUSTOMER_ADMIN: { GET_ALL_CUSTOMER_ADMIN_LOCATIONS, CREATE_CUSTOMER_ADMIN_LOCATIONS, UPDATE_LOCATION } } = API_BOOK


const getCustomerAdminSuccessAD = (payload) => ({
    type: GET_ALL_CUSTOMER_ADMIN_LOCATION_SUCCESS,
    payload
})
const getCustomerAdminFailedAD = () => ({
    type: GET_ALL_CUSTOMER_ADMIN_LOCATION_FAILED
})

const getAllMasterLocationSuccessAD = (payload) => ({
    type: GET_ALL_MASTER_LOCATIONS_SUCCESS,
    payload
})
const getAllMasterLocationFailedAD = () => ({
    type: GET_ALL_MASTER_LOCATIONS_FAILED
})

 
 /**
 *  @desc         Norminc get all master locations
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /customeradmin/getMasterLocation
 *  @method       GET
 *  @createdDate  28-March-2024
 *  @modifiedDate 28-March-2024
 **/

 export const GetAllMasterLocations = () => async (dispatch) => {
    try {
      const res = await api({
        url: API_BOOK.GET_ALL_MASTER_LOCATIONS.url,
        method: API_BOOK.GET_ALL_MASTER_LOCATIONS.method,
      });
      if(res.status === 200) {
        dispatch(getAllMasterLocationSuccessAD(res?.data?.data))
      }
      else {
        dispatch(getAllMasterLocationFailedAD())
      }
  
    } catch (error) {
      console.log("GetAllMasterLocations", error);
    }
  };
  
  /**
   *  @desc         Norminc get all locations for customer admin
   *  @author       Nilesh Bharat Salunkhe
   *  @api          /customeradmin/getAllLocation
   *  @method       POST
   *  @createdDate  28-March-2024
   *  @modifiedDate 28-March-2024
   **/
  
  export const GetAllCustomerAdminLocations = ({data}) => async (dispatch) => {
    try {
      const { searchKey, pageNumber, pageSize, sortByColumn, sortOrder  } = data 
      const res = await api({
        url: GET_ALL_CUSTOMER_ADMIN_LOCATIONS.url,
        method: GET_ALL_CUSTOMER_ADMIN_LOCATIONS.method,
        data: {
          searchKey, 
          pageNumber, 
          pageSize,
          sortByColumn,
          sortOrder
        }
      });

      if(res.status === 200) {
        dispatch(getCustomerAdminSuccessAD(res?.data?.data))
      }
    
      else {
        dispatch(getCustomerAdminFailedAD())
      }

    } catch (error) {
      console.log("GetAllCustomerAdminLocations", error);
    }
  };
  
  /**
   *  @desc         Norminc customer admin location
   *  @author       Nilesh Bharat Salunkhe
   *  @api          /customeradmin/createlocation
   *  @method       POST
   *  @createdDate  28-March-2024
   *  @modifiedDate 28-March-2024
   **/
  
  export const CreateCustomerAdminLocation = async ({state, city}) => {
    try {
      const res = await api({
        url: CREATE_CUSTOMER_ADMIN_LOCATIONS.url,
        method: CREATE_CUSTOMER_ADMIN_LOCATIONS.method,
        data: {
          state,
          city,
          cid: city
        }
      });
      return res
  
    } catch (error) {
      console.log("CreateCustomerAdminLocation", error);
    }
  };

  /**
   *  @desc         Customer Manage -> Location Update-Location Status.
   *  @author       Nilesh Bharat Salunkhe
   *  @api          /customers/update-location-status
   *  @method       PUT
   *  @createdDate  17-May-2024
   *  @modifiedDate 17-May-2024
   **/
  
  export const UpdateLocation = async ({locationId, isActive}) => {
    try {
      const res = await api({
        url: UPDATE_LOCATION.url,
        method: UPDATE_LOCATION.method,
        data: { locationId, isActive }
      });
      return res
  
    } catch (error) {
      console.log("UpdateLocation", error);
    }
  };
  /*conflict-resolution-will be removed later*/