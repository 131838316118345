import ActionTypes from '../actionTypes'
const { STOP_LOADING, START_LOADING } = ActionTypes.LOADER
const initialState = {
  isLoading: false,
}

const loaderReducer = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default loaderReducer
