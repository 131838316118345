import { useState, useMemo, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BsChevronDown } from "react-icons/bs";
import { SingleVehicleOnboard } from "../../../../../store/actions/vehicleOnboarding";
import { GetUserRoleList } from "../../../../../store/actions/roleAndMenuMapping";
import "./SingleVehOnboardingModal.css";
import "../../../../signup/signup.css";
import {
  ChargingTypes,
  RtoStateCodeList,
  VehicleOemAndModels,
  VehicleTypes,
} from "../../../../../utils/constants";
import {
  CheckFieldErrors,
  countFormData,
} from "../../../../../utils/validation/form-validator";
import { addEscapeKeyListener } from "../../../../../utils/common-methods";
import SingleSelect from "../../../../helpers/hoc/single-select/SingleSelect";

const SingleVehOnboardingModal = ({
  showSVOModal,
  setIsShowSVOModal,
  SingleVehicleOnboard,
  isUploadApiSuccess,
  vehicleOnboardingApiError,
  GetUserRoleList,
  masterLocationData,
}) => {
  const initialFieldValues = {
    registrationNumber: "",
    chassisNumber: "",
    motorNumber: "",
    oemAndModel: "",
    type: "",
    purchaseDate: "",
    vehicleBaseLocation: "",
    chargingType:""
  };
  const initialFieldErrors = {
    ...initialFieldValues,
    empty: false,
  };

  const initialRnFormatValues = {
    stc: [],
    dtc: "",
    rtoNo: "",
    no: "",
  };

  const [formData, setFormData] = useState(initialFieldValues);
  const [vehicleNumber, setVehicleNumber] = useState(initialRnFormatValues);
  const [errorFields, setErrorFields] = useState(initialFieldErrors);
  const { oemAndModel, type, vehicleBaseLocation, chargingType } = formData;
  const { stc, dtc, rtoNo, no } = vehicleNumber;

  useEffect(() => {
    GetUserRoleList();
  }, [GetUserRoleList]);

  useMemo(() => {
    if (isUploadApiSuccess) {
      setIsShowSVOModal(false);
    }
  }, [isUploadApiSuccess, setIsShowSVOModal]);

  const handleClose = useCallback(() => {
    setIsShowSVOModal(false);
  }, [setIsShowSVOModal]);

  const selectHandler = (data) => {
    onRNChangeHandler([data], true);
  };

  const onRNChangeHandler = (evt, isSelectChange = false) => {
    let name;
    let value;

    if (isSelectChange) {
      name = "stc";
      value = evt;
    } else {
      name = evt.target?.name;
      value = evt.target?.value?.trim();
    }

    switch (name) {
      case "stc":
        setVehicleNumber({
          ...vehicleNumber,
          [name]: value,
        });
        if (value[0] && dtc && rtoNo && no) {
          let vDtc = dtc;
          if (rtoNo?.length < 3) {
            vDtc = dtc.padStart(2, "0");
          }
          const vno = no.padStart(4, "0");
          setFormData({
            ...formData,
            registrationNumber: value[0]?.id + vDtc + rtoNo + vno,
          });
        }
        break;
      case "dtc": {
        if (value.length === 0 || value === "0") {
          value = "";
          setVehicleNumber({
            ...vehicleNumber,
            [name]: value,
          });
          setFormData({
            ...formData,
            registrationNumber: "",
          });
          break;
        }
        const regex = /^[0-9]+$/;
        if (value.match(regex)) {
          if (Number(value) > 0 && Number(value) <= 99) {
            if (rtoNo?.length > 2) {
              value = value?.startsWith("0") ? value.substr(1) : value;
            }
            setVehicleNumber({
              ...vehicleNumber,
              [name]: value,
            });
            if (stc[0] && value && rtoNo && no) {
              let vDtc = value;
              if (rtoNo?.length < 3) {
                vDtc = dtc.padStart(2, "0");
              }
              const vno = no.padStart(4, "0");
              setFormData({
                ...formData,
                registrationNumber: stc[0]?.id + vDtc + rtoNo + vno,
              });
            }
          }
        }
        break;
      }

      case "rtoNo": {
        if (value.length === 0) {
          setVehicleNumber({
            ...vehicleNumber,
            [name]: value,
          });
          setFormData({
            ...formData,
            registrationNumber: "",
          });
          break;
        }
        const regex = /^[a-zA-Z]+$/;
        if (value.match(regex)) {
          value = value.toUpperCase();
          setVehicleNumber({
            ...vehicleNumber,
            [name]: value,
          });
          if (stc[0] && dtc && value && no) {
            let vDtc = dtc;
            if (value?.length < 3) {
              vDtc = dtc.padStart(2, "0");
            } else if (value?.length > 2) {
              vDtc = vDtc?.startsWith("0") ? vDtc?.substr(1) : vDtc;
            }
            const vno = no.padStart(4, "0");
            setFormData({
              ...formData,
              registrationNumber: stc[0]?.id + vDtc + value + vno,
            });
          }
        }
        break;
      }

      case "no": {
        if (value.length === 0) {
          setVehicleNumber({
            ...vehicleNumber,
            [name]: value,
          });
          setFormData({
            ...formData,
            registrationNumber: "",
          });
          break;
        }
        const regex = /^[0-9]+$/;
        if (value.match(regex)) {
          if (Number(value) > 0 && Number(value) <= 9999) {
            setVehicleNumber({
              ...vehicleNumber,
              [name]: value,
            });
            if (stc[0] && dtc && rtoNo && value) {
              value = value.padStart(4, "0");
              let vDtc = dtc;
              if (rtoNo?.length < 3) {
                vDtc = dtc.padStart(2, "0");
              }
              setFormData({
                ...formData,
                registrationNumber: stc[0]?.id + vDtc + rtoNo + value,
              });
            }
          }
        }
        break;
      }
      default:
        break;
    }
  };

  const onChangeHandler = (evt) => {
    const name = evt.target?.name;
    let value = evt.target?.value?.trim();
    console.log(name, value);

    if (["chassisNumber"].includes(name)) {
      const alphaNumRegex = /^([A-Za-z]|[0-9])+$/;
      if (value.match(alphaNumRegex)) {
        value = value.toUpperCase();
      } else if (value.length !== 0) {
        return;
      }
    }

    if (
      !["chassisNumber", "motorNumber"].includes(name) &&
      evt.type === "change"
    ) {
      CheckFieldErrors(
        { fieldName: name, value },
        {},
        errorFields,
        setErrorFields
      );
    }

    if (
      ["chassisNumber", "motorNumber"].includes(name) &&
      evt.type === "blur"
    ) {
      CheckFieldErrors(
        { fieldName: name, value },
        {},
        errorFields,
        setErrorFields
      );
    }

    if (name === "type") {
      setFormData({
        ...formData,
        oemAndModel: "",
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const isValidForm = (form) => {
    // fill up all the fields
    if (!(countFormData(form) === Object.keys(form).length)) {
      setErrorFields({
        ...errorFields,
        empty: true,
      });
      setTimeout(() => {
        setErrorFields({
          ...errorFields,
          empty: false,
        });
      }, 2000);
      return false;
    }

    if (
      countFormData(form) === Object.keys(form).length &&
      countFormData(errorFields) === 0
    ) {
      return true;
    }
  };

  const onSaveHandler = (event) => {
    event.preventDefault();
    if (isValidForm(formData)) {
      SingleVehicleOnboard({
        data: {
          ...formData,
          location: formData.vehicleBaseLocation,
          oem: VehicleOemAndModels?.find(
            (val) => val.id === formData.oemAndModel
          )?.oem,
          model: VehicleOemAndModels?.find(
            (val) => val.id === formData.oemAndModel
          )?.model,
          registration_number: formData.registrationNumber,
          chassis_number: formData.chassisNumber,
          motor_number: formData.motorNumber,
          charging_type: formData.chargingType
        },
      });
    }
  };
  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      handleClose();
    });

    return () => {
      removeListener();
    };
  }, [handleClose]);

  return (
    <>
      <Modal
        show={showSVOModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Single Vehicle Entry</Modal.Title>
          <div className="d-flex flex-row justify-content-center">
            <form className="col-12" onSubmit={onSaveHandler}>
              <div className="d-flex flex-column col-12 px-1">
                <div className="d-flex flex-row justify-content-center ">
                  <div className="col-5 form-label-group required">
                    <div className="d-flex flex-row ">
                      <div className="col-2.5 me-1">
                        <label
                          htmlFor="stc"
                          className="formLabel-single-vehicle"
                        >
                          St. Code
                        </label>
                        <div>
                          <SingleSelect
                            placeholder=""
                            placeholderOnError=""
                            valueContainerWidthInPx="75px"
                            valueContainerMaxWidthInPx="75px"
                            optionPosition="ps-2 justify-content-start"
                            options={RtoStateCodeList}
                            selectedOptions={stc}
                            onSelectHandler={selectHandler}
                            maxMenuHeight={140}
                            customBackgroundColor="#E7ECF7 "
                            data-testid="state-select"
                            minHeight="48px"
                          />
                        </div>
                      </div>

                      <div className="col-3 me-1">
                        <label
                          htmlFor="dtc"
                          className="formLabel-single-vehicle"
                        >
                          Dist. Code
                        </label>
                        <div>
                          <input
                            type="text"
                            id="dtc"
                            name="dtc"
                            className={`form-control Single-Vehicle-inputfields`}
                            maxLength={2}
                            value={dtc}
                            onChange={onRNChangeHandler}
                            data-testid="dtc-input"
                          />
                        </div>
                      </div>

                      <div className="col-3 me-1">
                        <label
                          htmlFor="rtoNo"
                          className="formLabel-single-vehicle"
                        >
                          RTO Series
                        </label>
                        <div>
                          <input
                            type="text"
                            id="rtoNo"
                            maxLength={3}
                            className={`form-control Single-Vehicle-inputfields`}
                            name="rtoNo"
                            value={rtoNo}
                            onChange={onRNChangeHandler}
                            data-testid="rto-input"
                          />
                        </div>
                      </div>

                      <div className="col-2.5 me-1">
                        <label
                          htmlFor="no"
                          className="formLabel-single-vehicle"
                        >
                          No.
                          <span className="asterisk-red">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            id="no"
                            className={`form-control px-2 Single-Vehicle-inputfields`}
                            maxLength={4}
                            name="no"
                            value={no}
                            onChange={onRNChangeHandler}
                            data-testid="no-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-5 form-label-group required ms-5">
                    <label
                      htmlFor="type"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Vehicle Type
                      <span className="asterisk-red">*</span>
                    </label>
                    <div className="dropdown mt-1">
                      <select
                        name="type"
                        id="type"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.type ? "is-invalid" : ""
                        }`}
                        value={type}
                        onChange={onChangeHandler}
                        data-testid="type-select"
                      >
                        <option key={"ty01"} value=""></option>
                        {VehicleTypes?.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-testid={`type-option-${option.label}`}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="dropdown-arrow">
                        <BsChevronDown size={16} />
                      </div>
                    </div>
                    {errorFields.type && (
                      <div className="text-danger error-message">
                        {errorFields.type}
                      </div>
                    )}
                  </div>
                </div>
                {formData.registrationNumber.length > 0 && (
                  <div className="registration-number-container">
                    Vehicle Reg No: {formData.registrationNumber}
                  </div>
                )}

                <div className="d-flex flex-row justify-content-center  mt-1">
                  <div className="col-5 form-label-group required me-5 mt-2">
                    <label
                      htmlFor="chargingType"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Charging Type
                      <span className="asterisk-red">*</span>
                    </label>

                    <div className="dropdown">
                      <select
                        name="chargingType"
                        id="chargingType"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields?.chargingType ? "is-invalid" : ""
                        }`}
                        value={chargingType}
                        onChange={onChangeHandler}
                        data-testid="oem-model-select"
                      >
                        <option
                          key={"chargingType01"}
                          value=""
                          data-testid="oem-model-option-default"
                        ></option>
                        {ChargingTypes.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-testid={`charging-type-option-${option.label}`}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="dropdown-arrow">
                        <BsChevronDown size={16} />
                      </div>
                    </div>
                    {errorFields.chargingType && (
                      <div className="text-danger error-message">
                        {errorFields.chargingType}
                      </div>
                    )}
                  </div>
                  <div className="col-5 form-label-group required mt-2">
                    <label
                      htmlFor="chassisNumber"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Chassis No.
                      <span className="asterisk-red">*</span>
                    </label>

                    <div>
                      <input
                        type="text"
                        id="chassisNumber"
                        name="chassisNumber"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.chassisNumber ? "is-invalid" : ""
                        }`}
                        maxLength={17}
                        value={formData.chassisNumber}
                        onChange={onChangeHandler}
                        onBlur={onChangeHandler}
                        data-testid="chassis-input"
                      />
                      {errorFields.chassisNumber && (
                        <div className="text-danger error-message">
                          {errorFields.chassisNumber}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-5 form-label-group required mt-2">
                  <label htmlFor="purchaseDate" className="formLabel-single-vehicle mb-0">
                      Date of Purchase
                      <span className='asterisk-red'>*</span>
                    </label>

                    <div>
                      <input
                        type="date"
                        id="purchaseDate"
                        placeholder=""
                        name="purchaseDate"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.purchaseDate ? 'is-invalid' : ''
                        }`}
                        value={formData.purchaseDate}
                        onChange={onChangeHandler}
                        data-testid='purchase-date-input'
                      />
                      {errorFields.purchaseDate && (
                        <div className="text-danger error-message">
                          {errorFields.purchaseDate}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>

                <div className="d-flex flex-row justify-content-center ">
                  <div className="col-5 d-flex flex-column form-label-group required me-5 mt-2">
                    <label
                      htmlFor="purchaseDate"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Date of Purchase.
                      <span className="asterisk-red">*</span>
                    </label>

                    <div>
                      <input
                        type="date"
                        id="purchaseDate"
                        placeholder=""
                        name="purchaseDate"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.purchaseDate ? "is-invalid" : ""
                        }`}
                        value={formData.purchaseDate}
                        onChange={onChangeHandler}
                        data-testid="purchase-date-input"
                      />
                      {errorFields.purchaseDate && (
                        <div className="text-danger error-message">
                          {errorFields.purchaseDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-5 d-flex flex-column form-label-group required mt-2">
                    <label
                      htmlFor="motorNumber"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Motor No.
                      <span className="asterisk-red">*</span>
                    </label>

                    <div>
                      <input
                        type="text"
                        id="motorNumber"
                        name="motorNumber"
                        maxLength={21}
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.motorNumber ? "is-invalid" : ""
                        }`}
                        value={formData.motorNumber}
                        onChange={onChangeHandler}
                        onBlur={onChangeHandler}
                        data-testid="motor-number-input"
                      />
                      {errorFields.motorNumber && (
                        <div className="text-danger error-message">
                          {errorFields.motorNumber}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-5 d-flex flex-column form-label-group required mt-2 ">
                  <label
                      htmlFor="vehicleBaseLocation"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Location
                      <span className='asterisk-red'>*</span>
                    </label>
                    <div className="dropdown">
                      <select
                        name="vehicleBaseLocation"
                        id="vehicleBaseLocation"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.vehicleBaseLocation ? 'is-invalid' : ''
                        }`}
                        value={vehicleBaseLocation}
                        onChange={onChangeHandler}
                        data-testid='location-select'
                      >
                        <option key={'loc01'} value=""></option>
                        {masterLocationData?.map((option) => (
                          <option key={option.id} value={option.id} data-testid={`location-option-${option.label}`}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="dropdown-arrow">
                        <BsChevronDown size={16} />
                      </div>
                    </div>
                    {errorFields.vehicleBaseLocation && (
                      <div className="text-danger error-message">
                        {errorFields.vehicleBaseLocation}
                      </div>
                    )}
                  </div> */}
                </div>

                <div className="d-flex flex-row justify-content-center ">
                  <div className="col-5 d-flex flex-column form-label-group required me-5 mt-2">
                    <label
                      htmlFor="vehicleBaseLocation"
                      className="formLabel-single-vehicle mb-0"
                    >
                      Location
                      <span className="asterisk-red">*</span>
                    </label>
                    <div className="dropdown">
                      <select
                        name="vehicleBaseLocation"
                        id="vehicleBaseLocation"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.vehicleBaseLocation ? "is-invalid" : ""
                        }`}
                        value={vehicleBaseLocation}
                        onChange={onChangeHandler}
                        data-testid="location-select"
                      >
                        <option key={"loc01"} value=""></option>
                        {masterLocationData?.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-testid={`location-option-${option.label}`}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="dropdown-arrow">
                        <BsChevronDown size={16} />
                      </div>
                    </div>
                    {errorFields.vehicleBaseLocation && (
                      <div className="text-danger error-message">
                        {errorFields.vehicleBaseLocation}
                      </div>
                    )}
                  </div>

                  <div className="col-5 d-flex flex-column form-label-group required mt-2">
                    <label
                      htmlFor="oemAndModel"
                      className="formLabel-single-vehicle mb-0"
                    >
                      OEM & Model
                      <span className="asterisk-red">*</span>
                    </label>
                    <div className="dropdown mt-1">
                      <select
                        name="oemAndModel"
                        id="oemAndModel"
                        className={`form-control Single-Vehicle-inputfields ${
                          errorFields.oemAndModel ? "is-invalid" : ""
                        }`}
                        value={oemAndModel}
                        onChange={onChangeHandler}
                        data-testid="oem-model-select"
                      >
                        <option
                          key={"oem01"}
                          value=""
                          data-testid="oem-model-option-default"
                        ></option>
                        {type
                          ? VehicleOemAndModels?.filter(
                              (val) => val.typeId === type
                            )?.map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                data-testid={`oem-model-option-${option.label}`}
                              >
                                {option.label}
                              </option>
                            ))
                          : VehicleOemAndModels?.map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                data-testid={`oem-model-option-${option.label}`}
                              >
                                {option.label}
                              </option>
                            ))}
                      </select>
                      <div className="dropdown-arrow">
                        <BsChevronDown size={16} />
                      </div>
                    </div>
                    {errorFields.oemAndModel && (
                      <div className="text-danger error-message">
                        {errorFields.oemAndModel}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex flex-column  justify-content-center align-items-center">
                  <span className="form-field">
                    {errorFields.empty && (
                      <label className="text-danger error-message">
                        Please fill up all the fields
                      </label>
                    )}
                  </span>

                  {vehicleOnboardingApiError && (
                    <div className="error-msg">
                      <label className="text-danger error-message text-wrap">
                        {vehicleOnboardingApiError}
                      </label>
                    </div>
                  )}

                  <div className="d-flex justify-content-center align-items-center flex-row  ">
                    <button
                      type="submit"
                      className="modal-btn save-btn me-5 "
                      data-testid="single-save-button"
                    >
                      Save
                    </button>

                    <button
                      onClick={() => {
                        handleClose();
                      }}
                      className="modal-btn cancel-btn"
                      data-testid="single-cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

SingleVehOnboardingModal.propTypes = {
  SingleVehicleOnboard: PropTypes.func.isRequired,
  isUploadApiSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  vehicleOnboardingApiError: state.vehOnboarding.vehicleOnboardingApiError,
  isUploadApiSuccess: state.vehOnboarding.isUploadApiSuccess,
  masterLocationData: state.getAllUserRoleReducer.masterLocationData,
});

export default connect(mapStateToProps, {
  SingleVehicleOnboard,
  GetUserRoleList,
})(SingleVehOnboardingModal);
