import { clearLocalStorageExceptclientIP } from '../utils/common-methods'
import axios from './axios'

const setAuthToken = ({ token, refreshToken, user }) => {
  if (token && user && refreshToken) {
    axios.defaults.headers.Authorization = `Bearer ${token}`
    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('rtoken', refreshToken)
  } else {
    delete axios.defaults.headers.Authorization
    clearLocalStorageExceptclientIP()
  }
}

export default setAuthToken
