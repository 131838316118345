import ActionTypes from "../../actionTypes";

const dashboardGroupsAction = {}

dashboardGroupsAction.setEditGroupID = ({ subGroupId, subGroupIndex }) => ({
    type: ActionTypes.DASHBOARD_GROUPS.SET_EDIT_GROUP_DATA,
    payload: { subGroupId, subGroupIndex }
})

dashboardGroupsAction.resetEditGroupID = () => ({
    type: ActionTypes.DASHBOARD_GROUPS.RESET_EDIT_GROUP_DATA
})

dashboardGroupsAction.setEditSubgroup = ({ subGroupId, subGroupIndex }) => ({
    type: ActionTypes.DASHBOARD_GROUPS.SET_EDIT_SUBGROUP_DATA,
    payload: { subGroupId, subGroupIndex }
})

dashboardGroupsAction.resetEditSubgroup = () => ({
    type: ActionTypes.DASHBOARD_GROUPS.RESET_EDIT_SUBGROUP_DATA,
})

// Action to set group data in store for edit journey
dashboardGroupsAction.setGroupsData = (groupData) => ({
    type: ActionTypes.DASHBOARD_GROUPS.SET_GROUP,
    payload: groupData,
})

// Action to create a new group and set data in store
dashboardGroupsAction.createNewGroup = ({ name, vehicleIds = [] , adminIds=[]}) => ({
    type: ActionTypes.DASHBOARD_GROUPS.CREATE_NEW_GROUP,
    payload: { name, vehicleIds, adminIds }
})

dashboardGroupsAction.resetGroupsData = () => ({
    type: ActionTypes.DASHBOARD_GROUPS.RESET_GROUP,
})

// Action to create a new subgroup and set data in store
dashboardGroupsAction.createNewSubgroup = ({ name, vehicleIds = [] }) => ({
    type: ActionTypes.DASHBOARD_GROUPS.CREATE_NEW_SUBGROUP,
    payload: { name, vehicleIds }
})

// Action to remove vehicles from either a group or subgroup
dashboardGroupsAction.removeVehiclesFromGroup = ({vehicleIds, isSubGroup = false, subgroupId, subgroupIndex}) => ({
    type: ActionTypes.DASHBOARD_GROUPS.REMOVE_VEHICLES_FROM_GROUP,
    payload: { vehicleIds, isSubGroup, subgroupId, subgroupIndex }
})

// Action to add vehicles to either a group or subgroup
dashboardGroupsAction.addVehiclesToGroup = ({vehicleIds, isSubGroup = false, subgroupId, subgroupIndex}) => ({
    type: ActionTypes.DASHBOARD_GROUPS.ADD_VEHICLES_TO_GROUP,
    payload: { vehicleIds, isSubGroup, subgroupId, subgroupIndex }
})

// Action to add DepartmentAdmin to either a group or subgroup
dashboardGroupsAction.addDepartmentAdminToGroup = ({assignees}) => ({
    type: ActionTypes.DASHBOARD_GROUPS.ADD_ADMIN_TO_GROUP,
    payload: {assignees}
})

// Action to reset the states
dashboardGroupsAction.resetFunction = () => ({
    type: ActionTypes.DASHBOARD_GROUPS.RESET_STATES
})

dashboardGroupsAction.setActiveVehicles = ({ activeVehicleList }) => ({
    type: ActionTypes.DASHBOARD_GROUPS.SET_ACTIVE_VEHICLES,
    payload: activeVehicleList
})

dashboardGroupsAction.resetActiveVehicles = () => ({
    type: ActionTypes.DASHBOARD_GROUPS.RESET_ACTIVE_VEHICLES
})
export default dashboardGroupsAction ;