import { Button, Row, Col, Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import SingleSelect from '../../../helpers/hoc/single-select/SingleSelect'
import { loadSavedFenceOwners, loadSavedLabelOwners, saveFenceOwners, openFenceOwnerCard } from '../../../../store/actions/geo-fence/parameterConfigurationActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FenceOwnerEditRow from './FenceOwnerEditRow';
import { customToast } from '../../../../utils/toasts'
import { sentenceCompare } from '../../../../utils/common-methods';
import  API_BOOK  from '../../../../service/endpoints'
import api from '../../../../service/api';

const geoFenceCheckIfFenceOwnerIsUsedConfig = API_BOOK.GEO_FENCE.MASTERS.CHECK_IF_FENCE_OWNER_IS_USED

const AddFenceOwner = (
    {
        loadSavedLabelOwners,
        loadSavedFenceOwners,
        openFenceOwnerCard,
        saveFenceOwners,
        labelOwnersSaved,
        fenceOwnersSaved,
        fenceOwnerCardOpened
    }
)=> {
    const [fenceOwnerName, setFenceOwnerName] = useState('')
    const [fenceOwnersEditableData, setFenceOwnersEditableData] = useState([])
    const [labelOwnersDropdownValues, setLabelOwnersDropdownValues] = useState([])
    const [selectedLabelOwnerIdForAdd, setSelectedLabelOwnerIdForAdd] = useState({})
    const [deletedFenceOwnerIds, setDeletedFenceOwnerIds] = useState([])
    const [isLabelOwnerSelectionValid, setLabelOwnerSelectionValid] = useState(true)
    const [isFenceOwnerNameValid, setFenceOwnerNameValid] = useState(true)
    const [labelOwnerSelectionValidationMessage, setLabelOwnerSelectionValidationMessage] = useState('')
    const [fenceOwnerNameValidationMessage, setFenceOwnerNameValidationMessage] = useState('')
    const [isChanged, setIsChanged] = useState(false)


    useEffect(()=>{
        loadSavedLabelOwners()
        loadSavedFenceOwners()
    },[loadSavedLabelOwners, loadSavedFenceOwners])

    useEffect(()=>{
        if(fenceOwnerCardOpened)
            loadSavedFenceOwners()
    },[fenceOwnerCardOpened, loadSavedFenceOwners])

    useEffect(()=>{
        const dropdownValues = labelOwnersSaved.map(labelOwner => { 
            return {
            label: labelOwner.label,
            value: labelOwner._id
            }
        })
        setLabelOwnersDropdownValues(dropdownValues)
    }, [labelOwnersSaved])


    useEffect(()=>{
        if(fenceOwnersSaved)
        {
            const fenceOwnersData = fenceOwnersSaved.map((x, index)=> {
                return {
                    _id: x._id,
                    fenceOwnerName: x.label,
                    labelOwnerId: x.parent_id,
                    isEditable: false,
                    isFenceOwnerNameValid: true,
                    isLabelOwnerSelectionValid: true,
                    fenceOwnerNameValidationMessage: '',
                    labelOwnerSelectionValidationMessage: ''
                }
            })
            setFenceOwnersEditableData(fenceOwnersData)
        }
        
    }, [fenceOwnersSaved])

    const selectHandler = (data) => {
        setSelectedLabelOwnerIdForAdd(data.value)
        setLabelOwnerSelectionValid(true)
    }

    const handleLabelOwnerNameChange = (e)=>{
        setFenceOwnerName(e.target.value)
        setFenceOwnerNameValid(true)
    }

    const addNewFenceOwner = ()=>{
        let isValid = true
        if(Object.keys(selectedLabelOwnerIdForAdd).length === 0){
            setLabelOwnerSelectionValid(false)
            setLabelOwnerSelectionValidationMessage ('Label owner is required')
            isValid = false
        }

        if(fenceOwnerName === ''){  
            setFenceOwnerNameValid(false)
            setFenceOwnerNameValidationMessage('Fence owner name is required')
            isValid = false
        }

        if(!isValid)    
            return

        const existing = fenceOwnersEditableData
            .find(x=>sentenceCompare(x.fenceOwnerName, fenceOwnerName) && x.labelOwnerId === selectedLabelOwnerIdForAdd)

        if(existing){
            setFenceOwnerNameValid(false)
            setFenceOwnerNameValidationMessage('Fence owner is already present')
            return
        }

        const newFenceOwner = {
            _id: '',
            fenceOwnerName: fenceOwnerName,
            labelOwnerId: selectedLabelOwnerIdForAdd
        }
        setFenceOwnersEditableData([...fenceOwnersEditableData, newFenceOwner])
        setFenceOwnerName('')
        setSelectedLabelOwnerIdForAdd('')
        setIsChanged(true)
    }

    const save = ()=> {
        let fenceOwnersEditableDataCopy = [...fenceOwnersEditableData]
        let isValid = true

        if(fenceOwnersEditableDataCopy.length===0){
            customToast.error('Please add at least one fence owner')
            return
        }
        fenceOwnersEditableDataCopy = fenceOwnersEditableDataCopy.map((item)=> {
            const fenceOwner = {...item}
            if(!fenceOwner.isEditable){
                return {
                    ...fenceOwner
                }
            }
            if(fenceOwner.fenceOwnerName === ''){
                fenceOwner.isFenceOwnerNameValid = false
                fenceOwner.fenceOwnerNameValidationMessage = 'Fence owner name is required'
                isValid = false
            }
            if(Object.keys(fenceOwner.labelOwnerId).length === 0){
                fenceOwner.isLabelOwnerSelectionValid = false
                fenceOwner.labelOwnerSelectionValidationMessage = 'Label owner is required'
                isValid = false
            }
            const existing = fenceOwnersEditableDataCopy
                .filter(x=>sentenceCompare(x.fenceOwnerName, fenceOwner.fenceOwnerName) && x.labelOwnerId === fenceOwner.labelOwnerId)
            if(existing && existing.length>1){  
                fenceOwner.isFenceOwnerNameValid = false
                fenceOwner.fenceOwnerNameValidationMessage = 'Duplicate fence owners are not allowed'
                isValid = false
            }

            return {
                ...fenceOwner
            }
        })

        if(!isValid){
            setFenceOwnersEditableData([...fenceOwnersEditableDataCopy])
            return
        }

        saveFenceOwners({
            savedFenceOwners: fenceOwnersEditableData.map((fenceOwner) => ({ ...fenceOwner, fenceOwnerName: fenceOwner.fenceOwnerName.trim() })),
            deletedFenceOwnerIds: deletedFenceOwnerIds
        })
    }

    const checkIfFenceOwnerIsUsed = async (fenceOwner, key)=> {
        if(fenceOwner._id === ''){
            return false
        }
        try {
            const resp = await api({
                method: geoFenceCheckIfFenceOwnerIsUsedConfig.method,
                url: geoFenceCheckIfFenceOwnerIsUsedConfig.url + `/${fenceOwner._id}`
              });
            if (resp.status === 200) {
                const response = resp?.data.data;
                return response
            }
          } catch (error) {
            console.log("error:", error);
        }
    }

    return (

        <div className={`toggle-container-fence-owner shadow-sm p-3 mb-3 bg-white ${fenceOwnerCardOpened ? 'open' : ''}`}>
            <div className='add-label-font-configure-fence-owner' data-testid='fenceowner-collapse-toggle' onClick={() => openFenceOwnerCard(!fenceOwnerCardOpened)}>Add Fence Owner</div>
            <div className="toggle-button" data-testid='fenceowner-collapse-toggle' onClick={() => openFenceOwnerCard(!fenceOwnerCardOpened)}>
                {
                    fenceOwnerCardOpened ?
                    <img
                    alt={'add'}
                    src='/images/svgicon/arrow-up.svg'
                    style={{width:17, height:17}}
                    ></img>
                    :
                    <img
                    className='configure-arrow-down'
                    alt={'add'}
                    src='/images/svgicon/arrow-down.png'
                    ></img>
                }
            </div>
            <div className="tab-content" style= {{marginBottom:67, display: fenceOwnerCardOpened ? 'block': 'none'}}>
                <Row className=''>
                    <Col>
                    {
                    fenceOwnersEditableData && fenceOwnersEditableData.map((fenceOwner, index) => (
                        <FenceOwnerEditRow 
                            rowKey={index}
                            preSelectedLabelOwnerId={fenceOwner.labelOwnerId}
                            fenceOwnerName={fenceOwner.fenceOwnerName}
                            isEditable={fenceOwner.isEditable}
                            isFenceOwnerNameValid={fenceOwner.isFenceOwnerNameValid}
                            isLabelOwnerSelectionValid={fenceOwner.isLabelOwnerSelectionValid}
                            fenceOwnerNameValidationMessage={fenceOwner.fenceOwnerNameValidationMessage}
                            labelOwnerSelectionValidationMessage={fenceOwner.labelOwnerSelectionValidationMessage}
                            onDelete = {async (key)=> {
                                const newItems = [...fenceOwnersEditableData];
                                const deleted = newItems.splice(key, 1)
                                const isUsed = await checkIfFenceOwnerIsUsed(deleted[0], key)
                                if(isUsed){
                                    setTimeout(() => {
                                        customToast({ message: 'Fence Owner is already used', svgImageName: 'decline' })
                                    }, 400)
                                    return
                                }
                                setFenceOwnersEditableData([...newItems])
                                setDeletedFenceOwnerIds([...deletedFenceOwnerIds, deleted[0]])
                                setIsChanged(true)
                            }}
                            labelOwnerOnChange={(newLabelOwnerId, key)=> {
                                const fenceOwnersEditableDataCopy = fenceOwnersEditableData
                                const fenceOwner = {...fenceOwnersEditableDataCopy[key]}
                                fenceOwner.isLabelOwnerSelectionValid = true
                                fenceOwner.labelOwnerId = newLabelOwnerId
                                fenceOwnersEditableDataCopy[key] = fenceOwner
                                setFenceOwnersEditableData([...fenceOwnersEditableDataCopy])
                            }}
                            fenceOwnerNameOnChange={(newFenceOwnerName, key)=> {
                                const fenceOwnersEditableDataCopy = fenceOwnersEditableData
                                const fenceOwner = {...fenceOwnersEditableDataCopy[key]}
                                fenceOwner.isFenceOwnerNameValid = true
                                fenceOwner.fenceOwnerName = newFenceOwnerName
                                fenceOwnersEditableDataCopy[key] = fenceOwner
                                setFenceOwnersEditableData([...fenceOwnersEditableDataCopy])
                            }}
                            setEdit={(key)=>{
                                const fenceOwnersEditableDataCopy = fenceOwnersEditableData
                                const fenceOwner = {...fenceOwnersEditableDataCopy[key]}
                                fenceOwner.isEditable = true
                                fenceOwnersEditableDataCopy[key] = fenceOwner
                                setFenceOwnersEditableData([...fenceOwnersEditableDataCopy])
                                setIsChanged(true)
                            }}
                        >
                        </FenceOwnerEditRow>
                    ))
                }


        <Row>
            <Col className='geofence-configure-sub-text-fence-owner-text' xs={2}>
                Label Owner
            </Col>
            <Col xs={2}>
                <SingleSelect
                data-testid='fenceowner-labelowner-select'
                placeholder=""
                placeholderOnError=""
                valueContainerWidthInPx="75px"
                valueContainerMaxWidthInPx="75px"
                singleValueColor="#1F2A5D"
                optionPosition="ps-2 justify-content-start"
                options={labelOwnersDropdownValues}
                selectedOptions={
                    labelOwnersDropdownValues && labelOwnersDropdownValues.filter(option => 
                       option.value === selectedLabelOwnerIdForAdd)
                 }
                onSelectHandler={selectHandler}
                maxMenuHeight={140}
                customBackgroundColor="#F5F7F9"
                valueContainerJustifyContent="flex-start"
                />
                {
                    !isLabelOwnerSelectionValid &&
                    <div className='text-danger error-message'>{labelOwnerSelectionValidationMessage}</div>
                }
            </Col>

            <Col className='geofence-configure-sub-text-fence-owner-text' xs={2}>
                Fence Owner Name
            </Col>
            <Col xs={2}>
                <Form.Group controlId="textBox-fence">
                    <Form.Control 
                    data-testid='fenceowner-name-input'
                    value={fenceOwnerName}
                    onChange={handleLabelOwnerNameChange}
                    type="text" 
                    placeholder="Enter text" />
                </Form.Group>
                {
                    !isFenceOwnerNameValid &&
                    <div className='text-danger error-message'>{fenceOwnerNameValidationMessage}</div>
                }
            </Col>
            <Col  xs={2} className="d-flex geofence-configure-sub-text-fence-owner">
                <div data-testid='fenceowner-add-button' onClick={addNewFenceOwner}>
                    <img
                    alt={'add'}
                    src='/images/svgicon/add_button.svg'
                    style={{width:"30%", marginLeft:10, marginRight:5}}
                    ></img>
                    Add          
                </div>
            </Col>
        </Row>
                    </Col>
                </Row>
                
            

        
                
            </div>
            <Row style={{marginBottom: 15, display: fenceOwnerCardOpened ? 'block': 'none'}}>
            <Col className="d-flex flex-row justify-content-center " style={{ display: fenceOwnerCardOpened ? 'block': 'none'}}>
                <Button 
                disabled={!isChanged}
                className='save-btn-configure-fence-owner save-action-button'
                onClick={save}
                data-testid='fenceowner-save-button'
                >
                    Save
                </Button>
            </Col>
        
        </Row>
        </div>
    )
}

AddFenceOwner.propTypes = {
	// Actions
	loadSavedLabelOwners: PropTypes.func.isRequired,
    saveFenceOwners: PropTypes.func.isRequired,
    openFenceOwnerCard: PropTypes.func.isRequired,
	// States
    labelOwnersSaved: PropTypes.array.isRequired,
    fenceOwnersSaved: PropTypes.array.isRequired,
    fenceOwnerCardOpened: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	loadSavedLabelOwners,
    loadSavedFenceOwners,
    openFenceOwnerCard,
    saveFenceOwners,
    labelOwnersSaved: state.parameterConfiguration.labelOwnersSaved,
    fenceOwnersSaved: state.parameterConfiguration.fenceOwnersSaved,
    fenceOwnerCardOpened: state.parameterConfiguration.fenceOwnerCardOpened
});

export default connect(mapStateToProps, {
	loadSavedLabelOwners,
    loadSavedFenceOwners,
    saveFenceOwners,
    openFenceOwnerCard,
})(AddFenceOwner);
