import ActionTypes from '../../actionTypes'
const { GET_ALL_DATA_FAIL, GET_ALL_DATA_SUCCESS } = ActionTypes.REPORTS.CHARGING

const initialState = {
  allChargingSessions: [],
  dataCount: 0,
}

const chargingSessionsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_DATA_SUCCESS:
      return {
        ...state,
        allChargingSessions: payload.rows,
        dataCount: payload.count,
      }
    case GET_ALL_DATA_FAIL:
      return {
        ...state,
        allChargingSessions: [],
        dataCount: 0,
      }
    default:
      return state
  }
}

export default chargingSessionsReducer
