import api from "../../service/api";
import API_BOOK from "../../service/endpoints";
import { CipherGenerator } from "../../utils/crypto";
import { customToast } from '../../utils/toasts'

const forgotPasswordUserApiConfig = API_BOOK.FORGOT_PASSWORD;


/* Actions starts here */

/**
 *  @desc         Register User action
 *  @author       Shuvendu Prasad Naik
 *  @api          /user
 *  @method       POST
 *  @createdDate  23-may-2023
 *  @modifiedDate 23-May-2023
 **/

export const ForgotPasswordUser = ({
  email,
  password,
  location, navigate, navigateFrom }) => async (dispatch) => {
    try {
      const hashPassword = CipherGenerator(password)
      const resp = await api({
        method: forgotPasswordUserApiConfig.method,
        url: forgotPasswordUserApiConfig.url,
        data: {
          email,
          location,
          password: hashPassword
        }
      });
      if (resp.status === 200) {
        if (typeof (navigate) === 'function') {
          navigate(navigateFrom, { replace: true });
        }
      }
      if(resp.status === 400 && resp.data.error === 'The new password should not be same as last 3 passwords') {
        setTimeout(() => {
          customToast({ message: 'New password not be same as last 3 passwords', svgImageName: 'decline' })
        }, 400)
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

export const ResetPassword = async({
  email,
  password,
  location }) => {
    try {
      const hashPassword = CipherGenerator(password)
      const resp = await api({
        method: forgotPasswordUserApiConfig.method,
        url: forgotPasswordUserApiConfig.url,
        data: {
          email,
          location,
          password: hashPassword
        }
      });
      return resp;
    } catch (error) {
      console.log("error:", error);
    }
  };



/* Actions ends here */ 