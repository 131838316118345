import React from 'react'
import { Table, OverlayTrigger } from 'react-bootstrap'
import Pagination from '../../../helpers/hoc/paginator/Pagination'
import './geofanceAlert.css'
import {
  getFormattedDate,
  secondsToHoursAndMinutes,
} from '../../../../utils/common-methods'
import NoRecordFound from '../../../helpers/hoc/noRecordFound'

const RowButton = ({ renderTooltip, fenceOwner, labelOwner, label }) => (
  <OverlayTrigger
    placement="right"
    delay={{ show: 0, hide: 0 }}
    overlay={renderTooltip({
      parameter1: fenceOwner,
      parameter2: labelOwner,
      parameter3: label,
    })}
  >
    <button className="row-btn  alert-recent-btn">
      <img
        src="/images/svgicon/info.svg"
        alt="info"
        style={{ width: 14, cursor: 'pointer' }}
      />
    </button>
  </OverlayTrigger>
)

const Row = ({ renderTooltip, recent, index, currentPage, ITEMS_PER_PAGE }) => (
  <tr >
    <td className="alert-recent-td">
      <span className="alert-recent-td-span">
        {' '}
        {index +
          1 +
          ((currentPage - 1) * 10 + (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
          '.'}
      </span>
    </td>
    <td className="alert-recent-td">{recent.registration_number}</td>
    <td className="alert-recent-td">{`${recent.oem} ${recent.model}`}</td>
    <td className="alert-recent-td">
      {recent.fence_name}
      <RowButton
        renderTooltip={renderTooltip}
        fenceOwner={recent.fenceOwner}
        labelOwner={recent.labelOwner}
        label={recent.label}
      />
    </td>
    <td className="alert-recent-td">{recent.alertType}</td>
    <td className="alert-recent-td">
      <span
        className="alert-recent-td-span"
        style={{
          color: recent.alertType === 'Ingress' ? '#E20909' : '#1F2A5D',
        }}
      >
        {recent.ingress_date_time
          ? getFormattedDate(recent.ingress_date_time)
          : 'NA'}
      </span>
    </td>
    <td className="alert-recent-td">
      <span
        className="alert-recent-td-span"
        style={{
          color: recent.alertType === 'Egress' ? '#E20909' : '#1F2A5D',
        }}
      >
        {recent.egress_date_time
          ? getFormattedDate(recent.egress_date_time)
          : 'NA'}
      </span>
    </td>
    <td className="alert-recent-td">
      <span
        className="alert-recent-td-span"
        style={{
          color: recent.alertType === 'Dwell Time' ? '#E20909' : '#1F2A5D',
        }}
      >
        {recent.dwell_time_in_sec
          ? secondsToHoursAndMinutes(recent.dwell_time_in_sec)
          : 'NA'}
      </span>
    </td>
  </tr>
)

const RecentReport = ({
  renderTooltip,
  geoFanceReportData,
  page,
  setPage,
  loading,
  ITEMS_PER_PAGE,
  geoFanceReportDataCount,
}) => {
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12   bg-white">
        {geoFanceReportData?.length ? (
          <div
            className=" bg-white mb-1 position-relative "
            style={{ width: '100%', boxShadow: '0px 3px 6px #00000029' }}
          >
            <Table
              responsive={true}
              borderless
              className="mb-0"
              id="myTable"
             
            >
              <thead
                style={{
                  marginBottom: '10px',
                }}
              >
                <tr
                  className="table-header"
                  style={{
                    color: '#1F2A5D  !important',
                    verticalAlign: 'baseline',
                  }}
                >
                  <th>
                    <span className="alert-recent-th">S.No.</span>
                  </th>
                  <th>
                    <span className="alert-recent-th">Vehicle No.</span>
                  </th>
                  <th>
                    <span className="alert-recent-th">OEM & Model</span>
                  </th>
                  <th>
                    <span className="alert-recent-th">Fence Name</span>
                  </th>
                  <th>
                    <span className="alert-recent-th">Alert Type</span>
                  </th>
                  <th>
                    <span className="alert-recent-th">
                      Ingress <br></br> Date & Time
                    </span>
                  </th>
                  <th>
                    <span className="alert-recent-th">
                      Egress <br></br> Date & Time
                    </span>
                  </th>
                  <th>
                    <span className="alert-recent-th">Dwell Time</span>
                  </th>
                </tr>
                {!loading && geoFanceReportData?.length > 0 && (
                  <>
                    {geoFanceReportData?.map((item, index) => (
                      <Row
                        key={index}
                        recent={item}
                        index={index}
                        renderTooltip={renderTooltip}
                        currentPage={page}
                        ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                      />
                    ))}
                  </>
                )}
              </thead>
            </Table>

            <Pagination
              disabled={false}
              currentPage={page}
              setPage={setPage}
              totalPages={Math.ceil(
                (geoFanceReportDataCount || 0) / ITEMS_PER_PAGE
              )}
              data-testid='recent-report-pagination'
            />
          </div>
        ) : (
          <> </>
        )}

        {!geoFanceReportData?.length && !loading ? (
          <NoRecordFound></NoRecordFound>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default RecentReport
