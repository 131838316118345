import React from 'react'

import moment from 'moment'

import './NDTrackingChart.css'

const VehicleStatusClassNames = {
  "Parked": "tc__vehiclestatus-statusbar--immobile",
  "Live": "tc__vehiclestatus-statusbar--live",
  // Note: Merge Idle status to Live status
  "Idle": "tc__vehiclestatus-statusbar--live",
  "Charging": "tc__vehiclestatus-statusbar--charging",
}

const NDTrackingChart = ({ vehicleStatusData = [] }) => {
  const reversedVehicleStatusData = [...vehicleStatusData].reverse()
  
  return (
    <>
      <div className='nd__tracking-chart my-3'>
        <div className='nd__trackingchart_timeline'>
          {reversedVehicleStatusData.map((item, index) => {
            return (
              <div key={`timline_${index}`} className={index % 50 === 0 ? 'nd__timeline_bar' : 'nd__timeline_box'}>
                {index % 50 === 0 ? <p>{moment(item.time).format('HH:mm')}</p> : null}
              </div>
            )
          })}
        </div>
        <div className='nd__dark-tc-ignition-status'>
          {reversedVehicleStatusData.length ? (
            <>
              {reversedVehicleStatusData.map((item, index) => {
                let ignitionStatus = item?.ig === true ? 'nd__dark-tc-ignition-status-on' : item?.ig === false ? 'nd__dark-tc-ignition-status-off' : '';
                if (['Live', 'Idle'].includes(item?.vs)) {
                  ignitionStatus = 'nd__dark-tc-ignition-status-on'
                }
                return (
                    <div
                      key={`ignition_status_${index}`}
                      className={`nd__tc-ignition-status-container ${!item?.nct && ignitionStatus}`}
                      data-toggle="tooltip"
                      title={moment(item.time).format('DD-MMM-YYYY, HH:mm:ss')}
                    />
                )
              })}
            </>
          ) : (
            <div className='nd__tc-ignition-status-container'></div>
          )}
          
          
        </div>
        <div className='nd__tc-vehicle-status'>
          {reversedVehicleStatusData.length ? (
            <>
              {
                reversedVehicleStatusData.map((item, index) => {
                  return (
                    <div
                      key={`vehicle_status_${index}`}
                      className={`tc__vehicle-status-container ${ !item?.nct && VehicleStatusClassNames[item?.vs]}`}
                      data-toggle="tooltip"
                      title={moment(item.time).format('DD-MMM-YYYY, HH:mm:ss')}
                    />
                  )
                })
              }
            </>
          ) : (
            <div className='tc__vehicle-status-container' style={{backgroundColor: "#C9C9C9"}}></div>
          )}
          
          
        </div>
      </div>
    </>
  )
}

export default NDTrackingChart