import cx from 'classnames';
import toast from 'react-hot-toast';
import { variantImages as informativeModalVariantImages } from '../components/shared/modals/InformativeModal/InformativeModel';
import informativeModalStyles from '../components/shared/modals/InformativeModal/InformativeModal.module.css';

//custom success toastmessages
export const successToast = ({ message = 'Request successful!' }) => {
	toast.success(message, {
		duration: 4000,
		position: 'top-center',
		// Change colors of success/error/loading icon
		iconTheme: {
			primary: '#0a0',
			secondary: '#fff',
		},
		// styling
		style: {
			padding: '5px 10px',
			color: '#713200',
		},
		// Aria
		ariaProps: {
			role: 'status',
			'aria-live': 'polite',
		},
		id: 1,
	});
};

//custom error toats messages
export const errorToast = ({ message = 'Error processing your request!', duration = 4000 }) => {
	toast.error(message, {
		duration: duration,
		position: 'top-center',
		// Change colors of success/error/loading icon
		iconTheme: {
			primary: '#f00',
			secondary: '#fff',
		},
		// styling
		style: {
			fontSize: '15px',
			padding: '5px 10px',
			color: '#713200',
		},
		// Aria
		ariaProps: {
			role: 'status',
			'aria-live': 'polite',
		},
		id: 2,
	});
};

//custom loading toastmessages
export const customToast = ({
	message = 'Submitted Successfully',
	svgImageName = 'promptSuccess',
	duration = 4000,
	theme = 'dark', 
	secondaryMessage = '',
}) => {
	let toastInstance = null;

	const variantImages = {
		promptSuccess: informativeModalVariantImages.success,
		decline: informativeModalVariantImages.error,
		'404_status': informativeModalVariantImages.error,
		'400_status': informativeModalVariantImages.error,
		warning: informativeModalVariantImages.warning,
		vtNoDataFound: informativeModalVariantImages.warning,
	};

	const lightVariantImages = {
		promptSuccess: '/images/successGroupIcon.svg', 
		decline: informativeModalVariantImages.error,
		'404_status': informativeModalVariantImages.error,
		'400_status': informativeModalVariantImages.error,
		warning: informativeModalVariantImages.warning,
		vtNoDataFound: informativeModalVariantImages.warning,
	};

	const isDarkTheme = theme === 'dark';

	toast.custom(
		(t) => {
			toastInstance = t;
			return isDarkTheme ? (
				<div className={`${informativeModalStyles.modalContainer}`}>
					<div className={`${informativeModalStyles.modalWrapper}`}>
						<div className={`${informativeModalStyles.modalCloseWrapper}`}>
							<img
								className={`${informativeModalStyles.modalCloseIcon}`}
								onClick={() => toast.dismiss()}
								src='/images/svgicon/trackingScreen/close_active.svg'
								alt='close-btn'
							/>
						</div>
						<div className={`${informativeModalStyles.content}`}>
							<img className='h-25 w-25 mb-2' src={variantImages[svgImageName]} alt={svgImageName} />
							{message && <div className={`text-white fs-4 fw-medium`}>{message}</div>}
							{secondaryMessage && <div className={`text-white fs-4 fw-medium`}>{secondaryMessage}</div>}
						</div>
					</div>
				</div>
			) : (
				<div className={cx(informativeModalStyles.lightThemeModalContiner)}>
					<div className={cx(informativeModalStyles.lightThemeModalWrapper)}>
						<div className={`${informativeModalStyles.lightThemeModalHeader}`}>
							<div className={`${informativeModalStyles.lightThemeModalTitle}`}>
								<span className='fw-medium'>{message}</span>
							</div>
							<div className={`${informativeModalStyles.lightThemeModalCloseContainer}`}>
								<img
									className={`${informativeModalStyles.lightThemeModalCrossIcon}`}
									onClick={() => toast.dismiss()}
									src='/images/groupModalCrossIcon.svg'
									alt='close-btn'
								/>
							</div>
						</div>
						<div className={`${informativeModalStyles.lightThemeModalContent}`}>
						
            <img
								style={{ width: '100px', margin: 'auto' }}
								src={lightVariantImages[svgImageName]}
								alt={svgImageName}
							/>
							{message && (
								<div className={`text-white fs-4 fw-medium`}>
									{message} <br></br>
								</div>
							)}
              {secondaryMessage && <div className={`text-dark fs-4 fw-medium`}>{secondaryMessage}</div>}
              

						
						</div>
					</div>
				</div>
			);
		},
		{
			duration: duration,
		}
	);

	setTimeout(() => {
		if (toastInstance) {
			toast.dismiss(toastInstance.id);
		}
	}, duration);
};
