import { Button, Row, Col, Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { loadSavedLabels, saveLabels, openLabelCard} from '../../../../store/actions/geo-fence/parameterConfigurationActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LabelEditRow from './LabelEditRow';
import { customToast } from '../../../../utils/toasts'
import { sentenceCompare } from '../../../../utils/common-methods';
import  API_BOOK  from '../../../../service/endpoints'
import api from '../../../../service/api';

const geoFenceCheckIfLabelIsUsedConfig = API_BOOK.GEO_FENCE.MASTERS.CHECK_IF_LABEL_IS_USED


const AddLabel = (
    {
        loadSavedLabels,
        openLabelCard,
        saveLabels,
        labelsSaved,
        labelCardOpened
    }
)=> {
    const [labelName, setLabelName] = useState('')
    const [labelNameValidationMessage, setLabelNameValidationMessage] = useState('')
    const [isLabelNameValid, setLabelNameValid] = useState(true)
    const [labelsEditableData, setLabelsEditableData] = useState([])
    const [deletedLabelIds, setDeletedLabelIds] = useState([])
    const [isChanged, setIsChanged] = useState(false)

 
    useEffect(()=>{
        if(labelCardOpened)
            loadSavedLabels()
    },[labelCardOpened, loadSavedLabels])

    useEffect(()=>{
        if(labelsSaved)
        {
            const labelsData = labelsSaved.map((x, index)=> {
                return {
                    _id: x._id,
                    labelName: x.label,
                    isEditable: false,
                    isValid: true,
                    validationMessage: ''
                }
            })
            setLabelsEditableData(labelsData)
        }
        
    }, [labelsSaved])

    const handleLabelNameChange = (e)=>{
        setLabelName(e.target.value)
        setLabelNameValid(true)
    }

    const addNewLabel = ()=>{
        if(labelName === ''){
            setLabelNameValid(false)
            setLabelNameValidationMessage('Label name is required')
            return
        }
        const existing = labelsEditableData
            .find(x=>sentenceCompare(x.labelName, labelName))

        if(existing){
            setLabelNameValid(false)
            setLabelNameValidationMessage('Label is already present')
            return
        }

        const newLabel = {
            _id: '',
            labelName: labelName
        }
        setLabelsEditableData([...labelsEditableData, newLabel])
        setLabelName('')
        setIsChanged(true)
    }

    const save = ()=> {
        let labelsEditableDataCopy = [...labelsEditableData]
        let isValid = true

        if(labelsEditableDataCopy.length===0){
            setTimeout(() => {
                customToast({ message: 'Add atleast one label to save', svgImageName: 'decline' })
            }, 400)
            return
        }
        labelsEditableDataCopy = labelsEditableDataCopy.map((item)=> {
            const label = {...item}
            if(!label.isEditable){
                return {
                    ...label
                }
            }
            if(label.labelName === ''){
                label.isValid = false
                label.validationMessage = 'Label name is required'
                isValid = false
            }
            const existing = labelsEditableDataCopy
                .filter(x=>sentenceCompare(x.labelName, label.labelName))
            if(existing && existing.length>1){
                label.isValid = false
                label.validationMessage = 'Duplicate labels are not allowed'
                isValid = false
            }

            return {
                ...label
            }
        })
         
        if(!isValid){
            setLabelsEditableData([...labelsEditableDataCopy])
            return
        } 
        saveLabels({
            savedLabels: labelsEditableData.map(label => ({ ...label, labelName: label.labelName.trim() })),
            deletedLabelIds: deletedLabelIds
        })
    }

    const checkIfLabelIsUsed = async (label, key)=> {
        if(label._id === ''){
            return false
        }
        try {
            const resp = await api({
                method: geoFenceCheckIfLabelIsUsedConfig.method,
                url: geoFenceCheckIfLabelIsUsedConfig.url + `/${label._id}`
              });
            if (resp.status === 200) {
                const response = resp?.data.data;
                return response
            }
          } catch (error) {
            console.log("error:", error);
        }
    }


    return (

        <div className={` toggle-container-addlabel   p-3 mb-3 bg-white ${labelCardOpened ? 'open' : ''}`}>
            <div className='add-label-font-configure' data-testid='label-collapse-toggle' onClick={() => openLabelCard(!labelCardOpened)}>Add Label</div>
            <div className="toggle-button" data-testid='label-collapse-toggle' onClick={() => openLabelCard(!labelCardOpened)}>
                {
                    labelCardOpened ?
                    <img
                    className="configure-arrow-up"
                    alt={'add'}
                    src='/images/svgicon/arrow-up.svg'
                    ></img>
                    :
                    <img
                    className="configure-arrow-down"
                    alt={'add'}
                    src='/images/svgicon/arrow-down.png'
                    style={{width:17, height:17}}
                    ></img>
                }
            </div>
            <div className="tab-content" style= {{marginBottom:67}}>
                <Row className=''>
                    <Col>
                    {
                    labelsEditableData && labelsEditableData.map((label, index) => (
                        <LabelEditRow 
                            rowKey={index}
                            labelName={label.labelName}
                            isEditable={label.isEditable}
                            isValid={label.isValid}
                            validationMessage={label.validationMessage}
                            onDelete = {async (key)=> {
                                const newItems = [...labelsEditableData];
                                const deleted = newItems.splice(key, 1)
                                const isUsed = await checkIfLabelIsUsed(deleted[0], key)
                                if(isUsed){
                                    setTimeout(() => {
                                        customToast({ message: 'Label is already used', svgImageName: 'decline' })
                                    }, 400)
                                    return
                                }
                                setLabelsEditableData([...newItems])
                                setDeletedLabelIds([...deletedLabelIds, deleted[0]])
                                setIsChanged(true)
                            }}
                            labelNameOnChange={(newLabelName, key)=> {
                                const labelsEditableDataCopy = labelsEditableData
                                const label = {...labelsEditableDataCopy[key]}
                                label.labelName = newLabelName
                                label.isValid = true
                                labelsEditableDataCopy[key] = label
                                setLabelsEditableData([...labelsEditableDataCopy])
                            }}
                            setEdit={(key)=>{
                                const labelsEditableDataCopy = labelsEditableData
                                const label = {...labelsEditableDataCopy[key]}
                                label.isEditable = true
                                labelsEditableDataCopy[key] = label
                                setLabelsEditableData([...labelsEditableDataCopy])
                                setIsChanged(true)
                            }}
                        >
                        </LabelEditRow>
                    ))
                }


        <Row>
            <Col xs={2} className='geofence-configure-sub-text'>
                Label Name
            </Col>
            <Col xs={2}>
                <Form.Group controlId="textBox-label">
                    <Form.Control
                        data-testid='label-add-name-input'
                    value={labelName}
                    onChange={handleLabelNameChange}
                    type="text" 
                    placeholder="Enter text" />
                </Form.Group>
                {
                    !isLabelNameValid &&
                    <div className='text-danger error-message'>{labelNameValidationMessage}</div>
                }
                
            </Col>
            <Col xs={2} className="d-flex">
                <div className='geofence-configure-sub-text-add' data-testid='label-add-button' onClick={addNewLabel}>
                    <img
                    alt={'add'}
                    src='/images/svgicon/add_button.svg'
                    style={{width:"30%", marginLeft:10, marginRight:5}}
                    ></img>
                    Add          
                </div>
            </Col>
        </Row>
                    </Col>
                </Row>
                
            

        
                
            </div>
            <Row style={{marginBottom: 15}}>
            <Col className="d-flex flex-row justify-content-center">
                <Button 
                onClick={save}
                disabled={!isChanged}
                className='save-btn-configure save-action-button'
                data-testid='label-save-button'
                >
                    Save
                </Button>
            </Col>
        
        </Row>
        </div>
    )
}

AddLabel.propTypes = {
	// Actions
	loadSavedLabels: PropTypes.func.isRequired,
    saveLabels: PropTypes.func.isRequired,
    openLabelCard: PropTypes.func.isRequired,
	// States
    labelsSaved: PropTypes.array.isRequired,
    labelCardOpened: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	loadSavedLabels,
    openLabelCard,
    saveLabels,
    labelsSaved: state.parameterConfiguration.labelsSaved,
    labelCardOpened: state.parameterConfiguration.labelCardOpened
});

export default connect(mapStateToProps, {
	loadSavedLabels,
    saveLabels,
    openLabelCard
})(AddLabel);
