import ActionTypes from "../actionTypes";

let initialState = {
    notificationData : [],
    showNotificationPanel : false,
    checkForNotification : false,
    modalConfiguration: {
        isModalOpen: false,
        modalType: null
    }
    
}

const actions = ActionTypes.NOTIFICATION_PANEL;

const getFormattedDateTime = (dateTime) => {
    if(dateTime){
        let dateTimeLocal = new Date(dateTime);
        let timeArray = dateTimeLocal.toTimeString().split(":");
        let dateArray = dateTimeLocal.toDateString().split(" ");
    
        const date = `${dateArray[2]}-${dateArray[1]}-${dateArray[3]}`;
        const time = `${timeArray[0]}:${timeArray[1]}`;
        return `${date} ${time}`
    }
    return null
}

const notificationPanelReducer = (state = initialState, action) => {
    const { type, payload } = action;
    
    switch (type) {
        case actions.GET_NOTIFICATION_DATA_BY_USER:
            return {
                ...state,
                notificationData: payload && payload.length>0 ? payload?.map(notification=>{
                    notification.formattedStartDateTime  =  getFormattedDateTime(notification._meta.startDateTime);
                    notification.formattedEndDateTime  =  getFormattedDateTime(notification._meta.endDateTime);
                    notification.formattedCreatedDateTime  =  getFormattedDateTime(notification._meta.createdAt);
                    notification.formattedUpdatedDateTime  =  getFormattedDateTime(notification._meta.updatedAt);
                    return notification;
                }) : [],
            };
        case actions.CURRENT_EXPORTED_DATA_NOTIFICATION:
            let notification = payload;
            notification.formattedStartDateTime  =  getFormattedDateTime(notification._meta.startDateTime);
            notification.formattedEndDateTime  =  getFormattedDateTime(notification._meta.endDateTime);
            notification.formattedCreatedDateTime  =  getFormattedDateTime(notification._meta.createdAt);
            notification.formattedUpdatedDateTime  =  getFormattedDateTime(notification._meta.updatedAt);
            return {
                ...state,
                notificationData: [notification,...state.notificationData ]
            };
        case actions.SHOW_NOTIFICATION_PANEL:
            return {
                ...state,
                showNotificationPanel:  !state?.showNotificationPanel,
            };
        case actions.MARK_AS_READ_SUCCESS:
            return {
                ...state,
                notificationData : payload && payload.length > 0 && state?.notificationData && state?.notificationData?.length > 0 
                ? state?.notificationData?.map(notification => {
                    notification.isNotified = !notification.isNotified ? payload.some(x=>x === notification?._id) : notification.isNotified;
                    return notification;
                }) : []
            };
        case actions.START_NOTIFICATION:
            return {
                ...state,
                checkForNotification : true
            };
        case actions.STOP_NOTIFICATION:
            return {
                ...state,
                checkForNotification : false
            };
        case actions.SET_MODAL_OPEN:
                return {
                    ...state,
                    modalConfiguration:{
                        isModalOpen: true,
                        modalType: payload
                        
                    }
                };
         case actions.SET_MODAL_CLOSE:
                return {
                    ...state,
                    modalConfiguration:{
                        isModalOpen: false,  
                    }
                };
        default:
            return state;
    }
};

export default notificationPanelReducer;


