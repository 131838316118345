
const mapper = {}

mapper.mapVehicleDataOptions = (vehicleData, takeAll = false) => {
  const options = {
    oemModel: [],
    location: [],
    registrationNumber: [],
    vehicleCategory:[],
    chargingType:[],
    customDataSource: [{
      label: 'TFT100',
      value: 'TFT100'
    }, {
      label: 'OEM API',
      value: 'OEM_API',
    }],
  }

  if (!vehicleData?.length) {
    return options
  }

  vehicleData.forEach(vehicle => {
    if (vehicle.active || takeAll) {    
      const oemModel = `${vehicle.oem} ${vehicle.model}`
      const vehicleType = vehicle?.type
      
      if (options.oemModel.findIndex(d => d.value === oemModel) === -1) {
        options.oemModel.push({ label: oemModel, value: oemModel, ...vehicle })
      }

      if (options.location.findIndex(d => d.value === vehicle.location) === -1) {
        options.location.push({ label: vehicle.location, value: vehicle.location, ...vehicle })
      }
      if(options.vehicleCategory.findIndex(d=> d.value === vehicleType )=== -1){
        options.vehicleCategory.push({label:vehicleType, value:vehicleType, ...vehicle})

      } if(options.registrationNumber.findIndex(d=> d.value === vehicle.registration_number )=== -1){
        options.registrationNumber.push({ label: vehicle.registration_number, value: vehicle._id, ...vehicle })

      }if(options.chargingType.findIndex(d=> d.value === vehicle.charging_type )=== -1){
        options.chargingType.push({label:vehicle.charging_type, value:vehicle.charging_type, ...vehicle})
      }
    }
  })

  return options
   
}

export { mapper }