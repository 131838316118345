let initialState = {
    favouriteVehicles: []
}

const getFavoriteVehicleReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "GET_USER_FAVORITE_VEHICLE_SUCCESS":
            return {
                ...state,
                favouriteVehicles: payload,
            };
        case "ADD_USER_FAVORITE_VEHICLE_SUCCESS":
            return {
                ...state,
                favouriteVehicles: state.favouriteVehicles.includes(payload) ? state.favouriteVehicles : [...state.favouriteVehicles,payload],
            };
        case "DELETE_USER_FAVORITE_VEHICLE_SUCCESS":
            let favouriteVehicles = state.favouriteVehicles.filter(x=>x!==payload);
            return {
                ...state,
                favouriteVehicles: favouriteVehicles,
            };
        default:
            return state;
    }
};

export default getFavoriteVehicleReducer;