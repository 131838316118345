import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import inputStyles from './PasswordInput.module.css';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import Popover from '../../modals/Popover/Popover';

import { PasswordCriteriaMapper } from '../../../../utils/constants';

const PasswordInput = ({
	error,
	success,
	onChange,
	errorFields,
	enablePasswordValidation,
	value,
	...props
}) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [passwordCriteria, setPasswordCriteria] = useState({
		'length > 8': false,
		'length < 31': false,
		'must contain digit': false,
		'must contain at least 1 lowercase': false,
		'must contain at least 1 uppercase': false,
		'must have at least 1 special character': false,
	});
	const [isFocused, setIsFocused] = useState(false);

	// Validate password criteria
	const validatePasswordCriteria = (password) => {
		const criteria = {
			'length > 8': password.length >= 8,
			'length < 31': password.length <= 31,
			'must contain digit': /\d/.test(password),
			'must contain at least 1 lowercase': /[a-z]/.test(password),
			'must contain at least 1 uppercase': /[A-Z]/.test(password),
			'must have at least 1 special character': /[!@#$%^&*]/.test(password),
		};

		const fulfilledCriteria = Object.keys(criteria).filter((criterion) => criteria[criterion]);

		let check = {};
		Object.keys(criteria).forEach((key) => {
			check[key] = !!fulfilledCriteria.includes(key);
		});

		return check;
	};

	const handleOnChange = (e) => {
		if (enablePasswordValidation) {
			const passwordCriteriaCheck = validatePasswordCriteria(e.target.value)
			onChange(e, passwordCriteriaCheck);
			setPasswordCriteria(passwordCriteriaCheck);
		} else {
			onChange(e);
		}
	};

	const togglePasswordVisibility = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	const getCriteriaElement = (criterion, text, messageTestId) => (
        <p
            className={passwordCriteria[criterion] ? 'text-white' : 'text-white'}
            style={{ fontSize: '12px', marginBottom: '0px' }}
            // NOTE:
			// 	ex. baseTestId = forgot-password, messageTestId = MIN_LENGTH, criterion = SUCCESS|ERROR
			// 	forgot-password-MIN_LENGTH-success-message
			// 	forgot-password-MIN_LENGTH-error-message
            data-testid={`forgot-password-${messageTestId}-${passwordCriteria[criterion] ? 'success' : 'error'}-message`}
        >
            {passwordCriteria[criterion] ? (
                <img src='/images/saAdmin/tick-icon.svg' alt='' className={inputStyles.passwordIconImage} />
            ) : (
                <img src='/images/saAdmin/info_main_icon.svg' alt='' className={inputStyles.passwordIconImage} />
            )}
            {text}
        </p>
    );

	const hasError = Object.values(passwordCriteria).filter(d => !d).length;

	return (
		<div className={inputStyles.passwordInputWrapper}>
			<input
				className={`${inputStyles.root}`}
				type={isPasswordVisible ? 'text' : 'password'}
				onChange={handleOnChange}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				value={value}
				{...props}
			/>
			{isPasswordVisible ? (
				<BsEyeFill onClick={togglePasswordVisibility} className={inputStyles.loginEyeIcon} />
			) : (
				<BsEyeSlashFill onClick={togglePasswordVisibility} className={inputStyles.loginEyeIcon} />
			)}
			{error && <div className={inputStyles.errorMessage}>{error}</div>}
			
			{!enablePasswordValidation && error && (
				<div className={inputStyles.passwordIcon}>
					<img
						src={'/images/saAdmin/info_main_icon.svg'}
						alt=''
						className='cursor-pointer'
					/>
				</div>
			)}

			{!enablePasswordValidation && success && (
				<div className={inputStyles.passwordIcon}>
					<img
						src={'/images/saAdmin/tick-icon.svg'}
						alt=''
						className='cursor-pointer'
					/>
				</div>
			)}

			{enablePasswordValidation && !!value.length && (
				<Popover
					show={isFocused}
					triggerComponent={
						<div
							className={inputStyles.passwordIcon}
						>
							<img
								src={hasError ? '/images/saAdmin/info_main_icon.svg' : '/images/saAdmin/tick-icon.svg'}
								alt=''
								className='cursor-pointer'
							/>
						</div>
					}
					bodyComponent={<div>
						{getCriteriaElement(PasswordCriteriaMapper.MIN_LENGTH, 'At least 8 characters', 'MIN_LENGTH')}
                        {getCriteriaElement(PasswordCriteriaMapper.DIGIT, 'Contains a digit', 'DIGIT')}
                        {getCriteriaElement(PasswordCriteriaMapper.LOWERCASE, 'Contains a lowercase letter', 'LOWERCASE')}
                        {getCriteriaElement(PasswordCriteriaMapper.UPPERCASE, 'Contains an uppercase letter', 'UPPERCASE')}
                        {getCriteriaElement(PasswordCriteriaMapper.SPECIAL_CHARACTER, 'Contains a special character', 'SPECIAL_CHARACTER')}
					</div>}
				/>
			)}
		</div>
	);
};

PasswordInput.defaultProps = {
	onChange: () => {},
	error: '',
	value: '',
	enablePasswordValidation: false,
	success: '',
};

PasswordInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.string,
	enablePasswordValidation: PropTypes.bool,
	success: PropTypes.string,
};

export default memo(PasswordInput);
/*conflict-resolution-will be removed later*/