import PieChartComponent from '../../../../../helpers/hoc/donut-chart/dountChart';
import { Col, Row } from 'react-bootstrap';
import "./ChargingOemCityChart.css";
import { useState } from 'react';
import ModalReport from '../modal-report/ModalReport';


const ChargingOemAndCityChart = (props) => {

  const [isShowReportModal, setIsShowReportModal] = useState();
  const [reportFilter, setReportFilter] = useState();

  const [tableModalName, setTableModalName] = useState('')

  const openReportModal = (data, filterType) => {
    setIsShowReportModal(true)
    setReportFilter(() => {
      let filter = ""
      if(filterType === "Normal" || filterType === "Fast" ){
        const splitArray = data.name.split(' ');
        const oem = splitArray[0];
        const model = splitArray.slice(1).join(' ');
        filter = { oem, model,chargingType : filterType }
      }
      else if (filterType === "City") {
        filter = { city: data.name }
      }
      else if(filterType === "Charging Type"){
        const chargingType = data.name.split(" ")
        filter = {chargingType : chargingType[0]}
      }
      else{
        const splitArray = data.name.split(' ');
        const oem = splitArray[0];
        const model = splitArray.slice(1).join(' ');
        filter = { oem, model }
      }

      return {
        fromDate: props.date.fromDate,
        toDate: props.date.toDate,
        filterType: (filterType === "City") ? "city" : ((filterType === "Charging Type" || filterType === "Normal" || filterType === "Fast") ? "chargingType" :  "oemModel"),
        filter: filter,
        pageSize: 8,
        pageIndex: 0
      }
    })
  }

  return (
    <Row className="trip-report-main-row mb-4 mt-4">
      {props?.chartData?.map((chartItem, index) => (
        <Col xs={6} key={index}>
          <div className="trip-analytics-box-shadow-donut"
          >
            <label className="chart-item-trip-report">{chartItem?.chartName}</label>
            <hr />
            <div className="donut-chart-box-trip-report">
              {chartItem?.charts?.map((chart, cIndex) => (
                <div key={cIndex} >
                  <label className="titele-trip-report-dount">{chart?.title}</label>
                  {!chart?.data?.length ? (
                    <>
                      <div style={{ height: "100%" }}>
                        <div className="d-flex no-data-trip-analytics">
                          No data
                        </div>
                      </div>
                    </>
                  )
                    :
                    (<PieChartComponent
                      data={chart.data}
                              innerRadius={50}
                              outerRadius={80}
                              width={190}
                              height={190}
                      labelType={chart?.labelType}
                      selectedLabelType={chart?.selectedLabelType}
                      totalValue={chart?.totalValue}
                      line2={chart?.line2}
                      colors={chart?.colors}
                      onClick={(args) => {
                        openReportModal(args, chart?.title, chartItem.chartName);
                        setTableModalName(`${chartItem.chartName} : ${args?.name}`)
                      }}
                      data-testid='charging-piechart'
                    />)}
                </div>
              ))}
            </div>
          </div>

        </Col>
      ))}
      {isShowReportModal && (
        <ModalReport
          showModal={isShowReportModal}
          setIsShowModal={setIsShowReportModal}
          reportFilter={reportFilter}
          title={tableModalName}
        />
      )}
    </Row>
  )
}


export default ChargingOemAndCityChart;