import ActionTypes from "../../actionTypes";

const { SUB_GROUP } = ActionTypes.SUB_GROUP;

const initialState = {
  subGroupDetails: [],
  vehicleNumbers: [],
};

const subGroups = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUB_GROUP:
      // Check if a subgroup with the same subGroupName already exists
      const existingSubGroupIndex = state.subGroupDetails.findIndex(
        (subGroup) => subGroup.subGroupName === payload.subGroupName
      );
  
      if (existingSubGroupIndex !== -1) {
        // If the subgroup exists, update the vehicleNumbers array
        return {
          ...state,
          subGroupDetails: state.subGroupDetails.map((subGroup, index) => {
            if (index === existingSubGroupIndex) {
              return {
                ...subGroup,
                vehicles: [
                  ...subGroup.vehicles,
                  ...payload.vehicles,
                ],
                vehicleNos: [
                    ...subGroup.vehicleNos,
                    ...payload.vehicleNos
                ]
              };
            }
            return subGroup;
          }),
          vehicleNumbers: [
            ...state.vehicleNumbers,
            ...payload.vehicleNos,
          ],
        };
      } else {
        // If the subgroup does not exist, create a new one
        return {
          ...state,
          subGroupDetails: [
            ...state.subGroupDetails,
            {
              vehicles: payload.vehicles,
              subGroupName: payload.subGroupName,
              vehicleNos: payload.vehicleNos,
            },
          ],
          vehicleNumbers: [
            ...state.vehicleNumbers,
            ...payload.vehicleNos,
          ],
        };
      }

    case 'revert':
        return state;
    default:
      return state;
  }
};

export default subGroups;
