import React from 'react';
import './GPDeleteConfirmationModal.css';

const GPDeleteConfirmationModal = ({ isOpen , handleClose ,onDeleteHandler, message, ...props}) => {
  const baseTestId = props['data-testid'] || 'delete'
  return isOpen && (
    <div className="groupsDelete-modal-container">
      <div className="groupsDelete-modal">
        <span className="modal-text text-center">{message}</span>
        <div className="button-container">
          <span className="modal-button yes" data-testid={`${baseTestId}-yes-button`} onClick={onDeleteHandler}>Yes</span>
          <span className="modal-button no" data-testid={`${baseTestId}-no-button`} onClick={handleClose}>No</span>
        </div>
      </div>
    </div>
  );
};

export default GPDeleteConfirmationModal;
