import React from "react";
import { Row, Col, Button} from 'react-bootstrap'
import FenceSelectDiv from "./FenceSelectDiv";
import IngressSettingsDiv from "./IngressSettingsDiv";
import EgressSettingsDiv from "./EgressSettingsDiv";
import DwellTimeSettingsDiv from "./DwellTimeSettingsDiv";
import { saveAlertsConfiguration } from "../../../../store/actions/geo-fence/configuration/alertsConfiguration/alertConfigurationScreenActions";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { customToast } from "../../../../utils/toasts"

const GeoFenceAlertConfigurationTab = ({
    alertConfigurationScreen,
    saveAlertsConfiguration,
    fencesData
})=> {

    const isSaveDisabled = () => {
        const selectedFences = fencesData.filter(fence => fence.isSelected)
        if(selectedFences.length === 0) {
            return true
        }
    }

    const save = ()=> {
        const stateData = {...alertConfigurationScreen}
        //if no fence is selected, then show customModal alert
        if(fencesData.filter((fence)=> fence.isSelected).length === 0) {
            setTimeout(() => {
                customToast({ message: 'Select atleast one fence', svgImageName: 'decline' })
            }, 400)
            return
        }
        saveAlertsConfiguration({stateData})
    }

    return (
        <Row>
            <Col className="configuration-alert">
                <FenceSelectDiv></FenceSelectDiv>
                <div className="notification-heading">Notification</div>
                <IngressSettingsDiv></IngressSettingsDiv>
                <EgressSettingsDiv></EgressSettingsDiv>
                <DwellTimeSettingsDiv></DwellTimeSettingsDiv>
                <Row>
                    <Col style={{display:'flex', justifyContent:'right'}}>
                        <Button 
                            disabled={isSaveDisabled()}
                            variant="primary"
                            onClick={save}
                            data-testid='alert-configure-save-button'
                            >
                                Save
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}


GeoFenceAlertConfigurationTab.propTypes = {
    alertConfigurationScreen: PropTypes.object.isRequired,
    saveAlertsConfiguration: PropTypes.func.isRequired,
    fencesData: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    alertConfigurationScreen: state.alertConfigurationScreen,
    fencesData: state.alertConfigurationScreen.fencesData,
    saveAlertsConfiguration
});

export default connect(mapStateToProps, {
    saveAlertsConfiguration
})(GeoFenceAlertConfigurationTab)