import { useState, useMemo, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import FileDrawer from '../file drawer/FIleDrawer';
import FileUploadProgress from '../file upload progress bar/FileUploadProgress';
import './BrowseAndUploadFileModal.css';
import { addEscapeKeyListener } from '../../../../../../../utils/common-methods';

const BrowseAndUploadFileModal = ({
  showMoal,
  setIsShowModal,
  onFileUpload, 
  isUploadApiSuccess,
  vehicleOnboardingApiError,
}) => {
  const [file, setFile] = useState(null);
  const [fileSelectionError, setFileSelectionError] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useMemo(() => {
    if (!file) {
      setIsSaveDisabled(false);
    }
  }, [file]);

  
  useEffect(() => {
    if (isUploadApiSuccess) {
      setIsShowModal(false);
    }
  }, [isUploadApiSuccess, setIsShowModal]);

  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, [setIsShowModal]);

  const onSaveHandler = async () => {
    if (file) {
      setIsSaveDisabled(true);
      try {
        await onFileUpload(file); 
        setIsShowModal(false);
      } catch (error) {
        console.error('File upload failed:', error);
        setIsSaveDisabled(false); 
      }
    } else {
      setFileSelectionError(true);
      setTimeout(() => {
        setFileSelectionError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      console.log('Escape key pressed globally!');
      handleClose();
    });

    return () => {
      removeListener();
    };
  }, [handleClose]);

  return (
    <>
      <Modal
        show={showMoal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title> Upload File</Modal.Title>
          <div>
            <FileDrawer setFile={setFile} data-testid='vehicle-onboarding-file-drawer' />
            {file && <FileUploadProgress setFile={setFile} file={file} data-testid='vehicle-onboarding-file-progress' />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-row gap-1">
            <div className="width-86 text-center">
              {(fileSelectionError || vehicleOnboardingApiError) && (
                <div className="error-msg">
                  <label className="text-danger error-message text-wrap">
                    {vehicleOnboardingApiError
                      ? vehicleOnboardingApiError
                      : 'Please select file to save'}
                  </label>
                </div>
              )}
            </div>
            <div className="width-12">
              <button
                disabled={isSaveDisabled && file}
                onClick={onSaveHandler}
                className={isSaveDisabled && file ? 'save-btn-disabled' : 'save-btn'}
                data-testid='vehicle-onboarding-submit-button'
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BrowseAndUploadFileModal.propTypes = {
  onFileUpload: PropTypes.func.isRequired, 
  isUploadApiSuccess: PropTypes.bool.isRequired,
  vehicleOnboardingApiError: PropTypes.string, 
};

export default BrowseAndUploadFileModal;
