import actionTypes from '../../actionTypes'

const initialState = {
  customerListData: [],
}

const administrationReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.ADMINISTRATION.ADMINISTRATION_CUSTOMER_LIST:
      return {
        ...state,
        customerListData: payload
      }
    default:
      return state
  }
}

export default administrationReducer
/*conflict-resolution-will be removed later*/