import React, {  useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import TripLog from '../records/TripLog';
import TripAnalyticsGUI from '../../Analytics/TripAnalytics/TripAnalyticsGUI';


const Trip = () => {
	const [activeTab, setActiveTab] = useState('charge_analytics_chats');

	const onTabSelectHandler = async (event) => {
		setActiveTab(event);
	
		if (event === 'charge_analytics_chats') {
			return;
		} else {
		
		}
	};

	return (
		<>
			<div className='col-12 bg-white'>
				<div className='bg-white mb-1 position-relative tabbed-content'>
					
					<div className='col-12'>
						<Container fluid>
							<div className='charging-tab-container'>
								<Tabs
									className='alert-nav-link'
									defaultActiveKey='non_comm_report'
									id='uncontrolled-tab-example'
									onSelect={(event) => {
										onTabSelectHandler(event);
									}}
									activeKey={activeTab}
								>
									<Tab eventKey='charge_analytics_chats' title='Analytics'>
										<TripAnalyticsGUI/>
									
									</Tab>
									<Tab eventKey='charge_report' title='Reports' >
										{activeTab ==='charge_report' && (
                                        <TripLog/>
										)}
									</Tab>
								</Tabs>
							</div>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default Trip