import React from 'react'
import './VTLegend.css'

const Legend = () => {
  return (
    <div>
      <div className='vt__legend__title'>Ignition Status</div>

      <div className='vt__legend_box pt-0.7'>
        <div className='vt__legend__data'>
          <div className='vt__legend__colorbox__ion '></div>
          <div className='vt__legend__data__text'>Ignition On</div>
        </div>

        <div className='vt__legend__data '>
          <div className='vt__legend__colorbox__ioff '></div>
          <div className='vt__legend__data__text'>Ignition Off</div>
        </div>
        <div className='vt__legend__data pt-2 pb-1'>
          <div className='vt__legend__colorbox__inon-com '></div>
          <div className='vt__legend__data__text'>Non Communication</div>
        </div>
      </div>

      <div className='vt__legend__title pt-1'>Vehicle Status</div>

      <div className='vt__legend_box  pt-1'>
        <div className='vt__legend__data'>
          <div className='vt__legend__colorbox__live'></div>
          <div className='vt__legend__data__text'>Live</div>
        </div>

        <div className='vt__legend__data'>
          <div className='vt__legend__colorbox__immobilize'></div>
          <div className='vt__legend__data__text'>Parked</div>
        </div>
      </div>
      <div className='vt__legend_box pt-1'>
        <div className='vt__legend__data'>
          <div className='vt__legend__colorbox__charging'></div>
          <div className='vt__legend__data__text'>Charging</div>
        </div>

        <div className='vt__legend__data'>
          <div className='vt__legend__colorbox__non-communication'></div>
          <div className='vt__legend__data__text'>Non Communication</div>
        </div>
      </div>


    </div>
  )
}

export default Legend