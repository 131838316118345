import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import "./dountChart.css";
import { BsCircle } from "react-icons/bs";



const PieChartComponent = (props) => {
  const baseTestId = props['data-testid'] || 'piechart';
  const { data, innerRadius, outerRadius, labelType, selectedLabelType, totalValue, line2, width, height, } = props;
  const [activeIndex, setActiveIndex] = useState(-1)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [toolTipData, setTooltipData] = useState(null)
  const colors = props.colors || {
    "level-0": "#DE3200",
    "level-1": "#BC1B09",
    "level-2": "#A01708",
    "level-3": "#841307",
    "level-4": "#002E42",
    "level-5": "#004664",
    "level-6": "#00648F",
    "level-7": "#00A5EC",
    "level-8": "#720F44",
    "level-9": "#B6186C",
    "level-10": "#D81C80",
    "level-11": "#914100",
    "level-12": "#D46000",
    "level-13": "#FF7300",
  };

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill
    } = props;
      return (
        <g aria-label={`${baseTestId}-piecell-${props?.name}`} style={{cursor: 'pointer'}}>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius + 6}
            outerRadius={outerRadius + 10}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
        </g>
      );
  };

  const onPieEnter = (data, index, event) => {
    setActiveIndex(index);
    const tData = data.payload
    setTooltipData(tData)
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
    setTooltipData(null)
  };

  const onPiMouseMove = (data, index, event) => {
    let pos = {
      x: event.clientX - (2 * data.cx) + 70,
      y: event.clientY - (2 * data.cy) + 65
    }
    setTooltipPosition(pos)
  }

  function removeElementsByClass(className){
    const elements = document.getElementsByClassName(className);
    for (const ele of elements) {
      ele?.setAttribute(
        'style',
        'display: none'
      )
    }
 }

  useEffect(() => {
    const element  = document.getElementsByClassName('trip-analytics-main-container-main')
    if (element?.length) {
      for (const ele of  element) {      
        if (ele) { 
          ele.addEventListener("scroll", (e) => {
            removeElementsByClass("tooltip-container")
          });
        }
      };
    }
    return () => {}
 }, []);

  return (
    <div>
      <PieChart width={width || 150} height={height || 150}>
        <Pie
          activeIndex={activeIndex}
          activeShape={(data) =>
            renderActiveShape({
              ...data,
              labelType: labelType,
              selectedLabelType: selectedLabelType,
              totalValue: totalValue
            })
          }
          onClick={props.onClick}
          data={data}
          innerRadius={innerRadius || 50}
          outerRadius={outerRadius || 80}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          onMouseMove={onPiMouseMove}
        >
          {data?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry?.pieColor ? entry?.pieColor : colors[`level-${index }`]}
              cursor='pointer'
              aria-label={`${baseTestId}-piecell-${entry?.name}`}
            />
          ))}
        </Pie>
        
          <text
            className="main-midle-text"
            x={'50%'}
            y={'50%'}
            dy={0}
            textAnchor="middle"
            fill="#22046B"
          >
            {totalValue}
          </text>

          <text
            className="main-midle-text"
            x={'50%'}
            y={'50%'}
            dy={17}
            textAnchor="middle"
            fill="#22046B"
          >
            {line2}
          </text>
          <text
            className="main-midle-text"
            x={'50%'}
            y={'50%'}
            dy={17}
            textAnchor="middle"
            fill="#22046B"
          >
            { labelType ? labelType : ''}
          </text>
      </PieChart>
      {activeIndex > -1 && 
      <div className="tooltip-container" >
          <div className="custom-tooltip-donut-chart"  style={{
            left: tooltipPosition.x, 
            top: tooltipPosition.y}}>
          <div  className="label" style={{ margin: "2px 0", textAlign: "start" }}>
            <BsCircle className="circular-donut" style={{ color: toolTipData?.pieColor ? toolTipData?.pieColor : colors[`level-${activeIndex}`], background: toolTipData?.pieColor ? toolTipData?.pieColor : colors[`level-${activeIndex}`] }}  />
            <span style={{marginLeft: '3px'}}>{`${toolTipData.name}`}</span>
          </div>
          <div className="label" style={{ margin: "4px 0 0 20px", textAlign: "start" }}>{`${toolTipData.text}${" "}${labelType ? labelType : ''}`}</div>
        </div>
      </div>
        }
    </div>
  );
};

export default PieChartComponent;
