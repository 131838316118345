import ActionTypes from "../../actionTypes";

const {
    DA_FLOW: { 
        GET_ALL_DEPARTMENT_ADMIN_USERS_SUCCESS,
        GET_ALL_DEPARTMENT_ADMIN_USERS_FAILED,
        GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_SUCCESS,
        GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_FAILED,
        GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_SUCCESS,
        GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_FAILED,
        GET_PENDING_USERS_SUCCESS,
        GET_PENDING_USERS_FAILED,
        GET_ACCESS_TEMPLATE_COUNT_SUCCESS,
        GET_ACCESS_TEMPLATE_COUNT_FAILURE
     }
  } = ActionTypes;

const initialState = {
    moduleList: [],
    departmentUser: 0,
    departmentList:[],
    masterModuleList: [],
    templateList: [],
    departmentAdminUsers: [],
    departmentAdminUsersCount: 0,
    departmentAdminUsersActiveCount: 0,
    departmentAdminUsersDeactivatedCount: 0,
    pendingDepartmentUsers: [],
    pendingDepartmentUsersCount: 0,
    totalVehicleCount: 0, 
    accessTemplateCount: 0
}

const departmentReducers = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.DEPARTMENT.MODULE_LIST_SUCCESS: {
            return {
                ...state,
                moduleList: payload,
            }
        }

        case ActionTypes.DEPARTMENT.MODULE_LIST_FAILURE: {
            return {
                ...state,
                moduleList: initialState.moduleList,
            }
        }
        case ActionTypes.DEPARTMENT.DEPARTMENT_LIST_SUCCESS: {
            return {
                ...state,
                departmentList: payload,
            }
        }

        case ActionTypes.DEPARTMENT.DEPARTMENT_LIST_FAILURE: {
            return {
                ...state,
                departmentList: initialState.departmentList,
            };
        }
        case ActionTypes.DEPARTMENT.DEPARTMENT_USER_SUCCESS: {
            return {
                ...state,
                departmentUser: payload
            };
        }
        case ActionTypes.DEPARTMENT.DEPARTMENT_USER_FAILURE: {
            return {
                ...state,
                departmentUser: 0
            };
        }
        case GET_ALL_DEPARTMENT_ADMIN_USERS_SUCCESS: {
            return {
                ...state,
                departmentAdminUsers: payload?.data,
                departmentAdminUsersCount: payload?.totalCount
            }
        }
        
        case GET_ALL_DEPARTMENT_ADMIN_USERS_FAILED: {
            return {
                ...state,
                departmentAdminUsers: initialState.departmentAdminUsers,
                departmentAdminUsersCount: initialState.departmentAdminUsersCount
            }
        }
        case GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_SUCCESS: {
            return {
                ...state,
                departmentAdminUsersActiveCount: payload
            }
        }
        case GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_FAILED: {
            return {
                ...state,
                departmentAdminUsersActiveCount: initialState.departmentAdminUsersActiveCount
            }
        }
        case GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_SUCCESS: {
            return {
                ...state,
                departmentAdminUsersDeactivatedCount: payload
            }
        }
        case GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_FAILED: {
            return {
                ...state,
                departmentAdminUsersActiveCount: initialState.departmentAdminUsersActiveCount
            }
        }         
        case GET_PENDING_USERS_SUCCESS: {
            return {
                ...state,
                pendingDepartmentUsers: payload?.pendingStatusUsers,
                pendingDepartmentUsersCount: payload?.count
            }
        }
        case GET_PENDING_USERS_FAILED: {
            return {
                ...state,
                pendingDepartmentUsers: initialState.pendingDepartmentUsers,
                pendingDepartmentUsersCount: initialState.pendingDepartmentUsersCount
            }
        }
        case ActionTypes.DEPARTMENT.VEHICLES_COUNT_SUCCESS: {
            return {
              ...state,
              totalVehicleCount: payload,
            };
          }
      
          case ActionTypes.DEPARTMENT.VEHICLES_COUNT_FAILURE: {
            return {
              ...state,
              totalVehicleCount: 0,
            };
          }
          case GET_ACCESS_TEMPLATE_COUNT_SUCCESS: {
            return {
                ...state,
                accessTemplateCount: payload.count 
            };
        }
        
        case GET_ACCESS_TEMPLATE_COUNT_FAILURE: {
            return {
                ...state,
                accessTemplateCount: 0
            };
        }

        case ActionTypes.DEPARTMENT.MASTER_MODULE_LIST_SUCCESS: {
            return {
                ...state,
                masterModuleList: payload,
            }
        }

        case ActionTypes.DEPARTMENT.MASTER_MODULE_LIST_FAILURE: {
            return {
                ...state,
                masterModuleList: initialState.masterModuleList,
            }
        }

        case ActionTypes.DEPARTMENT.TEMPLATE_LIST_SUCCESS: {
            return {
                ...state,
                templateList: payload,
            }
        }

        case ActionTypes.DEPARTMENT.TEMPLATE_LIST_FAILURE: {
            return {
                ...state,
                templateList: initialState.templateList,
            }
        }

        default:
            return state
    }


}

export default departmentReducers;
/*conflict-resolution-will be removed later*/