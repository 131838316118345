import './AlarmAnalyticsPIChart.css'
import AlarmAnalyticsDonutChart from '../AlarmAnalyticsDonutChart/AlarmAnalyticsDonutChart'
import { DrivingPIChartAlertConfiguration, BatteryPIChartAlertConfiguration } from '../../../../../../utils/constants'
import { useEffect, useState } from 'react'
import AlarmAnalyticsTable from '../AlarmAnalyticsTable/AlarmAnalyticsTable'
import { useDispatch, useSelector } from 'react-redux'
import ActionTypes from '../../../../../../store/actionTypes'

const { SET_ALARM_TYPE_FOR_TABLE } = ActionTypes.REPORTS.ALARM

const AlarmAnalyticsPIChart = (props) => {
  const [drivingAlertData, setDrivingAlertData] = useState([])
  const [batteryAlertData, setBatteryAlertData] = useState([])
  const [isOpenAlarms, setAlarms] = useState(false);
  const [isOpenBattery, setBattery] = useState(false);

  useEffect(()=>{
    calculateChart()
    // eslint-disable-next-line
  }, [props.data])
  const calculateChart = () => {
    const calDrivingAlertData = []
    const calBatteryAlertData = []
    const alarmData = props.data ? props.data : {}

    if(alarmData.drivingAlerts){
      for(let config of DrivingPIChartAlertConfiguration){
        const filteredDataCount = alarmData.drivingAlerts.countByType[config.type.key]
        config.count = filteredDataCount ? filteredDataCount : 0
        calDrivingAlertData.push(config)
      }
    }

    if(alarmData.batteryAlerts){
      for(let config of BatteryPIChartAlertConfiguration){  
        if(config.type.key === "Battery Overheat"){
          continue
        }
        const filteredDataCount = alarmData.batteryAlerts.countByType[config.type.key]
        config.count = filteredDataCount ? filteredDataCount : 0
        calBatteryAlertData.push(config)
      }
    }
    

    setDrivingAlertData(calDrivingAlertData.sort((a, b) => a.sort - b.sort))
    setBatteryAlertData(calBatteryAlertData.sort((a, b) => a.sort - b.sort))
  }


  const alarmTableData = useSelector((state) => state.alarm.alarmTableData)
  const dispatch = useDispatch()

  const tableClickHandler = (data) => {
    setAlarms(!isOpenAlarms)    
    dispatch({type: SET_ALARM_TYPE_FOR_TABLE, payload: data?.type?.key})

  };
  const tableBatteryClickHandler = (data) => {
    setBattery(!isOpenBattery)    
    dispatch({type: SET_ALARM_TYPE_FOR_TABLE, payload: data?.type?.key})
  };

  const LegendItem = ({ color, label }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: color,
        
        }}
      ></div>
      &nbsp;&nbsp;
      <span style={{fontSize:'12px' , color:'#1F2A5D '}}>{label}</span>
    </div>
  )
  return (
    <>
          <div
          style={{
            boxShadow: "0 2px 5px 2px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            padding: '10px',
            width: '48%',
            borderRadius: '2px'
          }}
          className='al__driving-container'
          >
              {isOpenAlarms && (
          <AlarmAnalyticsTable  
          isOpen={isOpenAlarms} 
          name={'Driving Alert Data'} 
          setAlarms={setAlarms} 
          alarmData={alarmTableData} />
        )}
            <div className='row'>
              <div className='col-12'>
                <div className='al_section-pi-chart-header'>Driving Alert</div>
              </div>
            </div>
            <div className='container-fuild-hr'>
              <div className='row row-hr'></div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <AlarmAnalyticsDonutChart data = {drivingAlertData} onChartClick = {tableClickHandler}></AlarmAnalyticsDonutChart>
              </div>
              <div className='col-12 al_section-pi-chart-legend-flex'>
                {DrivingPIChartAlertConfiguration.map((item, index) => (
                  <LegendItem key={'al__' + item.type.label + index} color={item.color} label={item.type.label} />
                ))}
              </div>
            </div>
          </div>
          <div style={{padding: '10px 0px', width: '4%'}}></div>
          <div
          style={{
            boxShadow: "0 2px 5px 2px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            padding: '10px',
            width: '48%',
            borderRadius: '2px',
          }}
          className='al__battery-container'
          >
                   {isOpenBattery && (
          <AlarmAnalyticsTable  
          isOpen={isOpenBattery} 
          name={'Battery Alert Data'} 
          setBattery={setBattery} 
          batteryData={alarmTableData} />
        )}
            <div className='row'>
              <div className='col-12'>
                <div className='al_section-pi-chart-header'>Battery Alert</div>
              </div>
            </div>
            <div className='container-fuild-hr'>
              <div className='row row-hr'></div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <AlarmAnalyticsDonutChart data = {batteryAlertData} onChartClick = {tableBatteryClickHandler}></AlarmAnalyticsDonutChart>
              </div>
              <div className='col-12 al_section-pi-chart-legend-flex'>
                {BatteryPIChartAlertConfiguration.map((item, index) => (
                  (item.type.label !== "Battery Over Heat" && <LegendItem key={'al__' + item.type.label + index} color={item.color} label={item.type.label} />)
                ))}
              </div>
            </div>
          </div>
    </>
  )
}

export default AlarmAnalyticsPIChart
