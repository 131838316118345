import React from 'react'
import { useDispatch } from 'react-redux';

import GPAddVehicleTable from '../gp-tables/gp-add-vehicle-table/GPAddVehicleTable';

import { customToast } from '../../../../utils/toasts';
import dashboardGroupsAction from '../../../../store/actions/dashboard/dashboardGroupsAction';

import "./GPCreateGroup.css"
import { GroupSave } from '../../../../store/actions/groups';

const GPCreateGroup = (props) => {
    const { groupData, activeSubgroupIndex, activeVehicleList, fetchGroupsList, onComplete,groupsAssignDepartmentAdmin ,allDashboardVehicleData } = props;
    
    let subgroupData = {}
    if (![null, undefined].includes(activeSubgroupIndex)) {
        subgroupData = groupData.subGroup[activeSubgroupIndex];
    }

    const dispatch = useDispatch();
    
    const selectAllVehicleRef = React.useRef()
    const [selectedVehicleIds, setSelectedVehicleIds] = React.useState([]);
    const [selectedAssignees, setselectedAssignees] =React.useState([])
    const [selectedAllVehicle, setSelectedAllVehicle] = React.useState(false);
    const [filteredActiveVehicleList, setFilteredActiveVehicleList] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);

    const [selectedOEM, setSelectedOEM] = React.useState([]);
    const [selectedCity, setSelectedCity] = React.useState([]);
    const [selectedVehicleType, setSelectedVehicleType] = React.useState([])
    const [selectedVehicleNumber, setSelectedVehicleNumber] = React.useState([])
    const [selectedChargingType, setSelectedChargingType] = React.useState([])
    const [savedFilters, setSavedFilters] = React.useState({
        oem: [],
        city: [],
        vehicleTypes:[],
        vehicleNumber:[],
        chargingType:[]
    });
    const [searchQuery, setSearchQuery] = React.useState('');



   

  
    React.useEffect(() => {
        let existingVehicleIds = [];
        if (groupData._id) {
            if (!subgroupData || !Object.keys(subgroupData).length) {
                existingVehicleIds = [...existingVehicleIds, ...groupData.vehicles];
            } else {
                groupData.subGroup.forEach(subgroup => {
                    existingVehicleIds = [...existingVehicleIds, ...subgroup.vehicles];
                })
            }    
        }
        
        if (!existingVehicleIds.length) {
            setFilteredActiveVehicleList(activeVehicleList);
        } else {
            setFilteredActiveVehicleList(activeVehicleList.filter(vehicle => !existingVehicleIds.includes(vehicle._id)));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSubgroupIndex])

    const handleVehicleChange = (vehicleId) => {

        let newSelectedVehicleIds = [...selectedVehicleIds]

        if (newSelectedVehicleIds.includes(vehicleId)) {
            dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
                vehicleIds: [vehicleId],
                isSubGroup: activeSubgroupIndex !== null,
                subgroupId: null,
                subgroupIndex: activeSubgroupIndex,
            }))
            newSelectedVehicleIds = newSelectedVehicleIds.filter(v => v !== vehicleId)
        } else {
            dispatch(dashboardGroupsAction.addVehiclesToGroup({
                vehicleIds: [vehicleId],
                isSubGroup: activeSubgroupIndex !== null,
                subgroupId: null,
                subgroupIndex: activeSubgroupIndex,
            }))
            newSelectedVehicleIds.push(vehicleId)
        }

        setSelectedVehicleIds([...newSelectedVehicleIds]);
    }

    const handleSelectAllChange = (isSelected, filteredVehicleList) => {
        
        setSelectedVehicleIds(isSelected ? filteredVehicleList : [])
        setSelectedAllVehicle(isSelected);
        if (isSelected) {
            dispatch(dashboardGroupsAction.addVehiclesToGroup({
                vehicleIds: [...filteredVehicleList],
                isSubGroup: activeSubgroupIndex !== null,
                subgroupId: null,
                subgroupIndex: activeSubgroupIndex,
            }))
        } 
       
        else {
            dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
                vehicleIds: [...filteredVehicleList],
                isSubGroup: activeSubgroupIndex !== null,
                subgroupId: null,
                subgroupIndex: activeSubgroupIndex,
            }))
        }
    }

    const handleSaveClick = async () => {
        const adminids = selectedAssignees.map(option => option.value);
      const data = {...groupData,assignees:adminids}
        const payload = {
			groupDetails: data,
		};
         const res = await GroupSave(payload);
     
        if (res.isCompleted === 1) {
            if (!subgroupData || !Object.keys(subgroupData).length) {
               
            customToast({ message: 'Group Created Successfully' , theme: "light"})
            } 
        } else {
            customToast({ message: res.message ,  theme: "light"})
        }
		
        setTimeout(() => {
            fetchGroupsList();
			onComplete("SAVE")
		}, 500)
    }

    const handleCancelClick = () => {
        onComplete("CANCEL")
    }
    
    return (
        <div className='row p-3 mb-2 overflow-group'>
            <div className='d-flex'>
                <p className='groups__edit__text'>
                    {groupData?.name}
                </p>
                {subgroupData?.name ? (
                    <>
                        <span className='groups__edit__text'> &#10095; </span>
                        <p className='groups__edit__text'>{subgroupData?.name}</p>
                    </>
                ) : null}
            </div>

            <GPAddVehicleTable
                groupsAssignDepartmentAdmin={groupsAssignDepartmentAdmin}
                vehicleList={filteredActiveVehicleList}
                allDashboardVehicleData={allDashboardVehicleData}
                selectedVehicleIds={selectedVehicleIds}
                onVehicleChange={handleVehicleChange}
                
                selectAllVehicleRef={selectAllVehicleRef}
                selectedAllVehicle={selectedAllVehicle}
                onSelectAllChange={handleSelectAllChange}

                onSaveClick={handleSaveClick}
                onCancelClick={handleCancelClick}
                selectedAssignees={selectedAssignees}
                onAssginDAChange={setselectedAssignees}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setSelectedChargingType={setSelectedChargingType}
                setSelectedVehicleNumber={setSelectedVehicleNumber}
                setSelectedVehicleType={setSelectedVehicleType}
                setSelectedCity={setSelectedCity}
                setSelectedOEM={setSelectedOEM}
                selectedChargingType={selectedChargingType}
                selectedVehicleNumber={selectedVehicleNumber}
                selectedVehicleType={selectedVehicleType}
                selectedCity={selectedCity}
                selectedOEM={selectedOEM}
                setSavedFilters={setSavedFilters}
                savedFilters={savedFilters}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
            />
        </div>
    )
}

export default GPCreateGroup;