import React from 'react'
import PropTypes from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import PopoverB from 'react-bootstrap/Popover'
import PopoverHeader from 'react-bootstrap/PopoverHeader'
import PopoverBody from 'react-bootstrap/PopoverBody'

import styles from './Popover.module.css'

const Popover = (props) => {
    const { triggerComponent, show, trigger, placement, headerComponent, bodyComponent } = props
    const otherProps = {
        trigger,
    }
    if (trigger === 'custom') {
        otherProps.trigger = 'click'
        otherProps.show = show
    }
    return (
        <OverlayTrigger
            {...otherProps}
            placement={placement}
            overlay={
                <PopoverB className={styles.popover}>
                    {headerComponent && (
                        <PopoverHeader className={styles.popoverHeader}>
                            {headerComponent}
                        </PopoverHeader>
                    )}
                    {bodyComponent && (
                        <PopoverBody className={styles.popoverBody}>
                            {bodyComponent}
                        </PopoverBody>
                    )}
                </PopoverB>
            }
        >
            {triggerComponent}
        </OverlayTrigger>
    )
}

Popover.defaultProps = {
    show: false,
    trigger: 'custom',
    headerComponent: null,
    bodyComponent: null,
    placement: 'right-start',
}

Popover.propTypes = {
    triggerComponent: PropTypes.element.isRequired,
    show: PropTypes.bool,
    trigger: PropTypes.oneOf('click', 'focus', 'hover', 'custom'),
    headerComponent: PropTypes.element,
    bodyComponent: PropTypes.element,
    placement: PropTypes.oneOf('right-start', 'right-end', 'right','left','left-start', 'left-end',  'bottom-start', 'bottom', 'bottom-end'),
}

export default React.memo(Popover);
/*conflict-resolution-will be removed later*/