import React, { Component } from 'react'
import NDVehicleListDetailCard from '../nd-vehicle-list-detail-card/NDVehicleListDetailCard'
import NoRecordFound from '../../../helpers/hoc/noRecordFound'

import './NDVehicleListing.css'

class NDVehicleListing extends Component {


  getFavoriteIcon = (fav) => {
    return `/images/svgicon/${fav ? "favorite-flag-enable-dark.svg" : "favorite-flag-disable-dark.svg"}`
  }

  getSocClass = (status, soc, socClass, nonCommunication) => {
    if (nonCommunication) {
      socClass += ' soc-non-communication';
    }

    // Note: Merge Idle status to Live status
    if (status === 'Live' || status === 'Idle') {
      socClass += ' soc-live';
    }
    if (status === 'Parked') {
      socClass += ' soc-parked';
    }
    if (status === 'Immobile') {
      socClass += ' soc-immobile';
    }
    if (status === 'Charging') {
      socClass += ' soc-charging';
    }

    if (soc >= 50 && soc <= 100) {
      socClass += ` p${(Math.round(soc) > 97 && Math.round(soc) < 100) ? 97 : Math.round(soc)}`;
    }
    return socClass;
  }

  getSOCRotationStyle = (soc) => {
    let rotation = `rotate(${((Math.round(soc) > 97 && Math.round(soc) < 100) ? 97 : Math.round(soc)) * (3.6)}deg)`;
    return {
      position: "absolute",
      border: "0.09em solid #000000",
      width: "0.82em",
      height: "0.82em",
      clip: "rect(0em, 0.5em, 1em, 0em)",
      borderRadius: "50%",
      MozTransformStyle: rotation,
      msTransform: rotation,
      WebkitTransform: rotation,
      transform: rotation
    }
  }

  getSOCOuterStyle = (soc) => {
    return {
      position: "absolute",
      width: "1em",
      height: "1em",
      clip: soc > 50 ? "rect(auto, auto, auto, auto)" : "rect(0em, 1em, 1em, 0.5em)"
    }
  }

  render() {
    if(this.props?.allVehiclesTrackingData && this.props?.allVehiclesTrackingData?.length >0){
      return (
        this?.props?.allVehiclesTrackingData?.map(vehicle => {
          vehicle.favorite = this.props.favouriteVehicles?.includes(vehicle.rn);
          return vehicle;
        }).sort((a, b) => Number(b.favorite) - Number(a.favorite)
        ).map(vehicle => {
          return <NDVehicleListDetailCard
            key={vehicle.rn}
            lat={vehicle.lt}
            lng={vehicle.lng}
            nonCommunication={vehicle.ncom}
            status={vehicle.canStatus.vs}
            soc={vehicle.canStatus.soc}
            registration_number={vehicle.rn}
            OEM={`${vehicle.canStatus.oem} ${vehicle.canStatus.mdl}`}
            favorite={vehicle.favorite}
            onCardClick={() => this?.props?.onCardClick({ registrationNumber: vehicle.rn, lat: vehicle.lt, long: vehicle.lng})}
            onFavoriteFlagClick={this?.props?.onFavoriteFlagClick}
            getFavoriteIcon={this.getFavoriteIcon}
            getSocClass={this.getSocClass}
            getSOCRotationStyle={this.getSOCRotationStyle}
            getSOCOuterStyle={this.getSOCOuterStyle}
          ></NDVehicleListDetailCard>
        }))
    }else{
      return (
        <>
          <NoRecordFound />
          <span style={{ color: "white", textAlign: "center", margin: "auto", display: "block" }}>
            No vehicle found for the selected filters
          </span>
        </>
      );
    }
    
  }
}

export default NDVehicleListing