import ActionTypes from "../actionTypes";
const otpActionTypes = ActionTypes.OTP;
const smsOtpActionTypes = ActionTypes.SMS_OTP;

const initialState = {
  isOtpSend: false,
  isOtpVerified: false,
  isSmsOtpSend:false,
  isSmsOtpVerified:false,
  otpTimerKey:0,
  otpFieldDisabled: false
};

const otpReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case otpActionTypes.OTP_SEND_SUCCESS:
      return {
        ...state,
        isOtpSend: payload,
        otpTimerKey: state.otpTimerKey + 1,
        otpFieldDisabled: false
      };
      case otpActionTypes.DISABLE_OTP_FIELD:
        return{
          ...state,
          otpFieldDisabled: true
        }
      case otpActionTypes.OTP_VERIFY_SUCCESS:
        return {
          ...state,
          isOtpVerified: payload
        };
      case smsOtpActionTypes.SMS_OTP_SEND_SUCCESS:
        return {
          ...state,
          isSmsOtpSend: payload,
          otpTimerKey: state.otpTimerKey + 1,
          otpFieldDisabled: false
        };
      case otpActionTypes.OTP_STATE_RESET:
        return initialState;
    default:
      return state;
  }
};

export default otpReducer;