import React, {useCallback, useEffect } from 'react'
import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BsDownload, BsX } from "react-icons/bs";
import './OemModelTripReport.css'
import Pagination from '../../../../../helpers/hoc/paginator/Pagination';
import { BiInfoCircle } from 'react-icons/bi';
import { connect } from 'react-redux';
import { setReportCurrentPage, exportOemCityWiseTripReport } from '../../../../../../store/actions/reports/analytics/trip-report'
import { getFormattedDate } from '../../../../../../utils/common-methods';
import { addEscapeKeyListener } from '../../../../../../utils/common-methods';
import { formatter } from '../../../../../../utils/formatter';

const OemModelTripReport = (
  { showModal, 
    setIsShowModal,
    setReportCurrentPage,
    reportOemCityWiseTripData,
    reportOemCityWiseTripDataCount,
    currentPage,
    exportOemCityWiseTripReport,
    title=''
  }) => {

  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, [setIsShowModal]);

  const CustomCloseButton = ({ onClick }) => {
    return (
      <div className="popup-close" onClick={onClick}>
        <BsX size={24} />
      </div>
    );
  };

  function renderTooltipLog(props) {
    return (
      <Tooltip className="tooltip" id="button-tooltip" {...props}>
        {props.parameter1}: {props.start} 
        {props?.unit ? props.unit : ""} <br />
        {props.parameter2}: {props.end} 
        {props?.unit ? props.unit : ""}
      </Tooltip>
    );
  }

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      handleClose();  
    });
  
    return () => {
      removeListener();  
    };
  }, [handleClose]);

  return (
    <>
      <Modal
        onHide={handleClose}
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
         <Modal.Header
          style={{
            display:'flex',
            flexDirection:'row-reverse'
           }}
           >
            <CustomCloseButton onClick={handleClose} />
         </Modal.Header>
         <Modal.Body className='oem-trip-report-body'>
         <div className="header-container- d-flex justify-content-between">
            <div className="title w-100 mr-2">
              <div className="row" style={{ height: "85px" }}>
                <div className="col-3 d-inline-flex align-items-center"
                style={{
                  width:'fit-content'
                }}
                >
                  <h5 style={{marginTop:'40px'}}>{title}</h5>
                </div>
              </div>
            </div>
            <div className="download-btn">
              <button
               style={{position:'absolute',    top: '31px',
               right:' 17px'}}
                className="dwn-btn-oem-model"
                onClick={exportOemCityWiseTripReport}
              >
                <BsDownload style={{width:'22px',height:'22px'}}/>
              </button>
            </div>
          </div>
          <div className="oem-trip-report-box mb-4 mt-4 position-relative">
            <Table responsive={true} borderless id="oem-trip-report-model" className="oem-trip-report-table-header">
              <thead className="table-header">
                <th className="ps-3 text-nowrap"><p className='fw500'>S.No</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Vehicle No.</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>OEM & Model</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>City</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Duration</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Distance</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Energy Utilized</p></th>
              </thead>
              <tbody>
                {reportOemCityWiseTripData.map((Obj, index) => (
                  <tr key={"row-" + index.toString()}>
                    <td className="ps-3">
                      {8 * (currentPage - 1) + index + 1}
                    </td>
                    <td>{Obj.registration_number}</td>
                    <td>{Obj.oem+ " " + Obj.model}</td>
                    <td>{Obj.city}</td>
                    <td>
                            <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                              {Obj?.duration}

                              <OverlayTrigger
                                placement="auto-end"
                                delay={{ show: 0, hide: 0 }}
                                overlay={renderTooltipLog({
                                  parameter1: "Start Time",
                                  parameter2: "End Time",
                                  start: getFormattedDate(Obj.startDateTime),
                                  end: getFormattedDate(Obj.endDateTime),
                                })}
                              >
                                <button className="row-btn">
                                  <BiInfoCircle className="row-i-icon-trip-report" />
                                </button>
                              </OverlayTrigger>
                            </div>
                    </td>
                    <td>
                            <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                              {`${Math.round(Obj?.distance)} kms`}

                              <OverlayTrigger
                                placement="auto-end"
                                delay={{ show: 0, hide: 0 }}
                                overlay={renderTooltipLog({
                                  parameter1: 'Start Odometer',
                                  parameter2: 'End Odometer',
                                  start: formatter.withUnitOrDefault({ value: Obj.startOdo, unit: '' }),
                                  end: formatter.withUnitOrDefault({ value: Obj.endOdo, unit: '' }), 
                                  unit : `kms`
                                })}
                              >
                                <button className="row-btn">
                                  <BiInfoCircle className="row-i-icon-trip-report" />
                                </button>
                              </OverlayTrigger>
                            </div>
                    </td>
                    <td>
                        <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                          {Obj?.energyUtilized} kWh

                          <OverlayTrigger
                            placement="auto-end"
                            delay={{ show: 0, hide: 0 }}
                            overlay={renderTooltipLog({
                              parameter1: 'Start State of Charge',
                              parameter2: 'End State of Charge',
                              start: formatter.withUnitOrDefault({ value: Math.round(parseFloat(Obj.startSoC) * 10) / 10 }),
                              end: formatter.withUnitOrDefault({ value: Math.round(parseFloat(Obj.endSoC) * 10) / 10  })
                            })}
                          >
                            <button className="row-btn">
                              <BiInfoCircle className="row-i-icon-trip-report" />
                            </button>
                          </OverlayTrigger>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              disabled={false}
              totalPages={Math.ceil(reportOemCityWiseTripDataCount / 8)}
              currentPage={currentPage}
              setPage={setReportCurrentPage}
            />
          </div>
         </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.tripReportAnalytics.currentPage,
  reportOemCityWiseTripData: state.tripReportAnalytics.reportOemCityWiseTripData,
  reportOemCityWiseTripDataCount: state.tripReportAnalytics.reportOemCityWiseTripDataCount
});

export default connect(mapStateToProps, {
  setReportCurrentPage,
  exportOemCityWiseTripReport
})(OemModelTripReport);
