import React, {useState} from 'react'
import { DateRangePicker } from 'rsuite'
import './SingleDatePicker.css'

const SingleDatePicker = ({ startDate, endDate, maxRangeDays = 7, isFututeDatesDisable = false, onDateChange, ...props } , ref) => {
  const baseTestId = props['data-testid'] || 'single-datepicker'
  const [dStartDate, setStartDate] = useState(startDate)
  const [dEndDate, setEndDate] = useState(endDate)
  const { after, combine, allowedMaxDays, afterToday } = DateRangePicker

  const handleDateChange = (value) => {
    setStartDate(value[0])
    setEndDate(value[1])
    const sDate = value[0] ? new Date(value[0].setHours(0,0,0,0)) : value[0]
    const eDate = value[1] && !isEndDateEqualtoToday(value[1]) ? new Date(value[1].setHours(23, 59, 59, 999)) : value[1]
    onDateChange(sDate, eDate)
  }

  const isEndDateEqualtoToday = (date1) => {
    const date2 = new Date()
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const onDatePickerOpen = () => {
    const proceedButtonElement = document.querySelector('.rs-picker-toolbar-right button')
    if (proceedButtonElement && !proceedButtonElement.getAttribute('data-testid')) {
      proceedButtonElement.setAttribute('data-testid', `${baseTestId}-proceed-button`)
    }
    
    const selectMonthElement = document.querySelector('.rs-calendar-header-month-toolbar .rs-calendar-header-title')
    if (selectMonthElement && !selectMonthElement.getAttribute('data-testid')) {
      selectMonthElement.setAttribute('data-testid', `${baseTestId}-select-month-button`)
    }

    const previousMonthElement = document.querySelector('.rs-calendar-header-month-toolbar .rs-calendar-header-backward')
    if (previousMonthElement && !previousMonthElement.getAttribute('data-testid')) {
      previousMonthElement.setAttribute('data-testid', `${baseTestId}-previous-month-button`)
    }
    
    const nextMonthElement = document.querySelector('.rs-calendar-header-month-toolbar .rs-calendar-header-forward')
    if (nextMonthElement && !nextMonthElement.getAttribute('data-testid')) {
      nextMonthElement.setAttribute('data-testid', `${baseTestId}-next-month-button`)
    }
  }

  return (
    <div>
      <DateRangePicker
        shouldDisableDate={isFututeDatesDisable ? afterToday() : combine( allowedMaxDays(maxRangeDays) ,after(new Date()))}
        className='drp_SingleDatePicker'
        value={[dStartDate, dEndDate]}
        onChange={handleDateChange}
        showOneCalendar
        ranges={[]}
        format="dd-MMM-yyyy"
        ref = {ref}
        style={{position: 'absolute'}}
        cleanable={false}
        placeholder = 'dd-mmm-yyyy ~ dd-mmm-yyyy'
        data-testid={`${baseTestId}-picker`}
        onOpen={onDatePickerOpen}
      />
    </div>
  )
}

export default React.forwardRef(SingleDatePicker)
