import API from '../../service/api';
import ActionTypes from '../actionTypes';
import API_BOOK from '../../service/endpoints';
import { customToast } from '../../utils/toasts';
const {GROUPS_LIST ,GROUPS_DELETE ,GROUPS_RENAME , GROUPS_SAVE ,GROUPS_EDIT, GET_ACTIVE_VEHICLE ,GET_ASSIGN_DEPARTMENT_ADMIN ,GET_ASSIGN_DEPARTMENT_USER, SUB_GROUPS_SAVE,GET_ASSIGN_LIST} = API_BOOK.GROUPS;
const getGroupsTypeList = ActionTypes.GROUPS;

export const getGroupslist = (payload) => ({
	type: getGroupsTypeList.GROUPS_LIST,
	payload,
});

const getGroupsDelete = (payload) => ({
	type: getGroupsTypeList.GROUPS_DELETE,
	payload,
});

const putGroupsRename = (payload) => ({
	type: getGroupsTypeList.GROUPS_RENAME,
	payload,
});

const getGroupsEdit = (payload) => ({
	type: getGroupsTypeList.GROUPS_EDIT,
	payload,
});

const getAssignDepartment = (payload) => ({
    type: getGroupsTypeList.GET_ASSIGN_DEPARTMENT_ADMIN,
    payload,
});

const getAssignDepartmentUser = (payload) => ({
    type: getGroupsTypeList.GET_ASSIGN_DEPARTMENT_USER,
    payload,
});

const getAssignList = (payload) => ({
    type: getGroupsTypeList.GET_ASSIGN_LIST,
    payload,
});




/**
 *  @desc         	Get all groups list
 *  @author      	Amir Shaikh
 *  @api          	/group/list/:_id
 *  @method       	GET
 *  @createdDate  	23-NOV-2023
 *  @modifiedDate 	23-NOV-2023
 **/
export const fetchGroupList = async (userId) => {
	let groupList = []
	try {
		const res = await API({
			url: GROUPS_LIST.url.replace(':_id', userId),
			method: GROUPS_LIST.method,
		});
		if (res.status === 200 && res?.data?.data) {
			groupList = res?.data?.data;
		}
	} catch (error) {
		console.log("Group list fetch failed", error);
	} finally {
		return groupList;
	}
}


/**
 *  @desc         Delete group by id
 *  @author       Ankit Kushwaha
 *  @api          /group/delete/:_id
 *  @method       GET
 *  @createdDate  04-NOV-2023
 *  @modifiedDate 04-NOV-2023
 **/

export const GetGroupsDelete = ({ groupId, subgroupId, isSubgroup }) => async (dispatch) => {
	try {
		const res = await API({
			url: GROUPS_DELETE.url.replace(':_id', isSubgroup ? subgroupId : groupId),
			method: GROUPS_DELETE.method,
		});
		if (res.status === 200 && res?.data?.data) {
			const response = res?.data?.data;
			setTimeout(async () => {
				customToast({ message: isSubgroup ? 'Subgroup Deleted Successfully' : 'Group Deleted Successfully', theme:"light" })
			}, 200);
			dispatch(getGroupsDelete(response));
		} else {
			dispatch(getGroupsDelete());
		}
	} catch (error) {
		dispatch(getGroupsDelete());
		console.log('Error getting ', error);
	}
}



/**
 *  @desc        Rename group by id
 *  @author      Ankit Kushwaha
 *  @api          /group/rename/:_id
 *  @method       PUT	
 *  @createdDate  04-NOV-2023
 *  @modifiedDate 04-NOV-2023
 **/

export const PutGroupsRename = (groupId,data) => async (dispatch) => {
	
	try {
		const res = await API({
			url: GROUPS_RENAME.url.replace(':_id', groupId),
			method: GROUPS_RENAME.method,
			data
		});
		if (res.status === 200 && res?.data) {
			const response = res?.data;
			setTimeout(async () => {
				customToast({ message: 'Group Saved Successfully' , theme:"light" })
			}, 200);
			dispatch(putGroupsRename(response));
		} else {
			dispatch(putGroupsRename());
		}
	} catch (error) {
		dispatch(putGroupsRename());
		console.log('Error getting ', error);
	}
}


/**
 *  @desc        	Save group by id or create new group by id
 *  @author      	Amir Shaikh
 *  @api         	/group/save-group
 *  @method       	POST
 *  @createdDate  	23-NOV-2023
 *  @modifiedDate 	23-NOV-2023
 **/

export const GroupSave = async (data) => {
	let isCompleted = 0;
	let message = '';
	try {
		data.groupDetails = {
			_id:data.groupDetails._id,
			name:data.groupDetails.name,
			vehicles:data.groupDetails.vehicles,
			adminIds : data.groupDetails.assignees
		}

	

		// remove key
		delete data.groupDetails.assignees;

		const res = await API({
			url: GROUPS_SAVE.url,
			method: GROUPS_SAVE.method,
	      	data
		});

		if (res.status === 200 && res?.data?.data) {
			isCompleted = 1;
		} else {
			isCompleted = 0;
			message = res?.data?.message;
		}
	} catch (error) {
		isCompleted = -1;
		message = 'Something went wrong! Please contact support!';
	} finally {
		return { isCompleted, message }
	}
}



export const EditSubGroupSave = async (data) => {
	let isCompleted = 0;
	let message = '';



	try {
		const payload = {
			groupId:data.groupDetails._id,
			name:data.groupDetails.name,
			vehicles:data.groupDetails.vehicles,
			adminIds : data.groupDetails.assignees,
			subgroups: data.groupDetails.subGroup
		}

	

		const res = await API({
			url: SUB_GROUPS_SAVE.url,
			method: SUB_GROUPS_SAVE.method,
	      	data:payload
		});

		if (res.status === 200 && res?.data?.data) {
			isCompleted = 1;
		} else {
			isCompleted = 0;
			message = res?.data?.message;
		}
	} catch (error) {
		isCompleted = -1;
		message = 'Something went wrong! Please contact support!';
	} finally {
		return { isCompleted, message }
	}
}




export const SubGroupSave = async (data) => {
	let isCompleted = 0;
	let message = '';

	try {
		// Make the API request with the structured payload
		const res = await API({
			url: SUB_GROUPS_SAVE.url,
			method: SUB_GROUPS_SAVE.method,
			data: data,
		});

		if (res.status === 200 && res?.data?.data) {
			isCompleted = 1;
		} else {
			isCompleted = 0;
			message = res?.data?.message;
		}
	} catch (error) {
		isCompleted = -1;
		message = 'Something went wrong! Please contact support!';
	} finally {
		return { isCompleted, message };
	}
};


/**
 *  @desc       GET DATA Edit group by id 
 *  @author      Ankit Kushwaha
 *  @api          /group/edit/:_id
 *  @method       GET
 *  @createdDate  04-NOV-2023
 *  @modifiedDate 04-NOV-2023
 **/

export const GetGroupsEdit = (userId) => async (dispatch) => {
	try {
		const res = await API({
			url: GROUPS_EDIT.url.replace(':_id',userId),
			method: GROUPS_EDIT.method,
		});
		if (res.status === 200 && res?.data?.data) {
		
			const response = res?.data?.data;
			dispatch(getGroupsEdit(response));
		} else {
			dispatch(getGroupsEdit());
		}
	} catch (error) {
		dispatch(getGroupsEdit());
		console.log('Error getting ', error);
	}
}

/**
 *  @desc         Get Active vehicle
 *  @author       Ankit Kushwaha
 *  @api          /vehicle/onboarding/get-active-vehicle
 *  @method       GET
 *  @createdDate  04-NOV-2023
 *  @modifiedDate 04-NOV-2023
 **/
export const getActiveVehicle = async() => {
	try {
		const res = await API({
			url: GET_ACTIVE_VEHICLE.url,
			method: GET_ACTIVE_VEHICLE.method,
		})
		let data = {}
		if (res.status === 200 && res?.data?.data) {
			data = res?.data?.data
		}
		return data
	} catch (error) {
		console.log(`error while fetching get active vehicle ${error}`)
	}
}

/**
 *  @desc         Get Group Assign Department Admin
 *  @author       Ankit Kushwaha
 *  @api          /group/get-da
 *  @method       GET
 *  @createdDate  19-AUG-2024
 *  @modifiedDate 
 **/
export const GetGroupAssignDepartmentAdmin = () => {
    return async (dispatch) => {
        try {
            const res = await API({
                url: GET_ASSIGN_DEPARTMENT_ADMIN.url,
                method: GET_ASSIGN_DEPARTMENT_ADMIN.method,
            });

            if (res.status === 200 && res?.data?.data) {
                const data = res.data.data;


                // Dispatch the plain action object after async call
                dispatch(getAssignDepartment(data));
            }
        } catch (error) {
            console.error(`Error while fetching Group Assign Department Admin: ${error}`);
        }
    };
};

/**
 *  @desc         Get Group Assign Department User
 *  @author       Ankit Kushwaha
 *  @api          /group/get-da
 *  @method       GET
 *  @createdDate  19-AUG-2024
 *  @modifiedDate 
 **/
export const GetGroupAssignDepartmentUser = () => {
    return async (dispatch) => {
        try {
            const res = await API({
                url: GET_ASSIGN_DEPARTMENT_USER.url,
                method: GET_ASSIGN_DEPARTMENT_USER.method,
            });

            if (res.status === 200 && res?.data) {
                const data = res.data.data;
				
				
                // Dispatch the plain action object after async call
                dispatch(getAssignDepartmentUser(data));
            }
        } catch (error) {
            console.error(`Error while fetching Group Assign Department Admin: ${error}`);
        }
    };
};





/**
 *  @desc         Get Group Assign List
 *  @author       Ankit Kushwaha
 *  @api         /group/get-assigned-list
 *  @method       GET
 *  @createdDate  22-AUG-2024
 *  @modifiedDate 
 **/
export const GetGroupAssignListUser =  (userIds) =>
	async (dispatch) => {

		
	  try {
		const res = await API({
			url: GET_ASSIGN_LIST.url,
			method: GET_ASSIGN_LIST.method,
		  data: {
			userIds:userIds
		  },
		});
		if (res.status === 200 && res?.data) {
			const data = res.data.data;
			
			dispatch(getAssignList(data));
		}
	  } catch (error) {
		console.log("Assign List error", error);
	  }
	};