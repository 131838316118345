import React from 'react';
import '../css/VehicleStatus.css';

const VehicleStatusRecordsDetails = (props) => {
	const { recordDetail, lastIndex } = props;


	const getTime = (date) => {
		return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
	};
	const convrtToHrs = (time) => {
		let timeArr = time.split(':');
		let hrs = timeArr[1];
		let min = timeArr[2];
		return `${hrs} : ${min} hrs`;

	};




	const renderStatusBlock = (attribute1, attribute2, value1, value2, value3) => (
		<div className='Vs__records__details__container'>
			<div>
				<img
					className='dvs__vehicleLine-img'
					src='/images/svgicon/recordsVehicleIcon/VerticalLine.svg'
					alt='logo'
				/>
			</div>
			<div className='Vs__records__details__time'>
				{attribute1 ? (
					<div className='Vs__records__details__start__time'>
						<p className='Vs__records__details__start__time__text'>{attribute1}</p> <span>:</span>
						<p className='Vs__records__details__start__time__value'>{value1}</p>
					</div>) : <div className='Vs__records__details__start__time'>
					<p className='Vs__records__details__start__time__text'>{attribute1}</p> <span className='Vs__records__details__start__time__blank'>--</span>
					<p className='Vs__records__details__start__time__value'>{value1}</p>
				</div>}
				<div className='Vs__records__details__end__time'></div>
				{attribute2 ? (
					<div className='Vs__records__details__end__time__contain'>
						<p className='Vs__records__details__end__time__text'>{attribute2}</p> <span>:</span>
						<p className='Vs__records__details__end__time__value'>{value2}</p>
					</div>
				) : <div className='Vs__records__details__end__time__contain'>
					<p className='Vs__records__details__end__time__text'>{attribute2}</p> <span className='Vs__records__details__end__time__blank' >--</span>
					<p className='Vs__records__details__end__time__value'>{value2}</p>
				</div>}

			</div>
			<div>
				<p className='Vs__records__details__endT__duration'>{value3}</p>
			</div>
			<div className='Vs__records__details__dote'>
				{attribute1 ? (
					<img className='dvs__vehicle-img' src='/images/svgicon/recordsVehicleIcon/dotesLine.svg' alt='logo' />
				) : null}
			</div>
		</div>
	);
	return (
		<>
			<tr className={`${lastIndex ? 'Rc__records__row_last' : ''}`}>
				<td style={{ maxWidth: 10, padding: '0 !important' }} className={`Rc__records-line-col`}>
					<p className='fw500 Rc__records__row__td'></p>
				</td>


				<td className='ps-3 text-nowrap'>
					<table>
						<tbody>
							<tr className='Rc__records__row '>

								{(() => {
									switch (recordDetail.status) {

										case 'Charging':
											return (
												<td>
													<p className='fw500 Rc__records__row__td' >
														<p style={{ padding: '5px 0', position: 'relative' }}>
															<img
																style={{ float: 'left' }}
																className='dvs__vehicle-img'
																src={`/images/svgicon/recordsVehicleIcon/charging.svg`}
														
																alt={recordDetail.status}
															/>
															<span  className='rc__vehicle_status__heading'>{recordDetail.status}</span> 
														</p>
													</p>
													<div style={{ marginTop: ' 33px' }}>

														<hr></hr>
													</div>

													<div className='Rc__records__row__vs__details'>
														{renderStatusBlock(
															'Start Time',
															'End Time',
															getTime(recordDetail.startDateTime),
															getTime(recordDetail.endDateTime),
															convrtToHrs(recordDetail.duration)

														)}
														{renderStatusBlock(
															'Start Voltage',
															'End Voltage',
															recordDetail.startVbv,
															recordDetail.endVbv
														)}
														{renderStatusBlock(
															'Start SoC',
															'End SoC',
															recordDetail.startSoc ? recordDetail.startSoc + '%' : 'NA',
															recordDetail.endSoc ? recordDetail.endSoc + '%' : 'NA',
															recordDetail.socCharged ? recordDetail.socCharged.toFixed(2) + '%' : 'NA',
														)}
														{renderStatusBlock(
														)}
													</div>
												</td>
											);
										// Note: Merge Idle status to Live status
										// case 'Idle':
										// 	return (
										// 		<td>
										// 			<p className='fw500 Rc__records__row__td' >
										// 				<p style={{ padding: '5px 0', position: 'relative' }}>
										// 					<img
										// 						style={{ float: 'left' }}
										// 						className='dvs__vehicle-img'
										// 						src={`/images/svgicon/recordsVehicleIcon/idle.svg`}
										// 						alt={recordDetail.status}
										// 					/>
										// 					<span  className='rc__vehicle_status__heading'>{recordDetail.status}</span> 
										// 				</p>
										// 			</p>
										// 			<div style={{ marginTop: ' 33px' }}>

										// 				<hr></hr>
										// 			</div>

										// 			<div className='Rc__records__row__vs__details'>
										// 				{renderStatusBlock(
										// 					'Start Time',
										// 					'End Time',
										// 					getTime(recordDetail.startDateTime),
										// 					getTime(recordDetail.endDateTime),
										// 					convrtToHrs(recordDetail.duration)
										// 				)}
										// 				{renderStatusBlock(
										// 					'Start Voltage',
										// 					'End Voltage',
										// 					recordDetail.startVbv,
										// 					recordDetail.endVbv
										// 				)}
										// 				{renderStatusBlock(
										// 					'Start SoC',
										// 					'End SoC',
										// 					recordDetail.startSoc ? recordDetail.startSoc + '%' : 'NA',
										// 					recordDetail.endSoc ? recordDetail.endSoc + '%' : 'NA',
										// 					recordDetail.socDrain ? recordDetail.socDrain.toFixed(2) + '%' : 'NA',
										// 				)}
										// 				{renderStatusBlock(
										// 				)}
										// 			</div>
										// 		</td>
										// 	);
										case 'Parked':
											return (
												<td>
													<p className='fw500 Rc__records__row__td' >
														<p style={{ padding: '5px 0', position: 'relative' }}>
															<img
																style={{ float: 'left' }}
																className='dvs__vehicle-img'
																src={`/images/svgicon/recordsVehicleIcon/parked.svg`}
																alt={recordDetail.status}
															/>
															<span  className='rc__vehicle_status__heading'>{recordDetail.status}</span> 
														</p>
													</p>
													<div style={{ marginTop: ' 33px' }}>

														<hr></hr>
													</div>

													<div className='Rc__records__row__vs__details'>
														{renderStatusBlock(
															'Start Time',
															'End Time',
															getTime(recordDetail.startDateTime),
															getTime(recordDetail.endDateTime),
															convrtToHrs(recordDetail.duration)
														)}
														{renderStatusBlock(

														)}
														{renderStatusBlock(

														)}
														{renderStatusBlock(

														)}
													</div>
												</td>
											);
										case 'Idle':
										case 'Live':
											return (
												<td>
													<p className='fw500 Rc__records__row__td' >
														<p style={{ padding: '5px 0', position: 'relative' }}>
															<img
																style={{ float: 'left' }}
																className='dvs__vehicle-img'
																src={`/images/svgicon/recordsVehicleIcon/live.svg`}
																alt={recordDetail.status}
															/>
															<span  className='rc__vehicle_status__heading'>{recordDetail.status}</span> 
														</p>
													</p>
													<div style={{ marginTop: ' 33px' }}>

														<hr></hr>
													</div>

													<div className='Rc__records__row__vs__details'>
														{renderStatusBlock(
															'Start Time',
															'End Time',
															getTime(recordDetail.startDateTime),
															getTime(recordDetail.endDateTime),
															convrtToHrs(recordDetail.duration)
														)}
														{renderStatusBlock(
															'Start Voltage',
															'End Voltage',
															recordDetail.startVbv ? recordDetail.startVbv : 'NA',
															recordDetail.endVbv ? recordDetail.endVbv : 'NA'

														)}

														{renderStatusBlock(
															'Start SoC',
															'End SoC',
															recordDetail.startSoc ? recordDetail.startSoc + '%' : 'NA',
															recordDetail.endSoc ? recordDetail.endSoc + '%' : 'NA',
															recordDetail.socDrain ? recordDetail.socDrain.toFixed(2) + '%' : 'NA',
														)}

														{renderStatusBlock(
															'Start Odometer',
															'End Odometer',
															recordDetail.startOdometer + ' kms',
															recordDetail.endOdometer + ' kms',
															recordDetail.runKms.toFixed(2) + ' kms'
														)}
													</div>
												</td>
											);
										default:
											return null;
									}
								})()}

							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</>
	);
};

export default VehicleStatusRecordsDetails;
