import api from '../../../service/api';
import ActionTypes from '../../actionTypes';
import API_BOOK from '../../../service/endpoints';
import { fileDownloader } from '../../../utils/common-methods';
const { CUSTOMERS_LIST, CREATE_CUSTOMERS, UPDATE_CUSTOMERS, CUSTOMERS_EXPORT_LIST ,UPDATE_STATUS_CUSTOMERS} = API_BOOK.ADMINISTRATION;
const getAdministrationTypeList = ActionTypes.ADMINISTRATION;

export const getAdministrationCustomerslist = (payload) => ({
	type: getAdministrationTypeList.ADMINISTRATION_CUSTOMER_LIST,
	payload,
});





/**
 *  @desc         	Get Customers list
 *  @author      	Ankit Kushwaha
 *  @api          	/customers/list
 *  @method       	POST
 *  @createdDate  	28-MARCH-2024
 *  @modifiedDate 	28-MARCH-2024
 **/
export const fetchCustomerList = ({
	fromDate,
	toDate,
	searchText,
	sortBy,
	sortOrder,
	pageIndex,
	pageSize,
}) =>
	async (dispatch) => {
		try {
			const res = await api({
				url: CUSTOMERS_LIST.url,
				method: CUSTOMERS_LIST.method,
				data: {
					fromDate,
					toDate,
					searchText,
					sortBy,
					sortOrder,
					pageIndex,
					pageSize,
				},
			});



			if (res.status === 200 && res?.data?.data) {
				const response = res?.data?.data;
				dispatch(getAdministrationCustomerslist(response));
			}
		} catch (error) {
			console.error("Customers list fetch failed", error);
		}
	};


/**
 *  @desc         	create Customers
 *  @author      	Ankit Kushwaha
 *  @api          	/customers/create
 *  @method       	POST
 *  @createdDate  	28-MARCH-2024
 *  @modifiedDate 	28-MARCH-2024
 **/
export const CreateCustomer = async ({ data }) => {

	try {
		const resp = await api({
			method: CREATE_CUSTOMERS.method,
			url: CREATE_CUSTOMERS.url,
			data
		});
		return resp;
	} catch (error) {

		console.error("An error occurred while creating customer:", error);

	}

}


/**
 *  @desc         	edit Customers
 *  @author      	Ankit Kushwaha
 *  @api          	/customers/update
 *  @method       	POST
 *  @createdDate  	28-MARCH-2024
 *  @modifiedDate 	28-MARCH-2024
 **/
export const EditCustomer = async ({ data })  => {
	try {
		const resp = await api({
			method: UPDATE_CUSTOMERS.method,
			url: UPDATE_CUSTOMERS.url,
			data,

		});

		
		return resp;
	} catch (error) {

		console.error("An error occurred while creating customer:", error);

	}
}


/**
 *  @desc         Telematics Fleet Summary Export to excel API
 *  @author       Ankit Kushwaha
 *  @api          /customers/list/export
 *  @method       POST
 *  @createdDate  28-MARCH-2024
 *  @modifiedDate 28-MARCH-2024
 **/
export const exportExcelCustomer = async ({ data })  => {
	try {
		const res = await api({
			url: CUSTOMERS_EXPORT_LIST.url,
			method: CUSTOMERS_EXPORT_LIST.method,
			responseType: "arraybuffer",
			data: {
				searchText: data?.searchText,
				sortBy: data?.sortBy,
				sortOrder: data?.sortOrder,
				// timezoneOffset: new Date().getTimezoneOffset(),
			}
		})
		if (res) {
			fileDownloader({
				arrayBuffer: res?.data,
				fileName: "customer_list.xlsx",
			})
		}
	} catch (error) {
		console.log('customer_list error', error)
	}
}


/**
 *  @desc         	Active Status Customers
 *  @author      	Ankit Kushwaha
 *  @api          	/customers/update-status
 *  @method       	POST
 *  @createdDate  	28-MARCH-2024
 *  @modifiedDate 	28-MARCH-2024
 **/
export const ActiveStatusCustomer = async ({ data }) => {
	try {
		const resp = await api({
			method: UPDATE_STATUS_CUSTOMERS.method,
			url: UPDATE_STATUS_CUSTOMERS.url,
			data,

		});

		return resp;
	} catch (error) {

		console.error("An error occurred while creating customer:", error);

	}
}
/*conflict-resolution-will be removed later*/



