import React from 'react';
import styles from './Modal.module.css'


const Modal = ({ isVisible, closeModal , name, handleUpdateStatus, status : isNotActive}) => {

  return isVisible && (
    <div className={`${styles.activeModalContainer}`}>

      <div className={`${styles.activeModal}`}>
        <img  src='/images/illustration.svg' alt='illustration' width={120} height={120}/>
        <span className={`${styles.activeModalText}`}>ARE  YOU SURE ?</span>
        <span className={`${styles.activeModalTextConfirm}`}>You want to {(isNotActive ? 'Activate' : 'Deactivate')} {name}</span>

        <div className={`${styles.buttonContainer}`}>
          <span className={`${styles.modal_button} ${styles.no}`} onClick={closeModal}>No</span>
          <span className={`${styles.modal_button} ${styles.yes}`}  onClick={handleUpdateStatus}>Yes</span>
        </div>
      </div>
    </div>
  );
};

export default Modal;
