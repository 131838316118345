//vehicles images according to oem and models
const vehicleImageMapByName = {
    "hiload": {
        default: "/images/svgicon/vehicleIcon/euilerHiLoad/ignitionOn.svg",
        ignitionOn: "/images/svgicon/vehicleIcon/euilerHiLoad/ignitionOn.svg",
        ignitionOff: "/images/svgicon/vehicleIcon/euilerHiLoad/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/euilerHiLoad/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/euilerHiLoad/vehicleTracking.svg",
    },
    "ape extra": {
        default: "/images/svgicon/vehicleIcon/piaggio/ignitionOn.svg",
        ignitionOn: "/images/svgicon/vehicleIcon/piaggio/ignitionOn.svg",
        ignitionOff: "/images/svgicon/vehicleIcon/piaggio/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/piaggio/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/piaggio/vehicleTracking.svg",
    },
    "sunmobility": {
        default: "/images/svgicon/vehicleIcon/piaggio/ignitionOn.svg",
        ignitionOn: "/images/svgicon/vehicleIcon/piaggio/ignitionOn.svg",
        ignitionOff: "/images/svgicon/vehicleIcon/piaggio/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/piaggio/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/piaggio/vehicleTracking.svg",
    },
    "rage+": {
        default: "/images/svgicon/vehicleIcon/osmRage/ignitionOn.svg",
        ignitionOn: "/images/svgicon/vehicleIcon/osmRage/ignitionOn.svg",
        ignitionOff: "/images/svgicon/vehicleIcon/osmRage/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/osmRage/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/osmRage/vehicleTracking.svg",
    },
    "grand zor": {
        default: "/images/svgicon/vehicleIcon/mahindraGrandZor/ignitionOn.svg",
        ignitionOn:
            "/images/svgicon/vehicleIcon/mahindraGrandZor/ignitionOn.svg",
        ignitionOff:
            "/images/svgicon/vehicleIcon/mahindraGrandZor/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/mahindraGrandZor/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/mahindraGrandZor/vehicleTracking.svg",
    },
    "neev": {
        default: "/images/svgicon/vehicleIcon/altiGreen/ignitionOn.svg",
        ignitionOn: "/images/svgicon/vehicleIcon/altiGreen/ignitionOn.svg",
        ignitionOff: "/images/svgicon/vehicleIcon/altiGreen/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/altiGreen/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/altiGreen/vehicleTracking.svg",
    },
    "exponent": {
        default: "/images/svgicon/vehicleIcon/altiGreen/ignitionOn.svg",
        ignitionOn: "/images/svgicon/vehicleIcon/altiGreen/ignitionOn.svg",
        ignitionOff: "/images/svgicon/vehicleIcon/altiGreen/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/altiGreen/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/altiGreen/vehicleTracking.svg",
    },
    "treo zor": {
        default: "/images/svgicon/vehicleIcon/mahindraTreoZor/ignitionOn.svg",
        ignitionOn:
            "/images/svgicon/vehicleIcon/mahindraTreoZor/ignitionOn.svg",
        ignitionOff:
            "/images/svgicon/vehicleIcon/mahindraTreoZor/ignitionOff.svg",
        nonCommunication:
            "/images/svgicon/vehicleIcon/mahindraTreoZor/nonCommunication.svg",
        vehicleTracking:
            "/images/svgicon/vehicleIcon/mahindraTreoZor/vehicleTracking.svg",
    },
};

const vehicleImages = (vehicleName) =>
    vehicleImageMapByName[vehicleName] ||
    vehicleImageMapByName["treo zor"];

export default vehicleImages;