import React, { useEffect, useRef } from 'react'
import './NDVehicleInfoHeaderDark.css'
import { useState } from 'react'
import { VehicleStatusAndMetaDataDark } from '../../../../../utils/constants'

const NDVehicleInfoHeader = (props) => {
  const { vehicleNo, vehicleStatus, isFavourite, oem, model, address, lat, long, onFavoriteFlagClick, chassisNo, motorNo, nonCommunication, getAddress, onClose, vehicleSource } = props
  const popoverRef = useRef(null)
  const [isShowPopover, setIsShowPopover] = useState(false)
  const [isCopied, setCopied] = useState(false);
  const [hoverCloseIcon, setHoverCloseIcon] = useState(false);

  const shareLink = (lat && long) ? 'https://maps.google.com/?q=' + lat + ',' + long : null

  const capitalizeInitial = (value) => {

    let string = ''

    if(value === 'TFT100') return value
    if(!value) return 'Not subscribed'

    const [first, ...rest] = value.split('')

    const result = first.toUpperCase()
    string += result
    rest.forEach(e => string += e)
    return `${string} API`
  }

  const onCopyClick = () => {
    if (shareLink) {
      navigator.clipboard.writeText(shareLink)
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000)
    }
  }

  useEffect(() => {
    const handleSharePopoverOutsideClick = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setIsShowPopover(false)
      }
    }
    document.addEventListener('click', handleSharePopoverOutsideClick)
    return () => {
      document.removeEventListener('click', handleSharePopoverOutsideClick)
    }
  }, [])

  const statusColor = (status) => {
    return VehicleStatusAndMetaDataDark.filter(vehicleStatus => vehicleStatus.name === status)[0]?.color;
  }

  // const renderTooltip = (props) => (
  //   <Tooltip id="nd-vehicle-info-button-tooltip"
  //     bsPrefix='tooltip nd-dark-v-info-custom-tooltip show'
  //     {...props}
  //   >
  //     <div>
  //       <div><p className='nd-dark-v-info-custom-tooltip-text'>Start Time :</p> </div>
  //       <div><p className='nd-dark-v-info-custom-tooltip-text'>Duration :</p> </div>
  //     </div>
  //   </Tooltip>
  // );

  const [hoverBackButton, sethoverBackButton] = useState(false)
  return (
    <>
      <div className='nd__dark-vehicle-info-header-card'>
            <img className='px-2 mb-2' onClick={onClose} src={hoverBackButton ? '/images/svgicon/trackingScreen/back_arrow_hover.svg' : "/images/svgicon/trackingScreen/back_arrow.svg"}  alt='back-arrow' width={'50px'}style={{marginTop:'10px', cursor:'pointer'}}  onMouseOver={() => sethoverBackButton(true)} onMouseOut={() => sethoverBackButton(false)}/>
            {/* <img className='nd__vehicle-info-arrow-back px-2 mb-2' onClick={onClose} src='/images/svgicon/trackingScreen/back_arrow.svg' alt='back-arrow' width={'50px'} /> */}
         
        <div className='container-fuild'>
          <div className='row mb-2 px-3'>
            <div className='col-auto d-flex align-items-center'>
              <div className='nd__dark-vehicle-info-header-registration-no'>
                {vehicleNo ? vehicleNo : 'NA'}
              </div>

              <div className='nd__dark-vehicle-info-header-oem-model'>
                {oem && model ? oem + ' ' + model : 'NA'}
              </div>
            </div>
            <div className='col'></div>
            <div className='col-1 nd__vehicle-info-header-flex'>
              <div className='cursor-pointer' data-testid={`vehicle-info-favourite-toggle`} onClick={onFavoriteFlagClick}>
                {isFavourite && (
                  <img
                    src='/images/svgicon/favorite-flag-enable-dark.svg'
                    alt='favorite flag' className='nd__vehicle-info-headers-icons'
                  />
                )}
                {!isFavourite && (
                  <img
                    src='/images/svgicon/favorite-flag-disable-dark.svg'
                    alt='flag' className='nd__vehicle-info-headers-icons'
                  />
                )}
              </div>
            </div>
            <div className='col-auto d-inline-flex'>
              <div className='ndvh__vehicle-status' style={{ background: nonCommunication ? '#7D7D7D' : statusColor(vehicleStatus === "Idle" ? "Live" : vehicleStatus) }}>
                <span className='ndvh__vehicle-status-text'>{vehicleStatus === "Idle" ? "Live" : vehicleStatus}</span>
              </div>
            </div>
          </div>
          <div className='row mb-2 px-3'>
            <div className='col-auto d-flex align-items-start'>
              <div className='nd__dark-vehicle-info-header-chassis-number'>
                Chassis No: {chassisNo || 'NA'}
              </div>

              <div className='nd__dark-vehicle-info-header-motor-number'>
                Motor No: {motorNo || 'NA'}
              </div>
            </div>
            <div style={{ display: 'flex',alignItems:'center', marginTop:2 }}>
              <div style={{fontSize: 10, color:'#ffffff' }}>Data Source:</div>
              <span style={{ marginLeft: 2,color:'#ffffff', fontSize: 10}}>{capitalizeInitial(vehicleSource)}</span>
            </div>
            <div className='nd__dark-vehicle-info-line'></div>
          </div>

          <div className='row'>
            <div className='col-auto pr-0 px-4 d-flex align-items-start' >

              <img

                data-testid={`vehicle-info-location-button`}
                className='nd__vehicle-info-headers-icons-address cursor-pointer'
                src='/images/svgicon/address-location-dark.svg'
                alt='address'
              />

              {!address ? <p className="nd-vc-click  cursor-pointer" onClick={() => { getAddress() }} >Click to View </p> : ' '}

            </div>
            <div className='col px-0'>
              <div className='nd__dark-vehicle-info-header-address'>
                {address ? address : ''}
              </div>
            </div>

            <div className='col-auto'>
              {address && (
                <div className='d-flex  mr-2' data-testid={`vehicle-info-location-toggle`} onClick={() => setIsShowPopover(true)} ref={popoverRef}>
                  <img className='cursor-pointer ' src='/images/svgicon/share.svg' alt='share' />
                  <div style={{ display: isShowPopover ? 'block' : 'none' }}
                    id='nd_vehicle-info-header-share-popover'
                    className='nd_dark-vehicle-info-header-share-popover'
                  >
                    
                    <img  src={hoverCloseIcon ? '/images/svgicon/trackingScreen/close_active.svg' : "/images/svgicon/trackingScreen/Close_fad.svg"}
                      alt='close-btn'
                      onMouseOver={() => setHoverCloseIcon(true)}
                      onMouseOut={() => setHoverCloseIcon(false)}width={30} onClick={(e) => { e.stopPropagation(); setIsShowPopover(false) }} />
                    <div className='nd__dark-vehicle-info-popover-header '>
                      Share Location
                    </div>
                    <div className='nd__vehicle-info-popover-conetent container-fuild pt-2'>
                      <div className='row'>
                        <div className='col-10 nd__vehicle-info-header-flex'>
                          {shareLink && <a data-testid={`vehicle-info-location-link`} href={shareLink} target="_blank" rel="noreferrer">{shareLink}</a>}
                          {!shareLink && <div>NA</div>}
                        </div>
                        <div className='col-2'>
                          <div
                            className='cursor-pointer float-end'
                            onClick={onCopyClick}
                            data-testid={`vehicle-info-location-copy-button`}
                          >
                            <img className={`na__vehicle-info-copy ${isCopied && 'na__vehicle-info-copied'}`} src='/images/svgicon/copyDarkTheme.svg' alt='copy' />
                          </div>
                        </div>
                      </div>
                      <div className='container-fuild container-fuild-hr'>
                        <div className='row row-hr'></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NDVehicleInfoHeader
