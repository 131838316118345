import React from "react";
import { Modal } from "react-bootstrap";
import "./ApprovalUserModel.css";
import { connect, useSelector } from "react-redux";
import Loader from "../../../../../helpers/hoc/loader/Loader";
import { ApprovalUserByAdmin } from "../../../../../../store/actions/ra_user_management";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { UserProfile } from '../../../../../../store/actions/user';

const ApprovalUserModel = ({
  showModal,
  setIsShowModal,
  data,
  _id,
  ApprovalUserByAdmin
}) => {
  let email = localStorage.getItem('email')
  const selectedUserData = data.find(user => user._id === _id);
  const isLoading = useSelector((s) => s?.loader?.isLoading);
  const handleClose = () => setIsShowModal(false);

  const handleNavigate = (path) => {
    Navigate(path);
  };
  const handleApproveClick = async () => {
    ApprovalUserByAdmin({
      data: { 
        _id : _id,
        approved_by_email: email ,
        ra_name: selectedUserData?.populated_user_data?.raName,
        approval_request_user_email:  selectedUserData?.email,
        actionType: 'approve',
        userRole: selectedUserData?.userRole,
        location: selectedUserData?.location,
      },
      navigateTo: handleNavigate,
    });
    handleClose()
  };
  return (
    <Modal
      onHide={handleClose}
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      size="l"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="mt-4">
        <div>
          <div className="d-flex justify-content-center">
            <span className="delete-approval-text">
              Are you sure you want to Approve Ra user?
            </span>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn-cancle-approval"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn-delete-approval"
              onClick={handleApproveClick}
            >
              Approve
            </button>
          </div>
        </div>
        <Loader isLoading={isLoading}></Loader>
      </Modal.Body>
    </Modal>
  );
};
ApprovalUserModel.propTypes = {
  UserProfile: PropTypes.func.isRequired,
  
};

const mapStateToProps = (state) => ({
  UserProfile
});
export default connect(mapStateToProps, {
  ApprovalUserByAdmin,
  UserProfile
})(ApprovalUserModel);

