import ActionTypes from "../../actionTypes";
const {  
    PLACES_SEARCHBAR_KEY,
    PLACES_SEARCHBAR_CO_ORDINATES,
    ZOOM_LEVEL,
    DRAWN_SHAPES,
    GET_FENCE_BY_ID
  } = ActionTypes.DIFFERENT_CREATE_FENCE_ACTIONS


const initialState = {
    searchedlatLong: {
        lat: 20.5937,
        lng: 78.9629, 
    },
    zoomLevel: 5,
    randomKey : 0,
    shapesDrawn: {},
    markerDrawn: {}
  };
  
  const createFenceDifferentActions = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case PLACES_SEARCHBAR_KEY:
        return {
          ...state,
          randomKey: payload,
        };
      case PLACES_SEARCHBAR_CO_ORDINATES:
        return {
          ...state,
          searchedlatLong: payload,
        };
      case ZOOM_LEVEL:
        return {
          ...state,
          zoomLevel: payload,
        };
      
    case DRAWN_SHAPES:
        return {
          ...state,
          shapesDrawn: payload,
        };
    case 'markerDrawn':
        return {
          ...state,
          markerDrawn: payload,
        };
    case GET_FENCE_BY_ID:
        return {
          ...state,
          getFenceByIdDetails : payload
        }
  
      default:
          return state
        
    }
  };
  export default createFenceDifferentActions;
  