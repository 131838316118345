import React, { useState, useEffect } from 'react'
import { BsDownload } from 'react-icons/bs'
import { FaSearch } from 'react-icons/fa'
import { Table, Button } from 'react-bootstrap'
import './fleet.css'
import Pagination from '../helpers/hoc/paginator/Pagination'
import { getFormattedDate, getFormattedOnlyDate  } from '../../utils/common-methods'
import FleetSyncModal from './FleetSyncModal'
import Loader from '../helpers/hoc/loader/Loader'
// import { NewUserRoleID } from '../../utils/constants' 
import GPDeleteConfirmationModal from './groups/gp-list/gp-delete-confirmation-modal/GPDeleteConfirmationModal'
import { connect, useSelector } from "react-redux"
import { fetchFleetOemVehicles, fleetVehicleSubscribe, fleetVehicleUnSubsVehicle, exportExcelFleet } from '../../store/actions/fleetDataService'
import MultiSelect from '../helpers/hoc/mult-select/MultiSelect'
import { VehicleTypes, VehicleStatusFilter, ChargingTypes } from '../../utils/constants'
import { GetUserRoleList } from '../../store/actions/roleAndMenuMapping'
import NoRecordFound from '../helpers/hoc/noRecordFound'
import { mapper } from '../../utils/mapper'

const Fleet = ({
  //states
  vehicles,
  vehicleModelWithStatusCount,

  // actions
  fetchFleetOemVehicles,
  exportExcelFleet,
  GetUserRoleList,
  subscribedByCI,
}) => {
  // const isLiveEnv = process.env.REACT_APP_ENV === 'LIVE'
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOemModel, setSelectedOemModel] = useState([])
  const [selectedCity, setSelectedCity] = useState([])
  const preparedChargingTypes = ChargingTypes.map(value => ({ label: value.id, value: value.label}))
  const [selectedChargingTypes, setSelectedChargingTypes] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const ITEMS_PER_PAGE = 8

  // const newAdminIDObj = NewUserRoleID?.find(e => e?.label === 'Admin')
  const [ fleetSyncModal , setFleetSyncModal] = useState(false)

  const [fleetData, setFleetData] = useState({})
  const [isUnSubsConfirmationOpen, setIsUnSubsConfirmationOpen] = useState(false)
  const isLoading = useSelector((s) => s?.loader?.isLoading)

  const mappedOptions = mapper.mapVehicleDataOptions(subscribedByCI);

  useEffect(() => {
    GetUserRoleList()
  }, [GetUserRoleList])
  
  const fetchAllVehicles = () => {
    fetchFleetOemVehicles({
      searchKey : searchTerm,
      page: currentPage,
      pageSize: ITEMS_PER_PAGE,
      oemModels: selectedOemModel?.map((item)=>item?.label),
      cities: selectedCity?.map(x => x.value),
      types: selectedTypes?.map(x => x.value),
      subscribeFilter: selectedStatus?.map(x => x.value),
      chargingTypeFilter: selectedChargingTypes?.map(x => x.value)
    })
    // react-hooks/exhaustive-deps
  }

  useEffect(() => {
    fetchAllVehicles()
    // eslint-disable-next-line
  }, [currentPage, searchTerm]);

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const exportToExcel = () => {
    exportExcelFleet({
      data: {
        searchKey: searchTerm,
        oemModels: selectedOemModel?.map((item)=>item?.label),
        cities: selectedCity?.map(x => x.value),
        types: selectedTypes?.map(x => x.value),
        subscribeFilter: selectedStatus?.map(x => x.value)
      }
    })
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    onSelectionChange()
    setCurrentPage(1)
  }
 

  // const userRole =localStorage.getItem('userRole')

  // Calculate the start and end index of the items to be displayed on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE

  const handleUnSubsVehicle = async () => {
    setIsUnSubsConfirmationOpen(false)
    const formData = {
      registrationNumber: fleetData?.vehicle?.registration_number
    }
    fleetVehicleUnSubsVehicle(formData, fetchAllVehicles)
  }

  const handleSubscribe = async (formData) => {
    setFleetSyncModal(false)
    fleetVehicleSubscribe(formData, fetchAllVehicles)
  }

  const onSelectionChange = () => {
    if(vehicles) {
      vehicles.length = 0
    }
  }
  const capitalizeInitial = (value) => {

    let string = ''

if(value === 'TFT100') return value
    if(!value) return 'Not subscribed'
    if(value.includes('Not')) return 'Not Subscribed'

    const [first, ...rest] = value.split('')

    const result = first.toUpperCase()
    string += result
    rest.forEach(e => string += e)
    return `${string} API`
  }

  return (
    <div className="main-container container-fluid text-center">
      {isLoading && <Loader isLoading={isLoading} />}
      {fleetSyncModal && (
        <FleetSyncModal
          onClose={setFleetSyncModal}
          fleetDetails={fleetData}
          onSubmit={handleSubscribe}
        />
      )}

      {isUnSubsConfirmationOpen && (
        <GPDeleteConfirmationModal
                handleClose={() => {setIsUnSubsConfirmationOpen(false); setFleetData({})}}
                isOpen={isUnSubsConfirmationOpen}
                onDeleteHandler={handleUnSubsVehicle}
                message='Are you sure you want to unsubscribe the vehicle?'
                data-testid='summary-delete'
              >
        </GPDeleteConfirmationModal>
      )}

      <h5 className="fleetheading">Summary</h5>
      <div className="d-flex flex-row align-item-center justify-content-start">
        <hr className="hr-line"></hr>
      </div>
      <div className="row mt-3">
        <div className="col">
          <div>
            <div>
              <div className="d-flex">
                <div className={` d-inline-flex align-items-center gap-3 ${!searchTerm? 'no-value-selected-input ' : ''}`}>
                  <div 
                  className="search-bar-div search-input fleet-search-bar-div fleetSearchInput">
                    <FaSearch className="vob-search-icon" />
                    <input
                      type="text"
                      value={searchTerm}
                      className="vob-search-input search-input"
                      onChange={handleSearch}
                      placeholder="Search"
                      data-testid='summary-search-input'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          className='search_filters'>
            <div className='col-2 mr-2'>
            <div 
              className={`multi-select-width fleet-multi-select-width ${!selectedOemModel[0]? 'no-value-selected' : ''}`}>
             <MultiSelect
               options={mappedOptions.oemModel}
               selectedOptions={selectedOemModel}
               placeholder='OEM & Model'
               setSelectedOptions={setSelectedOemModel}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='oem-model-filter'

               />
           </div>
            </div>
            <div className='col-2 mr-2'>
            <div 
              className={`multi-select-width fleet-multi-select-width ${!selectedCity[0]? 'no-value-selected' : ''}`}>
             <MultiSelect
               options={mappedOptions.location}
               selectedOptions={selectedCity}
               placeholder='Location'
               setSelectedOptions={setSelectedCity}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='city-filter'
               />
           </div>
            </div>
            <div className='col-2 mr-2'>
            <div 
              className={`multi-select-width fleet-multi-select-width ${!selectedTypes[0]? 'no-value-selected' : ''}`}>
             <MultiSelect
               options={VehicleTypes}
               selectedOptions={selectedTypes}
               placeholder='Type'
               setSelectedOptions={setSelectedTypes}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='vehicle-type-filter'

               />
           </div>
            </div>
              <div className='col-2 mr-2'>
            <div 
              className={`multi-select-width fleet-multi-select-width ${!ChargingTypes[0]? 'no-value-selected' : ''}`}>
             <MultiSelect
               options={preparedChargingTypes}
               selectedOptions={selectedChargingTypes}
               placeholder='Charging Type'
               setSelectedOptions={setSelectedChargingTypes}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='oem-model-filter'

               />
           </div>
            </div>
            <div className='col-2'>
            <div 
              className={`multi-select-width fleet-multi-select-width ${!selectedStatus[0]? 'no-value-selected' : ''}`}>
             <MultiSelect
               options={VehicleStatusFilter}
               selectedOptions={selectedStatus}
               placeholder='Status'
               setSelectedOptions={setSelectedStatus}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='vehicle-status-filter'

               />
           </div>
            </div>
            <div className="col-2 ctaButtonsDiv">
              <div className="d-flex">
                <div className='col-9'>
                <Button
               className="bttn height fleet-btn"
               id="getReport"
               type="submit"
               onClick={() => { 
                  onSelectionChange()
                  setCurrentPage(1)
                  fetchAllVehicles()
               }}
               data-testid='get-report-button'
             >
               Get Report
                </Button>
                </div>
                <div className='col-3'>
                <button
                    disabled={!vehicles?.length}
                    className={
                      !vehicles?.length ? 'fleet-download-button dwn-btn-disabled' : 'fleet-download-button'
                    }
                    onClick={exportToExcel}
                    data-testid='export-button'
                  >
                    <BsDownload style={{width:'16px',height:'16px'}} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {
            vehicles?.length > 0 && (<div className=" bg-white mb-1 position-relative mt-2">
            <Table responsive={true} borderless className="mb-0" id="myTable">
              <thead className='nd_table-head'>
                <tr className="table-header">
                  <th>
                    <span className="fw500">S.No.</span>
                  </th>
                  <th>
                    <span className="fw500">Vehicle No.</span>
                  </th>
                  <th>
                    <span className="fw500">OEM & Model</span>
                  </th>
                  <th>
                    <span className="fw500">Chassis No.</span>
                  </th>
                  <th>
                    <span className="fw500">Motor No.</span>
                  </th>
                  <th>
                    <span className="fw500">Type</span>
                  </th>
                  <th>
                    <span className="fw500">Location</span>
                  </th>
                  <th>
                    <span className="fw500">Data Source</span>
                  </th>
                  <th>
                    <span className="fw500">Date of Purchase</span>
                  </th>
                  <th>
                    <span className="fw500">GPS Synced On</span>
                  </th>
                  <th>
                    <span className="fw500">CAN Synced On</span>
                  </th>
                  <th>
                    <span className="fw500">Charging Type</span>
                  </th>
                    <>                      
                      <th>
                        <span className="fw500 action_span">Action</span>
                      </th>
                    </>
                </tr>
              </thead>
              <tbody className="table-body">
                {vehicles?.map((vehicle, index) => (
                  <tr key={vehicle._id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{vehicle.registration_number}</td>
                    <td>{`${vehicle.oem} ${vehicle.model}`}</td>
                    <td>{vehicle.chassis_number}</td>
                    <td>{vehicle.motor_number}</td>
                    <td>{vehicle.type}</td>
                    <td>{vehicle.location}</td>
                    <td>{capitalizeInitial(vehicle.src)}</td>
                    <td>{getFormattedOnlyDate(vehicle.purchaseDate)}</td>
                    {vehicle.vehicleLocationSyncedOnDate === '-' ? (
                      <td>-</td>
                    ) : (
                      <td>
                        {getFormattedDate(vehicle.vehicleLocationSyncedOnDate)}
                      </td>
                    )}

                    {vehicle.vehicleCanSyncedOnDate === '-' ? (
                      <td>-</td>
                    ) : (
                      <td>
                        {getFormattedDate(vehicle.vehicleCanSyncedOnDate)}
                      </td>
                    )}
{/* {
  vehicle?.vehicleLocationSyncedOnDate === '-' && userRole === newAdminIDObj?.id && isLiveEnv ? (
    <td>Not Subscribed</td>
  ) : (
    userRole === newAdminIDObj?.id && isLiveEnv && <td>Subscribed</td>
  )
} 
{
vehicle?.vehicleLocationSyncedOnDate === '-' && userRole === newAdminIDObj?.id && isLiveEnv ?
<td>                       
<button
  style={{
    backgroundColor: "#22046B",
    color: "white",
    borderRadius: 4,                          
    height: 25,
    paddingLeft: 5,
    paddingRight: 5,
  }}
  onClick={() => {
    setFleetSyncModal(true)
    setFleetData({...fleetData, vehicle})
  }}
  data-testid={`subscribe-button-${vehicle.registration_number}`}
>
Subscribe
</button>
</td>
:userRole === newAdminIDObj?.id && isLiveEnv && <td>                       
  <button
    style={{
      backgroundColor: "#22046B",
      color: "white",
      borderRadius: 4,                          
      height: 25,
      paddingLeft: 5,
      paddingRight: 5,
    }}
    onClick={() => {
      setIsUnSubsConfirmationOpen(true)
      setFleetData({...fleetData, vehicle})
    }}
    data-testid={`unsubscribe-button-${vehicle.registration_number}`}
  >
   Unsubscribe
  </button>
  </td>
                    }                 */}
                    <td>{vehicle.charging_type ?? 'NA'}</td>

{
vehicle?.vehicleLocationSyncedOnDate === '-' ?
<td>                       
<button
  style={{
    backgroundColor: "#22046B",
    color: "white",
    borderRadius: 4,                          
    height: 25,
    paddingLeft: 5,
    paddingRight: 5,
  }}
  onClick={() => {
    setFleetSyncModal(true)
    setFleetData({...fleetData, vehicle})
  }}
  data-testid={`subscribe-button-${vehicle.registration_number}`}
>
Subscribe
</button>
</td> :
<td>-</td>
}                
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Render the Pagination component */}
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil((vehicleModelWithStatusCount || 0) / ITEMS_PER_PAGE)}
              setPage={handlePageChange}
              data-testid='summary-pagination'
            />
            </div>)
          }
          { !isLoading && !vehicles?.length && <NoRecordFound /> }
          
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  vehicles: state.getFleetReducer.vehicles,
  vehicleModelWithStatusCount: state.getFleetReducer.vehicleModelWithStatusCount,
  masterLocationData: state.getAllUserRoleReducer.masterLocationData,
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
});

export default connect(mapStateToProps, {
  fetchFleetOemVehicles,
  exportExcelFleet,
  GetUserRoleList
})(Fleet)