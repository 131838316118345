import { Row, Col } from 'react-bootstrap'
import MultiSelect from '../../../helpers/hoc/mult-select/MultiSelect'
import DatePicker from '../../../helpers/hoc/date-picker/DatePicker'
import { BsDownload } from 'react-icons/bs'
import {
  loadAllCities,
  setSelectedCities,
  setSelectedFenceOwners,
  setSelectedLabelOwners,
  setSelectedLabels,
  loadAllLabels,
  loadAllLabelOwners,
  loadAllFenceOwners,
  setStartDate,
  setEndDate,
  exportOperationsData,
  setReportsCurrentPage
} from '../../../../store/actions/geo-fence/analytics/analyticsActions'
import { useDispatch, useSelector } from 'react-redux'
import './filter.css'
import { useCallback, useEffect, useState } from 'react'
import {  setSelectedDataInterval } from '../../../../store/actions/geo-fence/analytics/analyticsActions'


const Filter = (props) => {
  const baseTestId = props['data-testid'] || 'filters'
  const dispatch = useDispatch()
  const reportsData = useSelector((s) => s?.analytics.reportsData);
  const cities = useSelector((s) => s?.analytics?.cities)
  const labels = useSelector((s) => s?.analytics?.labels)
  const labelOwners = useSelector((s) => s?.analytics?.labelOwners)
  const fenceOwners = useSelector((s) => s?.analytics?.fenceOwners)

  const selectedCities = useSelector((s) => s?.analytics?.selectedCities)
  const selectedLabels = useSelector((s) => s?.analytics?.selectedLabels)
  const selectedLabelOwners = useSelector(
    (s) => s?.analytics?.selectedLabelOwners
  )
  const selectedFenceOwners = useSelector(
    (s) => s?.analytics?.selectedFenceOwners
  )

  const startDate = useSelector((s) => s?.analytics?.startDate)
  const endDate = useSelector((s) => s?.analytics?.endDate)

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true)
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true)
  const [onLoadCalendarDate, setOnLoadCalendarDate] = useState([])

  const checkAllSelected = useCallback(() => {
    return (
      selectedCities.length > 0 &&
      selectedLabels.length > 0 &&
      selectedLabelOwners.length > 0 &&
      selectedFenceOwners.length > 0 &&
      startDate !== null &&
      endDate !== null
    )
  }, [
    selectedCities,
    selectedLabels,
    selectedLabelOwners,
    selectedFenceOwners,
    startDate,
    endDate,
  ])


  useEffect(() => {
    if (isInitialPageLoad && startDate && endDate) {
      setIsInitialPageLoad(false)
      setOnLoadCalendarDate([startDate, endDate])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialPageLoad])

  useEffect(() => {
    setIsButtonDisabled((prev) => !checkAllSelected())
  }, [checkAllSelected])

  //check all field selected for download
  const checkAllSelectedDownload = useCallback(() => {
    return (
      selectedCities.length > 0 &&
      selectedLabels.length > 0 &&
      selectedLabelOwners.length > 0 &&
      selectedFenceOwners.length > 0 &&
      startDate !== null &&
      endDate !== null &&
      reportsData.length > 0
    )
  }, [
    selectedCities,
    selectedLabels,
    selectedLabelOwners,
    selectedFenceOwners,
    startDate,
    endDate,
    reportsData,
  ])

  useEffect(() => {
    setIsDownloadButtonDisabled((prev) => !checkAllSelectedDownload())
  }, [checkAllSelectedDownload])

  function setDataInterval(){
    if(startDate && endDate){
      const startDateTimeInHours  = ((new Date(startDate).getTime()) / (1000 * 60 * 60)); 
      const endDateTimeInHours  = ((new Date(endDate).getTime()) / (1000 * 60 * 60));
      if(Math.abs(startDateTimeInHours - endDateTimeInHours)>=24){
        dispatch(setSelectedDataInterval([{label: '30 mins', value:'30'}]))
      }
      else if(Math.abs(startDateTimeInHours - endDateTimeInHours)<24){
        dispatch(setSelectedDataInterval([ {label: '30 mins', value:'30'},]))
      }
      dispatch(setReportsCurrentPage(1))
    }
  }

  function onDateChangeHandler(value) {
    dispatch(setStartDate(value[0]))
    dispatch(setEndDate(value[1]))

  }  


  useEffect(() => {
    dispatch(loadAllCities())
    dispatch(loadAllLabels())
    dispatch(loadAllLabelOwners())
    dispatch(loadAllFenceOwners())
  }, [dispatch])

  //download geofence report
  const downloadReportOnClick = () => {
    exportOperationsData({
      selectedCities,
      selectedLabels,
      selectedLabelOwners,
      selectedFenceOwners,
      startDate,
      endDate,
    })
  }

  return (
    <>
      <Row>
        <Col xs={2} className={`select-dropdown-analytics ${!selectedCities[0] ? 'no-value-selected' : ''}`}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            okButtonPaddingEnd="pe-2"
            placeholderAlignment='flex-start'
            optionPosition="ps-2 justify-content-start"
            placeholder="City"
            placeholderColor='#1F2A5D'
            options={cities}
            selectedOptions={selectedCities}
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#1F2A5D"
            customBackgroundColor='#EFF3F7'
            setSelectedOptions={(options) => {
              dispatch(setSelectedCities(options))
            }}
            data-testid={`${baseTestId}-city-multiselect`}
          />
        </Col>
        <Col xs={2} className={`select-dropdown-analytics ${!selectedLabels[0] ? 'no-value-selected' : ''}`}  >
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            placeholderAlignment='flex-start'
            okButtonPaddingEnd="pe-2"
            optionPosition="ps-2 justify-content-start"
            placeholder="Label"
            placeholderColor='#1F2A5D'
            options={labels}
            selectedOptions={selectedLabels}
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#1F2A5D"
            customBackgroundColor='#EFF3F7'
            setSelectedOptions={(options) => {
              dispatch(setSelectedLabels(options))
            }}
            data-testid={`${baseTestId}-label-multiselect`}
          />
        </Col>
        <Col xs={2} className={`select-dropdown-analytics ${!selectedLabelOwners[0] ? 'no-value-selected' : ''}`}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            okButtonPaddingEnd="pe-2"
            placeholderAlignment='flex-start'
            placeholderColor='#1F2A5D'
            optionPosition="ps-2 justify-content-start"
            placeholder="Label Owner"
            options={labelOwners}
            selectedOptions={selectedLabelOwners}
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#797694"
            customBackgroundColor='#EFF3F7'
            setSelectedOptions={(options) => {
              dispatch(setSelectedLabelOwners(options))
            }}
            data-testid={`${baseTestId}-labelowner-multiselect`}
          />
        </Col>
        <Col xs={2} className={`select-dropdown-analytics ${!selectedFenceOwners[0] ? 'no-value-selected' : ''}`}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            okButtonPaddingEnd="pe-2"
            placeholderColor='#1F2A5D'
            optionPosition="ps-2 justify-content-start"
            placeholder="Fence Owner"
            placeholderAlignment='flex-start'
            options={fenceOwners}
            selectedOptions={selectedFenceOwners}
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#797694"
            customBackgroundColor='#EFF3F7'
            setSelectedOptions={(options) => {
              dispatch(setSelectedFenceOwners(options))
            }}
            data-testid={`${baseTestId}-fenceowner-multiselect`}
          />
        </Col>
        <Col xs={2} className="date__picker">
          <DatePicker
            setCalendarDate = {onLoadCalendarDate}
            onDateChange={onDateChangeHandler}
            modelPlacement="bottomStart"
            paddingLeft='0'
            placeholderDefaultText='Date & Time'
            placeholderDefaultTextColor='#1F2A5D'     
            isDateSelected={startDate && endDate}  
            data-testid={`${baseTestId}-daterange`} 
          />
        </Col>
        <Col xs={1}>
          <button
            disabled={isButtonDisabled}
            style={{
              background: isButtonDisabled ? '#808080' : '#22046B',
            }}
            onClick={() =>{
              setDataInterval()
            }}
            className="getReportButton"
            data-testid={`${baseTestId}-getreport-button`}
          >
            Get Report
          </button>
        </Col>
        <Col xs={1}>
          <button
            disabled={isDownloadButtonDisabled}
            style={{
              background: isDownloadButtonDisabled ? '#808080' : '#22046B',
            }}
            onClick={downloadReportOnClick}
            className="downloadBtn-analytics"
            data-testid={`${baseTestId}-export-button`}
          >
            <BsDownload style={{width:'22px',height:'22px'}} />
          </button>
        </Col>
      </Row>
    </>
  )
}

export default Filter
