import moment from "moment";
import { customToast } from "../../utils/toasts";
import api from "../../service/api";
import API_BOOK from "../../service/endpoints";
import ActionTypes from "../actionTypes";
import { generateExcel, getFormattedDate, fileDownloader } from "../../utils/common-methods";
import {
  VehicleOnboardingTemplateColumns,
  VehicleOnboardingTemplateSheetName,
} from "../../utils/constants";

const {
  VEHICLE_ONBOARDING_UPLOAD,
  VEHICLE_ONBOARDING_LOGS,
  EXPORT_ONBOARDING_LOGS,
  SINGLE_VEHICLE_ONBOARD,
  VEHICLES_ONBOARD_BY_USER,
  BULK_VEHICLES_ONBOARDED_UPDATE,
  DELETE_ONBOARDED_VEHICLES_BY_ID,
  EXPORT_VEHICLES_BY_ONBOARDINGID
} = API_BOOK.VEHICLE.VEHICLE_ONBOARDING;
const { FILE_UPLOAD_PROGRESS } = ActionTypes.FILE;
const {
  VEHICLE_ONBOARD_UPLOAD_ERROR,
  VEHICLE_ONBOARD_UPLOAD_SUCCESS,
  GET_ALL_ONBOARDING_LOGS_SUCCESS,
  GET_ALL_ONBOARDING_VEHICLES_SUCCESS,
  GET_ALL_ONBOARDING_VEHICLES_API_ERROR,
  BULK_VEHICLE_ONBOARDED_UPDATE_SUCCESS,
  BULK_VEHICLE_ONBOARDED_UPDATE_ERROR,
} = ActionTypes.VEHICLE.VEHICLE_ONBOARDING;

/* Action dispatchers starts here */

const uploadProgressDispatcher = (payload) => ({
  type: FILE_UPLOAD_PROGRESS,
  payload,
});

const uploadErrorDispatcher = (payload) => ({
  type: VEHICLE_ONBOARD_UPLOAD_ERROR,
  payload,
});

const uploadSuccessDispatcher = (payload) => ({
  type: VEHICLE_ONBOARD_UPLOAD_SUCCESS,
  payload,
});

const getAllOnboardingLogsSuccessDispatcher = (payload) => ({
  type: GET_ALL_ONBOARDING_LOGS_SUCCESS,
  payload,
});

const getAllOnboardingVehiclesSuccessDispatcher = (payload) => ({
  type: GET_ALL_ONBOARDING_VEHICLES_SUCCESS,
  payload,
});

const getAllOnboardingVehiclesErrorDispatcher = () => ({
  type: GET_ALL_ONBOARDING_VEHICLES_API_ERROR,
});

const bulkUpdateErrorDispatcher = (payload) => ({
  type: BULK_VEHICLE_ONBOARDED_UPDATE_ERROR,
  payload,
});

const bulkUpdateSuccessDispatcher = (payload) => ({
  type: BULK_VEHICLE_ONBOARDED_UPDATE_SUCCESS,
  payload,
});

/* Action dispatchers ends here */

/* Actions starts here */

const GetAllOnboardingLogsData = async ({ searchKey, pageSize, pageIndex }) => {
  const resp = await api({
    method: VEHICLE_ONBOARDING_LOGS.method,
    url: VEHICLE_ONBOARDING_LOGS.url,
    params: { searchKey, pageSize, pageIndex },
  });

  return resp;
};

/**
 *  @desc         Single Vehicle Onboarding
 *  @author       Deepak Prajapati
 *  @api          /vehicle/onboarding/
 *  @method       POST
 *  @createdDate  3-Jul-2023
 *  @modifiedDate 3-Jul-2023
 **/
export const SingleVehicleOnboard =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await api({
        method: SINGLE_VEHICLE_ONBOARD.method,
        url: SINGLE_VEHICLE_ONBOARD.url,
        data: data,
      });

      if (resp.status === 200) {
        await dispatch(uploadSuccessDispatcher(true));

        const logsData = await GetAllOnboardingLogsData({
          searchKey: null,
          pageSize: 8,
          pageIndex: 0,
        });
        if (logsData.status === 200 && logsData?.data?.data) {
          const response = logsData?.data?.data;
          dispatch(getAllOnboardingLogsSuccessDispatcher(response));
        }

        setTimeout(() => {
          customToast({ message: resp?.data?.message });
        }, 400);

        setTimeout(() => {
          dispatch(uploadSuccessDispatcher(false));
        }, 5000);
      }

      if (resp.status === 400) {
        await dispatch(uploadErrorDispatcher(resp.data.error));
        setTimeout(() => {
          dispatch(uploadErrorDispatcher(null));
        }, 2000);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

/**
 *  @desc         Vehicle Onboarding excel upload
 *  @author       Deepak Prajapati
 *  @api          /vehicle/onboarding/upload
 *  @method       POST
 *  @createdDate  1-Jul-2023
 *  @modifiedDate 1-Jul-2023
 **/
export const BulkUploadVehicles =
  ({ file }) =>
  async (dispatch) => {
    try {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);

      const resp = await api({
        method: VEHICLE_ONBOARDING_UPLOAD.method,
        url: VEHICLE_ONBOARDING_UPLOAD.url,
        data: formData,
        onUploadProgress: (progressEvent) => {
          dispatch(
            uploadProgressDispatcher(
              Math.ceil((progressEvent.loaded / progressEvent.total) * 100)
            )
          );
        },
      });

      await dispatch(uploadProgressDispatcher(0));

      if (resp.status === 200) {
        await dispatch(uploadSuccessDispatcher(true));

        const logsData = await GetAllOnboardingLogsData({
          searchKey: null,
          pageSize: 8,
          pageIndex: 0,
        });
        if (logsData.status === 200 && logsData?.data?.data) {
          const response = logsData?.data?.data;
          dispatch(getAllOnboardingLogsSuccessDispatcher(response));
        }

        setTimeout(() => {
          customToast({ message: resp?.data?.message });
        }, 400);

        setTimeout(() => {
          dispatch(uploadSuccessDispatcher(false));
        }, 5000);
      }

      if (resp.status === 400 || resp.status === 500) {
        await dispatch(uploadErrorDispatcher(resp.data.error));

        setTimeout(() => {
          dispatch(uploadErrorDispatcher(null));
        }, 4000);

        const data = resp?.data?.data;
        if (Array.isArray(data) && data.length) {
          const modifiedData = data.map((val) =>
            val.purchaseDate
              ? {
                  ...val,
                  purchaseDate: moment(val.purchaseDate).format("DD/MM/YYYY"),
                }
              : val
          );
          const workbook = generateExcel({
            columns: VehicleOnboardingTemplateColumns,
            sheetName: VehicleOnboardingTemplateSheetName,
            rows: modifiedData,
          });
          fileDownloader({
            excelWorkbook: workbook,
            fileName: "Vehicle_onboarding_invalid_data.xlsx",
          });
        }
      }
    } catch (error) {
      await dispatch(uploadProgressDispatcher(0));
      console.log("error:", error);
    }
  };

/**
 *  @desc         Get all Onboarding logs
 *  @author       Deepak Prajapati
 *  @api          /vehicle/onboarding/logs
 *  @method       GET
 *  @createdDate  3-Jul-2023
 *  @modifiedDate 3-Jul-2023
 **/
export const GetAllOnboardingLogs =
  ({ searchKey, pageSize, pageIndex }) =>
  async (dispatch) => {
    try {
      const logsData = await GetAllOnboardingLogsData({
        searchKey,
        pageSize,
        pageIndex,
      });
      if (logsData.status === 200 && logsData?.data?.data) {
        const response = logsData?.data?.data;
        dispatch(getAllOnboardingLogsSuccessDispatcher(response));
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

/**
 *  @desc         Export Onboarding logs api action
 *  @author       Deepak Prajapati
 *  @api          /vehicle/onboarding/export
 *  @method       POST
 *  @createdDate  3-Jul-2023
 *  @modifiedDate 27-Jul-2023
 **/
export const ExportOnboardingLogs = () => async () => {
  try {
    const resp = await api({
      method: EXPORT_ONBOARDING_LOGS.method,
      url: EXPORT_ONBOARDING_LOGS.url,
      responseType: "arraybuffer",
      data: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
    });

    if (resp.status === 200) {
      const response = resp?.data;
      fileDownloader({
        arrayBuffer: response,
        fileName: "vehicle_onboarding_logs.xlsx",
      });
    }
  } catch (error) {
    console.log("error:", error);
  }
};
/* Actions ends here */

const GetAllOnboardingVehiclesData = async ({
  onboardingId
}) => {
  const resp = await api({
    method: VEHICLES_ONBOARD_BY_USER.method,
    url: VEHICLES_ONBOARD_BY_USER.url.replace(":onboardingId", onboardingId)
  });

  return resp;
};

/**
 *  @desc         Get all Onboarding logs
 *  @author       Bhavin Patel
 *  @api          /vehicle/onboarding/:id
 *  @method       GET
 *  @createdDate  27-Jul-2023
 *  @modifiedDate 27-Jul-2023
 **/
export const GetAllOnboardingVehicles =
  ({ onboardingId
   }) =>
  async (dispatch) => {
    try {
      const logsData = await GetAllOnboardingVehiclesData({
        onboardingId
      });
      if (logsData.status === 200
        ) {
        const response = logsData?.data?.data;
        dispatch(getAllOnboardingVehiclesSuccessDispatcher(response));
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

/**
 *  @desc         Bulk Vehicle Onboarded Update
 *  @author       Bhavin Patel
 *  @api          /vehicle/onboarding/bulk-update
 *  @method       POST
 *  @createdDate  27-Jul-2023
 *  @modifiedDate 27-Jul-2023
 **/
export const BulkVehicleOnboardedUpdate =
  ({ data, onboardingId,
     pageIndex,
      pageSize
     }) =>
  async (dispatch) => {
    try {
      const resp = await api({
        method: BULK_VEHICLES_ONBOARDED_UPDATE.method,
        url: BULK_VEHICLES_ONBOARDED_UPDATE.url,
        data: data,
      });

      if (resp.status === 200) {
        await dispatch(bulkUpdateSuccessDispatcher(true));

        const logsData = await GetAllOnboardingVehiclesData({
          onboardingId,
          searchKey: null,
          pageSize,
          pageIndex,
        });
        if (logsData.status === 200 && logsData?.data?.data) {
          const response = logsData?.data?.data;
          dispatch(getAllOnboardingVehiclesSuccessDispatcher(response));
        }

        setTimeout(() => {
          customToast({ message: resp?.data?.message });
        }, 400);

        setTimeout(() => {
          dispatch(bulkUpdateSuccessDispatcher(false));
        }, 5000);
      }

      if (resp.status === 400) {
        await dispatch(bulkUpdateErrorDispatcher(resp.data.error));
        const logsData = await GetAllOnboardingVehiclesData({
          onboardingId,
          searchKey: null,
          pageSize,
          pageIndex,
        });
        if (logsData.status === 200 && logsData?.data?.data) {
          const response = logsData?.data?.data;
          dispatch(getAllOnboardingVehiclesSuccessDispatcher(response));
        }
        setTimeout(() => {
          dispatch(bulkUpdateErrorDispatcher(null));
        }, 4000);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
 *  @desc         Delete Onboarded vehicle by id
 *  @author       Deepak Prajapati
 *  @api          /vehicle/onboarding/:id
 *  @method       DELETE
 *  @createdDate  27-Jul-2023
 *  @modifiedDate 27-Jul-2023
 **/
export const DeleteOnboardedVehicleById =
({ vehicleIds, onboardingId, pageSize, pageIndex }) =>
async (dispatch) => {
  try {
    const resp = await api({
      method: DELETE_ONBOARDED_VEHICLES_BY_ID.method,
      url: DELETE_ONBOARDED_VEHICLES_BY_ID.url,
      data: {
        _ids: vehicleIds
      }
    });

    if (resp.status === 200) {
      setTimeout(async () => {
        customToast({ message: 'Deleted Successfully' });
        const logsData = await GetAllOnboardingLogsData({
          pageSize: 8,
          pageIndex: 0,
        });
        if (logsData.status === 200 && logsData?.data?.data) {
          const response = logsData?.data?.data;
          dispatch(getAllOnboardingLogsSuccessDispatcher(response));
        }
      }, 400);
      const logsData = await GetAllOnboardingVehiclesData({
        onboardingId,
        pageSize,
        pageIndex,
      });
      if (logsData.status === 200 && logsData?.data?.data) {
        const response = logsData?.data?.data;
        dispatch(getAllOnboardingVehiclesSuccessDispatcher(response));
      } else if (logsData.status !== 200) {
        dispatch(getAllOnboardingVehiclesErrorDispatcher());
      }
    } else {
      customToast({message: resp?.data?.error, svgImageName: 'decline'})
    }
  } catch (error) {
    console.log("error:", error);
  }
};

/**
 *  @desc         Export vehicles by onboarding Id
 *  @author       Deepak Prajapati
 *  @api          /vehicle/onboarding/export-by-oid
 *  @method       POST
 *  @createdDate  27-Jul-2023
 *  @modifiedDate 27-Jul-2023
 **/
export const ExportVehiclesByOnboardingId = ({ onboardingId, onboardedByUser,searchKey }) => async () => {
  try {
    const resp = await api({
      method: EXPORT_VEHICLES_BY_ONBOARDINGID.method,
      url: EXPORT_VEHICLES_BY_ONBOARDINGID.url,
      responseType: 'arraybuffer',
      data: {
        onboardingId,
        onboardedByUser,
        timezoneOffset: new Date().getTimezoneOffset(),
        searchKey
       }
    });
  
    if (resp.status === 200) {
      const response = resp?.data
      fileDownloader({ arrayBuffer: response, fileName: `Vehicles_onboarded_by_user_${getFormattedDate(new Date()).replace(/,/g, '' ).replace(':', ' ').split(' ').join('_')}.xlsx` })
    }
  } catch (error) {
    console.log("error:", error);
  }
};

  /* Actions ends here */