import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import SingleSelect from '../helpers/hoc/single-select/SingleSelect'
import { GetUserRoleList } from '../../store/actions/roleAndMenuMapping'
import { connect } from "react-redux";
import './FleetSyncModal.css'
import { addEscapeKeyListener } from '../../utils/common-methods'

const FleetSyncModal = (props) => {
  const { vehicle } = props.fleetDetails
  const [apiSource, setApiSource] = useState(null)
  const [isValidAPISource, setIsValidAPISource] = useState(true)
  const handleSubmit = (event) => {
    if (validateForm()) {
      setIsValidAPISource(true)
      // API call
      const formData = {
        registrationNumber: vehicle.registration_number,
        chassisNumber: vehicle.chassis_number,
        oem: vehicle.oem,
        model: vehicle.model,
        apiSource: apiSource.value
      }
      props.onSubmit(formData)
    } else {
      setIsValidAPISource(false)
    }
  }
 
  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      console.log('Escape key pressed globally!');
    });

    return () => {
      removeListener();
    };
  }, []);

  const validateForm = () => {
    return apiSource !== null && apiSource !== undefined && apiSource._id !== null && apiSource._id !== undefined
  }

  const handleSelectHandler = (data) => {
    setApiSource(data)
    setIsValidAPISource(data !== null && data !== undefined && data._id !== null && data._id !== undefined)
  }

  useEffect(() => {
    if(props?.apiSourceData && props.apiSourceData?.length === 0) {
      props.GetUserRoleList()
    }
    // eslint-disable-next-line
  }, [props.apiSourceData])
  return (
    <>
      <Modal
        show={vehicle && vehicle._id ? true : false}
        onHide={() => {
          props.onClose()
        }}
        id='fleetVehicleSubscribeModal'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ padding: '30px' }}>
          <div
            style={{ textAlign: 'center', marginTop: '5px', color: '#1F2A5D' }}
          >
            <strong>Verify & Subscribe</strong>
          </div>
          <div className='row'>
            <div className='col-12 fl_vehicle_subs_row d-flex'>
              <div className='col-4'>Vehicle No.</div>
              <div className='col-1'>:</div>
              <div className='col-6'>{vehicle.registration_number}</div>
            </div>

            <div className='col-12 fl_vehicle_subs_row d-flex'>
              <div className='col-4'>OEM & Model</div>
              <div className='col-1'>:</div>
              <div className='col-6'>{`${vehicle.oem} ${vehicle.model}`}</div>
            </div>

            <div className='col-12 fl_vehicle_subs_row d-flex'>
              <div className='col-4'>Chassis No.</div>
              <div className='col-1'>:</div>
              <div className='col-6'>{vehicle.chassis_number}</div>
            </div>

            <div className='col-12 fl_vehicle_subs_row d-flex'>
              <div className='col-4'>API Source</div>
              <div className='col-1'>:</div>
              <div className='col-6'>
                <SingleSelect
                  placeholder='Select API Source'
                  options={props?.apiSourceData}
                  selectedOptions={apiSource}
                  onSelectHandler={handleSelectHandler}
                  optionPosition="justify-content-start"
                  valueContainerJustifyContent="start"
                  maxMenuHeight={180}
                  data-testid='api-source-select'
                ></SingleSelect>
                { 
                  !isValidAPISource && 
                  <label className='required-field'>
                    API Source Required
                  </label>
                }
              </div>
            </div>
          </div>

          <div className='text-center' style={{ marginTop: '20px' }}>
            <Button
              type='submit'
              variant='primary'
              className='small-button'
              id='submitButtonVerifyAndSubscribe'
              onClick={handleSubmit}
              data-testid='sync-modal-submit-button'
            >
              Verify & Subscribe
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  apiSourceData: state.getAllUserRoleReducer.apiSourceData,
})

export default connect(mapStateToProps, {
  GetUserRoleList
})(FleetSyncModal)
