import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import { customToast } from "../../../utils/toasts";
import { NotificationMessage } from "../../../utils/constants";
import {  errorToast } from '../../../utils/toasts';
const { GET_ALL_DATA_FAIL, GET_ALL_DATA_SUCCESS } = ActionTypes.REPORTS.CHARGING;
const { GET_ALL_CHARGING_SESSIONS, EXPORT_CHARGING_DATA } = API_BOOK.REPORTS.CHARGING;


/* Action dispatchers (AD) starts here */ 

const getAllChargeSessionsSuccessAD = (payload) => ({
  type: GET_ALL_DATA_SUCCESS,
  payload,
});

const getAllChargeSessionsFailAD = () => ({
  type: GET_ALL_DATA_FAIL
});


/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get all charging sessions api action
 *  @author       Deepak Prajapati
 *  @api          /reports/charging-sessions
 *  @method       POST
 *  @createdDate  14-Jul-2023
 *  @modifiedDate 14-Jul-2023
 **/
export const getAllChargeSessions = ({ registrationNumbers, fromDate, toDate, intervalDuration, pageSize, pageIndex, oemModels, cities }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_CHARGING_SESSIONS.method,
      url: GET_ALL_CHARGING_SESSIONS.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        intervalDuration,
        pageSize,
        pageIndex,
        oemModels,
        cities
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllChargeSessionsSuccessAD(response));
    } else {
      resp.data.error.forEach(error => {
        errorToast({ message: error.message })
    });
      dispatch(getAllChargeSessionsFailAD());
    }
  } catch (error) {
    dispatch(getAllChargeSessionsFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Export charging sessions report api action
 *  @author       Deepak Prajapati
 *  @api          /reports/charging-sessions/export
 *  @method       POST
 *  @createdDate  14-Jul-2023
 *  @modifiedDate 14-Jul-2023
 **/
export const ExportChargingSessionsReport = ({ registrationNumbers, fromDate, toDate, oemModels, cities}) => async (dispatch) => {
  try {
    const resp = await api({
      method: EXPORT_CHARGING_DATA.method,
      url: EXPORT_CHARGING_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        timezoneOffset: new Date().getTimezoneOffset(),
        oemModels,
        cities
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
};

/* Actions ends here */ 