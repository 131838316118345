import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Button.module.css';

const Button = ({ children, onClick, type, className, disabled, style, variant, fullwidth }) => {
  const variantStyleMap = {
    'DEFAULT': {
      root: styles.root,
      disabled: styles.rootDisabled,
    },
    'PINK': {
      root: styles.rootPink,
      disabled: styles.rootPinkDisabled,
    },
    'PINK_OUTLINED': {
      root: styles.rootPinkOutlined,
      disabled: styles.rootPinkOutlinedDisabled,
    },
    'BLUE': {
      root: styles.rootBlue,
      disabled: styles.rootBlueDisabled,
    },
    'BLUE_OUTLINED': {
      root: styles.rootBlueOutlined,
      disabled: styles.rootBlueOutlinedDisabled,
    },
    'LINK': {
      root: styles.rootLink,
      disabled: styles.rootLinkDisabled,
    },
    'PINK_OUTLINED_MODAL': {
      root: styles.rootPinkOutlinedModal,
      disabled: styles.rootPinkOutlinedDisabledModal,
    },
    'PINK_MODAL': {
      root: styles.rootPinkModal,
      disabled: styles.rootPinkDisabledModal,
    },
    
  }

  const variantStyles = variantStyleMap[variant]

  return (
    <button
      type={type}
      className={cx(styles.defaultRoot, variantStyles.root, { [styles.fullwidth]: fullwidth, [styles.defaultRootDisabled]: disabled, [variantStyles.disabled]: disabled, [className]: className, })}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['DEFAULT','PINK', 'PINK_OUTLINED', 'BLUE', 'BLUE_OUTLINED', 'LINK']),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  fullwidth: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  variant: 'DEFAULT',
  style: {},
  fullwidth: false,
  className: '',
};

export default memo(Button);
/*conflict-resolution-will be removed later*/