import React from 'react'
import { useDispatch } from 'react-redux';

import GPAddVehicleTable from '../gp-tables/gp-add-vehicle-table/GPAddVehicleTable';
import GPDeleteConfirmationModal from '../gp-list/gp-delete-confirmation-modal/GPDeleteConfirmationModal';
import GPCreateGroupModal from '../gp-create-group/gp-create-group-modal/GPCreateGroupModal';

import { customToast } from '../../../../utils/toasts';
import dashboardGroupsAction from '../../../../store/actions/dashboard/dashboardGroupsAction';
import { GroupSave } from '../../../../store/actions/groups';
import { CloseButton } from 'react-bootstrap';

import "./GPEditGroup.css"
import GPExistingGroupModal from './gp-existing-group-modal/GPExistingGroupModal';
import { SubGroupSave , EditSubGroupSave } from '../../../../store/actions/groups';

const GPEditGroup = (props) => {
    const { groupData, activeView, editSubGroupIndex, activeVehicleList, fetchGroupsList, onComplete ,groupsAssignDepartmentAdmin , allDashboardVehicleData } = props;

    let subgroupData = {}
    if (![null, undefined].includes(editSubGroupIndex)) {
        subgroupData = groupData.subGroup[editSubGroupIndex];
    }

    const dispatch = useDispatch();
    const selectAllVehicleRef = React.useRef()
    const addVehicleTableRef = React.useRef(null)

    const [activeState, setActiveState] = React.useState('EDIT');
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [selectedVehicleIds, setSelectedVehicleIds] = React.useState([]);

    const [selectedAllVehicle, setSelectedAllVehicle] = React.useState(false);
    const [filteredActiveVehicleList, setFilteredActiveVehicleList] = React.useState([]);

    const [isDeleteModelOpen, setIsDeleteModelOpen] = React.useState(false)
    const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = React.useState(false)

    const [createSubgroupModalOpen, setCreateSubgroupModalOpen] = React.useState(false);
    const [createSubgroupFormData, setCreateSubgroupFormData] = React.useState({ groupName: '' })
    const [createSubgroupFormError, setCreateSubgroupFormError] = React.useState({ groupName: '' })
    const [isAllMoveVehicleNotDone, setIsAllMoveVehicleNotDone] = React.useState(false)

    const [existingSubgroupModalOpen, setExistingSubgroupModalOpen] = React.useState(false);
    const [existingSubgroupFormData, setExistingSubgroupFormData] = React.useState({ groupIndex: null })
    const [existingSubgroupFormError, setExistingSubgroupFormError] = React.useState({ groupIndex: '' })


      const selectedAssigneesInitialValue = groupsAssignDepartmentAdmin.filter(admin => 
        groupData.assignees.includes(admin.value)
    );
    
   

    const [selectedAssignees, setselectedAssignees] =React.useState([...selectedAssigneesInitialValue])

    const [currentPage, setCurrentPage] = React.useState(1);

    const [selectedOEM, setSelectedOEM] = React.useState([]);
    const [selectedCity, setSelectedCity] = React.useState([]);
    const [selectedVehicleType, setSelectedVehicleType] = React.useState([])
    const [selectedVehicleNumber, setSelectedVehicleNumber] = React.useState([])
    const [selectedChargingType, setSelectedChargingType] = React.useState([])
    const [savedFilters, setSavedFilters] = React.useState({
        oem: [],
        city: [],
        vehicleTypes:[],
        vehicleNumber:[],
        chargingType:[]
    });
    const [searchQuery, setSearchQuery] = React.useState('');




    React.useEffect(() => {
        filterActiveVehicleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSubGroupIndex, refreshCount])

    const filterActiveVehicleList = () => {
        let existingVehicleIds = [];
        let parentGroupVehicleIds = [];
        if (groupData._id) {
         
            if (!subgroupData || !Object.keys(subgroupData).length) {
               
                existingVehicleIds = [...existingVehicleIds, ...groupData.vehicles];
            } else {
               
                if (['MOVE_VEHICLE', 'EDIT'].includes(activeState)) {
                   
                    existingVehicleIds = [...existingVehicleIds, ...subgroupData.vehicles];
                  
                } else {
                    parentGroupVehicleIds  = [...existingVehicleIds, ...groupData.vehicles];
                    existingVehicleIds = [...existingVehicleIds, ...subgroupData.vehicles];
                }
            }    
        }

        if (['MOVE_VEHICLE', 'EDIT'].includes(activeState)) {
    
            setFilteredActiveVehicleList(activeVehicleList.filter(vehicle => existingVehicleIds.includes(vehicle.registration_number)));
        }

        if (activeState === 'ADD_VEHICLE') {
        
            let subGroupVehicles = activeVehicleList.filter(vehicle => parentGroupVehicleIds.includes(vehicle.registration_number)) ;

               subGroupVehicles = subGroupVehicles.filter(vehicle => !existingVehicleIds.includes(vehicle.registration_number)) ; 
      
            if (!subgroupData || !Object.keys(subgroupData).length) {
               

             
                setFilteredActiveVehicleList(activeVehicleList.filter(vehicle => !existingVehicleIds.includes(vehicle.registration_number)));
            } else {
              
                setFilteredActiveVehicleList(subGroupVehicles);
            }
        }
    }

    const handleVehicleChange = (vehicleId) => {
        let newSelectedVehicleIds = [...selectedVehicleIds]

        if (newSelectedVehicleIds.includes(vehicleId)) {
            if (activeState === "ADD_VEHICLE") {
                dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
                    vehicleIds: [vehicleId],
                    isSubGroup: editSubGroupIndex !== null,
                    subgroupId: null,
                    subgroupIndex: editSubGroupIndex,
                }))
            }
            newSelectedVehicleIds = newSelectedVehicleIds.filter(v => v !== vehicleId)
        } else {
            if (activeState === "ADD_VEHICLE") {
                dispatch(dashboardGroupsAction.addVehiclesToGroup({
                    vehicleIds: [vehicleId],
                    isSubGroup: editSubGroupIndex !== null,
                    subgroupId: null,
                    subgroupIndex: editSubGroupIndex,
                }))
            }
            newSelectedVehicleIds.push(vehicleId)
        }

        setSelectedVehicleIds([...newSelectedVehicleIds]);
    }

    const handleSelectAllChange = (isSelected, filteredVehicleList) => {
        setSelectedVehicleIds(filteredVehicleList)
        setSelectedAllVehicle(isSelected);
        if (activeState === "ADD_VEHICLE") {
            if (isSelected) {
                dispatch(dashboardGroupsAction.addVehiclesToGroup({
                    vehicleIds: [...filteredVehicleList],
                    isSubGroup: editSubGroupIndex !== null,
                    subgroupId: null,
                    subgroupIndex: editSubGroupIndex,
                }))
            } else {
                dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
                    vehicleIds: [...filteredVehicleList],
                    isSubGroup: editSubGroupIndex !== null,
                    subgroupId: null,
                    subgroupIndex: editSubGroupIndex,
                }))
            }
        }
    }

    const handleVehicleMoveValidation = () => {
		let isValid = true;
		
		// has unsaved sub group
		const unsavedSubgroupIndex = groupData.subGroup.findIndex(group => group._id === null);

		// main group is saved and
		// there is unsaved subgroup and
		// vehicles present in main group
		if (groupData._id && unsavedSubgroupIndex !== -1 && groupData.vehicles.length) {
			isValid = false;
		}
		
		return isValid;
	}

    const handleBlankGroupValidation = () => {
        let isValid = true;

        if (!groupData.subGroup.length && !groupData.vehicles.length) {
            isValid = false;
        }

        return isValid;
    }
    

    const handleSaveClick = async () => {
        const adminids = selectedAssignees.map(option => option.value);
        const data = {...groupData,assignees:adminids}
        if (activeState === "ADD_VEHICLE") {
            setRefreshCount(refreshCount + 1);
            setSelectedVehicleIds([]);
            setActiveState("EDIT");
            setCurrentPage(1)
            setSelectedAllVehicle(false);
            setSelectedChargingType([]);
            setSelectedVehicleNumber([]);
            setSelectedVehicleType([]);
            setSelectedCity([]);
            setSelectedOEM([]);
            setSavedFilters({
                oem: [],
                city: [],
                vehicleTypes:[],
                vehicleNumber:[],
                chargingType:[]
            })
            setSearchQuery('')
        } else {
            if (activeState === 'MOVE_VEHICLE' && !handleVehicleMoveValidation()) {
                setIsAllMoveVehicleNotDone(true)
            	return;
            }

            if (!handleBlankGroupValidation()) {
                customToast({ message: "Can not save an empty group.", svgImageName: "decline" ,  theme: "light" });
                return;
            }

            const payload = {
                groupDetails: data,
            };

          

            const isSubgroupEmpty = !subgroupData || !Object.keys(subgroupData).length;
            const saveFunction = isSubgroupEmpty ? GroupSave : EditSubGroupSave;
            
            const res = await saveFunction(payload);
            
            if (res.isCompleted === 1) {
                const successMessage = isSubgroupEmpty ? 'Group Saved Successfully' : 'Subgroup Saved Successfully';
                customToast({ message: successMessage ,  theme: "light"});
            } else {
                customToast({ message: res.message  ,  theme: "light"});
            }
            
          
            
            setTimeout(() => {
                fetchGroupsList();
                onComplete("SAVE")
            }, 500)
        }
    }

    const handleCancelValidation = () => {
        if (groupData.subGroup.findIndex(d => !d._id) !== -1) {
            setIsCancelConfirmationModalOpen(true)
            return false;
        }

        return true;
    }

    const handleClick = () => {
        window.location.reload();
    }

    const handleCancelClick = () => {
        if (activeState === "ADD_VEHICLE") {
            setSelectedChargingType([]);
            setSelectedVehicleNumber([]);
            setSelectedVehicleType([]);
            setSelectedCity([]);
            setSelectedOEM([]);
            setSavedFilters({
                oem: [],
                city: [],
                vehicleTypes:[],
                vehicleNumber:[],
                chargingType:[]
            })
            setSearchQuery('')
            dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
                vehicleIds: [...selectedVehicleIds],
                isSubGroup: editSubGroupIndex !== null,
                subgroupId: null,
                subgroupIndex: editSubGroupIndex,
            }))
            
            setTimeout(() => {
                setRefreshCount(refreshCount + 1);
                setSelectedVehicleIds([]);
                setSelectedAllVehicle(false)
                setActiveState("EDIT");
                resetAddVehicleTable()
            }, 300)
        } else {
            if (!handleCancelValidation()) {
                return
            }
            
            onComplete("CANCEL")
            handleClick()
        }
    }

    const handleDeleteVehicle = () => {
        dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
            vehicleIds: [...selectedVehicleIds],
            isSubGroup: editSubGroupIndex !== null,
            subgroupId: null,
            subgroupIndex: editSubGroupIndex,
        }))
        
        setSelectedAllVehicle(false);
        setSelectedVehicleIds([]);
        setIsDeleteModelOpen(false);
        setRefreshCount(refreshCount + 1);
        resetAddVehicleTable()
    }

    const handleCreateSubgroupClick = () => {
  
        setActiveState('MOVE_VEHICLE')
        setCreateSubgroupModalOpen(true);
        setCreateSubgroupFormData({ groupName: '' })
        setCreateSubgroupFormError({ groupName: '' })
    }

    const handleSubgroupNameChange = ({ name, value }) => {
        const newCreateSubgroupFormData = { ...createSubgroupFormData }
        const newCreateSubgroupFormError = { ...createSubgroupFormError }
        
		newCreateSubgroupFormData[name] = value;

		if (newCreateSubgroupFormData[name].trim() === '') {
			newCreateSubgroupFormError[name] = 'Name is required'
		} else {
			newCreateSubgroupFormError[name] = ''
		}
        setCreateSubgroupFormData({ ...newCreateSubgroupFormData })
        setCreateSubgroupFormError({ ...newCreateSubgroupFormError })
    }

    const handleSubgroupSave = async () => {
        if (groupData.subGroup.findIndex(g => g.name === createSubgroupFormData.groupName) !== -1) {
			customToast({ message: 'Subgroup with the same name already exist', svgImageName: 'decline' ,  theme: "light" })
			return;
		}
        dispatch(dashboardGroupsAction.createNewSubgroup({
            name: createSubgroupFormData.groupName.trim(),
            vehicleIds: [...selectedVehicleIds]
        }))


        const payload = {
			groupId: groupData._id, 
			subgroups: [
            {
                name:createSubgroupFormData.groupName.trim(), 
                vehicles: [...selectedVehicleIds]
            },
		]
		};

        const res = await SubGroupSave(payload);
    
        if (res.isCompleted === 1) {
        
            // setSuccessMessage(true);
            customToast({ message: 'Subgroup Created Successfully',  theme: "light" });
    
            
            handleSubgroupCancel();
            setRefreshCount(refreshCount + 1);
            setSelectedVehicleIds([]);
            setSelectedAllVehicle(false);
            resetAddVehicleTable();
            fetchGroupsList();
            onComplete("SAVE")
        } else {
            customToast({ message: 'Error creating subgroup', svgImageName: 'error' ,  theme: "light"});
        }
    }

    const handleSubgroupCancel = () => {
        setCreateSubgroupModalOpen(false);
        setCreateSubgroupFormData({ groupName: '' })
        setCreateSubgroupFormError({ groupName: '' })
    }

    const  handleExistingSubgroupClick = () => {
        setActiveState('MOVE_VEHICLE')
        setExistingSubgroupModalOpen(true);
    }
    
    const handleExistingSubgroupConfirmation = () => {
        setActiveState('MOVE_VEHICLE')
        dispatch(dashboardGroupsAction.addVehiclesToGroup({
            vehicleIds: [...selectedVehicleIds],
            isSubGroup: existingSubgroupFormData.groupIndex !== null,
            subgroupId: null,
            subgroupIndex: existingSubgroupFormData.groupIndex,
        }))

        dispatch(dashboardGroupsAction.removeVehiclesFromGroup({
            vehicleIds: [...selectedVehicleIds],
            isSubGroup: editSubGroupIndex !== null,
            subgroupId: null,
            subgroupIndex: editSubGroupIndex,
        }))

        customToast({ message: 'Selected vehicle(s) are moved to subgroup' ,  theme: "light" });
        
        handleExistingSubgroupCancel();
        setRefreshCount(refreshCount + 1);
        setSelectedVehicleIds([]);
        setSelectedAllVehicle(false)
        resetAddVehicleTable()
    }

    const handleExistingSubgroupCancel = () => {
        setExistingSubgroupModalOpen(false);
        setExistingSubgroupFormData({ groupIndex: '' })
        setExistingSubgroupFormError({ groupIndex: '' })
    }

    const handleExistingSubgroupNameChange = ({ name, value }) => {
        const newExistingSubgroupFormData = { ...existingSubgroupFormData }
        const newExistingSubgroupFormError = { ...existingSubgroupFormError }
        
		newExistingSubgroupFormData[name] = value;

		if (newExistingSubgroupFormData[name].trim() === '') {
			newExistingSubgroupFormError[name] = 'Sub group selection is required'
		} else {
			newExistingSubgroupFormError[name] = ''
		}
        setExistingSubgroupFormData({ ...newExistingSubgroupFormData })
        setExistingSubgroupFormError({ ...newExistingSubgroupFormError })
    }

    const resetAddVehicleTable = () => {
        if (addVehicleTableRef.current) {
            addVehicleTableRef.current.resetPagination()
            addVehicleTableRef.current.resetSearchQuery()
            addVehicleTableRef.current.resetSelectAllVehicle()
        }
    }



    
    
    return (
        <div className='row p-3 mb-2 overflow-group'>
            <div className='d-flex' style={{paddingLeft:'0px'}}>
                <p className='groups__edit__text'>
                    {groupData?.name}
                </p>
                {subgroupData?.name ? (
                    <>
                        <span className='groups__edit__text'> &#10095; </span>
                        <p className='groups__edit__text'>{subgroupData?.name}</p>
                    </>
                ) : null}
                <span className='groups__edit__text'> &#10095; </span>
                <p className='groups__edit__text'>Edit</p>
                {activeState === "ADD_VEHICLE" ? (
                    <>
                        <span className='groups__edit__text'> &#10095; </span>
                        <p className='groups__edit__text'>Add Vehicle</p>
                    </>
                ) : null}
            </div>

            <GPAddVehicleTable
                ref={addVehicleTableRef}
                mode={activeView}
                activeState={activeState}
                vehicleList={filteredActiveVehicleList}
                allDashboardVehicleData={allDashboardVehicleData}
                selectedVehicleIds={selectedVehicleIds}
                onVehicleChange={handleVehicleChange}
                
                selectAllVehicleRef={selectAllVehicleRef}
                selectedAllVehicle={selectedAllVehicle}
                onSelectAllChange={handleSelectAllChange}

                onSaveClick={handleSaveClick}
                onCancelClick={handleCancelClick}

                onDeleteVehicleClick={() => setIsDeleteModelOpen(true)}
                onAddVehicleClick={() => {
                    setActiveState('ADD_VEHICLE');
                    setRefreshCount(refreshCount + 1);
                    setSelectedAllVehicle(false)
                    setSelectedVehicleIds([])
                    resetAddVehicleTable()
                    setSelectedChargingType([]);
                    setSelectedVehicleNumber([]);
                    setSelectedVehicleType([]);
                    setSelectedCity([]);
                    setSelectedOEM([]);
                    setSavedFilters({
                        oem: [],
                        city: [],
                        vehicleTypes:[],
                        vehicleNumber:[],
                        chargingType:[]
                    })
                    setSearchQuery('')
                }}

                onCreateSubgroupClick={handleCreateSubgroupClick}
                onExistingSubgroupClick={handleExistingSubgroupClick}
                subgroupOptions={groupData.subGroup}
                selectedAssigneesData={groupData.assignees}
                selectedAssignees={selectedAssignees}
                groupsAssignDepartmentAdmin={groupsAssignDepartmentAdmin}
                onAssginDAChange={setselectedAssignees}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setSelectedChargingType={setSelectedChargingType}
                setSelectedVehicleNumber={setSelectedVehicleNumber}
                setSelectedVehicleType={setSelectedVehicleType}
                setSelectedCity={setSelectedCity}
                setSelectedOEM={setSelectedOEM}
                selectedChargingType={selectedChargingType}
                selectedVehicleNumber={selectedVehicleNumber}
                selectedVehicleType={selectedVehicleType}
                selectedCity={selectedCity}
                selectedOEM={selectedOEM}
                setSavedFilters={setSavedFilters}
                savedFilters={savedFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}

            />

            <GPCreateGroupModal
                isOpen={createSubgroupModalOpen}
                formData={createSubgroupFormData}
                formError={createSubgroupFormError}
                onChange={handleSubgroupNameChange}
                onSave={handleSubgroupSave}
                onClose={handleSubgroupCancel}
                groupData={groupData}
            />
            
            <GPExistingGroupModal
                isOpen={existingSubgroupModalOpen}
                formData={existingSubgroupFormData}
                formError={existingSubgroupFormError}
                onChange={handleExistingSubgroupNameChange}
                onSave={handleExistingSubgroupConfirmation}
                onClose={handleExistingSubgroupCancel}
                groupData={groupData}
            />

            {
                isAllMoveVehicleNotDone &&
                <div className='row'>
                    <div className='col-6'>

                    </div>
                    <div className='col-6'>
                        <div class="alert alert-success grp__alert-success" role="alert">
                            Move remaining vehicles into a new subgroup.
                            <span 
                                className='float-right' 
                                style={{cursor: 'pointer'}}
                                onClick={() => setIsAllMoveVehicleNotDone(false)}
                                data-testid='toast-remaining-vehicle-button'
                            >
                                <CloseButton></CloseButton>
                            </span>
                            
                        </div>
                    </div>
                </div>
            }

            {/* Confirmation modal for delete vehicle */}
            <GPDeleteConfirmationModal
                handleClose={() => setIsDeleteModelOpen(false)}
                isOpen={isDeleteModelOpen}
                onDeleteHandler={handleDeleteVehicle}
                message='Are you sure you want to remove the selected vehicles from this group?'
            />

            {/* Confirmation modal for unsaved changes related to subgroup migration */}
            <GPDeleteConfirmationModal
                handleClose={() => setIsCancelConfirmationModalOpen(false)}
                isOpen={isCancelConfirmationModalOpen}
                onDeleteHandler={() => onComplete("CANCEL")}
                message='You have unsaved changes that will be lost if you decide to continue'
            />

      
        </div>
    )
}

export default GPEditGroup;