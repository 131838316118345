import React from 'react'
import PropTypes from 'prop-types'

import styles from './PageHeader.module.css'

const PageHeader = (props) => {
    const { heading, enableNavigateBack, onNavigateBack, variant } = props

    if (variant === 'SECONDARY_PAGE') {
        return (
            <div className={styles.secondaryPageWrapper}>
                {enableNavigateBack && (
                    <button
                        className={styles.btnNavigateBack}
                        onClick={onNavigateBack}
                    >
                        <img src='/images/svgicon/trackingScreen/back_arrow_hover.svg' alt="navigate back icon" />
                    </button>
                )}

                <h2 className={styles.secondaryPageHeading}>{typeof heading === 'function' ? heading() : heading}</h2>
            </div>
        )
    }

    return (
        <div className={styles.wrapper}>
            {enableNavigateBack && (
                <button
                    className={styles.btnNavigateBack}
                    onClick={onNavigateBack}
                >
                    <img src='/images/svgicon/trackingScreen/back_arrow_hover.svg' alt="navigate back icon" />
                </button>
            )}

            <h2 className={styles.heading}>{typeof heading === 'function' ? heading() : heading}</h2>
        </div>
    )
}

PageHeader.defaultProps = {
    enableNavigateBack: false,
    onNavigateBack: () => {},
    variant: 'DEFAULT'
}

PageHeader.propTypes = {
    heading: PropTypes.oneOfType(PropTypes.string, PropTypes.node).isRequired,
    enableNavigateBack: PropTypes.bool,
    onNavigateBack: PropTypes.func,
    variant: PropTypes.oneOf(['DEFAULT', 'SECONDARY_PAGE'])
}

export default React.memo(PageHeader)
/*conflict-resolution-will be removed later*/