const initialState = {
    labelsSaved: [],
    labelOwnersSaved: [],
    fenceOwnersSaved : [],
    fenceOwnerCardOpened: false,
    labelOwnerCardOpened: false,
    labelCardOpened: false
}

const parameterConfigurationReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case "loadSavedLabels":
        return {
            ...state,
            labelsSaved: payload,
        }
        case "loadSavedLabelOwners":
        return {
            ...state,
            labelOwnersSaved: payload,
        }
        case "loadSavedFenceOwners":
        return {
            ...state,
            fenceOwnersSaved: payload,
        }  
        case "toggleFenceOwnerCard":
          return{
            ...state,
            fenceOwnerCardOpened: payload
          }
        case "toggleLabelOwnerCard":
          return{
            ...state,
            labelOwnerCardOpened: payload
          }
        case "toggleLabelCard":
          return{
            ...state,
            labelCardOpened: payload
          }
      default:
        return state;
    }
  }

  export default parameterConfigurationReducer;