import { Modal } from "react-bootstrap";
import "./RejectUserModel.css";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ApprovalUserByAdmin } from "../../../../../../store/actions/ra_user_management";
import Loader from "../../../../../helpers/hoc/loader/Loader";
import { useNavigate } from "react-router-dom";

const RejectUserModel = ({ showModal, setIsShowModal, _id,data,ApprovalUserByAdmin}) => {


  let email = localStorage.getItem('email')
  const selectedUserData = data.find(user => user._id === _id)
  const isLoading = useSelector((s) => s?.loader?.isLoading);
  const handleClose = () => setIsShowModal(false);
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleReject = async () => {
    ApprovalUserByAdmin({
      data: { 
        _id : _id,
        approved_by_email: email ,
        ra_name: selectedUserData?.populated_user_data?.raName,
        approval_request_user_email:  selectedUserData?.email,
        actionType: 'reject',
        userRole: selectedUserData?.userRole,
        location: selectedUserData?.location,
      },
      navigateTo: handleNavigate,
    });
    handleClose()
  };
  return (
    <Modal
      onHide={handleClose}
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      size="l"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="mt-4">
        <div>
          <div className="d-flex justify-content-center">
            <span className="delete-reject-text">
              Are you sure you want to Reject Ra user?
            </span>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn-cancle-reject"

              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
            <button className="btn-delete-reject" 
            onClick={handleReject}
            >
              Reject
            </button>
          </div>
        </div>
        <Loader isLoading={isLoading}></Loader>
      </Modal.Body>
    </Modal>
  );
};
RejectUserModel.propTypes = {
  ApprovalUserByAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, {
  ApprovalUserByAdmin,
})(RejectUserModel);
