import API from "../../../service/api";
import API_BOOK from "../../../service/endpoints";
import ActionTypes from "../../actionTypes";

const departmentTemplateAction = {}

/**
 *  @desc         	Get department templates list
 *  @author      	Amir Shaikh
 *  @api          	/department-template/get-access-template
 *  @method       	GET
 *  @createdDate    6-APRIL-2024
 *  @modifiedDate 	6-APRIL-2024
 **/
departmentTemplateAction.getTemplatesList = () => async (dispatch) => {
    try {
        const response = await API({
            url: API_BOOK.DEPARTMENT_TEMPLATE.TEMPLATE_LIST.url,
            method: API_BOOK.DEPARTMENT_TEMPLATE.TEMPLATE_LIST.method,
        })
        if (response.status === 200) {
            dispatch({ type: ActionTypes.DEPARTMENT.TEMPLATE_LIST_SUCCESS, payload: response.data.data })
        } else {
            dispatch({ type: ActionTypes.DEPARTMENT.TEMPLATE_LIST_FAILURE })    
        }
        return response
    } catch (error) {
        dispatch({ type: ActionTypes.DEPARTMENT.TEMPLATE_LIST_FAILURE })
        console.log('error', error)
    }
}

/**
 *  @desc         	Get department master module list
 *  @author      	Amir Shaikh
 *  @api          	/department-template/get-master-modules
 *  @method       	GET
 *  @createdDate    6-APRIL-2024
 *  @modifiedDate 	6-APRIL-2024
 **/
departmentTemplateAction.getMasterModulesList = () => async (dispatch) => {
    try {
        const response = await API({
            url: API_BOOK.DEPARTMENT_TEMPLATE.MASTER_MODULE_LIST.url,
            method: API_BOOK.DEPARTMENT_TEMPLATE.MASTER_MODULE_LIST.method,
        })
        dispatch({ type: ActionTypes.DEPARTMENT.MASTER_MODULE_LIST_SUCCESS, payload: response.data.data })
        return response
    } catch (error) {
        dispatch({ type: ActionTypes.DEPARTMENT.MASTER_MODULE_LIST_FAILURE })
        console.log('error', error)
    }
}

/**
 *  @desc         	Create access template under a department
 *  @author      	Amir Shaikh
 *  @api          	/department-template/create-template
 *  @method       	POST
 *  @createdDate    10-APRIL-2024
 *  @modifiedDate 	10-APRIL-2024
 **/
departmentTemplateAction.createAccessTemplate = async ({ customerId, departmentId, templateName, moduleData }) => {
    try {
        const response = await API({
            url: API_BOOK.DEPARTMENT_TEMPLATE.CREATE_TEMPLATE.url,
            method: API_BOOK.DEPARTMENT_TEMPLATE.CREATE_TEMPLATE.method,
            data: { customerId, departmentId, templateName, modules: moduleData },
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

/**
 *  @desc         	Update access template under a template
 *  @author      	Amir Shaikh
 *  @api          	/department-template/update-template/:templateId
 *  @method       	PUT
 *  @createdDate    10-APRIL-2024
 *  @modifiedDate 	10-APRIL-2024
 **/
departmentTemplateAction.updateAccessTemplate = async ({ templateId, customerId, departmentId, templateName, moduleData }) => {
    try {
        const url = API_BOOK.DEPARTMENT_TEMPLATE.UPDATE_TEMPLATE.url.replace(':templateId', templateId)
        const response = await API({
            url,
            method: API_BOOK.DEPARTMENT_TEMPLATE.UPDATE_TEMPLATE.method,
            data: { customerId, departmentId, templateName, modules: moduleData },
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

export default departmentTemplateAction
