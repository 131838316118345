import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  Container } from 'react-bootstrap';
import _, {  } from 'lodash';
import './index.css';

import dashboardGroupsAction from '../../../store/actions/dashboard/dashboardGroupsAction';
import dashboardAction from '../../../store/actions/dashboard/dashboard';
import { fetchGroupList,  GetGroupAssignDepartmentAdmin,  GetGroupAssignDepartmentUser,  GetGroupAssignListUser,  getGroupslist } from '../../../store/actions/groups';
import { FetchAllDashboardVehicles } from '../../../store/actions/vehicle/vehicle';

import GPList from './gp-list/GPList';

import GroupVehicleListing from '../groupsDark/group-vehicle-listing/GroupVehicleListing';
import GroupVehicleDetails from '../groupsDark/group-vehicle-details/GroupVehicleDetails';

import GPCreateGroup from './gp-create-group/GPCreateGroup';
import GPCreateGroupModal from './gp-create-group/gp-create-group-modal/GPCreateGroupModal';
import { customToast } from '../../../utils/toasts';
import GPEditGroup from './gp-edit-group/GPEditGroup';
import Loader from '../../helpers/hoc/loader/Loader';
import { withRouter } from '../../../service/router';

const VIEWS = {
	GROUPS_LIST: "GROUPS_LIST",
	VEHICLE_LIST: "VEHICLE_LIST",
	VEHICLE_DETAIL: "VEHICLE_DETAIL",

	CREATE_GROUP: "CREATE_GROUP",
	EDIT_GROUP: "EDIT_GROUP",
	CREATE_SUBGROUP: "CREATE_SUBGROUP",
	EDIT_SUBGROUP: "EDIT_SUBGROUP",
}

const ACTIONS = {
	CREATE_GROUP: 'CREATE_GROUP',
	EDIT_GROUP: 'EDIT_GROUP',
	EDIT_SUBGROUP: "EDIT_SUBGROUP",

	CREATE_SUBGROUP: "CREATE_SUBGROUP",
	ADD_SUBGROUP: "ADD_SUBGROUP",
}

const vehicleIgnitionData = [
	{ value: 100, color: '#C9C9C9' },
	{ value: 100, color: '#C9C9C9' },
	{ value: 100, color: '#C9C9C9' },
];

const vehicleStatusData = [
	{
		status: 100,
		color: '#029B88',
	},
	{
		status: 100,
		color: '#E06000',
	},
	{
		status: 100,
		color: '#22046B',
	},
	{
		status: 100,
		color: '#632008',
	},
	{
		status: 100,
		color: '#B31E85',
	},
];

const dashboardCamUrl = 'https://static.videezy.com/system/resources/previews/000/014/052/original/loading_circle_bars.mp4';

class Groups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeView: VIEWS.GROUPS_LIST,
			action: '',
			isGroupLoading: false,
			isDashboardVehicleLoading: false,
			vehicleListPanelData: {
				groupId: '',
				groupIndex: null,
				groupName: '',
				isSubgroup: null,
				groupVehicles: [],
				filterStatus: '',
			},
			vehicleDetailPanelData: {
				registrationNumber: '',
			},

			createGroupModalOpen: false,
			createGroupFormData: {
				groupName: '',
			},
			createGroupFormError: {
				groupName: '',
			},

			activeSubgroupIndex: null,
			activeSubgroupId: null,
			createSubgroupModalOpen: false,
			createSubgroupFormData: {
				groupName: '',
			},
			createSubgroupFormError: {
				groupName: '',
			},
			isShowViewModal:false,
			assignuserId:[],
			assigngroupName:''
		}
	}

	componentDidMount() {
		this.fetchGroupsList();
		const routeParamId = _.get(this.props.routeParams, 'groupId', null)
		const registrationParamId = _.get(this.props.routeParams, 'registrationNumber', null)
		const { groupId, groupIndex, groupName, isSubgroup, groupVehicles, registrationNumber, filterStatus } = this.props.location.state || {}
		if (routeParamId && !this.props.allDashboardVehicleData.length) {
			this.fetchDashboardVehicleData();
		}

		if (routeParamId && groupId && groupName && groupVehicles && routeParamId === groupId) {
			const stateUpdate = {
				activeView: VIEWS.VEHICLE_LIST,
				vehicleListPanelData: {
					groupId: groupId,
					groupIndex: groupIndex,
					groupName: groupName,
					isSubgroup: isSubgroup,
					groupVehicles: groupVehicles,
					filterStatus,
				},
				vehicleDetailPanelData: {
					registrationNumber: '',
				}
			}
			if (registrationParamId) {
				stateUpdate['activeView'] = VIEWS.VEHICLE_DETAIL
				stateUpdate['vehicleDetailPanelData'] = { registrationNumber }
			}
			this.setState({ ...stateUpdate })
		}
		this.props.GetGroupAssignDepartmentAdminAction()
		this.props.GetGroupAssignDepartmentUserAction()
		if (Array.isArray(this.state.assignuserId) && this.state.assignuserId.length > 0) {
			this.toggleViewOnboardingVehiclesModal()
		}

	}

	componentDidUpdate(prevProps) {
		const prevRegistrationParamId = _.get(prevProps.routeParams, 'registrationNumber', null)
		const routeParamId = _.get(this.props.routeParams, 'groupId', null)
		const registrationParamId = _.get(this.props.routeParams, 'registrationNumber', null)
		const { groupId, groupIndex, groupName, isSubgroup, groupVehicles, registrationNumber, filterStatus } = this.props.location.state || {}
		
		if (routeParamId && groupId && groupName && groupVehicles && routeParamId === groupId) {
			const stateUpdate = {
				activeView: VIEWS.VEHICLE_LIST,
				vehicleListPanelData: {
					groupId: groupId,
					groupIndex: groupIndex,
					groupName: groupName,
					isSubgroup: isSubgroup,
					groupVehicles: groupVehicles,
					filterStatus,
				},
				vehicleDetailPanelData: {
					registrationNumber: '',
				}
			}
			
			if (registrationParamId && this.state.vehicleDetailPanelData.registrationNumber !== registrationNumber) {
				stateUpdate['activeView'] = VIEWS.VEHICLE_DETAIL
				stateUpdate['vehicleDetailPanelData'] = { registrationNumber }
				this.setState({ ...stateUpdate })
			}

			if (!registrationParamId && prevRegistrationParamId) {
				this.setState({ ...stateUpdate })
			}
			
			
		}
	}

	fetchGroupsList = async () => {
		const userId = JSON.parse(localStorage.getItem('user'))?._id;
		if (userId) {
			this.setState({ isGroupLoading: true });
			const result = await fetchGroupList(userId);
			this.props.setGroupsListAction(result);
			this.setState({ isGroupLoading: false });
		}
	}

	fetchDashboardVehicleData = async () => {
		this.setState({ isDashboardVehicleLoading: true });
		try {
			const response = await FetchAllDashboardVehicles();
			if (response.status === 200 && response.data.data) {
				this.props.setAllDashboardVehicleAction(response.data.data)
			}
		} catch (error) {
			console.log("Failed to fetch dashboard vehicle data", error)
		} finally {
			this.setState({ isDashboardVehicleLoading: false });
		}
	}

	// Start: Vehicle list and details panel related handlers
	handleVehicleListOpen = async ({ groupId, groupIndex, groupName, isSubgroup, groupVehicles, filterStatus = 'all' }) => {
		await this.fetchDashboardVehicleData();
		this.props.navigate(`/groups/${groupId}`, {
			replace: true,
			state: { groupId, groupIndex, groupName, isSubgroup, groupVehicles, filterStatus },
		});
	}

	handleVehicleDetailOpen = (registrationNumber, filterStatus) => {
		const { vehicleListPanelData } = this.state
		this.props.navigate(`/groups/${vehicleListPanelData.groupId}/${registrationNumber}`, {
			replace: true,
			state: {
				groupId: vehicleListPanelData.groupId,
				groupIndex: vehicleListPanelData.groupIndex,
				groupName: vehicleListPanelData.groupName,
				isSubgroup: vehicleListPanelData.isSubgroup,
				groupVehicles: vehicleListPanelData.groupVehicles,
				registrationNumber,
				filterStatus,
			}
		})
	}

	handleVehicleSidebarClose = () => {
		const { activeView, vehicleListPanelData } = this.state;

		if (activeView === VIEWS.VEHICLE_LIST) {
			this.props.navigate('/groups', { replace: true })
		}

		if (activeView === VIEWS.VEHICLE_DETAIL) {
			this.props.navigate(`/groups/${vehicleListPanelData.groupId}`, {
				replace: true,
				state: {
					groupId: vehicleListPanelData.groupId,
					groupIndex: vehicleListPanelData.groupIndex,
					groupName: vehicleListPanelData.groupName,
					isSubgroup: vehicleListPanelData.isSubgroup,
					groupVehicles: vehicleListPanelData.groupVehicles,
					filterStatus: vehicleListPanelData.filterStatus,
				},
			});
		}
	};
	// End: Vehicle list and details panel related handlers

	// Start: Create new group journey related handlers
	handleCreateGroupClick = () => {
		this.setState({
			createGroupModalOpen: true,
			createGroupFormData: {
				groupName: '',
			},
			createGroupFormError: {
				groupName: '',
			},
		});
	}

	handleGroupNameChange = ({ name, value }) => {
		const { createGroupFormData, createGroupFormError } = this.state;

		createGroupFormData[name] = value;

		if (createGroupFormData[name].trim() === '') {
			createGroupFormError[name] = 'Name is required'
		} else {
			createGroupFormError[name] = ''
		}

		this.setState({ createGroupFormData })
	}

	handleGroupNameClose = () => {
		this.setState({
			createGroupModalOpen: false,
			createGroupFormData: {
				groupName: '',
			},
			createGroupFormError: {
				groupName: '',
			},
		});
	}

	handleGroupNameSave = () => {
		const { createGroupFormData } = this.state;

		if (this.props.groupsListData.findIndex(g => g.name === createGroupFormData.groupName) !== -1) {
			customToast({ message: 'Group with the same name already exist' , theme: "light"})
			return;
		}

		this.props.createNewGroupAction({ name: createGroupFormData.groupName.trim() });

		this.setState({
			action: ACTIONS.CREATE_GROUP,
			activeView: VIEWS.CREATE_GROUP,
		}, () => {
			this.handleGroupNameClose();
		})
	}
	// End: Create new group journey related handlers

	// Start: Create new subgroup journey related handlers 
	handleCreateSubgroupClick = () => {
		this.setState({
			activeSubgroupIndex: this.props.groupData.subGroup.length,
			createSubgroupModalOpen: true,
			createSubgroupFormData: {
				groupName: '',
			},
			createSubgroupFormError: {
				groupName: '',
			},
		});
	}

	handleAddSubgroupClick = ({ groupData }) => {
		if (groupData.vehicles.length && !groupData.subGroup.length) {
			customToast({ message: 'You cannot create sub group for group with vehicles under it.', svgImageName: 'decline' ,  theme: "light"})
			return;
		}
		this.props.setGroupDataAction(groupData);
		setTimeout(() => {
			this.handleCreateSubgroupClick();
		}, 300)
	}

	handleSubgroupNameChange = ({ name, value }) => {
		const { createSubgroupFormData, createSubgroupFormError } = this.state;

		createSubgroupFormData[name] = value;

		if (createSubgroupFormData[name].trim() === '') {
			createSubgroupFormError[name] = 'Name is required'
		} else {
			createSubgroupFormError[name] = ''
		}

		this.setState({ createSubgroupFormData })
	}

	handleSubgroupNameClose = () => {
		this.setState({
			createSubgroupModalOpen: false,
			createSubgroupFormData: {
				groupName: '',
			},
			createSubgroupFormError: {
				groupName: '',
			},
		});
	}

	handleSubgroupNameSave = () => {
		const { createSubgroupFormData, action } = this.state;
		

		if (this.props.groupData.subGroup.findIndex(g => g.name === createSubgroupFormData.groupName) !== -1) {
			customToast({ message: 'Group with the same name already exist', svgImageName: 'decline',  theme: "light" })
			return;
		}

		let vehicleIds = []
		if (action === ACTIONS.CREATE_GROUP) {
			vehicleIds = this.props.groupData.vehicles;
		}

		this.props.createNewSubgroupAction({ name: createSubgroupFormData.groupName.trim(), vehicleIds });
		this.props.removeVehiclesFromGroupAction({ vehicleIds });

		setTimeout(() => {
			this.setState({
				action: ACTIONS.CREATE_SUBGROUP,
				activeView: VIEWS.CREATE_SUBGROUP,
			}, () => {
				this.handleSubgroupNameClose();
			})
		}, 300)
	}
	// End: Create new subgroup journey related handlers 

	handleCompleteByAction = (action) => {
		this.setState({
			activeView: VIEWS.GROUPS_LIST,
			action: '',
			activeSubgroupIndex: null,
		}, () => {
			this.props.resetGroupsDataAction();
			this.props.resetSubgroupEditAction();
		})
	}

	handleSetActiveView = (activeView) => {
		this.setState({ activeView });
	}

	handleEditGroupClick = ({ groupData, subgroupData, subgroupIndex }) => {
		this.props.setGroupDataAction(groupData);
		if (subgroupData && Object.keys(subgroupData).length) {
			this.props.setSubgroupEditAction({ subGroupId: subgroupData._id, subGroupIndex: subgroupIndex });
		}
		
		setTimeout(() => {
			this.setState({
				activeView: subgroupData && Object.keys(subgroupData).length ? VIEWS.EDIT_SUBGROUP : VIEWS.EDIT_GROUP,
				action: subgroupData && Object.keys(subgroupData).length ? ACTIONS.EDIT_SUBGROUP : ACTIONS.EDIT_GROUP,
			})
		}, 300);
	}

	toggleViewOnboardingVehiclesModal = (e, {id = null, groupName = null, userIds = null}) => {
     this.setState({assignuserId:userIds})
     this.setState({assigngroupName:groupName})
		this.props.GetGroupAssignListUser(userIds);
	
		this.setState(prevState => ({
			isShowViewModal: !prevState.isShowViewModal
		}));
	}
	toggleViewOnboardingVehiclesModalhandleclose =()=>{
		this.setState(prevState => ({
			isShowViewModal: false
		}));
	}
	
	

	render() {
		
		const {
			activeView,
			vehicleListPanelData,
			vehicleDetailPanelData,

			createGroupModalOpen,
			createGroupFormData,
			createGroupFormError,

			activeSubgroupIndex,
			createSubgroupModalOpen,
			createSubgroupFormData,
			createSubgroupFormError,

			isGroupLoading,
			isDashboardVehicleLoading,

		
		} = this.state;

		const {
			allDashboardVehicleData,
			groupsListData,
			groupData,
			favouriteVehicles,
			activeVehicleList,
			editSubGroupIndex,
			editSubGroupId,
			groupsAssignDepartmentAdmin,
		    groupsAssignDepartmentUser,
			groupAssignList
		} = this.props;

		const userRole = JSON.parse(localStorage.getItem('user'))?.userRole;

const assigneesOptionData = (userRole === 'CA' ? groupsAssignDepartmentAdmin : groupsAssignDepartmentUser)
  ?.map(({ name, adminId, _id }) => ({
    label: name,
    value: userRole === 'CA' ? adminId  : _id
  }));

		

		if (activeView === VIEWS.VEHICLE_LIST) {
			const vehicleListingData = allDashboardVehicleData.filter((fd) => vehicleListPanelData.groupVehicles.includes(fd.rn));
			return (
				<GroupVehicleListing
					handleOnCloseSidebar={this.handleVehicleSidebarClose}
					vehicleData={vehicleListingData}
					allVehicle={allDashboardVehicleData}
					handleShowVehicleDetails={this.handleVehicleDetailOpen}
					favouriteVehicles={favouriteVehicles}
					vehicleListGroupDetails={vehicleListPanelData}
				/>
			)
		}

		if (activeView === VIEWS.VEHICLE_DETAIL) {
			const selectedVehicleData = allDashboardVehicleData.find((vd) => vd.rn === vehicleDetailPanelData.registrationNumber);
			return (
				<GroupVehicleDetails
					handleOnCloseSidebar={this.handleVehicleSidebarClose}
					selectedVehicleData={selectedVehicleData}
					vehicleIgnitionData={vehicleIgnitionData}
					vehicleStatusData={vehicleStatusData}
					dashboardCamUrl={dashboardCamUrl}
					favouriteVehicles={favouriteVehicles}
				/>
			);
		}

		return (
			
			<Container fluid="md">
				<div className='d-flex justify-content-between align-items-center flex-row'>
					<h5 className='groups-main-text'>Groups</h5>
				</div>

				{activeView === VIEWS.GROUPS_LIST && (
					<GPList
						groupsListData={groupsListData}
						fetchGroupsList={this.fetchGroupsList}
						onVehicleCountClick={this.handleVehicleListOpen}
						onCreateGroupClick={this.handleCreateGroupClick}
						onAddSubgroupClick={this.handleAddSubgroupClick}
						onEditGroupClick={this.handleEditGroupClick}
						toggleViewOnboardingVehiclesModal={this.toggleViewOnboardingVehiclesModal}
						isShowViewModal={this.state.isShowViewModal}
						toggleViewOnboardingVehiclesModalhandleclose={this.toggleViewOnboardingVehiclesModalhandleclose}
						assigngroupName={this.state.assigngroupName}
						groupAssignList={groupAssignList}

					/>
				)}

				{[VIEWS.CREATE_GROUP, VIEWS.CREATE_SUBGROUP].includes(activeView) && (
					<GPCreateGroup
						groupData={groupData}
						activeVehicleList={activeVehicleList}
						allDashboardVehicleData={allDashboardVehicleData}
						activeSubgroupIndex={activeSubgroupIndex}
						fetchGroupsList={this.fetchGroupsList}
						onComplete={this.handleCompleteByAction}
					    groupsAssignDepartmentAdmin={assigneesOptionData}

					/>
				)}

				{[VIEWS.EDIT_GROUP, VIEWS.EDIT_SUBGROUP].includes(activeView) && (
					<GPEditGroup
						activeView={activeView}
						groupData={groupData}
						editSubGroupIndex={editSubGroupIndex}
						editSubGroupId={editSubGroupId}
						activeVehicleList={activeVehicleList}
						allDashboardVehicleData={allDashboardVehicleData}
						activeSubgroupIndex={activeSubgroupIndex}
						groupsAssignDepartmentAdmin={assigneesOptionData}
						fetchGroupsList={this.fetchGroupsList}
						onComplete={this.handleCompleteByAction}
					/>
				)}

				<GPCreateGroupModal
					isOpen={createGroupModalOpen}
					formData={createGroupFormData}
					formError={createGroupFormError}
					onChange={this.handleGroupNameChange}
					onSave={this.handleGroupNameSave}
					onClose={this.handleGroupNameClose}
					groupData={null}
				/>

				<GPCreateGroupModal
					isOpen={createSubgroupModalOpen}
					formData={createSubgroupFormData}
					formError={createSubgroupFormError}
					onChange={this.handleSubgroupNameChange}
					onSave={this.handleSubgroupNameSave}
					onClose={this.handleSubgroupNameClose}
					groupData={groupData}
				/>
				<Loader isLoading={isGroupLoading || isDashboardVehicleLoading} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	
	
	allDashboardVehicleData: state.dashboardReducer.allDashboardVehicleData,
	groupsListData: state.groupsReducer.groupsListData,
	groupData: state.dashboardGroupsReducer.groupData,
	editSubGroupId: state.dashboardGroupsReducer.editSubGroupId,
	editSubGroupIndex: state.dashboardGroupsReducer.editSubGroupIndex,
	activeVehicleList: state.vehicle.subscribedVehiclesByCI,
	favouriteVehicles: state.favoriteVehicle.favouriteVehicles,
	groupsAssignDepartmentAdmin:state.groupsReducer.groupsAssignDepartmentAdmin,
	groupsAssignDepartmentUser:state.groupsReducer.groupsAssignDepartmentUser,
	groupAssignList:state.groupsReducer.groupAssignList

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	setAllDashboardVehicleAction: dashboardAction.setAllDashboardVehicleData,
	setGroupsListAction: getGroupslist,
	setGroupDataAction: dashboardGroupsAction.setGroupsData,
	createNewGroupAction: dashboardGroupsAction.createNewGroup,
	createNewSubgroupAction: dashboardGroupsAction.createNewSubgroup,
	removeVehiclesFromGroupAction: dashboardGroupsAction.removeVehiclesFromGroup,
	resetGroupsDataAction: dashboardGroupsAction.resetGroupsData,
	setSubgroupEditAction: dashboardGroupsAction.setEditSubgroup,
	resetSubgroupEditAction: dashboardGroupsAction.resetEditSubgroup,
	GetGroupAssignDepartmentAdminAction:GetGroupAssignDepartmentAdmin,
	GetGroupAssignDepartmentUserAction :GetGroupAssignDepartmentUser,
	GetGroupAssignListUser:GetGroupAssignListUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Groups));
