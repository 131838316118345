const AUTH = {
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILED: 'LOGIN_FAILED',
	LOGOUT: 'LOGOUT',
	GET_CUSTOMER_ADDRESS_SUCCESS: 'GET_CUSTOMER_ADDRESS_SUCCESS',
	GET_CUSTOMER_ADDRESS_FAILED: 'GET_CUSTOMER_ADDRESS_FAILED',
	CONFIRM_CUSTOMER_ADDRESS_SUCCESS:'CONFIRM_CUSTOMER_ADDRESS_SUCCESS',
	CONFIRM_CUSTOMER_ADDRESS_FAILED: 'CONFIRM_CUSTOMER_ADDRESS_FAILED',
	GET_DEPARTMENT_SUCCESS:'GET_DEPARTMENT_SUCCESS',
	GET_DEPARTMENT_FAILURE:'GET_DEPARTMENT_FAILURE',
	GET_DU_LOCATION_SUCCESS:'GET_DU_LOCATION_SUCCESS',
	GET_DU_LOCATION_FAILURE:'GET_DU_LOCATION_FAILURE',
	GET_DU_TEMPLATE_SUCCESS:'GET_DU_TEMPLATE_SUCCESS',
	GET_DU_TEMPLATE_FAILURE:'GET_DU_TEMPLATE_FAILURE',
	GET_DU_ASSIGN_TEMPLATE_SUCCESS:'GET_DU_ASSIGN_TEMPLATE_SUCCESS'
};

const USER = {
	USER_REGISTERED_SUCCESS: 'USER_REGISTERED_SUCCESS',
	USER_UPDATED_SUCCESS: 'USER_UPDATED_SUCCESS',
};

const OTP = {
	OTP_SEND_SUCCESS: 'OTP_SEND_SUCCESS',
	OTP_VERIFY_SUCCESS: 'OTP_VERIFY_SUCCESS',
	DISABLE_OTP_FIELD: 'DISABLE_OTP_FIELD',
	OTP_STATE_RESET: 'OTP_STATE_RESET',
};

const SMS_OTP = {
	SMS_OTP_SEND_SUCCESS: 'SMS_OTP_SEND_SUCCESS',
};

const GET_USER = {
	USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
};

const USER_FAVORITE_VEHICLE = {
	ADD_USER_FAVORITE_VEHICLE_SUCCESS: 'ADD_USER_FAVORITE_VEHICLE_SUCCESS',
	DELETE_USER_FAVORITE_VEHICLE_SUCCESS: 'DELETE_USER_FAVORITE_VEHICLE_SUCCESS',
	GET_USER_FAVORITE_VEHICLE_SUCCESS: 'GET_USER_FAVORITE_VEHICLE_SUCCESS',
};

const NOTIFICATION_PANEL = {
	GET_NOTIFICATION_DATA_BY_USER: 'GET_NOTIFICATION_DATA_BY_USER',
	CURRENT_EXPORTED_DATA_NOTIFICATION: 'CURRENT_EXPORTED_DATA_NOTIFICATION',
	START_NOTIFICATION: 'START_NOTIFICATION',
	STOP_NOTIFICATION: 'STOP_NOTIFICATION',
	SHOW_NOTIFICATION_PANEL: 'SHOW_NOTIFICATION_PANEL',
	FILE_DOWNLOAD_PROGRESS: 'FILE_DOWNLOAD_PROGRESS',
	MARK_AS_READ_SUCCESS: 'MARK_AS_READ_SUCCESS',
	SET_MODAL_OPEN:'SET_MODAL_OPEN',
    SET_MODAL_CLOSE:'SET_MODAL_CLOSE'
};
const SUB_GROUP = {
	SUB_GROUP: 'SUB_GROUP_CREATED',
};

const REPORTS = {
	NON_COMM_LOGS: {
		GET_ALL_NON_COMM_LOGS_SUCCESS: 'GET_ALL_NON_COMM_LOGS_SUCCESS',
		GET_ALL_NON_COMM_REPORT_SUCCESS: 'GET_ALL_NON_COMM_REPORT_SUCCESS',
	},
	NON_COMM_ANALYTICS_DAY_WISE :{
		GET_ALL_NON_COMM_ANALYTICS_DAY_WISE_SUCCEESS: 'GET_ALL_NON_COMM_ANALYTICS_DAY_WISE_SUCCEESS',
	},
	NON_COMM_ANALYTICS_SOURCE_WISE :{
		GET_ALL_NON_COMM_ANALYTICS_SOURCE_WISE_SUCCEESS: 'GET_ALL_NON_COMM_ANALYTICS_SOURCE_WISE_SUCCEESS',
	},
	TRIP: {
		GET_ALL_DATA_SUCCESS: 'GET_ALL_DATA_TRIP_SUCCESS',
		GET_ALL_DATA_FAIL: 'GET_ALL_DATA_TRIP_FAIL',
		GET_STOPPAGE_DATA_SUCCESS: 'GET_STOPPAGE_DATA_SUCCESS',
		GET_STOPPAGE_DATA_FAILED: 'GET_STOPPAGE_DATA_FAILED'
	},
	CHARGING: {
		GET_ALL_DATA_SUCCESS: 'GET_ALL_DATA_CHARGING_SUCCESS',
		GET_ALL_DATA_FAIL: 'GET_ALL_DATA_CHARGING_FAIL',
	},
	CHARGING_ANALYTICS: {
		GET_CHARGING_ANALYTICS_DATA_SUCCESS: 'GET_CHARGING_ANALYTICS_DATA_SUCCESS',
		GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS:
			'GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS',
		GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_FAIL:
			'GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_FAIL',
		EXPORT_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS:
			'EXPORT_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS',
		GET_CHARGING_ANALYTICS_DATA_FAIL: 'GET_CHARGING_ANALYTICS_DATA_FAIL',
		IS_CHART_LOADING: 'IS_CHART_LOADING',
		IS_MODAL_LOADING: 'IS_MODAL_LOADING',
		IS_EXPORT_FILE_LOADING: 'IS_EXPORT_FILE_LOADING',
	},
	ALARM: {
		GET_ALL_DATA_SUCCESS: 'GET_ALL_DATA_ALARM_SUCCESS',
		GET_ALL_DATA_FAIL: 'GET_ALL_DATA_ALARM_FAIL',
		GET_ALL_DATA_RESET: 'GET_ALL_DATA_RESET',
		GET_ALL_IMMOBILE_SUCCESS: 'GET_ALL_IMMOBILE_SUCCESS',
		GET_IMMOBILE_COUNT_SUCCESS: 'GET_IMMOBILE_COUNT_SUCCESS',
		GET_ALL_IMMOBILE_FAIL: 'GET_ALL_IMMOBILE_FAIL',
		GET_ALL_NON_COMM_LOGS_SUCCESS: 'GET_ALL_NON_COMM_LOGS_SUCCESS',
		GET_ALL_NON_COMM_LOGS_FAIL: 'GET_ALL_NON_COMM_LOGS_FAIL',
		GET_ALL_ANALYTICS_DATA_SUCCESS: 'GET_ALL_ANALYTICS_DATA_SUCCESS',
		GET_ALL_ANALYTICS_DATA_FAIL: 'GET_ALL_ANALYTICS_DATA_FAIL',
		SET_SHOW_CURRENT_IMMOBILE_DATA: 'SHOW_CURRENT_IMMOBILE_DATA',
		SET_SHOW_CURRENT_NON_COMM_DATA: 'SHOW_CURRENT_NON_COMM_DATA',
		SET_IMMOBILE_TABLE_DATA: 'SET_IMMOBILE_TABLE_DATA',
		SET_NON_COMM_TABLE_DATA: 'SET_NON_COMM_TABLE_DATA',
		GET_NON_COMM_COUNT_SUCCESS: 'GET_NON_COMM_COUNT_SUCCESS',
		SET_ALERT_SCREEN_IMMOBILE_TABLE_PAGE: 'SET_ALERT_SCREEN_IMMOBILE_TABLE_PAGE',
		SET_ALERT_SCREEN_NON_COMM_TABLE_PAGE: 'SET_ALERT_SCREEN_NON_COMM_TABLE_PAGE',
		SET_ALERT_SCREEN_IMMOBILE_TABLE_DURATION: 'SET_ALERT_SCREEN_IMMOBILE_TABLE_DURATION',
		SET_ALERT_SCREEN_NON_COMM_TABLE_DURATION: 'SET_ALERT_SCREEN_NON_COMM_TABLE_DURATION',
		SET_ALARM_TYPE_FOR_TABLE: 'SET_ALARM_TYPE_FOR_TABLE',
		SET_ALERT_SCREEN_ALARM_TABLE_PAGE: 'SET_ALERT_SCREEN_ALARM_TABLE_PAGE',
		SET_ALERT_SCREEN_DRIVING_TABLE_PAGE: 'SET_ALERT_SCREEN_DRIVING_TABLE_PAGE',
		SET_ALARM_TABLE_DATA: 'SET_ALARM_TABLE_DATA',
		SET_ALERT_SCREEN_FROM_DATE_AND_TO_DATE: 'SET_ALERT_SCREEN_FROM_DATE_AND_TO_DATE'

		
	},
	HISTORIC_DATA: {
		GET_ALL_DATA_SUCCESS: 'GET_ALL_DATA_HISTORIC_DATA_SUCCESS',
		GET_ALL_DATA_FAIL: 'GET_ALL_DATA_HISTORIC_DATA_FAIL',
	},
};

const MAP = {
	GET_ADDRESS_BY_LOCATION: 'GET_ADDRESS_BY_LOCATION',
	COORDINATE_TO_DMS: 'COORDINATE_TO_DMS',
};
const FILE = {
	FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
};

const USER_ROLE_AND_MENU_MAPPING = {
	GET_USER_ROLE_SUCCESS: 'GET_USER_ROLE_SUCCESS',
	GET_USER_MENU_SUCCESS: 'GET_USER_MENU_SUCCESS',
	UPDATE_USER_MENU_MAPPING_SUCCESS: 'UPDATE_USER_MENU_MAPPING_SUCCESS',
	UPDATE_USER_MENU_MAPPING_ERROR: 'UPDATE_USER_MENU_MAPPING_ERROR',
};

const VEHICLE = {
	VEHICLE_ONBOARDING: {
		VEHICLE_ONBOARD_UPLOAD_SUCCESS: 'VEHICLE_ONBOARD_UPLOAD_SUCCESS',
		VEHICLE_ONBOARD_UPLOAD_ERROR: 'VEHICLE_ONBOARD_UPLOAD_ERROR',
		GET_ALL_ONBOARDING_LOGS_SUCCESS: 'GET_ALL_ONBOARDING_LOGS_SUCCESS',
		GET_ALL_ONBOARDING_VEHICLES_SUCCESS: 'GET_ALL_ONBOARDING_VEHICLES_SUCCESS',
		GET_ALL_ONBOARDING_VEHICLES_API_ERROR: 'GET_ALL_ONBOARDING_VEHICLES_API_ERROR',
		BULK_VEHICLE_ONBOARDED_UPDATE_SUCCESS: 'BULK_VEHICLE_ONBOARDED_UPDATE_SUCCESS',
		BULK_VEHICLE_ONBOARDED_UPDATE_ERROR: 'BULK_VEHICLE_ONBOARDED_UPDATE_ERROR',
	},
	VEHICLE: {
		GET_CI_SUBSCRIBED_VEHICLES_SUCCESS: 'GET_CI_SUBSCRIBED_VEHICLES_SUCCESS',
		GET_SUBSCRIBED_RN_NUMS_SUCCESS: 'GET_SUBSCRIBED_RN_NUMS_SUCCESS',
		GET_SUBSCRIBED_RN_NUMS_OEM_MODEL:'GET_SUBSCRIBED_RN_NUMS_OEM_MODEL',
		GET_SINGLE_VEHICLES_TRACKING_DETAILS: 'GET_SINGLE_VEHICLES_TRACKING_DETAILS',
		GET_ALL_VEHICLES_TRACKING_DETAILS: 'GET_ALL_VEHICLES_TRACKING_DETAILS',
		TOP_PANE_STATUS: 'TOP_PANE_STATUS',
		GET_ALL_VEHICLES: 'GET_ALL_VEHICLES',
		GET_VEHICLE_TRACKING_CHARGING_DETAILS_SUCCESSS: 'GET_VEHICLE_TRACKING_CHARGING_DETAILS_SUCCESSS',
		SYNC_VEHICLE_SUCCESS: 'SYNC_VEHICLE_SUCCESS',
		SYNC_VEHICLE_FAILED: 'SYNC_VEHICLE_FAILED',
		GET_VEHICLE_TRACKING_CAN_DATA_DETAILS_SUCCESSS: 'GET_VEHICLE_TRACKING_CAN_DATA_DETAILS_SUCCESSS',
	},
};

const DASHBOARD = {
	SET_ALL_DASHBOARD_VEHICLES_DATA: 'SET_ALL_DASHBOARD_VEHICLES_DATA',
	SET_TOP_PANEL_VEHICLE_STATUS: 'SET_TOP_PANEL_VEHICLE_STATUS',
	SET_TOP_PANEL_VEHICLE_NON_COM_STATUS: 'SET_TOP_PANEL_VEHICLE_NON_COM_STATUS',
	SET_TOP_PANEL_SEARCH_QUERY: 'SET_TOP_PANEL_SEARCH_QUERY',
	SET_VEHICLE_INFO_ALERT_TYPE: 'SET_VEHICLE_INFO_ALERT_TYPE',
	UPDATE_HOME_CLICK_COUNTER: 'UPDATE_HOME_CLICK_COUNTER',
	SET_PREV_TOP_PANEL_VEHICLE_STATUS: 'SET_PREV_TOP_PANEL_VEHICLE_STATUS',
	RESET_TRACKING_DETAILS: 'RESET_TRACKING_DETAILS',
	SET_ALL_FILTERS:'SET_ALL_FILTERS',
};

const GEO_FENCE_REPORT = {
	GEO_FANCE_TYPR_SUCCESS: 'GEO_FENCE_TYPE_SUCCESS',
	GEO_FANCE_REPORT_SUCCESS: 'GEO_FENCE_REPORT_SUCCESS',
	GEO_FANCE_OWNERS_SUCCESS: 'GEO_FENCE_OWNERS_SUCCESS',
	GEO_FANCE_LABEL_OWNERS_SUCCESS: 'GEO_FANCE_LABEL_OWNERS_SUCCESS',
	GEO_FANCE_LABEL_SUCCESS: 'GEO_FANCE_LABEL_SUCCESS',
	GEO_FANCE_CITY_SUCCESS: 'GEO_FANCE_CITY_SUCCESS',
	GEO_FANCE_NAME_SUCCESS: 'GEO_FENCE_NAME_SUCCESS',
	GEO_FANCE_UNIQUE_NAME_SUCCESS: 'GEO_FANCE_UNIQUE_NAME_SUCCESS',
	GEO_FANCE_UNIQUE_OWNERS: 'GEO_FANCE_UNIQUE_OWNERS',
	GEO_FANCE_UNIQUE_LABELS: 'GEO_FANCE_UNIQUE_LABELS',
	GEO_FANCE_UNIQUE_LABEL_OWNERS: 'GEO_FANCE_UNIQUE_LABEL_OWNERS',
	GEO_FANCE_REPORT_HISTORY_SUCCESS: 'GEO_FANCE_REPORT_HISTORY_SUCCESS',
};

const LOADER = {
	START_LOADING: 'START_LOADING',
	STOP_LOADING: 'STOP_LOADING',
};

const USERS = {
	GET_ALL_USERS_LIST: 'GET_ALL_USERS_LIST',
	GET_ALL_USERS_LIST_EXCEPTION: 'GET_ALL_USERS_LIST_EXCEPTION',
	GET_USER_BY_ID: 'GET_USER_BY_ID',
	GET_USER_BY_ID_EXCEPTION: 'GET_USER_BY_ID_EXCEPTION',
	CREATE_USER: 'CREATE_USER',
	CREATE_USER_EXCEPTION: 'CREATE_USER_EXCEPTION',
	EDIT_USER: 'EDIT_USER',
	EDIT_USER_EXCEPTION: 'EDIT_USER_EXCEPTION',
	BULK_USER_UPLOAD: 'BULK_USER_UPLOAD',
	BULK_USER_EXCEPTION: 'BULK_USER_EXCEPTION',
	DELETE_USER: 'DELETE_USER',
	DELETE_USER_EXCEPTION: 'DELETE_USER_EXCEPTION',
	GET_ALL_PENDIG_SIGNUP_USER_LIST: 'GET_ALL_PENDIG_SIGNUP_USER_LIST',
	GET_ALL_PENDIG_SIGNUP_USER_LIST_EXCEPTION: 'GET_ALL_PENDIG_SIGNUP_USER_LIST_EXCEPTION',
	RESEND_EMAIL: 'RESEND_EMAIL',
	RESEND_EMAIL_EXCEPTION: 'RESEND_EMAIL_EXCEPTION',
	APPROVAL_USER_BY_ADMIN: 'APPROVAL_USER_BY_ADMIN',
	APPROVAL_USER_BY_ADMIN_EXCEPTION: 'APPROVAL_USER_BY_ADMIN_EXCEPTION',
	USER_ACTIVITIES_FETCHED: 'USER_ACTIVITIES_FETCHED'
};

const MASTERS = {
	GET_ALL_MASTER_TYPES: 'GET_ALL_MASTER_TYPES_SUCCESS',
	GET_ALL_MASTER_TYPES_EXCEPTION: 'GET_ALL_MASTER_TYPES_EXCEPTION',
	GET_MASTER_TYPE_BY_ID_SUCCESS: 'GET_MASTER_TYPE_BY_ID_SUCCESS',
	GET_MASTER_TYPE_BY_ID_EXCEPTION: 'GET_MASTER_TYPE_BY_ID_EXCEPTION',
	CREATE_MASTER_TYPE_SUCCESS: 'CREATE_MASTER_TYPE_SUCCESS',
	CREATE_MASTER_TYPE_EXCEPTION: 'CREATE_MASTER_TYPE_EXCEPTION',
	UPDATE_MASTER_TYPE_SUCCESS: 'UPDATE_MASTER_TYPE_SUCCESS',
	UPDATE_MASTER_TYPE_EXCEPTION: 'UPDATE_MASTER_TYPE_EXCEPTION',
};

const GEO_FENCE = {
	GET_ALL_GEO_FANCE_SUCCESS: 'GET_ALLGEO_FANCE_SUCCESS',
	GEO_FENCE_VEHICLE_BY_FENCE_ID_SUCCESS: 'GEO_FENCE_VEHICLE_BY_FENCE_ID_SUCCESS',
	GEO_FENCE_VEHICLE_BY_FENCE_ID_ERROR: 'GEO_FENCE_VEHICLE_BY_FENCE_ID_ERROR',
	GET_GEO_FANCE_LABEL_SUCCESS: 'GET_GEO_FANCE_LABEL_SUCCESS',
	GET_GEO_FANCE_LABEL_OWNER_SUCCESS: 'GET_GEO_FANCE_LABEL_OWNER_SUCCESS',
	GET_GEO_FANCE_CITY_SUCCESS: 'GET_GEO_FANCE_CITY_SUCCESS',
	GEO_FENCE_IMAGE_UPLOAD_SUCCESS: 'GEO_FENCE_IMAGE_UPLOAD_SUCCESS',
	GEO_FENCE_IMAGE_UPLOAD_ERROR: 'GEO_FENCE_IMAGE_UPLOAD_ERROR',
	GEO_FENCE_EXPORT_VEHICLE_SUCCESS: 'GEO_FENCE_EXPORT_VEHICLE_SUCCESS',
	GEO_FENCE_EXPORT_VEHICLE_ERROR: 'GEO_FENCE_EXPORT_VEHICLE_ERROR',
	TOGGLE_SELECT_GEO_FENCE_CARD: 'TOGGLE_SELECT_GEO_FENCE_CARD',
};
const DIFFERENT_CREATE_FENCE_ACTIONS = {
	PLACES_SEARCHBAR_VALUE: 'PLACES_SEARCHBAR_VALUE',
	PLACES_SEARCHBAR_CO_ORDINATES: 'PLACES_SEARCHBAR_CO_ORDINATES',
	PLACES_SEARCHBAR_KEY: 'PLACES_SEARCHBAR_KEY',
	ZOOM_LEVEL: 'ZOOM_LEVEL',
	DRAWN_SHAPES: 'DRAWN_SHAPES',
	CREATE_FENCE: 'CREATE_FENCE',
	CREATE_FENCE_EXCEPTION: 'CREATE_FENCE_EXCEPTION',
	GET_FENCE_BY_ID: 'GET_FENCE_BY_ID_SUCCESS',
	GET_FENCE_BY_ID_EXCEPTION: 'GET_FENCE_BY_ID_EXCEPTION',
	UPDATE_FENCE: 'UPDATE_FENCE',
	UPDATE_FENCE_EXCEPTION: 'UPDATE_FENCE_EXCEPTION',
	FENCE_API_OPERATION: 'FENCE_API_OPERATION',
};

const GEOFENCE_ANALYTICS_ACTIONS = {
	LOAD_CITIES: 'LOAD_CITIES',
	LOAD_LABELS: 'LOAD_LABELS',
	LOAD_LABEL_OWNERS: 'LOAD_LABEL_OWNERS',
	LOAD_FENCE_OWNERS: 'LOAD_FENCE_OWNERS',
	SET_SELECTED_CITIES: 'SET_SELECTED_CITIES',
	SET_SELECTED_LABELS: 'SET_SELECTED_LABELS',
	SET_SELECTED_LABEL_OWNERS: 'SET_SELECTED_LABEL_OWNERS',
	SET_SELECTED_FENCE_OWNERS: 'SET_SELECTED_FENCE_OWNERS',
	SET_START_DATE: 'SET_START_DATE',
	SET_END_DATE: 'SET_END_DATE',
	SET_INGRESS_SELECTED: 'SET_INGRESS_SELECTED',
	SET_EGRESS_SELECTED: 'SET_EGRESS_SELECTED',
	SET_SELECTED_DATA_INTERVAL: 'SET_SELECTED_DATA_INTERVAL',
	SET_OPERATIONS_TREND_DATA: 'SET_OPERATIONS_TREND_DATA',
	SET_REPORTS_DATA: 'SET_REPORTS_DATA',
	SET_REPORTS_CURRENT_PAGE: 'SET_REPORTS_CURRENT_PAGE',
};

const REPORTS_ANALYTICS = {
	TRIP_REPORT_ACTIONS: {
		SET_CUSTOM_DURATION: 'SET_CUSTOM_DURATION',
		SET_SELECTED_DURATION: 'SET_SELECTED_DURATION',
		SET_TRIP_REPORT_DATA: 'SET_TRIP_REPORT_DATA',
		SET_AREA_CHART_DATA_BY_CITY: 'SET_AREA_CHART_DATA_BY_CITY',
		SET_AREA_CHART_DATA_BY_OEM_MODEL: 'SET_AREA_CHART_DATA_BY_OEM_MODEL',
		SET_AREA_CHART_OEM_MODEL_LEVEL: 'SET_AREA_CHART_OEM_MODEL_LEVEL',
		SET_AREA_CHART_CITY_LEVEL: 'SET_AREA_CHART_CITY_LEVEL',
		TRIP_REPORT_MODEL_SUCCESS: 'TRIP_REPORT_MODEL_SUCCESS',
		TRIP_REPORT_MODEL_ERROR: 'TRIP_REPORT_MODEL_ERROR',
		SET_REPORTS_CURRENT_PAGE: 'SET_REPORTS_CURRENT_PAGE',
		SET_OEM_CITY_WISE_TRIP_REPORT_DATA: 'SET_OEM_CITY_WISE_TRIP_REPORT_DATA',
		SET_OEM_CITY_WISE_TRIP_REPORT_FILTER: 'SET_OEM_CITY_WISE_TRIP_REPORT_FILTER',
	},
};
const GROUPS = {
	GROUPS_LIST: 'GROUPS_LIST',
	GROUPS_DELETE: 'GROUPS_DELETE',
	GROUPS_RENAME: 'GROUPS_RENAME',
	GROUPS_CREATE: 'GROUPS_CREATE',
	GROUPS_EDIT: 'GROUPS_EDIT',
	GROUPS_SAVE: 'GROUPS_SAVE',
	GET_ASSIGN_DEPARTMENT_ADMIN:'GET_ASSIGN_DEPARTMENT_ADMIN',
	GET_ASSIGN_DEPARTMENT_USER:'GET_ASSIGN_DEPARTMENT_USER',
	GET_ASSIGN_LIST:'GET_ASSIGN_LIST'

};

const DASHBOARD_GROUPS = {
	SET_EDIT_GROUP_DATA: 'SET_EDIT_GROUP_DATA',
	RESET_EDIT_GROUP_DATA: 'RESET_EDIT_GROUP_DATA',

	SET_EDIT_SUBGROUP_DATA: 'SET_EDIT_SUBGROUP_DATA',
	RESET_EDIT_SUBGROUP_DATA: 'RESET_EDIT_SUBGROUP_DATA',

	SET_GROUP: 'SET_GROUP',
    RESET_GROUP:'RESET_GROUP',
	REMOVE_VEHICLES_FROM_GROUP: 'REMOVE_VEHICLES_FROM_GROUP',
	ADD_VEHICLES_TO_GROUP: 'ADD_VEHICLES_TO_GROUP',
	ADD_ADMIN_TO_GROUP:'ADD_ADMIN_TO_GROUP',

	CREATE_NEW_GROUP: 'CREATE_NEW_GROUP',
	CREATE_NEW_SUBGROUP: 'CREATE_NEW_SUBGROUP',
	RESET_STATES:'RESET_STATES',

	SET_ACTIVE_VEHICLES: 'SET_ACTIVE_VEHICLES',
	RESET_ACTIVE_VEHICLES: 'RESET_ACTIVE_VEHICLES',

};


const RECORDS ={
	GET_VEHICLE_STATUS: 'GET_VEHICLE_STATUS',

}

const FLEET = {
	OEM_API: 'FLEET_OEM_API'
}

const SA_SIGNUP = {
	SA_SIGNUP_SUCCESS: 'SA_SIGNUP_SUCCESS',
	SA_SIGNUP_FAILED: 'SA_SIGNUP_FAILED',
	SA_VERIFY_OTP_SUCCESS: 'SA_VERIFY_OTP_SUCCESS',
	SA_VERIFY_OTP_FAILED: 'SA_VERIFY_OTP_FAILED',
}
const ADMINISTRATION = {
	ADMINISTRATION_CUSTOMER_LIST: 'ADMINISTRATION_CUSTOMER_LIST',
	CREATE_CUSTOMER:'CREATE_CUSTOMER',
	UPDATE_CUSTOMERS:'UPDATE_CUSTOMERS',
	UPDATE_STATUS_CUSTOMERS:'UPDATE_STATUS_CUSTOMERS'

};

const CA_FLOW = { 
	GET_ALL_CUSTOMER_ADMIN_LOCATION_SUCCESS: 'GET_ALL_CUSTOMER_ADMIN_LOCATION_SUCCESS',
	GET_ALL_CUSTOMER_ADMIN_LOCATION_FAILED: 'GET_ALL_CUSTOMER_ADMIN_LOCATION_FAILED',
	GET_ALL_MASTER_LOCATIONS_SUCCESS: 'GET_ALL_MASTER_LOCATIONS_SUCCESS', 
	GET_ALL_MASTER_LOCATIONS_FAILED: 'GET_ALL_MASTER_LOCATIONS_FAILED'
}

const DA_FLOW = {
	GET_ALL_DEPARTMENT_ADMIN_USERS_SUCCESS: 'GET_ALL_DEPARTMENT_ADMIN_USERS_SUCCESS',
	GET_ALL_DEPARTMENT_ADMIN_USERS_FAILED: 'GET_ALL_DEPARTMENT_ADMIN_USERS_FAILED',
	GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_SUCCESS: 'GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_SUCCESS',
	GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_FAILED: 'GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_FAILED',
	GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_SUCCESS: 'GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_SUCCESS',
	GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_FAILED: 'GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_FAILED',
	GET_PENDING_USERS_SUCCESS: 'GET_PENDING_USERS_SUCCESS',
	GET_PENDING_USERS_FAILED: 'GET_PENDING_USERS_FAILED',
}

const DEPARTMENT = {
	MODULE_LIST_SUCCESS: 'MODULE_LIST_SUCCESS',
	MODULE_LIST_FAILURE: 'MODULE_LIST_FAILURE',
	DEPARTMENT_LIST_SUCCESS:'DEPARTMENT_LIST_SUCCESS',
	DEPARTMENT_LIST_FAILURE:'DEPARTMENT_LIST_FAILURE',
	DEPARTMENT_USER_FAILURE:'DEPARTMENT_USER_FAILURE',
	DEPARTMENT_USER_SUCCESS:'DEPARTMENT_USER_SUCCESS',
	DEPARTMENT_VEHICLE_FAILURE:'DEPARTMENT_VEHICLE_FAILURE',
	DEPARTMENT_VEHICLE_SUCCESS:'DEPARTMENT_VEHICLE_SUCCESS',
	DEPARTMENT_ACCESS_TEMPLATE_FAILURE:'DEPARTMENT_TEMPLATE_FAILURE',
	DEPARTMENT_ACCESS_TEMPLATE_SUCCESS:'DEPARTMENT_TEMPLATE_SUCCESS',

	TEMPLATE_LIST_SUCCESS:'TEMPLATE_LIST_SUCCESS',
	TEMPLATE_LIST_FAILURE:'TEMPLATE_LIST_FAILURE',
	MASTER_MODULE_LIST_SUCCESS:'MASTER_MODULE_LIST_SUCCESS',
	MASTER_MODULE_LIST_FAILURE:'MASTER_MODULE_LIST_FAILURE',
	VEHICLES_COUNT_SUCCESS: 'VEHICLES_COUNT_SUCCESS',
	VEHICLES_COUNT_FAILURE: 'VEHICLES_COUNT_FAILURE'
}


const IMMOBILIZE = {
	IMMOBILIZE_LIST_SUCCESS: 'IMMOBILIZE_LIST_SUCCESS',
	IMMOBILIZE_FAILURE: 'IMMOBILIZE_FAILURE',
	MOBILIZE_LIST_SUCCESS: 'MOBILIZE_LIST_SUCCESS',
	IMMOBILIZE_HISTORY_LIST_SUCCESS: 'IMMOBILIZE_HISTORY_LIST_SUCCESS',



}

const ActionTypes = {
	AUTH,
	USER,
	OTP,
	SMS_OTP,
	GET_USER,
	USER_FAVORITE_VEHICLE,
	REPORTS,
	MAP,
	FILE,
	USER_ROLE_AND_MENU_MAPPING,
	VEHICLE,
	LOADER,
	USERS,
	MASTERS,
	GEO_FENCE,
	DIFFERENT_CREATE_FENCE_ACTIONS,
	GEO_FENCE_REPORT,
	GEOFENCE_ANALYTICS_ACTIONS,
	DASHBOARD,
	REPORTS_ANALYTICS,
	NOTIFICATION_PANEL,
	GROUPS,
	SUB_GROUP,
	DASHBOARD_GROUPS,
	RECORDS,
	FLEET,
	SA_SIGNUP,
	CA_FLOW,
	DA_FLOW,
	ADMINISTRATION,
	DEPARTMENT,
	IMMOBILIZE
};

export default ActionTypes;
