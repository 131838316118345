import React from 'react'
import L from "leaflet";
import { MapContainer, Marker, Polyline, TileLayer, useMap, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import DestinationMarkerIcon from '../../../map/map-routing/routing-destination-marker.svg';
import DirectionMarkerIcon from '../../../map/map-routing/routing-direction-marker.svg';

import './VTMap.css'

const VTMap = (props) => {
  const {
    vehicleIcon,
    routeData,
    currentRouteIndex,
    enableFitBound,
    mapRef,
  } = props;
  const mapVehicleIcon = L.divIcon({
    html: `
    <div style="transform: rotate(${routeData[currentRouteIndex]?.dir || 0}deg)">
        <span class="vt__map-marker">
            <span class="vt__map-marker__tip"></span>
        </span>
    </div>`,
    iconRetinaUrl: vehicleIcon,
    className: 'vtm__vehicle-marker',
  })

  const waypoints = routeData.map(d => ({ lat: d.lt, lng: d.lng, gpsDirection: d.dir }));

  const MapMarkerWrapper = (props) => {
    return props.waypoints.map((wp, i) => {
      // map direction markers
      if (i !== 0 && i % 4 === 0) {
        const mapDirectionIcon = L.divIcon({
          html: `<img src='${DirectionMarkerIcon}' style="transform: rotate(${wp?.gpsDirection || 0}deg);" />`,
          iconRetinaUrl: DirectionMarkerIcon,
          iconSize: [12, 12],
          className: 'vtm__direction-marker',
        })
        return <Marker key={`marker_${i}`} position={[wp.lat, wp.lng]} icon={mapDirectionIcon}></Marker>
      }
      // map destination marker
      if (i === props.waypoints.length - 1) {
        const mapDestinationIcon = L.divIcon({
          html: `<img src='${DestinationMarkerIcon}' />`,
          iconRetinaUrl: DestinationMarkerIcon,
          iconSize: [32, 32],
          className: 'vtm__destination-marker',
        })
        return <Marker key={`marker_${i}`} position={[wp.lat, wp.lng]} icon={mapDestinationIcon}></Marker>
      }
      return null;
    })
  }

  const FitBounds = ({ positions }) => {
    const map = useMap();
    if (enableFitBound) {
      map.fitBounds(positions);
    }
    return null;
  }

  return (
    <MapContainer
      style={{ height: '100%', width: '100%' }}
      center={[20.5937, 78.9629]}
      zoom={5}
      attributionControl={false}
      maxZoom={18}
      maxNativeZoom={18}
      minZoom={5}
      zoomControl={false}
      ref={mapRef}
    >
      <TileLayer
        attribution='&copy; <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors'
        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        subdomains={['mt0','mt1','mt2','mt3']}
        maxZoom={18}
        maxNativeZoom={18}
      />
      <Marker
        position={[routeData[currentRouteIndex].lt, routeData[currentRouteIndex].lng]}
        icon={mapVehicleIcon}
      />
      <MapMarkerWrapper waypoints={waypoints} />
      <Polyline positions={waypoints} color="#77CA8E" />
      <FitBounds positions={waypoints} />
      <ZoomControl position='bottomright' />
    </MapContainer>
  )
}

export default React.memo(VTMap);