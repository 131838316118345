import { useState, useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { BulkUploadUsers } from '../../../../../../store/actions/ra_user_management'
import FileDrawer from '../../../../../pages/vehicle onboarding/child components/file drawer/FIleDrawer' 
import FileUploadProgress from '../../../../../pages/vehicle onboarding/child components/file upload progress bar/FileUploadProgress'
import './BrowseAndUploadFile.css'

const BrowseAndUploadUsersFile = ({
  showModal,
  setIsShowModal,
  BulkUploadUsers,
  uploadSuccess,
  uploadUsersError
}) => {
  const [file, setFile] = useState(null)
  const [fileSelectionError, setFileSelectionError] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  useMemo(() => {
    if (!file) {
      setIsSaveDisabled(false)
    }
  }, [file])

  useMemo(() => {
    if (uploadSuccess) {
      setIsShowModal(false)
    }
  }, [uploadSuccess, setIsShowModal])

  const handleClose = () => setIsShowModal(false)

  const onSaveHandler = () => {
    if (file) {
      setIsSaveDisabled(true)
      BulkUploadUsers({ file })
    } else {
      setFileSelectionError(true)
      setTimeout(() => {
        setFileSelectionError(false)
      }, 2000)
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>Bulk Users Upload</Modal.Title>
          <div>
            <FileDrawer setFile={setFile} />
            {file && <FileUploadProgress setFile={setFile} file={file} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-row gap-1">
            <div className="width-86 text-center">
              {(fileSelectionError || uploadUsersError) && (
                <div className="error-msg">
                  <label className="text-danger error-message text-wrap">
                    {uploadUsersError
                      ? uploadUsersError
                      : 'Please select file to save'}
                  </label>
                </div>
              )}
            </div>

            <div className="width-12">
              <button
                disabled={isSaveDisabled && file}
                onClick={() => {
                  onSaveHandler()
                }}
                className={
                  isSaveDisabled && file ? 'save-btn-disabled' : 'save-btn'
                }
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

BrowseAndUploadUsersFile.propTypes = {
BulkUploadUsers: PropTypes.func.isRequired,
uploadUsersError: PropTypes.bool.isRequired,
uploadSuccess: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    uploadSuccess:state.user_management.uploadSuccess,
    uploadUsersError: state.user_management.uploadUsersError
})

export default connect(mapStateToProps, { 
BulkUploadUsers 
})(
  BrowseAndUploadUsersFile
)
