// External packages import
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// Internal components import
import AccessTemplateListCard from '../AccessTemplateListCard/AccessTemplateListCard'
import BaseCard from '../../../../shared/cards/BaseCard/BaseCard'

// Style import
import styles from './AccessTemplateList.module.css'

const AccessTemplateList = (props) => {
    const { templateList, onTemplateClick, cardId = '', baseCardVariant, classnames = {} } = props
    return (
        <BaseCard className={styles.container} variant={baseCardVariant}>
            <p className={styles.sectionHeading}>Available Template(s): {templateList.length}</p>

            <div className={cx(styles.cardsWrapper, { [classnames.cardsWrapper]: classnames.cardsWrapper })}>
                {templateList.map(template => (
                    <AccessTemplateListCard
                        key={`access_template_${template.id}`}
                        templateId={template.id}
                        templateName={template.name}
                        modulesList={template.modules}
                        onClick={() => onTemplateClick({ templateData: template })}
                        isActive={cardId === template.id}
                        classnames={{
                            container: classnames.cardContainer
                        }}
                    />
                ))}
            </div>
        </BaseCard>
    )
}

AccessTemplateList.defaultProps = {
    baseCardVariant: "default",
    classnames: {
        cardContainer: '',
        cardsWrapper: '',
    }
}

AccessTemplateList.propTypes = {
    templateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onTemplateClick: PropTypes.func.isRequired,
    baseCardVariant: PropTypes.string,
    classnames: PropTypes.shape({
        cardContainer: PropTypes.string,
        cardsWrapper: PropTypes.string,
    })
}

export default AccessTemplateList;