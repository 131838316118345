import { Stack } from 'react-bootstrap'
import "./Filters.css"
import { useEffect, useRef, useState } from 'react';
import SingleDatePicker from '../../../../helpers/hoc/single-date-picker/SingleDatePicker';
import moment from 'moment';

const Filters = ({
    onDateChangeHandler,
    ...props
}) => {
    const baseTestId = props['data-testid'] || 'date-filters'
    const datePickerRef = useRef(null)
    const [state,setState] = useState({selectedDuration : "today", interval : 120,isCustomDateSelected : false})
    const [ selectedDate, setSelectedDate ] = useState('Custom')

    const getFromToDate = (duration) => {
        const today = new Date();
        const dates = {
            from: null,
            to: null,
        };
        switch (duration) {
            case "7days": // Last 7 days
                const last7Days = new Date(today);
                const yesterdayy = new Date(today);
                yesterdayy.setDate(today.getDate() - 1);
                last7Days.setDate(today.getDate() - 7);
                dates.from = new Date(last7Days.setHours(0, 0, 0, 0)).toISOString();
                dates.to = new Date(yesterdayy.setHours(23, 59, 59, 999)).toISOString();
                break;
            case "yesterday": // Yesterday
                const yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);
                dates.from = new Date(yesterday.setHours(0, 0, 0, 0)).toISOString();
                dates.to = new Date(yesterday.setHours(23, 59, 59, 999)).toISOString();
                break;
            case "today": // Today
                dates.to = new Date(new Date().setTime(new Date().getTime() - 60000)).toISOString();
                dates.from = new Date(today.setHours(0, 0, 0, 0)).toISOString();
                break;
            default:
                console.log("dafault filter index not found");
                break;
        }
        return dates;
    };

    useEffect(() => {
        if(!state.isCustomDateSelected) {
            setSelectedDate('Custom')
        }
    }, [state.isCustomDateSelected])

    useEffect(()=>{
        const date = getFromToDate(state.selectedDuration)
        if(date)
        date?.from && date?.to && onDateChangeHandler({ startDate : date?.from, endDate: date?.to, interval : state.interval})
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ state])


    return (
        <Stack direction="horizontal" gap={3}>
            <div className='ms-auto'>
                <>
                    <div style={{ visibility: 'hidden' }}>
                        <SingleDatePicker key={'datepicker-analytics' + 1}
                            maxRangeDays={31}
                            ref={datePickerRef}
                            onDateChange={(startDate, endDate)=>{
                                setState({selectedDuration : "", interval : 720,isCustomDateSelected: true})
                                onDateChangeHandler({ startDate, endDate, interval : 720})
                                const dates = `${moment(startDate).format('DD-MMM-YYYY')} ~ ${moment(endDate).format('DD-MMM-YYYY')}`
                                setSelectedDate(dates)
                            }}
                            data-testid={baseTestId}
                        />
                    </div>
                    
                    <div
                        className={'trip-analytics-filter-button ' + (state.isCustomDateSelected ? 'selected' : '')}
                        onClick={() => {
                            datePickerRef.current.open();
                        }}
                        data-testid={`${baseTestId}-rangepicker-button`}
                    >
                        {selectedDate}
                        {state.isCustomDateSelected ?
                            <img style={{ marginLeft: '5px' }} height={16} width={16} src={'/images/svgicon/datepicker_white.svg'} alt='datepicker icon' />
                            : <img style={{ marginLeft: '5px' }} height={16} width={16} src={'/images/svgicon/datepicker.svg'} alt='datepicker icon' />
                        }
                    </div>
                </>
            </div>

            <div

                className={'trip-analytics-filter-button ' + (!state.isCustomDateSelected && state.selectedDuration === "7days" ? 'selected' : '')}
                onClick={() => setState({selectedDuration : "7days", interval : 720})}
                data-testid={`${baseTestId}-7days-button`}
            >
                7 days
            </div>

            <div
                className={'trip-analytics-filter-button ' + (!state.isCustomDateSelected && state.selectedDuration === "yesterday" ? 'selected' : '')}
                onClick={() => setState({selectedDuration : "yesterday", interval : 120})}
                data-testid={`${baseTestId}-yesterday-button`}
            >
                Yesterday
            </div>

            <div
                className={'trip-analytics-filter-button ' + (!state.isCustomDateSelected && state.selectedDuration === "today" ? 'selected' : '')}
                onClick={() => setState({selectedDuration : "today", interval : 120})}
                data-testid={`${baseTestId}-today-button`}
            >
                Today
            </div>
        </Stack>
    )
}

export default Filters;