import React, { useEffect } from "react";
import { Row, Col} from 'react-bootstrap'
import MultiSelect from "../../../helpers/hoc/mult-select/MultiSelect";
import {
    loadAllCities,
    loadAllLabels,
    loadAllLabelOwners,
    loadAllFenceOwners,
    loadAllVehicles,
    setSelectedCities,
    setSelectedLabels,
    setSelectedLabelOwners,
    setSelectedFenceOwners,
    getAllFences
} from "../../../../store/actions/geo-fence/configuration/alertsConfiguration/alertConfigurationScreenActions";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const FilterView = ({
    loadAllCities,
    loadAllLabels,
    loadAllLabelOwners,
    loadAllFenceOwners,
    loadAllVehicles,
    setSelectedCities,
    setSelectedLabels,
    setSelectedLabelOwners,
    setSelectedFenceOwners,
    getAllFences,
    selectedCities,
    selectedLabels,
    selectedLabelOwners,
    selectedFenceOwners,
    cities,
    labels,
    labelOwners,
    fenceOwners,
    fencesData
})=> {

    useEffect(()=>{
        loadAllCities()
        loadAllLabels()
        loadAllLabelOwners()
        loadAllFenceOwners()
        loadAllVehicles()
    },[loadAllCities, loadAllLabels, loadAllLabelOwners, loadAllFenceOwners,loadAllVehicles])

    useEffect(()=>{
        getAllFences({selectedCities, selectedLabels, selectedLabelOwners, selectedFenceOwners, fencesData})
    },[selectedCities, selectedLabelOwners, selectedLabels, selectedFenceOwners, getAllFences]) // eslint-disable-line

    return (
        <Row>
            <Col>
                <Row>
                    <Col xs={3} className={`config__alert__select ${!selectedCities[0] ? 'no-value-selected' : ''}`}>
                        <MultiSelect
                            webkitScrollbarWidth='5px'
                            webkitScrollbarHeight='5px'
                            minHeight='45px'
                            indicatorSeparatorDisplay='none'
                            valueContainerWidthInPx='200px'
                            valueContainerMaxWidthInPx='200px'
                            menuListPaddingX='px-4'
                            okButtonPaddingEnd='pe-2'
                            optionPosition='ps-2 justify-content-start'
                            placeholderColor='#C6C6C6'
                            placeholder="Select Cities"
                            options={cities}
                            selectedOptions={selectedCities}
                            isMultiSelect = {true}
                            setSelectedOptions={setSelectedCities}
                            valueContainerColor="#797694"
                            customBackgroundColor='#F5F7F9'
                            data-testid='city-filter'
                        />
                    </Col>
                    <Col xs={3} className={`config__alert__select ${!selectedLabels[0] ? 'no-value-selected' : ''}`}>
                        <MultiSelect
                            webkitScrollbarWidth='5px'
                            webkitScrollbarHeight='5px'
                            minHeight='45px'
                            indicatorSeparatorDisplay='none'
                            valueContainerWidthInPx='200px'
                            valueContainerMaxWidthInPx='200px'
                            menuListPaddingX='px-4'
                            okButtonPaddingEnd='pe-2'
                            optionPosition='ps-2 justify-content-start'
                           placeholderColor='#C6C6C6'
                            placeholder="Select Labels"
                            options={labels}
                            selectedOptions={selectedLabels}
                            isMultiSelect = {true}
                            setSelectedOptions={setSelectedLabels}
                            valueContainerColor="#797694"
                            customBackgroundColor='#F5F7F9'
                            data-testid='label-filter'
                        />
                    </Col>
                    <Col xs={3} className={`config__alert__select ${!selectedLabelOwners[0] ? 'no-value-selected' : ''}`}>
                        <MultiSelect
                            webkitScrollbarWidth='5px'
                            webkitScrollbarHeight='5px'
                            minHeight='45px'
                            indicatorSeparatorDisplay='none'
                            valueContainerWidthInPx='200px'
                            valueContainerMaxWidthInPx='200px'
                            menuListPaddingX='px-4'
                            okButtonPaddingEnd='pe-2'
                            optionPosition='ps-2 justify-content-start'
                           placeholderColor='#C6C6C6'
                            placeholder="Select Label Owners"
                            options={labelOwners}
                            selectedOptions={selectedLabelOwners}
                            isMultiSelect = {true}
                            setSelectedOptions={setSelectedLabelOwners}
                            valueContainerColor="#797694"
                            customBackgroundColor='#F5F7F9'
                            data-testid='labelowner-filter'
                        />
                    </Col>
                    <Col xs={3} className={`config__alert__select ${!selectedFenceOwners[0] ? 'no-value-selected' : ''}`}>
                        <MultiSelect
                            webkitScrollbarWidth='5px'
                            webkitScrollbarHeight='5px'
                            minHeight='45px'
                            indicatorSeparatorDisplay='none'
                            valueContainerWidthInPx='200px'
                            valueContainerMaxWidthInPx='200px'
                            menuListPaddingX='px-4'
                            okButtonPaddingEnd='pe-2'
                            optionPosition='ps-2 justify-content-start'
                           placeholderColor='#C6C6C6'
                            placeholder="Select Fence Owners"
                            options={fenceOwners}
                            selectedOptions={selectedFenceOwners}
                            isMultiSelect = {true}
                            setSelectedOptions={setSelectedFenceOwners}
                            valueContainerColor="#797694"
                            customBackgroundColor='#F5F7F9'
                            data-testid='fenceowner-filter'
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

FilterView.propTypes = {
	// Actions
	loadAllCities: PropTypes.func.isRequired,
    loadAllLabels: PropTypes.func.isRequired,
    loadAllLabelOwners: PropTypes.func.isRequired,
    loadAllFenceOwners: PropTypes.func.isRequired,
    setSelectedCities: PropTypes.func.isRequired,
    setSelectedLabels: PropTypes.func.isRequired,
    setSelectedLabelOwners: PropTypes.func.isRequired,
    setSelectedFenceOwners: PropTypes.func.isRequired,
    getAllFences: PropTypes.func.isRequired,
    loadAllVehicles: PropTypes.func.isRequired,
	// States

    selectedCities: PropTypes.array.isRequired,
    selectedLabels: PropTypes.array.isRequired,
	selectedLabelOwners: PropTypes.array.isRequired,
    selectedFenceOwners: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    labelOwners: PropTypes.array.isRequired,
    fenceOwners: PropTypes.array.isRequired,
    fencesData: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
	loadAllCities,
    loadAllLabels,
    loadAllLabelOwners,
    loadAllFenceOwners,
    setSelectedCities,
    setSelectedLabels,
    setSelectedLabelOwners,
    setSelectedFenceOwners,
    getAllFences,
    loadAllVehicles,
    cities: state.alertConfigurationScreen.cities,
    labels: state.alertConfigurationScreen.labels,
    labelOwners: state.alertConfigurationScreen.labelOwners,
    fenceOwners: state.alertConfigurationScreen.fenceOwners,
    selectedCities: state.alertConfigurationScreen.selectedCities,
    selectedLabels: state.alertConfigurationScreen.selectedLabels,
    selectedLabelOwners: state.alertConfigurationScreen.selectedLabelOwners,
    selectedFenceOwners: state.alertConfigurationScreen.selectedFenceOwners,
    fencesData: state.alertConfigurationScreen.fencesData
});

export default connect(mapStateToProps, {
	loadAllCities,
    loadAllLabels,
    loadAllLabelOwners,
    loadAllFenceOwners,
    setSelectedCities,
    setSelectedLabels,
    setSelectedLabelOwners,
    setSelectedFenceOwners,
    getAllFences,
    loadAllVehicles
})(FilterView);