// External packages import
import React, {  useState } from 'react'

// Internal components import
import DepartmentList from './DepartmentList'
import Tabs from '../../../shared/ui/Tabs/Tabs'
import CALocation from './CALocation'





const CAManage = () => {

  const TAB_HEADERS = [{
    label: 'Department',
    value: 'Department',
  }, {
    label: 'Location',
    value: 'Location',
  }]

  const [activeTab, setActiveTab] = useState(TAB_HEADERS[0].value);

  const handleTabChange = (value) => {
    setActiveTab(value);
  }


  return (
    <>
      <div className='mb-4 px-4 py-3'>
        <Tabs
          tabHeaders={TAB_HEADERS}
          value={activeTab}
          onChange={handleTabChange}
          variant='secondary'
        />
      </div>
      {
        activeTab === TAB_HEADERS[0].value ?<DepartmentList/> : null

      }
      {
        activeTab === TAB_HEADERS[1].value ? <CALocation /> : null
      }
    </>
  )
}

export default CAManage
/*conflict-resolution-will be removed later*/