import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ProgressBar, Spinner } from 'react-bootstrap'
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import './imageUploadProgressBar.css'
import AddPhotoIcon from '../../../../../assets/topPaneIcons/geofenceAddPhoto.svg';


const ImageUploadProgressBar = ({ file, setFile, uploadProgress, ...props }) => {
  const baseTestId = props['data-testid'] || 'image-progress'
  const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB'
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB'
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB'
    } else if (bytes > 1) {
      bytes = bytes + ' bytes'
    } else if (bytes === 1) {
      bytes = bytes + ' byte'
    } else {
      bytes = '0 bytes'
    }
    return bytes
  }

  return (
    
    <div className="file-upload-container-geofence">
        <div className='d-flex justify-content-end'>
        <button
              className="cls-btn-geofence"
              onClick={() => {
                setFile(null)
              }}
              data-testid={`${baseTestId}-remove-button`}
            >
              <AiOutlineClose className="close-icon-progressbar-geofence" />
            </button>
        </div>
      <div className="d-flex align-items-center flex-row">
        <div className="px-3 w-25">
          <img
            src={AddPhotoIcon}
            alt="file"
            height={60}
            width={60}
          ></img>
        </div>

        <div className="d-flex flex-column gap-1 w-75 margin-left-geofence">
          <div className="d-flex justify-content-between flex-row gap-3">
            <p className="m-0">{file?.name}</p>

          </div>
          <p className="m-0 upload-size-geofence">
            {uploadProgress > 0
              ? `${formatSizeUnits((uploadProgress / 100) * file.size)} / `
              : ' '}{' '}
            {formatSizeUnits(file.size)}
          </p>
          {uploadProgress > 0 && (
            <div className="d-flex align-items-center flex-row gap-3">
              <div className="w-100">
                <ProgressBar max={100} now={uploadProgress} />
              </div>
              <p className="m-0">{uploadProgress}%</p>
              <Spinner
                animation="border"
                variant="info"
                size="sm"
                className="upload-spinner-geofence"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ImageUploadProgressBar.propTypes = {
  uploadProgress: PropTypes.number,
}
const mapStateToProps = (state) => ({
  uploadProgress: state.fileUpload.uploadProgress,
})

export default connect(mapStateToProps)(ImageUploadProgressBar)
