import React, { useState } from "react";
import "./RaUser.css";
import { FaSearch } from "react-icons/fa";
import { Dropdown, Button } from "react-bootstrap";
import { Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import UserUploadTemplate from "../../../../assets/templates/User Upload_Template.xlsx";
import Pagination from "../../../helpers/hoc/paginator/Pagination";
import {
  GetAllUsers,
  DeleteUser,
} from "../../../../store/actions/ra_user_management";
import { useEffect } from "react";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import moment from "moment/moment";
import BrowseAndUploadUsersFile from "./child-component/BrowseAndUploadFile/BrowseAndUploadFile";
import Loader from "../../../helpers/hoc/loader/Loader";
import DeleteUserModel from "../RaUser/child-component/deleteUserModel/deleteUserModel";
import { fileDownloader } from "../../../../utils/common-methods";

const UserManagement = ({
  //actions
  GetAllUsers,
  DeleteUser,

  //states
  usersList,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 8;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const isLoading = useSelector((s) => s?.loader?.isLoading);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const downloadUserUploadTemplate = () => {
    FileSaver.saveAs(UserUploadTemplate, "User Upload_Template.xlsx");
  };

  const exportToExcel = () => {
    // Create a new workbook and worksheet using ExcelJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("userslist");

    // Add the column names to the worksheet
    worksheet.addRow([
      "S.No",
      "Employee Name",
      "Employee Email",
      "Employee ID",
      "Designation",
      "Department",
      "D.O.B",
      "D.O.J",
      "Operating office",
      "R.A Mail",
      "R.A Name",
    ]);
    const filteredList =  usersList
    ?.filter((user) => !user.isDeleted)
    ?.filter((e) => {
      const fullName =
        `${e.employeeName} ${e.empEmail} ${e.designation} ${e.empId} ${e.department} ${e.dateOfBirth} ${e.dateOfJoining}${e.operatingOffice}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    })
    // Add the data rows to the worksheet
    for (let i = 0; i < filteredList?.length; i++) {
      const rowData = [
        i + 1,
        filteredList[i]?.employeeName,
        filteredList[i]?.empEmail,
        filteredList[i]?.empId,
        filteredList[i]?.designation,
        filteredList[i]?.department,
        moment(filteredList?.dateOfBirth).format("DD/MM/YYYY"),
        moment(filteredList?.dateOfBirth).format("DD/MM/YYYY"),
        filteredList[i]?.operatingOffice,
        filteredList[i]?.raEmail,
        filteredList[i]?.raName,
      ];
      worksheet.addRow(rowData);
    }
    fileDownloader({ excelWorkbook: workbook, fileName: `users-list.xlsx` })
  };
  const [selectedUserId, setSelectedUserId] = useState(null);
  const toggleViewDeleteUserModal = (_id) => {
    setIsShowDeleteUserModal((prev) => !prev);
    setSelectedUserId(_id);
  };

  useEffect(() => {
    GetAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalFilteredPages = Math.ceil(
    usersList
    ?.filter((user) => !user.isDeleted)
    ?.filter((e) => {
      const fullName =
        `${e.employeeName} ${e.empEmail} ${e.designation} ${e.empId} ${e.department} ${e.dateOfBirth} ${e.dateOfJoining}${e.operatingOffice}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    })
    ?.length / ITEMS_PER_PAGE
  );

  return (
    <Container fluid className="scrollable-table">
      <div className="d-flex col-12 flex-column h-100 mt-2">
        <div className="d-flex justify-content-between align-items-center flex-row">
          <div
           className={` d-inline-flex align-items-center  ${!searchQuery? 'no-value-selected-input ' : ''}`}
          >
            <div
            className="search-bar-div search-input"

            >
              <FaSearch className="vob-search-icon" />
              <input
                onChange={(e) => {
                  setCurrentPage(1)
                  setSearchQuery(e.target.value);
                }}
                className="vob-search-input search-input"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end ">
            <div className="me-2">
              <Dropdown>
                <Dropdown.Toggle className="ra-bulk-upload-btn" id="dropdown-basic">
                  Bulk Upload
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      downloadUserUploadTemplate();
                    }}
                  >
                    Download Template
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      isShowModal
                        ? setIsShowModal(false)
                        : setIsShowModal(true);
                    }}
                  >
                    Upload File
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="me-2">
              <Button
                className="ra-add-btn"
                onClick={() => {
                  navigate("/ra-user/add-user");
                }}
              >
                Add
              </Button>
            </div>
            <div>
              <button
                className="dwn-btn"
                onClick={() => {
                  exportToExcel();
                }}
              >
                <BsDownload style={{width:'22px',height:'22px'}} />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white mb-1 position-relative">
          <Table responsive={true} borderless className="mb-0">
            <thead>
              <tr className="th-border-bottom">
                <th className="ps-3 text-nowrap">
                  <span className="fw500">S.No</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Employee Name</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Employee Email</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Employee ID</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Designation</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Department</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">D.O.J</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">R.A Name</span>
                </th>
                <th className="ps-3 text-nowrap">
                  <span className="fw500">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {usersList
                .filter((user) => !user.isDeleted)
                .filter((e) => {
                  const fullName =
                    `${e.employeeName} ${e.empEmail} ${e.designation} ${e.empId} ${e.department} ${e.dateOfBirth} ${e.dateOfJoining}${e.operatingOffice}`.toLowerCase();
                  return fullName.includes(searchQuery.toLowerCase());
                })
                ?.slice(startIndex, endIndex)
                ?.map((e, index) => {
                  return (
                    <tr>
                      <td>
                        {" "}
                        {index +
                          1 +
                          ((currentPage - 1) * 10 +
                            (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                          "."}{" "}
                      </td>
                      <td> {e.employeeName} </td>
                      <td> {e.empEmail} </td>
                      <td> {e.empId} </td>
                      <td> {e.designation} </td>
                      <td> {e.department} </td>
                      <td> {moment(e?.dateOfJoining).format("DD/MM/YYYY")} </td>
                      <td> {e.raName} </td>
                      <td className="ps-3 text-nowrap">
                        <div className="d-flex flex-row justify-content-center gap-2">
                          <button
                            className="row-btn"
                            onClick={() => {
                              navigate("/ra-user/details", {
                                state: {
                                  _id: e?._id,
                                },
                              });
                            }}
                          >
                            <BiEdit className="row-icon" />
                          </button>
                          <button className="row-btn">
                            <BiTrash
                              className="row-icon"
                              onClick={() => toggleViewDeleteUserModal(e._id)}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Pagination
            disabled={false}
            totalPages= {totalFilteredPages}
            currentPage={currentPage}
            setPage={handlePageChange}
          />
          <div>
            {isShowModal && (
              <BrowseAndUploadUsersFile
                showModal={isShowModal}
                setIsShowModal={setIsShowModal}
              />
            )}
            </div>
            <div>
            {isShowDeleteUserModal && (
              <DeleteUserModel
                showModal={isShowDeleteUserModal}
                setIsShowModal={setIsShowDeleteUserModal}
                _id={selectedUserId}
                DeleteUser={DeleteUser}
              />
            )}
          </div>
        </div>
        <Loader isLoading={isLoading}></Loader>
      </div>
    </Container>
  );
};

UserManagement.propTypes = {
  usersList: PropTypes.array.isRequired,
  DeleteUser: PropTypes.func.isRequired,
  // Action props
  GetAllUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  usersList: state.user_management.usersList,
});
export default connect(mapStateToProps, {
  GetAllUsers,
  DeleteUser,
})(UserManagement);
