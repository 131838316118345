/* User roles */
// NOTE: same user role array is used in backend,
// if making change here, please update the same change in backend as well
// to avoid role based routing issues
import controlsMenuIcon from "../assets/sidePaneIcons/controls-menu.svg"
import fleetMenuIcon from "../assets/sidePaneIcons/fleet-menu.svg"
import homeMenuIcon from "../assets/sidePaneIcons/home-menu.svg"
import recordsMenuIcon from "../assets/sidePaneIcons/records-menu.svg"
import geoFenceIcon from "../assets/sidePaneIcons/GeofencingLogo.svg"
import groupsIcon from "../assets/sidePaneIcons/groups-menu.svg"
import immobilizeIconLight from "../assets/sidePaneIcons/immobilizeIconLight.svg"
import immobilizeIconDark from "../assets/sidePaneIcons/immobilizeIconDark.svg"

import customizationMenuIcon from "../assets/sidePaneIcons/customization-menu.svg"
import NewDashboard from '../components/pages/NewDashboardDark'
import VehicleTracking from '../components/pages/VehicleTracking'
import VehicleOnboarding from '../components/pages/vehicle onboarding/VehicleOnboarding'
import Fleet from '../components/pages/Fleet'
import GeofanceDashboard from '../components/Geo-fencing/geoFencDashboard/geoFencDashboard'
import GeoFenceConfigurationScreen from '../components/Geo-fencing/configuration/GeoFenceConfigurationScreen'
import CreateFence from '../components/Geo-fencing/create-fence/CreateFence'
import RaUserManagement from '../components/pages/RaUserManagement/RaUserManagement'
import UserRoleAndMenuMapping from '../components/pages/User/RoleAndMenuMapping/RoleAndMenuMapping'
import UserProfile from '../components/userProfile/userProfile/userProfile'
import UserActivity from '../components/pages/User/UserActivity/UserActivity'
import NewSingleUserModal from '../components/pages/RaUserManagement/RaUser/child-component/SingleUser/SingleUser'
import EditUserModal from '../components/pages/RaUserManagement/RaUser/child-component/EditUserModel/EditUserModel'
import Groups from "../components/pages/groups"
import alertsIcon from '../assets/sidePaneIcons/alertsSidenavIcon.svg'
// import AlertsRevamp from "../components/Alerts/AlertsRevamp"
// import RecordsContainer from "../components/pages/records/RecordsContainer"
import DarkHomeMenuIcon from "../assets/dashboardNewIcons/routes/home.svg"
import DarkControlsMenuIcon from "../assets/dashboardNewIcons/routes/control.svg"
import DarkFleetMenuIcon from "../assets/dashboardNewIcons/routes/fleet.svg"
import DarkRecordsMenuIcon from "../assets/dashboardNewIcons/routes/records.svg"
import DarkGeoFenceIcon from "../assets/dashboardNewIcons/routes/geofence.svg"
import DarkAlertsIcon from "../assets/dashboardNewIcons/routes/alerts.svg"
import DarkGroupsIcon from "../assets/dashboardNewIcons/routes/group.svg"
import ReportingContainer from "../components/pages/reporting/ReportingContainer"
import AlertContainer from "../components/pages/alert-new/AlertContainer"
import Signup from "../components/public-pages/signup/SignUp"
import Forgotpassword from "../components/forgotpassword/Forgotpassword"
import RaActions from "../components/public-pages/ra actions/RaActions"
import TermsandConditions from "../components/pages/TermsandConditions"
import Unauthorized from "../components/pages/Unauthorized"
import Login from '../components/public-pages/login/Login'
import SA_DashboardIcon from '../assets/dashboardNewIcons/routes/SA_DashboardIcon.svg'
import SA_AdminIcon from '../assets/dashboardNewIcons/routes/SA_AdminLogo.svg'
import SA_Log from '../assets/dashboardNewIcons/routes/SA_LogIcon.svg'
import SAAdmin from "../components/pages/super-admin/SAAdmin/SAAdmin"
import SADashboard from "../components/pages/super-admin/SADashboard/SADashboard"
import SALogs from "../components/pages/super-admin/SALogs/SALogs"
import CADashboard from "../components/pages/customer-admin/CADashboard/CADashboard"
import CAAdmin from "../components/pages/customer-admin"
// import CALogs from "../components/pages/customer-admin/CALogs/CALogs"
// import CAManage from "../components/pages/customer-admin/CAManage/CAManage"
// import DADashboard from "../components/pages/department-admin/DADasboard/DADasboard"
import DAAdmin from "../components/pages/department-admin"
import Immobilize from "../components/pages/immobilize/Immobilize"

// Itadmin userid
export const itAdminUserId = ['667cf8b0501b8a7d1b7df681','6679027520d66cb7676d07ca','667fd91d42adddbd1857d5fb'] 


//userId according to userroles
export const NewUserRoleID = [
  {
    label: 'City Level User',
    id: '64cca78e0836f6d81273f06b'
  },
  {
    label: 'Business Development User',
    id: '64cca78e0836f6d81273f06c'
  },
  {
    label: 'Leadership Level Users',
    id: '64cca78e0836f6d81273f068'
  },
  {
    label: 'National Level User',
    id: '64cca78e0836f6d81273f069'
  },
  {
    label: 'Admin',
    id: '6524de49b1e49d598d3828c7'
  },
  {
    label: 'Regional Level User',
    id: '64cca78e0836f6d81273f06a'
  }
]
//userroles
export const UserRolesKeys = {
  LEADERSHIP_LEVEL_USER: 'Leadership Level Users',
  NATIONAL_LEVEL_USER: 'National Level User',
  REGIONAL_LEVEL_USER: 'Regional Level User',
  CITY_LEVEL_USER: 'City Level User',
  BUSINESS_DEVELOPMENT_USER: 'Business Development User',
  ADMIN: 'Admin'
}

//OemAndModels trip
export const TripVehicleOemAndModels = [
  {
    label: 'Mahindra Grand Zor',
    id: 'Mahindra Grand Zor',
    value:'Mahindra Grand Zor',
    oem: 'Mahindra',
    model: 'Grand Zor',
    typeId: '3 Wheeler'
  },
  {
    label: 'Mahindra Treo Zor',
    id: 'Mahindra Treo Zor',
    value:'Mahindra Treo Zor',
    oem: 'Mahindra',
    model: 'Treo Zor',
    typeId: '3 Wheeler'
  },
  {
    label: 'Euler HiLoad',
    id: 'Euler HiLoad',
    value:'Euler HiLoad',
    oem: 'Euler',
    model: 'HiLoad',
    typeId: '3 Wheeler'
  },
  {
    label: 'Piaggio Ape Extra',
    id: 'Piaggio Ape Extra',
    value:'Piaggio Ape Extra',
    oem: 'Piaggio',
    model: 'Ape Extra',
    typeId: '3 Wheeler'
  },
  {
    label: 'Piaggio Sunmobility',
    id: 'Piaggio Sunmobility',
    value:'Piaggio Sunmobility',
    oem: 'Piaggio',
    model: 'Sunmobility',
    typeId: '3 Wheeler'
  },
  {
    label: 'Altigreen neEV',
    id: 'Altigreen neEV',
    value:'Altigreen neEV',
    oem: 'Altigreen',
    model: 'neEV',
    typeId: '3 Wheeler'
  },
  {
    label: 'Altigreen Exponent',
    id: 'Altigreen Exponent',
    value:'Altigreen Exponent',
    oem: 'Altigreen',
    model: 'Exponent',
    typeId: '3 Wheeler'
  },
  {
    label: 'OSM Rage+',
    id: 'OSM Rage+',
    value:'OSM Rage+',
    oem: 'OSM',
    model: 'Rage+',
    typeId: '3 Wheeler'
  },
  {
    label: 'Tata Tigor',
    id: 'Tata Tigor',
    value:'Tata Tigor',
    oem: 'Tata',
    model: 'Tigor',
    typeId: '4 Wheeler'
  },
  {
    label: 'Tata Ace EV',
    id: 'Tata Ace EV',
    value:'Tata Ace EV',
    oem: 'Tata',
    model: 'Ace EV',
    typeId: '4 Wheeler'
  },
  {
    label: 'MG EV ZS',
    id: 'MG EV ZS',
    value:'MG EV ZS',
    oem: 'MG',
    model: 'EV ZS',
    typeId: '4 Wheeler'
  },
  {
    label: 'EKA -K1.5',
    id: 'EKA -K1.5',
    value:'EKA -K1.5',
    oem: 'EKA',
    model: '-K1.5',
    typeId: '4 Wheeler'
  },
  {
    label: 'Bajaj Maxima',
    id: 'Bajaj Maxima',
    value:'Bajaj Maxima',
    oem: 'Bajaj',
    model: 'Maxima',
    typeId: '3 Wheeler'
  },
  {
    label: 'Tata Nexon',
    id: 'Tata Nexon',
    value:'Tata Nexon',
    oem: 'Tata',
    model: 'Nexon',
    typeId: '4 Wheeler'
  },
  {
    label: 'Switch IEV4',
    id: 'Switch IEV4',
    value:'Switch IEV4',
    oem: 'Switch',
    model: 'IEV4',
    typeId: '4 Wheeler'
  },
  {
    label: 'Mahindra Zeo V1-DV',
    id: 'Mahindra Zeo V1-DV',
    value:'Mahindra Zeo V1-DV',
    oem: 'Mahindra',
    model: 'Zeo V1-DV',
    typeId: '4 Wheeler'
  },
  {
    label: 'Mahindra Zeo V2-DV',
    id: 'Mahindra Zeo V2-DV',
    value:'Mahindra Zeo V2-DV',
    oem: 'Mahindra',
    model: 'Zeo V2-DV',
    typeId: '4 Wheeler'
  },
  {
    label: 'Bounce E1x',
    id: 'Bounce E1x',
    value:'Bounce E1x',
    oem: 'Bounce',
    model: 'E1x',
    typeId: '2 Wheeler'
  }
]

/* Vehicle Model */
export const VehicleOemAndModels = [
  {
    label: 'Mahindra Grand Zor',
    id: 'Mahindra Grand Zor',
    oem: 'Mahindra',
    model: 'Grand Zor',
    typeId: '3 Wheeler'
  },
  {
    label: 'Mahindra Treo Zor',
    id: 'Mahindra Treo Zor',
    oem: 'Mahindra',
    model: 'Treo Zor',
    typeId: '3 Wheeler'
  },
  {
    label: 'Euler HiLoad',
    id: 'Euler HiLoad',
    oem: 'Euler',
    model: 'HiLoad',
    typeId: '3 Wheeler'
  },
  {
    label: 'Piaggio Ape Extra',
    id: 'Piaggio Ape Extra',
    oem: 'Piaggio',
    model: 'Ape Extra',
    typeId: '3 Wheeler'
  },
  {
    label: 'Piaggio Sunmobility',
    id: 'Piaggio Sunmobility',
    oem: 'Piaggio',
    model: 'Sunmobility',
    typeId: '3 Wheeler'
  },
  {
    label: 'Altigreen neEV',
    id: 'Altigreen neEV',
    oem: 'Altigreen',
    model: 'neEV',
    typeId: '3 Wheeler'
  },
  {
    label: 'Altigreen Exponent',
    id: 'Altigreen Exponent',
    oem: 'Altigreen',
    model: 'Exponent',
    typeId: '3 Wheeler'
  },
  {
    label: 'OSM Rage+',
    id: 'OSM Rage+',
    oem: 'OSM',
    model: 'Rage+',
    typeId: '3 Wheeler'
  },
  {
    label: 'Tata Tigor',
    id: 'Tata Tigor',
    oem: 'Tata',
    model: 'Tigor',
    typeId: '4 Wheeler'
  },
  {
    label: 'Tata Ace EV',
    id: 'Tata Ace EV',
    oem: 'Tata',
    model: 'Ace EV',
    typeId: '4 Wheeler'
  },
  {
    label: 'MG EV ZS',
    id: 'MG EV ZS',
    oem: 'MG',
    model: 'EV ZS',
    typeId: '4 Wheeler'
  },
  {
    label: 'EKA -K1.5',
    id: 'EKA -K1.5',
    oem: 'EKA',
    model: '-K1.5',
    typeId: '4 Wheeler'
  },
  {
    label: 'Bajaj Maxima',
    id: 'Bajaj Maxima',
    oem: 'Bajaj',
    model: 'Maxima',
    typeId: '3 Wheeler'
  },
  {
    label: 'Tata Nexon',
    id: 'Tata Nexon',
    oem: 'Tata',
    model: 'Nexon',
    typeId: '4 Wheeler'
  },
  {
    label: 'Switch IEV4',
    id: 'Switch IEV4',
    oem: 'Switch',
    model: 'IEV4',
    typeId: '4 Wheeler'
  },
  {
    label: 'Mahindra Zeo V1-DV',
    id: 'Mahindra Zeo V1-DV',
    oem: 'Mahindra',
    model: 'Zeo V1-DV',
    typeId: '4 Wheeler'
  },
  {
    label: 'Mahindra Zeo V2-DV',
    id: 'Mahindra Zeo V2-DV',
    oem: 'Mahindra',
    model: 'Zeo V2-DV',
    typeId: '4 Wheeler'
  },
  {
    label: 'Bounce E1x',
    id: 'Bounce E1x',
    oem: 'Bounce',
    model: 'E1x',
    typeId: '2 Wheeler'
  }
]

/* Vehicle Type */
export const VehicleTypes = [
  {
    label: '2 Wheeler',
    id: '2 Wheeler',
    value: '2 Wheeler'
  },
  {
    label: '3 Wheeler',
    id: '3 Wheeler',
    value: '3 Wheeler'
  },
  {
    label: '4 Wheeler',
    id: '4 Wheeler',
    value: '4 Wheeler'
  }
]

/* RTO State Code */
export const RtoStateCodeList = [
  { id: 'AR', label: 'AR' },
  { id: 'AS', label: 'AS' },
  { id: 'BR', label: 'BR' },
  { id: 'CG', label: 'CG' },
  { id: 'DL', label: 'DL' },
  { id: 'GA', label: 'GA' },
  { id: 'GJ', label: 'GJ' },
  { id: 'HR', label: 'HR' },
  { id: 'HP', label: 'HP' },
  { id: 'JK', label: 'JK' },
  { id: 'JH', label: 'JH' },
  { id: 'KA', label: 'KA' },
  { id: 'KL', label: 'KL' },
  { id: 'LD', label: 'LD' },
  { id: 'MP', label: 'MP' },
  { id: 'MH', label: 'MH' },
  { id: 'MN', label: 'MN' },
  { id: 'ML', label: 'ML' },
  { id: 'MZ', label: 'MZ' },
  { id: 'NL', label: 'NL' },
  { id: 'OD', label: 'OD' },
  { id: 'PY', label: 'PY' },
  { id: 'PB', label: 'PB' },
  { id: 'RJ', label: 'RJ' },
  { id: 'SK', label: 'SK' },
  { id: 'TN', label: 'TN' },
  { id: 'TG', label: 'TG' },
  { id: 'TS', label: 'TS' },
  { id: 'TR', label: 'TR' },
  { id: 'UP', label: 'UP' },
  { id: 'UK', label: 'UK' },
  { id: 'WB', label: 'WB' },
  { id: 'AN', label: 'AN' },
  { id: 'CH', label: 'CH' },
  { id: 'DN', label: 'DN' },
  { id: 'DD', label: 'DD' },
  { id: 'LA', label: 'LA' }
]

/* Vehicle onboarding template */

export const VehicleOnboardingTemplateColumns = [
  { header: 'Sl No', key: 'sNo', width: 6 },
  { header: 'Vehicle Registration No.', key: 'registration_number', width: 26 },
  { header: 'Chassis Number', key: 'chassis_number', width: 22 },
  { header: 'Motor Number', key: 'motor_number', width: 22 },
  { header: 'Vehicle Type', key: 'type', width: 20 },
  { header: 'OEM & Model', key: 'oemAndModel', width: 25 },
  { header: 'Location', key: 'location', width: 20 },
  { header: 'Purchase Date', key: 'purchaseDate', width: 20 },
  { header: 'Remarks', key: 'remarks', width: 20 }
]

//otp timeduration
export const OtpSettings = {
  resendOtpDelayInSeconds: 30,
  otpValidTimeInSeconds: 300,
}

//RA decline reasons
export const declineReason = [
  {
    id: 1,
    label: 'The user doesn�t require telematics access',
  },
  {
    id: 2,
    label: 'The user is outside the organization',
  },
  {
    id: 3,
    label: 'The roles and location credentials are wrong',
  },
  {
    id: 4,
    label: 'Other',
  },
]

//vheicleonboardsheet
export const VehicleOnboardingTemplateSheetName = 'Invalid Vehicle Onboarding Data'

//password acceptance criteria
export const PasswordCriteriaMapper = {
  MIN_LENGTH: "length > 8",
  MAX_LENGTH: "length < 31",
  DIGIT: "must contain digit",
  LOWERCASE: "must contain at least 1 lowercase",
  UPPERCASE: "must contain at least 1 uppercase",
  SPECIAL_CHARACTER: "must have at least 1 special character",
}

//mastertypes
export const MasterTypes = {
  LOCATION:"location",
  ROLE:"role",
  MENU:"menu",
  GEO_FENCE_LOCATIONS:"geofence_locations",
  API_SOURCE:"api-source",
}

//vehicle tarcking controllers
export const VehicleTrackingController = {
  PLAY: 'play',
  PAUSE: 'pause',
  STOP: 'stop',
  BACKWARD: 'backward',
  FORWARD: 'forward'
}

//vehicle status and color codes
export const VehicleStatusAndMetaData = [
  { name: "Live", color: "#009B88" },
  // Note: Merge Idle status to Live status
  // { name: "Idle", color: "#E06000" },
  { name: "Parked", color: "#632008" },
  { name: "Immobile", color: "#632008" },
  { name: "Charging", color: "#22046B" }]

  export const VehicleStatusAndMetaDataDark = [
    { name: "Live", color: "#009B88" },
    // Note: Merge Idle status to Live status
    // { name: "Idle", color: "#F29900" },
    { name: "Parked", color: "#E06000" },
    { name: "Immobile", color: "#5491F5" },
    { name: "Charging", color: "#5491F5" }]


//dashboard alert types
export const NewDashboard_AlertTypes = [
  {
    label: 'Overspeed',
    key: 'OverSpeed',
  },
  {
    label: 'Harsh Acceleration',
    key: 'Harsh Acceleration',
  },
  {
    label: 'Harsh Braking',
    key: 'Harsh Braking',
  },
  {
    label: 'Deep Discharge',
    key: 'Deep Discharge Warning',
  },
  {
    label: 'Battery Under Voltage',
    key: 'Battery Under Voltage',
  },
  {
    label: 'Low SoC',
    key: 'Low SoC',
  },
  {
    label: 'Battery Over Heat',
    key: 'Battery Overheat',
  },
]
export const NewDarkDashboard_AlertTypes = [
  {
    label: 'Overspeed',
    key: 'OverSpeed',
  },
  {
    label: 'Harsh Acceleration',
    key: 'Harsh Acceleration',
  },
  {
    label: 'Harsh Braking',
    key: 'Harsh Braking',
  },
  {
    label: 'Harsh Cornering',
    key: 'Harsh Cornering',
  }
]
export const NewDashboard_Battery_status=[
  {
    label: 'Deep Discharge',
    key: 'Deep Discharge Warning',
  },
  {
    label: 'Battery Under Voltage',
    key: 'Battery Under Voltage',
  },
  {
    label: 'Low SoC',
    key: 'Low SoC',
  },
  {
    label: 'Battery Over Heat',
    key: 'Battery Overheat',
  },
]

export const immobilizevehicleStatusOptions = [
  { value: 'Live', label: 'Live' },
  { value: 'Non Com', label: 'Non Com' },
  { value: 'Parked', label: 'Parked' },
  { value: 'Charging', label: 'Charging' },
];
export const immobilizeHostoryStatusOptions = [
  { value: 'Mobilize', label: 'Mobilize' },
  { value: 'Immobilize', label: 'Immobilize' },
];

export const  immobilizeNonComIssue = [
  { value: 'Poor Network', label: 'No Network' },
  { value: 'Plugged Out', label: 'Plugged Out' },
  { value: 'Inactive GPS', label: 'Inactive GPS' },

];


export const immobilizeReasonData = [
 { value: "Non Payment",label: "Non Payment"},
 { value: "Different Rider",label: "Different Rider"},
 { value: "Away from Geo Location",label: "Away from Geo Location"},
 { value: "Bike used personally",label: "Bike used personally"},
 { value: "OTHER",label: "Others"},
];

export const mobilizeReasonData = [
  { value: "Payment Received",label: "Payment Received"},
  { value: "Vehicle in Geo Location",label: "Vehicle in Geo Location"},
  { value: "Vehicle fit for Delivery",label: "Vehicle fit for Delivery"},
  { value: "OTHER",label: "Others"},
 ];





//calculations operations
export const CalculationOperation = {
  GREATER_THEN: '>',
  LESS_THAN: '<',
  RANGE: 'range',
  LESS_THAN_EQUAL: '<=',
  GREATER_THAN_EQUAL: '<='
}

//alert parking config
export const ParkedAlertConfiguration = {
  name: 'Parked Vehicles',
  key: 'parked_vehicles',
  countsByHours: [
    {
      label: '< 6 Hrs',
      value: '0',
      backgroundColor: '#fe8a62',
      calculationOperation: CalculationOperation.LESS_THAN_EQUAL,
      checkValue: 6,
      rangeValue: {
        startValue: null,
        endValue: null
      }
    },
    {
      label: '6-12 Hrs',
      value: '0',
      backgroundColor: '#e46e44',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 6,
        endValue: 12
      }
    },
    {
      label: '12-18 Hrs',
      value: '0',
      backgroundColor: '#f05d29',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 12,
        endValue: 18
      }
    },
    {
      label: '18-24 Hrs',
      value: '0',
      backgroundColor: '#e93d00',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 18,
        endValue: 24
      }
    },
    {
      label: '24-36 Hrs',
      value: '0',
      backgroundColor: '#b73406',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 24,
        endValue: 36
      }
    },
    {
      label: '36-48 Hrs',
      value: '0',
      backgroundColor: '#8d2703',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 36,
        endValue: 48
      }
    },
    {
      label: '> 48 Hrs',
      value: '0',
      backgroundColor: '#632008',
      calculationOperation: CalculationOperation.GREATER_THEN,
      checkValue: 48,
      rangeValue: {
        startValue: null,
        endValue: null
      }
    }
  ]
}

//noncommunication alert config
export const NonComAlertConfiguration = {
  name: 'Non Communication Vehicles',
  key: 'non_communication_vehicles',
  countsByHours: [
    {
      label: '< 2 Hrs',
      value: '0',
      backgroundColor: '#feb1e6',
      calculationOperation: CalculationOperation.LESS_THAN_EQUAL,
      checkValue: 2,
      rangeValue: {
        startValue: null,
        endValue: null
      }
    },
    {
      label: '2-6 Hrs',
      value: '0',
      backgroundColor: '#ff97df',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 2,
        endValue: 6
      }
    },
    {
      label: '6-10 Hrs',
      value: '0',
      backgroundColor: '#ff72d3',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 6,
        endValue: 10
      }
    },
    {
      label: '10-14 Hrs',
      value: '0',
      backgroundColor: '#ff55ca',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 10,
        endValue: 14
      }
    },
    {
      label: '14-18 Hrs',
      value: '0',
      backgroundColor: '#fb2aba',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 14,
        endValue: 18
      }
    },
    {
      label: '18-22 Hrs',
      value: '0',
      backgroundColor: '#d726a0',
      calculationOperation: CalculationOperation.RANGE,
      checkValue: null,
      rangeValue: {
        startValue: 18,
        endValue: 22
      }
    },
    {
      label: '> 22 Hrs',
      value: '0',
      backgroundColor: '#b31f85',
      calculationOperation: CalculationOperation.GREATER_THEN,
      checkValue: 22,
      rangeValue: {
        startValue: null,
        endValue: null
      }
    }
  ]
}

//alarm alert types
export const Alarm_AlertTypes = {
  OverSpeed: {
    label: 'Overspeed',
    key: 'OverSpeed',
  },
  HarshAcceleration: {
    label: 'Harsh Acceleration',
    key: 'Harsh Acceleration',
  },
  HarshCornering: {
    label: 'Harsh Cornering',
    key: 'Harsh Cornering',
  },
  DeepDischargeWarning: {
    label: 'Deep Discharge',
    key: 'Deep Discharge Warning',
  },
  HarshBraking: {
    label: 'Harsh Braking',
    key: 'Harsh Braking',
  },
  BatteryUnderVoltage: {
    label: 'Battery Under Voltage',
    key: 'Battery Under Voltage',
  },
  LowSoC: {
    label: 'Low SoC',
    key: 'Low SoC',
  },
  BatteryOverheat: {
    label: 'Battery Over Heat',
    key: 'Battery Overheat',
  }
}

//alert driving piechart config
export const DrivingPIChartAlertConfiguration = [
  { type: Alarm_AlertTypes.OverSpeed, value: 1, count: 0, color: '#ff0000', sort: 1 },
  { type: Alarm_AlertTypes.HarshBraking, value: 1, count: 0, color: '#f15858', sort: 3 },
  { type: Alarm_AlertTypes.HarshAcceleration, value: 1, count: 0, color: '#fc8585', sort: 2 },
  { type: Alarm_AlertTypes.HarshCornering, value: 1, count: 0, color: '#FCD1D1', sort: 4 },
]

//alert battery piechart config
export const BatteryPIChartAlertConfiguration = [
  { type: Alarm_AlertTypes.LowSoC, value: 1, count: 0, color: '#F34848', sort: 1 },
  { type: Alarm_AlertTypes.DeepDischargeWarning, value: 1, count: 0, color: '#FD6868', sort: 4 },
  { type: Alarm_AlertTypes.BatteryUnderVoltage, value: 1, count: 0, color: '#FF8484', sort: 3 },
  { type: Alarm_AlertTypes.BatteryOverheat, value: 1, count: 0, color: '#FFA7A7', sort: 2 },
]

//OEM color codes
export const OEMChartColorShades = {
  "level-0": "#DE3200",
    "level-1": "#BC1B09",
    "level-2": "#A01708",
    "level-3": "#841307",
    "level-4": "#002E42",
    "level-5": "#004664",
    "level-6": "#00648F",
    "level-7": "#00A5EC",
    "level-8": "#720F44",
    "level-9": "#B6186C",
    "level-10": "#D81C80",
    "level-11": "#914100",
    "level-12": "#D46000",
    "level-13": "#FF7300",
}

//city color codes
export const CityChartColorShades = {
  "level-0": "#4D0070",
  "level-1": "#7A018C",
  "level-2": "#9A0680",
  "level-3": "#A9107A",
  "level-4": "#A12559",
  "level-5": "#B25069",
  "level-6": "#2DCB75",
  "level-7": "#52D726",
  "level-8": "#0F5E1A",
  "level-9": "#3E432D",
  "level-10": "#395B64",
  "level-11": "#219797",
  "level-12": "#3B2D85",
}

//charging color codes
export const ChargingTypeShades = {
  "level-0": "#018177",
  "level-1": "#65a69d",
  "level-2": "#9dc8c1"
}

export const ChartDataType = {
  OEM: "OEM-MODEL",
  city: "CITY",
  chargingType: "CHARGING-TYPE"
}

//notification status
export const notificationStatus = {
  queued: "Queued",
  inProgress: "In Progress",
  failed: "Failed",
  completed: "Completed"
}

export const SUPER_ADMIN = 'SA'

export const CUSTOMER_ADMIN = 'CA'

export const DEPARTMENT_ADMIN = 'DA'

export const DEPARTMENT_USER = 'DU'

export const SA_Menus = [
  {
    isDarkTheme: true,
    id: '652b7079472f1590f49856b7',
    path: '/sa/dashboard',
    name: 'Dashboard',
    isLink: true,
    icon: SA_DashboardIcon,
    darkIcon: SA_DashboardIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 1,
    content: () => (<SADashboard />),
    isPublic: false
  },
  {
    isDarkTheme: true,
    id: '652b7079472f1590f49856b7',
    path: '/sa/admin',
    name: 'Admin',
    isLink: true,
    icon: SA_AdminIcon,
    darkIcon: SA_AdminIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 2,
    content: () => (<SAAdmin />),
    isPublic: false
  },
  {
    isDarkTheme: true,
    id: '652b7079472f1590f49856b7',
    path: '/sa/logs',
    name: 'Log',
    isLink: true,
    icon: SA_Log,
    darkIcon: SA_Log,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 3,
    content: () => (<SALogs />),
    isPublic: false
  },
  
]
// export const DA_Menus = [
  // {
  //   isDarkTheme: true,
  //   id: '652b7079472f1590f49856b7',
  //   path: '/da/dashboard',
  //   name: 'Dashboard',
  //   isLink: true,
  //   icon: SA_DashboardIcon,
  //   darkIcon: SA_DashboardIcon,
  //   isHidden: false,
  //   isDarkHidden: false,
  //   sortOrder: 1,
  //   content: () => (<DADashboard />),
  //   isPublic: false
  // },
  // {
  //   isDarkTheme: true,
  //   id: '652b7079472f1590f49856b7',
  //   path: '/da/admin',
  //   name: 'Admin',
  //   isLink: true,
  //   icon: SA_AdminIcon,
  //   darkIcon: SA_AdminIcon,
  //   isHidden: false,
  //   isDarkHidden: false,
  //   sortOrder: 2,
  //   content: () => (<DAAdmin />),
  //   isPublic: false
  // },
  // {
  //   isDarkTheme: true,
  //   id: '652b7079472f1590f49856b7',
  //   path: '/da/logs',
  //   name: 'Log',
  //   isLink: true,
  //   icon: SA_Log,
  //   darkIcon: SA_Log,
  //   isHidden: false,
  //   isDarkHidden: false,
  //   sortOrder: 3,
  //   content: () => (<DALogs />),
  //   isPublic: false
  // },
  
// ]
export const CA_Menus = [
  {
    isDarkTheme: false,
    id: '652b708c472f1590f49856c0',
    path: '/ca/',
    name: 'Admin',
    isLink: false,
    icon: controlsMenuIcon,
    darkIcon: DarkControlsMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 1,
    content: () => (<CADashboard />),
    isPublic: true,
    submenu: [
      {
        path: "/ca/dashboard",
        name: "Dashboard",
        content: () => (<CAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/ca/manage",
        name: "Manage",
        content: () => (<CAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/ca/logs",
        name: "Log",
        content: () => (<CAAdmin />),
        isMenu: true,
        isPublic: false
      }
      

    ],
  }
]

export const DA_Menus = [
  {
    isDarkTheme: false,
    id: '652b708c472f1590f49856c0',
    path: '/da',
    name: 'Admin',
    isLink: false,
    icon: controlsMenuIcon,
    darkIcon: DarkControlsMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 1,
    content: () => (<DAAdmin />),
    isPublic: true,
    submenu: [
      {
        path: "/da/dashboard",
        name: "Dashboard",
        content: () => (<DAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/da/manage",
        name: "Manage",
        content: () => (<DAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/da/logs",
        name: "Log",
        content: () => (<DAAdmin />),
        isMenu: true,
        isPublic: false
      }
      

    ],
  }
]

//protected menus
export const ProtectedMenus = [
  {
    isDarkTheme: true,
    id: '664444f9f13c3a06c891601a',
    path: '/sa/dashboard',
    name: 'Dashboard',
    isLink: true,
    icon: SA_DashboardIcon,
    darkIcon: SA_DashboardIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 1,
    content: () => (<SADashboard />),
    isPublic: false
  },
  {
    isDarkTheme: true,
    id: '664444f9f13c3a06c891601b',
    path: '/sa/admin',
    name: 'Admin',
    isLink: true,
    icon: SA_AdminIcon,
    darkIcon: SA_AdminIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 2,
    content: () => (<SAAdmin />),
    isPublic: false
  },
  {
    isDarkTheme: true,
    id: '664444f9f13c3a06c891601c',
    path: '/sa/logs',
    name: 'Log',
    isLink: true,
    icon: SA_Log,
    darkIcon: SA_Log,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 3,
    content: () => (<SALogs />),
    isPublic: false
  },
  {
    isDarkTheme: false,
    id: '66288d14b781f077a2f133cf',
    path: '/da',
    name: 'Admin',
    isLink: false,
    icon: controlsMenuIcon,
    darkIcon: DarkControlsMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 1,
    content: () => (<DAAdmin />),
    isPublic: false,
    submenu: [
      {
        path: "/da/dashboard",
        name: "Dashboard",
        content: () => (<DAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/da/manage",
        name: "Manage",
        content: () => (<DAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/da/logs",
        name: "Log",
        content: () => (<DAAdmin />),
        isMenu: true,
        isPublic: false
      }
      

    ],
  },
  {
    isDarkTheme: false,
    id: '66288d14b781f077a2f133d0',
    path: '/ca/',
    name: 'Admin',
    isLink: false,
    icon: controlsMenuIcon,
    darkIcon: DarkControlsMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    sortOrder: 1,
    content: () => (<CADashboard />),
    isPublic: false,
    submenu: [
      {
        path: "/ca/dashboard",
        name: "Dashboard",
        content: () => (<CAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/ca/manage",
        name: "Manage",
        content: () => (<CAAdmin />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/ca/logs",
        name: "Log",
        content: () => (<CAAdmin />),
        isMenu: true,
        isPublic: false
      }
      

    ],
  },
  {
    isDarkTheme: true,
    id: '66288d14b781f077a2f133cd',
    path: '/',
    name: 'Home',
    isLink: true,
    icon: homeMenuIcon,
    darkIcon: DarkHomeMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    // submenu: [],
    sortOrder: 2,
    content: () => (<NewDashboard />),
    isPublic: false
  },
  // {
  //   isDarkTheme: false,
  //   id: '652b708c472f1590f49856c0',
  //   path: '/controls',
  //   name: 'Control',
  //   isLink: false,
  //   icon: controlsMenuIcon,
  //   darkIcon: DarkControlsMenuIcon,
  //   isHidden: false,
  //   isDarkHidden: false,
  //   submenu: [
  //     {
  //       path: "/controls/tracking",
  //       name: "Tracking",
  //       content: () => (<VehicleTracking />),
  //       isMenu: true,
  //       isPublic: false
  //     }
  //   ],
  //   sortOrder: 2,
  //   isPublic: false
  // },
  //NOTE:Commented out the changes as they were specified for the upcoming release, pending confirmation on whether to keep or revert them
  {
    isDarkTheme: false,
    id: '652b708c472f1590f49856c0',
    path: '/controls/tracking',
    name: 'Tracking',
    isLink: true,
    icon: controlsMenuIcon,
    darkIcon: DarkControlsMenuIcon,
    isHidden: false,
    isDarkHidden: false,
   content: () => (<VehicleTracking />),
    sortOrder: 2,
    isPublic: false
  },
  // {
  //   isDarkTheme: false,
  //   id: '652b70a2472f1590f49856db',
  //   path: '/records',
  //   name: 'Record',
  //   isLink: false,
  //   icon: recordsMenuIcon,
  //   darkIcon: DarkRecordsMenuIcon,
  //   isHidden: false,
  //   isDarkHidden: false,
  //   submenu: [
  //     {
  //       path: "/records/charging",
  //       name: "Charge",
  //       isMenu: true,
  //       isPublic: false,
  //       content: () => (<RecordsContainer/>),
  //     },
  //     {
  //       path: "/records/trip",
  //       name: "Trip",
  //       isMenu: true,
  //       isPublic: false,
  //       content: () => (<RecordsContainer/>),
  //     },
  //     {
  //       path: "/records/vehicle-status",
  //       name: "Vehicle",
  //       isMenu: true,
  //       isPublic: false,
  //       content: () => (<RecordsContainer/>),
  //     },
  //     // commenting the service for now as its needed in future
  //     // {
  //     //   path: "/records/service",
  //     //   name: "Service",
  //     //   isMenu: true,
  //     //   isPublic: false,
  //     //   content: () => (<RecordsContainer/>),
  //     // },
  //     {
  //       path: "/records/historic",
  //       name: "Historic",
  //       isMenu: true,
  //       isPublic: false,
  //       content: () => (<RecordsContainer/>),
  //     },
  //     {
  //       path: "/records/connectivity",
  //       name: "Connectivity",
  //       isMenu: true,
  //       isPublic: false,
  //       content: () => (<RecordsContainer/>),
  //     },
  //     {
  //       path: "/records/alert",
  //       name: "Alert",
  //       isMenu: true,
  //       isPublic: false,
  //       content: () => (<RecordsContainer/>),
  //     },
  //   ],
  //   sortOrder: 4,
  //   isPublic: false
  // },
  {
    isDarkTheme: false,
    id: '6628c52eb781f077a2f133d1',
    path: '/reports',
    name: 'Reports',
    isLink: false,
    icon: recordsMenuIcon,
    darkIcon: DarkRecordsMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    submenu: [
      {
        path: "/reports/charge",
        name: "Charge",
        isMenu: true,
        isPublic: false,
        content: () => (<ReportingContainer/>),
      },
      {
        path: "/reports/trip",
        name: "Trip",
        isMenu: true,
        isPublic: false,
        content: () => (<ReportingContainer/>),
      },
      {
        path: "/reports/status",
        name: "Status",
        isMenu: true,
        isPublic: false,
        content: () => (<ReportingContainer/>),
      },
      {
        path: "/reports/historic-data",
        name: "Source Data",
        isMenu: process.env.REACT_APP_ENV !== 'LIVE',
        isPublic: false,
        content: process.env.REACT_APP_ENV !== 'LIVE' ? () => (<ReportingContainer/>) : null,
      },
      {
        path: "/reports/historical",
        name: "Historical",
        isMenu: true,
        isPublic: false,
        content: () => (<ReportingContainer/>),
      },
    ],
    sortOrder: 4,
    isPublic: false
  },


  {
    isDarkTheme: false,
    id: '6628c52eb781f077a2f133d2',
    path: '/alert',
    name: 'Alert',
    isLink: false,
    icon: alertsIcon,
    darkIcon: DarkAlertsIcon,
    isHidden: false,
    isDarkHidden: false,
    submenu: [
      {
        path: "/alert/home",
        name: "Overview",
        isMenu: true,
        isPublic: false,
        content: () => (<AlertContainer/>),
      },
      {
        path: "/alert/geofence",
        name: "Geofence",
        isMenu: true,
        isPublic: false,
        content: () => (<AlertContainer/>),
      },
      {
        path: "/alert/vehicle",
        name: "Vehicle",
        isMenu: true,
        isPublic: false,
        content: () => (<AlertContainer/>),
      },
      {
        path: "/alert/connectivity",
        name: "Connectivity",
        isMenu: true,
        isPublic: false,
        content: () => (<AlertContainer/>),
      },
      {
        path: "/alert/analytics",
        name: "Analytics",
        isMenu: true,
        isPublic: false,
        content: () => (<AlertContainer/>),
      },
    ],
    sortOrder: 4,
    isPublic: false
  },



  // {
  //   isDarkTheme: false,
  //   id: '652b70a2472f1590f49856w2',
  //   // need to check with this id
  //   path: '/alerts',
  //   name: 'Alerts',
  //   isLink: false,
  //   icon: alertsIcon,
  //   darkIcon: DarkAlertsIcon,
  //   isHidden: false,
  //   isDarkHidden: false,
  //   submenu: [
  //     // {
  //     //   path: "/alerts/service",
  //     //   name: "Service",
  //     //   content: () => (<AlertsRevamp />),
  //     //   isMenu: true,
  //     //   isPublic: false
  //     // },
  //     {
  //       path: "/alerts/geofence",        
  //       name: "Geofence",
  //       content: () => (<AlertsRevamp />),
  //       isMenu: true,
  //       isPublic: false
  //     },
  //     {
  //       path: "/alerts/status",        
  //       name: "Status",
  //       content: () => (<AlertsRevamp />),
  //       isMenu: true,
  //       isPublic: false
  //     },
  //   ],
  //   sortOrder: 5,
  //   isPublic: false
  // },
  {
    isDarkTheme: false,
    id: '652b70a2472f1590f49856f2',
    path: '/fleet',
    name: 'Fleet',
    isLink: false,
    icon: fleetMenuIcon,
    darkIcon: DarkFleetMenuIcon,
    isHidden: false,
    isDarkHidden: false,
    submenu: [
      {
        path: "/fleet/summary",
        name: "Summary",
        content: () => (<Fleet />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/fleet/vehicles",
        name: "Onboarding",
        content: () => (<VehicleOnboarding />),
        isMenu: true,
        isPublic: false
      }
    ],
    sortOrder: 7,
    isPublic: false
  },
  {
    isDarkTheme: false,
    id: '652b70a4bde922959168f19d',
    path: '/geo-fence',
    name: 'Geofence',
    isLink: false,
    icon: geoFenceIcon,
    darkIcon: DarkGeoFenceIcon,
    isHidden: false,
    isDarkHidden: false,
    submenu: [
      {
        path: "/geo-fence/dashboard",
        name: "Dashboard",
        content: () => (<GeofanceDashboard />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/geo-fence/configure",
        name: "Configure",
        content: () => (<GeoFenceConfigurationScreen />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/geo-fence/create",
        name: "Create",
        content: () => (<CreateFence />),
        isMenu: true,
        isPublic: false
      },
      {
        path: "/geo-fence/edit/:id",
        name: "Edit",
        content: () => (<CreateFence />),
        isMenu: false,
        isPublic: false
      },
    ],
    sortOrder: 8,
    isPublic: false
  },

  {
    id: '66288d14b781f077a2f133ce',
    path: '/groups',
    isDarkTheme: false,
    name: 'Group',
    isLink: true,
    icon: groupsIcon,
    darkIcon: DarkGroupsIcon,
    isHidden: false,
    isDarkHidden: false,
    content: () => (<Groups/>),
    sortOrder: 9,
    isPublic: false,
  },


  {
    isDarkTheme: true,
    id: '66288d14b781f077a2f133ce',
    path: '/groups/:groupId',
    name: 'Group Details',
    isLink: true,
    icon: groupsIcon,
    darkIcon: DarkGroupsIcon,
    isHidden: true,
    isDarkHidden: true,
    content: () => (<Groups />),
    sortOrder: 9,
    isPublic: false,
  },
  {
    isDarkTheme: true,
    id: '66288d14b781f077a2f133ce',
    path: '/groups/:groupId/:registrationNumber',
    name: 'Group Vehicle Details',
    isLink: true,
    icon: groupsIcon,
    darkIcon: DarkGroupsIcon,
    isHidden: true,
    isDarkHidden: true,
    content: () => (<Groups />),
    sortOrder: 9,
    isPublic: false,
  },


  {
    id: '66288d14b781f077a2f133ab',
    path: '/immobilize',
    isDarkTheme: false,
    name: 'Immobilize',
    isLink: true,
    icon: immobilizeIconLight,
    darkIcon: immobilizeIconDark,
    isHidden: false,
    isDarkHidden: false,
    content: () => (<Immobilize/>),
    sortOrder: 9,
    isPublic: false,
  },
  {
    isDarkTheme: false,
    id: '652b70a4bde922959168f1a2',
    path: '/ra-user',
    name: 'RA Users',
    isLink: true,
    icon: controlsMenuIcon,
    darkIcon: DarkControlsMenuIcon,
    isHidden: false,
    isDarkHidden: true,
    submenu: [
      {
        path: "/ra-user",
        name: "RA Users",
        content: () => (<RaUserManagement />),
        isMenu: false,
        isPublic: false
      },
      {
        path: "/ra-user/details",
        name: "RA User Details",
        content: () => (<EditUserModal />),
        isMenu: false,
        isPublic: false
      },
      {
        path: "/ra-user/add-user",
        name: "RA Add User",
        content: () => (<NewSingleUserModal />),
        isMenu: false,
        isPublic: false
      }
    ],
    sortOrder: 10,
    isPublic: false,
    isAdminOnlyMenu: true
  },
  {
    id: '652b70a4bde922959168f1a7',
    path: '/customization',
    name: 'Customization',
    isLink: false,
    icon: customizationMenuIcon,
    darkIcon: customizationMenuIcon,
    isHidden: false,
    isDarkHidden: true,
    submenu: [
      {
        path: "/role-menu-mapping",
        name: "Role And Menu",
        content: () => (<UserRoleAndMenuMapping />),
        isMenu: true,
        isPublic: false
      }
    ],
    sortOrder: 11,
    isPublic: false,
    isAdminOnlyMenu: true
  },
  {
    isDarkTheme: true,
    id: '652b70a4bde922959168f1aa',
    path: "/userProfile",
    name: "User Profile",
    isLink: false,
    icon: <span>Hello</span>,
    darkIcon: <span>Hello</span>,
    isHidden: false,
    isDarkHidden: true,
    submenu: [
      {
        path: "/userProfile/profile",
        name: "Profile",
        content: () => (<UserProfile />),
        isMenu: true,
        isPublic: true
      },
      {
        path: "/userProfile/activityLog",
        name: "Activity Log",
        content: () => (<UserActivity />),
        isMenu: true,
        isPublic: true
      },
      {
        name: "Logout",
        isMenu: true,
        content: null,
        isPublic: false
      }
    ],
    sortOrder: 12,
    isPublic: true
  },
  
 

]

export const publicRoutes = [

  {
    id: 'LOGIN',
    path: 'login',
    content: () => (<Login /> ),
    isDarkTheme: true
  },
  {
    id: 'SIGNUP',
    path: 'signup',
    content: () => (<Signup /> ),
    isDarkTheme: false
  },
  {
    id: 'FORGOTPASSWORD',
    path: 'forgot-password',
    content: () => (<Forgotpassword /> ),
    isDarkTheme: false
  },
  {
    id: 'RA_ACTIONS',
    path: 'ra-action/:token',
    content: () => (<RaActions /> ),
    isDarkTheme: false
  },
  {
    id: 'TERMS',
    path: '/terms',
    content: () => (<TermsandConditions /> ),
    isDarkTheme: false
  },
  {
    id: 'UNAUTHORIZED',
    path: 'unauthorized',
    content: () => (<Unauthorized /> ),
    isDarkTheme: false
  },
]

export const NotificationMessage = {
  Request: {
    Primary: 'Export request sent successfully.',
    Secondary: 'You will get update in notification.'
  },
  ExportIsInProgress: {
    Primary: 'Previous request for this report is in progress.',
    Secondary: 'Please wait until previous export is completed.'
  }
}

export const Report_Filter_Type = {
  OEM_MODEL_CITY: 'OEM/City',
  VEHICLE: 'Vehicle'
}

export const VehicleStatusFilter = [
  {
    label: 'Subscribed',
    id: 'Subscribed',
    value: 'Subscribed'
  },
  {
    label: 'Unsubscribed',
    id: 'Unsubscribed',
    value: 'Unsubscribed'
  }
]

export const CHART_COLOR_CODES = {
  'Mahindra Grand Zor': '#DE3200',
  'Mahindra Treo Zor': '#BC1B09',
  'Euler HiLoad': '#A01708',
  'Piaggio Ape Extra': ' #841307',
  'Switch IEV4': '#002E42',
  'Altigreen neEV': '#004664',
  'Piaggio Sunmobility': '#00648F',
  'Altigreen Exponent': '#00A5EC',
  'Tata Nexon': '#720F44',
  'MG EV ZS': '#B6186C',
  'Bajaj Maxima': '#D81C80',
  'EKA -K1.5': '#914100',
  'Tata Ace EV': '#D46000',
  'Tata Tigor': '#FF7300',
  'Bounce E1x': '#FF6EC7',
  'Mahindra Zeo V1-DV': '#7C8BF2',
  'Mahindra Zeo V2-DV': '#EDE0AB',

  'Mumbai': '#4D0070',
  'Bengaluru': '#7A018C',
  'Delhi NCR': '#9A0680',
  'Hyderabad': '#A9107A',
  'Pune': '#A12559',
  'Ahmedabad': '#B25069',
  'Surat': '#2DCB75',
  'Mysore': '#52D726',
  'Rajkot': '#0F5E1A',
  'Jaipur': '#3E432D',
  'Kolkata': '#395B64',
  'Chennai': '#219797',
  'Lucknow': '#3B2D85',

  'Normal': '#205CAA',
  'Fast': '#007070'
}

export const ROLE_ID_MAP = {
  DU: '663dfb46fcba1fc763f0cbe6',
  DA: '6603aabbdfe44ae9a2635b38',
  CA: '65fc156499e3f624bf467ebd',
  SA: '65fa6e77c2e3f21b5346b24e',
}

export const nonCommStatusMapping = {
  poorNetwork: 'Poor Network',
  pluggedOut: 'Plugged Out',
  deviceFault: 'Device Fault',
  zeroData: 'Zero Data',
  inactiveGps: 'Inactive GPS'
};

export const nonCommStatusLabelMapping = {
  'Poor Network': 'No Network',
  'Plugged Out': 'Plugged Out',
  'Device Fault': 'Device Fault',
  'Zero Data': 'Zero Data',
  'Inactive GPS': 'GPS Not Connected'
}

export const ChargingTypes = [ { id: 'Fixed Charging', label: 'Fixed Charging' }, {id: 'Battery Swapping', label: 'Battery Swapping'} ]
