import React, { useState } from 'react';
import styles from './Modal.module.css'
import Button from '../../../../shared/form/Button/Button';

const Modal = ({ isVisible, closeModal , name,handleUpdateStatus,status}) => {

    const [hoverCloseIcon, setHoverCloseIcon] = useState(false);

  return isVisible && (
    <div className={`${styles.activeModalContainer}`}>

      <div className={`${styles.activeModal}`}>
      <img className={`${styles.activeCrossIcon}`}
      onClick={closeModal}
      src={hoverCloseIcon ? '/images/svgicon/trackingScreen/close_active.svg' : "/images/svgicon/trackingScreen/Close_fad.svg"}
      alt='close-btn'
      onMouseOver={() => setHoverCloseIcon(true)}
      onMouseOut={() => setHoverCloseIcon(false)}
    />
        <img  src='/images/illustration.svg' alt='illustration' width={120} height={120}/>
        <span className={`${styles.activeModalText}`}>Are  you sure ?</span>
        <span className={`${styles.activeModalTextConfirm}`}>You want to {(status === 'Active' ? 'deactivate' : 'activate')} {name}</span>

        <div className={`${styles.buttonContainer}`}>

          <Button variant="PINK_OUTLINED_MODAL" onClick={closeModal} >No</Button>
          <Button variant="PINK_MODAL" onClick={handleUpdateStatus}>Yes</Button>
          
        </div>
      </div>
    </div>
  );
};

export default Modal;
/*conflict-resolution-will be removed later*/