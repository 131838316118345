import { FileUploader } from 'react-drag-drop-files'
import './FileDrawer.css'

const fileTypes = ['XLSX']

const FileDrawer = ({ setFile, ...props }) => {
  const baseTestId = props['data-testid'] || 'file-drawer'
  const handleChange = (file) => {
    setFile(file)
  }

  const onFileDropCss = {
    display: 'none',
  }

  return (
    <>
      <FileUploader
        data-testid={baseTestId}
        multiple={false}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        dropMessageStyle={onFileDropCss}
        children={
          <div className="file-drawer">
            <img
              src="/images/svgicon/file.svg"
              alt="file"
              height={50}
              width={50}
            ></img>
            <div className="d-flex flex-row align-items-center">
              Drag & Drop or <div className="browse-div">Browse</div> your files
            </div>
          </div>
        }
      />
    </>
  )
}

export default FileDrawer
