import ActionTypes from "../../actionTypes"

const actions = ActionTypes.GEOFENCE_ANALYTICS_ACTIONS
const initialState = {
    cities:[],
    labels:[],
    labelOwners:[],
    fenceOwners:[],
    selectedCities:[],
    selectedLabels:[],
    selectedLabelOwners:[],
    selectedFenceOwners:[],
    startDate: null,
    endDate: null,
    ingressSelected: true,
    egressSelected:true,
    dataIntervals: [
        {label: '30 mins', value:'30'},
        {label: '1 hr', value:'60'},
        {label: '6 hrs', value:'360'},
        {label: '1 day', value:'1440'}
    ],
    selectedDataInterval:[{label: '30 mins', value:'30'}],
    operationsTrendData: [],
    reportsData:[],
    reportsDataCount:0,
    reportPageSize:8,
    reportCurrentPage:1
}

const analyticsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case actions.LOAD_CITIES:
            return {
              ...state,
              cities: payload,
            }
        case actions.LOAD_LABELS:
            return {
                ...state,
                labels: payload,
            }
        case actions.LOAD_LABEL_OWNERS:
            return {
                ...state,
                labelOwners: payload,
            }
        case actions.LOAD_FENCE_OWNERS:
            return {
                ...state,
                fenceOwners: payload,
            }
            
        case actions.SET_SELECTED_CITIES:
            return {
                ...state,
                selectedCities: payload
            }
        case actions.SET_SELECTED_LABELS:
            return {
                ...state,
                selectedLabels: payload
            }
        case actions.SET_SELECTED_LABEL_OWNERS:
            return {
                ...state,
                selectedLabelOwners: payload
            }
        case actions.SET_SELECTED_FENCE_OWNERS:
            return {
                ...state,
                selectedFenceOwners: payload
            }
        case actions.SET_START_DATE:
            return {
                ...state,
                startDate: payload
            }
        case actions.SET_END_DATE:
            return {
                ...state,
                endDate: payload
            }
        case actions.SET_INGRESS_SELECTED:
            return {
                ...state,
                ingressSelected: payload
            }
        case actions.SET_EGRESS_SELECTED:
            return {
                ...state,
                egressSelected: payload
            }
        case actions.SET_SELECTED_DATA_INTERVAL:
            return {
                ...state,
                selectedDataInterval: payload
            }
        case actions.SET_OPERATIONS_TREND_DATA:
            return {
                ...state,
                operationsTrendData: payload
            }
        case actions.SET_REPORTS_DATA:
            return {
                ...state,
                reportsData:payload.rows,
                reportsDataCount:payload.count
            }
        case actions.SET_REPORTS_CURRENT_PAGE:
            return {
                ...state,
                reportCurrentPage:payload
            }
        default:
            return{
                ...state
            }
    }
}

export default analyticsReducer;