import { Row, Col} from 'react-bootstrap'
import './chartViewFilters.css'
import { useSelector, useDispatch } from 'react-redux'
import SingleSelect from '../../../helpers/hoc/single-select/SingleSelect'
import { setIngressSelected, setEgressSelected, setSelectedDataInterval } from '../../../../store/actions/geo-fence/analytics/analyticsActions'
import { useEffect } from 'react'

//analytics chartview fields
const ChartViewFilters = ({onFiltersChanged, ...props})=> {
    const baseTestId = props['data-testid'] || 'chartfilters'
    const dispatch = useDispatch()
    const ingressSelected = useSelector((s)=> s?.analytics.ingressSelected)
    const egressSelected = useSelector((s)=>s?.analytics.egressSelected)
    const dataIntervals = useSelector((s)=>s?.analytics.dataIntervals)
    const selectedDataInterval = useSelector((s)=> s?.analytics.selectedDataInterval)
    const operationsTrendData = useSelector((s)=> s?.analytics.operationsTrendData)

    useEffect(()=> {
        onFiltersChanged()
    },[ingressSelected, egressSelected,selectedDataInterval]) // eslint-disable-line

    const selectHandler = (selectedData)=>  {
        dispatch(setSelectedDataInterval([selectedData]))
    }

    return (
        <Row className="h-100">
            <Col className="d-flex flex-column h-100 w-100 justify-content-center align-items-center">
                <Row>
                    <Col className='w-100'>
                        <div
                        className={'operation-type-button ' + (ingressSelected && operationsTrendData?.length ?'selected':'')}
                        onClick={() => {
                            dispatch(setIngressSelected(!ingressSelected))
                        }}
                        data-testid={`${baseTestId}-ingress-button`}
                    >
                        Ingress
                    </div>
                    </Col>
                </Row>
                <br></br>

                <Row>
                    <Col className='w-100'>
                        <div
                        className={'operation-type-button ' + (egressSelected && operationsTrendData?.length ?'selected':'')}
                        onClick={() => {
                            dispatch(setEgressSelected(!egressSelected))
                        }}
                        data-testid={`${baseTestId}-egress-button`}
                    >
                        Egress
                    </div>
                    </Col>
                </Row>

                <br></br>
                <Row>
                    <Col className='w-100'>
                    <SingleSelect
                        placeholder="Interval"
                        placeholderOnError=""
                        valueContainerWidthInPx="125px"
                        valueContainerMaxWidthInPx="150px"
                        optionPosition="ps-2 justify-content-start"
                        options={dataIntervals}
                        selectedOptions={selectedDataInterval}
                        onSelectHandler={selectHandler}
                        maxMenuHeight={140}
                        customBackgroundColor="#F5F7F9"
                        data-testid={`${baseTestId}-interval-singleselect`}
                        />    
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ChartViewFilters