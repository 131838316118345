import React from 'react';
import PropTypes from 'prop-types';
import BasicInput from '../../../shared/form/Input/Input';
import Textarea from '../../../shared/form/Textarea/Textarea';
import adminStyles from './SAAdmin.module.css'; 
import validationService from '../../../../service/validationService';
import Button from '../../../shared/form/Button/Button';

const CustomerForm = ({ formData, setFormData, formError, setFormError, onSubmit }) => {
  const handleOnChange = ({ target: { name, value } }) => {
    let errorMessage = '';
    
    if (name === 'email') {
      const emailValidation = validationService.email(value);
      errorMessage = emailValidation.message;
    } else if (name === 'customerName' || name === 'adminName') {
      const nameValidation = /^[a-zA-Z\s]*$/;
      if (!nameValidation.test(value)) {
        errorMessage = 'Name can only be alphabets';
      }
    }

    setFormData(prevState => ({ ...prevState, [name]: value }));
    setFormError(prevState => ({ ...prevState, [name]: errorMessage }));
  };

  return (
    <form onSubmit={onSubmit}>
      <div className='d-flex'>
        <div className={`${adminStyles.formContainer} form-group`}>
          <label htmlFor="customerName" className="ra-edit-label text-white">
            Customer Name<span style={{ color: '#C417E0' }}>*</span>
          </label>
          <BasicInput
            name="customerName"
            value={formData.customerName}
            onChange={handleOnChange}
            error={formError.customerName}
            type='text'
            placeholder='Enter Customer Name'
          />
        </div>
        <div className='form-group'>
          <label htmlFor="adminName" className="ra-edit-label text-white">
            Admin Name<span style={{ color: '#C417E0' }}>*</span>
          </label>
          <BasicInput
            name="adminName"
            value={formData.adminName}
            onChange={handleOnChange}
            error={formError.adminName}
            type='text'
            placeholder='Enter Admin Name'
          />
        </div>
      </div>
      <div className='d-flex'>
        <div className={`${adminStyles.formContainer} form-group`}>
          <label htmlFor="email" className="ra-edit-label text-white">
            Admin Email Id<span style={{ color: '#C417E0' }}>*</span>
          </label>
          <BasicInput
            name="email"
            value={formData.email}
            onChange={handleOnChange}
            error={formError.email}
            type='email'
            placeholder='Enter Email ID'
          />
        </div>
        <div className='form-group'>
          <label htmlFor="contactNumber" className="ra-edit-label text-white">
            Contact Number<span style={{ color: '#C417E0' }}>*</span>
          </label>
          <BasicInput
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleOnChange}
            error={formError.contactNumber}
            maxlength={10}
            type='text'
            placeholder='Enter Contact Number'
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
          />
        </div>
      </div>
      <div className='d-flex'>
        <div className="form-group">
          <br />
          <label htmlFor="address" className="ra-edit-label text-white">
            Registered Address<span style={{ color: '#C417E0' }}>*</span>
          </label>
          <Textarea
            name="address"
            value={formData.address}
            onChange={handleOnChange}
            error={formError.address}
            placeholder='Enter Registered Address'
          />
        </div>
      </div>
      <div className="passform-label-group text-center mt-4">
        <Button variant="PINK" onClick={onSubmit}>Submit</Button>
      </div>
    </form>
  );
};

CustomerForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  formError: PropTypes.object.isRequired,
  setFormError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomerForm;
