import React from 'react';

const AuthSidebar = ({width='50%'}) => {


	return (
		<div
				className='col-6'
				style={{
					display: 'flex',
					alignContent: 'center',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					flexDirection: 'column',
					background: '#f5f7f9',
					height: '100vh',
					width:width,
				}}
			>
				<img alt='login-logo' src='/images/logo/NmLogo.svg' className='login-logo-image' />

				<img alt='loginimage' src='/images/logo/NewLoginImage.svg' className='login-background-image' />
				<img
  src="images/logo/poweredbymagentaa.svg"
  alt="Magenta Logo"
  style={{ width: "30%" }}
/>


			</div>
	);
};

export default AuthSidebar  ;
