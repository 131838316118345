import ActionTypes from "../../actionTypes";

// use this dataset to add a new group
const newGroupDataset = {
    _id: null,
    name: "",
    vehicles: [],
    assignees:[],
    parentGroupId: null,
    subGroup: [],
};

// use this dataset while adding a new subgroup
const newSubgroupDataset = {
    _id: null,
    name: "",
    vehicles: [],
    subGroup: [],
};

const initialState = {
    groupData: {},
    editSubGroupId: null,
    editSubGroupIndex: null,
    activeVehicleList: [],
};

const dashboardGroupsReducer = (state = initialState, action) => {
    
    switch (action.type) {

        case ActionTypes.DASHBOARD_GROUPS.SET_EDIT_SUBGROUP_DATA: {
            return {
                ...state,
                editSubGroupId: action.payload.subGroupId,
                editSubGroupIndex: action.payload.subGroupIndex,
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.SET_EDIT_GROUP_DATA: {
            return {
                ...state,
                editSubGroupId: action.payload.subGroupId,
                editSubGroupIndex: action.payload.subGroupIndex,
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.RESET_EDIT_SUBGROUP_DATA: {
            return {
                ...state,
                editSubGroupId: initialState.editSubGroupId,
                editSubGroupIndex: initialState.editSubGroupIndex,
            }
        }
        
        case ActionTypes.DASHBOARD_GROUPS.RESET_EDIT_GROUP_DATA: {
            return {
                ...state,
                editSubGroupId: initialState.editSubGroupId,
                editSubGroupIndex: initialState.editSubGroupIndex,
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.SET_GROUP: {
            return {
                ...state,
                groupData: Object.assign({}, action.payload),
            }
        }
        case ActionTypes.DASHBOARD_GROUPS.RESET_GROUP: {
            return {
                ...state,
                groupData: Object.assign({}, initialState.groupData),
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.CREATE_NEW_GROUP: {
            const groupData = Object.assign({}, {
                ...newGroupDataset,
                name: action.payload.name,
                vehicles: action.payload.vehicleIds,
                assignees:action.payload.assignees
            })
            
            return {
                ...state,
                groupData: Object.assign({}, groupData),
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.CREATE_NEW_SUBGROUP: {
            const subgroupData = Object.assign({}, {
                ...newSubgroupDataset,
                name: action.payload.name,
                vehicles: action.payload.vehicleIds,
            })

            return {
                ...state,
                groupData: {
                    ...state.groupData,
                    subGroup: [...state.groupData.subGroup, subgroupData],
                },
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.REMOVE_VEHICLES_FROM_GROUP: {
            let { vehicleIds, isSubGroup, subgroupId, subgroupIndex } = action.payload;
            const groupData = Object.assign({}, state.groupData);

            if (isSubGroup) {
                if ((subgroupIndex === null || subgroupIndex === undefined) && subgroupId) {
                    subgroupIndex = groupData.subGroup.findIndex(d => d._id === subgroupId);
                }
                if (subgroupIndex !== -1) {
                    groupData.subGroup[subgroupIndex].vehicles = groupData.subGroup[subgroupIndex].vehicles.filter(v => !vehicleIds.includes(v));
                }
            } else {
                groupData.vehicles = groupData.vehicles.filter(v => !vehicleIds.includes(v));
            }

            return {
                ...state,
                groupData: {
                    ...groupData,
                }
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.ADD_VEHICLES_TO_GROUP: {
            let { vehicleIds, isSubGroup, subgroupId, subgroupIndex } = action.payload;
            const groupData = Object.assign({}, state.groupData);

            if (isSubGroup) {
                if ((subgroupIndex === null || subgroupIndex === undefined) && subgroupId) {
                    subgroupIndex = groupData.subGroup.findIndex(d => d._id === subgroupId);
                }
                if (subgroupIndex !== -1) {
                    groupData.subGroup[subgroupIndex].vehicles = [...groupData.subGroup[subgroupIndex].vehicles, ...vehicleIds];
                }
            } else {
                groupData.vehicles = [...groupData.vehicles, ...vehicleIds];
            }

            return {
                ...state,
                groupData: {
                    ...groupData
                }
            }
        }


        case ActionTypes.DASHBOARD_GROUPS.ADD_ADMIN_TO_GROUP: {
            let { assignees } = action.payload;
           
            let groupData = Object.assign({}, state.groupData);

   
            groupData.assignees = [
                ...(Array.isArray(groupData?.assignees) ? groupData.assignees : []),
                ...(Array.isArray(assignees) ? assignees : [])
              ];
              
          
            return {
                ...state,
                groupData: {
                    ...groupData
                }
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.SET_ACTIVE_VEHICLES: {
            return {
                ...state,
                activeVehicleList: new Array(...action.payload),    
            }
        }

        case ActionTypes.DASHBOARD_GROUPS.RESET_ACTIVE_VEHICLES: {
            return {
                ...state,
                activeVehicleList: new Array(initialState.activeVehicleList),
            }
        }

        default: {
            return state;
        }
    }
};

export default dashboardGroupsReducer;
