import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GetAllOnboardingVehicles, ExportVehiclesByOnboardingId } from "../../../../../store/actions/vehicleOnboarding";
import { Table } from "react-bootstrap";
import "./ViewVehicleOnboardingModal.css";
import { BsDownload } from "react-icons/bs";
import { getFormattedDate,addEscapeKeyListener } from "../../../../../utils/common-methods";
import Loader from "../../../../helpers/hoc/loader/Loader";
import { FaSearch } from 'react-icons/fa'

const ViewVehicleOnboardingModal = ({
  onboardingId,
  onboardedByUser,
  showModal,
  setIsShowModal,

  onBoardingVehiclesCount,
  onBoardingVehiclesData,
  isLoading,

  GetAllOnboardingVehicles,
  ExportVehiclesByOnboardingId
}) => {

  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, [setIsShowModal]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("")
  const ITEMS_PER_PAGE = 8;

  useEffect(() => {
    if (showModal && onboardingId) {
      GetAllOnboardingVehicles({
        onboardingId,
        searchKey: searchKey,
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetAllOnboardingVehicles, onboardingId, currentPage, searchKey]);

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      handleClose();
    });
  
    return () => {
      removeListener();
    };
  }, [handleClose]);



 
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="vehicle-onboard-modal-body mt-4 " style={{margin:'0 19px'}}>
          <div className="header-container d-flex justify-content-between">
            <div className="title w-100 mr-2">
            <div className="row" style={{height:'60px'}}>
                <div className="col-3 d-inline-flex align-items-center"><h5  style={{fontSize:'18px', fontWeight:'600'}}>Vehicle Onboarded</h5></div>
                <div className="col-6">
                <div
                 className={` d-inline-flex align-items-center justify-content-center gap-6 ${!searchKey? 'no-value-selected-input ' : ''}`}
                 >
                  <div className="search-bar-div search-input">
                    <FaSearch className="vob-search-icon" />
                    <input
                      onChange={(e) => {
                        setCurrentPage(1)
                        setSearchKey(e.target.value)
                      }}
                      className="vob-search-input search-input"
                      placeholder="Search"
                      data-testid='view-vehicle-search-input'
                    />
                  </div>
              </div>
                </div>
              </div>
              <div className="d-flex flex-row align-item-center justify-content-start">
              </div>
            </div>
            <div className="download-btn">
              <button
                className="dwn-btn"
                onClick={() => {
                  ExportVehiclesByOnboardingId({ onboardingId, onboardedByUser ,searchKey})
                }}
                data-testid='view-vehicle-export-button'
              >
                <BsDownload style={{width:'22px',height:'22px'}} />
              </button>
            </div>
          </div>

          <div className="bg-white mb-1 position-relative"
            style={{
            maxHeight: 'calc(80vh - 200px)',
            overflowY: 'auto'
            }}
          >
            
            <Table responsive={true} borderless className="mt-4" id="vehicle-onboarded-model">
              <thead className="table-header">
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>S.No</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Vehicle No</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>OEM & Model</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Chassis No</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Motor No</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Date of Purchase</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Vehicle Type</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Charging Type</p></th>
                <th className="ps-1 text-nowrap"><p className='fw500 vehicle-onboarded-th'>Location</p></th>
              </thead>
              <tbody>
                {onBoardingVehiclesData
                .filter((e) => {
                  const vehicleDetails =
                    `${e.registration_number} ${e.oem} ${e.model} ${e.chassis_number} ${e.motor_number}`.toLowerCase();
                  return vehicleDetails.includes(searchKey.toLowerCase());
                })
                .map((Obj, index) => (
                  <tr key={"row-" + index.toString()}>
                    <td>
                      {8 * (currentPage - 1) + index + 1}
                    </td>
                    <td>{Obj.registration_number}</td>
                    <td>{Obj.oem + " " + Obj.model}</td>
                    <td>{Obj.chassis_number}</td>
                    <td>{Obj.motor_number}</td>
                    <td>
                      {Obj.purchaseDate
                        ? getFormattedDate(Obj.purchaseDate, true)
                        : Obj.purchaseDate}
                    </td>
                    <td>{Obj.type}</td>
                    <td>{Obj.charging_type ?? 'NA'}</td>
                    <td>{Obj.location}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loader isLoading={isLoading} />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

ViewVehicleOnboardingModal.propTypes = {
  BulkUploadVehicles: PropTypes.func.isRequired,
  ExportVehiclesByOnboardingId: PropTypes.func.isRequired,
  isUploadApiSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  vehicleOnboardingApiError: state.vehOnboarding.vehicleOnboardingApiError,
  isUploadApiSuccess: state.vehOnboarding.isUploadApiSuccess,
  onBoardingVehiclesData: state.vehOnboarding.onBoardingVehiclesData,
  onBoardingVehiclesCount: state.vehOnboarding.onBoardingVehiclesCount,
  isLoading: state.loader.isLoading,
});

export default connect(mapStateToProps, {
  GetAllOnboardingVehicles,
  ExportVehiclesByOnboardingId,
})(ViewVehicleOnboardingModal);
