import React from "react";
import { Row, Col} from 'react-bootstrap'
import './alertConfiguration.css'
import MultiSelect from '../../../helpers/hoc/multi-select/MultiSelect'
import PropTypes from 'prop-types';
import { connect} from 'react-redux';
import { useEffect } from "react";
import {
    setEgressApplicable,
    setEgressTimeForFullDay,
    setEgressTimeCustomized,
    setEgressStartTimeHr,
    setEgressStartTimeMinute,
    setEgressEndTimeHr,
    setEgressEndTimeMinute,
    setEgressForAllVehicles,
    setEgressForCustomizedVehicles,
    setSelectedVehiclesForEgress
} from '../../../../store/actions/geo-fence/configuration/alertsConfiguration/egressSettingsAction'

const EgressSettingsDiv = ({
    isEgressApplicable,
    setEgressApplicable,
    isEgressTimeFullDay,
    isEgressTimeCustomized,
    setEgressTimeForFullDay,
    setEgressTimeCustomized,
    startTimeHour,
    setEgressStartTimeHr,
    startTimeMinute,
    setEgressStartTimeMinute,
    endTimeHour,
    setEgressEndTimeHr,
    endTimeMinute,
    setEgressEndTimeMinute,
    isEgressForAllVehicles,
    isEgressForCustomizedVehicles,
    setEgressForAllVehicles,
    setEgressForCustomizedVehicles,
    selectedVehicles,
    allVehicles,
    setSelectedVehiclesForEgress,
    fencesData
})=> {

    const isEgressDisabled = ()=> {
        const selectedFences = fencesData.filter((fence)=> fence.isSelected)
        if(selectedFences.length === 0) {
            return true
        }
    }

    useEffect(()=>{
        if(startTimeHour.length === 2) {
            document.getElementById('egress-start-time-minute').focus()
        }
    },[startTimeHour])
    
    useEffect(()=>{
        if(startTimeMinute.length === 2) {
            document.getElementById('egress-end-time-hour').focus()
        }
    },[startTimeMinute])

    useEffect(()=>{
        if(endTimeHour.length === 2) {
            document.getElementById('egress-end-time-minute').focus()
        }
    },[endTimeHour])

    const startTimeHourOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setEgressStartTimeHr('00')
            return
        }

        if(parseInt(e.target.value) > 23) {
            setEgressStartTimeHr('23')
            return
        }

        if(e.target.value.length <= 2) {
            setEgressStartTimeHr(e.target.value)
            return
        }
        setEgressStartTimeHr(e.target.value.substring(0,2))
    }

    const startTimeMinuteOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setEgressStartTimeMinute('00')
            return
        }

        if(parseInt(e.target.value) > 59) {
            setEgressStartTimeMinute('59')
            return
        }

        if(e.target.value.length <= 2) {
            setEgressStartTimeMinute(e.target.value)
            return
        }

        setEgressStartTimeMinute(e.target.value.substring(0,2))
    }

    const endTimeHourOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setEgressEndTimeHr('00')
            return
        }

        if(parseInt(e.target.value) > 23) {
            setEgressEndTimeHr('23')
            return
        }

        if(e.target.value.length <= 2) {
            setEgressEndTimeHr(e.target.value)
            return
        }
        setEgressEndTimeHr(e.target.value.substring(0,2))
    }

    const endTimeMinuteOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setEgressEndTimeMinute('00')
            return
        }

        if(parseInt(e.target.value) > 59) {
            setEgressEndTimeMinute('59')
            return
        }

        if(e.target.value.length <= 2) {
            setEgressEndTimeMinute(e.target.value)
            return
        }

        setEgressEndTimeMinute(e.target.value.substring(0,2))
    }

    return (
        <Row>
            
            <Col className="egress-configure p-3 mb-5 bg-white">

              <Row>
                <Col xs={2}>
                <div className="d-flex align-items-center flex-row justify-content-between ">
                <div className="alerts-operation alert_text">Egress</div>
                <div className="material-switch ">
                  <input
                    id="switch-egress"
                    name="switch-egress"
                    type="checkbox"
                    disabled={isEgressDisabled()}
                    checked={!isEgressDisabled() && isEgressApplicable}
                    onChange={() => {
                        setEgressApplicable(!isEgressApplicable);
                    }}
                    data-testid='egress-checkbox'
                  />
                  <label htmlFor="switch-egress"></label>
                </div>
              </div>
                </Col>
             </Row>
              
                <br></br>

              {
                !isEgressDisabled() && isEgressApplicable &&
                <Row>
                <Col xs={4}>
                    <label
                    style={{
                        fontSize: 15,
                    }}
                    >
                    Select Time
                    </label>
                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="anytime" 
                                checked={isEgressTimeFullDay}
                                onClick={setEgressTimeForFullDay}
                                data-testid='egress-anytime-radio'
                            />
                        </Col>
                        <Col xs={2} className="radio-button-label">
                            <label for="anytime">24 Hrs</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="customize" 
                                checked={isEgressTimeCustomized}
                                onClick={setEgressTimeCustomized}
                                data-testid='egress-customize-radio'
                            />
                        </Col>
                        <Col xs={2} className="radio-button-label">
                            <label for="customize">Customize</label>
                        </Col>
                    </Row>

                    {
                        isEgressTimeCustomized &&
                        <Row>
                            <Col xs={2}>
                            </Col>
                            <Col xs={10}>
                                <Row>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>HH</label>
                                        <input 
                                            type="text" 
                                            id="egress-start-time-hour"
                                            value={startTimeHour} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { startTimeHourOnChanged(e)}}
                                            data-testid='egress-starttime-hour-input'
                                        >
                                        </input>
                                    </Col>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>MM</label>
                                        <input 
                                            type="text" 
                                            id="egress-start-time-minute"
                                            value={startTimeMinute} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { startTimeMinuteOnChanged(e)}}
                                            data-testid='egress-starttime-minute-input'
                                        >
                                        </input>
                                    </Col>
                                    <Col xs={1} className="px-1">
                                        <br></br>
                                        <p>to</p>
                                    </Col>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>HH</label>
                                        <input 
                                            type="text" 
                                            id="egress-end-time-hour"
                                            value={endTimeHour} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { endTimeHourOnChanged(e)}}
                                            data-testid='egress-endtime-hour-input'
                                        >
                                        </input>
                                    </Col>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>MM</label>
                                        <input 
                                            type="text" 
                                            id="egress-end-time-minute"
                                            value={endTimeMinute} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { endTimeMinuteOnChanged(e)}}
                                            data-testid='egress-endtime-minute-input'
                                        >
                                        </input>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    
                </Col>
                <Col xs={4}>
                </Col>
                <Col xs={4}>
                    <label
                    style={{
                        fontSize: 15,
                    }}
                    >
                    Select Vehicle
                    </label>

                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="all-vehicles" 
                                checked={isEgressForAllVehicles}
                                onClick={setEgressForAllVehicles}
                                data-testid='egress-allvehicle-radio'
                            />
                        </Col>
                        <Col xs={3} className="radio-button-label">
                            <label for="all-vehicles">All Vehicles</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1} className="radio-button-label">
                            <input type="radio" id="customize-vehicles" 
                                checked={isEgressForCustomizedVehicles}
                                onClick={setEgressForCustomizedVehicles}
                                data-testid='egress-customizevehicle-radio'
                            />
                        </Col>
                        <Col xs={3}>
                            <label for="customize-vehicles">Customize</label>
                        </Col>
                    </Row> 
                    {
                        isEgressForCustomizedVehicles &&
                        <Row>
                            <Col xs={2}>
                            </Col>
                            <Col xs={9}>
                                <MultiSelect
                                    
                                    placeholder="Select Vehicles"
                                    options={allVehicles}
                                    selectedOptions={selectedVehicles}
                                    isMultiSelect = {true}
                                    setSelectedOptions={setSelectedVehiclesForEgress}
                                    data-testid='egress-vehicle-filter'
                                />
                            </Col>
                    </Row>
                    } 
                </Col>
                </Row>
              }

              
              
            </Col>
        </Row>
    )
}

EgressSettingsDiv.propTypes = {
    isEgressApplicable: PropTypes.bool.isRequired,
    isEgressTimeFullDay: PropTypes.bool.isRequired,
    isEgressTimeCustomized: PropTypes.bool.isRequired,
    startTimeHour: PropTypes.string.isRequired,
    startTimeMinute: PropTypes.string.isRequired,
    endTimeHour: PropTypes.string.isRequired,
    endTimeMinute: PropTypes.string.isRequired,
    isEgressForAllVehicles: PropTypes.bool.isRequired,
    isEgressForCustomizedVehicles: PropTypes.bool.isRequired,
    selectedVehicles: PropTypes.array.isRequired,
    allVehicles: PropTypes.array.isRequired,
    fencesData: PropTypes.array.isRequired,

    setEgressApplicable: PropTypes.func.isRequired,
    setEgressTimeForFullDay: PropTypes.func.isRequired,
    setEgressTimeCustomized: PropTypes.func.isRequired,
    setEgressStartTimeHr: PropTypes.func.isRequired,
    setEgressStartTimeMinute: PropTypes.func.isRequired,
    setEgressEndTimeHr: PropTypes.func.isRequired,
    setEgressEndTimeMinute: PropTypes.func.isRequired,
    setEgressForAllVehicles: PropTypes.func.isRequired,
    setEgressForCustomizedVehicles: PropTypes.func.isRequired,
    setSelectedVehiclesForEgress: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isEgressApplicable: state.alertConfigurationScreen.egressSettings.isEgressApplicable,
    isEgressTimeFullDay: state.alertConfigurationScreen.egressSettings.isEgressTimeFullDay,
    isEgressTimeCustomized: state.alertConfigurationScreen.egressSettings.isEgressTimeCustomized,
    startTimeHour: state.alertConfigurationScreen.egressSettings.startTimeHour,
    startTimeMinute: state.alertConfigurationScreen.egressSettings.startTimeMinute,
    endTimeHour: state.alertConfigurationScreen.egressSettings.endTimeHour,
    endTimeMinute: state.alertConfigurationScreen.egressSettings.endTimeMinute,
    isEgressForAllVehicles: state.alertConfigurationScreen.egressSettings.isEgressForAllVehicles,
    isEgressForCustomizedVehicles: state.alertConfigurationScreen.egressSettings.isEgressForCustomizedVehicles,
    selectedVehicles: state.alertConfigurationScreen.egressSettings.selectedVehicles,
    allVehicles: state.alertConfigurationScreen.allVehicles,
    fencesData: state.alertConfigurationScreen.fencesData,

    setEgressApplicable,
    setEgressTimeForFullDay,
    setEgressTimeCustomized,
    setEgressStartTimeHr,
    setEgressStartTimeMinute,
    setEgressEndTimeHr,
    setEgressEndTimeMinute,
    setEgressForAllVehicles,
    setEgressForCustomizedVehicles,
    setSelectedVehiclesForEgress
});

export default connect(mapStateToProps, {
    setEgressApplicable,
    setEgressTimeForFullDay,
    setEgressTimeCustomized,
    setEgressStartTimeHr,
    setEgressStartTimeMinute,
    setEgressEndTimeHr,
    setEgressEndTimeMinute,
    setEgressForAllVehicles,
    setEgressForCustomizedVehicles,
    setSelectedVehiclesForEgress
})(EgressSettingsDiv);
