import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import styles from "./InformativeModal.module.css";

export const variantImages = {
  success: '/images/svgicon/successIcon.svg',
  error: '/images/svgicon/wariningIcon.svg',
  warning: '/images/svgicon/wariningIcon.svg',
}

export const VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
}

const InformativeModal = ({ isOpen, onClose, variant, message }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        onClose();
      }, 2000);
    }
  }, [isOpen, onClose]);

  const handleModalClose = () => {
    setIsModalOpen(false)
    onClose()
  }

  if (!isModalOpen) {
    return null;
  }

  return (
    <div className={`${styles.modalContainer}`}>
      <div className={`${styles.modalWrapper}`}>
          <div className={`${styles.modalCloseWrapper}`}>
            <img
                className={`${styles.modalCloseIcon}`}
                onClick={handleModalClose}
                src='/images/svgicon/trackingScreen/close_active.svg'
                alt='close-btn'
            />
        </div>
        <div className={`${styles.content}`}>
          <img className="h-25 w-25 mb-2" src={variantImages[variant]} alt={variant} />
          <div className={`text-white fs-4 fw-medium`}>{message}</div>
        </div>
      </div>
    </div>
  );
};

InformativeModal.defaultProps = {
  isOpen: false,
  onClose: () => { },
  variant: 'success',
  message: 'Success',
};

InformativeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success, error, warning']),
  message: PropTypes.string,
};

export default InformativeModal; 