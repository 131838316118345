import React from "react";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import ActionTypes from "../../../../store/actionTypes";
const { 
  // PLACES_SEARCHBAR_VALUE,
  // this might be needed in future. 
  PLACES_SEARCHBAR_KEY,
  PLACES_SEARCHBAR_CO_ORDINATES,
  ZOOM_LEVEL
} = ActionTypes.DIFFERENT_CREATE_FENCE_ACTIONS 

const PlacesSearchBar = () => {

  const dispatch = useDispatch()
  const randomKey = Math.random()
  const [address, setAddress] = useState("");

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLong = await getLatLng(results[0]);
    const randomKey = Math.random()

    setAddress(value)
    dispatch({
      type: PLACES_SEARCHBAR_CO_ORDINATES,
      payload: latLong
    })
    dispatch({
      type: ZOOM_LEVEL,
      payload: 18
    })
    dispatch({
      type: PLACES_SEARCHBAR_KEY,
      payload: randomKey
    })
    
  };
  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      
      
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={` ${!address? 'no-value-selected-input' : ''}`}>

        <div
        className="search-input"
          style={{
            position: "fixed",
            bottom: "79vh",
            zIndex: 999,
            left: "42vw",
            paddingTop: 8,
            background:" #E7ECF7" 
          }}
        >
          <FaSearch
            style={{
              marginBottom: 15,
              marginRight: 20,
              cursor: "pointer",
              color: "gray",
              fontSize: 20,
              position: "relative",
              left: 20,
              top: 5,
            }}
            title="search"
          />
          <input
            {...getInputProps({
              placeholder: "Search",
              className: "location-search-input",
            })}
            className="search-input"
            style={{
              borderRadius: 10,
              border: "none",
              width: 400,
              paddingLeft: 10,
              paddingBottom: 6,
              outline: "none",
              fontSize:'14px',
              background:" #E7ECF7" 
            }}
            value={address}
            data-testid='fence-place-search-input'
          />
          {address.length > 0 && (
            <AiOutlineClose
              style={{
                cursor: "pointer",
                color: "gray",
                fontSize: 28,
                position: "relative",
                right: 5,
              }}
              onClick={() => {
                setAddress("");              
                dispatch({
                  type: ZOOM_LEVEL,
                  payload: 4
                })
                dispatch({
                  type: PLACES_SEARCHBAR_CO_ORDINATES,
                  payload: {
                    lat: 20.5937,
                    lng: 78.9629, 
                },
                })
                dispatch({
                  type: PLACES_SEARCHBAR_KEY,
                  payload: randomKey
                })
              }}
              title="Clear"
              data-testid='fence-place-search-clear-button'
            />
          )}
          <div
            className="autocomplete-dropdown-container"
            style={{
              position: "absolute",
              zIndex: 100,
              width: "100%",
              backgroundColor: "white",
              border: "1px solid #ccc",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  data-testid={`fence-place-search-result-${index}`}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default PlacesSearchBar;
