import ActionTypes from '../../actionTypes'
const { USER_ACTIVITIES_FETCHED } = ActionTypes.USERS

const initialState = {
    allActivities: [],
    dataCount: 0
}

const userActivityReducer = (state = initialState, action) =>  {
    const { type, payload } = action
    
    switch (type) {
        case USER_ACTIVITIES_FETCHED: 
        return {
            allActivities: payload?.data?.data?.data,
            dataCount: payload?.data?.data?.totalCount
        }
        default:
            return state
    }
}

export default userActivityReducer