

import store from "./store/store";
import { useEffect } from "react";
import AppRoutes from "./routes/AppRoutes";
import ActionTypes from "./store/actionTypes";
import "bootstrap/dist/css/bootstrap.css";
import TagManager from 'react-gtm-module'
import { clearLocalStorageExceptclientIP, setClientIPInLocalStorage } from "./utils/common-methods";


function App() {


  

  useEffect(() => {
    //Get client IP for request and response and store in localstorage
    setClientIPInLocalStorage()

    //Logout user from all tabs if user log out in one tab
    window.addEventListener("storage", (event) => {
      if (!localStorage.token || !localStorage.user) {
        store.dispatch({ type: ActionTypes.AUTH.LOGOUT });
      } else {
        if (["token", "user", "rtoken"].includes(event?.key)) {
          if (event.oldValue !== event.newValue && event.oldValue !== null) {
            store.dispatch({ type: ActionTypes.AUTH.LOGOUT });
            clearLocalStorageExceptclientIP()
          }
        }
      }
    });


    if (process.env.REACT_APP_ENV === 'LIVE') {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID
      };
      TagManager.initialize(tagManagerArgs);
    }


    // const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    // when VPN or internet server change 
    const connectionChangeHandler = () => {
      console.log("Network or IP changed")
      setClientIPInLocalStorage();
    }

    // if(connection){
    //   connection.onchange = connectionChangeHandler;
    // }

    window.addEventListener('offline', function(event) {
      console.log("The network connection has been lost.");
    });

    window.addEventListener('online', function(event) {
      console.log("You are now connected to the network.");
      connectionChangeHandler()
    });

  }, []);


 

  return  <AppRoutes />
}

export default App;
