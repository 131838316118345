import api from '../../../service/api'
import ActionTypes from '../../actionTypes'
import API_BOOK from '../../../service/endpoints'
const { GET_CI_SUBSCRIBED_VEHICLES_SUCCESS, GET_SUBSCRIBED_RN_NUMS_SUCCESS, GET_ALL_VEHICLES_TRACKING_DETAILS, TOP_PANE_STATUS, GET_VEHICLE_TRACKING_CHARGING_DETAILS_SUCCESSS, SYNC_VEHICLE_SUCCESS,SYNC_VEHICLE_FAILED ,GET_SUBSCRIBED_RN_NUMS_OEM_MODEL, GET_VEHICLE_TRACKING_CAN_DATA_DETAILS_SUCCESSS} = ActionTypes.VEHICLE.VEHICLE
const { GET_SUBSCRIBED_RN_NUMS, GET_ALL_VEHICLES_TRACKING, GET_VEHICLE_TRACKING_HISTORY, GET_VEHICLE_TRACKING_CHARGING_DETAILS,SYNC_VEHICLES, GET_VEHICLE_TRACKING_CAN_DATA_DETAILS } = API_BOOK.VEHICLE

/* Action dispatchers starts here */

const getSubRnNumsActionDispatcher = (payload) => ({
  type: GET_SUBSCRIBED_RN_NUMS_SUCCESS,
  payload,
})

const getSubRnNumsOemModelActionDispatcher = (payload) => ({
  type: GET_SUBSCRIBED_RN_NUMS_OEM_MODEL,
  payload,
})

export const getAllVehicleTrackingDataAD = (payload) => ({
  type: GET_ALL_VEHICLES_TRACKING_DETAILS,
  payload,
})

export const resetTopPanelStatus = () => ({
  type: TOP_PANE_STATUS,
  payload: {
    status: 'all'
  },
})

export const getVehicleTrackingChargingDetailsAD = (payload) => ({
  type: GET_VEHICLE_TRACKING_CHARGING_DETAILS_SUCCESSS,
  payload,
})


const syncVehicleSuccessAD = (payload) => ({
  type: SYNC_VEHICLE_SUCCESS,
  payload,
});

const syncVehicleFailedAD = () => ({
  type: SYNC_VEHICLE_FAILED
});

export const getVehicleTrackingCanDataDetailsAD = (payload) => ({
  type: GET_VEHICLE_TRACKING_CAN_DATA_DETAILS_SUCCESSS,
  payload,
})
/* Action dispatchers ends here */
/* Actions starts here */

/**
 *  @desc         Get all subscribed registration numbers api action
 *  @author       Deepak Prajapati
 *  @api          /tracking/subscribed-rn
 *  @method       GET
 *  @createdDate  07-Jul-2023
 *  @modifiedDate 07-Jul-2023
 **/
export const GetSubsRnNums = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_SUBSCRIBED_RN_NUMS.method,
      url: GET_SUBSCRIBED_RN_NUMS.url,
    })

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data?.map((val) => {
        const obj = {
          value: val._id,
          label: val.registration_number,
          oem: val.oem,
          model: val.model,
        }
        return obj
      })
      dispatch(getSubRnNumsActionDispatcher(response))
    }
  } catch (error) {
    console.log('error:', error)
  }
}

/**
 *  @desc         Get all subscribed registration numbers api action and oem model
 *  @author       Ankit Kushwaha
 *  @api          /tracking/subscribed-rn
 *  @method       GET
 *  @createdDate  14-Dec-2023
 *  @modifiedDate 14-Dec-2023
 **/

export const GetSubsRnNumsAndOemModel = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_SUBSCRIBED_RN_NUMS.method,
      url: GET_SUBSCRIBED_RN_NUMS.url,
    })

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data?.map((val) => {
        const obj = {
          value: val._id,
          label: val.registration_number,
          oem: val.oem,
          model: val.model,
        }
        return obj
      })
      dispatch(getSubRnNumsOemModelActionDispatcher(response))
    }
  } catch (error) {
    console.log('error:', error)
  }
}

/**
 *  @desc         Get all vehicle tacking data
 *  @author       Deepak Prajapati
 *  @api          /tracking
 *  @method       GET
 *  @createdDate  03-Aug-2023
 *  @modifiedDate 03-Aug-2023
 **/
export const GetAllVehicleTrackingData = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_VEHICLES_TRACKING.method,
      url: GET_ALL_VEHICLES_TRACKING.url,
    })

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllVehicleTrackingDataAD(response))
    }
  } catch (error) {
    console.log('error:', error)
  }
}

/**
 *  @desc         Get vehicle history based on registration number
 *  @author       Amir shaikh
 *  @api          /:registrationNumber/history
 *  @method       POST
 *  @createdDate  18-Aug-2023
 *  @modifiedDate 18-Aug-2023
 **/
export const GetVehicleTrackingHistoryAPI = async({ registrationNumber, startDateTime, endDateTime }) => {
  return await api({
    url: GET_VEHICLE_TRACKING_HISTORY.url.replace(":registration_number",registrationNumber),
    method: GET_VEHICLE_TRACKING_HISTORY.method,
    data: { startDateTime, endDateTime }
  })
}

/**
 *  @desc         Get all vehicle tracking details
 *  @author       Amir shaikh
 *  @api          /vehicle/tracking
 *  @method       GET
 *  @createdDate  05-Sept-2023
 *  @modifiedDate 05-Sept-2023
 **/
export const FetchAllDashboardVehicles = async() => {
  return await api({
    url: GET_ALL_VEHICLES_TRACKING.url,
    method: GET_ALL_VEHICLES_TRACKING.method,
  })
}

/**
 *  @desc         Get tracking/charging details for dashboard
 *  @author       Mukesh Fulwariya
 *  @api          /vehicle/tracking
 *  @method       POST
 *  @createdDate  13-Sept-2023
 *  @modifiedDate 13-Sept-2023
 **/
export const getVehicleTrackingChargingDetails = async({ registrationNumber, startDateTime, endDateTime, canDataTimestamp }) => {
  return await api({
    url: GET_VEHICLE_TRACKING_CHARGING_DETAILS.url.replace(":registration_number",registrationNumber),
    method: GET_VEHICLE_TRACKING_CHARGING_DETAILS.method,
    data: { startDateTime, endDateTime, canDataTimestamp }
  })
}

    /**
 *  @desc         Sync Vehicles
 *  @author       Nilesh Bharat Salunkhe
 *  @api          `altigreen-exponent-service/v1/vehicleOem/addVehicle`
 *  @method       POST
 *  @createdDate  04-October-2023
 *  @modifiedDate 04-October-2023
 **/

    export const SyncVehicles = ({data, oemSource}) => async (dispatch) => {
      try {
        const resp = await api({
          method: SYNC_VEHICLES.SYNC_VEHICLE.method,
          url: SYNC_VEHICLES.SYNC_VEHICLE.url.replace('vehicleOem', oemSource ),
          data
          
        });
      
        if (resp.status === 200) {          
          dispatch(syncVehicleSuccessAD(true));            
        } else {
          dispatch(syncVehicleFailedAD());
        }
      } catch (error) {
        dispatch(syncVehicleFailedAD());
        console.log("syncVehicle error:", error);
      }
    };

/**
 *  @desc         Get tracking can data details for dashboard
 *  @author       Mukesh Fulwariya
 *  @api          /vehicle/tracking/:registration_number/can-data
 *  @method       POST
 *  @createdDate  23-Apr-2024
 *  @modifiedDate 23-Apr-2024
 **/
export const getVehicleTrackingCanData = async({ registrationNumber, startDateTime, endDateTime }) => {
  return await api({
    url: GET_VEHICLE_TRACKING_CAN_DATA_DETAILS.url.replace(":registration_number",registrationNumber),
    method: GET_VEHICLE_TRACKING_CAN_DATA_DETAILS.method,
    data: { startDateTime, endDateTime }
  })
}

/**
 *  @desc         Get all subscribed vehicles by CI
 *  @author       Amir Shaikh
 *  @api          /vehicle/tracking/subscribed
 *  @method       GET
 *  @createdDate  29-May-2024
 *  @modifiedDate 29-May-2024
 **/
export const GetSubscribedVehiclesByCIAction = () => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_SUBSCRIBED_RN_NUMS.method,
      url: GET_SUBSCRIBED_RN_NUMS.url,
    })

    if (resp.status === 200 && resp?.data?.data) {
      dispatch({ type: GET_CI_SUBSCRIBED_VEHICLES_SUCCESS, payload: resp.data.data })
    }
  } catch (error) {
    console.log('error:', error)
  }
}
/* Actions ends here */
