import React from 'react';
import styles from './Modal.module.css'
import Button from '../../../shared/form/Button/Button';

const Modal = ({ isVisible, closeModal , name,handleUpdateStatus,status}) => {

  return isVisible && (
    <div className={`${styles.activeModalContainer}`}>

      <div className={`${styles.activeModal}`}>
        <img  src='/images/illustration.svg' alt='illustration' width={120} height={120}/>
        <span className={`${styles.activeModalText}`}>ARE  YOU SURE ?</span>
        <span className={`${styles.activeModalTextConfirm}`}>You want to {(status === 'approve' ? 'Approve' : 'Decline')} {name}</span>

        <div className={`${styles.buttonContainer}`}>
   
        <Button variant="PINK_OUTLINED_MODAL" onClick={closeModal} >No</Button>
         <Button variant="PINK_MODAL" onClick={handleUpdateStatus}>Yes</Button>
          
        </div>
      </div>
    </div>
  );
};

export default Modal;
