import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Table } from "react-bootstrap";
import MultiSelect from "../helpers/hoc/mult-select/MultiSelect";
import PopoverB from "react-bootstrap/Popover";
import PopoverBody from "react-bootstrap/PopoverBody";
import { BsDownload } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import Pagination from "../helpers/hoc/paginator/Pagination";
import MapAdddressByLocation from "../helpers/hoc/latlong-to-address-map/MapAddressByLocation";
import {
  immobilizeNonComIssue,
  mobilizeReasonData,
} from "../../utils/constants";
import { mapper } from "../../utils/mapper";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { processChangeWithDebounce } from "../../utils/common-methods";
import {
  ExportMobilize,
  fetchMobilize,
  fetchReasonMobilize,
} from "../../store/actions/immobilize/Immobilize";
import NoRecordFound from "../helpers/hoc/noRecordFound";
import BaseModal from "../shared/modals/BaseModal/BaseModal";
import SingleSelect from "../helpers/hoc/single-select/SingleSelect";
import styleMobilize from "./mobilize.module.css";
import Loader from "../helpers/hoc/loader/Loader";

const Mobilize = ({
  // state
  subscribedByCI,
  mobilizeListData,
  selectedTab,
  //Action
  fetchMobilize,
  ExportMobilize,
  fetchReasonMobilize,
}) => {

  const vehicleData = subscribedByCI?.filter(v => v.oem === 'Bounce')
  const mappedOptions = mapper.mapVehicleDataOptions(vehicleData);
  const immobilizeNonComIssueList = immobilizeNonComIssue?.map((d) => ({
    label: d.label,
    value: d.value,
  }));
  const MobilizeReasonDataList = mobilizeReasonData?.map((d) => ({
    label: d.label,
    value: d.value,
  }));

  const OthersData = [
    { id: 1, message: "Location not received" },
    { id: 2, message: " SoC received is 0" },
    { id: 3, message: "CAN data received is 0" },
    { id: 4, message: "Location, SoC, CAN & ODO Meter data received are 0" },
  ];

  const initialFieldValues = {
    registrationNumber: [],
    nonComIssue: [],
  };

  const ITEMS_PER_PAGE = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState();
  const [mobilizeReasonModal, setmobilizeReasonModal] = useState(false);
  const [selectedReasonMobilize, setSelectedReasonMobilize] = useState(null);
  const [reasonOtherInput, setReasonOtherInput] = useState();
  const [showInputField, setShowInputField] = useState(false);
  const [formData, setFormData] = useState(initialFieldValues);
  const [showPopup, setShowPopup] = useState(false);
  const [coordinate, setCoordinate] = useState();
  const [mobilizieImei, setMobilizieImei] = useState("");

  const isLoading = useSelector((state) => state.loader.isLoading);

  useEffect(() => {
    setFormData(initialFieldValues);
    setReasonOtherInput("");
    setSelectedReasonMobilize(null);
    setSearchTerm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleOpenMobilizeReason = (imei) => {
    setmobilizeReasonModal(true);
    setMobilizieImei(imei);
  };

  const handleCloseMobilizeReason = () => {
    setmobilizeReasonModal(false);
    setReasonOtherInput("");
    setSelectedReasonMobilize(null);
    setShowInputField(false);
  };

  const handleChangeReasonMobilize = (e) => {
    setReasonOtherInput(e.target.value);
  };

  const onSelectionChangeOther = (value) => {
    setSelectedReasonMobilize(value);
    if (value.label === "Others") {
      setShowInputField(true);
    } else {
      setShowInputField(false);
    }
  };

  const handlereasonMobilizeUpdate = async () => {
    await fetchReasonMobilize({
      data: {
        reasonType: selectedReasonMobilize?.value,
        reasonMessage: reasonOtherInput,
        imei: mobilizieImei,
        command: "Mobilize",
      },
    });
    setmobilizeReasonModal(false);
    setSearchTerm("");
    fetchMobilizeData()
  };

  function renderTooltip(props) {
    return (
      <PopoverB className={styleMobilize.popover}>
        <PopoverBody className={styleMobilize.popoverBody}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  background: "#22046B",
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              >
                {OthersData.map((location) => (
                  <li
                    key={location.id}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {location.id}
                    </span>
                    <span
                      style={{
                        width: "190px",
                        textAlign: "left",
                      }}
                    >
                      {location.message}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </PopoverBody>
      </PopoverB>
    );
  }

  const RowButton = ({
    renderTooltip,
    integrationBy,
    intergrationDate,
    integrationTime,
  }) => (
    <OverlayTrigger
      placement="top"
      delay={{ show: 0, hide: 0 }}
      overlay={renderTooltip({
        parameter1: integrationBy,
        parameter2: intergrationDate,
        parameter3: integrationTime,
      })}
    >
      <button style={{ background: "transparent", marginLeft: "5px" }}>
        <img src="/images/infoIconMobilize.svg" alt="info" />
      </button>
    </OverlayTrigger>
  );

  const togglePopup = () => {
    setShowPopup((prevState) => !prevState);
  };
  const handleMapCoOrdinateClick = (event) => {
    setShowPopup(true);
    setCoordinate(event);
  };



  // eslint-disable-next-line no-unused-vars

  const handleOnChange = ({ name = "", value = "" }) => {
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value,
      };
      setCurrentPage(1)
      return newFormData;
    });
  };

  const fetchMobilizeData = () => {
    fetchMobilize({
      searchKey: searchTerm,
      pageIndex: currentPage,
      pageSize: ITEMS_PER_PAGE,
      registrationNumber: formData.registrationNumber?.map((x) => x.label),
      nonComIssue: formData.nonComIssue?.map((x) => x.label),
    });
  };

  useEffect(() => {
    fetchMobilizeData({ ...formData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  useEffect(() => {
    if (selectedTab === "non_comm_log") {
      fetchMobilizeData({ ...formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentPage, searchTerm,formData]);

  const exportToExcel = () => {
    ExportMobilize({
      data: {
        searchKey: searchTerm,
        pageIndex: currentPage,
        pageSize: ITEMS_PER_PAGE,
        registrationNumber: formData.registrationNumber?.map((x) => x.label),
        nonComIssue: formData.nonComIssue?.map((x) => x.label),
        vehicleStatuses: 'Mobilize'
      },
    });
  };

  function getStatusClass(status) {
    if (status === "Non Com") return styleMobilize["immobilize-non-com-status"];
    if (status === "Live") return styleMobilize["immobilize-live-status"];
    if (status === "Non Com") return styleMobilize["immobilize-non-com-status"];
    if (status === "Charging")
      return styleMobilize["immobilize-charging-status"];
    if (status === "Parked") return styleMobilize["immobilize-parked-status"];
    if (status === "Idle") return styleMobilize["immobilize-idle-status"];
    return "";
  }

  function getStatus(deviceResponse) {
    if (deviceResponse.includes("INFINITY")) {
      return "Moblized";
    } else {
      return "Immobilized";
    }
  }

  const DataRow = ({ recent, index }) => (
    <tr>
      <td style={{verticalAlign:'middle'}}>{recent?.rn}</td>
      <td style={{verticalAlign:'middle'}}> {recent.oem + " " + recent.mdl}</td>
      <td style={{verticalAlign:'middle'}}>
        <img
          style={{ cursor: "pointer" }}
          src="/images/locationImobilizeIcon.svg"
          alt="location"
          onClick={(event) =>
            handleMapCoOrdinateClick({
              lat: parseFloat(recent?.lt),
              lng: parseFloat(recent?.lng),
            })
          }
          data-testid={`unresolved-gps-button-${recent?.rn}`}
        />
      </td>

      <td style={{verticalAlign:'middle'}}>
        <button
          style={{ cursor: "auto" }}
          className={getStatusClass(recent?.vs)}
        >
          {recent?.vs}
        </button>
      </td>
      <td style={{verticalAlign:'middle'}}>{recent?.issue ? recent?.issue : "_ _ _ _"}</td>
      <td style={{verticalAlign:'middle'}}>
        <button
          className={styleMobilize.mobilizeActionBtn}
          data-testid={`view-button-${index}`}
          onClick={() => handleOpenMobilizeReason(recent.imei)}
        >
          Mobilize
        </button>
      </td>
      <td style={{verticalAlign:'middle'}}>
        <button
          style={{
            cursor: "auto",
            width: "140px",
            height: "40px",
            borderRadius: "5px",
            marginTop: "5px",
          }}
          disabled={recent?.deviceResponse ?? false}
          className={
            recent?.deviceResponse ? getStatusClass(getStatus(recent?.deviceResponse )) : ""
          }
        >
          {recent?.deviceResponse ? getStatus(recent?.deviceResponse) : "NA"}
        </button>
      </td>
      <td style={{verticalAlign:'middle'}}>
        <button
          className={styleMobilize.reasonBtnForMobilize}
          data-testid={`view-button-${index}`}
        >
          {recent?.mrt}
          {recent.Reason === "Others" && (
            <RowButton renderTooltip={renderTooltip} />
          )}
        </button>
      </td>
    </tr>
  );

  return (
    <>
      <Row>
        <Col xs={2}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            placeholderAlignment="flex-start"
            okButtonPaddingEnd="pe-2"
            optionPosition="ps-2 justify-content-start"
            placeholder="Registration No."
            placeholderColor="#1F2A5D"
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#1F2A5D"
            customBackgroundColor="#EFF3F8"
            options={mappedOptions.registrationNumber}
            selectedOptions={formData.registrationNumber}
            setSelectedOptions={(data) =>
              handleOnChange({ name: "registrationNumber", value: data })
            }
            data-testid="vehicle-filter"
          />
        </Col>
        <Col xs={2}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            okButtonPaddingEnd="pe-2"
            placeholderColor="#1F2A5D"
            optionPosition="ps-2 justify-content-start"
            placeholder="Non Com Issue"
            placeholderAlignment="flex-start"
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#797694"
            customBackgroundColor="#EFF3F8"
            options={immobilizeNonComIssueList}
            selectedOptions={formData.nonComIssue}
            setSelectedOptions={(data) =>
              handleOnChange({ name: "nonComIssue", value: data })
            }
            data-testid="nonComIssue-filter"
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-between">
        <div
          className="search-bar-div search-input mt-3 "
          style={{ width: "15%" }}
        >
          <FaSearch className="vob-search-icon" />
          <input
            onChange={(e) => {
              processChangeWithDebounce(e, setSearchTerm, setCurrentPage);
            }}
            className="vob-search-input search-input"
            placeholder="Search"
            data-testid="unresolved-search-input"
          />
          <div></div>
        </div>

        <div className=" d-flex  me-3 mt-4">
          <button
            onClick={() => {
              fetchMobilizeData();
            }}
            className={styleMobilize.dwnRefresh}
            data-testid="unresolved-export-button"
          >
            Refresh <img src="/images/refreshIcon.svg" alt="refreshIcon" />
          </button>

          <button
            onClick={() => {
              exportToExcel("Open");
            }}
            className={"dwn-btn"}
            data-testid="unresolved-export-button"
          >
            <BsDownload style={{width:'22px',height:'22px'}} />
          </button>
        </div>
      </div>
      {mobilizeListData?.result?.length ? (
        <div className="bg-white position-relative px-2 py-3 mt-3" style={{
          boxShadow: '0px 3px 6px #00000029',
          border:'1px solid rgba(0, 0, 0, 0.1)',
          marginRight:'1%',
          marginBottom:'15px'
        }}>
          <Table
            responsive={true}
            borderless
            id="immobilize_table"
            className={styleMobilize.mobilizeTable}
          >
            <thead>
              <tr className="th-border-bottom position-relative">
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Vehicle No.</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500"> OEM & Model</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Device GPS</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Status</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Non Com Issue</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Action</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Command Status</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">
                    Reason for <br></br> Mobilization
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {mobilizeListData?.result?.length > 0 && (
                <>
                  {mobilizeListData?.result.map((item, index) => (
                    <DataRow
                      key={index}
                      recent={item}
                      index={index}
                      renderTooltip={renderTooltip}
                    />
                  ))}
                </>
              )}
            </tbody>
          </Table>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(mobilizeListData?.total / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setCurrentPage}
            data-testid="unresolved-pagination"
          />
        </div>
      ) : (
        <></>
      )}
      {!mobilizeListData?.result?.length ? <NoRecordFound></NoRecordFound> : <></>}

      <BaseModal
        variant="immobilize"
        isOpen={mobilizeReasonModal}
        onClose={handleCloseMobilizeReason}
        title="Reason for Mobilization "
      >
        <SingleSelect
          placeholder="Select Reason"
          placeholderColor="#10204B"
          placeholderOnError=""
          webkitScrollbarWidth="5px"
          singleValueColor="#10204B"
          valueContainerJustifyContent="start"
          valueContainerWidthInPx="350px"
          valueContainerMaxWidthInPx="350px"
          optionPosition="ps-2 justify-content-start"
          hoverWidth=" 357px"
          hoverMargin="0 20px 0 10px;"
          hoverBorderRadius="5px"
          hoverpadding="10px 1px"
          maxMenuHeight={140}
          customBackgroundColor="#FFFFFF"
          data-testid="fence-labelowner-select"
          IndicatorsContainerBorderright="1px solid rgba(151, 151, 151, 0.39)"
          IndicatorsContainerBorderbottom="1px solid rgba(151, 151, 151, 0.39)"
          IndicatorsContainerBorderTop="1px solid rgba(151, 151, 151, 0.39)"
          IndicatorsContainerBorderRadius="5px"
          valueContainerBorderleft="1px solid rgba(151, 151, 151, 0.39)"
          valueContainerBorderbottom="1px solid rgba(151, 151, 151, 0.39)"
          valueContainerBorderTop="1px solid rgba(151, 151, 151, 0.39)"
          valueContainerborderTopLeftRadius="5px"
          valueContainerborderBottomLeftRadius="5px"
          IndicatorsContainerborderTopRightRadius="5px"
          IndicatorsContainerborderBottomRightRadius="5px"
          menuMaxheight='198px'
          valueContainerPadding="10px 30px 7px 15px"
          hoverBackgroundColor='rgba(134, 25, 228, 0.2)'

          options={MobilizeReasonDataList}
          selectedOptions={selectedReasonMobilize}
          onSelectHandler={(value) => {
            setSelectedReasonMobilize(value);
            onSelectionChangeOther(value);
          }}
        />
        {showInputField && (
          <textarea
            className={styleMobilize.reasonOtherTextarea}
            type="text"
            onChange={handleChangeReasonMobilize}
            value={reasonOtherInput}
          />
        )}
        <div className="immobilize-reason-btn-container">
          <button
            className={styleMobilize.mobilizeReasonBtn}
            onClick={handlereasonMobilizeUpdate}
          >
            Mobilize
          </button>
        </div>
      </BaseModal>

      {showPopup && (
        <div id="popup1" className={`popup ${showPopup ? "open" : ""}`}>
          <div
            className="popup-overlay"
            data-testid="unresolved-gps-dialog-toggle"
            onClick={togglePopup}
          ></div>
          <div className="popup-content">
            <MapAdddressByLocation
              isOpen={showPopup}
              toggleModal={togglePopup}
              CoOrdinates={coordinate}
              data-testid="unresolved-gps-dialog"
            />
          </div>
        </div>
      )}

      <Loader isLoading={isLoading} />
    </>
  );
};

Mobilize.propTypes = {
  subscribedByCI: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
  mobilizeListData: state.Immobilize.mobilizeListData,
});
export default connect(mapStateToProps, {
  fetchMobilize,
  ExportMobilize,
  fetchReasonMobilize,
})(Mobilize);
