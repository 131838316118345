import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import MultiSelect from "../helpers/hoc/mult-select/MultiSelect";
import { BsDownload } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import Pagination from "../helpers/hoc/paginator/Pagination";
import { mapper } from "../../utils/mapper";
import { immobilizeHostoryStatusOptions } from "../../utils/constants";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  getFormattedDate,
  processChangeWithDebounce,
} from "../../utils/common-methods";
import {
  ExportHistoryImmobilize,
  fetchHistoryImmobilize,
} from "../../store/actions/immobilize/Immobilize";
import NoRecordFound from "../helpers/hoc/noRecordFound";
import MapAdddressByLocation from "../helpers/hoc/latlong-to-address-map/MapAddressByLocation";
import styleImmobilizeHistory from "./immobilizeHistory.module.css";
import Loader from "../helpers/hoc/loader/Loader";

const ImmobilizeHistory = ({
  // state
  subscribedByCI,
  historyImmobilizeListData,
  selectedTab,

  // // action
  fetchHistoryImmobilize,
  ExportHistoryImmobilize,
}) => {
  
  const vehicleData = subscribedByCI?.filter(v => v.oem === 'Bounce')
  const mappedOptions = mapper.mapVehicleDataOptions(vehicleData);
  const vehicleStatusOptionsList = immobilizeHostoryStatusOptions?.map((d) => ({
    label: d.label,
    value: d.value,
  }));

  const initialFieldValues = {
    registrationNumber: [],
    status: [],
  };

  const ITEMS_PER_PAGE = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState();
  const [formData, setFormData] = useState(initialFieldValues);
  const [showPopup, setShowPopup] = useState(false);
  const [coordinate, setCoordinate] = useState();
  const isLoading = useSelector((state) => state.loader.isLoading);

  useEffect(() => {
    setFormData(initialFieldValues);
    setSearchTerm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  // vehicle Registration no. Dropdown

  

  const fetchHistoryImmobilizeData = () => {
    fetchHistoryImmobilize({
      searchKey: searchTerm,
      pageIndex: currentPage,
      pageSize: ITEMS_PER_PAGE,
      registrationNumber: formData.registrationNumber?.map((x) => x.label),
      status: formData.status?.map((x) => x.label),
    });
  };

  useEffect(() => {
    fetchHistoryImmobilizeData({ ...formData });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTab === "history") {
      fetchHistoryImmobilizeData({ ...formData });
    }

    // eslint-disable-next-line
  }, [currentPage, searchTerm,formData]);

 

  const exportToExcel = () => {
    ExportHistoryImmobilize({
      data: {
        searchKey: searchTerm,
        pageIndex: currentPage,
        pageSize: ITEMS_PER_PAGE,
        registrationNumber: formData.registrationNumber?.map((x) => x.label),
        location: formData.location?.map((x) => x.label),
        status: formData.status?.map((x) => x.label),
        nonComIssue: formData.nonComIssue?.map((x) => x.label),
      },
    });
  };

  const handleOnChange = ({ name = "", value = "" }) => {
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value,
      };
      setCurrentPage(1)

      return newFormData;
    });
  };

  const togglePopup = () => {
    setShowPopup((prevState) => !prevState);
  };
  const handleMapCoOrdinateClick = (event) => {
    setShowPopup(true);
    setCoordinate(event);
  };

  function getStatusClass(status) {
    if (status === "Mobilize")
      return styleImmobilizeHistory["mobilize-history-status"];
    if (status === "Immobilize")
      return styleImmobilizeHistory["immobilize-history-status"];
    if (status === "NA") return styleImmobilizeHistory["immobilize-na-status"];
    return "";
  }

  function getReasonType (row) {
    if(row.ms === 'Mobilize') {
      return row.mrt
    }
    return row.irt
  }

  const DataRow = ({ recent, index }) => (
    <tr style={{verticalAlign:'middle'}}>
      <td>{recent?.rn}</td>
      <td> {recent.oem + " " + recent.mdl}</td>
      <td>{getFormattedDate(recent?.createdAt)}</td>
      <td>
        <img
          style={{ cursor: "pointer" }}
          src="/images/locationImobilizeIcon.svg"
          alt="location"
          onClick={(event) =>
            handleMapCoOrdinateClick({
              lat: parseFloat(recent?.lt),
              lng: parseFloat(recent?.lng),
            })
          }
          data-testid={`unresolved-gps-button-${recent?.rn}`}
        />
      </td>
      <td className="mt-3">
        <button
          style={{ cursor: "auto" }}
          className={getStatusClass(recent?.ms)}
        >
          {recent?.ms}
        </button>
      </td>
      <td>
        <button
          style={{ cursor: "auto" }}
          className={styleImmobilizeHistory.reasonImmobilizeHistoryBtn}
          data-testid={`view-button-${index}`}
        >
          { getReasonType(recent) }
        </button>
      </td>
    </tr>
  );

  return (
    <>
      <Row>
        <Col xs={2}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            placeholderAlignment="flex-start"
            okButtonPaddingEnd="pe-2"
            optionPosition="ps-2 justify-content-start"
            placeholder="Registration No."
            placeholderColor="#1F2A5D"
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#1F2A5D"
            customBackgroundColor="#EFF3F8"
            options={mappedOptions.registrationNumber}
            selectedOptions={formData.registrationNumber}
            setSelectedOptions={(data) =>
              handleOnChange({ name: "registrationNumber", value: data })
            }
            data-testid="vehicle-filter"
          />
        </Col>
        <Col xs={2}>
          <MultiSelect
            valueContainerWidthInPx="200px"
            valueContainerMaxWidthInPx="200px"
            menuListPaddingX="px-2"
            okButtonPaddingEnd="pe-2"
            placeholderAlignment="flex-start"
            placeholderColor="#1F2A5D"
            optionPosition="ps-2 justify-content-start"
            placeholder="Mobilization Status"
            isMultiSelect={true}
            indicatorSeparatorDisplay="none"
            minHeight="45px"
            valueContainerColor="#797694"
            customBackgroundColor="#EFF3F8"
            options={vehicleStatusOptionsList}
            selectedOptions={formData.status}
            setSelectedOptions={(data) =>
              handleOnChange({ name: "status", value: data })
            }
            data-testid="status-filter"
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-between">
        <div
          className="search-bar-div search-input mt-3 "
          style={{ width: "15%" }}
        >
          <FaSearch className="vob-search-icon" />
          <input
            onChange={(e) => {
              processChangeWithDebounce(e, setSearchTerm, setCurrentPage);
            }}
            className="vob-search-input search-input"
            placeholder="Search"
            data-testid="unresolved-search-input"
          />
          <div></div>
        </div>

        <div className=" d-flex  me-3 mt-4">
          <button
           onClick={() => {
            fetchHistoryImmobilizeData();
          }}
            className={styleImmobilizeHistory.dwnRefresh}
            data-testid="unresolved-export-button"
          >
            Refresh <img src="/images/refreshIcon.svg" alt="refreshIcon" />
          </button>

          <button
            onClick={() => {
              exportToExcel("Open");
            }}
            className={"dwn-btn"}
            data-testid="unresolved-export-button"
          >
            <BsDownload style={{width:'22px',height:'22px'}} />
          </button>
        </div>
      </div>

      {historyImmobilizeListData?.data?.length ? (
        <div className="bg-white position-relative px-2 py-3 mt-3" style={{
          boxShadow: '0px 3px 6px #00000029',
          border:'1px solid rgba(0, 0, 0, 0.1)',
          marginRight:'1%',
          marginBottom:'15px'
        }}>
          <Table
            responsive={true}
            borderless
            className={styleImmobilizeHistory.immobilizeHistorytable}
          >
            <thead>
              <tr className="th-border-bottom position-relative">
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Vehicle No.</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500"> OEM & Model</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Date & Time</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Device GPS</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Mobilization Status</span>
                </th>
                <th className="ps-2 text-nowrap" style={{verticalAlign:'top'}}>
                  <span className="fw500">Reason</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {historyImmobilizeListData?.data?.length > 0 && (
                <>
                  {historyImmobilizeListData?.data.map((item, index) => (
                    <DataRow key={index} recent={item} index={index} />
                  ))}
                </>
              )}
            </tbody>
          </Table>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(historyImmobilizeListData?.total / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setCurrentPage}
            data-testid="unresolved-pagination"
          />
        </div>
      ) : (
        <></>
      )}
      {!historyImmobilizeListData?.data?.length ? (
        <NoRecordFound></NoRecordFound>
      ) : (
        <></>
      )}

      {showPopup && (
        <div id="popup1" className={`popup ${showPopup ? "open" : ""}`}>
          <div
            className="popup-overlay"
            data-testid="unresolved-gps-dialog-toggle"
            onClick={togglePopup}
          ></div>
          <div className="popup-content">
            <MapAdddressByLocation
              isOpen={showPopup}
              toggleModal={togglePopup}
              CoOrdinates={coordinate}
              data-testid="unresolved-gps-dialog"
            />
          </div>
        </div>
      )}

      <Loader isLoading={isLoading} />
    </>
  );
};

ImmobilizeHistory.propTypes = {
  subscribedByCI: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
  historyImmobilizeListData: state.Immobilize.historyImmobilizeListData,
});
export default connect(mapStateToProps, {
  fetchHistoryImmobilize,
  ExportHistoryImmobilize,
})(ImmobilizeHistory);