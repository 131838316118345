import { Modal } from 'react-bootstrap'

const GPExistingGroupModal = ({
    isOpen,
    formData,
    formError,
    onChange,
    groupData,
    onClose,
    onSave
}) => {

    return (
        <Modal
            dialogClassName="border-radius-group_create"
            show={isOpen}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Body>
                <div>
                    <div
                        style={{
                            backgroundColor: "white",
                            height: 250,
                            width: 460,
                            borderRadius: 5,
                            paddingLeft: 30,
                            paddingTop: 30,
                        }}
                    >
                        <div
                            style={{
                                color: "#22046B",
                                fontWeight: "600",
                            }}
                        >
                            {groupData?.name}
                        </div>

                        <div
                            style={{
                                marginTop: 30,
                                color: "#22046B",
                                fontWeight: "600",
                            }}
                        >
                            Add in existing Sub Group
                        </div>

                        <div>
                            <select
                                style={{
                                    backgroundColor: "#D3D9F4",
                                    width: 390,
                                    height: 40,
                                    outline: "none",
                                    borderColor: "transparent",
                                    marginTop: 20,
                                    paddingLeft: 10,
                                }}
                                placeholder="Please Select"
                                onChange={(e) => onChange({ name: 'groupIndex', value: e.target.value })}
                                value={formData.groupIndex}
                                data-testid='group-select'
                            >
                                <option 
                                hidden
                                >Please Select</option>
                                {groupData.subGroup.map((e, index) => {
                                    return <option data-testid={`group-option-${e?.name}`} key={`subgroup_option_${index}`} value={index}>{e?.name}</option>;
                                })}
                            </select>
                            {formError.groupIndex && (
                                <div data-testid='group-select-error-message' className="text-danger error-message">
                                    {formError.groupIndex}
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                width: "200",
                                marginTop: 30,
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "#22046B",
                                    color: "white",
                                    width: 90,
                                    height: 40,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 5,
                                    cursor: "pointer",
                                }}
                                className={`${[null, ''].includes(formData.groupIndex) ? 'disabledbutton' : ''}`}
                                onClick={onSave}
                                disabled={[null, ''].includes(formData.groupIndex) === null}
                                data-testid='modal-group-continue-button'
                            >
                                Continue
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#E7ECF7",
                                    color: "#22046B",
                                    width: 90,
                                    height: 40,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 5,
                                    marginRight: 40,
                                    marginLeft: 20,
                                    cursor: "pointer",
                                }}
                                onClick={onClose}
                                data-testid='modal-group-cancel-button'
                            >
                                Cancel
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}

export default GPExistingGroupModal