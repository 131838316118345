import React from "react";
import normincLogo from '../../assets/darkThemeAssets/newDarkThemeNormincLogo.svg'
import { Outlet } from "react-router-dom";
import cx from 'classnames'
import styles from './DarkPublicLayout.module.css'

const DarkPublicLayout = () => {
    return (
        <div
        className={cx(styles.public_body)}
        >
            <div
            className={cx(styles.public_header)}
            >
             <img 
            src={normincLogo}
            className={cx(styles.norminc_logo)}
            alt="normincLogo"
            />       
            </div>   
            <div
            className={cx(styles.public_content)}
            >
            <Outlet />
            </div> 
        </div>
    )
}
// conflict-resolution-will be removed later

export default DarkPublicLayout