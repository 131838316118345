import React, { useState, useEffect } from 'react';
import UploadUserImageModal from '../uploadUserImage/uploadUserImageModal';
import UpdatePassword from '../../pages/User/Auth/updatepass';
import CameraIcon from '../profileIcons/cameraIcon.svg';
import { connect } from "react-redux"
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping';
import _ from 'lodash'

import './userProfile.css';
import Button from '../../shared/form/Button/Button';
import { ROLE_ID_MAP } from '../../../utils/constants';
import { UserProfile as UserProfileAction } from '../../../store/actions/user';

const getInitials = function (name = '') {
	const parts = name.split(' ')
	let initials = ''

	if (parts.length) {
		if (parts.length === 1) {
		initials = parts[0][0]
		} else {
		for (let i = 0; i < 2; i++) {
			if (parts[i].length > 0 && parts[i] !== '') {
			initials += parts[i][0]
			}
		}
		}
	}

	return initials
}

const UserProfile = ({
GetUserRoleList,
masterRoleData,
UserProfileAction,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [roleName, setRoleName] = useState('')
const [profileImage, setProfileImage] = useState('');
const [department, setDepartment] = useState('');
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [location, setLocation] = useState('');
	// react router hook

	//redux hook
	const email = localStorage.getItem('email')
	const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    UserProfileAction();
    const userRoleName = localStorage.getItem('userRoleName');
    if (userRoleName === null || userRoleName === '') {
      GetUserRoleList()
    } else {
      setRoleName(userRoleName)
    }
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
	const splittedName = _.get(user, 'name', '').split(' ')
    setFirstName(_.get(splittedName, `[0]`, ''))
    setLastName(_.get(splittedName, `[1]`, ''))
    setDepartment(user?.departmentId || '');
    setLocation(user?.location || ''); 
    const roleId = ROLE_ID_MAP[user?.userRole]
    if (roleId) {
      const roleData = masterRoleData.find(r => r._id === roleId)
      if (roleData) {
        setRoleName(roleData?.label)
      }
    }

    const profileImage = localStorage.getItem('profileImage')
    if (profileImage) {
      setProfileImage(profileImage)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  
return (
    <div className="user-profile-container">
      <h5 className='profile-heading'>My Profile</h5>
      <div className="profile-card">
        <UploadUserImageModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setImageUrl={setProfileImage} />
        <div className="card-content">
          <div className="profile-image-section">
            <div className="profile-image-container">
              {profileImage ? (
                <img
                  className="profile-image"
                  src={`${process.env.REACT_APP_HOST_API_URL}/user/image-get/${profileImage}`}
                  alt={user ? user.name : 'Profile Image'}
                />
              ) : (
                <span className='profile-image-avatar'>{getInitials(user.name)}</span>
              )}
              <img
                src={CameraIcon}
                className="camera-icon"
                alt="Camera Icon"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </div>
          <div className="profile-details-section">
          <h3 className='user-name'>{`${firstName} ${lastName}`}</h3>
            <div className='profile-email'>{`Email: ${email}`}</div>
            <div className='profile-role'>{`Role: ${roleName}`}</div>
            <div className='profile-department'>{`Department: ${department}`}</div>
            <div className='profile-location'>{`Location: ${location}`}</div>
          </div>
      <UpdatePassword isModalOpen={changePasswordModalOpen} onClose={() => setChangePasswordModalOpen(false)} />

        </div>
		<Button variant="BLUE" className="update-password-button" onClick={() => setChangePasswordModalOpen(true)}>Change Password</Button>
</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
	masterRoleData: state.getAllUserRoleReducer.masterRoleData,
});

export default connect(mapStateToProps, {
	GetUserRoleList,
  UserProfileAction,
})(UserProfile)
