import React, { useRef, useMemo, useEffect } from 'react'
import {
  FeatureGroup,
  MapContainer,
  TileLayer,
  Marker
} from 'react-leaflet'
import { Icon } from 'leaflet'
import { EditControl } from 'react-leaflet-draw'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import L from 'leaflet'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useDispatch, useSelector } from 'react-redux'
import ActionTypes from '../../../../store/actionTypes'
import './SideMap.css'
const styles = [
  // reset Maps style
  {
    backgroundColor: "#FFFFFF",
    position: "fixed",
    zIndex: 400,
    top: "15%",
    right: "2%",
    padding: 3,
    borderRadius: 4,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
  },
  {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    position: "fixed",
    zIndex: "400",
    top: "22%",
    right: "2%",
    padding: "5px 3px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  {
    backgroundColor: "#FFFFFF",
    position: "fixed",
    zIndex: 400,
    top: "32%",
    right: "2%",
    padding: 3,
    borderRadius: 4,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
  },

];
const { DRAWN_SHAPES } = ActionTypes.DIFFERENT_CREATE_FENCE_ACTIONS

const SideMap = ({ fenceDetail = null, fenceId = null, resetMap = false }) => {
  const dispatch = useDispatch()
  const sideMapRef = useRef(null)
  const sideMapFGRef = useRef(null)

  const customIcon = new Icon({
    iconUrl: require('../../../../assets/GeoFencingIcons/shapes/pinPoint.png'),
    iconRetinaUrl: require('../../../../assets/GeoFencingIcons/shapes/pinPoint.png'),
    iconSize: [30, 30],
  })

  const searchPlacesLocationIcon = new Icon({
    iconUrl: require('../../../../assets/GeoFencingIcons/shapes/locationPin.png'),
    iconRetinaUrl: require('../../../../assets/GeoFencingIcons/shapes/locationPin.png'),
    iconSize: [35, 35],
  })

  const selector = useSelector((s) => s.createFenceDifferentActions)
  const zoomLevel = selector?.zoomLevel
  const searchedCoordinates = selector?.searchedlatLong
  const searchRandomKey = selector?.randomKey

  const handle = useFullScreenHandle()

  function resetMapView() {
    sideMapRef?.current.setView([20.5937, 78.9629], 5)
  }

  const data = useMemo(() => {
    if (fenceId !== null && fenceDetail !== null) {
      return { fenceId, fenceDetail }
    }
    return null
  }, [fenceId, fenceDetail])

  useEffect(() => {
    if (resetMap || fenceId === null) {
      if (sideMapFGRef.current) {
        sideMapFGRef.current.clearLayers()
      }
      onDeleted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetMap, fenceId])

  useEffect(() => {
    if (data) {
      const geojsonFeature = {
        type: 'Feature',
        properties: { layerType: '' },
        geometry: {
          coordinates: [],
          type: 'Point',
        },
      }
      const geojsonMarkerFeature = {
        type: 'Feature',
        properties: { layerType: '' },
        geometry: {
          coordinates: [],
          type: 'Point',
        },
      }

      const fenceGeometry = data.fenceDetail.fence_geometry
      const centerCoordinates = data.fenceDetail.center_coordinates
      if (
        fenceGeometry?.coordinates?.length &&
        centerCoordinates?.length &&
        fenceGeometry?.area?.value &&
        fenceGeometry?.area?.unit &&
        fenceGeometry?.type &&
        sideMapRef?.current
      ) {
        let dataLayer
        switch (fenceGeometry.type) {
          case 'circle':
            geojsonFeature.geometry.type = 'Point'
            geojsonFeature.properties.layerType = 'circle'
            geojsonFeature.geometry.coordinates =
              fenceGeometry.coordinates.reverse()
            dataLayer = new L.GeoJSON(geojsonFeature, {
              pointToLayer: function (feature, latlng) {
                return L.circle(latlng, {
                  radius: fenceGeometry.area.value,
                  fillOpacity: 0.2,
                  opacity: 0.5,
                })
              },
            })
            break
          case 'polygon':
          case 'rectangle':
            geojsonFeature.geometry.type = 'Polygon'
            geojsonFeature.properties.layerType = fenceGeometry.type
            geojsonFeature.geometry.coordinates = [
              fenceGeometry.coordinates.map((val) => val?.reverse()),
            ]
            dataLayer = new L.GeoJSON(geojsonFeature)
            break

          default:
            return
        }

        geojsonMarkerFeature.geometry.type = 'Point'
        geojsonMarkerFeature.properties.layerType = 'marker'
        geojsonMarkerFeature.geometry.coordinates = [
          centerCoordinates[1],
          centerCoordinates[0],
        ]

        const markerLayer = new L.GeoJSON(geojsonMarkerFeature, {
          pointToLayer: function (feature, latlng) {
            return L.marker(latlng, {
              icon: customIcon,
            })
          },
        })

        sideMapFGRef.current.clearLayers()
        let i = 0
        dataLayer.eachLayer((layer) => {
          layer.feature.properties.editLayerId = i
          layer.layerType = layer.feature.properties.layerType
          sideMapFGRef.current.addLayer(layer)
          i++
        })

        let x = 10
        markerLayer.eachLayer((layer) => {
          layer.feature.properties.editLayerId = x
          layer.layerType = layer.feature.properties.layerType
          sideMapFGRef.current.addLayer(layer)
          x++
        })
        const drawnItems = sideMapFGRef?.current._layers
        const layerIds = Object.keys(drawnItems)
        for (const id of layerIds) {
          const layer = drawnItems[id]
          const layerType = layer.layerType
          saveFence({ layer, layerType })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onCreated = (e) => {
    const { layer, layerType } = e
    sideMapFGRef.current._layers[layer._leaflet_id].layerType = layerType
    const drawnItems = sideMapFGRef?.current._layers

    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (layer._leaflet_id.toString() === layerid) {
          return
        }
        if (
          (layerType === 'marker' && drawnItems[layerid]._icon) ||
          (layerType !== 'marker' && !drawnItems[layerid]._icon)
        ) {
          const layer = drawnItems[layerid]
          sideMapFGRef?.current.removeLayer(layer)
        }
      })
    }
    saveFence({ layer, layerType })
  }

  function saveFence({ layer, layerType }) {
    try {
      if (layerType === 'marker') {
        const latLong = {
          latLong: [layer?._latlng.lat, layer?._latlng.lng],
          fenceShape: 'marker',
        }
        dispatch({
          type: 'markerDrawn',
          payload: latLong,
        })
      }

      if (layerType === 'circle') {
        const getCircleRadius = () => {
          return layer.getRadius()
        }
        const latLongKeys = Object.keys(layer?._latlng)
        const latLongArray = [
          layer?._latlng[latLongKeys[0]],
          layer?._latlng[latLongKeys[1]],
        ]

        let shapeObject = {
          fenceArea: getCircleRadius(),
          unit: 'm',
          _leaflet_id: layer._leaflet_id,
          coordinates: latLongArray,
          fenceShape: layerType,
        }

        dispatch({
          type: DRAWN_SHAPES,
          payload: shapeObject,
        })
      }

      if (layerType === 'rectangle') {
        const arrayOfObjects = layer?._latlngs[0]

        const rectangleLatLongs = [
          [arrayOfObjects[0].lat, arrayOfObjects[0].lng],
          [arrayOfObjects[1].lat, arrayOfObjects[1].lng],
          [arrayOfObjects[2].lat, arrayOfObjects[2].lng],
          [arrayOfObjects[3].lat, arrayOfObjects[3].lng],
        ]

        const bounds = layer.getBounds()
        let northBounds = bounds.getNorthWest()
        let southBounds = bounds.getSouthEast()

        const width = northBounds.distanceTo(
          L.latLng([northBounds.lat, southBounds.lng])
        )
        const height = northBounds.distanceTo(
          L.latLng([southBounds.lat, northBounds.lng])
        )
        const area = width * height
        const unit = area > 10000 ? 'h' : 'sqm'
        const unitArea = unit === 'sqm' ? area : area / 10000

        const shapeObject = {
          fenceArea: unitArea,
          unit: unit,
          _leaflet_id: layer._leaflet_id,
          coordinates: rectangleLatLongs,
          fenceShape: layerType,
        }
        dispatch({
          type: DRAWN_SHAPES,
          payload: shapeObject,
        })
      }

      if (layerType === 'polygon') {
        const latLngs = layer.getLatLngs()[0]
        const area = L.GeometryUtil.geodesicArea(latLngs)
        const unit = area > 10000 ? 'h' : 'sqm'
        const unitArea = unit === 'sqm' ? area : area / 10000

        let polygonArrayLatLongs = []

        for (const key in latLngs) {
          let array = [latLngs[key].lat, latLngs[key].lng]
          polygonArrayLatLongs.push(array)
        }

        const shapeObject = {
          fenceArea: unitArea,
          unit: unit,
          _leaflet_id: layer._leaflet_id,
          coordinates: polygonArrayLatLongs,
          fenceShape: layerType,
        }

        dispatch({
          type: DRAWN_SHAPES,
          payload: shapeObject,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onEdited = (e) => {
    const drawnItems = sideMapFGRef?.current._layers
    const layerIds = Object.keys(e.layers._layers)
    for (const id of layerIds) {
      const layer = drawnItems[id]
      const layerType = layer.layerType
      saveFence({ layer, layerType })
    }
  }

  const onDeleted = (e) => {
    const obj = {
      fenceShape: 'marker',
      latLong: [],
    }
    dispatch({
      type: 'markerDrawn',
      payload: obj,
    })
    dispatch({
      type: 'GET_ADDRESS_BY_LOCATION',
      payload: null,
    })
    dispatch({
      type: DRAWN_SHAPES,
      payload: {},
    })
  }

  return (
    <>
      <FullScreen key={'gsm-map-fs'} handle={handle}>
        <div key={searchRandomKey}>
          <MapContainer
            style={{
              height: '100vh',
              width: '100%',
            }}
            maxZoom={22}
            maxNativeZoom={22}
            minZoom={5}
            center={[searchedCoordinates.lat, searchedCoordinates.lng]}
            zoom={zoomLevel}
            ref={sideMapRef}
            zoomControl={false}
            attributionControl={false}
            id="gsm_id"
          >
            <TileLayer
                        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                        subdomains={['mt0','mt1','mt2','mt3']}    
                        maxNativeZoom={22}    
                        maxZoom={22}                       
                    />
            { (searchedCoordinates.lat !== 20.5937 && searchedCoordinates.lng !== 78.9629) ? <Marker
              position={[searchedCoordinates.lat, searchedCoordinates.lng]}
              icon={searchPlacesLocationIcon}
            ></Marker> : null}
            <FeatureGroup ref={sideMapFGRef}> 
              <EditControl
                className="geofence-sm-edit-control"
                position="topright"
                onCreated={onCreated}
                onEdited={onEdited}
                onDeleted={onDeleted}
                draw={{
                  marker: { icon: customIcon },
                  circlemarker: false,
                  polyline: false,
                }}
              />
            </FeatureGroup>
            
            <>
                <span
                  style={styles[0]}
                  onClick={() => {
                    resetMapView();
                  }}
                  data-toggle="tooltip"
                  title="Reset"
                  data-testid='map-reset-button'
                >
                  <img alt="resetBtn" src="/images/svgicon/map-reset.svg" />
                </span>

                <div style={styles[1]}>
                  <img
                    data-toggle="tooltip"
                    title="zoom in"
                    alt="downloadBtn"
                    src="/images/svgicon/map-zoom-in.svg"
                    onClick={() => {
                      sideMapRef?.current?.setZoom(
                        sideMapRef?.current.getZoom() + 1
                      );
                    }}
                    data-testid='map-zoomin-button'
                  />

                  <img
                    data-toggle="tooltip"
                    title="zoom out"
                    alt="downloadBtn"
                    src="/images/svgicon/map-zoom-out.svg"
                    onClick={() => {
                      sideMapRef?.current?.setZoom(
                        sideMapRef?.current.getZoom() - 1
                      );
                    }}
                    data-testid='map-zoomout-button'
                  />
                </div>

                <span
                  style={styles[2]}
                  onClick={() => {
                    if (handle.active) {
                      handle.exit();
                    } else {
                      handle.enter();
                    }
                  }}
                  data-testid='map-fullscreen-button'
                >
                  <img 
                    handle={handle}
                    data-toggle="tooltip"
                    title={handle.active ? "exit full screen" : "full screen"}
                    style={{ height: "24px" }}
                    alt="resetBtn"
                    src={
                      "/images/svgicon/" +
                      (handle.active
                        ? "full-screen-exit.svg"
                        : "map-fullscreen.svg")
                    }
                  />
                </span>
              </>
          </MapContainer>
        </div>
      </FullScreen>
    </>
  )
}

export default SideMap
