import React from "react";
import styles from "./Loader.module.css";

export default function Loader({ isLoading = true }) {
  return (
    <>
      <div className={`${styles.nd_dark_loader_container} ${isLoading ? '' : styles.hidden}`}></div>
      <div className={`${styles.nd_dark_loader_box} ${isLoading ? '' : styles.hidden}`}>
        <div className={styles.loaderContainer}>
          <div className={`${styles.square} ${styles.purple}`} id={styles.square1}></div>
          <div className={`${styles.square} ${styles.purple}`} id={styles.square2}></div>
          <div className={`${styles.square} ${styles.purple}`} id={styles.square3}></div>
          <div className={`${styles.square} ${styles.purple}`} id={styles.square4}></div>
        </div>
      </div>
    </>
  );
}
