import ActionTypes from '../actionTypes'
const vehicleOnboardingActionTypes = ActionTypes.VEHICLE.VEHICLE_ONBOARDING

const initialState = {
  allOnboardingLogsData: [],
  logsCount: 0,
  isUploadApiSuccess: false,
  vehicleOnboardingApiError: null,
  onBoardingVehiclesData: [],
  onBoardingVehiclesCount: 0,
}

const vehOnboardingReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case vehicleOnboardingActionTypes.VEHICLE_ONBOARD_UPLOAD_ERROR:
    case vehicleOnboardingActionTypes.BULK_VEHICLE_ONBOARDED_UPDATE_ERROR:
      return {
        ...state,
        vehicleOnboardingApiError: payload,
      }
    case vehicleOnboardingActionTypes.VEHICLE_ONBOARD_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploadApiSuccess: payload,
      }
    case vehicleOnboardingActionTypes.GET_ALL_ONBOARDING_LOGS_SUCCESS:
      return {
        ...state,
        allOnboardingLogsData: payload.rows,
        logsCount: payload.count,
      }
    case vehicleOnboardingActionTypes.GET_ALL_ONBOARDING_VEHICLES_SUCCESS:
      return {
        ...state,
        onBoardingVehiclesData: payload,
        onBoardingVehiclesCount: payload.length,
      }
    case vehicleOnboardingActionTypes.GET_ALL_ONBOARDING_VEHICLES_API_ERROR:
      return {
        ...state,
        onBoardingVehiclesData: [],
        onBoardingVehiclesCount: 0,
      }
    default:
      return state
  }
}

export default vehOnboardingReducer