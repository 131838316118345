import React, { useState } from 'react';
import styles from './SidebarFilter.module.css'

const GroupDropdown = ({ groups, onClick, selected , filteredVehicleData }) => {
  const [openGroups, setOpenGroups] = useState({});
  const [hoverArrowColor, setHoverArrowColor] = useState(false);

  const toggleGroup = (groupId) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  };

  const handleGroupClick = (group) => {
    const isSelected = selected?.includes(group.name);
    
    // Gather all subgroup names
    const subGroupNames = group?.subGroup?.map(subGroup => subGroup.name);

    console.log(subGroupNames);
  
    if (isSelected) {
      // Deselect group and all its subgroups
      onClick(selected.filter((item) => item !== group.name && !subGroupNames?.includes(item)));
    } else {
      // Select group and all its subgroups
      onClick([...selected, group.name, ...subGroupNames]);
    }
  };
  

  const handleSubGroupClick = (name) => {
    const isSelected = selected?.includes(name);
    if (isSelected) {
      onClick(selected.filter((item) => item !== name));
    } else {
      onClick([...selected, name]); 
    }
  };

  const handleSelectAllClick = () => {
	const safeSelected = Array.isArray(selected) ? selected : [];
  
	const allGroupNames = groups.map((group) => group.name);
	const areAllGroupsSelected = allGroupNames.every((name) => safeSelected.includes(name));
  
	if (areAllGroupsSelected) {
	  onClick(safeSelected.filter((name) => !allGroupNames.includes(name)));
	} else {
	  onClick([...safeSelected, ...allGroupNames]);
	}
  };

 
 
const updateGroupAndSubGroupCounts = (group, filteredVehicleData) => {
  
  const countMatchingVehicles = (vehicles) => vehicles.filter(vehicle => 
      filteredVehicleData.some(filteredVehicle => filteredVehicle.rn === vehicle)
  ).length;
  
  const groupVehicleCount = countMatchingVehicles(group.vehicles);
 
  const updatedSubGroups = group.subGroup.map(subGroup => {
      const subGroupVehicleCount = countMatchingVehicles(subGroup.vehicles);
      return {
          ...subGroup,
          count: subGroupVehicleCount
      };
  });

  return {
      ...group,
      count: groupVehicleCount,  
      subGroup: updatedSubGroups 
  };
};



const filteredGroup = groups.map(group => updateGroupAndSubGroupCounts(group, filteredVehicleData));

const isAllSelected =  groups.every((group) => selected?.includes(group.name)) ;



  return (
    <div className='nd__map_location'>
      <div className='nd__select-all-group'>
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={handleSelectAllClick}
          className={`custom-checkbox ${isAllSelected ? "checked" : ""}`}
          style={{
            margin: "11px 6px",
            width: "11.5px",
            height: "11.5px",
            appearance: "none",
            backgroundColor: isAllSelected ? "#39FFFF" : "transparent",
            border: "1px solid #39FFFF",  
            borderRadius: "2px",
          }}
        />
        <p className={`${styles.selectAllLabel}`}>Select All</p>
      </div>

      {filteredGroup?.map((group) => (
        <div key={group.id} className='nd__render-location-list nd_group-sub-list'>
          <div
             className={`nd__location-range ${selected?.includes(group.name) ? 'selected' : ''}`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleGroupClick(group)}
            onMouseOver={() => setHoverArrowColor(group.name)}
            onMouseOut={() => setHoverArrowColor(null)}
          >
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <input
                  type="checkbox"
                  checked={selected?.includes(group.name)}
                  onChange={() => handleGroupClick(group)}
                  className={`custom-checkbox ${selected?.includes(group.name) ? "checked" : ""}`}
                  style={{
                    marginRight: "8px",
                    position: 'relative',
                    top: '2px',
                    width: "11.5px",
                    height: "11.5px",
                    appearance: "none",
                    backgroundColor: selected?.includes(group.name) ? "#39FFFF" : "transparent",
                    border: "1px solid #39FFFF",  
                    borderRadius: "2px",
                  }}
                />
              <p 
              // style={{ color: '#FFFFFF', display: 'flex', alignItems: 'baseline', width: '138px' }}
              style={{
                color: '#FFFFFF',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '120px'
              }}
              >
                {group.name}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <p className='location-count group-filter-count' style={{
                width: group?.subGroup?.length > 0 ? '52px' : '78px',
                }}>
                <span style={{ color: '#fff', marginRight: '5px' }}>----</span>{group.count}
              </p>

              {/* Toggle Subgroup Icon */}
              {group?.subGroup?.length > 0 && (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleGroup(group._id); 
                  }}
                >
                  {openGroups[group._id] ? (
                    <>
                      {selected?.includes(group.name) || hoverArrowColor === group?.name ? (
                        <img
                          src="/images/rightSideBar/upArrowLight.svg"
                          alt="up-arrow"
                          className='groupArrowIcon'
                        />
                      ) : (
                        <img
                          src="/images/rightSideBar/upArrowDark.svg"
                          alt="up-arrow"
                          className='groupArrowIcon'
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {selected?.includes(group.name) || hoverArrowColor === group?.name ? (
                        <img
                          src="/images/rightSideBar/downArrowLight.svg"
                          alt="down-arrow"
                          className='groupArrowIcon'
                        />
                      ) : (
                        <img
                          src="/images/rightSideBar/downArrowDark.svg"
                          alt="down-arrow"
                          className='groupArrowIcon'
                        />
                      )}
                    </>
                  )}
                </div>
              )}


            </div>
          </div>

          {/* Subgroup Dropdown */}
          {openGroups[group._id] && group.subGroup.length > 0 && (
            <div className='nd_subgroup-border-style'>
              {group?.subGroup?.map((subGroup) => (
                <div
                  key={subGroup._id}
                  className={`nd_subgroup-range ${selected?.includes(subGroup.name) ? 'selected' : ''}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding:'6px 6px',
                    background: selected?.includes(subGroup.name)
                      ? 'linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24))'
                      : undefined,
                  }}
                  onClick={() => handleSubGroupClick(subGroup.name)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      checked={selected?.includes(subGroup.name)}
                      onChange={() => handleSubGroupClick(subGroup.name)}
                      className={`custom-checkbox ${selected?.includes(subGroup.name) ? "checked" : ""}`}
                      style={{
                        marginRight: '8px',
                        width: "11.5px",
                        height: "11.5px",
                        appearance: "none",
                        backgroundColor: selected?.includes(subGroup.name) ? "#39FFFF" : "transparent",
                        border: "1px solid #39FFFF",  
                        borderRadius: "2px",
                      }}
                    />
                    <p  style={{
                          color: '#FFFFFF',
                          marginBottom: '0px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '80px'
                        }}>
                      {subGroup.name}
                    </p>
                  </div>
                  <p className='location-count sub-group-count'>
                    <span style={{ color: '#fff', marginRight: '5px' }}>----</span>{subGroup.count}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GroupDropdown;
