
import ActionTypes from "../actionTypes";
const userActionTypes = ActionTypes.GET_USER;

const initialState = {

  createdOn: "",
  email: "",
  firstName: "",
  mobileNumber: "",
  lastName: "",
  location : "",
  userRole:[],
  userId: ''
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case userActionTypes.USER_DETAILS_SUCCESS:
      return {
        ...state,
          createdOn: payload.createdOn,
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          mobileNumber: payload.mobileNumber,
          userRole: payload.userRole,
          location : payload.location,
          userId: payload._id
      };
    default:
      return state;
  }
};

export default userReducer;