const initialState = {
    isTrue: false,
    socFilter: ''
  };
  
  const socDropdownReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case "socClicked":
        return {
          ...state,
          isTrue: payload.isTrue,
          socFilter: payload.filter
        };
      default:
        return state;
    }
  };
  
  export default socDropdownReducer;
  