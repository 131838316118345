import ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'

export async function exportToExcel(options) {
  const { data, headers, fileName, sheetName } = options
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(sheetName || 'Sheet 1') // Use provided sheet name or default to 'Sheet 1'
  // Set column headers
  worksheet.columns = headers
  // Add data rows
  data.forEach((item) => {
    worksheet.addRow(item)
  })
  workbook.xlsx.writeBuffer().then(function (buffer) {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    FileSaver.saveAs(blob, fileName)
  })
}