let initialState = {
    selectedCity : ""
}

const cityLocationSelectReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case "location_select":
        return {
          ...state,
          selectedCity: payload,
        };
      default:
        return state;
    }
  };
  
  export default cityLocationSelectReducer;