const validationService = {};

// required field validation
validationService.required = (value) => {
    let isValid = 1;
    let message = '';
  
    if (
      typeof value === 'object' &&
      value !== null &&
      value !== undefined &&
      !Object.values(value).length
    ) {
      message = 'This field is required';
      isValid = 0;
    }
  
    if ([null, undefined, ''].includes(value)) {
      isValid = 0;
      message = 'This field is required';
    }
  
    return { isValid, message };
  };
  
  // email field validation
  validationService.email = (value) => {
    let isValid = 1;
    let message = '';
  
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(value)) {
      message = 'Please enter valid Email ID';
      isValid = 0;
    }
  
    return { isValid, message };
  };

  // contact no field validation
  validationService.mobile = (value) => {
    let isValid = 1;
    let message = '';
  
    
    const mobileRegex = /^\d{10}$/;
  
 
    if (!mobileRegex.test(value)) {
      message = 'Mobile number must be exactly 10 digits';
      isValid = 0;
    }
  
    return { isValid, message };
  };

  // array validation
  validationService.array = (value = []) => {
    let isValid = 1;
    let message = '';

    if (isValid && !value.length) {
      message = 'You have to select at least one item.';
      isValid = 0;
    }

    return { isValid, message };
  };

  validationService.maxLength = (value, maxLength) => {
    let { isValid, message } = validationService.required(value)

    if (isValid && value.length > maxLength) {
      message = `Field value must have a length less than or equal to ${maxLength}`
      isValid = 0
    }

    return { isValid, message }
  }

export default validationService
/*conflict-resolution-will be removed later*/