import ActionTypes from '../../actionTypes'
const mapActionType = ActionTypes.MAP

const initialState = {
  address : '',
  city: '',
  dmsLatLng: {
    dmsLat: 0,
    dmsLng: 0
  },
  previousCoordinates : {
    lat:0,
    lng:0
  }
}

const latlongToAddressReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case mapActionType.GET_ADDRESS_BY_LOCATION:
      if (!payload) {
        return initialState
      }
      return {
        ...state,
        address: payload.response.address,
        city: payload.response.city,
        previousCoordinates : payload.coordinates,
        dmsLatLng: {
          dmsLat: payload.response.dmsLat,
          dmsLng: payload.response.dmsLng
        }
      }
    default:
      return state
  }
}

export default latlongToAddressReducer
