import { secondsToHoursAndMinutesHHMM , secondsToHours, secondsToRemainingMinutesAfterHour} from '../../../utils/common-methods'
import { ChargingTypeShades, CityChartColorShades, OEMChartColorShades, CHART_COLOR_CODES } from '../../../utils/constants'
import ActionTypes from '../../actionTypes'
const { GET_CHARGING_ANALYTICS_DATA_FAIL, GET_CHARGING_ANALYTICS_DATA_SUCCESS,
    GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS, GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_FAIL,
    IS_CHART_LOADING, IS_MODAL_LOADING, IS_EXPORT_FILE_LOADING } = ActionTypes.REPORTS.CHARGING_ANALYTICS

const initialState = {
    isChartLoading : false,
    isModalLoading: false,
    isExportFileLoading: false,
    pieChartData: null,
    areaChartData: null,
    dataLevel : null,
    modalData: {
        rows: [],
        count: 0
    }
}

const defaultAreaChartData = {
    dataType: "",
    title: "", //can be used as radio button text
    checkBoxTitle: "",
    dataLevel: null,
    colorLevel: null,
    chartData: null,
    xDataKey: "formattedDateTime",
    xLabel: "Time",
    yLabel: "No. of Sessions",
    interval: 0,
    height: "100%",
    width: "80%",
    gridLine: {
        horizontal: false,
        Vertical: true,
    },
    isColorShadingEnable: true,
}

const defaultPieChartData = {
    title: "",
    data: null,
    colors: null,
    labelType: "",
    selectedLabelType: "",
    totalValue: 0
}

const chargingAnalyticsReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_CHARGING_ANALYTICS_DATA_SUCCESS:
            return {
                ...state,
                pieChartData : getPieChartData(payload),
                areaChartData : getAreaChartData(payload),
            }
        case GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS:
            return {
                ...state,
                modalData: payload
            }
        case GET_CHARGING_ANALYTICS_DATA_FAIL:
            return {
                ...state,
                areaChartData : getAreaChartData(payload),
                pieChartData : getPieChartData(payload),
            }
        case GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_FAIL:
            return {
                ...state,
                modalData: initialState.modalData
            }
        case IS_CHART_LOADING:
            return {
                ...state,
                isChartLoading : payload
            }
        case IS_MODAL_LOADING:
            return {
                ...state,
                isModalLoading: !state.isModalLoading,
            }
        case IS_EXPORT_FILE_LOADING:
            return {
                ...state,
                isExportFileLoading: !state.isExportFileLoading,
            }
        default:
            return {
                ...state
            }
    }
}

const getPieChartData = (chartData) => {
    return [
        {
            chartName: "Charging Session Count",
            charts: [
                generatePieChartData(chartData,"OEM & Model", "countByOemAndModel", "oemAndModel", OEMChartColorShades),
                generatePieChartData(chartData,"City", "countByCity", "city", CityChartColorShades),
                generatePieChartData(chartData,"Charging Type", "countByChargingType", "chargingType", ChargingTypeShades),
            ] 
        },
        {
            chartName: "Energy Charged",
            charts: [
                generatePieChartData(chartData,"OEM & Model", "ecByOemAndModel", "oemAndModel", OEMChartColorShades),
                generatePieChartData(chartData,"City", "ecByCity", "city", CityChartColorShades),
            ]
        },
        {
            chartName: "Charging Session Duration",
            charts: [
                generatePieChartData(chartData,"OEM & Model", "durationByOemAndModel", "oemAndModel", OEMChartColorShades),
                generatePieChartData(chartData,"City", "durationByCity", "city", CityChartColorShades),
            ]
        },
        {
            chartName: "Average Charging Duration per Session",
            charts: [
                generatePieChartData(chartData,"Normal", "adByNormalCharging", "oemAndModel", OEMChartColorShades),
                generatePieChartData(chartData,"Fast", "adByFastCharging", "oemAndModel", OEMChartColorShades),
            ]
        }
    ]
}

const getAreaChartData = (chartData) => {
    return [
        {
            ...defaultAreaChartData,
            dataType: "OEM-MODEL",
            title: "OEM & Model",
            checkBoxTitle: "OEMs & Models",
            dataLevel: getColorWiselevel(chartData,"countByOemAndModel", "oemAndModel"),
            colorLevel: OEMChartColorShades,
            chartData: chartData?.areaChartDataByOemAndModel,
        },
        {
            ...defaultAreaChartData,
            dataType: "CITY",
            title: "City",
            checkBoxTitle: "Cities",
            dataLevel: getColorWiselevel(chartData,"countByCity", "city"),
            colorLevel: CityChartColorShades,
            chartData: chartData?.areaChartDataByCity,
        }
    ]
}

const getColorWiselevel = (chartData,key, field) => {
    return chartData && chartData[key]?.data?.map((level, index) => {
        return {
            key: level[field],
            value: `level-${index}`,
            chartColor: CHART_COLOR_CODES[level[field]]
        }
    })
}

const generatePieChartData = (chartData, title, key, field, color) => {
    return chartData ? {
        ...defaultPieChartData,
        title: title,
        data: chartData[key].data?.sort((v1, v2) => { return v2.value - v1.value })?.map(data => {
            data.text = (key === "durationByOemAndModel" || key === "durationByCity" || key === "adByNormalCharging" || key === "adByFastCharging") ?
                secondsToHoursAndMinutesHHMM(Math.round(data.value)) : `${data.value}`
            data.name = data[field]
            data.pieColor = CHART_COLOR_CODES[data.name]
            return data
        }),
        colors: color,
        labelType: chartData[key].unit?.toLowerCase() === 'nos' ? '' : chartData[key].unit,
        selectedLabelType: chartData[key].unit,
        totalValue: (key === "durationByOemAndModel" || key === "durationByCity" || key === "adByNormalCharging" || key === "adByFastCharging") ?
            (secondsToHours(Math.round(chartData[key].totalValue)) + " Hrs") : chartData[key].totalValue,
        line2: (key === "durationByOemAndModel" || key === "durationByCity" || key === "adByNormalCharging" || key === "adByFastCharging") ?
            (secondsToRemainingMinutesAfterHour(Math.round(chartData[key].totalValue)) + " Mins") : ""
    } : {
        ...defaultPieChartData,
        title: title
    };
}



export default chargingAnalyticsReducer