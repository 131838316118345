import { Buffer } from 'buffer';
import api from "../../service/api";
import setAuthToken from "../../service/setAuthToken.lib";
import ActionTypes from "../actionTypes";
import API_BOOK from "../../service/endpoints";
import { CipherGenerator, encrypt } from "../../utils/crypto";
import { customToast, errorToast } from '../../utils/toasts'
import { clearLocalStorageExceptclientIP } from '../../utils/common-methods';
// import { ToggleLoader } from '../../service/interceptor';
const authActionTypes = ActionTypes.AUTH;
const getTokenApiConfig = API_BOOK.GET_TOKEN;
const getRefreshTokenConfig = API_BOOK.GET_REFRESH_TOKEN;
const { GET_CUSTOMER_ADDRESS_SUCCESS, GET_CUSTOMER_ADDRESS_FAILED,CONFIRM_CUSTOMER_ADDRESS_SUCCESS, CONFIRM_CUSTOMER_ADDRESS_FAILED, } = ActionTypes.AUTH;


/* Action dispatchers starts here */ 

const login = (payload) => ({
  type: authActionTypes.LOGIN_SUCCESS,
  payload,
});

const logout = () => ({
    type: authActionTypes.LOGOUT
  });

/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get auth token action
 *  @author       Deepak Prajapati
 *  @api          /auth/gettoken
 *  @method       POST
 *  @createdDate  5-may-2023
 *  @modifiedDate 5-May-2023
 **/
export const AuthenticateUser = ({ emailOrMobileNumber, password, navigate, navigateFrom, rememberMe = false }) => async (dispatch) => {
  try {
    const hashPassword = CipherGenerator(password)
    clearLocalStorageExceptclientIP()
    const resp = await api({
      method: getTokenApiConfig.method,
      url: getTokenApiConfig.url,
      data: { emailOrMobileNumber, password: hashPassword }
    });

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      let tokenPayload = response?.accessToken.slice(response?.accessToken.indexOf('.') + 1)
        tokenPayload = JSON.parse(
          Buffer.from(
            tokenPayload.slice(0, tokenPayload.indexOf('.')),
            'base64'
          ).toString('binary')
        )      
      const sessionExpiryDateTime = new Date(tokenPayload?.exp * 1000)
      response.sessionExpiryDateTime = sessionExpiryDateTime
      setAuthToken({ token: response?.accessToken, refreshToken: response?.refreshToken, user: response?.user })
      

      if (rememberMe) {
          const encryptedEmail = encrypt(emailOrMobileNumber)
          const encryptedPassword = encrypt(password)
          if (encryptedEmail && encryptedPassword) {
            localStorage.setItem('secretX', encryptedEmail)
            localStorage.setItem('secretY', encryptedPassword)
          }
      }
      
      dispatch(login(response));

      if(typeof(navigate) === 'function'){
        navigate(navigateFrom, { replace: true });
      }
      if (resp.data?.data?.showResetPasswordWarning) {
        setTimeout(() => {
          customToast({ message: 'Your password will expire soon, please reset', svgImageName: 'warning', duration: 6000 })
        }, 3000)
      }
    }
    
    if (resp.status === 401 && resp.data.error === 'User not registered') {
      setTimeout(() => {
        customToast({ message: resp.data.error, svgImageName: '400_status', isSubTitleReq: true, svgImageName2: 'kindlyregister' })
      }, 400)
    }

    if (resp.status === 401 && resp.data.error === 'Your registraion request is declined for RA approval, kindly contact respective RA or IT support team.') {
      setTimeout(() => {
        customToast({ message: 'Access request declined', svgImageName: 'decline', secondaryMessage: 'Please contact your approver' })
      }, 400)
    }

    if (resp.status === 401 && resp.data.error === 'Your registration request is pending for RA approval, kindly contact respective RA or IT support team.') {
      setTimeout(() => {
        customToast({ message: 'Waiting For Approval', svgImageName: 'waiting', secondaryMessage: 'Please contact your approver' })
      }, 400)
    }

    if(resp.status === 401 && resp.data.error === 'Enter valid email and password') {
      setTimeout(() => {
        customToast({ message: 'Enter valid email and password', svgImageName: 'decline' })
      }, 400)
    }

    if(resp.status === 401 && resp.data.error === 'User account is inactive') {
      setTimeout(() => {
        customToast({ message: 'User account is inactive', svgImageName: 'decline' })
      }, 400)
    }

    if(resp.status === 401 && resp.data.error === 'Too many failed attempts, User account is locked, please try again after sometime.') {
      setTimeout(() => {
        customToast({ message: 'Too many failed attempts, User account is locked, please try again after sometime.', svgImageName: 'decline' })
      }, 400)
    }

    if(resp.status === 401 && resp.data.error === 'User account is locked, please try again after sometime.') {
      setTimeout(() => {
        customToast({ message: 'Too many failed attempts, User account is locked, please try again after sometime.', svgImageName: 'decline' })
      }, 400)
    }

    if(resp.status === 401 && resp.data.error === 'User password is expired, please reset new password') {
      setTimeout(() => {
        customToast({ message: 'Password expired, Please reset new password', svgImageName: 'decline' })
      }, 400)
    }

    if (resp.status === 400 && resp.data.error.length) {
      const message = resp.data.error[0]?.message
      setTimeout(() => {
        customToast({ message, svgImageName: '400_status' })
      }, 400)
    }

    if (resp.status === 401 && resp.data.error) {
      setTimeout(() => {
        customToast({ message: resp.data.error, svgImageName: 'decline' })
      }, 400)
    }

    return resp

  } catch (error) {
    console.log("error:", error);
  }
};


/**
 *  @desc         Logout action
 *  @author       Deepak Prajapati
 *  @createdDate  5-may-2023
 *  @modifiedDate 5-May-2023
 **/
export const Logout = () => async (dispatch) => {
     await dispatch(logout())
     clearLocalStorageExceptclientIP()
     window.location.reload()
}

/**
 *  @desc         Get refresh auth token action
 *  @author       Amir Shaikh
 *  @api          /auth/refresh
 *  @method       POST
 *  @createdDate  22-jan-2024
 *  @modifiedDate 22-jan-2024
 **/
export const refreshAuth = async() => {
  try {
    const userId = JSON.parse(localStorage.getItem('user'))?._id;
    const res = await api({
      method: getRefreshTokenConfig.method,
      url: getRefreshTokenConfig.url,
      headers: {
        'x-refresh-token': localStorage.getItem('rtoken'),
      },
      data: {
        userId
      }
    })
    if (res && res?.data?.data && res.status === 200) {
      const response = res.data.data;
      let tokenPayload = response?.accessToken.slice(response?.accessToken.indexOf('.') + 1)
      tokenPayload = JSON.parse(
        Buffer.from(
          tokenPayload.slice(0, tokenPayload.indexOf('.')),
          'base64'
        ).toString('binary')
      )
      const sessionExpiryDateTime = new Date(tokenPayload?.exp * 1000)
      response.sessionExpiryDateTime = sessionExpiryDateTime
      setAuthToken({ token: response?.accessToken, refreshToken: response?.refreshToken, user: response?.user })
    } else {
      errorToast({ message: res.message, duration: 1500 })
      clearLocalStorageExceptclientIP()
      window.location = '/login'
    }
  } catch (error) {
    console.log(error)
    errorToast({ message: error.message, duration: 1500 })
    clearLocalStorageExceptclientIP()
    window.location = '/login'
  }
}

/**
 *  @desc         Verify email of user action
 *  @author       Amir Shaikh
 *  @api          /user/verify-email
 *  @method       POST
 *  @createdDate  20-mar-2024
 *  @modifiedDate 20-mar-2024
 **/
export const verifyEmail = async ({ email, purpose }) => {
  try {
    let url = API_BOOK.VERIFY_EMAIL.url
    if (purpose) {
      url += `?purpose=${purpose}`
    }
    const res = await api({
      method: API_BOOK.VERIFY_EMAIL.method,
      url,
      data: {
        email
      }
    })
    return res;
  } catch (error) {
    console.log('verify user api failed :', error)
  }
}

/**
 *  @desc        Get user's address API
 *  @author       Sanjana Manimaran
 *  @api         /user/get-address
 *  @method       POST
 *  @createdDate  26-Mar-2024
 *  @modifiedDate 26-Mar-2024
 **/

export const getAddressSuccess = (payload) => ({
  type: GET_CUSTOMER_ADDRESS_SUCCESS,
  payload,
});

export const getAddressFailed = () => ({
  type: GET_CUSTOMER_ADDRESS_FAILED,
});

export const getUsersAddress = async (email) => {
  try {
    const res = await api({
      url: API_BOOK.GET_CUSTOMER_ADDRESS.url,
      method: API_BOOK.GET_CUSTOMER_ADDRESS.method,
      data: {
        email,
      }
    });
    return res.data
  } catch (error) {
    console.log("getUsersAddress error", error);
    throw error;
  }
};


/**
 *  @desc         Confirm Customers Address
 *  @author       Sanjana Manimaran
 *  @api          /customers/confirm-customer-address
 *  @method       POST
 *  @createdDate  26-Mar-2024
 *  @modifiedDate 26-Mar-2024
 **/

export const confirmCustomerAddressSuccess = (payload) => ({
  type: CONFIRM_CUSTOMER_ADDRESS_SUCCESS,
  payload,
});

export const confirmCustomerAddressFailed = () => ({
  type: CONFIRM_CUSTOMER_ADDRESS_FAILED,
});

export const confirmCustomerAddress = async (_id) => {
  try {
    const res = await api({
      url: API_BOOK.CONFIRM_CUSTOMER_ADDRESS.url,
      method: API_BOOK.CONFIRM_CUSTOMER_ADDRESS.method,
      data: {
        _id,
      }
    });
    return res.data;
  } catch (error) {
    console.log("confirmCustomerAddress error", error);
    throw error;
  }
};

/* Actions ends here */ 