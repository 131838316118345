import React, { useEffect, useState } from "react";
import Table from "../../../../shared/table/Table";
import { useDispatch, useSelector } from "react-redux";
import styles from "../UserControl/userControl.module.css";
import moment from "moment";
import Pagination from "../../../../shared/pagination/Pagination";
import cx from 'classnames'
import editBtn from '../../../../../assets/darkThemeAssets/editButtonLightBlue.svg'
import { ApproveDepartmentUserRequest, DeclineDepartmentUserRequest, PendingDepartmentUsers, AssignTemplateToUserApprovalRequest } from "../../../../../store/actions/department/department";
import PendingRequestModal from "../../DepartmentAdminManage/Modal";
import InformativeModal from "../../../../shared/modals/InformativeModal/InformativeModel";
import Popover from "../../../../shared/modals/Popover/Popover";
import SuccessFlashMessage from '../../../../shared/modals/FlashMessage/SuccessFlashMessage'
import EditDuUserApproval from "./EditDuUserApproval";
import AssignTemplateToUser from "../Pending Requests/AssignTemplate/AssignTemplateToUser"
import departmentTemplateAction from "../../../../../store/actions/department/departmentTemplate";

const DAPendingRequests = () => {

  //variables
    const ITEMS_PER_PAGE = 8;

  const columns = [
    {
      key: "rId",
      header: "USER ID",
      sortable: true,
      sortFieldName: "rId",
      renderCell: (row) => <>{row.rId}</>,
    },
    {
      key: "name",
      header: "NAME",
      sortable: true,
      sortFieldName: "name",
      renderCell: (row) => <>{row?.name}</>,
    },
    {
      key: "createdAt",
      header: "CREATED ON",
      sortable: true,
      sortFieldName: "createdAt",
      renderCell: (row) => <>{moment(row?.createdAt).format("DD MMM YYYY")}</>,
    },
    {
      key: "email",
      header: "EMAIL ID",
      sortable: true,
      sortFieldName: "email",
      renderCell: (row) => <>{row?.email}</>,
    },
    {
      key: "departmentName",
      header: "DEPARTMENT",
      sortable: true,
      sortFieldName: "departmentName",
      renderCell: (row) => <>{row?.departmentName}</>,
    },
    {
      key: "location",
      header: "LOCATION",
      sortable: true,
      sortFieldName: "location",
      renderCell: (row) => <>{row?.location}</>,
    },
    {
      key: "accessTemplate",
      header: "ACCESS",
      sortable: false,
      sortFieldName: "accessTemplate",
      renderCell: (row) => {
        return (
          <>
          {
            row?.assingedTemplateId &&
            <> 

              <span
              style={{
                color: "#39FFFF",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
         {row?.assignedTemplate}
            </span>
            <Popover
              triggerComponent={
                <button className="row-btn">
                  <img
                    src="/images/tooltipInfo.svg"
                    alt="info"
                    className="alert-history-info"
                  />
                </button>
              }
              bodyComponent={
                <div className={`${styles.tooltip_inner_container}`}>
                  {row?.modules?.map((module) =>  <div key={module?.moduleId}> {module?.moduleName} </div>   )}
                </div>
              }
              trigger={["hover", "focus"]} // Include both "hover" and "focus" triggers
              placement="right-start"
            />
            </>
          }
          {
            !row?.assingedTemplateId &&
            <span
              style={{
                color: "#39FFFF",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsModalOpen(true)
                setTemplateDetails({
                  ...templateDetails,
                  userId: row?.id
                })
              }}
            >
             Assign
            </span>}
          </>
        );
      },
    },
    {
      key: "action",
      header: "ACTION",
      sortable: false,
      renderCell: (row, index) => (
        // Assuming ActionComponent is a component that renders the action buttons
        <div className={cx(styles.actionColParent)}>
          <div className={cx(styles.approveBtn)} onClick={() => handleStatusClick({name: row?.name, status: 'approve', userId: row?.id, row })} >Approve</div>
          <div className={cx(styles.declineBtn)} onClick={() => handleStatusClick({name: row?.name, status: 'decline', userId: row?.id })} >Decline</div>
          <img src={editBtn} alt="editBtn" style={{ cursor:'pointer'}} onClick={() => {
            setEditModalDetails({
              ...editModalDetails,
              isOpen: true,
              editDetails: row
            })
          }} />
        </div>
      ),
    },
  ];

  //redux hooks
  const dispatch = useDispatch()
  const pendingUsers = useSelector((s) => s?.department?.pendingDepartmentUsers)
  const pendingUsersCount = useSelector((s) => s?.department?.pendingDepartmentUsersCount)

  // states
  const [currentPage, setCurrentPage] = useState(1);
  
  const [ templateDetails, setTemplateDetails] = useState({
    userId: '',
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const [sortField, setSortfield] = useState({ sortBy: "", sortOrder: "" });

  const [assignTemplateResDetails, setAssignTemplateResDetails] = useState({
    isOpen: false,
    message: ''
  })

  const [editModalDetails, setEditModalDetails] = useState({
    isOpen: false,
    editDetails: {}
  })

  const [modal, setModalOptions] = useState({
    isOpen: false,
    name: '',   // name of the user
    status: '',  // approve or decline
    userId: '',   // id of user
    row: {}   // row object to check if user has assigned template.
  })

  
  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: '',
    variant: ''
})

  // lifecycle hook
  useEffect(() => {
    dispatch(PendingDepartmentUsers({
          pageIndex: currentPage,
          pageSize: ITEMS_PER_PAGE,
          sortBy: sortField.sortBy,
          sortOrder: sortField.sortOrder
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortField, editModalDetails.isOpen, assignTemplateResDetails.isOpen])

  useEffect(() => {
    dispatch(departmentTemplateAction.getTemplatesList()) 
    dispatch(departmentTemplateAction.getMasterModulesList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // functions

  const setPageNumber = (page) => {
    setCurrentPage(page);
  };

  const sortHandler = (sortBy, sortOrder) => {
    setSortfield({
      sortBy,
      sortOrder,
    });
  };

  const handleStatusClick = ({name,status, userId, row = {}}) => {
    setModalOptions({
      ...modal,
      isOpen: true,
      name,
      status,
      userId,
      row
    })
  }

  const handleCloseClick = () => {
    setModalOptions({
      ...modal,
      isOpen: false,
      name: '',
      status: '',
      userId: '',
      row: {}
    })
  }


  const handleApproveOrRejectClick = () => {
  const status = modal?.status
  const isTemplateAssigned = modal.row.assingedTemplateId
  if(status === 'approve') {
    if(!isTemplateAssigned) {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: 'Thank you for your request We are unable to approve the process without assign template!',
        variant: 'error'
    })
    setTimeout(() => {
      setModalOptions({
        ...modal,
        isOpen: false
      })
    }, 2002)
    }
    else {
      handleApproveClick()
    }
  }
  if(status === 'decline') handleDeclineClick()
  }

  const handleApproveClick = async() => {
    try {
      const res = await ApproveDepartmentUserRequest({userId: modal?.userId})
    if(res.status === 200) {
      setModalOptions({
        ...modal,
        isOpen: false,
        name: '',
        status: '',
        userId: ''
      })
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: 'Successfully Approved',
        variant: 'success'
    })
    dispatch(PendingDepartmentUsers({
      pageIndex: currentPage,
      pageSize: ITEMS_PER_PAGE,
      sortBy: sortField.sortBy,
      sortOrder: sortField.sortOrder
    }))
    }
    } catch (error) {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: error,
        variant: 'error'
    })
      console.log('ApproveDepartmentUserRequest error', error)
    }
  }

  const handleDeclineClick = async () => {
    try {
      const res = await DeclineDepartmentUserRequest({userId: modal?.userId})
    if(res.status === 200) {
      setModalOptions({
        ...modal,
        isOpen: false,
        name: '',
        status: '',
        userId: ''
      })
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: 'Successfully Declined',
        variant: 'success'
    })
    dispatch(PendingDepartmentUsers({
      pageIndex: currentPage,
      pageSize: ITEMS_PER_PAGE,
      sortBy: sortField.sortBy,
      sortOrder: sortField.sortOrder
}))
    }
    } catch (error) {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: error,
        variant: 'variant'
    })
      console.log('DeclineDepartmentUserRequest error', error)
    }
  }


  const closeFlashHandler = () => {
    setAssignTemplateResDetails({
      ...assignTemplateResDetails,
      isOpen: false
    })
  }

  const handleAssignTemplateClick = async (data) => {
    const { templateData : { id, name } } = data
    try {
      const res = await AssignTemplateToUserApprovalRequest(
        {
          userId: templateDetails?.userId,
          accessTemplateId: id
        }
      )
      if(res.status === 200) {
        setIsModalOpen(false)
        setTimeout(() => {
          setAssignTemplateResDetails({
            ...assignTemplateResDetails,
            isOpen: true,
            message: `${name} assigned successfully!`
          })
        }, 100);
      }
    } catch (error) {
      console.log('handleAssignTemplateClick error:', error)
    }
  }

  const handleCloseEditModal = () => {
    setEditModalDetails({
      ...editModalDetails,
      isOpen: false
    })
  }

  return (
    <>
      <div
        className={cx(styles.department_container, 'd-flex col-12 flex-column h-70')}>
        <Table
          data={pendingUsers}
          columns={columns}
          handleSortHandler={(e, v) => sortHandler(e, v)}
        />
      </div>
      { pendingUsersCount !== 0 ? (
        <div className={cx(styles.paginationContainer, 'mb-1 position-relative')}>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(pendingUsersCount / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setPageNumber}
          />
        </div>
      ) : (
        <></>
      )}
       <PendingRequestModal
          isVisible={modal.isOpen}
          closeModal={handleCloseClick}
          name={modal?.name}
          handleUpdateStatus={handleApproveOrRejectClick}
          status={modal?.status}
        />
        <InformativeModal
                isOpen={apiResponse.isOpen}
                onClose={() => setApiResponse({
                    ...apiResponse,
                    isOpen: false
                })}
                message={apiResponse.message}
                variant={apiResponse.variant}
            />
            <EditDuUserApproval 
            isOpen={editModalDetails.isOpen}
            editData={editModalDetails.editDetails}
            onClose={() => { setEditModalDetails({...editModalDetails, isOpen: false }) }}
            closeEditModal={handleCloseEditModal}
            />
      <AssignTemplateToUser
      isOpen={isModalOpen}
      handleClose={() => setIsModalOpen(false)}
      assignClicked={handleAssignTemplateClick}
      />
      <SuccessFlashMessage isOpen={assignTemplateResDetails.isOpen} message={assignTemplateResDetails.message} closeHandler={closeFlashHandler} />  
    </>
  );
};

export default DAPendingRequests;
