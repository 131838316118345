import React, { useState, Fragment, useEffect, useRef, useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io';
export default function VehicleSelect({
	selectedOptions = [],
	setSelectedOptions,
	options = [],
	setNoDataWarning = false,
	placeholder = 'Vehicle Registration No.',
	placeholderOnError = 'Select registration no.',
	valueContainerWidthInPx = '300px',
	valueContainerMaxWidthInPx = '300px',
	menuListPaddingX = 'px-5',
	isMultiSelect = false,
	optionPosition = 'justify-content-center',
	placeholderColor = 'hsl(0, 0%, 50%)',
	valueContainerColor = '#1F2A5D',
	indicatorSeparatorDisplay = 'block',
	maxMenuHeight = 276,
	customBackgroundColorReq = false,
	customBackgroundColor = '#E7ECF7',
	maxOptionSelectionLimit = options.length,
	maxSelectionExceedErrorMsg = 'Max option selection limit exceeded',
	placeholderAlignmentFlag,
	onChange = () => { },
}) {
	const myDivRef = useRef();
	const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
	const [selectedRadio, setSelectedRadio] = useState('vehicleStatus');

	const vehicleStatusOptions = [
		{ value: 'Live', label: 'Live' },
		// Note: Merge Idle status to Live status
		// { value: 'Idle', label: 'Idle' },
		{ value: 'Parked', label: 'Parked' },
		{ value: 'Charging', label: 'Charging' },
	];

	const connectivityStatusOptions = [{ value: 'NonComm', label: 'Non Communication' }];

	const getOptions = () => {
		return selectedRadio === 'vehicleStatus' ? vehicleStatusOptions : connectivityStatusOptions;
	};

	const handleRadioChange = (value) => {
		setSelectedRadio(value);
	};

	function handleSelect(data) {
		onChange(!isMultiSelect ? [data] : data);
		if (setSelectedOptions) {
			setSelectedOptions(!isMultiSelect ? [data] : data);
		}
	}



	function toggleSelectMenuOpenClose(bool) {
		setIsSelectMenuOpen(bool);
	}

	const isMaxSelectionLimitExceeded = useMemo(() => {
		return selectedOptions.length >= maxOptionSelectionLimit && maxOptionSelectionLimit !== options.length;
	}, [selectedOptions, maxOptionSelectionLimit, options]);




	const MenuList = useCallback(
		(props) => {
			return (
				<Fragment>
					{isMultiSelect && options.length === maxOptionSelectionLimit && (
						<div className={`d-flex flex-column align-items-center justify-content-between ${menuListPaddingX} py-2`}>
						</div>
					)}

					<div className='d-inline-flex align-items-stretch' onClick={() => handleRadioChange('vehicleStatus')}>
						<input
							type='radio'
							className="vehicle-status-radio-input cursor-pointer"
							value='vehicleStatus'
							checked={selectedRadio === 'vehicleStatus'}
							onChange={() => handleRadioChange('vehicleStatus')}

						/>
						<label className="ps-2 cursor-pointer" >
							Vehicle Status
						</label>
					</div>
					
					{selectedRadio === 'vehicleStatus' && (
						<components.MenuList {...props}>{props.children}</components.MenuList>
					)}
					
					<hr style={{ width: '100%', color: '#707070' }} />
					
					<div className='d-inline-flex align-items-stretch' onClick={() => handleRadioChange('connectivityStatus')}>
						<input
							type='radio'
							class="vehicle-status-radio-input cursor-pointer"
							value='connectivityStatus'
							checked={selectedRadio === 'connectivityStatus'}
							onChange={() => handleRadioChange('connectivityStatus')}
						/>
						
						<label className="ps-2 cursor-pointer" >
							Connectivity Status
						</label>
					</div>
					
					{selectedRadio === 'connectivityStatus' && (
						<components.MenuList {...props}>{props.children}</components.MenuList>
					)}


					{isMaxSelectionLimitExceeded && (
						<span className='menu-list-max-option-limit'>{maxSelectionExceedErrorMsg}</span>
					)}
				</Fragment>
			);
			// eslint-disable-next-line
		},
		[isMultiSelect, options.length, maxOptionSelectionLimit, menuListPaddingX, selectedRadio, isMaxSelectionLimitExceeded, maxSelectionExceedErrorMsg]
	);

	const Option = useCallback(
		(props) => {
			return (
				<div className='select-option-div'>
					<components.Option {...props}>
						{isMultiSelect ? (
							<div className={`d-flex flex-row align-items-center   justify-content-start ps-3 gap-2 w-100`}>
							
									{!props.isSelected && <IoIosSquareOutline color='#707070' size={20} />}
									{props.isSelected && <IoIosCheckbox color='#22046B' size={20} />}
									<label>{props.label}</label>
							</div>
						) : (
							<div className={`d-flex flex-row align-items-center ${optionPosition} gap-2 w-100`}>
								<div
									className={`d-flex flex-row align-items-center ps-2 justify-content-start gap-2 ${optionPosition === 'justify-content-center' ? 'w-50' : ''
										}`}
								>
									<label>{props.label}</label>
								</div>
							</div>
						)}
					</components.Option>
				</div>
			);
			// eslint-disable-next-line
		},
		[isMultiSelect, optionPosition]
	);

	if (customBackgroundColorReq) {
		document
			.getElementsByClassName('css-13cymwt-control')[0]
			?.setAttribute(
				'style',
				`background-color: ${customBackgroundColor} !important; border-color: ${customBackgroundColor} !important; height: 38px !important`
			);
	}

	// Function to execute when clicking outside the div
	function handleClickOutside(event) {
		if (myDivRef.current && !myDivRef.current.contains(event.target)) {
			toggleSelectMenuOpenClose(false);
			return;
		}
	}

	function handleClick(event) {
		if (isMultiSelect) {
			event.stopPropagation();
		}
		toggleSelectMenuOpenClose(true);
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		// Cleanup the event listener when the component is unmounted
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
		// eslint-disable-next-line
	}, []);

// Reset selected options when the radio changes
	useEffect(() => {
		
		if (setSelectedOptions) {
		  setSelectedOptions([]);
		}
	  }, [selectedRadio, setSelectedOptions]);

	return (
		<div ref={myDivRef} onClick={handleClick}>
			<Select
				options={getOptions()}
				id='react-mselect'
				closeMenuOnSelect={false}
				placeholder={setNoDataWarning ? placeholderOnError : placeholder}
				value={selectedOptions}
				maxMenuHeight={maxMenuHeight}
				onChange={handleSelect}
				menuIsOpen={isSelectMenuOpen}
				styles={{
					valueContainer: (styles, state) => {
						return {
							...styles,
							textOverflow: 'ellipsis',
							width: valueContainerWidthInPx,
							maxWidth: valueContainerMaxWidthInPx,
							backgroundColor: customBackgroundColor,
							fontSize: '14px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							justifyContent: 'center',
							display: 'flex',
							color: valueContainerColor,
							active: {
								backgroundColor: customBackgroundColor,
							},
						};
					},
					placeholder: (styles, state) => {
						const isSignupPage = placeholderAlignmentFlag === 'signup';
						return {
							...styles,
							marginRight: isSignupPage ? '115px' : '2',
							marginTop: isSignupPage ? '10px' : '2',
							color: `${state.children === placeholder ? placeholderColor : '#10204B'}`,
						};
					},
					menu:(styles)=>{
						return {
							...styles,
							paddingBottom:'11px'
						};

					},
					option: (styles, { isDisabled, isSelected }) => {
						return {
							...styles,
							backgroundColor:
								isSelected ? '#e7ecf7' : '#FFFFFF',
							color: '#1f2a5d',
							textAlign: 'center',
							fontFamily: 'inherit',
							fontSize: '14px',
							cursor: isDisabled ? 'not-allowed' : 'default',
							':active': {
								...styles[':active'],
								backgroundColor: '#FFFFFF',
							},
						};
					},
					singleValue: (styles, state) => {
						return {
							...styles,
							color: '#1F2a5d',
						};
					},
					indicatorSeparator: (styles) => {
						return {
							...styles,
							display: indicatorSeparatorDisplay,
						};
					},
				}}
				components={{
					MultiValueContainer: ({ selectProps, data }) => {
						const label = selectProps.value.length > 1 ? `${data.label}, ` : data.label;
						const val = `${selectProps.inputValue ? '' : label}`;
						return val;
					},
					Option: Option,
					MenuList: MenuList,
				}}
				isOptionDisabled={(option, selectValue) => {
					return (
						selectedOptions.length >= maxOptionSelectionLimit &&
						!selectValue.map((val) => val.label).includes(option.label)
					);
				}}
				isMulti={isMultiSelect}
				isSearchable={true}
				hideSelectedOptions={false}
				isClearable={true}
				allowSelectAll={isMultiSelect && selectedOptions.length === maxOptionSelectionLimit}
				className={setNoDataWarning ? 'box-shadow-danger' : ''}
			/>
		</div>
	);
}
