import ActionTypes from '../actionTypes'
const usersActionTypes = ActionTypes.USERS

const initialState = {
  usersListData: [],
  usersListCount: 0,
  isUploadApiSuccess: false,
}

const usersListReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case usersActionTypes.GET_ALL_USERS_LIST:
        return {
          ...state,
          usersListData: payload.rows,
          usersListCount: payload.count,
        }
      default:
        return state
    }
  }
  
  export default usersListReducer