import React from 'react';
import { useDispatch } from 'react-redux';
import './LogoutModal.css';

const LogoutModal = ({ isVisible, closeModal, logout }) => {
  const dispatch = useDispatch();

  return isVisible && (
    <div className="logout-modal-container">
      <div className="logout-modal">
        <span className="modal-text">Are you sure you want to log out?</span>
        <div className="button-container">
          <span className="modal-button yes" onClick={logout}>Yes</span>
          <span className="modal-button no" onClick={() => dispatch({ type: 'logoutClicked', payload: false })}>No</span>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
