const initialState = {
  detailsObj: {},
};

const alarmReportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "vehicleDetailsFromAlarm":
      return {
        ...state,
        detailsObj: payload,
      };
    default:
      return null;
  }
};

export default alarmReportReducer;
