import Layout from '../components/Layout';
import Missing from '../components/pages/Missing';
import RequireAuth from './RequireAuth';
import { Routes, Route } from 'react-router-dom';
import { ProtectedMenus, publicRoutes } from '../utils/constants';
import Maintenance from "../components/public-pages/maintenance/Maintenance";
import DarkLayout from '../components/Layout/DarkLayout'
import DarkPublicLayout from '../components/Layout/DarkPublicLayout';
import SADashboard from '../components/pages/super-admin/SADashboard/SADashboard';
import SAAdmin from '../components/pages/super-admin/SAAdmin/SAAdmin';
import SALogs from '../components/pages/super-admin/SALogs/SALogs';
import CAAdmin from '../components/pages/customer-admin';
// import CAManage from '../components/pages/customer-admin/CAManage/CAManage';
import DAAdmin from '../components/pages/department-admin';
import AccessTemplate from '../components/pages/department-admin/AccessTemplate/AccessTemplate';

const AppRoutes = () => {
  let menus = ProtectedMenus
  let darkMenus = ProtectedMenus
  const darkPublicMenus = publicRoutes  

  const userRole = JSON.parse(localStorage.getItem('user'))?.userRole
  const localUser = JSON.parse(localStorage.getItem('user'))

  if(userRole === 'DU') {
    menus = ProtectedMenus.filter((x) => (x.isPublic || localUser?.menus?.includes(x.id)) && x.name !== 'Admin' )
    darkMenus = ProtectedMenus.filter((x) => (x.isPublic || localUser?.menus?.includes(x.id)) && x.name !== 'Admin' )
  }


  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return <Maintenance />;
  } 


  return (
    <Routes>
      <Route element={<DarkPublicLayout />}>
        {darkPublicMenus.map(menu => (
          <Route key={`darkmenu_${menu.id}`} path={menu.path} element={menu.content()} />
        ))}
      </Route>

      {/* Public routes ends here */}
    
      {/* Secure routes starts here*/}
      {/* Dark layout routes starts here */}
      <Route element={<DarkLayout />}>
        <Route element={<RequireAuth isPublic={true} />}>
          <Route path="/da/access-template" element={<AccessTemplate />} />
          <Route path="/sa/dashboard" element={<SADashboard />} />

          <Route path="/sa/admin" element={<SAAdmin />} />

          <Route path="/sa/logs" element={<SALogs />} />

          <Route path="/ca/dashboard" element={<CAAdmin />} />

          <Route path="/ca/manage" element={<CAAdmin /> } />
          
          <Route path="/ca/logs" element={<CAAdmin /> } />
          
          {/* for testing part only, adding the routes here, later it can be added dynamically
          based on department admin role
          */}
          <Route path="/da/dashboard" element={<DAAdmin /> } />
          <Route path="/da/manage" element={<DAAdmin /> } />
          <Route path="/da/logs" element={<DAAdmin /> } />
        </Route>

        {darkMenus.filter(menu => menu.isLink && typeof menu.content === 'function' && menu.isDarkTheme).map(menu => (
          <Route key={`darkmenu_${menu.id}`} element={<RequireAuth menuId={menu.id} isPublic={menu.isPublic} />}>
            <Route path={menu.path} element={menu.content()} />
          </Route>
        ))}

        {darkMenus.filter(menu => menu?.submenu?.length && menu.isDarkTheme)?.map(menu => (
          menu?.submenu?.filter(submenu => typeof submenu?.content === 'function')?.map((submenu, submenuIndex) => (
            <Route key={`darkmenu_${menu.id}_${submenuIndex}`} element={<RequireAuth menuId={menu.id} isPublic={submenu.isPublic} />}>
              <Route path={submenu.path} element={submenu.content()} />
            </Route>
          ))
        ))}
      </Route>
      {/* Dark layout routes ends here */}
        
      {/* Light layout routes starts here */}
      <Route element={<Layout />}>
        {menus.filter(menu => menu.isLink && typeof menu.content === 'function').map(menu => (
          <Route key={`menu_${menu.id}`} element={<RequireAuth menuId= {menu.id}  isPublic = {menu.isPublic}  />}>
            <Route path={menu.path} element={menu.content()} />
          </Route>
        ))}

        {menus.filter(menu => menu?.submenu?.length > 0)?.map(menu => (
          menu?.submenu?.filter(submenu => typeof submenu?.content === 'function')?.map((submenu, submenuIndex) => (
            <Route key={`menu_${menu.id}_${submenuIndex}`} element={<RequireAuth menuId= {menu.id}  isPublic = {submenu.isPublic}  />}>
              <Route path={submenu.path} element={submenu.content()} />
            </Route>
          ))
        ))}
        {/* Light layout routes ends here */}
      </Route>
      
      {/* Secure routes ends here*/}
      
      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Routes>
  )
};

export default AppRoutes;