export const setIngressApplicable = (flag) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.ingressSettingsApplicable', payload: flag})
}

export const setIngressTimeForFullDay = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressTimeForFullDay'})
}

export const setIngressTimeCustomized = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressTimeCustomized'})
}

export const setIngressStartTimeHr = (hr) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressStartTimeHr', payload: hr})
}

export const setIngressStartTimeMinute = (minute) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressStartTimeMinute', payload: minute})
}

export const setIngressEndTimeHr = (hr) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressEndTimeHr', payload: hr})
}

export const setIngressEndTimeMinute = (minute) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressEndTimeMinute', payload: minute})
}

export const setIngressForAllVehicles = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressForAllVehicles'})
}

export const setIngressForCustomizedVehicles = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setIngressForCustomizedVehicles'})
}

export const setSelectedVehiclesForIngress = (selectedVehicles) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.ingressSettings.setSelectedVehiclesForIngress', payload: selectedVehicles})
}