import { Row, Col, Form } from 'react-bootstrap'
import { connect} from 'react-redux'
import "../geoFenceConfigurationScreen.css";

const LabelEditRow = (
        {
            rowKey,
            labelName,
            labelNameOnChange,
            onDelete,
            isEditable,
            setEdit,
            isValid,
            validationMessage
        }
    )=> {

    const handleLabelNameChange =(e)=> {
        labelNameOnChange(e.target.value, rowKey)
    }

    const deleteLabel = ()=> {
        onDelete(rowKey)
    }

    return (
        <Row style={{marginBottom:10}}>
            <Col xs={2} className='geofence-configure-sub-text'>
                Label Name
            </Col>
            <Col xs={2}>
                <Form.Group controlId="textBox">
                    <Form.Control 
                    className='geofence-configure-placeholder'
                    disabled = {!isEditable}
                    value={labelName}
                    onChange={handleLabelNameChange}
                    type="text" 
                    placeholder="Enter text"
                    data-testid='label-edit-name-input'
                    />
                    
                </Form.Group>
                {
                    !isValid &&
                    <div className='text-danger error-message'>{validationMessage}</div>
                }
            </Col>
            <Col xs={4} className="d-flex geofence-configure-sub-text-edit-delete ">


            <div className='geofence-configure-sub-text-edit-delete' data-testid='label-delete-button' onClick={deleteLabel}>
                <img
                alt={'delete'}
                src='/images/svgicon/delete_button.svg'
                style={{width:"25%", marginLeft:10, marginRight:5}}
                ></img>
                Delete          
            </div>
                 
            <div className='geofence-configure-sub-text-edit-delete' data-testid='label-edit-button' onClick={() => {setEdit(rowKey)}}>
                <img
                alt={'delete'}
                src='/images/svgicon/edit_button.svg'
                style={{width:"25%", marginLeft: 10, marginRight:5}}
                ></img>
                Edit        
            </div>
            </Col>
        </Row>
    )
}

LabelEditRow.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
})(LabelEditRow)