import React from 'react'
import { Button, Stack } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa';

import MultiSelect from '../../../helpers/hoc/multi-select/MultiSelect'
import DatePicker from '../../../helpers/hoc/date-picker/DatePicker'

import './VTHeader.css'


const Header = ({ formData, vehicleList = [], onVehicleTrackingChange, onVehicleTrackingSubmit, fetchingRutes }) => {

  // handle date change
  const onDateChange = (date) => {
    if (onVehicleTrackingChange) {
      onVehicleTrackingChange('startDateTime', date[0])
      onVehicleTrackingChange('endDateTime', date[1])
    }
  }

  // handle registered vehicle change
  const vehicleChange = (vehicle) => {
    if (onVehicleTrackingChange) {
      onVehicleTrackingChange('registrationNumber', vehicle.filter(Boolean))
    }
  }

  // handle on submit
  const onSubmit = (e) => {
    e.preventDefault();
    if (onVehicleTrackingSubmit) {
      onVehicleTrackingSubmit()
    }
  }

  // Handle get data button
  const handleGetDataButton = () => {
    let isDisabled = false
    Object.values(formData).forEach(item => {
      if (Array.isArray(item) && !item.length) {
        isDisabled = true
      }
      if ([null, undefined, ''].includes(item)) {
        isDisabled = true
      }
    })
    if (fetchingRutes) {
      isDisabled = true
    }
    return isDisabled
  }

  return (
    <Stack gap={5} direction='horizontal'>
      <div className='multi-select-container'>
        <div className='search-icon' style={{ backgroundColor: formData.registrationNumber[0] ? "#E7ECF7" : "#F5F7F9" }}>
          <FaSearch size={15}/>
        </div>
        <div className={`multi-select-width ${!formData.registrationNumber[0] ? 'no-value-selected' : ''}`}>
          <MultiSelect
            options={vehicleList}
            selectedOptions={formData.registrationNumber}
            maxOptionSelectionLimit={5}
            isMultiSelect={false}
            onChange={vehicleChange}
            placeholderColor='#C6C6C6'
            customBackgroundColor={{ backgroundColor: formData.registrationNumber[0] ? "#FFFFFF" : "red" }}
            data-testid='vt-vehicle'
          />
        </div>
      </div>
      <div className='vt__date-picker'>
        <DatePicker
          onDateChange={onDateChange}
          isDateSelected={formData.startDateTime && formData.endDateTime}
          placeholderDefaultTextColor='#C6C6C6'
          allowedMaxDaysInSelection={2}
          data-testid='vt-daterange'
          startDate={formData.startDateTime}
          endDate={formData.endDateTime}
        />
      </div>
      <div>
        <Button
          className='bttn height vt__btn'
          disabled={handleGetDataButton()}
          onClick={onSubmit}
          data-testid='vt-submit-button'
        >
          Get Data
        </Button>
      </div>
    </Stack>
  )
}

export default Header