// External packages import
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Style import
import styles from './BaseCard.module.css'

const BaseCard = (props) => {
    return (
        <div
            className={cx(
                styles.container,
                {
                    [styles.defaultContainer]: props.variant === 'default',
                    [styles.secondaryContainer]: props.variant === 'secondary',
                    [props.className]: props.className,
                }
            )}
            onClick={props.onClick}
            style={props.style}
        >
            {props.children}
        </div>
    )
}

BaseCard.defaultProps = {
    className: '',
    onClick: () => {},
    variant: 'default',
    style: {},
}

BaseCard.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf('default', 'secondary'),
    style: PropTypes.object,
}

export default BaseCard;