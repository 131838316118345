import React,{ useState } from 'react'
import NDVehicleInfoHeader from '../nd-vehicleInfo/nd-vehicleInfo-header/NDVehicleInfoHeader'
import NDVehicleInfoCanStatus from '../nd-vehicleInfo/nd-vehicleInfo-canStatus/NDVehicleInfoCanStatus'
import NdMap from '../nd-map/NdMap_Dark'
import NDTrackingWebCam from '../nd-vehicleInfo/nd-trackingWebCam/NDTrackingWebCam'
import NDClusterMeter from '../nd-cluster-meter/NDClusterMeter'
import moment from 'moment'
import NDVehicleInfo from '../nd-vehicleInfo/NDVehicleInfo'
import { GetAddressByCoordinates } from '../../../../store/actions/map/latlng-to-address';
import { errorToast } from '../../../../utils/toasts';




const NDVehicleInfoDark = ({ 
    variant,
    selectedVehicleData, 
    favouriteVehicles, 
    detailsMapConfig, 
    dashboardCamUrl, 
    vehicleIgnitionData, 
    vehicleStatusData, 
    activeAlert, 
    onFavoriteFlagClick, 
    mapRef, 
    mapReset, 
    handleActiveAlert, 
    handleOnCloseSidebar,
    onHeaderClose,
    isFavourite,
    onUpdateMapConfigForZoom,
    listPanelOpen,
    onFilterButtonClick,
    filters,
    handleChangeFilter,
    handleClearAllFilters,
    handleRemoveOption,
    isSidebarOpen,
    toggleSidebar
}) => {
 

    const [address, setAddress] = useState(null);

    const getAddress = async () => {
        try {
          const res = await GetAddressByCoordinates({ lat: selectedVehicleData.lt, lng: selectedVehicleData.lng })
          if (res.status === 200 && res.data.data) {
            let address =  res.data.data.address ;
            setAddress(address );
          } else {
            res.data.error.forEach(error => {
              errorToast({ message: error.message });
            });
          }
        } catch (error) {
          console.log(error)
        }
      }

    return (
        <div className='new-dark-dashboard new-dashboard-dark'>
            <div className='nd__vehicle-details-and-visuals-container'>
                <div className='nd__dark-vehicle-details-container'>
                    <div className='nd__dark-vehicle-details'>
                        <NDVehicleInfoHeader
                            vehicleStatus={selectedVehicleData?.canStatus?.vs}
                            vehicleSource={selectedVehicleData?.canStatus?.src}
                            vehicleNo={selectedVehicleData?.rn}
                            chassisNo={selectedVehicleData?.cn}
                            motorNo={selectedVehicleData?.mn}
                            nonCommunication={selectedVehicleData?.ncom}
                            oem={selectedVehicleData?.canStatus?.oem}
                            model={selectedVehicleData?.canStatus?.mdl}
                            address={address}
                            getAddress={getAddress}
                            lat={selectedVehicleData?.lt}
                            long={selectedVehicleData?.lng}
                            onClose={onHeaderClose}
                            isFavourite={isFavourite}
                            onFavoriteFlagClick={() => onFavoriteFlagClick(selectedVehicleData?.rn, favouriteVehicles?.includes(selectedVehicleData?.rn))}
                        />
                        <NDVehicleInfoCanStatus
                            gpsSyncTime={selectedVehicleData?.ltime ? moment(selectedVehicleData?.ltime).format('DD-MMM-YYYY, HH:mm') : 'NA'}
                            canSyncTime={selectedVehicleData?.canStatus?.ctime ? moment(selectedVehicleData?.canStatus?.ctime).format('DD-MMM-YYYY, HH:mm') : 'NA'}
                            batteryVoltage={selectedVehicleData?.canStatus?.vbv}
                            batteryTemperature={selectedVehicleData?.canStatus?.vbt}
                            distanceTillEmpty={selectedVehicleData?.canStatus?.dte}
                            nonCommunication={selectedVehicleData?.ncom}
                            soh={selectedVehicleData?.canStatus?.soh}
                            motorTemperature={selectedVehicleData?.canStatus?.vmt}
                            batteryCurrent={selectedVehicleData?.canStatus?.vbc}
                            currentBatteryCapacity={selectedVehicleData?.canStatus?.bc}
                            controllerTemperature={selectedVehicleData?.canStatus?.vct}
                        />
                    </div>
                    <div className='nd__vehicle-map py-3'>
                        <NdMap
                            variant={variant || "DARK_VEHICLE_INFO"}
                            mapConfig={detailsMapConfig}
                            mapRef={mapRef}
                            vehicleData={[selectedVehicleData]}
                            reset={mapReset}
                            disableControls={false}
                            onUpdateMapConfigForZoom={onUpdateMapConfigForZoom}
                            listPanelOpen={listPanelOpen}
                            onFilterButtonClick={onFilterButtonClick}
                            filters={filters}
                            handleChangeFilter={handleChangeFilter}
                            handleClearAllFilters={handleClearAllFilters}
                            handleRemoveOption={handleRemoveOption}
                            isSidebarOpen={isSidebarOpen}
                            toggleSidebar={toggleSidebar}
                        />
                    </div>
                </div>
                <div className='nd__vehicle-visuals-container'>
                    <div className='nd__vehicle-webcam'>
                        <NDTrackingWebCam camURL={dashboardCamUrl} />
                    </div>
                    <div className='nd__vehicle-cluster-meter'>
                        <NDClusterMeter
                            batteryVoltage={selectedVehicleData?.canStatus?.vbv}
                            batterySOC={selectedVehicleData?.canStatus?.soc}
                            vehicleSpeed={selectedVehicleData?.canStatus?.sp}
                            odometerValue={selectedVehicleData?.canStatus?.odo}
                            dte={selectedVehicleData?.canStatus?.dte}
                            ignitionStatus={selectedVehicleData?.canStatus?.ig}
                            vehicleStatus={selectedVehicleData?.canStatus?.vs}
                        />
                    </div>
                </div>
            </div>
            <div className='nd__vehicle-info-continer'>
                <div className='nd__sidebar-body-details'>
                    <NDVehicleInfo
                        vehicleInfo={selectedVehicleData}
                        vehicleIgnitionData={vehicleIgnitionData}
                        vehicleStatusData={vehicleStatusData}
                        selectedAlert={activeAlert}
                        onActiveAlertChange={handleActiveAlert}
                        dashboardCamUrl={dashboardCamUrl}
                        isFavourite={favouriteVehicles?.includes(selectedVehicleData?.rn)}
                        onCloseSidebar={handleOnCloseSidebar}
                        onFavoriteFlagClick={onFavoriteFlagClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default NDVehicleInfoDark
/*conflict-resolution-will be removed later*/