// External packages import
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Internal components import
import Modal from '../../../../shared/modals/BaseModal/BaseModal';
import BasicInput from '../../../../shared/form/Input/Input';
import Button from '../../../../shared/form/Button/Button';
import Select from '../../../../shared/form/Select/Select';
import validationService from '../../../../../service/validationService';
import InformativeModal, { VARIANTS } from '../../../../shared/modals/InformativeModal/InformativeModel';

// Services Utils and Store import
import { GetDepartmentName, GetDuLocation, UpdateUser } from '../../../../../store/actions/SA_Signup/SA_Signup';
// Style import
import styles from './EditUserControl.module.css';

const EditUserControl = ({
	isOpen,
	onClose,
	duDepartmentList,
	GetDuLocation,
	duLocationList,
	GetDepartmentName,
	editData = null,
}) => {
	const [formData, setFormData] = useState({
		departmentName: [],
		location: [],
		templateName: []
	});

	const [formDataError, setFormDataError] = useState({
		departmentName: '',
		location: '',
	});
	const [informativeModalData, setInformativeModalData] = useState({
		message: '',
		isOpen: false,
		variant: VARIANTS.SUCCESS,
		action: '',
	});

	const isEdit = Object?.keys(editData)?.length;

	const [preparedData, setPreparedData] = useState({
		departmentName: {},
		location: {},
		templateName: {}
	});

	const fetchData = async () => {
		await GetDepartmentName({
			ci: editData?.ci,
			searchText: '',
		});
		await GetDuLocation({
			ci: editData?.ci,
		})
	};

	const handleDepartmentUserUpdate = async (payload) => {
		try {
			let response;
			const requestData = {
				userId: editData?.id,
				departmentId: payload.departmentName.value,
				locationId: payload.location.value,
				assignedAccessTemplateId: (Object.keys(formData.templateName).length && formData.templateName.value ) || editData?.assingedTemplateId,
			};

			response = await UpdateUser(requestData);

			if (response?.status === 200) {
				setInformativeModalData({
					message: 'Successfully Submitted',
					isOpen: true,
					variant: VARIANTS.SUCCESS,
				});
				handleClose()
				return;
			}
		} catch (error) {
			setInformativeModalData({
				message: 'Something went wrong!!!',
				isOpen: true,
				variant: VARIANTS.ERROR,
			});
		}
	};

	const handleOnChange = ({ name = '', value = '' }) => {
		formData[name] = value;
		setFormData({ ...formData });
		setFormDataError({ ...formDataError });
	};

	const handleClose = () => {
		setFormData({
			departmentName: '',
			location: '',
			templateName: ''
		});
		setFormDataError({
			departmentName: '',
			location: '',
			templateName: ''
		});
		onClose();
	};

	const handleValidation = () => {
		let errorCount = 0;

		const departmentName = validationService.required(formData.departmentName);
		if (!departmentName.isValid) {
			errorCount++;
		}
		formDataError['departmentName'] = departmentName.message;

		const location = validationService.required(formData.location);
		if (!location.isValid) {
			errorCount++;
		}
		formDataError['location'] = location.message;
		setFormDataError((prevState) => ({ ...prevState, formDataError }));
		return errorCount;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!isEdit) {
			if (handleValidation() > 0) {
				return;
			}
		}
		handleDepartmentUserUpdate(formData);
	};

	useEffect(() => {
		if (isEdit) {
			fetchData();
		}
		setPreparedData({
			...preparedData,
			departmentName: { value: editData?.departmentName?.toLowerCase(), label: editData?.departmentName },
			location: { value: editData?.location?.toLowerCase(), label: editData?.location },
			templateName: { value: editData?.assignedTemplate?.toLowerCase(), label: editData.assignedTemplate }
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEdit]);

	const DepartmentNameOptions = duDepartmentList?.map((d) => ({ label: d.name, value: d._id }));

	const DepartmentLocation = duLocationList?.map((d) => ({ label: d.city, value: d._id }));

	const templates = useSelector(state => state.department.templateList).map(d => ({ label: d.name, value: d.id }))
	
	return (
		<>
			<Modal isOpen={isOpen} title={`Edit ` + editData.rId} onClose={handleClose}>
				<div>
					<BasicInput
						label='Name'
						value={editData?.name}
						onChange={(e) => handleOnChange({ name: 'name', value: e.target.value })}
						error={formDataError.name}
						placeholder='Enter First and Last name'
						disable={true}
					/>
					<BasicInput
						label='Email ID'
						value={editData?.email}
						onChange={(e) => handleOnChange({ name: 'email', value: e.target.value })}
						error={formDataError.email}
						placeholder='Enter Email ID'
						type='email'
						disable={true}
					/>

					<Select
						label={'Department Name'}
						options={DepartmentNameOptions}
						value={
							(Object.keys(formData.departmentName).length && formData.departmentName) ||
							preparedData.departmentName
						}
						error={formDataError.departmentName}
						onChange={(data) => handleOnChange({ name: 'departmentName', value: data })}
						placeholder='Select Department'
						isMultiSelect={false}
						controlStyle={{ width: '410px' }}
					/>
					<Select
						label='Location'
						options={DepartmentLocation}
						value={(Object.keys(formData.location).length && formData.location) || preparedData?.location}
						error={formDataError.location}
						onChange={(data) => handleOnChange({ name: 'location', value: data })}
						placeholder='Select Location'
						isMultiSelect={false}
						controlStyle={{ width: '410px' }}
						isDisabled={true}
					/>
					<Select
                        label='Template'
                        options={templates}
                        value={(Object.keys(formData.templateName).length && formData.templateName) || preparedData?.templateName }
                        error={formDataError.templateName}
                        onChange={(data) => handleOnChange({ name: 'templateName', value: data })}
                        placeholder='Select Template'
                        required={true}
                        isMultiSelect={false}
                        controlStyle={{ width: '410px' }}

                    />
					<div className={styles.modalCTAWrapper}>
						<Button variant="PINK" onClick={handleSubmit}>
							Submit
						</Button>
					</div>
				</div>
			</Modal>

			<InformativeModal
				isOpen={informativeModalData.isOpen}
				message={informativeModalData.message}
				variant={informativeModalData.variant}
				onClose={() => {
					setInformativeModalData({
						...informativeModalData,
						isOpen: false,
					});
				}}
			/>
		</>
	);
};

EditUserControl.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	duDepartmentList: state.SA_Signup.duDepartmentList,
	duLocationList: state.SA_Signup.duLocationList,
});

export default connect(mapStateToProps, {
	GetDuLocation,
	GetDepartmentName,
})(EditUserControl);
