import ActionTypes from '../../actionTypes'
const { GET_ALL_DATA_FAIL, GET_ALL_DATA_SUCCESS, GET_STOPPAGE_DATA_SUCCESS, GET_STOPPAGE_DATA_FAILED } = ActionTypes.REPORTS.TRIP

const initialState = {
  allTripData: [],
  dataCount: 0,
  stoppageData: [],
  stoppageDataCount: 0
}

const tripReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_DATA_SUCCESS:
      return {
        ...state,
        allTripData: payload.rows,
        dataCount: payload.count,
      }
    case GET_ALL_DATA_FAIL:
      return {
        ...state,
        allTripData: [],
        dataCount: 0,
      }
    case GET_STOPPAGE_DATA_SUCCESS:
      return {
        ...state,
        stoppageData: payload,
        stoppageDataCount: payload.length,
      }
    case GET_STOPPAGE_DATA_FAILED:
      return {
        ...state,
        stoppageData: [],
        stoppageDataCount: 0,
      }
    default:
      return state
  }
}

export default tripReducer
