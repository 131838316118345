const initialState = {
  profileImage: '',
  logoutClicked: false,
  darklogoutClicked:false
};

const profileImageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
      case 'profileImage':
        return {
          ...state,
          profileImage: payload
        }
      case 'logoutClicked':
        return {
          ...state,
          logoutClicked: payload
        }
        case 'darklogoutClicked':
          return {
            ...state,
            darklogoutClicked: payload
          }
    default:
      return state;
  }
};

export default profileImageReducer;