import { useEffect, useRef, useState } from 'react'
import './AlarmAnalyticsFilter.css'
import SingleDatePicker from '../../../../../helpers/hoc/single-date-picker/SingleDatePicker'
import moment from 'moment/moment'

const AlarmAnalyticsFilter = (props) => {
  const [activeIndex, setActiveIndex] = useState(3)

  const [ selectedDate, setSelectedDate ] = useState('Custom')
  
  const datePickerRef = useRef(null)
  const filters = [
    {
      key: 'custom',
      label: selectedDate,
      index: 0,
      svg: '/images/svgicon/datepicker.svg',
      activesvg: '/images/svgicon/datepicker_white.svg',
      isDatePicker: true
    },
    {
      key: 'week',
      label: 'Last 2 Days',
      index: 1,
      svg: null,
      activesvg: null,
      isDatePicker: false
    },
    {
      key: 'yesterday',
      label: 'Yesterday',
      index: 2,
      svg: null,
      activesvg: null,
      isDatePicker: false
    },
    {
      key: 'today',
      label: 'Today',
      index: 3,
      svg: null,
      activesvg: null,
      isDatePicker: false
    },
  ]
  const onFilterChange = (isCustomChange, index) => {
    if (!isCustomChange) {
      const datesRange = getFromAndToDate(index)
      props.onFilterChange(datesRange.from, datesRange.to)
    }
  }
  useEffect(() => {
    onFilterChange(false, 3)
    // eslint-disable-next-line
  }, [])
  const onFilterTypeClick = (index) => {
    if (index !== activeIndex) {
      setActiveIndex(index)
      if(index !== 0) {
        setSelectedDate('Custom')
      }
      onFilterChange(index === 0, index)
    }

    if(index === 0){
      datePickerRef.current.open();
    }

  }
  const getFromAndToDate = (index) => {
    const today = new Date()
    const dates = {
      from: null,
      to: null
    }
    switch (index) {
      case 1: // Last 2 days
        const last2Days = new Date(today)
        const yesterdayy = new Date(today)
        yesterdayy.setDate(today.getDate() - 1)
        last2Days.setDate(today.getDate() - 2)
        dates.from = new Date(last2Days.setHours(0, 0, 0, 0)).toISOString()
        dates.to = new Date(yesterdayy.setHours(23, 59, 59, 999)).toISOString()
        break
      case 2: // Yesterday
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        dates.from = new Date(yesterday.setHours(0, 0, 0, 0)).toISOString()
        dates.to = new Date(yesterday.setHours(23, 59, 59, 999)).toISOString()
        break
      case 3: // Today
        dates.to =  new Date(today.setHours(today.getHours(), today.getMinutes(), 0, 0)).toISOString()
        dates.from = new Date(today.setHours(0, 0, 0, 0)).toISOString()
        break
      default:
        console.log('dafault filter index not found')
        break
    }
    return dates
  }
  const onDateChange = (startDate, endDate) => {
    if(startDate && endDate) {
      props.onFilterChange(startDate, endDate)
     const date = `${moment(startDate).format('DD-MMM-YYYY')} ~ ${moment(endDate).format('DD-MMM-YYYY')}`
      setSelectedDate(date)
    }
  } 
  return (
    <>
      <div className='al_filter-flex container-fluid'>
        {filters.map((item, index) => (
          <>
          {item.isDatePicker && <div style={{visibility: 'hidden', marginTop: '-25px'}}>
            <SingleDatePicker key={'datepicker-analytics' + index}
              maxRangeDays={2} 
              ref = {datePickerRef} 
              onDateChange = {onDateChange} />
          </div>}
          <button
            key={'al_button-filter-' + index}
            className={item.index === activeIndex ? 'al__button_active cursor-pointer' : 'cursor-pointer'}
            onClick={() => {onFilterTypeClick(item.index)}}
          >
            {item.label}
            {item.svg && item.index !== activeIndex  && <img style={{marginLeft: '5px'}} height={16} width={16} src={item.svg} alt='datepicker icon' />}
            {item.svg && item.index === activeIndex  && <img style={{marginLeft: '5px'}} height={16} width={16} src={item.activesvg} alt='datepicker icon' />}
          </button>
          </>
          
        ))}
      </div>
    </>
  )
}

export default AlarmAnalyticsFilter
