import React, { memo } from 'react';
import PropTypes from 'prop-types'; 
import inputStyles from './Input.module.css';

const Input = ({ type, error, label, required, labelStyle, disable = false, ...props }) => {
 
  return (
    <div className={inputStyles.inputWrapper}>
      {label && <label className={inputStyles.label}>{label} {required ? <span className={inputStyles.lableRequired}>*</span> : null}</label>}
      <input
        type={type}
        className={`${inputStyles.root} ${error ? inputStyles.error : ''}`}
        {...props} 
        disabled={disable}
      />
      {type === 'email' && !error && props.value && (
       <img src="/images/saAdmin/tick-icon.svg" alt="" className={inputStyles.iconSuccess} />
      )}
       {type === 'email' && error && props.value && (
       <img src="/images/saAdmin/cross-icon.svg" alt="" className={inputStyles.iconSuccess} />
      )}
      {error && <div className={inputStyles.errorMessage}>{error}</div>}
    </div>
  );
};

Input.defaultProps = {
  onChange: () => {},
  error: '',
  type: 'text',
  value: '',
  label: '',
  required: false,
  labelStyle: {},
  readOnly: false,
};

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  labelStyle:PropTypes.object,
  readOnly: PropTypes.bool,
};

export default memo(Input);
/*conflict-resolution-will be removed later*/