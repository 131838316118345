import React, { useEffect } from "react";
import { Row, Col} from 'react-bootstrap'
import './alertConfiguration.css'
import MultiSelect from '../../../helpers/hoc/multi-select/MultiSelect'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    setIngressApplicable,
    setIngressTimeForFullDay,
    setIngressTimeCustomized,
    setIngressStartTimeHr,
    setIngressStartTimeMinute,
    setIngressEndTimeHr,
    setIngressEndTimeMinute,
    setIngressForAllVehicles,
    setIngressForCustomizedVehicles,
    setSelectedVehiclesForIngress
} from '../../../../store/actions/geo-fence/configuration/alertsConfiguration/ingressSettingsAction'

const IngressSettingsDiv = ({
    isIngressApplicable,
    setIngressApplicable,
    isIngressTimeFullDay,
    isIngressTimeCustomized,
    setIngressTimeForFullDay,
    setIngressTimeCustomized,
    startTimeHour,
    setIngressStartTimeHr,
    startTimeMinute,
    setIngressStartTimeMinute,
    endTimeHour,
    setIngressEndTimeHr,
    endTimeMinute,
    setIngressEndTimeMinute,
    isIngressForAllVehicles,
    isIngressForCustomizedVehicles,
    setIngressForAllVehicles,
    setIngressForCustomizedVehicles,
    selectedVehicles,
    allVehicles,
    setSelectedVehiclesForIngress,
    fencesData
})=> {

    const isIngressDisabled = () => {
        const selectedFences = fencesData.filter(fence => fence.isSelected)
        if(selectedFences.length === 0) {
            return true
        }
    }

    //if ingressStartTimeHour changed and length is 2, then move focus to ingressStartTimeMinute
    useEffect(()=>{
        if(startTimeHour.length === 2) {
            document.getElementById('ingress-start-time-minute').focus()
        }
    },[startTimeHour])
    
    useEffect(()=>{
        if(startTimeMinute.length === 2) {
            document.getElementById('ingress-end-time-hour').focus()
        }
    },[startTimeMinute])

    useEffect(()=>{
        if(endTimeHour.length === 2) {
            document.getElementById('ingress-end-time-minute').focus()
        }
    },[endTimeHour])

    const startTimeHourOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setIngressStartTimeHr('00')
            return
        }

        if(parseInt(e.target.value) > 23) {
            setIngressStartTimeHr('23')
            return
        }

        if(e.target.value.length <= 2) {
            setIngressStartTimeHr(e.target.value)
            return
        }
        setIngressStartTimeHr(e.target.value.substring(0,2))
    }

    const startTimeMinuteOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setIngressStartTimeMinute('00')
            return
        }

        if(parseInt(e.target.value) > 59) {
            setIngressStartTimeMinute('59')
            return
        }

        if(e.target.value.length <= 2) {
            setIngressStartTimeMinute(e.target.value)
            return
        }

        setIngressStartTimeMinute(e.target.value.substring(0,2))
    }

    const endTimeHourOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setIngressEndTimeHr('00')
            return
        }

        if(parseInt(e.target.value) > 23) {
            setIngressEndTimeHr('23')
            return
        }

        if(e.target.value.length <= 2) {
            setIngressEndTimeHr(e.target.value)
            return
        }
        setIngressEndTimeHr(e.target.value.substring(0,2))
    }

    const endTimeMinuteOnChanged = (e) => {
        if(isNaN(e.target.value)) {
            return
        }

        if(parseInt(e.target.value) < 0) {
            setIngressEndTimeMinute('00')
            return
        }

        if(parseInt(e.target.value) > 59) {
            setIngressEndTimeMinute('59')
            return
        }

        if(e.target.value.length <= 2) {
            setIngressEndTimeMinute(e.target.value)
            return
        }

        setIngressEndTimeMinute(e.target.value.substring(0,2))
    }

    return (
        <Row>
            
            <Col className="ingress-configure p-3 mb-5 bg-white">
              
             <Row>
                <Col xs={2}>
                <div className="d-flex align-items-center flex-row justify-content-between ">
                <div className="alerts-operation alert_text">Ingress</div>
                <div className="material-switch ">
                  <input
                    id="switch-ingress"
                    name="switch-ingress"
                    type="checkbox"
                    disabled={isIngressDisabled()}
                    checked={!isIngressDisabled() && isIngressApplicable}
                    onChange={() => {
                        setIngressApplicable(!isIngressApplicable);
                    }}
                    data-testid='ingress-checkbox'
                  />
                  <label htmlFor="switch-ingress"></label>
                </div>
              </div>
                </Col>
             </Row>
              
                <br></br>
              {
                !isIngressDisabled() && isIngressApplicable &&
                <Row>
                <Col xs={4}>
                    <label
                    style={{
                        fontSize: 15,
                    }}
                    >
                    Select Time
                    </label>
                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="anytime" 
                                className="radio-button"
                                checked={isIngressTimeFullDay}
                                onClick={setIngressTimeForFullDay}
                                data-testid='ingress-anytime-radio'
                            />
                        </Col>
                        <Col xs={2} className="radio-button-label">
                            <label for="anytime">24 Hrs</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="customize" 
                                checked={isIngressTimeCustomized}
                                onClick={setIngressTimeCustomized}
                                data-testid='ingress-customize-radio'
                            />
                        </Col>
                        <Col xs={2} className="radio-button-label">
                            <label for="customize">Customize</label>
                        </Col>
                    </Row>

                    {
                        isIngressTimeCustomized &&
                        <Row>
                            <Col xs={2}>
                            </Col>
                            <Col xs={10}>
                                <Row>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>HH</label>
                                        <input 
                                            type="text" 
                                            id="ingress-start-time-hour"
                                            value={startTimeHour} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { 
                                                startTimeHourOnChanged(e)
                                                //setIngressStartTimeHr(e.target.value)
                                            }}
                                            data-testid='ingress-starttime-hour-input'
                                        >
                                        </input>
                                    </Col>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>MM</label>
                                        <input 
                                            type="text" 
                                            id="ingress-start-time-minute"
                                            value={startTimeMinute} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { startTimeMinuteOnChanged(e)}}
                                            data-testid='ingress-starttime-minute-input'
                                        >
                                        </input>
                                    </Col>
                                    <Col xs={1} className="px-1">
                                        <br></br>
                                        <p>to</p>
                                    </Col>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>HH</label>
                                        <input 
                                            type="text" 
                                            id="ingress-end-time-hour"
                                            value={endTimeHour} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { endTimeHourOnChanged(e)}}
                                            data-testid='ingress-endtime-hour-input'
                                        >
                                        </input>
                                    </Col>
                                    <Col xs={2} className="px-1 text-center">
                                        <label>MM</label>
                                        <input 
                                            type="text" 
                                            id="ingress-end-time-minute"
                                            value={endTimeMinute} 
                                            className="hour-textbox text-center"
                                            onChange={(e) => { endTimeMinuteOnChanged(e)}}
                                            data-testid='ingress-endtime-minute-input'
                                        >
                                        </input>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    
                </Col>
                <Col xs={4}>
                </Col>
                <Col xs={4}>
                    <label
                    style={{
                        fontSize: 15,
                    }}
                    >
                    Select Vehicle
                    </label>

                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="all-vehicles" 
                                checked={isIngressForAllVehicles}
                                onClick={setIngressForAllVehicles}
                                data-testid='ingress-allvehicle-radio'
                            />
                        </Col>
                        <Col xs={3} className="radio-button-label">
                            <label for="all-vehicles">All Vehicles</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={1}>
                        </Col>
                        <Col xs={1}>
                            <input type="radio" id="customize-vehicles" 
                                checked={isIngressForCustomizedVehicles}
                                onClick={setIngressForCustomizedVehicles}
                                data-testid='ingress-customizevehicle-radio'
                            />
                        </Col>
                        <Col xs={3} className="radio-button-label">
                            <label for="customize-vehicles">Customize</label>
                        </Col>
                    </Row> 
                    {
                        isIngressForCustomizedVehicles &&
                        <Row>
                            <Col xs={2}>
                            </Col>
                            <Col xs={9}>
                                <MultiSelect
                                    placeholder="Select Vehicles"
                                    options={allVehicles}
                                    selectedOptions={selectedVehicles}
                                    isMultiSelect = {true}
                                    setSelectedOptions={setSelectedVehiclesForIngress}
                                    data-testid='ingress-vehicle-filter'
                                />
                            </Col>
                    </Row>
                    } 
                </Col>
                </Row>
              }

              
              
            </Col>
        </Row>
    )
}

IngressSettingsDiv.propTypes = {
    isIngressApplicable: PropTypes.bool.isRequired,
    isIngressTimeFullDay: PropTypes.bool.isRequired,
    isIngressTimeCustomized: PropTypes.bool.isRequired,
    startTimeHour: PropTypes.string.isRequired,
    startTimeMinute: PropTypes.string.isRequired,
    endTimeHour: PropTypes.string.isRequired,
    endTimeMinute: PropTypes.string.isRequired,
    isIngressForAllVehicles: PropTypes.bool.isRequired,
    isIngressForCustomizedVehicles: PropTypes.bool.isRequired,
    selectedVehicles: PropTypes.array.isRequired,
    allVehicles: PropTypes.array.isRequired,
    fencesData: PropTypes.array.isRequired,

    setIngressApplicable: PropTypes.func.isRequired,
    setIngressTimeForFullDay: PropTypes.func.isRequired,
    setIngressTimeCustomized: PropTypes.func.isRequired,
    setIngressStartTimeHr: PropTypes.func.isRequired,
    setIngressStartTimeMinute: PropTypes.func.isRequired,
    setIngressEndTimeHr: PropTypes.func.isRequired,
    setIngressEndTimeMinute: PropTypes.func.isRequired,
    setIngressForAllVehicles: PropTypes.func.isRequired,
    setIngressForCustomizedVehicles: PropTypes.func.isRequired,
    setSelectedVehiclesForIngress: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isIngressApplicable: state.alertConfigurationScreen.ingressSettings.isIngressApplicable,
    isIngressTimeFullDay: state.alertConfigurationScreen.ingressSettings.isIngressTimeFullDay,
    isIngressTimeCustomized: state.alertConfigurationScreen.ingressSettings.isIngressTimeCustomized,
    startTimeHour: state.alertConfigurationScreen.ingressSettings.startTimeHour,
    startTimeMinute: state.alertConfigurationScreen.ingressSettings.startTimeMinute,
    endTimeHour: state.alertConfigurationScreen.ingressSettings.endTimeHour,
    endTimeMinute: state.alertConfigurationScreen.ingressSettings.endTimeMinute,
    isIngressForAllVehicles: state.alertConfigurationScreen.ingressSettings.isIngressForAllVehicles,
    isIngressForCustomizedVehicles: state.alertConfigurationScreen.ingressSettings.isIngressForCustomizedVehicles,
    selectedVehicles: state.alertConfigurationScreen.ingressSettings.selectedVehicles,
    allVehicles: state.alertConfigurationScreen.allVehicles,
    fencesData: state.alertConfigurationScreen.fencesData,

    setIngressApplicable,
    setIngressTimeForFullDay,
    setIngressTimeCustomized,
    setIngressStartTimeHr,
    setIngressStartTimeMinute,
    setIngressEndTimeHr,
    setIngressEndTimeMinute,
    setIngressForAllVehicles,
    setIngressForCustomizedVehicles,
    setSelectedVehiclesForIngress
});

export default connect(mapStateToProps, {
    setIngressApplicable,
    setIngressTimeForFullDay,
    setIngressTimeCustomized,
    setIngressStartTimeHr,
    setIngressStartTimeMinute,
    setIngressEndTimeHr,
    setIngressEndTimeMinute,
    setIngressForAllVehicles,
    setIngressForCustomizedVehicles,
    setSelectedVehiclesForIngress
})(IngressSettingsDiv);
