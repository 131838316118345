import React, { useState } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts'

const AlarmAnalyticsDonutChart = (props) => {
  const total = props.data
    ? props.data?.reduce((acc, obj) => acc + obj.count, 0)
    : 0
  const [tooltipPositionClient, setTooltipPositionClient] = useState(null)
  const [toolTipData, setTooltipData] = useState(null)
  const [activeIndex, setActiveIndex] = useState(-1)

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill
    } = props;
      return (
        <g style={{cursor: 'pointer'}}>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius + 6}
            outerRadius={outerRadius + 10}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
        </g>
      );
  }
  
  const handleChartClick = (data, index) => {  
    props.onChartClick(data)
  }

  const onPieEnter = (data, index, event) => {
    setActiveIndex(index);
    const tData = data.payload
    setTooltipData(tData)
    let pos = {
      x: event.clientX - data.cx + 55,
      y: event.clientY - data.cy + 15
    }
    setTooltipPositionClient(pos)
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
    setTooltipData(null)
  };
  const onPiMouseMove = (data, index, event) => {
    let pos = {
      x: event.clientX - data.cx + 55,
      y: event.clientY - data.cy + 15
    }
    setTooltipPositionClient(pos)
  }
  return (
    <div className='donut-chart-container'>
      <ResponsiveContainer width='90%' height={250}>
        <PieChart>
          <Pie
            dataKey='count'
            data={props.data}
            cx='50%'
            cy='50%'
            innerRadius={60}
            outerRadius={110}
            fill='#8884d8'
            paddingAngle={0}
            labelLine={false}
            onClick={handleChartClick}
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            onMouseMove={onPiMouseMove}
          >
            {props?.data?.map((entry, index) => (
              <Cell key={index} fill={entry.color} cursor='pointer' />
            ))}
          </Pie>
          {total > 0 && (
            <text
              x='50%'
              y='50%'
              textAnchor='middle'
              dominantBaseline='middle'
              fill='#22046B'
              fontSize='12'
              style={{ fontWeight: 500, color:"#1F2a5d" }}
            >
              {total}
            </text>
          )}

          {total === 0 && (
            <text
              x='50%'
              y='50%'
              textAnchor='middle'
              dominantBaseline='middle'
              fill='#22046B'
              fontSize='14'
              style={{ fontWeight: 500, color:"#1F2a5d" }}
            >
              No Data
            </text>
          )}
        </PieChart>
      </ResponsiveContainer>
      { toolTipData &&
      <div
      style={{
        opacity: 0.9,
        backgroundColor: '#939393',
        borderRadius: '5px',
        display: 'flex',
        padding: "10px",
        fontSize: '0.70em',
        color:'white',
        position: 'absolute',
        whiteSpace: 'nowrap',
        marginTop: '-10px',
        marginLeft: '-10px',
        left: tooltipPositionClient.x, 
        top: tooltipPositionClient.y
      }}
      > 
      <div style={{display: 'inline-flex', alignItems: 'center'}}>
      <span
            style={{
             height: 10,
             width: 10,
             borderRadius: "50%",
             backgroundColor: toolTipData.color,
             color: toolTipData.color,
           }}
           > 
      </span>
      <span style={{marginLeft: '3px'}}>{`${toolTipData.count} ${toolTipData.type.label}`}</span>       
      </div>
        </div>
      }
    </div>
  )
}

export default AlarmAnalyticsDonutChart
