import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsXLg } from "react-icons/bs";
import chargingIcon from "../../../../../assets/geofenceIcons/charging-geofence.svg";
import vehicleIcon from "../../../../../assets/GeoFencingIcons/vehicles.svg";
import servicesIcon from "../../../../../assets/geofenceIcons/service.svg";
import storageIcon from "../../../../../assets/geofenceIcons/storage.svg";
import hiringDeskIcon from "../../../../../assets/geofenceIcons/hiringdesk.svg";
import loadingUnloadingIcon from "../../../../../assets/geofenceIcons/loadingUnloading.svg";
import parkingIcon from "../../../../../assets/geofenceIcons/parking.svg";
import editIcon from "../../../../../assets/GeoFencingIcons/geofence-edit.svg";
import "./detailsCard.css";
import GeofenceImageUploadModel from "../imageUploadModel/imageUploadModel.js";
import ImageCarousel from "../imageCarousel/imageCarouselComponent"; // Make sure to adjust the import path
import { coordinatesToDMS } from "../../../../../utils/common-methods";

function DashBoardDetailsCard({ data, onClose, onEdit, toggleVehicleDetialModal }) {
  const [isShowModal, setIsShowModal] = useState(false);
  const [formattedCoordinates, setFormattedCoordinates] = useState("");

  React.useEffect(() => {
    if (data?.center_coordinates) {
      let result = coordinatesToDMS(
        data.center_coordinates[0],
        data.center_coordinates[1]
      );
      setFormattedCoordinates(result.join(", "));
    } else {
      setFormattedCoordinates("");
    }
  }, [data]);

  if (!data) return;

  return (
    <div className="row  text-style-details ">
      <div className="col-12 profile-container container-fluid">
        <div className="col-12 d-flex justify-content-start">
          <div className=" col-12 card-details-geofence pl-5 pr-5 pt-1 pb-4">
            <div className="d-flex justify-content-end">
              <BsXLg data-testid='details-close-button' onClick={onClose} />
            </div>
            <div className="d-flex justify-content-end geofence-details-card-edit">
              <img
                src={editIcon}
                alt=""
                title=""
                className="cursor-pointer"
                onClick={() => {
                  onEdit(data._id);
                }}
                data-testid='details-edit-button'
              />
            </div>
            <div className="details-card-overflow-geofence">
              {
                data && data.fence_images && data.fence_images.length ? (
                  <ImageCarousel
                    fence_images={data?.fence_images || []}
                    fenceId={data._id}
                  />
                ) : (
                  <div
                    className="mt-3 mb-4"
                    onClick={() => {
                      isShowModal
                        ? setIsShowModal(false)
                        : setIsShowModal(true);
                    }}
                    data-testid='details-add-photo-button'
                  >
                    <div className="file-drawer-geofence">
                    <img  className="file-drawer-geofence-img" src='/images/svgicon/add_Photo.svg' alt='addphoto'  />
                      <div className="d-flex flex-row align-items-center  file-drawer-geofence-text ">
                        Add photo
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="card-body-geofence mt-5 position-relative">
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence">
                      Fence Name
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5} className="text-style-value-geofence">
                    {data?.fence_name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence">
                      Label
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5} className="text-style-value-geofence">
                    {data.populated_label?.label}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence">
                      Label Owner
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5} className="text-style-value-geofence">
                    {data.populated_label_owner?.label}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence">
                      Fence Owner
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5} className="text-style-value-geofence">
                    {data.populated_fence_owner?.label}
                  </Col>
                </Row>
                <div 
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVehicleDetialModal(e, data )
                }}
                data-testid='details-view-vehicle-button'
                className="align-items-center d-flex flex-column justify-content-center vehicle-count-geofence ">
                  <img className="image" src={vehicleIcon} alt="" title="" />
                  <span className="count">{data.vehicleCount}</span>
                </div>
              </div>
              <div className="card-body-geofence mt-4">
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence">
                      Address
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7} className="text-style-value-geofence">
                    {data.fence_identifiers?.address}
                  </Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence ">
                      Area
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7} className="text-style-value-geofence">
                    {`${(data.fence_geometry.area?.value)?.toFixed(2)} ${
                      data.fence_geometry.area?.unit
                    }`}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence ">
                      City
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7} className="text-style-value-geofence">
                    {data.fence_identifiers?.city}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <label htmlFor="name" className="text-style-key-geofence ">
                      Coordinates
                    </label>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7} className="text-style-value-geofence">
                    {formattedCoordinates}
                  </Col>
                </Row>
              </div>
              <div className="card-body-geofence mt-4 mb-3">
                <div className="sub-heading-amenities mt-2 mb-2 ">
                  Amenities
                </div>
                <Row>
                  {!(
                    data.amenities.charging ||
                    data.amenities.parking ||
                    data.amenities.servicing ||
                    data.amenities.storage ||
                    data.amenities.hiring_desk ||
                    data.amenities.loading_unloading
                  ) ? (
                    <>
                    <span className="amenities-font ml-3">
                    No Amenities Found
                    </span>
                    </>
                  ) : null}
                  {!!data.amenities.charging ? (
                    <Col xs={4} className="amenities">
                      <>
                        <img src={chargingIcon} alt="" title="" />
                        <label className="amenities-font">Charging</label>
                      </>
                    </Col>
                  ) : null}
                  {!!data.amenities.parking ? (
                    <Col xs={4} className="amenities">
                      <>
                        <img src={parkingIcon} alt="" title="" />
                        <label className="amenities-font">Parking</label>
                      </>
                    </Col>
                  ) : null}
                  {!!data.amenities.servicing ? (
                    <Col xs={4} className="amenities">
                      <>
                        <img src={servicesIcon} alt="" title="" />
                        <label className="amenities-font">Servicing</label>
                      </>
                    </Col>
                  ) : null}
                  {!!data.amenities.storage ? (
                    <Col xs={4} className="amenities">
                      <>
                        <img src={storageIcon} alt="" title="" />
                        <label className="amenities-font">Storage</label>
                      </>
                    </Col>
                  ) : null}
                  {!!data.amenities.hiring_desk ? (
                    <Col xs={4} className="amenities">
                      <>
                        <img src={hiringDeskIcon} alt="" title="" />
                        <label className="amenities-font">Hiring desk</label>
                      </>
                    </Col>
                  ) : null}
                  {!!data.amenities.loading_unloading ? (
                    <Col xs="auto" className="amenities">
                      <>
                        <img src={loadingUnloadingIcon} alt="" title="" />
                        <label className="amenities-font">Loading/Unloading</label>
                      </>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowModal && (
        <GeofenceImageUploadModel
          fenceId={data._id}
          showMoal={isShowModal}
          setIsShowModal={setIsShowModal}
        />
      )}
    </div>
  );
}

export default DashBoardDetailsCard;
