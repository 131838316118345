import API from '../../service/api'
import API_BOOK from '../../service/endpoints'
import ActionTypes from '../actionTypes'
import { customToast } from '../../utils/toasts'
import { fileDownloader } from '../../utils/common-methods'

const dispatchFetchFleetOemVehicles = (payload) => ({
  type: ActionTypes.FLEET.OEM_API,
  payload,
})


/**
 *  @desc         Telematics API to fetch fleet oem vehicles
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /vehicle/onboarding/get-all-vehicles-status
 *  @method       POST
 *  @createdDate  20-DEC-2023
 *  @modifiedDate 20-DEC-2023
 **/
export const fetchFleetOemVehicles = ({ searchKey, page, pageSize, oemModels, cities, subscribeFilter, types, chargingTypeFilter }) => async (dispatch) => {
  try {
    const res = await API({
      url: API_BOOK.FLEET.ONBOARDING_ALL_VEHICLES.url,
      method: API_BOOK.FLEET.ONBOARDING_ALL_VEHICLES.method,
      data : {
        searchKey,
        page, 
        pageSize,
        oemModels,
        cities,
        subscribeFilter,
        types,
        chargingTypeFilter
      }
    })
    if (res) {
      dispatch(dispatchFetchFleetOemVehicles(res?.data?.data || []))
    }
  } catch (error) {
    console.log('getVehicles error', error)
  }
}

/**
 *  @desc         Telematics Fleet Summary Export to excel API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          vehicle/onBoarding/export-to-excel
 *  @method       POST
 *  @createdDate  20-DEC-2023
 *  @modifiedDate 20-DEC-2023
 **/
export const exportExcelFleet = ({data}) => async (dispatch) => {  
  try {
    const res = await API({
      url: API_BOOK.FLEET.EXPORT_ONBOARDING_ALL_VEHICLES.url,
      method: API_BOOK.FLEET.EXPORT_ONBOARDING_ALL_VEHICLES.method,
      responseType: "arraybuffer",
      data : {
        searchKey: data?.searchKey,
        timezoneOffset: new Date().getTimezoneOffset(),
        oemModels: data?.oemModels,
        cities: data?.cities,
        subscribeFilter: data?.subscribeFilter,
        types: data?.types
      }
    })
    if (res) {
      fileDownloader({
        arrayBuffer: res?.data,
        fileName: "fleet_summary.xlsx",
      })
    }
  } catch (error) {
    console.log('getVehicles error', error)
  }
}


/**
 *  @desc         Telematics API to subscribe  fleet vehicle
 *  @author       Nilesh Bharat Salunkhe
 *  @api          vehicle-subscription/verify-save-vehicle
 *  @method       POST
 *  @createdDate  20-DEC-2023
 *  @modifiedDate 20-DEC-2023
 **/


export const fleetVehicleSubscribe = async (formData, onSuccess) => {
  try {
    const response = await API  ({
      url: API_BOOK.FLEET.SUBSCRIBE.url,
      method: API_BOOK.FLEET.SUBSCRIBE.method,
      data: formData
    })
    if(response) {
      if(response?.data?.status === 200){
        customToast({message: response?.data?.message})
        onSuccess()
      } else if (response?.data?.status === 400) {
        customToast({message: response?.data?.error, svgImageName:'decline'})
      } else {
        customToast({message: response?.data?.message, svgImageName:'decline'})
      }
    } else {
      customToast({message: 'Something went wrong!', svgImageName:'decline'})
    }
  } catch (err) {
    console.log('Fleet Subsribe API Error', err)
    if(err?.response?.data?.error) {
      customToast({message: err?.response?.data?.error, svgImageName:'decline'})
    } else {
      customToast({message: 'Something went wrong!', svgImageName:'decline'})
    }
  }
}


/**
 *  @desc         Telematics API to unsubscribe fleet vehicle
 *  @author       MUKESH FULWARIYA
 *  @api          /vehicle-subscription/unsubscribe-vehicle
 *  @method       POST
 *  @createdDate  20-DEC-2023
 *  @modifiedDate 20-DEC-2023
 **/

export const fleetVehicleUnSubsVehicle = async (formData, onSuccess) => {
  try {
    const response = await API({
      url: API_BOOK.FLEET.UNSUBSCRIBE.url,
      method: API_BOOK.FLEET.UNSUBSCRIBE.method,
      data: formData
    })
    if(response) {
      if(response?.data?.status === 200){
        customToast({message: response?.data?.message})
        onSuccess()
      } else if (response?.data?.status === 400) {
        customToast({message: response?.data?.error, svgImageName:'decline'})
      } else {
        customToast({message: response?.data?.message, svgImageName:'decline'})
      }
    } else {
      customToast({message: 'Something went wrong!', svgImageName:'decline'})
    }
  } catch (err) {
    console.log('Fleet UnSubsribe API Error')
    if(err?.response?.data?.error) {
      customToast({message: err?.response?.data?.error, svgImageName:'decline'})
    } else {
      customToast({message: 'Something went wrong!', svgImageName:'decline'})
    }
  }
}