import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Charging from './Charging';
import './Reporting.css';
import Trip from './Trip';
import Status from './Status';
import Historical from './Historical';
import HistoricalAnalytics from './HistoricalAnalytics';



const ReportingContainer = () => {
	const items = [ 
		{
			name: 'Charge',
			url: '/reports/charge',
			isShow: true
		},
		{
			name: 'Trip',
			url: '/reports/trip',
			isShow: true
		},
        {
			name: 'Status',
			url: '/reports/status',
			isShow: true
		},
		{
			name: 'Source Data',
			url: '/reports/historic-data',
			isShow: process.env.REACT_APP_ENV !== 'LIVE'
		},
		{
			name: 'Historical',
			url: '/reports/historical',
			isShow: true
		},
		
	];
	const location = useLocation();
	const currentRoute = location.pathname;

	return (
		<>
			<div className='reporting__container'>
				<div className='reporting__tab__container'>
					{items.filter(x => x.isShow).map((item) => (
						<>
							<Link
								to={item.url}
								className={`reporting-title reporting__vertical__line reporting__tabs ${
									currentRoute === item.url ? ' active__tab' : ''
								}`}
								data-testid={`tab-link-${item.name}`}
							>
								{item.name}
							</Link>
						</>
					))}
				</div>

				<div className='reporting__body_wrapper'>
					{(() => {
						switch (currentRoute) {
							case '/reports/charge':
								return <Charging/>;
							case '/reports/trip':
								return <Trip/>;
							case '/reports/status':
								return <Status/>;
							case '/reports/historic-data':
								return <Historical />;
							case '/reports/historical':
								return <HistoricalAnalytics />;
							default:
								return null;
						}
					})()}
				</div>
			</div>
		</>
	);
};

export default ReportingContainer;
