import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Table } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import Pagination from "../../../../helpers/hoc/paginator/Pagination";
import "./vehicleDetailsModel.css";
import {
  getFormattedDate,
  secondsToDayHousrsAndMinuesteSeconds,
  formatTimestamp,
  addEscapeKeyListener
} from "../../../../../utils/common-methods";
import { useState } from "react";
import {
  GeofenceVehicleExport,
  GetFenceVehicleByFenceId,
} from "../../../../../store/actions/geo-fence/dashboardGeoFence";
import { useCallback } from "react";

function VehiclesDetails({
  fenceId,
  showMoal,
  setIsShowModal,
  fenceNameData,
  fenceVehicleCount,
  GeofenceVehicleExport,
  fenceVehicleByFenceIdData,
  GetFenceVehicleByFenceId
}) {
  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, [setIsShowModal]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const formattedDate = getFormattedDate(currentDate, true); // Get formatted date
    const options = { hour: "numeric", minute: "2-digit", hour12: true };
    const currentTime = currentDate.toLocaleTimeString(undefined, options);
    const combinedDateTime = `${formattedDate}, ${currentTime}`; // Combine date and time
    setCurrentDateTime(combinedDateTime);
  };
  useEffect(() => {
    getCurrentDateTime();
  }, []);
  const ITEMS_PER_PAGE = 8;

  const getData = useCallback(
    (fenceId) => {
      GeofenceVehicleExport({
        data: {
          fenceId: fenceId,
          type: "Ingress",
          pageIndex: currentPage - 1,
          pageSize: ITEMS_PER_PAGE,
        },
      });
    },
    [GeofenceVehicleExport, currentPage]
  );

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      handleClose();
    });

    return () => {
      removeListener();
    };
  }, [handleClose]);
  useEffect(() => {
    if (showMoal) {
      GetFenceVehicleByFenceId({
        data: {
          fenceId: fenceId,
          type: "Ingress",
          pageIndex: currentPage - 1,
          pageSize: ITEMS_PER_PAGE,
        },
      });
    }
  }, [GetFenceVehicleByFenceId, currentPage, fenceId, showMoal]);

  return (
    <>
      <Modal
        show={showMoal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="geofence-details ">
          <div className="header-container d-flex justify-content-between">
            <div className="title w-100 mr-2">
              <div className="row" style={{ height: "80px" }}>
                <div className="col-3 d-inline-flex align-items-center">
                  <h5 style={{marginTop:'14px'}}>{fenceNameData}</h5>
                </div>
              </div>
            </div>
            <div className="download-btn">
            <button
  className="dwn-btn al__dwn-btn"
  onClick={() => {
    getData(fenceId);
  }}
  data-testid={`fence-export-button`}
  disabled={!fenceVehicleByFenceIdData.length}
>
  <BsDownload style={{width:'22px',height:'22px'}} />
</button>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-start">
            <div className="container-geofence">Date & Time : {currentDateTime} </div>
          </div>
          <Table responsive={true} borderless id='vehicle__Details__Model__table' >
            <thead className="">
              <th className="ps-3 text-nowrap">
                <span className="geofence-thead-text">S.No</span>
              </th>
              <th className="ps-3 text-nowrap">
                <span className="geofence-thead-text">Vehicle No.</span>
              </th>
              <th className="ps-3 text-nowrap">
                <span className="geofence-thead-text">OEM & Model</span>
              </th>
              <th className="ps-3 text-nowrap">
                <span className="geofence-thead-text">State of Charge</span>
              </th>
              <th className="ps-3 text-nowrap">
                <span className="geofence-thead-text">Ingress Date & Time</span>
              </th>
              <th className="ps-3 text-nowrap">
                <span className="geofence-thead-text">Duration</span>
              </th>
            </thead>
            <tbody>
              {fenceVehicleByFenceIdData.map((Obj, index) => (
                <tr key={"row-" + index.toString()}>
                  <td className="ps-3  geofence-td-text ">{8 * (currentPage - 1) + index + 1}.</td>
                  <td className="geofence-td-text">{Obj._id}</td>
                  <td className="geofence-td-text">{Obj.oem + " " + Obj.model}</td>
                  <td className="geofence-td-text">{Obj.soc}%</td>
                  <td className="geofence-td-text">
                    {Obj.date_time
                      ? formatTimestamp(Obj.date_time, true)
                      : Obj.date_time}
                  </td>
                  <td className="geofence-td-text">
                    {Obj.date_time
                      ? secondsToDayHousrsAndMinuesteSeconds(
                          Obj.dwell_time_in_sec,
                          true
                        )
                      : Obj.dwell_time_in_sec}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(fenceVehicleCount / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setCurrentPage}
            data-testid='fence-pagination'
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  fenceVehicleByFenceIdData: state.dashBoardGeoFance.fenceVehicleByFenceIdData,
  fenceVehicleCount: state.dashBoardGeoFance.fenceVehicleCount,
});

export default connect(mapStateToProps, {
  GeofenceVehicleExport,
  GetFenceVehicleByFenceId,
})(VehiclesDetails);
