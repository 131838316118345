import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import { customToast } from "../../../utils/toasts";
import { NotificationMessage } from "../../../utils/constants";
const { GET_ALL_DATA_FAIL, GET_ALL_DATA_SUCCESS } = ActionTypes.REPORTS.HISTORIC_DATA;
const { GET_ALL_HISTORIC_ANALYTICS_DATA, EXPORT_HISTORIC_ANALYTICS_DATA } = API_BOOK.REPORTS.HISTORIC_DATA;


/* Action dispatchers (AD) starts here */ 

const getAllHistoricDataSuccessAD = (payload) => ({
  type: GET_ALL_DATA_SUCCESS,
  payload,
});

const getAllHistoricDataFailAD = () => ({
  type: GET_ALL_DATA_FAIL
});


/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         NorMinc historic data analytics report API
 *  @author       Mukesh Fulwariya
 *  @api          /report/historic-data-analytics
 *  @method       POST
 *  @createdDate  02-Mar-2024
 *  @modifiedDate 02-Mar-2024
 **/
export const GetHistoricAnalyticsData = ({ registrationNumbers, fromDate, toDate, pageSize, pageIndex,intervalDuration, oemAndModels, city}) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_HISTORIC_ANALYTICS_DATA.method,
      url: GET_ALL_HISTORIC_ANALYTICS_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        pageSize,
        pageIndex,
        intervalDuration,
        oemAndModels,
        city
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllHistoricDataSuccessAD(response));
    } else {
      dispatch(getAllHistoricDataFailAD());
    }
  } catch (error) {
    dispatch(getAllHistoricDataFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         NorMinc export historic data analytics report API
 *  @author       Mukesh Fulwariya
 *  @api          /report/historic-data-analytics/export
 *  @method       POST
 *  @createdDate  02-Mar-2024
 *  @modifiedDate 02-Mar-2024
 **/
export const ExportHistoricAnalyticsData = ({ registrationNumbers, fromDate, toDate, oemAndModels, city }) => async (dispatch) => {
  try {
    const resp = await api({
      method: EXPORT_HISTORIC_ANALYTICS_DATA.method,
      url: EXPORT_HISTORIC_ANALYTICS_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        timezoneOffset: new Date().getTimezoneOffset(),
        oemAndModels,
        city
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
};


/* Actions ends here */ 