import { combineReducers } from 'redux'
import auth from './auth'
import otp from './otp'
import user from './user'
import smsotp from './otp'
import nonCommLogs from './reports/nonCommLogs'
import latlongToAddress from './map/latlng-to-address'
import fileUpload from './fileUpload'
import vehOnboarding from './vehicleOnboarding'
import loader from './loader'
import trip from './reports/trip'
import vehicle from './vehicle/vehicle'
import historic from './reports/historic-data'
import topPaneSearchbarReducer from "./topPaneSearchbarReducer";
import handleStatusReducer from "./vehicleStatusReducer";
import cityDropdownReducer from './cityDropdownReducer'
import alarmReportReducer from './alarmReportReducer'
import cityLocationSelectReducer from './cityLocationSelectReducer'
import chargingSessions from './reports/charging'
import alarm from './reports/alarm'
import socDropdownReducer from './socDropdownReducer'
import topFilterLabelReducer from './topFilterLabelReducer'
import getAllUserRoleReducer from './roleMenuMapping'
import usersList from './userList'
import masters from './masters'
import dashBoardGeoFance from './geo-fence/dashboardGeofence'
import fence from '../reducers/geo-fence/createfence'
import createFenceDifferentActions from './geo-fence/createFenceDifferentActions.Reducer'
import parameterConfiguration from './geo-fence/parameterConfigurationReducer'
import geoFanceReportReducer from './geo-fence/geoFanceReport'
import alertConfigurationScreen from './geo-fence/alertConfigurationScreenReducer'
import analytics from './geo-fence/analyticsReducer'
import user_management from './ra_user_management'
import dashboardReducer from './dashboard/dashboard'
import favoriteVehicle from './favoriteVehicleReducer'
import tripReportAnalytics from './reports/analytics/trip-report'
import chargingAnalytics from './reports/charging-analytics'
import userReducer from './user'
import profileImageReducer from './profileImageReducer'
import notificationPanelReducer from './notificationPanel'
import groupsReducer from './Groups/GroupsReducer'
import subGroups from './sub_groups/subGroups'
import dashboardGroupsReducer from './dashboard/dashboardGroupsReducer';
import recordReducer from './records'
import getFleetReducer from './fleetDataService'
import userActivityReducer from './userActivity/userActivity'
import SA_Signup from './SA_Signup/Sa_Signup'
import customerAdmin from '../actions/CAFlow/CAFlowReducer'
import administrationReducer from './administration/administration'
import departmentReducer from './department/department'
import ImmobilizeReducer from './immobilize/ImmobilizeReducer'

export default combineReducers({
  auth,
  otp,
  user,
  smsotp,
  nonCommLogs,
  latlongToAddress,
  fileUpload,
  vehOnboarding,
  loader,
  trip,
  vehicle,
  historic,
  chargingSessions,
  alarm,
  topPaneSearchbarReducer,
  handleStatusReducer,
  cityDropdownReducer,
  alarmReportReducer,
  cityLocationSelectReducer,
  socDropdownReducer,
  topFilterLabelReducer,
  masters,
  getAllUserRoleReducer,
  usersList,
  dashBoardGeoFance,
  createFenceDifferentActions,
  fence,
  parameterConfiguration,
  geoFanceReportReducer,
  alertConfigurationScreen,
  analytics,
  user_management,
  dashboardReducer,
  favoriteVehicle,
  tripReportAnalytics,
  chargingAnalytics,
  userReducer,
  profileImageReducer,
  notificationPanelReducer,
  groupsReducer,
  subGroups,
  dashboardGroupsReducer,
  recordReducer,
  getFleetReducer,
  userActivityReducer,
  SA_Signup,
  customerAdmin,
  administrationReducer,
  department: departmentReducer,
  Immobilize: ImmobilizeReducer
})
