// External packages import
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Internal components import
import BaseCard from '../../../../shared/cards/BaseCard/BaseCard'
import Button from '../../../../shared/form/Button/Button'
import Checkbox from '../../../../shared/form/Checkbox/Checkbox'
import Input from '../../../../shared/form/Input/Input'

// Internal utils import
import validationService from '../../../../../service/validationService'

// Style import
import styles from './AccessTemplateAddEditForm.module.css'

const AccessTemplateAddEditForm = (props) => {
    const { masterModules, onSaveTemplateClick, templateData, templateNames } = props
    const isEdit = templateData?.id

    const [formData, setFormData] = React.useState({
        templateName: '',
        moduleData: [],
    })
    const [formErrorData, setFormErroData] = React.useState({
        templateName: '',
        moduleData: '',
        actionTypeData: '',
    })

    const [moduleId, setModuleId] = React.useState(null);
    const [moduleName, setModuleName] = React.useState('');
    const [selectedActionTypes, setSelectedActionTypes] = React.useState({});

    // populate form data on load for edit journey
    React.useEffect(() => {
        if (isEdit) {
            // parse template data to form data format for edit
            const editModuleData = templateData.modules.map(m => {
                return {
                    moduleId: m.moduleId,
                    module: m.module,
                    subModules: m.subModules.map(sm => {
                        return {
                            submoduleValue: sm.value,
                            actionTypes: sm.actionTypes.map(at => at.value)
                        }
                    })
                }
            })
            
            setFormData({
                templateName: templateData.name,
                moduleData: editModuleData,
            })

            // set first module selected and update action types
            handleModuleSelect({ moduleValue: editModuleData[0].moduleId, moduleName: editModuleData[0].module, moduleData: editModuleData })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData])
    
    // keep first module default selected on load for create template
    React.useEffect(() => {
        if (!isEdit && masterModules.length) {
            handleModuleSelect({ moduleValue: masterModules[0].moduleId, moduleName: masterModules[0].module, moduleData: formData.moduleData })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterModules])

    // function to update state for selected module id and name.
    // this also gets available action types and set them in state for active module
    const handleModuleSelect = ({ moduleValue, moduleName, moduleData }) => {
        const subModules = masterModules.find(m => m.moduleId === moduleValue).subModules
        const subModulesFormData = moduleData.find(m => m.moduleId === moduleValue)?.subModules || []
        
        const tempSelectedActionTypes = {}

        subModules.forEach(s => {
            const subModuleIndex = subModulesFormData.findIndex(d => d.submoduleValue === s.value)
            tempSelectedActionTypes[s.value] = []
            if (subModuleIndex !== -1 && subModulesFormData[subModuleIndex].actionTypes.length) {
                tempSelectedActionTypes[s.value] = subModulesFormData[subModuleIndex].actionTypes
            }
        })
        
        setModuleId(moduleValue)
        setModuleName(moduleName)
        setSelectedActionTypes({ ...tempSelectedActionTypes })
    }

    // select and unselect all action types when submodule is selected or unselected
    const handleSubmoduleSelect = ({ submoduleValue, actionTypes }) => {
        const tempSelectedActionTypes = { ...selectedActionTypes }

        if (tempSelectedActionTypes[submoduleValue].length) {
            tempSelectedActionTypes[submoduleValue] = []
        } else {
            tempSelectedActionTypes[submoduleValue] = actionTypes.map(d => d.value)
        }

        setSelectedActionTypes({...tempSelectedActionTypes})
    }

    // select and unselect individual action type
    const handleActionTypeSelect = ({ submoduleValue, actionTypeValue }) => {
        const tempSelectedActionTypes = { ...selectedActionTypes }

        if (!tempSelectedActionTypes[submoduleValue].includes(actionTypeValue)) {
            tempSelectedActionTypes[submoduleValue].push(actionTypeValue)
        } else {
            tempSelectedActionTypes[submoduleValue] = tempSelectedActionTypes[submoduleValue].filter(v => actionTypeValue !== v)
        }

        setSelectedActionTypes({...tempSelectedActionTypes})
    }

    // update form module data with selected module and action types
    // this will either create new entry or update existing entry under form module data
    const handleAddClick = () => {
        const moduleData = [...formData.moduleData]
        
        const selectedModuleIndex = moduleData.findIndex(m => m.moduleId === moduleId)

        const subModules = []
            
        Object.keys(selectedActionTypes).forEach(sm => {
            if (selectedActionTypes[sm].length) {
                subModules.push({
                    submoduleValue: sm,
                    actionTypes: selectedActionTypes[sm]
                })
            }
        })

        if (!subModules.length) {
            setFormErroData({ ...formErrorData, actionTypeData: 'Please select at least one action type or submodule' })
            return
        }

        if (selectedModuleIndex === -1) {
            moduleData.push({
                moduleId,
                module: moduleName,
                subModules
            })
        } else {
            moduleData[selectedModuleIndex] = {
                ...moduleData[selectedModuleIndex],
                subModules
            }
        }

        setFormData({ ...formData, moduleData })
        setFormErroData({ ...formErrorData, moduleData: '', actionTypeData: '' })
    }

    // update form module data by removing module and action types
    const handleRemoveClick = ({ moduleIndex, moduleId:removeModuleId }) => {
        const moduleData = [...formData.moduleData]
        
        moduleData.splice(moduleIndex, 1)
        setFormData({
            ...formData,
            moduleData
        })

        if (removeModuleId === moduleId) {
            const tempSelectedActionTypes = {}
            Object.keys(selectedActionTypes).forEach(key => {
                tempSelectedActionTypes[key] = []
            })
            setSelectedActionTypes({ ...tempSelectedActionTypes })
        }
        setFormErroData({ ...formErrorData, moduleData: '', actionTypeData: '' })
    }

    // handle template name input change
    const handleInputChange = ({ name, value }) => {
        const tempFormData = {...formData}
        tempFormData[name] = value
        setFormData({ ...tempFormData })
    }

    // validate template body befor save
    const validateSaveTemplate = () => {
        let errorCount = 0

        let templateNameValidation = validationService.maxLength(formData.templateName, 20)
        if (!templateNameValidation.isValid) {
            errorCount++
        } else {
            if (templateNames.includes(formData.templateName)) {
                errorCount++
                templateNameValidation.message = 'Template name already exists'
            }
        }
        formErrorData['templateName'] = templateNameValidation.message

        const moduleDataValidation = validationService.array(formData.moduleData)
        if (!moduleDataValidation.isValid) {
            errorCount++
        } else {
            // check if selected modules has valid submodule data
            const submoduleDataValidation = formData.moduleData.filter(module => {
                // get subModules list from master module data
                const submoduleData = masterModules.find(m => m.moduleId === module.moduleId).subModules
                
                // if master module has submodule and user has selected submodule then consider it as valid submodule selection
                if (submoduleData.length) {
                    return !!module.subModules.length
                }
                
                // if master module does not have submodule and user have not selected submodule then consider it as valid submodule selection
                return !module.subModules.length
            })

            if (!submoduleDataValidation.length) {
                errorCount++;
                formErrorData['moduleData'] = 'It has issue with one of the submodule selection'
            } else {
                formErrorData['moduleData'] = ''
                
                // check if selected subModules has valid action types data
                const actionTypeDataValidation = formData.moduleData.filter(module => {
                    // get subModules list from master module data
                    const submoduleData = masterModules.find(m => m.moduleId === module.moduleId).subModules
                    
                    // if there are no subModules then skip check for action types
                    if (!submoduleData.length) {
                        return true
                    }
                    
                    const actionTypeValidation = module.subModules.filter(submodule => {
                        // get applicable list of action types from master submodule data
                        const actionTypeData = submoduleData.find(s => s.value === submodule.submoduleValue).actionTypes.filter(a => a.isApplicable)
                        
                        // if master submodule has action types and user has selected action types then consider it as valid action type selection
                        if (actionTypeData.length) {
                            return !!submodule.actionTypes.length
                        }

                        // if master module does not have action types and user have not selected action types then consider it as valid action type selection
                        return !submodule.actionTypes.length
                    })

                    // if there are valid action types present then consider it as valid data
                    return !!actionTypeValidation.length
                })
                if (!actionTypeDataValidation.length) {
                    errorCount++;
                    formErrorData['moduleData'] = 'It has issue with one of the action type selection'
                } else {
                    formErrorData['moduleData'] = ''
                }
            }
        }
        formErrorData['moduleData'] = moduleDataValidation.message
        
        console.log({ formErrorData })
        
        setFormErroData(prevState => ({ ...prevState, ...formErrorData }))
        return errorCount
    }

    // handle save template
    const handleSaveTemplateClick = () => {
        if (validateSaveTemplate()) {
            return
        }
        onSaveTemplateClick({ ...formData })
    }
    
    const submodulesList = masterModules.find(d => d.moduleId === moduleId)?.subModules || []
    
    return (
        <BaseCard className={styles.container}>
            <div className={styles.headerWrapper}>
                <p>Template Name</p>
                <Input
                    value={formData.templateName}
                    onChange={(e) => handleInputChange({ name: 'templateName', value: e.target.value })}
                    error={formErrorData.templateName}
                />
            </div>

            <div className={styles.contentWrapper}>
                <BaseCard className={cx(styles.column, styles.columnModuleList)} variant='secondary' style={{ maxWidth: '260px' }}>
                    <div className={styles.columnHeader}>
                        <p>List of Modules</p>
                    </div>

                    <div className={styles.columnBody}>
                        {masterModules.map((module, moduleIndex) => (
                            <button
                                key={`module_${moduleIndex}`}
                                className={cx(styles.moduleListItem, { [styles.moduleListActiveItem]: moduleId === module.moduleId })}
                                onClick={() => handleModuleSelect({ moduleValue: module.moduleId, moduleName: module.module, moduleData: formData.moduleData })}
                            >
                                {module.module}
                                <img src="/images/darkSVGIcon/arrow_right.svg" alt="arrow icon" />
                            </button>
                        ))}
                    </div>
                </BaseCard>

                <BaseCard className={cx(styles.column, styles.columnSubmodulesActionTypes)} variant='secondary'>
                    <div className={styles.columnHeader}>
                        <p>Sub Modules</p>
                        <p>Action Type</p>
                    </div>

                    {formErrorData.actionTypeData && <span className={styles.columnHeaderError}>{formErrorData.actionTypeData}</span>}

                    <div className={styles.columnBody}>
                        {submodulesList.map(submodule => (
                            <div
                                key={`submodule_${submodule.value}`}
                                className={styles.rowWrapper}
                            >
                                <div className={styles.submoduleWrapper}>
                                    <Checkbox
                                        label={submodule.label}
                                        onClick={() => handleSubmoduleSelect({ submoduleValue: submodule.value, actionTypes: submodule.actionTypes })}
                                        value={
                                            (selectedActionTypes[submodule.value] || []).length === submodule.actionTypes.length ? true : false
                                        }
                                        isIntermediate={
                                            (selectedActionTypes[submodule.value] || []).length && (selectedActionTypes[submodule.value] || []).length !== submodule.actionTypes.length ? true : false
                                        }
                                    />
                                </div>

                                <div className={styles.actionTypesWrapper}>
                                    {submodule.actionTypes.map((actionType) => (
                                        <Checkbox
                                            key={`action_types_${submodule.value}_${actionType.value}`}
                                            label={actionType.label}
                                            onClick={() => handleActionTypeSelect({ submoduleValue: submodule.value, actionTypeValue: actionType.value })}
                                            value={(selectedActionTypes[submodule.value] || []).includes(actionType.value)}
                                            disabled={!actionType.isApplicable}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.columnFooter}>
                        <Button variant="PINK" onClick={handleAddClick}>Add</Button>
                    </div>
                </BaseCard>

                <BaseCard className={cx(styles.column, styles.columnAssignedModules)} variant='secondary'>
                    <div className={styles.columnHeader}>
                        <p>Assigned Modules</p>
                    </div>
                    {formErrorData.moduleData && <span className={styles.columnHeaderError}>{formErrorData.moduleData}</span>}

                    <div className={styles.columnBody}>
                        <div>
                        {formData.moduleData.map((module, moduleIndex) => (
                            <button
                                key={`assigned_module_${moduleIndex}`}
                                className={styles.assignedModuleListItem}
                                onClick={() => handleRemoveClick({ moduleIndex, moduleId: module.moduleId })}
                            >
                                {module.module}
                                <img src="/images/darkSVGIcon/cancel.svg" alt="cancel icon" />
                            </button>
                        ))}
                        </div>
                    </div>

                    <div className={styles.columnFooter}>
                        <Button variant="PINK" onClick={handleSaveTemplateClick}>Save Template</Button>
                    </div>
                </BaseCard>
            </div>
        </BaseCard>
    )
}

AccessTemplateAddEditForm.defaultProps = {
    isEditEnabled: false,
    templateData: {},
}

AccessTemplateAddEditForm.propTypes = {
    masterModules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSaveTemplateClick: PropTypes.func.isRequired,
    templateData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        modules: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            subModules: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
                actionTypes: PropTypes.arrayOf(PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.string,
                })),
            })).isRequired,
        })).isRequired,
    }),
    isEditEnabled: PropTypes.bool,
    templateNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default AccessTemplateAddEditForm;