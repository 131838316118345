import ActionTypes from '../../actionTypes';
const recordActionTypes = ActionTypes.RECORDS;

const initialState = {
	vehicleStatusData: [],
	vehicleStatusDataLoading: true,
};
const reportReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case recordActionTypes.GET_VEHICLE_STATUS:
			return {
				...state,
				vehicleStatusData: payload.data || [],
				vehicleStatusDataLoading: false,
			};
		default:
			return state;
	}
};

export default reportReducer;
