import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import { customToast } from "../../../utils/toasts";
import { NotificationMessage } from "../../../utils/constants";
import {  errorToast } from '../../../utils/toasts';
const { GET_ALL_DATA_FAIL, GET_ALL_DATA_SUCCESS, GET_STOPPAGE_DATA_SUCCESS, GET_STOPPAGE_DATA_FAILED } = ActionTypes.REPORTS.TRIP;
const { GET_ALL_TRIPS, EXPORT_TRIPS_DATA, GET_STOPPAGE_DATA } = API_BOOK.REPORTS.TRIP;

/* Action dispatchers (AD) starts here */ 

const getAllTripsSuccessAD = (payload) => ({
  type: GET_ALL_DATA_SUCCESS,
  payload,
});

const getAllTripsFailAD = () => ({
  type: GET_ALL_DATA_FAIL
});

const getStoppageDurationSuccessAD = (payload) => ({
  type: GET_STOPPAGE_DATA_SUCCESS,
  payload
})

const getStoppageDurationFailedAD = () => ({
  type: GET_STOPPAGE_DATA_FAILED
})

/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get all trips api action
 *  @author       Deepak Prajapati
 *  @api          /tripReports
 *  @method       POST
 *  @createdDate  07-Jul-2023
 *  @modifiedDate 07-Jul-2023
 **/
export const GetAllTrips = ({ registrationNumbers, fromDate, toDate, pageSize, pageIndex,intervalDuration ,oemAndModels,city,}) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_TRIPS.method,
      url: GET_ALL_TRIPS.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        pageSize,
        pageIndex,
        intervalDuration,
        oemAndModels,
        city,
       }
    });
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllTripsSuccessAD(response));
    } else {
      resp.data.error.forEach(error => {
        errorToast({ message: error.message })
    });
      dispatch(getAllTripsFailAD());
    }
  } catch (error) {
    dispatch(getAllTripsFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Export trips report api action
 *  @author       Deepak Prajapati
 *  @api          /tripReports/export
 *  @method       POST
 *  @createdDate  10-Jul-2023
 *  @modifiedDate 10-Jul-2023
 **/
export const ExportTripsReport = ({ registrationNumbers, fromDate, toDate,oemAndModels,city }) => async (dispatch) => {
  try {
    const resp = await api({
      method: EXPORT_TRIPS_DATA.method,
      url: EXPORT_TRIPS_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        oemAndModels,
        city,
        timezoneOffset: new Date().getTimezoneOffset()
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
};

/**
 *  @desc         Get trips stoppage duration api action
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /stoppage/:_id
 *  @method       GET
 *  @createdDate  14-FEB-2024
 *  @modifiedDate 14-FEB-2024
 **/
export const GetStoppageDurationData = ({ _id }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_STOPPAGE_DATA.method,
      url: GET_STOPPAGE_DATA.url.replace(':_id', _id)
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getStoppageDurationSuccessAD(response));
    } else {
      dispatch(getStoppageDurationFailedAD());
    }
  } catch (error) {
    dispatch(getStoppageDurationFailedAD());
    console.log("error:", error);
  }
};

/* Actions ends here */ 