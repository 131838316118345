import API from "../../service/api";
import ActionTypes from "../actionTypes";
import API_BOOK from "../../service/endpoints";
import { customToast } from "../../utils/toasts";

const {
    GET_ALL_USERS_LIST,
    GET_ALL_USERS_LIST_EXCEPTION,
    GET_USER_BY_ID,
    GET_USER_BY_ID_EXCEPTION,
    CREATE_USER,
    CREATE_USER_EXCEPTION,
    EDIT_USER,
    EDIT_USER_EXCEPTION,
    BULK_USER_UPLOAD,
    BULK_USER_EXCEPTION,
    DELETE_USER_EXCEPTION,
    GET_ALL_PENDIG_SIGNUP_USER_LIST,
    GET_ALL_PENDIG_SIGNUP_USER_LIST_EXCEPTION,
    RESEND_EMAIL,
    RESEND_EMAIL_EXCEPTION
 } = ActionTypes.USERS

/* Action dispatchers (AD) starts here */ 

const getAllUsersSuccessAD = (payload) => ({
    type: GET_ALL_USERS_LIST,
    payload,
  });
const getAllUsersFailedAD = () => ({
    type: GET_ALL_USERS_LIST_EXCEPTION
  });
  const getAllPendingSignUpUsersSuccessAD = (payload) => ({
    type: GET_ALL_PENDIG_SIGNUP_USER_LIST,
    payload,
  });
const getAllPendingSignUpUsersFailedAD = () => ({
    type: GET_ALL_PENDIG_SIGNUP_USER_LIST_EXCEPTION
  });

const getUserByIdSuccessAD = (payload) => ({
    type : GET_USER_BY_ID,
    payload
})
const getUserByIdFailedAD = () => ({
    type : GET_USER_BY_ID_EXCEPTION,
})

const createUserSuccessAD = (payload) => ({
    type: CREATE_USER,
    payload
})

const createUserFailedAD = () => ({
    type: CREATE_USER_EXCEPTION,
})

const EditUserSuccessAD = (payload) => ({
    type: EDIT_USER,
    payload
})

const EditUserFailedAD = () => ({
    type: EDIT_USER_EXCEPTION,
})

const DeleteUserFailedAD = () => ({
  type: DELETE_USER_EXCEPTION,
})

const ResendEmailSuccessAD = (payload) => ({
  type: RESEND_EMAIL,
  payload
})
const ResendEmailFailedAD = () => ({
  type: RESEND_EMAIL_EXCEPTION,
})

const ApproveUserByAdminSuccessAD = (payload) => ({
  type: RESEND_EMAIL,
  payload
})
const ApprovalUserByAdminFailedAD = () => ({
  type: RESEND_EMAIL_EXCEPTION,
})

const BulkUploadUsersSuccessAD = (payload) => ({
  type: BULK_USER_UPLOAD,
  payload
})

const BulkUploadUsersFailedAD = (payload ) => ({
  type: BULK_USER_EXCEPTION,
  payload  
})

  /* Action dispatchers ends here */ 
  /* Actions starts here */ 
  const GetAllUser = async ({ searchKey, pageSize, pageIndex }) => {
    const resp = await API({
      method: API_BOOK.USER_MANAGEMENT.GET_ALL_USERS.method,
      url: API_BOOK.USER_MANAGEMENT.GET_ALL_USERS.url,
      params: { searchKey, pageSize, pageIndex },
    });
  
    return resp;
  };
  
  /**
 *  @desc         Get all users
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /rauser/users
 *  @method       GET
 *  @createdDate  14-Sep-2023
 *  @modifiedDate 14-Sep-2023
 **/

  export const GetAllUsers = () => async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.GET_ALL_USERS.method,
        url: API_BOOK.USER_MANAGEMENT.GET_ALL_USERS.url,
        
      });
    
      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data
        dispatch(getAllUsersSuccessAD(response));
      } else {
        dispatch(getAllUsersFailedAD());
      }
    } catch (error) {
      dispatch(getAllUsersFailedAD());
      console.log("error:", error);
    }
  };
  
   /**
 *  @desc         Get user by id
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /rauser/:_id
 *  @method       GET
 *  @createdDate  14-Sep-2023
 *  @modifiedDate 14-Sep-2023
 **/

  export const GetUserById = ({_id}) => async (dispatch) => {    
    try {
      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.GET_USER_BY_ID.method,
        url: API_BOOK.USER_MANAGEMENT.GET_USER_BY_ID.url.replace(":_id", _id)
        
      });
    
      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data[0]
        dispatch(getUserByIdSuccessAD(response));
      } else {
        dispatch(getUserByIdFailedAD());
      }
    } catch (error) {
      dispatch(getUserByIdFailedAD());
      console.log("error:", error);
    }
  };

/**
 *  @desc         Create user
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /rauser/create
 *  @method       POST
 *  @createdDate  14-Sep-2023
 *  @modifiedDate 14-Sep-2023
 **/

export const CreateUser = ({data, navigateTo}) => async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.CREATE_USER.method,
        url: API_BOOK.USER_MANAGEMENT.CREATE_USER.url,
        data
        
      });
    
      if (resp.status === 200) {
        dispatch(createUserSuccessAD(true));            
        setTimeout(() => {
            customToast({ message: resp?.data?.message });
          }, 400);
        
          navigateTo('/ra-user')

          setTimeout(() => {
            dispatch(createUserSuccessAD(false));
          }, 3000);
      } else {
        dispatch(createUserFailedAD());
      }
    } catch (error) {
      dispatch(createUserFailedAD());
      console.log("error:", error);
    }
  };

/**
 *  @desc         Edit user
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /rauser/edituser/:_id
 *  @method       PUT
 *  @createdDate  14-Sep-2023
 *  @modifiedDate 14-Sep-2023
 **/

export const EditUser = ({data, navigateTo}) => async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.EDIT_USER.method,
        url: API_BOOK.USER_MANAGEMENT.EDIT_USER.url.replace(':_id', data?._id),
        data            
      });
    
      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data
        setTimeout(() => {
          customToast({ message: resp?.data?.message });
        }, 400);
        dispatch(EditUserSuccessAD(response));
        navigateTo('/ra-user')
      } else {
        dispatch(EditUserFailedAD());
      }
    } catch (error) {
      dispatch(EditUserFailedAD());
      console.log("error:", error);
    }
  };

  /**
 *  @desc         Bulk Upload users
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /rauser/upload
 *  @method       POST
 *  @createdDate  15-Sep-2023
 *  @modifiedDate 15-Sep-2023
 **/

  export const BulkUploadUsers =
  ({ file }) =>
  async (dispatch) => {    
    try {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);

      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.BULK_UPLOAD_USERS.method,
        url: API_BOOK.USER_MANAGEMENT.BULK_UPLOAD_USERS.url,
        data: formData,
      });

      if (resp.status === 200) {
        GetAllUsers()
        await dispatch(BulkUploadUsersSuccessAD(true));
        await dispatch(BulkUploadUsersFailedAD(''))
        setTimeout(() => {
          customToast({ message: resp?.data?.message });
          GetAllUsers()
        }, 400);
       
        setTimeout(() => {
          dispatch(BulkUploadUsersSuccessAD(false));
        }, 5000);
      }
      else if (resp.status === 400 || resp.status === 500) {
        console.log("error:",resp);
        await dispatch(BulkUploadUsersFailedAD(resp.data.error));
        setTimeout(async() => {
          await dispatch(BulkUploadUsersFailedAD(""));
        }, 4000);
      }
    } catch (error) {      
      console.log("error:", error);
    }
  };
  /**
 *  @desc         Delete user
 *  @author       Bhavin Patel
 *  @api          /rauser/deleteuser/:_id
 *  @method       POST
 *  @createdDate  03-OCT-2023
 *  @modifiedDate 03-OCT-2023
 **/

export const DeleteUser =
  ({ data, pageSize, pageIndex }) =>
  async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.DELETE_USER.method,
        url: API_BOOK.USER_MANAGEMENT.DELETE_USER.url.replace(
          ":_id",
          data?._id
        ),
        data,
      });

      if (resp.status === 200) {
        setTimeout(async () => {
          customToast({ message: resp?.data?.message });
          const userData = await GetAllUser({
            pageSize: 8,
            pageIndex: 0,
          });
          if (userData.status === 200 && userData?.data?.data) {
            const response = userData?.data?.data;
            dispatch(getAllUsersSuccessAD(response));
          }
        }, 400);
      } else {
        customToast({ message: resp?.data?.message });
        dispatch(DeleteUserFailedAD());
      }
    } catch (error) {   
      console.log("error:", error);
    }
  };
  /**
 *  @desc         Get all pending signup users
 *  @author       Bhavin Patel
 *  @api          /user/pending-signup
 *  @method       GET
 *  @createdDate  04-Oct-2023
 *  @modifiedDate 04-Oct-2023
 **/

  export const GetAllPendingSignUpUsers = ({searchKey}) => async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.USER_MANAGEMENT.GET_ALL_PENDING_SIGNUP_USERS.method,
        url: API_BOOK.USER_MANAGEMENT.GET_ALL_PENDING_SIGNUP_USERS.url,
        params:{
          searchKey:searchKey
        },
      });
    
      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data
        dispatch(getAllPendingSignUpUsersSuccessAD(response));
      } else {
        dispatch(getAllPendingSignUpUsersFailedAD());
      }
    } catch (error) {
      dispatch(getAllPendingSignUpUsersFailedAD());
      console.log("error:", error);
    }
  };

    /**
 *  @desc         Resend Email
 *  @author       Bhavin Patel
 *  @api          /user/resend-email/:_id
 *  @method       POST
 *  @createdDate  05-OCT-2023
 *  @modifiedDate 05-OCT-2023
 **/

export const ResendEmail = ({data}) => async (dispatch) => {
  try {
    const resp = await API({
      method: API_BOOK.USER_MANAGEMENT.RESEND_EMAIL.method,
      url: API_BOOK.USER_MANAGEMENT.RESEND_EMAIL.url.replace(':_id', data?._id),
      data            
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      setTimeout(() => {
        customToast({ message: resp?.data?.message });
      }, 400);
      dispatch(ResendEmailSuccessAD(response));
    } else {
      customToast({ message: resp?.data?.message });
      dispatch(ResendEmailFailedAD());
    }
  } catch (error) {
    dispatch(ResendEmailFailedAD());
    console.log("error:", error);
  }
};

    /**
 *  @desc         Approve Reject RA user Request by Admin
 *  @author       Bhavin Patel
 *  @api          /user/ra-action-by-admin
 *  @method       POST
 *  @createdDate  16-OCT-2023
 *  @modifiedDate 16-OCT-2023
 **/

    export const ApprovalUserByAdmin = ({data}) => async (dispatch) => {
      try {
        const resp = await API({
          method: API_BOOK.USER_MANAGEMENT.APPROVAL_USER_BY_ADMIN.method,
          url: API_BOOK.USER_MANAGEMENT.APPROVAL_USER_BY_ADMIN.url,
          data            
        });
        if (resp.status === 200) {
          const response = resp?.data?.data
          setTimeout(() => {
            customToast({ message: resp?.data?.message });
          }, 400);
          dispatch(ApproveUserByAdminSuccessAD(response));
          dispatch(GetAllPendingSignUpUsers({searchKey:""}));
        } else {
          customToast({ message: resp?.data?.message,svgImageName: '400_status'});
          dispatch(ApprovalUserByAdminFailedAD());
        }
      } catch (error) {
        dispatch(ApprovalUserByAdminFailedAD());
        console.log("error:", error);
      }
    };