import React, { useState,memo } from 'react';
import PropTypes from 'prop-types';
import style from './Table.module.css';

const TableC = ({ columns, data, handleSortHandler, message }) => {
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');



  const handleSort = (field) => {
    if (sortField === field) {
      if (handleSortHandler) {
        handleSortHandler(field, sortOrder === 'asc' ? 'desc' : 'asc');
      }
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
     
      
    } else {
      setSortField(field);
      setSortOrder('asc');
      if (handleSortHandler) {
        handleSortHandler(field, 'asc');
      }
    }
  };

  const NoData = ({ colSpan }) => (
    <tr>
      <td colSpan={colSpan} className={`${style.table_td_no_data}`}>
        <div className={`${style.no_data_container}`}>
          <div>
            <img
              src="/images/svgicon/no-data-icon-table.svg"
              alt="No data"
              height={147}
              width={147}
            />
          </div>

          <div className="no-record-found-label">
            <span className={`${style.no_data_text}`}>{message}</span>
          </div>
        </div>
      </td>
    </tr>
  );

  return (
    <table className={`${style.table} table`}>
      <thead>
        <tr>
          {columns?.map((col) => (
            <th
              key={col.key}
              className={`${style.table_th} ${col.sortable ? style.sortable : ''}`}
              onClick={() => col.sortable && handleSort(col?.sortFieldName)}
            >
              {col.header}
              {col.sortable && (
                <> 
                  <span className={`${style.sort_icon} ${sortField === col.key }`}>
                    {sortField === col?.sortFieldName && sortOrder === 'asc' ? (
                      <img className={`${style.arrow}`} alt="upArrow" src="/images/upperArrow.svg" />
                    ) : (
                      <img className={`${style.arrow}`}  alt="upArrowDisable" src="/images/upperArrowDisable.svg" />
                    )}
                  </span>
                  <span className={`${style.sort_icon} ${sortField === col.key }`}>
                    {sortField === col?.sortFieldName && sortOrder === 'desc' ? (
                      <img className={`${style.arrow}`}  alt="downArrow" src="/images/downArrow.svg" />
                    ) : (
                      <img className={`${style.arrow}`}  alt="downArrowDisable" src="/images/downArrowDisable.svg" />
                    )}


                  </span>
                </>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length > 0  ? (
          data?.map((row, index) => (
            <tr key={index} className={`${style.table_tr}`}>
              {columns.map((col) => (
                <td key={col.key} className={`${style.table_td}`}>
                  {col.renderCell ? col.renderCell(row, index) : row[col.key]}
                </td>
              ))}

            </tr>
          ))
        ) : (
          <NoData colSpan={columns.length} />
        )}
      </tbody>
    </table>
  );
};


TableC.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      header: PropTypes.node.isRequired,
      sortable: PropTypes.bool,
      sortFieldName:PropTypes.string,
      renderCell: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  handleSortHandler: PropTypes.func,
  message: PropTypes.string,
};

TableC.defaultProps = {
  handleSortHandler: null,  
  message: 'No records found.',
};

export default memo(TableC);
/*conflict-resolution-will be removed later*/