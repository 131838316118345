import { connect } from 'react-redux';
import { Stack} from 'react-bootstrap'
import "./Filters.css"
import PropTypes from 'prop-types';
import { onDateChangeHandler, setSelectedDuration } from '../../../../../store/actions/reports/analytics/trip-report'
import { useRef, useState } from 'react';
import SingleDatePicker from '../../../../helpers/hoc/single-date-picker/SingleDatePicker';
import moment from 'moment';
import { useEffect } from 'react';

const Filters = ({
    isCustomDateSelected,
    selectedDuration,
    onDateChangeHandler,
    setSelectedDuration,
    ...props
}) => {
    const baseTestId = props['data-testid'] || 'filters'
    const [ selectedDate, setSelectedDate ] = useState('Custom')
    const datePickerRef = useRef(null)
    const onDateFilterChange = (startDate, endDate) => {
        onDateChangeHandler({startDate, endDate})
        const date = `${moment(startDate).format('DD-MMM-YYYY')} ~ ${moment(endDate).format('DD-MMM-YYYY')}`
        setSelectedDate(date)
    }

    useEffect(() => {
        if(!isCustomDateSelected) {
            setSelectedDate('Custom')
        }
    }, [isCustomDateSelected])

    useEffect(() => {
        return () => {
            setSelectedDuration('today')
        }
    }, [setSelectedDuration])

        return (
            <Stack direction="horizontal" gap={3}>
                <div className='ms-auto'>
                <>
                    <div style={{visibility: 'hidden'}}>
                        <SingleDatePicker key={'datepicker-analytics' + 1}
                        maxRangeDays={31} 
                        ref = {datePickerRef} 
                        onDateChange = {onDateFilterChange}
                        data-testid={baseTestId}
                        />
                    </div>
                    <div 

                        className={'trip-analytics-filter-button ' + (isCustomDateSelected? 'selected' : '')}
                        onClick={() => datePickerRef.current.open()}
                        data-testid={`${baseTestId}-rangepicker-button`}
                    >
                        {selectedDate}
                        { isCustomDateSelected ?
                            <img style={{marginLeft: '5px'}} height={16} width={16} src={'/images/svgicon/datepicker_white.svg'} alt='datepicker icon' />
                            :<img style={{marginLeft: '5px'}} height={16} width={16} src={'/images/svgicon/datepicker.svg'} alt='datepicker icon' />
                        }
                    </div>
                    </>
                </div>
                
                <div 

                    className={'trip-analytics-filter-button ' + (!isCustomDateSelected && selectedDuration === "7days" ? 'selected' : '')}
                    onClick={()=> setSelectedDuration("7days")}
                    data-testid={`${baseTestId}-7days-button`}
                >
                    7 days
                </div>

                <div 
                className={'trip-analytics-filter-button ' + (!isCustomDateSelected && selectedDuration === "yesterday" ? 'selected' : '')} 
                onClick={()=> setSelectedDuration("yesterday")}
                data-testid={`${baseTestId}-yesterday-button`}
                >
                    Yesterday
                </div>

                <div 
                    className={'trip-analytics-filter-button ' + (!isCustomDateSelected && selectedDuration === "today" ? 'selected' : '')}
                    onClick={()=> setSelectedDuration("today")}
                    data-testid={`${baseTestId}-today-button`}
                >
                    Today
                </div>
            </Stack>
        )
}

Filters.propTypes = {
    isCustomDateSelected: PropTypes.bool.isRequired,
    selectedDuration: PropTypes.string.isRequired,
    onDateChangeHandler: PropTypes.func.isRequired,
    setSelectedDuration: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    selectedDuration: state.tripReportAnalytics.selectedDuration,
    isCustomDateSelected: state.tripReportAnalytics.isCustomDateSelected
});

export default connect(mapStateToProps, {
    onDateChangeHandler,
    setSelectedDuration
})(Filters);