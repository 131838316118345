import ActionTypes from "../../actionTypes";
const GROUPS = ActionTypes.GROUPS;


const initialState = {
    groupsListData: [],
    groupsListDataLoading: true,
    groupsDeleteData: [],
    groupsDeleteDataLoading: true,
    groupsRenameData: [],
    groupsRenameDataLoading: true,
    groupsSaveData: [],
    groupsSaveDataLoading: true,
    groupsEditData: [],
    groupsEditDataLoading: true,
    groupsAssignDepartmentAdmin: [],
    groupsAssignDepartmentAdminLoading: true,
    groupsAssignDepartmentUser: [],
    groupsAssignDepartmentUserLoading: true,
    groupAssignList:[],
    groupAssignListLoading:true

  };

  const groupsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    
    switch (type) {
      case GROUPS.GROUPS_LIST:
        return {
          ...state,
          groupsListData: payload,
          groupsListDataLoading:false,
        };
        case GROUPS.GROUPS_DELETE:
        return {
          ...state,
          groupsDeleteData: payload,
          groupsDeleteDataLoading:false,
        };
        case GROUPS.GROUPS_RENAME:
        return {
          ...state,
          groupsRenameData: payload,
          groupsRenameDataLoading:false,
        };
        case GROUPS.GROUPS_SAVE:
        return {
          ...state,
          groupsSaveData: payload,
          groupsSaveDataLoading:false,
        };
        case GROUPS.GROUPS_EDIT:
        return {
          ...state,
          groupsEditData: payload,
          groupsEditDataLoading:false,
        };
        case GROUPS.GET_ASSIGN_DEPARTMENT_ADMIN:
          return {
            ...state,
            groupsAssignDepartmentAdmin:payload,
            groupsAssignDepartmentAdminLoading:false
          };
          case GROUPS.GET_ASSIGN_DEPARTMENT_USER:
          return {
            ...state,
            groupsAssignDepartmentUser:payload,
            groupsAssignDepartmentUserLoading:false
          }
          case GROUPS.GET_ASSIGN_LIST:
          return {
            ...state,
            groupAssignList:payload,
            groupAssignListLoading:true
          }
      default:
        return state;
    }
  };




  export default groupsReducer
