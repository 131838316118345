import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import "../ReportTrip.css";
import Filters from "./Filters";
import GroupedDataCharts from "./GroupedDataCharts";
import AreaChart from "./AreaChart";

const Analytics = (props) => {
  const baseTestId = props['data-testid'] || 'analytics'
  return (
    <div className="col-12">
      <Container fluid className="analytics-container">
        <Row className="trip-analytics-box-shadow">
          <Col xs={12} className="row trip-analytics-main-container-main">
            <Filters data-testid={`${baseTestId}-filters`}></Filters>
            <br></br>
            <GroupedDataCharts data-testid={`${baseTestId}-datachart`}></GroupedDataCharts>
            <br></br>
            <AreaChart data-testid={`${baseTestId}-chartarea`}></AreaChart>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Analytics.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Analytics);
