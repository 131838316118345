import React from "react";
import PropTypes from "prop-types";

import ArrowToggle from "../../toggles/arrowToggle/ArrowToggle";

import styles from "./DashboardStat.module.css";

const formatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })

const DashboardStat = ({ title, count, vendors, units }) => {

    return (
        <div className={styles.container}>
            <div className="d-flex flex-column justify-content-evenly h-100">
                <span className={styles.title}>{title}</span>
                <div className="d-flex justify-content-start align-items-baseline gap-1">
                    <span className={styles.count}>
                        {formatter.format(count)}
                    </span>
                    <span className={styles.units}>{units}</span>
                </div>
            </div>
            <ArrowToggle data={vendors} unit={units} />
        </div>
    );
};

DashboardStat.defaultProps = {
    title: "Total users",
    count: 0,
    vendors: [],
    units: '',
};

DashboardStat.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    vendors: PropTypes.array.isRequired,
    units: PropTypes.string,
};

export default DashboardStat;
/*conflict-resolution-will be removed later*/