
const initialState = {
  searchQuery: "",
  zoomLevelReducer: 5,
  randomKeyReducer: 0,
  allStatusClicked : false,
  latLongDefault: {
    lat:20.5937,
    long:78.9629,
  },
  singleVehicleModal: false
};

const topPaneSearchbarReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "topSearch":
      return {
        ...state,
        searchQuery: payload,
      };
    case "zoomLevelReducer":
      return {
        ...state,
        zoomLevelReducer: payload,
      };
      case "setAllTopStatus":
      return {
        ...state,
        allStatusClicked: payload,
      };
    case "randomKeyReducer":
      return {
        ...state,
        randomKeyReducer: payload,
      };
    case "latLongChange":
      if(!payload) {
        return {
          ...state,
          latLongDefault: {...state.latLongDefault}
        }
      }
      return {
        ...state,
        latLongDefault: payload,
      };
    case "singleVehicleModal":
      return {
        ...state,
        singleVehicleModal: payload,
      };

    default:
        return state      
  }
};
export default topPaneSearchbarReducer;