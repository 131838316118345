import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Tabs.module.css'

const Tabs = (props) => {
    const { tabHeaders, keyExtractor, onChange, value,variant } = props

    const handleTabClick = (tabValue) => {
        onChange(tabValue);
    }

    if (variant === 'secondary') {
        return (
            <div className={styles.defaulttabsContainer}>
                {tabHeaders.map((tab, tabIndex) => (
                    <div
                        key={keyExtractor(tab, tabIndex)}
                        className={cx(styles.defaulttab, {[styles.active]: tab.value === value})}
                        onClick={() => handleTabClick(tab.value)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
        )
      }

    return (
        <div className={styles.tabsContainer}>
            {tabHeaders.map((tab, tabIndex) => (
                <div
                    key={keyExtractor(tab, tabIndex)}
                    className={cx(styles.tab, {[styles.active]: tab.value === value})}
                    onClick={() => handleTabClick(tab.value)}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    )
}

Tabs.propTypes = {
    keyExtractor: PropTypes.func,
    tabHeaders: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    variant:'default'
}

Tabs.defaultProps = {
    keyExtractor: (tab) => `tab_${tab.value}`
}

export default React.memo(Tabs)
/*conflict-resolution-will be removed later*/