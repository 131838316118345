import actionTypes from '../../actionTypes'

const dashboardAction = {}

dashboardAction.setAllDashboardVehicleData = (payload) => ({
    type: actionTypes.DASHBOARD.SET_ALL_DASHBOARD_VEHICLES_DATA,
    payload
})

dashboardAction.setTopPanelVehicleStatus = (payload) => ({
    type: actionTypes.DASHBOARD.SET_TOP_PANEL_VEHICLE_STATUS,
    payload
})

dashboardAction.setPrevTopPanelVehicleStatus = (payload) => ({
    type: actionTypes.DASHBOARD.SET_PREV_TOP_PANEL_VEHICLE_STATUS,
    payload
}) 


dashboardAction.setFilter = (payload) => ({
    type: actionTypes.DASHBOARD.SET_ALL_FILTERS,
    payload
})



dashboardAction.getTrackingChargingDetails = (payload) => ({
    type: actionTypes.VEHICLE.VEHICLE.GET_VEHICLE_TRACKING_CHARGING_DETAILS_SUCCESSS,
    payload
})

dashboardAction.setTopPanelSearchQuery = ({ searchQuery = '' }) => ({
    type: actionTypes.DASHBOARD.SET_TOP_PANEL_SEARCH_QUERY,
    payload: {
        searchQuery
    }
})

dashboardAction.updateHomeClickCounter = () => ({
    type: actionTypes.DASHBOARD.UPDATE_HOME_CLICK_COUNTER
})

dashboardAction.getTrackingCanDataDetails = (payload) => ({
    type: actionTypes.VEHICLE.VEHICLE.GET_VEHICLE_TRACKING_CAN_DATA_DETAILS_SUCCESSS,
    payload
})

dashboardAction.resetTrackingDetails = () => ({
    type: actionTypes.DASHBOARD.RESET_TRACKING_DETAILS,
})

export default dashboardAction