import axios from 'axios'

const env = process.env.REACT_APP_HOST_API_URL || 'http://localhost:4010'
axios.defaults.baseURL = env

axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: '',
  },
})

/*
  NOTE: intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated
*/

axios.interceptors.response.use(
  (res) => res,

  async (err) => {
    return Promise.reject(err)
  }
)

export default axios
