import React from 'react'
// import styles from './SALogs.module.css'

const SALogs = () => {
    return (
        <h2>SA Logs Page</h2>
    )
}

export default SALogs
/*conflict-resolution-will be removed later*/