import api from "../../service/api";
import API_BOOK from "../../service/endpoints";
import { customToast } from "../../utils/toasts";
import ActionTypes from "../actionTypes";
const getUserRoleAndMenuList = ActionTypes.USER_ROLE_AND_MENU_MAPPING;
const getUserRoleAndMenuApiConfig = API_BOOK.ROLE_AND_MENU_MAPPING;

const updateUserMenuSuccsess = (payload) => ({
  type: getUserRoleAndMenuList.UPDATE_USER_MENU_MAPPING_SUCCESS,
  payload,
});

const updateUserMenuFail = () => ({
  type: getUserRoleAndMenuList.UPDATE_USER_MENU_MAPPING_SUCCESS,
});

const getAllUSerRole = (payload) => ({
  type: getUserRoleAndMenuList.GET_USER_ROLE_SUCCESS,
  payload,
});

/* Actions starts here */

/**
 *  @desc         Role and menu mapping action
 *  @author       Bhavin patel
 *  @api          /roleAndMenu
 *  @method       GET
 *  @createdDate  11-Aug-2023
 *  @modifiedDate 11-Aug-2023
 **/

export const GetUserRoleList = () => async (dispatch) => {
  try {
    const res = await api({
      url: getUserRoleAndMenuApiConfig.USER_ROLE.url,
      method: getUserRoleAndMenuApiConfig.USER_ROLE.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data;
      dispatch(getAllUSerRole(response));
    }
  } catch (error) {
    console.log("Error getting ", error);
  }
};

const getUserMenu = (payload) => ({
  type: getUserRoleAndMenuList.GET_USER_MENU_SUCCESS,
  payload,
});

/**
 *  @desc         Telematics API for menu mapping action
 *  @author       Bhavin patel
 *  @api          roleAndMenu/user-menu/:role_value
 *  @method       GET
 *  @createdDate  11-Aug-2023
 *  @modifiedDate 11-Aug-2023
 **/

export const getUserMenuByUserRoleId = (role_value) => async (dispatch) => {
  try {
    const res = await api({
      url: getUserRoleAndMenuApiConfig.USER_MENU.url.replace(
        ":role_value",
        role_value
      ),
      method: getUserRoleAndMenuApiConfig.USER_MENU.method,
    });
    if (res.status === 200) {
      const response = res?.data?.data  
      dispatch(getUserMenu(response));
    }
  } catch (error) {
    console.log("Error getting ", error);
  }
};
/**
 *  @desc         Telematics API for updating menu mapping
 *  @author       Bhavin patel
 *  @api          roleAndMenu/update-role-menu
 *  @method       POST
 *  @createdDate  11-Aug-2023
 *  @modifiedDate 11-Aug-2023
 **/
export const updateMenuMapping =
  ({ role, menu }) =>
  async (dispatch) => {
    try {
      const resp = await api({
        method: getUserRoleAndMenuApiConfig.UPDATE_USER_ROLE_MENU.method,
        url: getUserRoleAndMenuApiConfig.UPDATE_USER_ROLE_MENU.url,
        data: { role, menu },
      });

      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data;
        dispatch(updateUserMenuSuccsess(response));
        setTimeout(() => {
          customToast({ message: resp?.data?.message });
        }, 400);
      } else {
        dispatch(updateUserMenuFail());
      }
    } catch (error) {
      dispatch(updateUserMenuFail());
      console.log("error:", error);
    }
  };

  /* Actions ends  here */