import React, { useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import './ViewVehicleOnboardingModal.css';
import { getFormattedDate, addEscapeKeyListener } from '../../../../utils/common-methods';

const ViewVehicleOnboardingModal = ({ showModal, setIsShowModal, data }) => {
	const handleClose = useCallback(() => {
		setIsShowModal(false);
	}, [setIsShowModal]);

	useEffect(() => {
		const removeListener = addEscapeKeyListener(() => {
			handleClose();
		});

		return () => {
			removeListener();
		};
	}, [handleClose]);

	const toPascalCase = (str) => {
		return str
		  .split(' ')                   // Split the string into an array of words by spaces
		  .map(word =>                  // For each word
			word.charAt(0).toUpperCase() + // Capitalize the first letter
			word.slice(1).toLowerCase()   // Lowercase the rest of the word
		  )
		  .join('');                    // Join the words back together without spaces
	}

	return (
		<Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered size='xl'>
			<Modal.Header closeButton></Modal.Header>

			<Modal.Body className='vehicle-onboard-modal-body mt-4'>
				<div
					className='bg-white mb-1 position-relative'
					style={{
						maxHeight: 'calc(80vh - 200px)',
						overflowY: 'auto',
					}}
				>
					<Table responsive={true} borderless id='vehicle-onboarded-model'>
						<thead className='table-header'>
							<tr>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>S.No</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>Vehicle No.</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>OEM & Model</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>Location</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>Non Com Issue</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>Data Source</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>Non Com Days</p>
								</th>
								<th className='ps-1 text-nowrap'>
									<p className='fw500 vehicle-onboarded-th'>Start Date & Time</p>
								</th>
							</tr>
						</thead>
						<tbody>
							{data.map((Obj, index) => (
								<tr key={'row-' + index.toString()}>
									<td>{index + 1}</td>
									<td>{Obj.registration_number}</td>
									<td>{Obj.oem + ' ' + Obj.model}</td>
									<td>{Obj.city || 'N/A'}</td>
									<td>
										{Obj.issue === 'Poor Network'
											? 'No Network'
											: Obj.issue === 'Inactive GPS'
											? 'GPS Not Connected'
											: Obj.issue || 'N/A'}
									</td>
									<td>{Obj.dataSource === 'OEM' ? toPascalCase(Obj.dsrc) + ' API' : Obj.dsrc}</td>
									<td>{Obj.duration !== null ? Obj.duration : 'N/A'}</td>
									<td>{Obj.startDateTime ? getFormattedDate(Obj.startDateTime) : 'N/A'}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
};

export default ViewVehicleOnboardingModal;