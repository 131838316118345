import { GoogleMap, MarkerF } from '@react-google-maps/api';
import PropTypes from "prop-types";
import React, { useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import {
    latlongToAddressAction
} from '../../../../store/actions/map/latlng-to-address.js';
import GoogleMapStyles from "../../../map/Mapstyle";
import "./MapAddressByLocation.css";

const MapAdddressByLocation = ({
    //State
    CoOrdinates,
    isOpen,
    address,
    dmsLat,
    dmsLng,
    previousCoOrdinate,

    //Action
    toggleModal,
    latlongToAddressAction,
    ...props
}) => {
  const baseTestId = props['data-testid'] || 'gps-dialog';

  const handleClose = useCallback(() => {
    latlongToAddressAction(null);
    toggleModal();
  }, [latlongToAddressAction, toggleModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose]);

  useEffect(() => {
    if (CoOrdinates && (previousCoOrdinate?.lat !== CoOrdinates.lat || previousCoOrdinate?.lng !== CoOrdinates.lng)) {
      latlongToAddressAction(CoOrdinates);
    }
  }, [CoOrdinates, latlongToAddressAction, previousCoOrdinate, address]);

    return (
        isOpen &&
        <div className={`popup ${isOpen ? 'open' : ''}`}>

            <div className="updatepopup-content-gmap">
                <div className='popup-title'>
                    GPS
                    <button className="popup-close" data-testid={`${baseTestId}-close-button`} onClick={handleClose}>
                    <img src="/images/svgicon/x-close.svg"
                        alt="location"
                        style={{
                            width: 18,
                            cursor: "pointer"
                        }}></img>
                    </button>
                </div>
                
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                        position: 'unset',
                        color:"gray",
                        colorScheme : "dark"
                    }}
                    center={CoOrdinates}
                    zoom={11}
                    options={
                        {
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            styles: GoogleMapStyles
                        }
                    }>
                    {
                        <MarkerF
                            key={Math.random()}
                            position={CoOrdinates}
                            clickable={true}
                            icon = "/images/svgicon/map-marker.svg"
                        ></MarkerF>
                    }
                </GoogleMap>
                <div className='Address-bar row'>
                    <div className='col-1'>
                        <img src="/images/svgicon/map-marker-blue.svg"
                            alt="location"
                            style={{
                                width: 18,
                                cursor: "pointer"
                            }}></img>
                    </div>
                    <div className='col-11 Add-text-bar'>
                        {address}
                        <div className='Add-coordinate-bar'>
                            {(dmsLat && dmsLng) ? dmsLat : CoOrdinates.lat} , {(dmsLat && dmsLng) ? dmsLng : CoOrdinates.lng}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}



MapAdddressByLocation.propTypes = {
    // States
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    CoOrdinates: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    address: state.latlongToAddress.address,
    dmsLat: state.latlongToAddress?.dmsLatLng?.dmsLat,
    dmsLng: state.latlongToAddress?.dmsLatLng?.dmsLng,
    previousCoOrdinate: state.latlongToAddress.previousCoordinates
});

export default connect(mapStateToProps, {
    latlongToAddressAction
})(React.memo(MapAdddressByLocation));