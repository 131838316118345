import React, { useState, Fragment, useEffect, useRef } from 'react'
import Select, { components } from 'react-select'
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io'

export default function MultiSelect({
  selectedOptions = [],
  setSelectedOptions,
  options = [],
  setNoDataWarning = false,
  placeholder = 'Vehicle Registration No.',
  placeholderOnError = 'Select registration no.',
  valueContainerWidthInPx = '300px',
  valueContainerMaxWidthInPx = '300px',
  menuListPaddingX = 'px-5',
  okButtonPaddingEnd = 'pe-4',
  isMultiSelect = false,
  optionPosition = 'justify-content-start',
  maxMenuHeight = 276,
  customBackgroundColorReq = false,
  customBackgroundColor = '#E7ECF7',
  maxOptionSelectionLimit = options.length,
  onChange = (()=> {})
}) {
  const myDivRef = useRef()
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)

  function handleSelect(data) {
    onChange(!isMultiSelect ? [data] : data)
    setSelectedOptions(!isMultiSelect ? [data] : data)
  }

  function selectAllOpts() {
    if (options.length !== selectedOptions?.length) {
      setSelectedOptions(options)
    }
  }

  function unSelectAllOpts() {
    setSelectedOptions([])
  }

  function toggleSelectMenuOpenClose(bool) {
      setIsSelectMenuOpen(bool)
  }

  const MenuList = (props) => {
    return (
      <Fragment>
        {isMultiSelect && options.length === maxOptionSelectionLimit && (
          <div
            className={`d-flex flex-row align-items-center justify-content-between ${menuListPaddingX} py-3`}
          >
            <button
              onClick={() => {
                selectAllOpts()
              }}
              className="multi-select-menu-buttons"
            >
              Select all
            </button>

            <button
              onClick={() => {
                unSelectAllOpts()
              }}
              className="multi-select-menu-buttons"
            >
              Clear all
            </button>
          </div>
        )}
        <components.MenuList {...props}>
          {props?.children?.length
            ? props.children.sort(
                (a, b) => b.props.isSelected - a.props.isSelected
              )
            : props.children}
        </components.MenuList>
        <div
          className={`d-flex flex-row justify-content-end py-2 ${okButtonPaddingEnd}`}
        >
        </div>
      </Fragment>
    )
  }

  const Option = (props) => {
    return (
      <div className='select-option-div'>
        <components.Option {...props}>
          {isMultiSelect ? (
            <div
              className={`d-flex flex-row align-items-center ${optionPosition} gap-2 w-100`}
            >
              <div
                className={`d-flex flex-row align-items-center ps-2 justify-content-start gap-2 ${
                  optionPosition === 'justify-content-center' ? 'w-50' : ''
                }`}
              >
                {!props.isSelected && (
                  <IoIosSquareOutline color="#707070" size={20} />
                )}
                {props.isSelected && (
                  <IoIosCheckbox color="#1f2a5d" size={20} />
                )}
                <label>{props.label}</label>
              </div>
            </div>
          ) : (
            <div
              className={`d-flex flex-row ${optionPosition} gap-2 w-100`}
            >
              <div
                className={`d-flex flex-row  ps-2 justify-content-start gap-2 `}
              >
                <label>{props.label}</label>
              </div>
            </div>
          )}
        </components.Option>
      </div>
    )
  }

  if (customBackgroundColorReq) {
    document
      .getElementsByClassName('css-13cymwt-control')[0]
      ?.setAttribute(
        'style',
        `background-color: ${customBackgroundColor} !important; border-color: ${customBackgroundColor} !important; height: 38px !important`
      )
  }

  // Function to execute when clicking outside the div
  function handleClickOutside(event) {
    if ((myDivRef.current && !myDivRef.current.contains(event.target))) {
      toggleSelectMenuOpenClose(false) 
      return
    }
  }

  function handleClick(event) {
    if(isMultiSelect){
      event.stopPropagation()
    }
    toggleSelectMenuOpenClose(true) 
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={myDivRef}
      onClick={handleClick}


    >
      <Select
        options={options}
        id="react-mselect"
        closeMenuOnSelect={false}
        placeholder={setNoDataWarning ? placeholderOnError : placeholder}
        value={selectedOptions}
        maxMenuHeight={maxMenuHeight}
        onChange={handleSelect}
        menuIsOpen={isSelectMenuOpen}
        styles={{
          valueContainer: (styles, state) => {
            return {
              ...styles,
              textOverflow: 'ellipsis',
              width: valueContainerWidthInPx,
              maxWidth: valueContainerMaxWidthInPx,
              backgroundColor: customBackgroundColor,
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              justifyContent: 'center',
              display: 'flex',
              active: {
                backgroundColor: customBackgroundColor,
              },
            }
          },
          placeholder: (styles, state) => {
            return {
              ...styles,
              color: `${
                state.children === placeholder ? 'hsl(0, 0%, 50%)' : 'red'
              }`,
            }
          },
          option: (styles, { isDisabled, isSelected }) => {
            return {
              ...styles,
              backgroundColor:
                isDisabled && !isSelected ? '#edf0ef' : '#FFFFFF',
              color: '#1f2a5d',
              textAlign: 'center',
              fontFamily: 'inherit',
              fontSize: '14px',
              cursor: isDisabled ? 'not-allowed' : 'default',
              ':active': {
                ...styles[':active'],
                backgroundColor: '#FFFFFF',
              },
            }
          },
        }}
        components={{
          MultiValueContainer: ({ selectProps, data }) => {
            const label =
              selectProps.value.length > 1 ? `${data.label}, ` : data.label
            const val = `${selectProps.inputValue ? '' : label}`
            return val
          },
          Option: Option,
          MenuList: MenuList,
        }}
        isOptionDisabled={(option, selectValue) => {
          return (
            selectedOptions.length >= maxOptionSelectionLimit &&
            !selectValue.map((val) => val.label).includes(option.label)
          )
        }}
        isMulti={isMultiSelect}
        isSearchable={true}
        hideSelectedOptions={false}
        isClearable = { true }
        allowSelectAll={
          isMultiSelect && selectedOptions.length === maxOptionSelectionLimit
        }
        className={setNoDataWarning ? 'box-shadow-danger' : ''}
      />
    </div>
  )
}
