import React, { useEffect, useState } from 'react';
import { Container, Table, Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './css/nonCommLogs.css';
import Loader from '../../helpers/hoc/loader/Loader';
import NoRecordFound from '../../helpers/hoc/noRecordFound';
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping';
import MultiSelect from '../../helpers/hoc/mult-select/MultiSelect';
import { mapper } from '../../../utils/mapper';
import { immobilizeNonComIssue } from '../../../utils/constants';
import { GetAllNonAnalyticsDayWise, GetAllNonAnalyticsSourceWise } from '../../../store/actions/reports/nonCommLogs';
import ViewVehicleOnboardingModal from './model/ViewNonComModal';
// import ViewVehicleOnboardingModal from './modal/ViewNonComModal';

const NonCommReport = ({
	isLoading,
	GetUserRoleList,
	GetAllNonAnalyticsDayWise,
	allNonCommAnalyticsDayWiseData,
	masterLocationData,
	GetAllNonAnalyticsSourceWise,
	allNonCommAnalyticsSourceWiseData,
}) => {
	const [tempUnresolvedFilters, setTempUnresolvedFilters] = useState({
		DATA_SOURCE: [],
		LOCATION: [],
		NONCOMMTYPES: [],
	});
	const [isShowViewModal, setIsShowViewModal] = useState(false);
	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const [nonCommTypeSource, setNonCommTypeSource] = useState([]);

	const handleFilterChange = ({ name, value }) => {
		setTempUnresolvedFilters((prevState) => ({ ...prevState, [name]: value }));
	};

	const fetchNonCommAnalyticsDayWise = () => {
		GetAllNonAnalyticsDayWise({
			cities: tempUnresolvedFilters.LOCATION?.map((x) => x.value),
			dataSources: tempUnresolvedFilters.DATA_SOURCE?.map((x) => x.value),
			nonCommTypes: tempUnresolvedFilters.NONCOMMTYPES?.map((x) => x.value),
		});
	};
	const fetchNonCommAnalyticsSourceWise = () => {
		GetAllNonAnalyticsSourceWise({
			nonCommTypes: nonCommTypeSource?.map((x) => x.value),
		});
	};

	useEffect(() => {
		GetUserRoleList();
		fetchNonCommAnalyticsDayWise();
		fetchNonCommAnalyticsSourceWise();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [GetUserRoleList, tempUnresolvedFilters, nonCommTypeSource]);

	const mappedOptions = mapper.mapVehicleDataOptions();


	const immobilizeNonComIssueList = immobilizeNonComIssue?.map((d) => ({
		label: d.label,
		value: d.value,
	}));

	const allCounts = allNonCommAnalyticsDayWiseData?.flatMap((vehicle) => vehicle.lables.map((label) => label.count));
	const globalMinCount = Math.min(...allCounts);
	const globalMaxCount = Math.max(...allCounts);

	return (
		<>
			<Container fluid>
				<div className='non_comm_report-tab-container'>
					<Tabs
						defaultActiveKey='non_comm_report'
						className='alert-nav-link'
						style={{ display: 'flex', gap: '20px' }}
					>
						<Tab eventKey='non_comm_report' title='Day Wise'>
							<div className='d-flex col-12 flex-column h-100 mt-4'>
								<div className='d-flex gap-2 align-items-center'>
									<MultiSelect
										options={masterLocationData?.map((x) => {
											return { label: x.label, value: x.value };
										})}
										selectedOptions={tempUnresolvedFilters.LOCATION}
										placeholder='Location'
										setSelectedOptions={(value) => handleFilterChange({ value, name: 'LOCATION' })}
										isMultiSelect={true}
										customBackgroundColor='#EFF3F7'
										webkitScrollbarWidth='5px'
										webkitScrollbarHeight='5px'
										minHeight='45px'
										placeholderAlignment='flex-start'
										indicatorSeparatorDisplay='none'
										placeholderColor='#C6C6C6'
										valueContainerColor='#797694'
										optionPosition='justify-content-start'
										valueContainerWidthInPx='200px'
										valueContainerMaxWidthInPx='200px'
										menuListPaddingX='px-2'
										okButtonPaddingEnd='pe-2'
										data-testid='multi-city-filter'
									/>

									<MultiSelect
										options={mappedOptions.customDataSource.map((option) =>
											option.value === 'OEM_API' ? { ...option, value: 'OEM' } : option
										)}
										selectedOptions={tempUnresolvedFilters.DATA_SOURCE}
										placeholder='Data Source'
										setSelectedOptions={(value) =>
											handleFilterChange({ value, name: 'DATA_SOURCE' })
										}
										isMultiSelect={true}
										customBackgroundColor='#EFF3F7'
										webkitScrollbarWidth='5px'
										webkitScrollbarHeight='5px'
										minHeight='45px'
										placeholderAlignment='flex-start'
										indicatorSeparatorDisplay='none'
										placeholderColor='#C6C6C6'
										valueContainerColor='#797694'
										optionPosition='justify-content-start'
										valueContainerWidthInPx='200px'
										valueContainerMaxWidthInPx='200px'
										menuListPaddingX='px-2'
										okButtonPaddingEnd='pe-2'
										data-testid='data-source-filter'
									/>

									<MultiSelect
										valueContainerWidthInPx='200px'
										valueContainerMaxWidthInPx='200px'
										menuListPaddingX='px-2'
										okButtonPaddingEnd='pe-2'
										placeholderColor='#C6C6C6'
										optionPosition='ps-2 justify-content-start'
										placeholder='Non Com Issue'
										placeholderAlignment='flex-start'
										isMultiSelect={true}
										indicatorSeparatorDisplay='none'
										minHeight='45px'
										valueContainerColor='#797694'
										customBackgroundColor='#EFF3F8'
										options={immobilizeNonComIssueList}
										setSelectedOptions={(value) =>
											handleFilterChange({ value, name: 'NONCOMMTYPES' })
										}
										selectedOptions={tempUnresolvedFilters.NONCOMMTYPES}
										data-testid='nonComIssue-filter'
									/>

									<button
										onClick={() => {
											fetchNonCommAnalyticsDayWise();
										}}
										className='dwnRefresh ms-auto' // Added ms-auto to push button to the right
										data-testid='unresolved-export-button'
									>
										Refresh
										<img
											src='/images/refreshIcon.svg'
											alt='refreshIcon'
											style={{ marginLeft: '8px' }}
										/>
									</button>
								</div>

								{allNonCommAnalyticsDayWiseData?.length ? (
									<div className='bg-white mb-2 mt-4 position-relative trip__log__table'>
										<Table responsive className='mb-0 non-comm-table'>
											<thead>
												<tr className='th-border-bottom position-relative'>
													<th className='fixed-width'>OEM & Model</th>
													{allNonCommAnalyticsDayWiseData?.[0].lables.map((label, index) => (
														<th key={index} className='fixed-width'>
															{label.label}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{allNonCommAnalyticsDayWiseData?.map((vehicle, index) => {
													// Get an array of counts for the current vehicle
													// const counts = vehicle.lables.map((label) => label.count);

													// Calculate the min and max values for this vehicle's counts
													// const minCount = Math.min(...counts);
													// const maxCount = Math.max(...counts);

													// Function to determine the color based on the percentile
													const getColor = (count) => {
														if (globalMinCount === globalMaxCount) {
															return `rgb(0, 128, 128)`; // Fixed muted teal color for equal values
														}

														const percentile =
															(count - globalMinCount) /
															(globalMaxCount - globalMinCount); // Normalize to 0 - 1

														if (percentile <= 0.3) {
															const greenIntensity = Math.floor((120 * percentile) / 0.3); // Balanced green with max value 120
															return `rgb(0, 120, ${greenIntensity})`; // Slightly darker green for lower values
														} else if (percentile >= 0.7) {
															const redIntensity = Math.floor(
																(128 * (1 - percentile)) / 0.3
															); // Duller red
															return `rgb(128, 0, ${redIntensity})`; // Muted red-blue for higher values
														} else {
															const yellowIntensity = Math.floor(
																(128 * (percentile - 0.3)) / 0.4
															); // Duller yellow
															return `rgb(128, 128, ${yellowIntensity})`; // Muted yellow-blue for mid-range values
														}
													};

													return (
														<tr key={index}>
															<td>{`${vehicle.oem}  ${vehicle.model}`}</td>
															{vehicle.lables.map((label, labelIndex) => (
																<td
																	key={labelIndex}
																	style={{
																		backgroundColor: getColor(label.count),
																		color: '#000',
																		cursor: label.count > 0 ? 'pointer' : 'default',
																	}}
																	onClick={() => {
																		if (label.count > 0) {
																			setSelectedVehicle(label.vehicles);
																			setIsShowViewModal(true);
																		}
																	}}
																>
																	{label.count}
																</td>
															))}
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
								) : (
									!isLoading && <NoRecordFound />
								)}
							</div>
						</Tab>

						<Tab eventKey='non_comm_log' title='Source Wise'>
							<div className='d-flex col-12 flex-column h-100 mt-4'>
								<div className='d-flex gap-2 align-items-center'>
									<MultiSelect
										valueContainerWidthInPx='200px'
										valueContainerMaxWidthInPx='200px'
										menuListPaddingX='px-2'
										okButtonPaddingEnd='pe-2'
										placeholderColor='#C6C6C6'
										optionPosition='ps-2 justify-content-start'
										placeholder='Non Com Issue'
										placeholderAlignment='flex-start'
										isMultiSelect={true}
										indicatorSeparatorDisplay='none'
										minHeight='45px'
										valueContainerColor='#797694'
										customBackgroundColor='#EFF3F8'
										options={immobilizeNonComIssueList}
										setSelectedOptions={setNonCommTypeSource}
										selectedOptions={nonCommTypeSource}
										data-testid='nonComIssue-filter'
									/>

									<button
										onClick={() => {
											fetchNonCommAnalyticsSourceWise();
										}}
										className='dwnRefresh ms-auto'
										data-testid='unresolved-export-button'
									>
										Refresh{' '}
										<img
											src='/images/refreshIcon.svg'
											alt='refreshIcon'
											style={{ marginLeft: '8px' }}
										/>
									</button>
								</div>

								{allNonCommAnalyticsSourceWiseData?.length ? (
									<div className='bg-white mb-2 mt-4 position-relative trip__log__table '>
										<Table responsive className='mb-0 non-comm-table'>
											<thead>
												<tr className='th-border-bottom position-relative'>
													<th className='fixed-width'>OEM & Model</th>
													{allNonCommAnalyticsSourceWiseData?.[0].lables.map(
														(label, index) => (
															<th key={index} className='fixed-width'>
																{label.label === 'OEM' ? 'OEM API' : label.label}
															</th>
														)
													)}
												</tr>
											</thead>
											<tbody>
												{allNonCommAnalyticsSourceWiseData?.map((vehicle, index) => {
													// Collect counts for global min and max calculations
													//	const counts = vehicle.lables.map((label) => label.count);

													return (
														<tr key={index}>
															<td>{`${vehicle.oem}  ${vehicle.model}`}</td>
															{vehicle.lables.map((label, labelIndex) => {
																// Calculate global min and max counts across all vehicles
																const allCounts =
																	allNonCommAnalyticsSourceWiseData.flatMap((v) =>
																		v.lables.map((l) => l.count)
																	);
																const globalMinCount = Math.min(...allCounts);
																const globalMaxCount = Math.max(...allCounts);

																// Function to determine the color based on the global min and max
																const getColor = (count) => {
																	if (globalMinCount === globalMaxCount) {
																		return `rgb(0, 128, 128)`; // Fixed muted teal color for equal values
																	}

																	const percentile =
																		(count - globalMinCount) /
																		(globalMaxCount - globalMinCount); // Normalize to 0 - 1

																	if (percentile <= 0.3) {
																		const greenIntensity = Math.floor(
																			(120 * percentile) / 0.3
																		); // Balanced green with max value 120
																		return `rgb(0, 120, ${greenIntensity})`; // Slightly darker green for lower values
																	} else if (percentile >= 0.7) {
																		const redIntensity = Math.floor(
																			(128 * (1 - percentile)) / 0.3
																		); // Duller red
																		return `rgb(128, 0, ${redIntensity})`; // Muted red-blue for higher values
																	} else {
																		const yellowIntensity = Math.floor(
																			(128 * (percentile - 0.3)) / 0.4
																		); // Duller yellow
																		return `rgb(128, 128, ${yellowIntensity})`; // Muted yellow-blue for mid-range values
																	}
																};

																return (
																	<td
																		key={labelIndex}
																		style={{
																			backgroundColor: getColor(label.count),
																			color: '#000',
																			cursor:
																				label.count > 0 ? 'pointer' : 'default',
																		}}
																		onClick={() => {
																			if (label.count > 0) {
																				setSelectedVehicle(label.vehicles);
																				setIsShowViewModal(true);
																			}
																		}}
																	>
																		{label.count}
																	</td>
																);
															})}
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
								) : (
									!isLoading && <NoRecordFound />
								)}
							</div>
						</Tab>
					</Tabs>
				</div>
			</Container>
			<Loader isLoading={isLoading} />
			<div>
				{isShowViewModal && (
					<ViewVehicleOnboardingModal
						data={selectedVehicle} // Pass the selected vehicle data
						showModal={isShowViewModal}
						setIsShowModal={setIsShowViewModal}
					/>
				)}
			</div>
		</>
	);
};

NonCommReport.propTypes = {
	allNonCommLogsData: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	GetUserRoleList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	allNonCommLogsData: state.nonCommLogs.allNonCommLogsData,
	isLoading: state.loader.isLoading,
	allNonCommAnalyticsDayWiseData: state.nonCommLogs.allNonCommAnalyticsDayWiseData,
	masterLocationData: state.getAllUserRoleReducer.masterLocationData,
	allNonCommAnalyticsSourceWiseData: state.nonCommLogs.allNonCommAnalyticsSourceWiseData,
});

export default connect(mapStateToProps, {
	GetUserRoleList,
	GetAllNonAnalyticsDayWise,
	GetAllNonAnalyticsSourceWise,
})(NonCommReport);