import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { BsDownload } from 'react-icons/bs'
import { Container, Table, Button, Row, Col } from 'react-bootstrap'
import { BiInfoCircle } from 'react-icons/bi'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { getFormattedDate } from '../../../utils/common-methods'
import PropTypes from 'prop-types'
import Pagination from '../../helpers/hoc/paginator/Pagination'
import MultiSelect from '../../helpers/hoc/mult-select/MultiSelect'
import Loader from '../../helpers/hoc/loader/Loader'
import { GetAllTrips, ExportTripsReport } from '../../../store/actions/reports/trip'
import { useLocation } from "react-router-dom";
import NoRecordFound from '../../helpers/hoc/noRecordFound'
import './css/TripLog.css'
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping'
import { Report_Filter_Type } from '../../../utils/constants'
import DatePicker from '../../helpers/hoc/date-picker/DatePicker'
import SingleSelect from '../../helpers/hoc/single-select/SingleSelect'
import TripStopageDetailsModal from './child components/trip-stopage-details-modal/trip-stopage-details-modal'
import { mapper } from '../../../utils/mapper'



const TripLog = ({
  //actions
  GetAllTrips,
  ExportTripsReport,
  GetUserRoleList,

  //states
  allTripData,
  dataCount,
  isLoading,
  subscribedByCI,
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [intervalDuration, setIntervalDuration] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedOemModel, setSelectedOemModel] = useState([])
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filterType, setFilterType] = useState(Report_Filter_Type.VEHICLE)
  const [clearDateSelection, setClearDateSelection] = useState(false)
  const [isShowStopageModal, setIsShowStopageModal] = useState(false)

  const [tripId, settripId] = useState('')

  const ITEMS_PER_PAGE = 8
  const MaxVehicleLimit = 51

  const location = useLocation()

  const mappedOptions = mapper.mapVehicleDataOptions(subscribedByCI);

  useEffect(() => {
    if (mappedOptions.registrationNumber.length) {
      const queryParams = new URLSearchParams(location.search);
      const selectedVehicle = mappedOptions.registrationNumber.filter(x=> x.label === queryParams.get('vehicleNumber'))
      setSelectedOptions(selectedVehicle)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedByCI, location.search])

  useEffect(() => {
    GetUserRoleList()
  }, [GetUserRoleList])

  const oemAndModels = selectedOemModel.map((item)=>item?.label)
  useEffect(() => {
    if (dataCount > 0) {
      GetAllTrips({
      registrationNumbers: Report_Filter_Type.VEHICLE === filterType ? selectedOptions : [],
      fromDate: startDate,
      toDate: endDate,
      pageIndex: currentPage - 1, 
      pageSize: ITEMS_PER_PAGE,
      intervalDuration:intervalDuration,
      oemAndModels:Report_Filter_Type.OEM_MODEL_CITY === filterType ? oemAndModels : [],
      city: Report_Filter_Type.OEM_MODEL_CITY === filterType ? [selectedCity?.value] : []
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const isDisabled = () => {
    if(Report_Filter_Type.VEHICLE === filterType) {
      return !(selectedOptions !== null && selectedOptions?.length > 0 && startDate !== null && endDate !== null) || selectedOptions?.length >= MaxVehicleLimit
    } else {
      return !(selectedOemModel !== null && selectedOemModel?.length > 0 && selectedCity !== null && startDate !== null && endDate !== null)
    }
  }

  const calculateIntervalDuration = (fromDate, toDate) => {
    const from = new Date(fromDate)
    const to = new Date(toDate)
    const diff = Math.abs(to - from)
    const minutes = Math.floor((diff / 1000) / 60)

    //for less than 1 day return 1 hr
    if (minutes < 1440) return 120
    //for less than 3 days return 4 hrs
    else if (minutes < 4320) return 240
    //for less than 7 days return 12 hrs
    else if (minutes < 10080) return 720
    //for less than 16 days return 1 day
    else if (minutes < 23040) return 1440
    //for greater than 16 days return 2 day
    return 2880
  }

  const onDateChange = (date) => {
    setStartDate(date[0])
    setEndDate(date[1])
    setIntervalDuration(calculateIntervalDuration(startDate, endDate))
  }

  function onSelectionChange(){
    allTripData.length = 0;
  }

  const onSubmitHandler = () => { 
    setCurrentPage(1)
    GetAllTrips({
      registrationNumbers: Report_Filter_Type.VEHICLE === filterType ? selectedOptions : [],
      fromDate: startDate,
      toDate: endDate,
      pageIndex: 0,
      pageSize: ITEMS_PER_PAGE,
      intervalDuration:intervalDuration,
      oemAndModels: Report_Filter_Type.OEM_MODEL_CITY === filterType ? oemAndModels : [],
      city: Report_Filter_Type.OEM_MODEL_CITY === filterType ? [selectedCity?.value] : []
      
    })
    setTimeout(()=>{
      setIsSubmitClicked(true)
    }, 1000)
  }

  function exportToExcel() {
    ExportTripsReport({
      registrationNumbers: Report_Filter_Type.VEHICLE === filterType ? selectedOptions : [],
      fromDate: startDate,
      toDate: endDate,
      oemAndModels:Report_Filter_Type.OEM_MODEL_CITY === filterType ? oemAndModels : [],
      city:Report_Filter_Type.OEM_MODEL_CITY === filterType ? [selectedCity?.value] : []
    }) 
  }

  function renderTooltip(props) {
    return (
      <Tooltip className="tooltip" id="button-tooltip" {...props}>
        Start {props.parameter}: {props.start}
        {props?.unit ? props.unit : ''} <br />
        End {props.parameter}: {props.end}
        {props?.unit ? props.unit : ''}
      </Tooltip>
    )
  }

  const stoppageHandler = (_id) => {
    setIsShowStopageModal(true)
    settripId(_id)
  }

  useEffect(() => {
    if(Report_Filter_Type.VEHICLE === filterType) {
      setSelectedOemModel([])
      setSelectedCity(null)
    } else {
      setSelectedOptions([])
    }
    setStartDate(null)
    setEndDate(null)
    setClearDateSelection(true);
		setTimeout(() => {
			setClearDateSelection(false);
		}, 500);

  }, [filterType])

  return (
    <>
        <div>
          <Container fluid className="analytics-container">
            <Row>
              <Col>
              <div className='col-12 records__trip'>
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"tripFilterType"}
                  id={"tripFilterType"}
                  checked={filterType === Report_Filter_Type.VEHICLE}
                  onChange={() => { setFilterType(Report_Filter_Type.VEHICLE); onSelectionChange(); }}
                  data-testid={`vehicle-type-radio-button`}
                />
                <text className="radio-checkbox-text-heading">
                    {Report_Filter_Type.VEHICLE}
                </text>
              </span>
              &nbsp;&nbsp;
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"tripFilterType"}
                  id={"tripFilterType"}
                  checked={filterType === Report_Filter_Type.OEM_MODEL_CITY}
                  onChange={() => { setFilterType(Report_Filter_Type.OEM_MODEL_CITY); onSelectionChange(); }}
                  data-testid={`oem-model-radio-button`}
                />
                <text className="radio-checkbox-text-heading">
                    {Report_Filter_Type.OEM_MODEL_CITY}
                </text>
              </span>
              </div>
      <div className="row d-flex tripLogsFilter">
           <div 
              className={`col-3 multi-select-width ${!selectedOptions[0]? 'no-value-selected' : ''}`}
              style={{display: filterType === Report_Filter_Type.VEHICLE ? 'block': 'none'}}>
             <MultiSelect
               options={mappedOptions.registrationNumber}
               selectedOptions={selectedOptions}
               setSelectedOptions={setSelectedOptions}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694" 
               data-testid='vehicle-filter'
               maxOptionSelectionLimit={MaxVehicleLimit}
              />
           </div>
           <div 
              className={`col-3 multi-select-width ${!selectedOemModel[0]? 'no-value-selected' : ''}`}
              style={{display: filterType === Report_Filter_Type.OEM_MODEL_CITY ? 'block': 'none'}}>
             <MultiSelect
               options={mappedOptions.oemModel}
               selectedOptions={selectedOemModel}
               placeholder='OEM & Model'
               setSelectedOptions={setSelectedOemModel}
               isMultiSelect={true}
               onChange = {onSelectionChange}
               customBackgroundColor='#EFF3F7'
               webkitScrollbarWidth='5px'
               webkitScrollbarHeight='5px'
               minHeight='45px'
               placeholderAlignment='flex-start'
               indicatorSeparatorDisplay='none'
               placeholderColor='#C6C6C6'
               valueContainerColor="#797694"  
							 optionPosition='justify-content-start'
               valueContainerWidthInPx='200px'
               valueContainerMaxWidthInPx='200px'
               menuListPaddingX='px-2'
               okButtonPaddingEnd='pe-2'
               data-testid='oem-model-filter'

               />
           </div>
           <div className={`col-3 multi-select-width ${!selectedCity? 'no-value-selected' : ''}`}
                style={{display: filterType === Report_Filter_Type.OEM_MODEL_CITY ? 'block': 'none'}}>
                  <SingleSelect
                        placeholder="City"
                        options={mappedOptions.location}
                        selectedOptions={selectedCity}
                        onSelectHandler={(value) => {setSelectedCity(value); onSelectionChange(); }}
                        customBackgroundColor="#F5F7F9"
                        data-testid='city-filter'
                        minHeight={'45px'}
                        optionPosition="ps-2 justify-content-start"
                        valueContainerJustifyContent="start"
                        singleValueColor="#1F2A5D"
                        menuMaxheight="225px"
                  /> 
           </div>
           <div className={`col-3 multi-select-width ${!startDate? 'no-value-selected' : ''}`}>
           <DatePicker
              onDateChange={(dates) => {onDateChange(dates); onSelectionChange();}}
              allowedMaxDaysInSelection={31}
              paddingLeft='0px'
              clearDateSelectionOnToggle={clearDateSelection}
              data-testid='daterange-filter'
              customBackgroundColor='#EFF3F7'
          />
           </div>
           <div className='col-1'>
             <Button
               className="bttn height"
               id="getReport"
               type="submit"
               onClick={onSubmitHandler}
               disabled={isDisabled()}
               data-testid='get-report-button'
             >
               Get Report
             </Button>
           </div>

           <div className='col-1 export-btn-box'>
             <button
                data-testid='export-button'
               disabled={!allTripData?.length}
               className={
                 !allTripData?.length ? 'dwn-btn dwn-btn-disabled' : 'dwn-btn'
               }
               onClick={() => {
                 exportToExcel()
               }}
             >
               <BsDownload style={{width:'22px',height:'22px'}} />
             </button>
           </div>
  
          
       </div>
       {allTripData?.length ? (
         <div className="bg-white mb-2 mt-4 position-relative trip__log__table">
           <Table responsive={true} borderless className="mb-0" id="trip-table">
             <thead>
               <tr className="th-border-bottom">
                 <th className="ps-2 text-nowrap"><p className='fw500'>S.No</p></th>
                 <th className="ps-2 text-nowrap"><p className='fw500'>Vehicle No.</p></th>
                 <th className="ps-2 text-nowrap"><p className='fw500'>OEM & Model</p></th>
                 <th className="ps-2 text-nowrap"><p className='fw500'>Run Duration</p></th>
                 <th className="ps-2 text-nowrap"><p className='fw500'>Run kms</p></th>
                 <th className="ps-2 text-nowrap"><span className='fw500'>SoC<br/> Drain</span></th>
                 <th className="ps-2 text-nowrap"><span className='fw500'>Stoppage<br/> Count</span></th>
                 <th className="ps-2 text-nowrap"><span className='fw500'>Energy<br/> Utilized</span></th>
                 <th className="ps-2 text-nowrap"><span className='fw500'>Average <br/> Speed</span></th>
                 <th className="ps-2 text-nowrap"><span className='fw500'>Maximum <br/> Speed</span></th>
                 <th className="ps-2 text-nowrap"><span className='fw500'>Tracking</span></th>
               </tr>
             </thead>
             <tbody>
               {allTripData?.map((n, index) => {
                 return (
                   <tr key={index}>
                     <td>{index +
                             1 +
                             ((currentPage - 1) * 10 +
                               (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                             '.'}</td>
                     <td>{n?.registration_number}</td>
                     <td>{n?.oem} {n?.model}</td>
                     <td>
                       <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                         {n.duration}
                         <OverlayTrigger
                           placement="auto-end"
                           delay={{ show: 0, hide: 0 }}
                           overlay={renderTooltip({
                             parameter: 'Time',
                             start: getFormattedDate(n.startDateTime),
                             end: getFormattedDate(n.endDateTime),
                            
                           })}
                         >
                           <button className="row-btn">
                             <BiInfoCircle className="row-i-icon" />
                           </button>
                         </OverlayTrigger>
                       </div>
                     </td>
                     <td>
                       <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                         {`${n?.runKms} kms`}
                         <OverlayTrigger
                           placement="auto-end"
                           delay={{ show: 0, hide: 0 }}
                           overlay={renderTooltip({
                             parameter: 'Odo',
                             unit: ' kms',
                             start: n.startOdo,
                             end: n.endOdo,
                           })}
                         >
                           <button className="row-btn">
                             <BiInfoCircle className="row-i-icon" />
                           </button>
                         </OverlayTrigger>
                       </div>
                     </td>
                     <td>
                       <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                        {`${n?.socDrain}%`}
                         <OverlayTrigger
                           placement="auto-end"
                           delay={{ show: 0, hide: 0 }}
                           overlay={renderTooltip({
                             parameter: 'SoC',
                             unit: '%',
                             start: n.startSoC,
                             end: n.endSoC,
                           })}
                         >
                           <button className="row-btn">
                             <BiInfoCircle className="row-i-icon" />
                           </button>
                         </OverlayTrigger>
                       </div>
                     </td>
                     <td>
                     <div className="d-flex flex-row gap-1 align-items-center justify-content-center">
                         {n?.stops}
                         {
                          n?.stops > 0 && <button className="row-btn">
                             <BiInfoCircle className="row-i-icon" onClick={() => stoppageHandler(n?._id)} />
                           </button>
                         }
                           
                       </div>
                     </td>
                     <td>{n?.eu ? n?.eu: 0 } kWh</td>
                     <td>{n.avgSpeed ? Math.round(n.avgSpeed) : 0 } kmph</td>
                     <td>{n.maxSpeed ? Math.round(n.maxSpeed) : 0 } kmph</td>
                     <td><a href={'/controls/tracking?vn=' + n?.registration_number + '&st=' + n?.startDateTime + '&et='  +  n?.endDateTime + '&oem=' + n?.oem + '&model=' +  n?.model } target='_blank' rel='noreferrer'>Track</a></td>
                   </tr>
                 )
               })}
             </tbody>
           </Table>
           <Pagination
             disabled={false}
             totalPages={Math.ceil((dataCount || 0) / ITEMS_PER_PAGE)}
             currentPage={currentPage}
             setPage={setCurrentPage}
             data-testid='trip-pagination'
           />
         </div>
       ) : (
         <></>
       )}
     {!allTripData?.length && isSubmitClicked && !isLoading ? (
       <NoRecordFound></NoRecordFound>
     ): <></> }
              </Col>
            </Row>
          </Container>
        </div>
        

      <Loader isLoading={isLoading} />
      <TripStopageDetailsModal showModal={isShowStopageModal} tripId={tripId} setIsShowModal= {setIsShowStopageModal} />
    </>
  )
}
TripLog.propTypes = {
  allTripData: PropTypes.array.isRequired,
  dataCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Action props
  GetAllTrips: PropTypes.func.isRequired,
  ExportTripsReport: PropTypes.func.isRequired,
  subscribedByCI: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  allTripData: state.trip.allTripData,
  dataCount: state.trip.dataCount,
  isLoading: state.loader.isLoading,
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
})
export default connect(mapStateToProps, { GetAllTrips, ExportTripsReport, GetUserRoleList })(
    TripLog
)