import api from "../../service/api";
import API_BOOK from "../../service/endpoints";
import { clearLocalStorageExceptclientIP } from "../../utils/common-methods";
import { CipherGenerator } from "../../utils/crypto";
import { customToast } from '../../utils/toasts'

const updatePasswordUserApiConfig = API_BOOK.UPDATE_PASSWORD;
/* Actions starts here */

/**
 *  @desc         Register User action
 *  @author       Sanjana Manimaran
 *  @api          /user
 *  @method       POST
 *  @createdDate  23-may-2023
 *  @modifiedDate 23-May-2023
 **/

export const UpdatePasswordUser = ({
  oldPassword,
  password,
  reject}) => async (dispatch) => {
    try {
      const { _id } = JSON.parse(localStorage.getItem('user'))
      const oldhashPassword = CipherGenerator(oldPassword)
      const hashPassword = CipherGenerator(password)
      const resp = await api({
        method: updatePasswordUserApiConfig.method,
        url: `${updatePasswordUserApiConfig.url}/${_id}`,
        data: {
          oldPassword: oldhashPassword,
          password: hashPassword,
        }
      });
      if (resp.status === 200) {
        clearLocalStorageExceptclientIP()
        customToast({ message: 'Password updated successfully' })
        setTimeout(() => {
          window.location.href='/login'
          window.location.reload()
        },500)
        return;
      }
      if (resp.status === 400 && resp.data.error === 'Old password does not match') {
        localStorage.setItem('oldpassword', false)
      }
      if(resp.status === 400 && resp.data.error === 'The new password should not be same as last 3 passwords') {
        setTimeout(() => {
          customToast({ message: 'New password not be same as last 3 passwords', svgImageName: 'decline' })
        }, 400)
      }
      reject();
    } catch (error) {
      console.log("error:", error);
    }
  };




/* Actions ends here */