import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BasicInput from '../../shared/form/Input/Input';
import styles from './Signup.module.css';
import loginStyles from '../login/Login.module.css';
import validationService from '../../../service/validationService';
import { SASetPassword, SA_SendOTP, SA_VerifyEmail, SA_VerifyEmailFailedAD, SA_VerifyEmailSuccessAD, SA_VerifyOTP, SA_VerifyOTP_FailedAD } from '../../../store/actions/SA_Signup/SA_Signup';
import { useDispatch, useSelector } from 'react-redux';
import OTPModals from '../../shared/modals/OTPModals/OTPModals';
import PasswordInput from '../../shared/form/PasswordInput/PasswordInput';
import InformativeModal from '../../shared/modals/InformativeModal/InformativeModel';
import Button from '../../shared/form/Button/Button';
import DuSignUp from './DuSignup';

const Signup = () => {
    const navigate = useNavigate()

    // redux hook
    const dispatch = useDispatch()

    const notVerified = useSelector(s => s?.SA_Signup?.notVerified)

    const isOtpVerified = useSelector(s => s?.SA_Signup?.isOtpVerified);
    const userId = useSelector(s => s?.SA_Signup?.userId);
    const [isDuUser, setIsDuUser] = useState(false)
    const [tempUserId ,setTempUserId]= useState()
  
    const [isClicked, setIsClicked] = useState(false)

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        rememberMe: false,
        passwordCheck: {},
    });

    // eslint-disable-next-line 
    const [formError, setFormError] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });

    // eslint-disable-next-line
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [otp, setOTP] = useState('');

    const [apiResponse, setApiResponse] = useState({
        isOpen: false,
        message: '',
        variant: ''
    })

    const handleOnclick = async () => {

        try {
            const res = await SA_VerifyEmail(formData.email);

            if (res.status !== 200) {
                setApiResponse({
                    ...apiResponse,
                    isOpen: true,
                    message: res.data.error,
                    variant: 'error'
                })
                dispatch(SA_VerifyEmailFailedAD())
            }
            else {
                dispatch(SA_VerifyEmailSuccessAD(res?.data?.data?.userId))
                const userID = res?.data?.data?.userId
                setTempUserId(res?.data?.data?.userId)
                dispatch(SA_SendOTP(formData.email, userID))
            }

        } catch (error) {
            console.log('SAVerify email error', error)
        }
        setIsClicked(true)
    }

    const handleEmailVerification = () => {
        const errorCount = handleEmailValidation();
        if (errorCount === 0) {
            setShowOTPModal(true);
        }
    };

    const handleEmailValidation = () => {
        let errorCount = 0;

        const emailValidation = validationService.email(formData.email);
        if (!emailValidation.isValid) {
            errorCount++;
        }
        setFormError(prevState => ({ ...prevState, email: emailValidation.message }));

        return errorCount;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        handleEmailVerification();
    };

    const handlePasswordValidation = () => {
        let errorCount = 0

        let passwordValidaton = validationService.required(formData.password)
        if (!passwordValidaton.isValid) {
            errorCount++
        } else {
            if (Object.values(formData.passwordCheck).filter(d => !d).length > 0) {
                errorCount++
                passwordValidaton.message = 'Password checks are missing'
            } else {
                passwordValidaton.message = ''
            }
        }
        setFormError(prevState => ({ ...prevState, password: passwordValidaton.message }))

        let confirmPasswordValidaton = validationService.required(formData.confirmPassword)
        if (!confirmPasswordValidaton.isValid) {
            errorCount++
        }
        setFormError(prevState => ({ ...prevState, confirmPassword: confirmPasswordValidaton.message }))

        return errorCount
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (handlePasswordValidation() > 0) {
            return;
        }

        try {
            const res = await SASetPassword(
                {
                    email: formData.email,
                    password: formData.password
                }
            )
            if (res.status === 200) {

                setApiResponse({
                    ...apiResponse,
                    isOpen: true,
                    message: 'Successfully Registered',
                    variant: 'success'
                })
                setTimeout(() => {
                    navigate('/login')
                    dispatch(SA_VerifyOTP_FailedAD())
                }, 2100)
            }
        } catch (error) {
            console.log('SASetPassword error', error)
        }
    }

    const resendOtp = () => {
        dispatch(SA_SendOTP(formData.email, userId))
    }

    const handleDuUserModalClose = ( ) =>{
        setIsDuUser(false)
        dispatch(SA_VerifyOTP_FailedAD())
    }

    const handleDuUserModalSubmit = () => {
        setIsDuUser(false)
    }

    useEffect(() => {

        if (otp.length === 6) {

            dispatch(SA_VerifyOTP(otp, formData.email))
        }


    }, [otp, dispatch, formData.email])

    useEffect(() => {
        if (isOtpVerified) {
            setIsClicked(false)
        }
        if (isOtpVerified && tempUserId == null) {
            setIsClicked(false)
            setIsDuUser(true)
        }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOtpVerified])



    return (
        <>
            {isOtpVerified ? (
                <div className={`${loginStyles.mainContainer}`}>
                    <div className={`${loginStyles.resetContainer}`}>
                        <h3 className='text-white'>Create password</h3>
                        <div className='flex-fill mt-3'>
                            <form>
                                <label className='text-white'>New password<span style={{ color: '#C417E0' }}>*</span></label>
                                <PasswordInput
                                    value={formData.password}
                                    onChange={(e, passwordCheck) => {
                                        const password = e.target.value;
                                        setFormData({
                                            ...formData,
                                            password,
                                            passwordCheck,
                                            confirmPassword: '',
                                        });
                                        setFormError({ ...formError, confirmPassword: '' })
                                    }}
                                    error={formError.password}
                                    enablePasswordValidation={true}
                                />

                                <label className='text-white'>Confirm password<span style={{ color: '#C417E0' }}>*</span></label>
                                <PasswordInput
                                    value={formData.confirmPassword}
                                    onChange={(e) => {
                                        const confirmPassword = e.target.value;
                                        setFormData({
                                            ...formData,
                                            confirmPassword,
                                        });
                                        setFormError({ ...formError, confirmPassword: confirmPassword !== formData.password ? 'New password & Confirm password should be same' : '' })
                                    }}
                                    error={formError.confirmPassword}
                                    success={formData.confirmPassword && !formError.confirmPassword}
                                />
                                <div className='mt-4 mb-2'>
                                    <Button type='submit'
                                        onClick={handlePasswordSubmit}
                                    >Submit</Button>
                                </div>
                                <span className={loginStyles.signIn}>
                                    Back to{' '}
                                    <Link
                                        data-testid='signin-link'
                                        className={loginStyles.signupText}
                                        to='/login'
                                    >
                                        Sign in
                                    </Link>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.mainContainer}>
                    <div className={styles.signupContainer}>
                        <h4 className='text-white'>Sign Up</h4>
                        <p className={styles.signupSubheading}>
                            Enter your email address and you will receive an email with a one-time password
                        </p>
                        <div className='flex-fill mt-3'>
                            <form onSubmit={handleSubmit}>
                                <label className='text-white'>Email ID<span style={{ color: '#C417E0' }}>*</span></label>
                                <BasicInput
                                    value={formData.email}
                                    onChange={(e) => {
                                        const emailValidaton = validationService.email(e.target.value)
                                        setFormError({ ...formError, email: emailValidaton.message })
                                        setFormData({ ...formData, email: e.target.value })
                                    }}
                                    error={formError.email}
                                    placeholder="Enter your email"
                                    type='email'
                                />
                                <div className='mt-4 mb-2'>
                                    <button type="submit" className={styles.submit} onClick={handleOnclick} >Next</button>
                                </div>
                                <span className={loginStyles.signIn}>
                                    Already a User? <Link data-testid='signin-link' className={loginStyles.signupText} to='/login'>Sign In</Link>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            <OTPModals
                isOpen={isClicked && !notVerified}
                closeModal={() => {
                    setOTP('')
                    setIsClicked(false)
                }}
                onChange={(value) => setOTP(value)}
                value={otp}
                userEmail={formData.email}
                error=''
                resendOtpHandler={resendOtp}
                onPrevoiusPageClick={() => {
                    setOTP('')
                    setIsClicked(false)
                }}
            />

            <InformativeModal
                isOpen={apiResponse.isOpen}
                onClose={() => setApiResponse({
                    ...apiResponse,
                    isOpen: false
                })}
                message={apiResponse.message}
                variant={apiResponse.variant}
            />
            <DuSignUp
             isOpen={isDuUser}
             onClose={handleDuUserModalClose}
             onSubmit={handleDuUserModalSubmit}
             email={formData.email}
            /> 
        </>
    );

};

export default Signup;
/*conflict-resolution-will be removed later*/