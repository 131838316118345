import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import {customToast} from "../../../utils/toasts"
import { NotificationMessage } from "../../../utils/constants";
const { GET_CHARGING_ANALYTICS_DATA_FAIL, GET_CHARGING_ANALYTICS_DATA_SUCCESS,
  GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS, GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_FAIL,
   IS_CHART_LOADING, IS_MODAL_LOADING, IS_EXPORT_FILE_LOADING } = ActionTypes.REPORTS.CHARGING_ANALYTICS;
const { GET_CHARGING_ANALYTICS_DATA } = API_BOOK.REPORTS.CHARGING_ANALYTICS;

/* Action dispatchers (AD) starts here */


const getChargingAnalyticsSuceesAD = (payload) => ({
  type: GET_CHARGING_ANALYTICS_DATA_SUCCESS,
  payload,
});

const getChargingAnalyticsFailAD = () => ({
  type: GET_CHARGING_ANALYTICS_DATA_FAIL
});

const getAllChargingAnalyticsByOemModelORCitySuceesAD = (payload) => ({
  type: GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_SUCCESS,
  payload,
});

const getAllChargingAnalyticsByOemModelORCityFailAD = (payload) => ({
  type: GET_ALL_CHARGING_ANALYTICS_DATA_BY_OEM_MODAL_CITY_FAIL,
  payload,
});

const toggleChartLoading = (payload) =>({
  type :IS_CHART_LOADING,
  payload
})

const toggleModalLoading = () =>({
  type :IS_MODAL_LOADING
})

const toggleExportFileLoading = () =>({
  type :IS_EXPORT_FILE_LOADING
})
/* Action dispatchers ends here */

/* Actions starts here */

/**
 *  @desc         Get charging anlaytics data
 *  @author       Parvez Siddique
 *  @api          /reports/analytics/charging
 *  @method       POST
 *  @createdDate  26-SEP-2023
 *  @modifiedDate 26-SEP-2023
 **/
export const GetChargingAnalytics = ({ fromDate, toDate, intervalDuration,chartType }) => async (dispatch) => {
  try {
    dispatch(toggleChartLoading(true))
    const resp = await api({
      method: GET_CHARGING_ANALYTICS_DATA.method,
      url: GET_CHARGING_ANALYTICS_DATA.url.GET_CHARGING_ANALYTICS_DATA,
      data: {
        fromDate,
        toDate,
        intervalDuration,
        chartType
      }
    });

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getChargingAnalyticsSuceesAD(response));
      dispatch(toggleChartLoading(false))
    } else {
      dispatch(getChargingAnalyticsFailAD());
      dispatch(toggleChartLoading(false))
    }
  } catch (error) {
    dispatch(getChargingAnalyticsFailAD());
    dispatch(toggleChartLoading(false))
  }
};

/**
*  @desc         Get charging anlaytics data by oem model or city by paging
*  @author       Parvez Siddique
*  @api          /reports/analytics/charging/by-city-oem-model
*  @method       POST
*  @createdDate  28-SEP-2023
*  @modifiedDate 28-SEP-2023
**/
export const GetAllChargingSessionByOemModelOrCity = ({ filterType, filter, fromDate, toDate, pageSize, pageIndex }) => async (dispatch) => {
  try {
    if (fromDate === null || toDate === null) return
    if(!filterType || filterType === "") return
    dispatch(toggleModalLoading())
    const resp = await api({
      method: GET_CHARGING_ANALYTICS_DATA.method,
      url: GET_CHARGING_ANALYTICS_DATA.url.GET_ALL_CHARGING_SESSION_BY_OEM_MOEDL_CITY,
      data: {
        filterType,
        filter,
        fromDate,
        toDate,
        pageSize,
        pageIndex
      }
    });

    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllChargingAnalyticsByOemModelORCitySuceesAD(response));
      dispatch(toggleModalLoading())
    } else {
      dispatch(getAllChargingAnalyticsByOemModelORCityFailAD());
      dispatch(toggleModalLoading())
    }
  } catch (error) {
    dispatch(getAllChargingAnalyticsByOemModelORCityFailAD());
    dispatch(toggleModalLoading())
  }
};

/**
*  @desc          export ALL charging anlaytics data by oem model or city without paging
*  @author       Parvez Siddique
*  @api          /reports/analytics/charging/by-city-oem-model/export
*  @method       POST
*  @createdDate  28-SEP-2023
*  @modifiedDate 28-SEP-2023
**/
export const ExportAllChargingSessionByOemModelOrCity = ({ filterType, filter, fromDate, toDate }) => async (dispatch) => {
  try {
    dispatch(toggleExportFileLoading())
    const resp = await api({
      method: GET_CHARGING_ANALYTICS_DATA.method,
      url: GET_CHARGING_ANALYTICS_DATA.url.EXPORT_ALL_CHARGING_SESSION_BY_OEM_MOEDL_CITY,
      data: {
        filterType,
        filter,
        fromDate,
        toDate,
      }
    });

    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    dispatch(toggleExportFileLoading())
    dispatch(getChargingAnalyticsFailAD());
  }
};