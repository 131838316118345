const initialState = {
  statusArray: [],
  count : 0
};

const handleStatusReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "vehicleStatus":
      return {
        ...state,
        statusArray: payload,
        count: 1
      };

      case 'setZero' : 
      return {
        ...state,
        count :0
      }

    default:
      return state;
  }
};

export default handleStatusReducer;
