import React from 'react'
import cx from 'classnames'
import { Col, Row } from 'react-bootstrap'

import PageHeader from '../../../shared/ui/PageHeader/PageHeader'
import DashboardKPI from '../../../shared/cards/DashboardKPI/DashboardKPI'
import DashboardStat from '../../../shared/cards/DashboardStat/DashboardStat'
import DashboardFleet from '../../../shared/cards/DashboardFleet/DashboardFleet'

import styles from './CADashboard.module.css'
import { useSelector } from 'react-redux'
 
const usersStatData = [
    {
        title: 'Total Users',
        count: 60,
        vendors: [{
            name: 'Operations',
            count: 10,
        },
        {
            name: 'Accounts',
            count: 20,
        }]
    },
    {
        title: 'Active Users',
        count: 30000,
        vendors: [{
            name: 'Operations',
            count: 10,
        },
        {
            name: 'Accounts',
            count: 20,
        }]
    },
    {
        title: 'Deactivated Users',
        count: 40000,
        vendors: [{
            name: 'Operations',
            count: 10,
        },
        {
            name: 'Accounts',
            count: 20,
        }]
    },
]

const usageStatData = [
    {
        title: 'Login Count',
        count: 60000,
        vendors: [{
            name: 'Operation',
            count: 10,
        },
        {
            name: 'Account',
            count: 20,
        }]
    },
    {
        title: 'Avg. Daily Session Duration',
        count: 50000,
        unit: 'Hrs',
        vendors: [{
            name: 'Operation',
            count: 10,
        },
        {
            name: 'Account',
            count: 20,
        }]
    },
    {
        title: 'Current Active Logins',
        count: 30000,
        vendors: [{
            name: 'Operation',
            count: 10,
        },
        {
            name: 'Account',
            count: 20,
        }]
    },
]

const CADashboard = () => {
    const userData = useSelector(state => state.auth.user)

    return (
        <>
            <PageHeader heading={`${userData.companyName} Dashboard`} />

            <div className='mb-4'>
                <Row>
                    <Col md="3">
                        <DashboardKPI
                            heading='Total Users'
                            count='53000'
                            detailsLink='/ca/dashboard'
                            image='/images/svgicon/totalCustomers.svg'
                        />
                    </Col>

                    <Col md="3">
                        <DashboardKPI
                            heading='Total Departments'
                            count='23000'
                            detailsLink='/ca/dashboard'
                            image='/images/svgicon/totalUsers.svg'
                        />
                    </Col>

                    <Col md="3">
                        <DashboardKPI
                            heading='Total Vehicles'
                            count='53000'
                            detailsLink='/ca/dashboard'
                            image='/images/svgicon/totalVehicles.svg'
                        />
                    </Col>

                    <Col md="3">
                        <DashboardKPI
                            heading='Total Locations'
                            count='53000'
                            detailsLink='/ca/dashboard'
                            image='/images/svgicon/totalCities.svg'
                        />
                    </Col>
                </Row>
            </div>

            <div className='flex-1 d-flex justify-content-between gap-4 w-100'>
                <div className={cx(styles.dashboardStatContainer, 'h-100 w-75')}>
                    <Row>
                        <Col>
                            <p className={cx(styles.dashboardStasHeading)}>Users</p>

                            {usersStatData.map((item, index) => (
                                <div className={cx(styles.dashboardStatCard)} key={`users_${index}`}>
                                    <DashboardStat title={item.title} vendors={item.vendors} units={item.unit} count={item.count} />
                                </div>
                            ))}
                        </Col>

                        <Col>
                            <p className={cx(styles.dashboardStasHeading)}>Usage</p>

                            {usageStatData.map((item, index) => (
                                <div className={cx(styles.dashboardStatCard)} key={`usage_${index}`}>
                                    <DashboardStat title={item.title} vendors={item.vendors} units={item.unit} count={item.count} />
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>

                <div className='h-100 w-25'>
                    <DashboardFleet
                        total={150}
                        data={{
                            TWO: {
                                active: 20,
                                inactive: 30,
                                total: 50,
                            },
                            THREE: {
                                active: 30,
                                inactive: 20,
                                total: 50,
                            },
                            FOUR: {
                                active: 40,
                                inactive: 10,
                                total: 50,
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default CADashboard
/*conflict-resolution-will be removed later*/