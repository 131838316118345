import React from "react";
import { Row, Col, Table } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io'
import { selectFence, unselectFence, selectAll } from "../../../../store/actions/geo-fence/configuration/alertsConfiguration/alertConfigurationScreenActions";

const DataView = ({
    fencesData,
    allSelected,
    selectAll,
    selectFence,
    unselectFence
}
) => {

    const selectFenceClicked = (fenceId) => {
        selectFence({ fenceId, fencesData })
    }

    const unselectFenceClicked = (fenceId) => {
        unselectFence({ fenceId, fencesData })
    }
    return (
        <Row  className="config-dataView" >
            <Col className="shadow-sm p-3 mb-5 bg-white alert-configuration-table" >

                <Table borderless className="mt-4" id="configuration-fence-data">
                    <thead>
                        <th className="ps-3 text-nowrap">
                            {
                                allSelected &&
                                <IoIosCheckbox color="#1f2a5d" size={35}
                                    onClick={() => { selectAll(false) }}
                                    data-testid='alert-checkbox-all'
                                />
                            }
                            {
                                !allSelected &&
                                <IoIosSquareOutline color="#707070" size={35}
                                    onClick={() => { selectAll(true) }}
                                    data-testid='alert-checkbox-all'
                                />
                            }
                        </th>
                        <th className="ps-3 text-nowrap"><p className='fw500'>Fence Name</p></th>
                        <th className="ps-3 text-nowrap"><p className='fw500'>City</p></th>
                        <th className="ps-3 text-nowrap"><p className='fw500'>Label</p></th>
                        <th className="ps-3 text-nowrap"><p className='fw500'>Label Owner</p></th>
                        <th className="ps-3 text-nowrap"><p className='fw500'>Fence Owner</p></th>
                    </thead>
                    <tbody className="alert-configuration-table-body">
                        {fencesData.map((fence, index) => (
                            <tr key={"row-" + index.toString()}>
                                <td className="select-col">
                                    {
                                        fence.isSelected &&
                                        <IoIosCheckbox color="#22046B" size={20}
                                            data-testid={`alert-checkbox-${fence.name}`}
                                            onClick={() => { unselectFenceClicked(fence.fenceId) }}
                                        />
                                    }
                                    {
                                        !fence.isSelected &&
                                        <IoIosSquareOutline color="#707070" size={20}
                                        data-testid={`alert-checkbox-${fence.name}`}
                                            onClick={() => { selectFenceClicked(fence.fenceId) }}
                                        />
                                    }
                                </td>
                                <td >{fence.name}</td>
                                <td >{fence.city}</td>
                                <td >{fence.label}</td>
                                <td >{fence.labelOwner}</td>
                                <td >{fence.fenceOwner}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

DataView.propTypes = {
    fencesData: PropTypes.array.isRequired,
    allSelected: PropTypes.array.isRequired,
    selectAll: PropTypes.func.isRequired,
    selectFence: PropTypes.func.isRequired,
    unselectFence: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    fencesData: state.alertConfigurationScreen.fencesData,
    allSelected: state.alertConfigurationScreen.allSelected,
    selectAll,
    unselectFence,
    selectFence
});

export default connect(mapStateToProps, {
    selectAll,
    selectFence,
    unselectFence
})(DataView);