import API_BOOK from '../../../../service/endpoints'
import api from '../../../../service/api'
import ActionTypes from '../../../actionTypes'
import {
  getFormattedDate,
  fileDownloader,
} from '../../../../utils/common-methods'

const geoFenceActions = ActionTypes.GEOFENCE_ANALYTICS_ACTIONS
const geoFenceGetAllCitiesConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_CITIES
const geoFenceGetAllLabelsConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_LABELS
const geoFenceGetAllLabelOwnerConfig =
  API_BOOK.GEO_FENCE.MASTERS.GET_ALL_LABEL_OWNERS
const geoFenceGetAllFenceOwnerConfig =
  API_BOOK.GEO_FENCE.MASTERS.GET_ALL_FENCE_OWNERS


/**
 *  @desc         Telematics API for loading cities
 *  @author       Deepak Prajapati
 *  @api         /geo-fence/get-distinct-cities
 *  @method       GET
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/  

export const loadAllCities = () => {
  return async (dispatch) => {
    try {
      const resp = await api({
        method: geoFenceGetAllCitiesConfig.method,
        url: geoFenceGetAllCitiesConfig.url,
      })
      if (resp.status === 200) {
        const response = resp?.data.data
        const dropdownData = response.map((city) => {
          return {
            label: city,
            value: city,
          }
        })
        dispatch({ type: geoFenceActions.LOAD_CITIES, payload: dropdownData })
      }
    } catch (error) {
      console.log('error:', error)
    }
  }
}

/**
 *  @desc         Telematics API for loading labels
 *  @author       Deepak Prajapati
 *  @api         /geo-fence/configuration/labels
 *  @method       GET
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/

export const loadAllLabels = () => async (dispatch) => {
  try {
    const resp = await api({
      method: geoFenceGetAllLabelsConfig.method,
      url: geoFenceGetAllLabelsConfig.url,
    })
    if (resp.status === 200) {
      const response = resp?.data.data
      const dropdownData = response.map((labelMaster) => {
        return {
          label: labelMaster.label,
          value: labelMaster._id,
        }
      })
      dispatch({ type: geoFenceActions.LOAD_LABELS, payload: dropdownData })
    }
  } catch (error) {
    console.log('error:', error)
  }
}



/**
 *  @desc         Telematics API for loading Label owner
 *  @author       Deepak Prajapati
 *  @api         /geo-fence/configuration/label-owners
 *  @method       GET
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/

export const loadAllLabelOwners = () => async (dispatch) => {
  try {
    const resp = await api({
      method: geoFenceGetAllLabelOwnerConfig.method,
      url: geoFenceGetAllLabelOwnerConfig.url,
    })
    if (resp.status === 200) {
      const response = resp?.data.data
      const dropdownData = response.map((labelOwner) => {
        return {
          label: labelOwner.label,
          value: labelOwner._id,
        }
      })
      dispatch({
        type: geoFenceActions.LOAD_LABEL_OWNERS,
        payload: dropdownData,
      })
    }
  } catch (error) {
    console.log('error:', error)
  }
}


/**
 *  @desc         Telematics API for loading fence owner
 *  @author       Deepak Prajapati
 *  @api         /geo-fence/configuration/fence-owners
 *  @method       GET
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/

export const loadAllFenceOwners = () => async (dispatch) => {
  try {
    const resp = await api({
      method: geoFenceGetAllFenceOwnerConfig.method,
      url: geoFenceGetAllFenceOwnerConfig.url,
    })
    if (resp.status === 200) {
      const response = resp?.data.data
      const dropdownData = response.map((fenceOwnerMaster) => {
        return {
          label: fenceOwnerMaster.label,
          value: fenceOwnerMaster._id,
        }
      })
      dispatch({
        type: geoFenceActions.LOAD_FENCE_OWNERS,
        payload: dropdownData,
      })
    }
  } catch (error) {
    console.log('error:', error)
  }
}

export const setSelectedCities = (selectedCities) => (dispatch) => {
  dispatch({
    type: geoFenceActions.SET_SELECTED_CITIES,
    payload: selectedCities,
  })
}

export const setSelectedLabels = (selectedLabels) => (dispatch) => {
  dispatch({
    type: geoFenceActions.SET_SELECTED_LABELS,
    payload: selectedLabels,
  })
}

export const setSelectedLabelOwners = (selectedLabelOwners) => (dispatch) => {
  dispatch({
    type: geoFenceActions.SET_SELECTED_LABEL_OWNERS,
    payload: selectedLabelOwners,
  })
}

export const setSelectedFenceOwners = (selectedFenceOwners) => (dispatch) => {
  dispatch({
    type: geoFenceActions.SET_SELECTED_FENCE_OWNERS,
    payload: selectedFenceOwners,
  })
}

export const setStartDate = (startDate) => (dispatch) => {
  dispatch({ type: geoFenceActions.SET_START_DATE, payload: startDate })
}

export const setEndDate = (startEndDate) => (dispatch) => {
  dispatch({ type: geoFenceActions.SET_END_DATE, payload: startEndDate })
}

export const setIngressSelected = (flag) => (dispatch) => {
  dispatch({ type: geoFenceActions.SET_INGRESS_SELECTED, payload: flag })
}

export const setEgressSelected = (flag) => (dispatch) => {
  dispatch({ type: geoFenceActions.SET_EGRESS_SELECTED, payload: flag })
}

export const setSelectedDataInterval = (dataInterval) => (dispatch) => {
  dispatch({
    type: geoFenceActions.SET_SELECTED_DATA_INTERVAL,
    payload: dataInterval,
  })
}

/**
 *  @desc         Telematics API for Fetching operation data
 *  @author       Kailash Desiti
 *  @api          /geo-fence/operation/get-count-by-fence-identifiers
 *  @method       POST
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/

export const fetchOperationsData =
  ({
    selectedCities,
    selectedLabels,
    selectedLabelOwners,
    selectedFenceOwners,
    selectedDataInterval,
    ingressSelected,
    egressSelected,
    startDate,
    endDate,
  }) =>
  async (dispatch) => {
    let operationTypes = ['Ingress', 'Egress']
    if (ingressSelected) {
      operationTypes = []
      operationTypes.push('Ingress')
    }
    if (egressSelected) {
      operationTypes = []
      operationTypes.push('Egress')
    }
    if (ingressSelected && egressSelected) {
      operationTypes = ['Ingress', 'Egress']
    }
    try {
      const resp = await api({
        method: 'POST',
        url: '/geo-fence/operation/get-count-by-fence-identifiers',
        data: {
          cities: selectedCities.map((x) => x.value),
          labels: selectedLabels.map((x) => x.value),
          labelOwners: selectedLabelOwners.map((x) => x.value),
          fenceOnwers: selectedFenceOwners.map((x) => x.value),
          operationTypes: operationTypes,
          intervalDuration: parseInt(
            selectedDataInterval.map((x) => x.value)[0]
          ),
          fromDate: startDate,
          toDate: endDate,
        },
      })

      if (resp.status === 200 && resp?.data?.data) {
        const response = resp?.data?.data
        dispatch({
          type: geoFenceActions.SET_OPERATIONS_TREND_DATA,
          payload: response,
        })
      } else {
        dispatch({
          type: geoFenceActions.SET_OPERATIONS_TREND_DATA,
          payload: [],
        })
      }
    } catch (error) {
      dispatch({ type: geoFenceActions.SET_OPERATIONS_TREND_DATA, payload: [] })
      console.log('error:', error)
    }
  }


  /**
 *  @desc         Telematics API for fetching IngressEgress sessions
 *  @author       Kailash Desiti
 *  @api          /geo-fence/operation/get-by-fence-identifiers
 *  @method       POST
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/

export const fetchIngressEgressSessions =
  ({
    selectedCities,
    selectedLabels,
    selectedLabelOwners,
    selectedFenceOwners,
    startDate,
    endDate,
    reportPageSize,
    reportCurrentPage,
  }) =>
  async (dispatch) => {
    try {
      const resp = await api({
        method: 'POST',
        url: '/geo-fence/operation/get-by-fence-identifiers',
        data: {
          cities: selectedCities.map((x) => x.value),
          labels: selectedLabels.map((x) => x.value),
          labelOwners: selectedLabelOwners.map((x) => x.value),
          fenceOwners: selectedFenceOwners.map((x) => x.value),
          fromDate: startDate,
          toDate: endDate,
          page: reportCurrentPage,
          pageSize: reportPageSize,
        },
      })

      if (resp.status === 200 && resp?.data?.data) {
        const rows = resp?.data?.data.rows
        const count = resp?.data?.data.count
        dispatch({
          type: geoFenceActions.SET_REPORTS_DATA,
          payload: { rows, count },
        })
      } else {
        dispatch({
          type: geoFenceActions.SET_REPORTS_DATA,
          payload: { rows: [], count: 0 },
        })
      }
    } catch (error) {
      dispatch({
        type: geoFenceActions.SET_REPORTS_DATA,
        payload: { rows: [], count: 0 },
      })
      console.log('error:', error)
    }
  }


  /**
 *  @desc         Telematics API for exporting operations data
 *  @author       Kailash Desiti
 *  @api          /geo-fence/operation/get-by-fence-identifiers/export
 *  @method       POST
 *  @createdDate   SEPT 16, 2023
 *  @modifiedDate SEPT 18, 2023
 **/

export const exportOperationsData = async ({
  selectedCities,
  selectedLabels,
  selectedLabelOwners,
  selectedFenceOwners,
  startDate,
  endDate,
}) => {
  try {
    const resp = await api({
      method: 'POST',
      url: '/geo-fence/operation/get-by-fence-identifiers/export',
      responseType: 'arraybuffer',
      data: {
        cities: selectedCities.map((x) => x.value),
        labels: selectedLabels.map((x) => x.value),
        labelOwners: selectedLabelOwners.map((x) => x.value),
        fenceOwners: selectedFenceOwners.map((x) => x.value),
        fromDate: startDate,
        toDate: endDate,
      },
    })

    if (resp.status === 200) {
      const response = resp?.data
      fileDownloader({
        arrayBuffer: response,
        fileName: `ingress_egress_sessions_report_${getFormattedDate(new Date())
          .replace(/,/g, '')
          .replace(':', ' ')
          .split(' ')
          .join('_')}.xlsx`,
      })
    }
  } catch (error) {
    console.log('error:', error)
  }
}

export const setReportsCurrentPage = (page) => (dispatch) => {
  dispatch({ type: geoFenceActions.SET_REPORTS_CURRENT_PAGE, payload: page })
}
