import persistReducer from 'redux-persist/lib/persistReducer'
import storage from "redux-persist/lib/storage";
import actionTypes from '../../actionTypes'
import { createTransform } from 'redux-persist';

const initialState = {
  allDashboardVehicleData: [],
  filterVehicleData: {
    topPanelQuery: 'all',
    prevTopPanelQuery: '',
    soc: [],
    location: [],
    nonComType: '',
    previousNonComType: '',
    oemModel: [],
    vehicleCategory:[],
    dataSource:[],
    groupName: [],
    filterVehicleNoByGroup : [],
    prevOemModel: [],
    prevLocation: [],
    prevSoc: [],
    prevVehicleCategory:[],
    prevDataSource:[],
    prevGroupName: [],
    prevFilterVehicleNoByGroup : []
  },
  vehicleChartData: {},
  topPanelSearchQuery: '',
  homeClickCounter: 0,
  vehicleTrackingCanData: {}
}

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.DASHBOARD.SET_ALL_DASHBOARD_VEHICLES_DATA:
      return {
        ...state,
        allDashboardVehicleData: payload
      }
    case actionTypes.DASHBOARD.SET_PREV_TOP_PANEL_VEHICLE_STATUS: 
      return {
        ...state,
        filterVehicleData: {
         ...state.filterVehicleData,
          prevDataSource:payload.dataSource,
          prevGroupName: payload.groupName,
          prevLocation: payload.location,
          prevOemModel: payload.oemModel,
          prevSoc: payload.soc ,
          prevVehicleCategory:payload.vehicleCategory,
          prevTopPanelQuery: payload.topPanelQuery,
          previousNonComType: payload.nonComType
        }
      }
    case actionTypes.DASHBOARD.SET_TOP_PANEL_VEHICLE_STATUS:
      return {
        ...state,
        filterVehicleData: {
          ...state.filterVehicleData,
          topPanelQuery: payload.topPanelQuery,
          nonComType: payload.nonComType,
          prevTopPanelQuery: state.filterVehicleData.prevTopPanelQuery,
          previousNonComType: state.filterVehicleData.previousNonComType,
          prevDataSource:state.filterVehicleData.prevDataSource,
          prevGroupName: state.filterVehicleData.prevGroupName,
          prevLocation: state.filterVehicleData.prevLocation,
          prevOemModel: state.filterVehicleData.prevOemModel,
          prevSoc: state.filterVehicleData.prevSoc ,
          prevVehicleCategory:state.filterVehicleData.prevVehicleCategory,
          oemModel: payload.oemModel,
          location: payload.location,
          soc: payload.soc ,
          vehicleCategory:payload.vehicleCategory,
          dataSource:payload.dataSource,
          groupName: payload.groupName,
        },
        topPanelSearchQuery: initialState.topPanelSearchQuery,
      }
    case actionTypes.DASHBOARD.SET_TOP_PANEL_VEHICLE_NON_COM_STATUS:
      return {
        ...state,
        filterVehicleData: {
          ...state.filterVehicleData,
          topPanelQuery: payload.status,
          nonComType: payload.nonComType,
        },
        topPanelSearchQuery: initialState.topPanelSearchQuery,
      }

    case actionTypes.VEHICLE.VEHICLE.GET_VEHICLE_TRACKING_CHARGING_DETAILS_SUCCESSS:
      return {
        ...state,
        vehicleChartData: payload
      }
    case actionTypes.DASHBOARD.SET_TOP_PANEL_SEARCH_QUERY:
      return {
        ...state,
        filterVehicleData: {
          ...initialState.filterVehicleData,
          prevTopPanelQuery: state.filterVehicleData.prevTopPanelQuery,
          previousNonComType: state.filterVehicleData.previousNonComType,
          prevLocation: state.filterVehicleData.prevLocation,
          prevDataSource:state.filterVehicleData.prevDataSource,
          prevGroupName: state.filterVehicleData.prevGroupName,
          prevOemModel: state.filterVehicleData.prevOemModel,
          prevSoc: state.filterVehicleData.prevSoc ,
          prevVehicleCategory:state.filterVehicleData.prevVehicleCategory,
        },
        topPanelSearchQuery: payload.searchQuery,
      }
    case actionTypes.DASHBOARD.UPDATE_HOME_CLICK_COUNTER:
      return {
        ...state,
        homeClickCounter: state.homeClickCounter + 1,
        filterVehicleData: initialState.filterVehicleData,
        topPanelSearchQuery: initialState.topPanelSearchQuery,
      }
    case actionTypes.VEHICLE.VEHICLE.GET_VEHICLE_TRACKING_CAN_DATA_DETAILS_SUCCESSS:
        return {
          ...state,
          vehicleTrackingCanData: payload
        }
    case actionTypes.DASHBOARD.RESET_TRACKING_DETAILS:
      return {
        ...state,
        vehicleTrackingCanData: initialState.vehicleTrackingCanData,
        vehicleChartData: initialState.vehicleChartData,
      }
      case actionTypes.DASHBOARD.SET_ALL_FILTERS:
        return {
          ...state,
          filterVehicleData: {
            ...state.filterVehicleData,
            soc: payload.soc || state.filterVehicleData.soc,
            location: payload.location || state.filterVehicleData.location,
            oemModel: payload.oemModel || state.filterVehicleData.oemModel,
            dataSource: payload.dataSource || state.filterVehicleData.dataSource,
            vehicleCategory: payload.vehicleCategory || state.filterVehicleData.vehicleCategory,
            groupName: payload.groupName || state.filterVehicleData.groupName,
            filterVehicleNoByGroup: payload.filterVehicleNoByGroup || state.filterVehicleData.filterVehicleNoByGroup,
          },
          topPanelSearchQuery: initialState.topPanelSearchQuery,
        };
    
    default:
      return state
  }
}

// Presist reducer added to cache store data
export default persistReducer({
  storage,
  key: "dashboardReducer",
  transforms: [
    createTransform(
      // Transform state on its way to being serialized and persisted.
      (inboundState, key) => {
        if (['_persist', 'topPanelSearchQuery'].includes(key)) {
          return inboundState
        }
        if (key === 'filterVehicleData') {
          return {
            ...initialState.filterVehicleData,
            topPanelQuery: inboundState.topPanelQuery,
            nonComType: inboundState.nonComType,
            prevTopPanelQuery: inboundState.prevTopPanelQuery,
            previousNonComType: inboundState.previousNonComType,
            oemModel: inboundState.oemModel,
            location: inboundState.location,
            soc: inboundState.soc ,
            vehicleCategory:inboundState.vehicleCategory,
            dataSource:inboundState.dataSource,
            groupName: inboundState.groupName,
            filterVehicleNoByGroup : inboundState.filterVehicleNoByGroup,
            prevOemModel: inboundState.prevOemModel,
            prevLocation: inboundState.prevLocation,
            prevSoc: inboundState.prevSoc ,
            prevVehicleCategory:inboundState.prevVehicleCategory,
            prevDataSource:inboundState.prevDataSource,
            prevGroupName: inboundState.prevGroupName,
            prevFilterVehicleNoByGroup : inboundState.prevFilterVehicleNoByGroup
          }
        }
        return initialState[key]
      },
      // Transform state being rehydrated
      (outboundState, key) => {
        if (['_persist', 'topPanelSearchQuery'].includes(key)) {
          return outboundState
        }
        if (key === 'filterVehicleData') {
          return {
            ...initialState.filterVehicleData,
            topPanelQuery: outboundState.topPanelQuery,
            nonComType: outboundState.nonComType,
            prevTopPanelQuery: outboundState.prevTopPanelQuery,
            previousNonComType: outboundState.previousNonComType,
            oemModel: outboundState.oemModel,
            location: outboundState.location,
            soc: outboundState.soc ,
            vehicleCategory:outboundState.vehicleCategory,
            dataSource:outboundState.dataSource,
            groupName: outboundState.groupName,
            filterVehicleNoByGroup : outboundState.filterVehicleNoByGroup,
            prevOemModel: outboundState.prevOemModel,
            prevLocation: outboundState.prevLocation,
            prevSoc: outboundState.prevSoc ,
            prevVehicleCategory:outboundState.prevVehicleCategory,
            prevDataSource:outboundState.prevDataSource,
            prevGroupName: outboundState.prevGroupName,
            prevFilterVehicleNoByGroup : outboundState.prevFilterVehicleNoByGroup
          }
        }
        
        return initialState[key]
      },
      {}
    )
  ]
}, dashboardReducer)