import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './VehicleStatus.css'
import ActionTypes from '../../store/actionTypes'
import { useNavigate } from 'react-router-dom'

const RenderVehicleAndCount = ({ isDashboard, vehicleCount, activeVehicleStatus, onActiveVechileStatusChange, filterVehicleData }) => {
    const navigate = useNavigate();
    const handleOnClick = (status) => {
        if (!isDashboard) {
            navigate("/")
            setTimeout(() => {
                if (onActiveVechileStatusChange) {
                    onActiveVechileStatusChange(status)
                }
            }, 300)
        }
    }
    const lableMap = {
        all: "All Vehicles",
        // ignitionOn: "",
        // ignitionOff: "",
        live: "Live Vehicles",
        // Note: Merge Idle status to Live status
        // idle: "Idle Vehicles",
        parking: "Parked Vehicles",
        charging: "Charging Vehicles",
        nonCommunication: "Non Communicating Vehicles",
        favourite: "Favourite Vehicles",
    };
    return (
        Object.keys(vehicleCount).map(status => (
            <button
                key={`vehicle_count_${status}`}
                className={`dvs__vehicle-container ${status}-vehicle`}
                style={{ backgroundColor: activeVehicleStatus === status && !filterVehicleData.soc && !filterVehicleData.location ? '#EFF3F8' : 'transparent' }}
                onClick={() => handleOnClick(status)}
                title={lableMap[status]}
                data-testid={`header-vehicle-filter-button-${status}`}
            >
                <img className='dvs__vehicle-img' src={`images/svgicon/vehicleStatus/${status}.svg`} alt={status} />
                <p className='dvs__vehicle-count'>{vehicleCount[`${status}`]}</p>
                <div className={`dvs__vehicle-line-${status}`}></div>
            </button>
        ))
    )
}

const VehicleStatus = () => {
    const dispatch = useDispatch()
    const vehicleData = useSelector(state => state.dashboardReducer.allDashboardVehicleData)
    const filterVehicleData = useSelector(state => state.dashboardReducer.filterVehicleData)
    const favouriteVehicle = useSelector(state => state.favoriteVehicle.favouriteVehicles)
    const [vehicleCount, setVehicleCount] = useState({
        all: 0,
        // ignitionOn: 0,
        // ignitionOff: 0,
        live: 0,
        idle: 0,
        parking: 0,
        charging: 0,
        nonCommunication: 0,
        favourite: 0,
    })

    useEffect(() => {
        filterVehicleCountBasedOnStatus()
        // eslint-disable-next-line
    }, [vehicleData,favouriteVehicle])

    const filterVehicleCountBasedOnStatus = () => {
        const vehicleCountObj = JSON.parse(JSON.stringify(vehicleCount))
        vehicleCountObj.all = vehicleData.length
        vehicleCountObj.nonCommunication = vehicleData?.filter(fd => fd.ncom).length
        const communicatingVehicle = vehicleData?.filter(fd => !fd.ncom)
        vehicleCountObj.live = communicatingVehicle?.filter(fd => ["Live", "Idle"].includes(fd?.canStatus?.vs)).length;
        // Note: Merge Idle status to Live status
        // vehicleCountObj.idle = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Idle").length;
        vehicleCountObj.parking = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Parked").length;
        vehicleCountObj.charging = communicatingVehicle?.filter(fd => fd?.canStatus?.vs === "Charging").length;
        vehicleCountObj.favourite = favouriteVehicle?.length
        setVehicleCount({ ...vehicleCountObj })
    }

    const handleActiveVehicleStatus = (status) => {
        dispatch({ type: ActionTypes.DASHBOARD.SET_TOP_PANEL_VEHICLE_STATUS, payload: status })
    }

    return (
        <div className='dashboard-vehicle-status'>
            <RenderVehicleAndCount vehicleCount={vehicleCount} activeVehicleStatus={filterVehicleData.topPanelQuery} filterVehicleData={filterVehicleData} onActiveVechileStatusChange={handleActiveVehicleStatus} />
        </div>
    )
}

export default VehicleStatus