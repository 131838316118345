import API from '../../../service/api'
import ActionTypes from '../../actionTypes'
import API_BOOK from '../../../service/endpoints'
import { customToast } from '../../../utils/toasts'

const {
  CREATE_FENCE_EXCEPTION,
  GET_FENCE_BY_ID,
  GET_FENCE_BY_ID_EXCEPTION,
  UPDATE_FENCE,
  UPDATE_FENCE_EXCEPTION,
  FENCE_API_OPERATION,
} = ActionTypes.DIFFERENT_CREATE_FENCE_ACTIONS

const fenceAPISuccessAD = (payload) => ({
  type: FENCE_API_OPERATION,
  payload,
})

const createFenceFailedAD = () => ({
  type: CREATE_FENCE_EXCEPTION,
})

const getFenceByIdSuccessAD = (payload) => ({
  type: GET_FENCE_BY_ID,
  payload,
})

const getFenceByIdFailedAD = () => ({
  type: GET_FENCE_BY_ID_EXCEPTION,
})

const updateFenceSuccessAD = (payload) => ({
  type: UPDATE_FENCE,
  payload,
})

const updateFenceFailedAD = () => ({
  type: UPDATE_FENCE_EXCEPTION,
})

/* Action dispatchers ends here */
/* Actions starts here */

/**
 *  @desc         Create Fence
 *  @author       Nilesh Bharat Salunkhe
 *  @api          `/geo-fence/createfence`
 *  @method       POST
 *  @createdDate  23-Aug-2023
 *  @modifiedDate 23-Aug-2023
 **/

export const CreateFence =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.GEO_FENCE.CREATE_FENCE.method,
        url: API_BOOK.GEO_FENCE.CREATE_FENCE.url,
        data,
      })
      if (resp.status === 200) {
        setTimeout(() => {
          dispatch(fenceAPISuccessAD(true))
          customToast({ message: resp?.data?.message })
        }, 400)
        setTimeout(() => {
          dispatch(fenceAPISuccessAD(false))
        }, 1000)
      } else if (resp.status === 400) {
        setTimeout(() => {
          customToast({ message: resp?.data?.error, svgImageName: 'decline' })
        }, 400)
      } else {
        dispatch(createFenceFailedAD())
      }
    } catch (error) {
      dispatch(createFenceFailedAD())
      console.log('CreateFence error', error)
    }
  }

/**
 *  @desc         Get fence by Id
 *  @author       Nilesh Bharat Salunkhe
 *  @api          `/geo-fence/get-fence-by-id/:_id'`
 *  @method       GET
 *  @createdDate  01-SEP-2023
 *  @modifiedDate 01-SEP-2023
 **/
export const GetFenceById =
  ({ _id }) =>
  async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.GEO_FENCE.GET_FENCE_BY_ID.method,
        url: API_BOOK.GEO_FENCE.GET_FENCE_BY_ID.url.replace(':_id', _id),
      })
      if (resp.status === 200) {
        const response = resp.data.data
        dispatch(getFenceByIdSuccessAD(response))
      } else {
        dispatch(getFenceByIdFailedAD())
      }
    } catch (error) {
      dispatch(getFenceByIdFailedAD())
      console.log('Get fence by id error', error)
    }
  }

/**
 *  @desc         Get fence by Id
 *  @author       Nilesh Bharat Salunkhe
 *  @api          `/geo-fence/update-fence/:id'`
 *  @method       PUT
 *  @createdDate  01-SEP-2023
 *  @modifiedDate 01-SEP-2023
 **/

export const UpdateGeoFence =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await API({
        method: API_BOOK.GEO_FENCE.UPDATE_FENCE.method,
        url: API_BOOK.GEO_FENCE.UPDATE_FENCE.url.replace(':_id', data?._id),
        data,
      })
      if (resp.status === 200 && resp?.data) {
        setTimeout(() => {
          dispatch(fenceAPISuccessAD(true))
          customToast({ message: resp?.data?.message })
        }, 400)
        setTimeout(() => {
          dispatch(fenceAPISuccessAD(false))
        }, 1000)

        dispatch(updateFenceSuccessAD(true))
      } else if (resp.status === 400) {
        setTimeout(() => {
          customToast({ message: resp?.data?.error, svgImageName: 'decline' })
        }, 400)
      } else {
        dispatch(updateFenceFailedAD())
      }
    } catch (error) {
      dispatch(updateFenceFailedAD())
      console.log('error:', error)
    }
  }

/* Actions ends here */
