import React from 'react';
import { PasswordCriteriaMapper } from '../../utils/constants';
import Popover from 'react-bootstrap/Popover';
import { BsFillCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import './PopOverComponent.css';


const PopOverComponentContainer = (props) => {
	const { passwordCriteria, hidePasswordIntruct, showPasswordIntruct, passwordFormData, errorFields ,positionStyle , tootTipAlighmenClass} = props;
	const baseTestId = props['data-testid'] || ''
	
	const getCriteriaElement = (criterion, text, messageTestId) => (
		<p
			className={passwordCriteria[criterion] ? 'text-green' : 'text-red'}
			style={{ fontSize: '12px', marginBottom: '0px' }}
			// NOTE:
			// 	ex. baseTestId = forgot-password, messageTestId = MIN_LENGTH, criterion = SUCCESS|ERROR
			// 	forgot-password-MIN_LENGTH-success-message
			// 	forgot-password-MIN_LENGTH-error-message
			data-testid={`${baseTestId}-${messageTestId}-${passwordCriteria[criterion] ? 'success' : 'error'}-message`}
		>
			{passwordCriteria[criterion] ? '✓' : 'X'} {text}
		</p>
	);

	const isSuccess = !errorFields.password && passwordFormData.password.length >= 8;

	return (
		<>
			<div
				data-testid={`${baseTestId}-${isSuccess ? 'success' : 'error'}-icon`}
				onMouseLeave={hidePasswordIntruct}
				onMouseEnter={showPasswordIntruct}
				className={isSuccess ? 'chagepassword-success '+ tootTipAlighmenClass : 'chagepassword-error '+ tootTipAlighmenClass}
			>
				{isSuccess ? <BsFillCheckCircleFill /> : <BsFillExclamationCircleFill />}
			</div>

			<Popover id='error-popover' style={positionStyle} >
				<Popover.Body>
					{getCriteriaElement(PasswordCriteriaMapper.MIN_LENGTH, 'At least 8 characters', 'MIN_LENGTH')}
					{getCriteriaElement(PasswordCriteriaMapper.DIGIT, 'Contains a digit', 'DIGIT')}
					{getCriteriaElement(PasswordCriteriaMapper.LOWERCASE, 'Contains a lowercase letter', 'LOWERCASE')}
					{getCriteriaElement(PasswordCriteriaMapper.UPPERCASE, 'Contains an uppercase letter', 'UPPERCASE')}
					{getCriteriaElement(PasswordCriteriaMapper.SPECIAL_CHARACTER, 'Contains a special character', 'SPECIAL_CHARACTER')}
				</Popover.Body>
			</Popover>
		</>
	);
};

export default PopOverComponentContainer;
