import React, { useEffect, useState } from "react";
import Modal from '../../../../../shared/modals/BaseModal/BaseModal';
import AccessTemplateList from "../../../AccessTemplate/AccessTemplateList/AccessTemplateList";
import { useSelector } from "react-redux";
import cx from 'classnames'
import styles from './AssignTemplate.module.css'

const AssignTemplateToUser = ({ isOpen, handleClose, assignClicked }) => {
    
    // redux hook
    const templates = useSelector(state => state.department.templateList)
    const masterModules = useSelector(state => state.department.masterModuleList)
    
    // generated mapped template list by combining template list and master module list
    const mapTemplateList = () => {
      let templateList = []
      if(templates || templates.length !== 0) {
        templateList = [...templates]
      }
      
      if (templateList.length && masterModules.length) {
          templateList = templateList.map(template => {
              return {
                  id: template.id,
                  name: template.name,
                  modules: template.modules.map(module => {
                      const moduleData = masterModules.find(mm => mm.moduleId === module.id)
                      return {
                          module: moduleData.module,
                          moduleId: moduleData.moduleId,
                          subModules: module.subModules.map(subModule => {
                              const subModuleData = moduleData.subModules.find(sm => sm.value === subModule.value)
                              return {
                                  label: subModuleData.label,
                                  value: subModuleData.value,
                                  actionTypes: subModule.actionTypes.map(actionType => {
                                      const actionTypeData = subModuleData.actionTypes.find(at => at.value === actionType.value)
                                      return {
                                          label: actionTypeData.label,
                                          value: actionTypeData.value,
                                          isApplicable: actionTypeData.isApplicable,
                                      }
                                  })
                              }
                          })
                      }
                  }),
              }
          })
      }

      return templateList
    }
    const templateListMapped = mapTemplateList();

    const [isAssignButtonDisabled, setIsAssignButtonDisabled] = useState(true);

    const [templateCardDetails, setTemplateCardDetails] = useState({
      isOpen: false,
      templateData: {}
    })
    
    const handleTemplateClick = (data) => {
      const { templateData } = data
      setTemplateCardDetails({
        ...templateCardDetails,
        templateData
      })
    } 

    useEffect(() => {
      if(Object.keys(templateCardDetails.templateData).length) {
        setIsAssignButtonDisabled(false);
      }
    }, [templateCardDetails.templateData])

    useEffect(() => {
      setTemplateCardDetails({
        ...templateCardDetails,
        templateData: {}
      })
      setIsAssignButtonDisabled(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleClose])

    return (
        <Modal
        isOpen={isOpen}
        onClose={handleClose}
        >
          <h3
          style={{
            width:'50vw',
            color: 'white',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}
          >Assign Templates</h3>
        <div
          className={cx(styles.templateListWrapper)}
        >
            <AccessTemplateList
            templateList={templateListMapped}
            onTemplateClick={handleTemplateClick}
            cardId={templateCardDetails?.templateData?.id}
            baseCardVariant="secondary"
            classnames={{
              cardContainer: styles.cardContainer,
              cardsWrapper: styles.cardsWrapper,
            }}
             />
        </div>

         <div className={cx(styles.saveBtnContainer)}>
            <div
              className={cx(styles.saveBtn, { [styles.disabled]: isAssignButtonDisabled } )}
              style={{
                color: "white"
              }}
              onClick={() => assignClicked(templateCardDetails)}
              disabled={isAssignButtonDisabled}
            >
              Assign
            </div>
          </div>
        </Modal>
    )
}
export default AssignTemplateToUser;