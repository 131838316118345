import React, { useState, useEffect } from 'react'
import './EmailTagChips.css'

const EmailTagChips = ({ 
  onItemsChanged,
  selectedItemsProps = [],
  isButtonDisabled = false,
  ...props
}) => {
  const baseTestId = props['data-testid'] || 'email-chips'
  const [items, setItems] = useState([])
  const [value, setValue] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    onItemsChanged(items)
  }, [items, onItemsChanged])

  useEffect(()=>{
    setItems(selectedItemsProps)
  },[selectedItemsProps])

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault()

      const trimmedValue = value.trim()

      if (trimmedValue && isValid(trimmedValue)) {
        setItems([...items, trimmedValue])
        setValue('')
      }
    }
  }

  const handleChange = (evt) => {
    setValue(evt.target.value)
    setError(null)
  }

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item))
  }

  const handlePaste = (evt) => {
    evt.preventDefault()

    const paste = evt.clipboardData.getData('text')
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g) // eslint-disable-line

    if (emails) {
      const toBeAdded = emails.filter((email) => !isInList(email))
      setItems([...items, ...toBeAdded])
    }
  }

  const isValid = (email) => {
    let error = null

    if (isInList(email)) {
      error = 'Email already been added.'
    }

    if (!isEmail(email)) {
      error = 'Invalid email address.'
    }

    if (error) {
      setError(error)
      return false
    }

    return true
  }

  const isInList = (email) => {
    return items.includes(email)
  }

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email) // eslint-disable-line
  }

  return (
    <>
    <div className= {`${!value[0]? 'no-value-selected-input' : ''}`}>

      <div className= "email-container" >
        <div className="d-flex flex-wrap align-items-center">
          {items.map((item) => (
            <div className="chip-tag-item" key={item}>
              {item}
              <button
                hidden={isButtonDisabled}
                type="button"
                className="button"
                onClick={() => handleDelete(item)}
                data-testid={`${baseTestId}-delete-button`}
              >
                &times;
              </button>
            </div>
          ))}

          <input
            className={'chip-input ' + (error && ' has-error')}
            value={value}
            placeholder=""
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
            data-testid={`${baseTestId}-email-input`}
          />

          {error && <p className="chip-input-error">{error}</p>}
        </div>
      </div>
    </div>
    </>
  )
}

export default EmailTagChips
