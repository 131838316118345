import React from 'react'
import './VTVehicleInfo.css'
import { getFormattedDate } from '../../../../utils/common-methods'
import { nonCommStatusLabelMapping } from '../../../../utils/constants'

const vehicleStatus = (status, nct) => {
  if (!status) {
    return 'NA'
  }
  if (status === 'Non Communication') {
    return nonCommStatusLabelMapping['Poor Network']
  }
  // Note: Merge Idle status to Live status
  if (status === 'Idle') {
    status = 'Live'
  }
  if (nct) {
    return nonCommStatusLabelMapping[nct] || nct
  }
  return status
}

const VTVehicleInfo = (props) => {
  const fieldsToRender = [
    {
      label: 'Vehicle Reg. No.',
      value: props.registrationNumber
    },
    {
      label: 'OEM & Model',
      value: props.vehicleOEM + ' ' + props.vehicleModel
    },
    {
      label: 'Avg. Speed',
      value: props.averageSpeed ? props.averageSpeed + ' kmph' : '0 kmph'
    },
    {
      label: 'Max Speed',
      value: props.maxSpeed ? props.maxSpeed + ' kmph' : '0 kmph'
    },
    {
      label: 'Run Kms',
      value: props.vehicleRunKM ? props.vehicleRunKM + ' kms' : 'NA'
    },
    {
      label: 'Odometer Reading',
      value: props.activeIndexInfo.odo
        ? props.activeIndexInfo.odo + ' kms'
        : 'NA'
    },
    {
      label: 'Speed',
      value: props.activeIndexInfo.sp
        ? props.activeIndexInfo.sp + ' kmph'
        : '0 kmph'
    },
    {
      label: 'SoC',
      value: props.activeIndexInfo.soc
        ? props.activeIndexInfo.soc + ' %'
        : 'NA'
    },
    {
      label: 'DTE',
      value: props.activeIndexInfo.dte
        ? props.activeIndexInfo.dte + ' kms'
        : 'NA'
    },
    {
      label: 'Battery Temp',
      value: props.activeIndexInfo.vbt
        ? props.activeIndexInfo.vbt + ' ℃'
        : 'NA'
    },
    {
      label: 'Battery Volt',
      value: props.activeIndexInfo.vbv
        ? props.activeIndexInfo.vbv + ' V'
        : 'NA'
    },
    {
      label: 'Ignition Status',
      // Note: Considering Idle and Live status as Ignition On
      value: ['Idle', 'Live'].includes(props.activeIndexInfo.vs) || props.activeIndexInfo.ig === 'true' ? 'Ignition On'
        : props.activeIndexInfo.ig === 'false' ? 'Ignition Off'
        : 'NA'
    },
    {
      label: 'Vehicle Status',
      // Note: Merge Idle status to Live status
      value: vehicleStatus(props.activeIndexInfo.vs, props.activeIndexInfo.nct)
    },
    {
      label: 'Drive Mode',
      value: props.activeIndexInfo.gp
        ? props.activeIndexInfo.gp
        : 'NA'
    },
  ]

  const onViewChartclikc = () => {
    props.onViewChartClick()
  }
  return (
    <div className='vt__vehicle-tracking-info-container'>
      <div className='vt__vehicle-tracking-info-header'>
        <div className='container-fluid vt__container-fluid'>
          <div className='row'>
            <div className='col-8 vt__tracking-info-header vt__tracking-header-left'>
              {getFormattedDate(props.activeIndexInfo.time)}
            </div>
            <div className='col-4 vt__tracking-header-right'>
              <button
                type='button'
                className='vt__view-chart-button btn btn-primary btn-sm'
                onClick={onViewChartclikc}
                data-testid='vt-view-chart-button'
              >
                View Chart
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='vt__vehicle-tracking-info-content'>
        <br />
        <div className='container-fluid vt__container-fluid'>
          {fieldsToRender.map((field) => (
            <div className='row vt__content-row' key={field.label}>
              <div className='col-6 vt__tracking-info-header'>{field.label}</div>
              <div className='col-1 vt__tracking-info-header'>:</div>
              <div className='col-5 vt__tracking-info-data'>{field.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default VTVehicleInfo
