import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Container, Table } from 'react-bootstrap'
import { BsDownload } from 'react-icons/bs'
import { BiInfoCircle } from 'react-icons/bi'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  ExportHistoricAnalyticsData,
  GetHistoricAnalyticsData,
} from '../../../store/actions/reports/historic-analytics.js'
import Loader from '../../helpers/hoc/loader/Loader.js'
import MultiSelect from '../../helpers/hoc/mult-select/MultiSelect.js'
import NoRecordFound from '../../helpers/hoc/noRecordFound.js'
import Pagination from '../../helpers/hoc/paginator/Pagination.js'
import './css/ReportHistoricData.css'
import {
  Report_Filter_Type,
} from '../../../utils/constants.js'
import DatePicker from '../../helpers/hoc/date-picker/DatePicker.js'
import { GetUserRoleList } from '../../../store/actions/roleAndMenuMapping.js'
import { mapper } from '../../../utils/mapper.js'

const HistoricAnalytics = ({
  //actions
  ExportHistoricAnalyticsData,
  GetHistoricAnalyticsData,
  GetUserRoleList,

  //states
  allHistoricData,
  dataCount,
  isLoading,
  subscribedByCI,
}) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [intervalDuration, setIntervalDuration] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedDataPoints, setSelectedDataPoints] = useState([])
  const [selectedCity, setSelectedCity] = useState([])
  const [selectedOemModel, setSelectedOemModel] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [clearDateSelection, setClearDateSelection] = useState(false)
  const [filterType, setFilterType] = useState(Report_Filter_Type.VEHICLE)

  const ITEMS_PER_PAGE = 8

  const location = useLocation()
  const mappedOptions = mapper.mapVehicleDataOptions(subscribedByCI);

  const dataPoints = [
    {
      label: 'Distance Travelled',
      value: 'distance_travelled',
    },
    {
      label: 'SoH Difference',
      value: 'difference_soh',
    },
    {
      label: 'Energy Charged',
      value: 'energy_charged',
    },
    {
      label: 'Energy Utilized',
      value: 'energy_utilized',
    },
  ]

  useEffect(() => {
    if (mappedOptions.registrationNumber.length) {
      const queryParams = new URLSearchParams(location.search)
      let selectedVehicle = mappedOptions.registrationNumber.filter(
        (x) => x.label === queryParams.get('vehicleNumber')
      )
      setSelectedOptions(selectedVehicle)
    }
    setClearDateSelection(true)
    setTimeout(() => {
      setClearDateSelection(false)
    }, 500)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedByCI, location.search])

  useEffect(() => {
    GetUserRoleList()
  }, [GetUserRoleList])

  useEffect(() => {
    if (dataCount > 0) {
      GetHistoricAnalyticsData({
        registrationNumbers: selectedOptions,
        fromDate: startDate,
        toDate: endDate,
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        intervalDuration: intervalDuration,
        oemAndModels: selectedOemModel?.map((x) => x.value),
        city: selectedCity?.map((x) => x.value),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const calculateIntervalDuration = (fromDate, toDate) => {
    const from = new Date(fromDate)
    const to = new Date(toDate)
    const diff = Math.abs(to - from)
    const minutes = Math.floor(diff / 1000 / 60)

    //for less than 1 day return 1 hr
    if (minutes < 1440) return 120
    //for less than 3 days return 4 hrs
    else if (minutes < 4320) return 240
    //for less than 7 days return 12 hrs
    else if (minutes < 10080) return 720
    //for less than 16 days return 1 day
    else if (minutes < 23040) return 1440
    //for greater than 16 days return 2 day
    return 2880
  }

  const onDateChange = (date) => {
    setStartDate(date[0])
    setEndDate(date[1])
    setIntervalDuration(calculateIntervalDuration(startDate, endDate))
  }

  function onSelectionChange() {
    allHistoricData.length = 0
  }

  const onSubmitHandler = () => {
    setCurrentPage(1)
    GetHistoricAnalyticsData({
      registrationNumbers: selectedOptions,
      fromDate: startDate,
      toDate: endDate,
      pageIndex: 0,
      pageSize: ITEMS_PER_PAGE,
      intervalDuration: intervalDuration,
      oemAndModels: selectedOemModel?.map((x) => x.value),
      city: selectedCity?.map((x) => x.value),
    })
  }

  function exportToExcel() {
    ExportHistoricAnalyticsData({
      registrationNumbers: selectedOptions,
      oemAndModels: selectedOemModel?.map((x) => x.value),
      city: selectedCity?.map((x) => x.value),
      fromDate: startDate,
      toDate: endDate,
    })
  }

  function renderTooltip(props) {
    return (
      <Tooltip className='tooltip' id='button-tooltip' {...props}>
        Start {props.parameter}: {props.start}
        {props?.unit ? props.unit : ''} <br />
        End {props.parameter}: {props.end}
        {props?.unit ? props.unit : ''}
      </Tooltip>
    )
  }

  let isDisabled = () => {
    if (!selectedDataPoints || !selectedDataPoints?.length) {
      return true
    }
    if (Report_Filter_Type.VEHICLE === filterType) {
      return (
        !(
          selectedOptions !== null &&
          selectedOptions?.length > 0 &&
          startDate !== null &&
          endDate !== null
        )
      )
    } else {
      return !(
        selectedOemModel !== null &&
        selectedOemModel?.length > 0 &&
        selectedCity !== null &&
        startDate !== null &&
        endDate !== null
      )
    }
  }

  useEffect(() => {
    if (Report_Filter_Type.VEHICLE === filterType) {
      setSelectedOemModel([])
      setSelectedCity([])
    } else {
      setSelectedOptions([])
    }
    setStartDate(null)
    setEndDate(null)
    setClearDateSelection(true)
    setTimeout(() => {
      setClearDateSelection(false)
    }, 500)
    setSelectedDataPoints([])
  }, [filterType])

  return (
    <>
      <Container fluid>
        <div className=' bg-white mb-1 position-relative  '>
          <div className='records-history-radio-container'>
            <div className='records-history-radio-button'>
              <span>
                <input
                  className='form-check-input'
                  type='radio'
                  name={'tripFilterType'}
                  id={'tripFilterType'}
                  checked={filterType === Report_Filter_Type.VEHICLE}
                  onChange={() => {
                    setFilterType(Report_Filter_Type.VEHICLE)
                    onSelectionChange()
                  }}
                  data-testid={`vehicle-type-radio-button`}
                />
                <text className='radio-checkbox-text-heading'>
                  {Report_Filter_Type.VEHICLE}
                </text>
              </span>
              &nbsp;&nbsp;
              <span>
                <input
                  className='form-check-input'
                  type='radio'
                  name={'tripFilterType'}
                  id={'tripFilterType'}
                  checked={filterType === Report_Filter_Type.OEM_MODEL_CITY}
                  onChange={() => {
                    setFilterType(Report_Filter_Type.OEM_MODEL_CITY)
                    onSelectionChange()
                  }}
                  data-testid={`oem-model-radio-button`}
                />
                <text className='radio-checkbox-text-heading'>
                  {Report_Filter_Type.OEM_MODEL_CITY}
                </text>
              </span>
            </div>
          </div>
        </div>
        <div className='row d-flex tripLogsFilter'>
          <div
            className={`col-3 multi-select-width ${
              !selectedOptions[0] ? 'no-value-selected' : ''
            }`}
            style={{
              display:
                filterType === Report_Filter_Type.VEHICLE ? 'block' : 'none',
            }}
          >
            <MultiSelect
              options={mappedOptions.registrationNumber}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              isMultiSelect={true}
              onChange={onSelectionChange}
              customBackgroundColor='#EFF3F7'
              webkitScrollbarWidth='5px'
              webkitScrollbarHeight='5px'
              minHeight='45px'
              placeholderAlignment='flex-start'
              indicatorSeparatorDisplay='none'
              placeholderColor='#C6C6C6'
              valueContainerColor='#797694'
              data-testid='vehicle-filter'
            />
          </div>

          <div
            className={`col-2 multi-select-width ${
              !selectedOemModel[0] ? 'no-value-selected' : ''
            }`}
            style={{
              display:
                filterType === Report_Filter_Type.OEM_MODEL_CITY
                  ? 'block'
                  : 'none',
            }}
          >
            <MultiSelect
              options={mappedOptions.oemModel}
              selectedOptions={selectedOemModel}
              placeholder='OEM & Model'
              setSelectedOptions={setSelectedOemModel}
              isMultiSelect={true}
              onChange={onSelectionChange}
              customBackgroundColor='#EFF3F7'
              webkitScrollbarWidth='5px'
              webkitScrollbarHeight='5px'
              minHeight='45px'
              placeholderAlignment='flex-start'
              indicatorSeparatorDisplay='none'
              placeholderColor='#C6C6C6'
              valueContainerColor='#797694'
              optionPosition='justify-content-start'
              valueContainerWidthInPx='200px'
              valueContainerMaxWidthInPx='200px'
              menuListPaddingX='px-2'
              okButtonPaddingEnd='pe-2'
              data-testid='oem-model-filter'
            />
          </div>

          <div
            className={`col-2 multi-select-width ${
              !selectedCity ? 'no-value-selected' : ''
            }`}
            style={{
              display:
                filterType === Report_Filter_Type.OEM_MODEL_CITY
                  ? 'block'
                  : 'none',
            }}
          >
            <MultiSelect
              options={mappedOptions.location}
              selectedOptions={selectedCity}
              placeholder='City'
              setSelectedOptions={setSelectedCity}
              isMultiSelect={true}
              onChange={onSelectionChange}
              customBackgroundColor='#EFF3F7'
              webkitScrollbarWidth='5px'
              webkitScrollbarHeight='5px'
              minHeight='45px'
              placeholderAlignment='flex-start'
              indicatorSeparatorDisplay='none'
              placeholderColor='#C6C6C6'
              valueContainerColor='#797694'
              optionPosition='justify-content-start'
              valueContainerWidthInPx='200px'
              valueContainerMaxWidthInPx='200px'
              menuListPaddingX='px-2'
              okButtonPaddingEnd='pe-2'
              data-testid='city-filter'
            />
          </div>

          <div
            className={`col-2 multi-select-width ${
              !selectedDataPoints ? 'no-value-selected' : ''
            }`}
          >
            <MultiSelect
              options={dataPoints}
              selectedOptions={selectedDataPoints}
              placeholder='Select Data Points'
              setSelectedOptions={setSelectedDataPoints}
              isMultiSelect={true}
              onChange={onSelectionChange}
              customBackgroundColor='#EFF3F7'
              webkitScrollbarWidth='5px'
              webkitScrollbarHeight='5px'
              minHeight='45px'
              placeholderAlignment='flex-start'
              indicatorSeparatorDisplay='none'
              placeholderColor='#C6C6C6'
              valueContainerColor='#797694'
              optionPosition='justify-content-start'
              valueContainerWidthInPx='200px'
              valueContainerMaxWidthInPx='200px'
              menuListPaddingX='px-2'
              okButtonPaddingEnd='pe-2'
              data-testid='data-points-filter'
            />
          </div>

          <div
            className={`col-2 multi-select-width ${
              !startDate ? 'no-value-selected' : ''
            }`}
          >
            <DatePicker
              onDateChange={(dates) => {
                onDateChange(dates)
                onSelectionChange()
              }}
              allowedMaxDaysInSelection={31}
              paddingLeft='0px'
              clearDateSelectionOnToggle={clearDateSelection}
              data-testid='daterange-filter'
              placeholderDefaultText = 'Start Date - End Date'
              isShowOneCalendar = {true}
              dateFormat = {'dd-MMM-yyyy'}
              isTodayDisable = {true}
            />
          </div>

          <div className='col-1'>
            <Button
              data-testid='get-report-button'
              className='bttn height'
              id='getReport'
              type='submit'
              onClick={onSubmitHandler}
              disabled={isDisabled()}
            >
              Get Report
            </Button>
          </div>

          <div className='col-1 export-btn-box'>
            <button
              data-testid='export-button'
              disabled={!allHistoricData?.length}
              className={
                !allHistoricData?.length
                  ? 'dwn-btn dwn-btn-disabled'
                  : 'dwn-btn'
              }
              onClick={() => {
                exportToExcel()
              }}
            >
              <BsDownload style={{width:'22px',height:'22px'}} />
            </button>
          </div>
        </div>

        {allHistoricData?.length ? (
          <div className='bg-white mb-2 position-relative trip__log__table mt-4'>
            <Table
              responsive={true}
              borderless
              className='mb-0 historical-data-analytics'
              id='history-table-historical-data'
            >
              <thead>
                <tr className='th-border-bottom'>
                  <th className='ps-2 text-nowrap'>
                    <p className='fw500'>S.No</p>
                  </th>
                  <th className='ps-2 text-nowrap'>
                    <p className='fw500'>Vehicle No.</p>
                  </th>
                  <th className='ps-2 text-nowrap'>
                    <p className='fw500'>OEM & Model</p>
                  </th>
                  <th className='ps-2 text-nowrap'>
                    <p className='fw500'>City</p>
                  </th>
                  <th className='ps-2 text-nowrap'>
                    <p className='fw500'>Last Sync <br/> Date Time</p>
                  </th>
                  {selectedDataPoints.some((r) =>
                    ['distance_travelled'].includes(r.value)
                  ) && (
                    <th className='ps-2 text-nowrap'>
                      <p className='fw500'>
                        Distance <br /> Travelled
                      </p>
                    </th>
                  )}

                  {selectedDataPoints.some((r) =>
                    ['difference_soh'].includes(r.value)
                  ) && (
                    <th className='ps-2 text-nowrap'>
                      <p className='fw500'>
                        State of Health <br /> Difference
                      </p>
                    </th>
                  )}

                  {selectedDataPoints.some((r) =>
                    ['energy_charged'].includes(r.value)
                  ) && (
                    <th className='ps-2 text-nowrap'>
                      <p className='fw500'>
                        Energy <br /> Charged
                      </p>
                    </th>
                  )}

                  {selectedDataPoints.some((r) =>
                    ['energy_utilized'].includes(r.value)
                  ) && (
                    <th className='ps-2 text-nowrap'>
                      <p className='fw500'>
                        Energy <br /> Utilized
                      </p>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {allHistoricData?.map((n, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {index +
                          1 +
                          ((currentPage - 1) * 10 +
                            (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                          '.'}
                      </td>
                      <td>{n?.registrationNumber}</td>
                      <td>
                        {n?.oem} {n?.model}
                      </td>
                      <td>{n?.city}</td>
                      <td>
                        -
                      </td>
                      {selectedDataPoints.some((r) =>
                        ['distance_travelled'].includes(r.value)
                      ) && (
                        <td>
                          {(n?.totalOdometerDiff
                            ? parseFloat(n?.totalOdometerDiff).toFixed(2)
                            : 0) + ' kms '}
                          <OverlayTrigger
                            placement='auto-end'
                            delay={{ show: 0, hide: 0 }}
                            overlay={renderTooltip({
                              parameter: 'Odo',
                              unit: ' kms',
                              start: parseFloat(
                                n?.firstRecord?.startOdometer
                              ).toFixed(2),
                              end: parseFloat(
                                n?.lastRecord?.endOdometer
                              ).toFixed(2),
                            })}
                          >
                            <button className='row-btn'>
                              <BiInfoCircle className='row-i-icon' />
                            </button>
                          </OverlayTrigger>
                        </td>
                      )}
                      {selectedDataPoints.some((r) =>
                        ['difference_soh'].includes(r.value)
                      ) && (
                        <td>
                          {(n?.totalSohDiff
                            ? parseFloat(n?.totalSohDiff).toFixed(2)
                            : 0) + '% '}
                          <OverlayTrigger
                            placement='auto-end'
                            delay={{ show: 0, hide: 0 }}
                            overlay={renderTooltip({
                              parameter: 'State of Health',
                              unit: ' %',
                              start: parseFloat(
                                n?.firstRecord?.startSoH
                              ).toFixed(2),
                              end: parseFloat(n?.lastRecord?.endSoH).toFixed(
                                2
                              ),
                            })}
                          >
                            <button className='row-btn'>
                              <BiInfoCircle className='row-i-icon' />
                            </button>
                          </OverlayTrigger>
                        </td>
                      )}
                      {selectedDataPoints.some((r) =>
                        ['energy_charged'].includes(r.value)
                      ) && (
                        <td>
                          {(n?.totalEnergyCharged
                            ? parseFloat(n?.totalEnergyCharged).toFixed(2)
                            : 0) + ' kWh '}
                        </td>
                      )}
                      {selectedDataPoints.some((r) =>
                        ['energy_utilized'].includes(r.value)
                      ) && (
                        <td>
                          {(n?.totalEnergyUtilized
                            ? parseFloat(n?.totalEnergyUtilized).toFixed(2)
                            : 0) + ' kWh '}
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Pagination
              disabled={false}
              totalPages={Math.ceil((dataCount || 0) / ITEMS_PER_PAGE)}
              currentPage={currentPage}
              setPage={setCurrentPage}
              data-testid='historic-pagination'
            />
          </div>
        ) : (
          <></>
        )}
        {!allHistoricData?.length && !isLoading ? (
          <NoRecordFound></NoRecordFound>
        ) : (
          <></>
        )}
      </Container>
      <Loader isLoading={isLoading} />
    </>
  )
}
HistoricAnalytics.propTypes = {
  allHistoricData: PropTypes.array.isRequired,
  dataCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Action props
  GetHistoricAnalyticsData: PropTypes.func.isRequired,
  ExportHistoricAnalyticsData: PropTypes.func.isRequired,
  subscribedByCI: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  allHistoricData: state.historic.allHistoricData,
  dataCount: state.historic.dataCount,
  isLoading: state.loader.isLoading,
  subscribedByCI: state.vehicle.subscribedVehiclesByCI,
})
export default connect(mapStateToProps, {
  GetHistoricAnalyticsData,
  ExportHistoricAnalyticsData,
  GetUserRoleList,
})(HistoricAnalytics)
