import api from "../../../service/api";
import ActionTypes from "../../actionTypes";
import API_BOOK from "../../../service/endpoints";
import { customToast } from "../../../utils/toasts";
import { NotificationMessage } from "../../../utils/constants";
const { GET_ALL_DATA_FAIL, GET_ALL_DATA_SUCCESS } = ActionTypes.REPORTS.HISTORIC_DATA;
const { GET_ALL_HISTORIC_DATA, EXPORT_HISTORIC_DATA } = API_BOOK.REPORTS.HISTORIC_DATA;


/* Action dispatchers (AD) starts here */ 

const getAllHistoricDataSuccessAD = (payload) => ({
  type: GET_ALL_DATA_SUCCESS,
  payload,
});

const getAllHistoricDataFailAD = () => ({
  type: GET_ALL_DATA_FAIL
});


/* Action dispatchers ends here */ 
/* Actions starts here */ 

/**
 *  @desc         Get all historical data api action
 *  @author       Deepak Prajapati
 *  @api          /report/historic-data
 *  @method       POST
 *  @createdDate  07-Jul-2023
 *  @modifiedDate 20-Jul-2023
 **/
export const GetHistoricData = ({ registrationNumbers, fromDate, toDate, pageSize, pageIndex,intervalDuration }) => async (dispatch) => {
  try {
    const resp = await api({
      method: GET_ALL_HISTORIC_DATA.method,
      url: GET_ALL_HISTORIC_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        pageSize,
        pageIndex,
        intervalDuration
       }
    });
  
    if (resp.status === 200 && resp?.data?.data) {
      const response = resp?.data?.data
      dispatch(getAllHistoricDataSuccessAD(response));
    } else {
      dispatch(getAllHistoricDataFailAD());
    }
  } catch (error) {
    dispatch(getAllHistoricDataFailAD());
    console.log("error:", error);
  }
};

/**
 *  @desc         Export historic data api action
 *  @author       Deepak Prajapati
 *  @api          /report/historic-data/export
 *  @method       POST
 *  @createdDate  07-Jul-2023
 *  @modifiedDate 20-Jul-2023
 **/
export const ExportHistoricData = ({ registrationNumbers, fromDate, toDate }) => async (dispatch) => {
  try {
    const resp = await api({
      method: EXPORT_HISTORIC_DATA.method,
      url: EXPORT_HISTORIC_DATA.url,
      data: {
        registrationNumbers: registrationNumbers?.map((val)=> val.label),
        fromDate,
        toDate,
        timezoneOffset: new Date().getTimezoneOffset()
       }
    });
  
    if (resp.status === 200 && resp?.data) {
      const respData = resp?.data?.data
      dispatch({ type: ActionTypes.NOTIFICATION_PANEL.CURRENT_EXPORTED_DATA_NOTIFICATION, payload: respData });
      customToast({ message: NotificationMessage.Request.Primary, secondaryMessage : NotificationMessage.Request.Secondary, duration : 6000 })
    } else {
      customToast({ message: NotificationMessage.ExportIsInProgress.Primary, secondaryMessage : NotificationMessage.ExportIsInProgress.Secondary, duration : 6000, svgImageName: 'decline' }) 
    }
  } catch (error) {
    console.log("error:", error);
  }
};


/* Actions ends here */ 