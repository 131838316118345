import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, Table, Dropdown, Button } from 'react-bootstrap'
import Pagination from '../../helpers/hoc/paginator/Pagination'
import BrowseAndUploadFileModal from './child components/Bulk upload modal/BrowseAndUploadFileModal'
import SingleVehOnboardingModal from './child components/single vehicle entry modal/SingleVehOnboardingModal'
import { FaSearch } from 'react-icons/fa'
import { BsDownload } from 'react-icons/bs'
import { BiEdit } from 'react-icons/bi'
import { processChangeWithDebounce, getFormattedDate } from '../../../utils/common-methods'
import {
  GetAllOnboardingLogs,
  ExportOnboardingLogs,
} from '../../../store/actions/vehicleOnboarding'
import VehicleOnboardingTemplate from '../../../assets/templates/Vehicle_Onboarding_Template.xlsx'
import './VehicleOnboarding.css'
import ViewVehicleOnboardingModal from './child components/view vehicle onboarding/ViewVehicleOnboardingModal'
import UpdateOnboardingModal from './child components/update vehicle onboarding/UpdateOnboardingModal'
import Loader from '../../helpers/hoc/loader/Loader'

const VehicleOnboarding = ({
  // State props
  allOnboardingLogsData,
  logsCount,
  isLoading,

  // Action props
  GetAllOnboardingLogs,
  ExportOnboardingLogs,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearchKey] = useState()
  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowSVOModal, setIsShowSVOModal] = useState(false)
  const [isShowViewModal, setIsShowViewModal] = useState(false)
  const [selectedOnboardingId, setSelectedOnboardingId] = useState(null)
  const [selectedOnboardedByUserName, setSelectedOnboardedByUserName] = useState(null)
  const [isShowEditModal, setIsShowEditModal] = useState(false)
  const ITEMS_PER_PAGE = 8

  const toggleViewOnboardingVehiclesModal = (e, onboardingId = null, userName = null) => {
    setIsShowViewModal(prev => !prev);
    setSelectedOnboardingId(onboardingId);
    setSelectedOnboardedByUserName(userName);
  }

  const toggleEditOnboardingVehiclesModal = (e, onboardingId = null) => {
    setIsShowEditModal(prev => !prev);
    setSelectedOnboardingId(onboardingId);
  }

  useEffect(() => {
    GetAllOnboardingLogs({
      searchKey: search,
      pageIndex: currentPage - 1,
      pageSize: ITEMS_PER_PAGE,
    })
  }, [GetAllOnboardingLogs, search, currentPage])

  const downloadOnboardingTemplate = () => {
    FileSaver.saveAs(
      VehicleOnboardingTemplate,
      'Vehicle Onboarding_Template.xlsx'
    )
  }
  return (
    <Container fluid>
      <div className="d-flex col-12 flex-column h-100 mt-2">
        <div className="d-flex justify-content-between align-items-center flex-row">
          <div 
          className={` d-inline-flex align-items-center ${!search? 'no-value-selected-input ' : ''}`}
          >
          <div 
          className="search-bar-div search-input"
          >
              <FaSearch className="vob-search-icon" />
              <input
                onChange={(e) => {
                  processChangeWithDebounce(e, setSearchKey, setCurrentPage)
                }}
                className="vob-search-input search-input"
                placeholder="Search by Vehicle Number"
                data-testid='vehicle-search-input'
              />
            </div>
          </div>

          <div className="d-flex flex-row justify-content-end ">
          <div className="me-2">
  <Dropdown className='bulk-upload-dropdown'>
    <Dropdown.Toggle className="vob-btns" id="dropdown-basic" data-testid='bulk-download-dropdown'>
      Bulk Upload
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item
        style={{color: '#22046B'}}
        onClick={() => {
          downloadOnboardingTemplate();
        }}
        data-testid='download-template-button'
      >
        Download Template
      </Dropdown.Item>
      <Dropdown.Item
        style={{color: '#22046B'}}
        onClick={() => {
          isShowModal ? setIsShowModal(false) : setIsShowModal(true);
        }}
        data-testid='upload-file-button'
      >
        Upload File
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
</div>

            <div className="me-2">
              <Button
                onClick={() => {
                  isShowSVOModal
                    ? setIsShowSVOModal(false)
                    : setIsShowSVOModal(true)
                }}
                className="vob-btns"
                data-testid='single-upload-button'
              >
                Single Upload
              </Button>
            </div>

            <div>
              <button
                className="dwn-btns"
                onClick={() => {
                  ExportOnboardingLogs()
                }}
                data-testid='export-button'
              >
                <BsDownload style={{width:'22px',height:'22px'}} />
              </button>
            </div>
          </div>




        </div>
        <div className="bg-white mb-1 position-relative">
          <Table responsive={true} borderless className="mb-0">
            <thead>
              <tr className="th-border-bottom">
                <th className="ps-3 text-nowrap"><span className='fw500'>S.No</span></th>
                <th className="ps-3 text-nowrap"><span className='fw500'>Date of Onboarding</span></th>
                <th className="ps-3 text-nowrap"><span className='fw500'>No. of Vehicle Onboarded</span></th>
                <th className="ps-3 text-nowrap"><span className='fw500'>Vehicle Onboarded by</span></th>
                <th className="ps-3 text-nowrap"><span className='fw500'>Action</span></th>
              </tr>
            </thead>
            <tbody>
              {allOnboardingLogsData.map((Obj, index) => {
                return (
                  <tr key={'row-' + index.toString()}>
                    <td className="ps-3">
                      {' '}
                      {index +
                        1 +
                        ((currentPage - 1) * 10 +
                          (currentPage - 1) * (ITEMS_PER_PAGE - 10)) +
                        '.'}
                    </td>
                    <td className="ps-3">
                      {Obj.onboarding_date
                        ? getFormattedDate(Obj.onboarding_date, true)
                        : Obj.onboarding_date}
                    </td>
                    <td className="ps-3">{Obj.vehicleCount}</td>
                    <td className="ps-3">{Obj.userName}</td>
                    <td className="ps-3 text-nowrap">
                      <div className="d-flex flex-row justify-content-center gap-2">
                        <button
                          className="view-row-btn"
                          data-testid={`view-button-${index}`}
                          onClick={(e) => {
                            toggleViewOnboardingVehiclesModal(e, Obj._id, Obj.userName);
                          }}
                        >
                          View
                        </button>
                        <button className="row-btn"
                          data-testid={`edit-button-${index}`}
                          onClick={(e) => {
                            toggleEditOnboardingVehiclesModal(e, Obj._id);
                          }}
                        >
                          <BiEdit className="row-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Pagination
            disabled={false}
            totalPages={Math.ceil(logsCount / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            setPage={setCurrentPage}
            data-testid='vehicle-onboarding-pagination'
          />
           <Loader isLoading={isLoading} />
        </div>
        <div>
          {isShowModal && (
            <BrowseAndUploadFileModal
              showMoal={isShowModal}
              setIsShowModal={setIsShowModal}
            />
          )}
        </div>
        <div>
          {isShowViewModal && (
            <ViewVehicleOnboardingModal
              onboardingId={selectedOnboardingId}
              onboardedByUser={selectedOnboardedByUserName}
              showModal={isShowViewModal}
              setIsShowModal={setIsShowViewModal}
            />
          )}</div>
        <div>
          {
            isShowEditModal && (
              <UpdateOnboardingModal
                onboardingId={selectedOnboardingId}
                showModal={isShowEditModal}
                setIsShowModal={setIsShowEditModal}
              />
            )
          }
        </div>
        <div>
          {isShowSVOModal && (
            <SingleVehOnboardingModal
              showSVOModal={isShowSVOModal}
              setIsShowSVOModal={setIsShowSVOModal}
            />
          )}
        </div>

      </div>
    </Container>
  )
}

VehicleOnboarding.propTypes = {
  allOnboardingLogsData: PropTypes.array.isRequired,
  logsCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Action props
  GetAllOnboardingLogs: PropTypes.func.isRequired,
  ExportOnboardingLogs: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  allOnboardingLogsData: state.vehOnboarding.allOnboardingLogsData,
  logsCount: state.vehOnboarding.logsCount,
  isLoading: state.loader.isLoading,
})

export default connect(mapStateToProps, {
  GetAllOnboardingLogs,
  ExportOnboardingLogs,
})(VehicleOnboarding)
