import html2canvas from 'html2canvas'

export const captureAndDownloadElementImage = async (element, filename, excludeElements) => {
  const canvas = await html2canvas(element, {
    ignoreElements: (node) => excludeElements.includes(node)
  })
  const imgData = canvas.toDataURL('image/png')
  const link = document.createElement('a')
  link.href = imgData
  link.download = filename
  link.click()
}