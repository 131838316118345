import React from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { getFormattedOnlyDate } from '../../../../../utils/common-methods';
import GPSubgroupTableRow from './GPSubgroupTableRow';
import './AssignModel.css';
import AssignModel from './AssignModel';

const GPGroupTableRow = (props) => {
    const { groupData, groupIndex, isGroupExpanded, hasSubgroup, onGroupExpansionClick, onVehicleCountClick, expandedMenuId, onMenuExpansionClick, onRenameClick, onDeleteClick, onEditClick,isShowViewModal,toggleViewOnboardingVehiclesModal ,toggleViewOnboardingVehiclesModalhandleclose,assigngroupName,groupAssignList} = props;

    return (
        <>
            <tr className="group__row">
                <td className="ps-3 text-nowrap">
                    <p className="fw500 groups__row__td">{groupIndex + 1}.</p>
                </td>
                <td className='ps-3 text-nowrap '>
                    <p className='fw500 groups__row__td groups__row__td_name' onClick={() => onEditClick({ groupData })}>{groupData.name}</p>
                </td>
                <td className='ps-3 text-nowrap '>
                    <p style={{ marginTop: "-6px" }} className='fw500 groups__row__td cursor-pointer'
                        onClick={() => {
                            if (hasSubgroup) {
                                onGroupExpansionClick();
                            } else {
                                onVehicleCountClick({ groupId: groupData._id, groupIndex: groupIndex, groupName: groupData.name, isSubgroup: false, groupVehicles: groupData.vehicles })
                            }
                        }}
                    >
                        <span style={{ color: '#22046B', fontSize: '18px', cursor: 'pointer' }}>
                            <img src='/images/svgicon/groupsIcon/vehicalGroups.svg' alt='info' />{' '}
                        </span>
                        {groupData.count}
                    </p>
                </td>
                <td className='ps-3 text-nowrap '>
                    <p className='fw500 groups__row__td'>{getFormattedOnlyDate(groupData.createdDate)}</p>
                </td>
                <td className='ps-3 text-nowrap '>
                    <p className='fw500 groups__row__td'>{getFormattedOnlyDate(groupData.modifiedDate)}</p>
                </td>
                <td className='ps-3 text-nowrap '>
                    <p className='fw500 groups__row__td'>{groupData.noOfSubGroups}</p>
                </td>
                <td className='ps-3 text-nowrap '>
                <p className='fw500 groups__row__td groups_assinee_td' style={{cursor:'pointer'}}
                    onClick={(e) => {
                        toggleViewOnboardingVehiclesModal(e,{id:groupData._id, groupName:groupData.name,userIds:groupData.assignees}  );
                    }}
                >
                    {groupData?.assignees?.length}
                </p>
                </td>

                <td className='ps-3 text-nowrap'>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <p className='fw500 groups__row__td'>
                            <span
                                className='groups__view__threedots cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation() 
                                    onMenuExpansionClick(groupData._id)
                                }}
                                data-testid={`group-action-menu-${groupData.name}`}
                            >
                                <img
                                    src='/images/svgicon/groupsIcon/verticalThreedots.svg'
                                    alt='info'
                                    style={{ width: '20px', height: '20px' }}
                                    id={'spanThreeDots' + groupIndex}
                                />
                            </span>
                        </p>

                        {expandedMenuId === groupData._id && (
                            <div className='popupGroups'>
                                <ul className='popupGroups_ul'>
                                  
                                    <li className='popupGroups_li' onClick={() => onRenameClick({ groupId: groupData._id, name: groupData.name, isSubgroup: false })}
                                        data-testid={`group-action-rename-button-${groupData.name}`}
                                    >
                                        Rename
                                    </li>
                                    <li onClick={() => onEditClick({ groupData })}>
                                            Edit
                                   </li>
                                    <li onClick={() => onDeleteClick({ groupId: groupData._id, isSubgroup: false })}
                                        data-testid={`group-action-delete-button-${groupData.name}`}
                                    >
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </td>

                <td className='ps-3 text-nowrap'>
                    {groupData.subGroup.length ? (
                        <p
                            className={`fw500 groups__row__td cursor-pointer`}
                            onClick={onGroupExpansionClick}
                            data-testid={`group-expansion-toggle-${groupData.name}`}
                        >
                            {isGroupExpanded ? (
                                <BsChevronUp
                                    style={{
                                        fontSize: 14,
                                        fontWeight: '500',
                                        strokeWidth: 1
                                    }}
                                />
                            ) : (
                                <BsChevronDown
                                    style={{
                                        fontSize: 14,
                                        fontWeight: '500',
                                        strokeWidth: 1
                                    }}
                                />
                            )}
                        </p>
                    ) : null}
                </td>
            </tr>
            {isGroupExpanded && groupData.subGroup.map((subgroupData, subgroupIndex) => (
                <GPSubgroupTableRow
                    key={`${groupIndex}_subgroup_${subgroupIndex}`}
                    subgroupData={subgroupData}
                    subgroupIndex={subgroupIndex}

                    onVehicleCountClick={() => {
                        onVehicleCountClick({ groupId: subgroupData._id, groupIndex: subgroupIndex, groupName: subgroupData.name, isSubgroup: true, groupVehicles: subgroupData.vehicles })
                    }}

                    expandedMenuId={expandedMenuId}
                    onMenuExpansionClick={(e) => {
                        e.stopPropagation()
                        onMenuExpansionClick(subgroupData._id)
                    }}

                    onRenameClick={() => {
                        onRenameClick({ groupId: groupData._id, subgroupId: subgroupData._id, name: subgroupData.name, isSubgroup: true })
                    }}
                    onDeleteClick={() => onDeleteClick({ groupId: groupData._id, subgroupId: subgroupData._id, isSubgroup: true })}
                    onEditClick={() => onEditClick({ groupData, subgroupData, subgroupIndex })}
                />
            ))}

            {isShowViewModal && (
                <div>
                    <AssignModel
                    assigngroupName={assigngroupName}
                    showModal={isShowViewModal}
                    handleclose={toggleViewOnboardingVehiclesModalhandleclose}
                    groupAssignList={groupAssignList}
                    />
                </div>
                )}
        </>
    )
}
export default GPGroupTableRow;