import ActionTypes from "../../../actionTypes";

const actions = ActionTypes.REPORTS_ANALYTICS.TRIP_REPORT_ACTIONS;
const initialState = {
    isCustomDateSelected: false,
    customFromDate: '',
    customToDate: '',
    selectedDuration: 'today',
    areaChartDataByCity: [],
    areaChartDataByOemModel: [],
    areaChartOemModelLevel: [],
    areaChartCityLevel: [],
    tripReportData: null,
    currentPage: 1,
    reportOemCityWiseTripData: [],
    reportOemCityWiseTripDataCount: 0,
    filter: '',
    filterType: ''
}

const tripReportAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;

    switch(type) {
        case actions.SET_CUSTOM_DURATION:
            return {
                ...state,
                isCustomDateSelected: true,
                customFromDate: payload.startDate,
                customToDate: payload.endDate,
                currentPage:1,
                filter: '',
                filterType: ''
            }
        case actions.SET_SELECTED_DURATION:
            return {
                ...state,
                isCustomDateSelected: false,
                selectedDuration: payload,
                currentPage:1,
                filter: '',
                filterType: ''
            }
        case actions.SET_AREA_CHART_DATA_BY_CITY:
            return {
                ...state,
                areaChartDataByCity: payload
            }
        case actions.SET_AREA_CHART_DATA_BY_OEM_MODEL:
            return {
                ...state,
                areaChartDataByOemModel: payload
            }
        case actions.SET_AREA_CHART_OEM_MODEL_LEVEL:
            return {
                ...state,
                areaChartOemModelLevel: payload
            }
        case actions.SET_AREA_CHART_CITY_LEVEL:
            return {
                ...state,
                areaChartCityLevel: payload
            }
        case actions.SET_TRIP_REPORT_DATA:
            return {
            ...state,
            tripReportData: payload,
            };
        case actions.SET_REPORTS_CURRENT_PAGE: 
            return {
                ...state,
                currentPage: payload
            }
        case actions.SET_OEM_CITY_WISE_TRIP_REPORT_DATA:
            return {
                ...state,
                reportOemCityWiseTripData: payload.rows,
                reportOemCityWiseTripDataCount: payload.count
            }
        case actions.SET_OEM_CITY_WISE_TRIP_REPORT_FILTER:
            return {
                ...state,
                filter: payload.filter,
                filterType: payload.filterType,
                currentPage: 1
            }
        default:
            return state;
    }
}

export default tripReportAnalyticsReducer;
