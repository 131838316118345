import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styles from './OTPModals.module.css';
import OtpInput from '../../form/OtpInput/OtpInput';
import Modal from '../BaseModal/BaseModal';

const OTPModal = ({ isOpen, closeModal, onChange, value, userEmail, error, resendOtpHandler, onPrevoiusPageClick }) => {
	const [otpTimer, setOtpTimer] = useState(60);

	useEffect(() => {
		let interval;
	  
		if (isOpen && otpTimer > 0) {
		  interval = setInterval(() => {
			setOtpTimer(prev => {
			  const newTimer = prev > 0 ? prev - 1 : 0;
			  return newTimer >= 10 ? newTimer : `0${newTimer}`;
			});
		  }, 1000);
		}
	  
		return () => clearInterval(interval);
	  }, [isOpen, otpTimer]);
	  
	
	  const handleResendOtp = (e) => {
		e.preventDefault();
		if (otpTimer === '00') {
			resendOtpHandler();
			setOtpTimer(60);
		}
	  };

	  const handleCloseModal = () => {
		if(closeModal){
			setOtpTimer(60);
			closeModal();
		}
	  }

	return (
		isOpen && (
			<Modal isOpen={isOpen} title="OTP Verification" onClose={handleCloseModal} variant='auth'>
				<div style={{ padding: '5px 106px' }}>
					<span className='text-white'>Please enter the verification code that was sent to {userEmail}</span>
					<div className={`${styles.otpContainer}`}>
						<span className='text-white'>Verification code<span style={{color:'#C417E0'}}>*</span></span>
						<OtpInput onChange={onChange} value={value} error={error} handleResendOtp={handleResendOtp} otpTimer={otpTimer} onPrevoiusPageClick={onPrevoiusPageClick} />
					</div>
				</div>
			</Modal>
		)
	);
};

OTPModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	userEmail: PropTypes.string.isRequired,
	error: PropTypes.string,
	resendOtpHandler: PropTypes.func.isRequired,
	onPrevoiusPageClick: PropTypes.func,
};

OTPModal.defaultProps = {
	isOpen: false,
	closeModal: () => {},
	onChange: () => {},
	value: '',
	userEmail: '',
	error: '',
	resendOtpHandler: () => {},
	onPrevoiusPageClick: () => {},
};

export default memo(OTPModal);
/*conflict-resolution-will be removed later*/