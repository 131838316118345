import React from 'react'

import './NDTrackingWebCam.css'

const NDTrackingWebCam = ({ camURL }) => {
  return (
    <div className='nd__dark-tracking-web-cam-card mb-2'>
      <iframe className='nd__dark-tracking-web-cam-card-iframe' height="160px" width="70%" src={camURL} title="normic"></iframe>
    </div>
  )
}

export default NDTrackingWebCam