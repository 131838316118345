import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { Col, Row } from "react-bootstrap";
import DashboardKPI from "../../../shared/cards/DashboardKPI/DashboardKPI";
import PageHeader from "../../../shared/ui/PageHeader/PageHeader";
import Button from "../../../shared/form/Button/Button";
// import editBtn from '../../../../../assets/darkThemeAssets/editButtonLightBlue.svg'
import editBtn from '../../../../assets/darkThemeAssets/editButtonLightBlue.svg'
import styles from "../DepartmentAdminManage/UserControl/userControl.module.css";
import {
  getUserCount,
  getVehicleCount,
  PendingDepartmentUsers,
  ApproveDepartmentUserRequest,
  DeclineDepartmentUserRequest,
  AssignTemplateToUserApprovalRequest,
} from "../../../../store/actions/department/department";
// import sharedStyles from "../../../pages/super-admin/SADashboard/SADashboard.module.css";
import { useNavigate } from "react-router-dom";
import departmentTemplateAction from "../../../../store/actions/department/departmentTemplate";
import moment from "moment";
import Popover from "../../../shared/modals/Popover/Popover";
import PendingRequestModal from "../DepartmentAdminManage/Modal";
import AssignTemplateToUser from "../DepartmentAdminManage/Pending Requests/AssignTemplate/AssignTemplateToUser";
import SuccessFlashMessage from "../../../shared/modals/FlashMessage/SuccessFlashMessage";
import InformativeModal from "../../../shared/modals/InformativeModal/InformativeModel";
import EditDuUserApproval from "../DepartmentAdminManage/Pending Requests/EditDuUserApproval";
import Table from "../../../shared/table/Table";

const DADashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 8;


  const pendingUsers = useSelector((s) => s?.department?.pendingDepartmentUsers)
  const pendingUsersCount = useSelector((s) => s?.department?.pendingDepartmentUsersCount)
  const totalAccessTemplateCount = useSelector(state => state.department.templateList).length

  const [expandedMenuId, setExpandedMenuId] = React.useState("");
  const popupRef = useRef(null);

  const totalUsersCount = useSelector(s => s?.department?.departmentUser)
  const totalVehiclesCount = useSelector(s => s?.department?.totalVehicleCount)

  const [sortField, setSortfield] = useState({ sortBy: "", sortOrder: "" });
  const sortHandler = (sortBy, sortOrder) => {
    setSortfield({
      sortBy,
      sortOrder,
    });
  };

  const [templateDetails, setTemplateDetails] = useState({
    userId: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [assignTemplateResDetails, setAssignTemplateResDetails] = useState({
    isOpen: false,
    message: "",
  });

  const [editModalDetails, setEditModalDetails] = useState({
    isOpen: false,
    editDetails: {},
  });

  const [modal, setModalOptions] = useState({
    isOpen: false,
    name: "",
    status: "",
    userId: "",
    row: {},
  });

  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: "",
    variant: "",
  });

  const handleStatusClick = ({ name, status, userId, row = {} }) => {
    setModalOptions({
      ...modal,
      isOpen: true,
      name,
      status,
      userId,
      row,
    });
  };

  const handleCloseClick = () => {
    setModalOptions({
      ...modal,
      isOpen: false,
      name: "",
      status: "",
      userId: "",
    });
  };

  const handleApproveOrRejectClick = () => {
    const status = modal?.status;
    const isTemplateAssigned = modal.row.assingedTemplateId;
    if (status === "approve") {
      if (!isTemplateAssigned) {
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: "Thank you for your request We are unable to approve the process without assign template!",
          variant: "error",
        });
        setTimeout(() => {
          setModalOptions({
            ...modal,
            isOpen: false,
          });
        }, 2002);
      } else {
        handleApproveClick();
      }
    }
    if (status === "decline") handleDeclineClick();
  };

  const handleApproveClick = async () => {
    try {
      const res = await ApproveDepartmentUserRequest({ userId: modal?.userId });
      if (res.status === 200) {
        setModalOptions({
          ...modal,
          isOpen: false,
          name: "",
          status: "",
          userId: "",
        });
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: "Successfully Approved",
          variant: "success",
        });
        dispatch(
          PendingDepartmentUsers({
            pageIndex: currentPage,
            pageSize: ITEMS_PER_PAGE,
            sortBy: sortField.sortBy,
            sortOrder: sortField.sortOrder,
          })
        );
      }
    } catch (error) {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: error,
        variant: "error",
      });
      console.log("ApproveDepartmentUserRequest error", error);
    }
  };

  const handleDeclineClick = async () => {
    try {
      const res = await DeclineDepartmentUserRequest({ userId: modal?.userId });
      if (res.status === 200) {
        setModalOptions({
          ...modal,
          isOpen: false,
          name: "",
          status: "",
          userId: "",
        });
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: "Successfully Declined",
          variant: "success",
        });
        dispatch(
          PendingDepartmentUsers({
            pageIndex: currentPage,
            pageSize: ITEMS_PER_PAGE,
            sortBy: sortField.sortBy,
            sortOrder: sortField.sortOrder,
          })
        );
      }
    } catch (error) {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: error,
        variant: "variant",
      });
      console.log("DeclineDepartmentUserRequest error", error);
    }
  };

  const closeFlashHandler = () => {
    setAssignTemplateResDetails({
      ...assignTemplateResDetails,
      isOpen: false,
    });
  };

  const handleAssignTemplateClick = async (data) => {
    const {
      templateData: { id, name },
    } = data;
    try {
      const res = await AssignTemplateToUserApprovalRequest({
        userId: templateDetails?.userId,
        accessTemplateId: id,
      });
      if (res.status === 200) {
        setIsModalOpen(false);
        setTimeout(() => {
          setAssignTemplateResDetails({
            ...assignTemplateResDetails,
            isOpen: true,
            message: `${name} assigned successfully!`,
          });
        }, 100);
      }
    } catch (error) {
      console.log("handleAssignTemplateClick error:", error);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalDetails({
      ...editModalDetails,
      isOpen: false,
    });
  };

  useEffect(() => {
    dispatch(departmentTemplateAction.getMasterModulesList());
    dispatch(departmentTemplateAction.getTemplatesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      PendingDepartmentUsers({
        pageIndex: currentPage,
        pageSize: ITEMS_PER_PAGE,
        sortBy: sortField.sortBy,
        sortOrder: sortField.sortOrder,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortField,
    editModalDetails.isOpen,
    assignTemplateResDetails.isOpen,
  ]);

  const closePopup = () => {
    setExpandedMenuId("");
  };

  const fetchUsersCount = () => dispatch(getUserCount())
  const fetchVehicleCount = () => dispatch(getVehicleCount())

  useEffect(() => {
    fetchUsersCount()
    fetchVehicleCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    }

    if (expandedMenuId) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expandedMenuId]);

  const columns = [
    {
      key: "rId",
      header: "USER ID",
      sortable: true,
      sortFieldName: "rId",
      renderCell: (row) => <>{row.rId}</>,
    },
    {
      key: "name",
      header: "NAME",
      sortable: true,
      sortFieldName: "name",
      renderCell: (row) => <>{row?.name}</>,
    },
    {
      key: "createdAt",
      header: "CREATED ON",
      sortable: true,
      sortFieldName: "createdAt",
      renderCell: (row) => <>{moment(row?.createdAt).format("DD MMM YYYY")}</>,
    },
    {
      key: "email",
      header: "EMAIL ID",
      sortable: true,
      sortFieldName: "email",
      renderCell: (row) => <>{row?.email}</>,
    },
    {
      key: "departmentName",
      header: "DEPARTMENT",
      sortable: true,
      sortFieldName: "departmentName",
      renderCell: (row) => <>{row?.departmentName}</>,
    },
    {
      key: "location",
      header: "LOCATION",
      sortable: true,
      sortFieldName: "location",
      renderCell: (row) => <>{row?.location}</>,
    },
    {
      key: "accessTemplate",
      header: "ACCESS",
      sortable: false,
      sortFieldName: "accessTemplate",
      renderCell: (row) => {
        return (
          <>
            {row?.assingedTemplateId && (
              <>
                <span
                  style={{
                    color: "#39FFFF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {row?.assignedTemplate}
                </span>
                <Popover
                  triggerComponent={
                    <button className="row-btn">
                      <img
                        src="/images/tooltipInfo.svg"
                        alt="info"
                        className="alert-history-info"
                      />
                    </button>
                  }
                  bodyComponent={
                    <div className={`${styles.tooltip_inner_container}`}>
                      {row?.modules?.map((module) => (
                        <div key={module?.moduleId}> {module?.moduleName} </div>
                      ))}
                    </div>
                  }
                  trigger={["hover", "focus"]} // Include both "hover" and "focus" triggers
                  placement="right-start"
                />
              </>
            )}
            {!row?.assingedTemplateId && (
              <span
                style={{
                  color: "#39FFFF",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsModalOpen(true);
                  setTemplateDetails({
                    ...templateDetails,
                    userId: row?.id,
                  });
                }}
              >
                Assign
              </span>
            )}
          </>
        );
      },
    },
    {
      key: "action",
      header: "ACTION",
      sortable: false,
      renderCell: (row, index) => (
        // Assuming ActionComponent is a component that renders the action buttons
        <div className={cx(styles.actionColParent)}>
          <div
            className={cx(styles.approveBtn)}
            onClick={() =>
              handleStatusClick({
                name: row?.name,
                status: "approve",
                userId: row?.id,
                row,
              })
            }
          >
            Approve
          </div>
          <div
            className={cx(styles.declineBtn)}
            onClick={() =>
              handleStatusClick({
                name: row?.name,
                status: "decline",
                userId: row?.id,
              })
            }
          >
            Decline
          </div>
          <img
            src={editBtn}
            alt="editBtn"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditModalDetails({
                ...editModalDetails,
                isOpen: true,
                editDetails: row,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const handleCreateTemplateClick = () => {
    navigate("/da/access-template");
  };

  const handleViewAllPendingRequestsClick = () => {
    navigate("/da/manage")
  }

  return (
    <div className={cx("d-flex flex-column h-100")}>
      <div className="d-flex align-items-center justify-content-between">
        <PageHeader heading="Asset Dashboard" />
        
        <Button
          variant="BLUE"
          onClick={handleCreateTemplateClick}
        >
          Create Template
        </Button>
      </div>

      <div className="mb-4">
        <Row>
          <Col md="3">
            <DashboardKPI
              heading="Total Users Onboarded"
              count={totalUsersCount}
              detailsLink="/da/dashboard"
              image="/images/svgicon/totalCustomers.svg"
            />
          </Col>

          <Col md="3">
            <DashboardKPI
              heading="Total Vehicles Onboarded"
              count={totalVehiclesCount}
              detailsLink="/da/dashboard"
              image="/images/svgicon/totalVehicles.svg"
            />
          </Col>

          <Col md="3">
            <DashboardKPI
              heading="Access Template Count"
              count={totalAccessTemplateCount}
              detailsLink="/da/dashboard"
              image="/images/svgicon/accessTemplate.svg"
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <PageHeader
          variant="SECONDARY_PAGE"
          heading={() => <>Pending Requests <strong style={{ color: "#EF7B10" }}>({pendingUsersCount})</strong></>}
        />

        <Button variant="LINK" onClick={handleViewAllPendingRequestsClick}>View All</Button>
      </div>
      

      <Table
        data={pendingUsers}
        columns={columns}
        handleSortHandler={(e, v) => sortHandler(e, v)}
      />

      <PendingRequestModal
        isVisible={modal.isOpen}
        closeModal={handleCloseClick}
        name={modal?.name}
        handleUpdateStatus={handleApproveOrRejectClick}
        status={modal?.status}
      />

      <InformativeModal
        isOpen={apiResponse.isOpen}
        onClose={() =>
          setApiResponse({
            ...apiResponse,
            isOpen: false,
          })
        }
        message={apiResponse.message}
        variant={apiResponse.variant}
      />
      <EditDuUserApproval
        isOpen={editModalDetails.isOpen}
        editData={editModalDetails.editDetails}
        onClose={() => {
          setEditModalDetails({ ...editModalDetails, isOpen: false });
        }}
        closeEditModal={handleCloseEditModal}
      />
      <AssignTemplateToUser
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        assignClicked={handleAssignTemplateClick}
      />
      <SuccessFlashMessage
        isOpen={assignTemplateResDetails.isOpen}
        message={assignTemplateResDetails.message}
        closeHandler={closeFlashHandler}
      />
    </div>
  );
};

export default DADashboard;
