import ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import axios from 'axios'
import API_BOOK from '../service/endpoints'

/* Method to check valid email */
export const IsValidEmail = (email) => {
  return email
    ?.toString()
    ?.match('^[A-Za-z0-9._%+-]+@(magentamobility|magentagroup).com$')
}

/* Method to check valid magenta email or mobile number */
export const IsValidEmailOrMobile = (email) => {
  return email
    ?.toString()
    ?.match(
      '^[A-Za-z0-9._%+-]+@(magentamobility|magentagroup).com$|(^[0-9]{10})+$'
    )
}

/* Method to check valid mobile number */
export const IsValidMobile = (mobile) => {
  return mobile?.toString()?.match('(^[0-9]{10})+$')
}

/* Method to verify password string */
export const IsValidPassword = (password) => {
  return password
    ?.toString()
    ?.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[\W_]).{8,31}$/)
}

/* Method to get array range */
export const range = (start, end) => {
  for (var i = start, list = []; i <= end; list.push(i), i++);
  return list
}

/* Method to set debounce time on search */
function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
function saveInput(e, state1, state2) {
  state1(e?.target?.value)
  state2(1)
}

export const processChangeWithDebounce = debounce((e, state1, state2) =>
  saveInput(e, state1, state2)
)

/* Method to get formatted datetime */
export const getFormattedDate = (date, isDateOnly = false, skipSeconds = false) => {
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  const dateTime = new Date(date)
  const day = dateTime.getDate().toString().padStart(2, '0')
  const monthIndex = dateTime.getMonth()
  const hrs = dateTime.getHours().toString().padStart(2, '0')
  const mins = dateTime.getMinutes().toString().padStart(2, '0')
  const sec = dateTime.getSeconds().toString().padStart(2, '0')
  const monthName = monthNames[monthIndex]
  const year = dateTime.getFullYear()
  if (isDateOnly) {
    return `${day}-${monthName}-${year}`
  }
  if (skipSeconds) {
    return `${day}-${monthName}-${year}, ${hrs}:${mins}`
  }
  return `${day}-${monthName}-${year}, ${hrs}:${mins}:${sec}`
}

/* Method to get formatted date */
export const getFormattedOnlyDate = (date, isDateOnly = false) => {
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  const dateTime = new Date(date)
  const day = dateTime.getDate().toString().padStart(2, '0')
  const monthIndex = dateTime.getMonth()
  const monthName = monthNames[monthIndex]
  const year = dateTime.getFullYear()
  if (isDateOnly) {
    return `${day}-${monthName}-${year}`
  }
  return `${day}-${monthName}-${year}`
}

/* Method to get seconds to minutes And seconds */
export const  secondsToMinutesAndSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

/* Method to get seconds to hours and minutes */
export const secondsToHoursAndMinutes = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

/* Method to get seconds to hours*/
export const secondsToHours = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  let formattedHours = String(hours).padStart(2, '0');
  if (formattedHours === '00') {
    formattedHours = 0;
  }

  return formattedHours;
};


/* Method to get seconds to hours & minutes*/
export const secondsToHoursAndMinutesHHMM = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}H${" "}${formattedMinutes}M`;
}

export const secondsToRemainingMinutesAfterHour = (seconds) => {
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const formattedMinutes = String(minutes).padStart(2, '0');
  return formattedMinutes
}

/* Method to get seconds to days hours & minutes n seconds*/
export const secondsToDayHousrsAndMinuesteSeconds = (seconds, includeDays = false) => {
  const days = Math.floor(seconds / 86400);
  const remainingSeconds = seconds % 86400;
  const hours = Math.floor(remainingSeconds / 3600);
  const remainingSeconds2 = remainingSeconds % 3600;
  const minutes = Math.floor(remainingSeconds2 / 60);
  const remainingSeconds3 = remainingSeconds2 % 60;
  const formattedDays = includeDays ? `${days}d ` : '';
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds3).padStart(2, '0');

  return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

/* Generate excel */
export const generateExcel = ({
  columns,
  sheetName = 'undefined',
  rows = [],
}) => {
  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet(sheetName)
  sheet.columns = columns
  rows.forEach((response) => {
    sheet.addRow(response)
  })
  return workbook
}

/* download file */
export const fileDownloader = ({
  arrayBuffer = null,
  excelWorkbook = null,
  fileName = 'undefined.xlsx',
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}) => {
  if (arrayBuffer) {
    const blob = new Blob([arrayBuffer], { type: fileType })
    FileSaver.saveAs(blob, fileName)
  } else if (excelWorkbook) {
    excelWorkbook.xlsx.writeBuffer().then(function (buffer) {
      const blob = new Blob([buffer], { type: fileType })
      FileSaver.saveAs(blob, fileName)
    })
  }
}

/* Method to get date in YYYYMMDD format*/
export const getDateStringInYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/* Method to get publicIP*/
export const getPublicIP = async () => {
  try {
    const response = await axios(API_BOOK.GET_IP.url,{headers: {Authorization: null}});
    const publicIP = response?.data?.ip;
    return publicIP;
  } catch (error) {
    console.error('Error fetching or storing public IP address:', error);
    return null
  }
}

/* Method to set client IP in localstorage*/
export const setClientIPInLocalStorage = async()=> {
  const publicIp = await getPublicIP();
  localStorage.setItem("clientIP", publicIp);
}

/* Method to clear localstorage except clientIP*/
export const clearLocalStorageExceptclientIP = ()=>{
  const clientIp = localStorage.clientIP
  const secretX = localStorage.getItem('secretX')
  const secretY = localStorage.getItem('secretY')
  localStorage.clear()
  localStorage.setItem("clientIP", clientIp);
  if (secretX && secretY) {
    localStorage.setItem("secretX", secretX);
    localStorage.setItem("secretY", secretY);
  }
}

/* Method to compare sentence*/
export const sentenceCompare = (str1, str2)=> {
  str1 = str1.replace(/\s+/g, ' ').trim().toLowerCase()
  str2 = str2.replace(/\s+/g, ' ').trim().toLowerCase()
  return (str1===str2)
}

export const coordinatesToDMS = (latitude, longitude) => {
  // Convert latitude to DMS
  let latDegrees = Math.floor(latitude);
  let latMinutes = Math.floor((latitude - latDegrees) * 60);
  let latSeconds = ((latitude - latDegrees) * 60 - latMinutes) * 60;
  let latDirection = latitude >= 0 ? "N" : "S";

  // Convert longitude to DMS
  let lonDegrees = Math.floor(longitude);
  let lonMinutes = Math.floor((longitude - lonDegrees) * 60);
  let lonSeconds = ((longitude - lonDegrees) * 60 - lonMinutes) * 60;
  let lonDirection = longitude >= 0 ? "E" : "W";

  // Format the DMS strings
  let latDMS = `${latDegrees}°${latMinutes}'${latSeconds.toFixed(2)}"${latDirection}`;
  let lonDMS = `${lonDegrees}°${lonMinutes}'${lonSeconds.toFixed(2)}"${lonDirection}`;

  return [latDMS, lonDMS];
}

export const formatTimestamp = (timestamp) => {
  const dt = new Date(timestamp);
  
  // Convert date to "day-MONTH-year"
  const formattedDate = dt.toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });

  // Convert time to hours and minutes (24-hour format)
  const formattedTime = dt.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit',hour12: false });

  return `${formattedDate.toUpperCase()}, ${formattedTime}`;
}


export const addEscapeKeyListener = (callback) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      callback();
    }
  };

  window.addEventListener('keydown', handleKeyDown);

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
};

export const isDateNotWithinMinutesAgo = (date, minutes) => {
  // Get the current timestamp in milliseconds
  const currentTimestamp = new Date().getTime()
  // Calculate the timestamp 'minutes' ago
  const minutesAgoTimestamp = currentTimestamp - (minutes * 60 * 1000);
  // Convert the input date to timestamp
  const inputDateTimestamp = new Date(date).getTime()
  // Check if the input date timestamp is not within the 'minutes' ago timestamp
  return inputDateTimestamp < minutesAgoTimestamp;
}


export const filterVehicleDataBasedOnVehicleCategory = (vehicleCategory, allDashboardVehicleData) => {
	if (vehicleCategory === 'Four Wheeler') {
		const vehicleCategoryDataBasedOnStatus = allDashboardVehicleData.filter((vehicle) => {
			return vehicle.type === '4 Wheeler';
		});

		return vehicleCategoryDataBasedOnStatus;
	} else if (vehicleCategory === 'Three Wheeler') {
		const vehicleCategoryDataBasedOnStatus = allDashboardVehicleData.filter((vehicle) => {
			return vehicle.type === '3 Wheeler';
		});
		return vehicleCategoryDataBasedOnStatus;
	} else if (vehicleCategory === 'Two Wheeler') {
		const vehicleCategoryDataBasedOnStatus = allDashboardVehicleData.filter((vehicle) => {
			return vehicle.type === '2 Wheeler';
		});
		return vehicleCategoryDataBasedOnStatus;
	}
};


export const filterVehicleDataBasedOnDataSource = (dataSource, allDashboardVehicleData) => {
	if (dataSource === 'TFT100') {
		const vehicleDataBasedOnStatusAndSoc = allDashboardVehicleData.filter((vehicle) => {
			return vehicle.canStatus && vehicle.canStatus.src === 'TFT100';
		});
		return vehicleDataBasedOnStatusAndSoc;
	} else {
		const vehicleDataBasedOnStatusAndSoc = allDashboardVehicleData.filter((vehicle) => {
			return vehicle.canStatus && vehicle.canStatus.src !== 'TFT100';
		});
		return vehicleDataBasedOnStatusAndSoc;
	}
};



export const   filterVehicleDataBasedOnSoc = (socRanges,allDashboardVehicleData) => {

  const onlyCommunicatingVehicles = allDashboardVehicleData;
  
  if (Array.isArray(socRanges) && socRanges.length > 0) {
    return onlyCommunicatingVehicles?.filter(fd => {
      return socRanges.some(range => {
        if (range === '0% - 25%') return fd.canStatus.soc <= 25;
        if (range === '25% - 50%') return fd.canStatus.soc <= 50 && fd.canStatus.soc > 25;
        if (range === '50% - 75%') return fd.canStatus.soc <= 75 && fd.canStatus.soc > 50;
        if (range === '75% - 100%') return fd.canStatus.soc <= 100 && fd.canStatus.soc > 75;
        return false;
      });
    });
  }
  return onlyCommunicatingVehicles;
};


export const filterVehicleDataBasedOnLocation = (vehicleData, location) => {
  return vehicleData.filter(vd => vd.loc === location);
};



export const filterVehicleDataBasedOnOemAndModel = (oemModel,allDashboardVehicleData) => {


  const vehicleDataBasedOnStatusAndSoc = allDashboardVehicleData.filter((vehicle) => {
    let oemModal = `${vehicle.canStatus.oem} ${vehicle.canStatus.mdl}`;
    return oemModal === oemModel;
  });

  return vehicleDataBasedOnStatusAndSoc;
};



export const getVehicleNosByGroupNames = (groupNames, groupsListData) => {
  const vehicleNos = [];

  // Loop through groupNames
  groupNames.forEach(groupName => {
      // Find the matching group in groupsListData
      const group = groupsListData.find(group => group.name === groupName);

      if (group) {
          // Add vehicles of the main group
          if (group.vehicles && group.vehicles.length) {
              vehicleNos.push(...group.vehicles);
          }

          // Loop through subgroups if they exist
          if (group.subGroup && group.subGroup.length) {
              group.subGroup.forEach(subGroup => {
                  // Check if the subgroup name matches any groupName in groupNames
                  if (groupNames.includes(subGroup.name)) {
                      if (subGroup.vehicles && subGroup.vehicles.length) {
                          vehicleNos.push(...subGroup.vehicles);
                      }
                  }
              });
          }
      } else {
          // Check for the case where the groupName might directly match a subgroup name
        
         groupsListData.forEach(group => {  
        
              if (group.subGroup && group.subGroup.length) {
              
                  const subGroup = group.subGroup.find(subGroup => subGroup.name === groupName);
                 
                  if (subGroup && subGroup.vehicles && subGroup.vehicles.length) {
                      vehicleNos.push(...subGroup.vehicles);
                  }
              }
          });
      }
  });
  return vehicleNos;  // Return the vehicle numbers array
};






