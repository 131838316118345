import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'
import { RAStatusCheckAPI, RaUserActionAPI } from "../../../store/actions/user";

import { BsFillPencilFill } from 'react-icons/bs'
import "./ra-actions.css";
import EditRARequisition from "./EditRARequisition";
import DeclineRARequisition from "./DeclineRARequisition";
import { UserRolesKeys } from "../../../utils/constants";
import { connect } from 'react-redux';

// helper validator functions
const validators = {
  isExist: value => {
    let isValid = 1;
    let message = "";
    if ([null, undefined, ""].includes(value)) {
      message = "This field is required";
      isValid = 0;
    }
    return { isValid, message }
  },
  isEmptyArray: value => {
    let { isValid, message } = validators.isExist(value);
    if (isValid && value instanceof Array && value.length === 0) {
      message = "This field is required";
      isValid = 0;
    }
    return { isValid, message }
  },
  isValidLength: (value, length) => {
    let { isValid, message } = validators.isExist(value);
    if (isValid && value.length <= length) {
      message = "This field is required";
      isValid = 0;
    }
    return { isValid, message }
  }
}

const actionStatusContent = {
  approve: {
    image: "/images/svgicon/promptSuccess.svg",
    message: "Access request approved"
  },
  reject: {
    image: "/images/svgicon/decline.svg",
    message: "Access request declined"
  },
  expired: {
    image: "/images/svgicon/broken_link_icon.svg",
    message: "Approval link expired"
  }
}

const RaActions = ({masterLocationData}) => {
  const { token } = useParams()
  const tokenPayload = useRef('')

  /* States */
  const [tokenData, setTokenData] = useState({});
  const [RaActionStatus, setRaActionStatus] = useState(null)

  // states for edit form
  const [editRARequisitionModal, setEditRARequisitionModal] = useState(false);
  const [isMultiLocation, setIsMultiLocation] = useState(true)
  const [editFormData, setEditFormData] = useState({
    userRole: "",
    location: "",
    comments: ""
  })
  const [editFormDataError, setEditFormDataError] = useState({
    userRole: "",
    location: "",
    comments: ""
  })

  // states for decline form
  const [declineRARequisitionModal, setDeclineRARequisitionModal] = useState(false);
  const [declineFormData, setDeclineFormData] = useState({
    remarks: "",
    comments: ""
  })
  const [declineFormDataError, setDeclineFormDataError] = useState({
    remarks: "",
    comments: ""
  })

  useEffect(() => {
    const validateAndGetTokenData = async () => {
      try {
        tokenPayload.current = JSON.parse(
          atob(token.split('.')[1])
        )

        const response = await RAStatusCheckAPI({ email: tokenPayload.current.email });
        if (response.status !== 200 || ["RA Rejected", "RA Approved"].includes(response.data?.data?.raStatus)) {
          setRaActionStatus("expired")
        }

        setTokenData(tokenPayload.current)
      } catch(err) {
        setRaActionStatus("expired")
      }
    }

    validateAndGetTokenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // handle approve CTA
  const handleApproveClick = async() => {
    const result = await RaUserActionAPI({ token, actionType: 'approve', userRole: [tokenData.userRole], location: tokenData.location, comments: tokenData.comments })
    if(result.status === 200) {
      setRaActionStatus("approve")
    }
  }

  // START: Edit modal helpers 
  const handleEditModalOpen = () => {
    setEditFormData({
      ...editFormData,
      userRole: tokenData.userRole,
      location: masterLocationData?.filter(location => tokenData.location.includes(location.label)),
    })
    setEditRARequisitionModal(true)
  }

  const handleEditModalClose = () => {
    resetEditFormData()
    setEditRARequisitionModal(false)
  }

  const resetEditFormData = () => {
    setEditFormData({
      userRole: "",
      location: "",
      comments: ""
    })
    setEditFormDataError({
      userRole: "",
      location: "",
      comments: ""
    })
  }

  const handleEditChange = ({ name, value, selectedValueText }) => {
    const editFormDataObj = JSON.parse(JSON.stringify(editFormData));

    editFormDataObj[name] = value;

    if (name === "userRole") {
      if (selectedValueText === UserRolesKeys.CITY_LEVEL_USER) {
        setIsMultiLocation(false);
        editFormDataObj.location = [];
      } else if (selectedValueText === UserRolesKeys.REGIONAL_LEVEL_USER) {
        setIsMultiLocation(true);
        editFormDataObj.location = [];
      } else {
        setIsMultiLocation(true);
        editFormDataObj.location = masterLocationData ? masterLocationData.map(x => { return {label : x.label, value: x.value}}) : [];
      }
    }

    setEditFormData({ ...editFormDataObj })
  }

  const handleEditSubmit = () => {
    if (!formValidations("edit")) {
      return
    }

    setTokenData({
      ...tokenData,
      userRole: editFormData.userRole,
      location: editFormData.location.map(l => l.label),
      comments: editFormData.comments
    })
    handleEditModalClose()
  }
  // END: Edit modal helpers 

  // START: Decline modal helpers 
  const handleDeclineModalOpen = () => {
    setDeclineRARequisitionModal(true)
  }

  const hanldeDeclineModalClose = () => {
    resetDeclineFormdata()
    setDeclineRARequisitionModal(false)
  }

  const resetDeclineFormdata = () => {
    setDeclineFormData({
      remarks: "",
      comments: ""
    })
    setDeclineFormDataError({
      remarks: "",
      comments: ""
    })
  }

  const handleDeclineChange = ({ name, value }) => {
    const declineFormDataObj = JSON.parse(JSON.stringify(declineFormData));
    const declineFormDataErrorObj = JSON.parse(JSON.stringify(declineFormDataError));

    declineFormDataObj[name] = value;

    if (name === "remarks") {
      declineFormDataObj.comments = "";
    }
    
    if (declineFormDataErrorObj[name] && declineFormDataObj[name]) {
      declineFormDataErrorObj[name] = ""
      setDeclineFormDataError({ ...declineFormDataErrorObj })
    }

    setDeclineFormData({ ...declineFormDataObj })
  }

  const handleDeclineSubmit = async() => {
    if (!formValidations("decline")) {
      return
    }

    const result = await RaUserActionAPI({
      token, actionType: 'reject',
      comments: declineFormData.remarks === "Other" ? declineFormData.comments : declineFormData.remarks
    })
    if(result.status === 200) {
      setRaActionStatus("reject")
    }
    hanldeDeclineModalClose()
  };
  // END: Decline modal helpers

  const formValidations = (formKey) => {
    if (formKey === "decline") {
      const declineFormDataErrorObj = JSON.parse(JSON.stringify(declineFormDataError))

      const remarksValidation = validators.isExist(declineFormData.remarks)
      declineFormDataErrorObj.remarks = remarksValidation.message

      let commentsValidation = { isValid: true, message: "" };
      if (declineFormData.remarks === "Other") {
        commentsValidation = validators.isValidLength(declineFormData.comments, 3)
        declineFormDataErrorObj.comments = commentsValidation.message
      }

      setDeclineFormDataError({ ...declineFormDataErrorObj });
      return (
        remarksValidation.isValid &&
        commentsValidation.isValid
      )
    }

    if (formKey === "edit") {
      const editFormDataErrorObj = JSON.parse(JSON.stringify(editFormDataError))

      const userRoleValidation = validators.isEmptyArray(editFormData.userRole)
      editFormDataErrorObj.userRole = userRoleValidation.message;

      const locationValidation = validators.isEmptyArray(editFormData.location)
      editFormDataErrorObj.location = locationValidation.message;

      setEditFormDataError({ ...editFormDataErrorObj });
      return (
        userRoleValidation.isValid &&
        locationValidation.isValid
      );
    }
  }

  return (
    <div className="main-container container-fluid text-center">
      <div className="row  d-flex flex-row justify-content-center">
        <div className="col-6">
          <div className="row ">
            <div className="col-12">
              <div className="image-container">
                <div className="row">
                  <div className="col-12">
                    <div className=" logo-container mb-4">
                      <img
                        alt="login-logo"
                        src="/images/logo/NmLogo.svg"
                        style={{ width: "180px", height: "20%", marginBottom:"50px" }} 
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ImageContainer">
                      <img
                        alt="loginimage"
                        src="/images/logo/NewLoginImage.svg"
                        className="login-background-image "
                        style={{ marginBottom:"30px" }} 
                      />
                    </div>
                    <img
                      src="/images/logo/poweredbymagentaa.svg"
                      alt="Magenta Logo"
                      style={{ width: "30%" }}
                    />
                  </div>
                </div>
              </div>   
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row h-100">
            <div className="heading">
              <h4 className="fw-bold">User Access Requisition</h4>
            </div>

            {RaActionStatus ? (
              <div className="expired-wrapper">
                <img className="expired-icon mb-4" src={actionStatusContent[RaActionStatus]?.image} alt={actionStatusContent[RaActionStatus]?.messgae} />
                <p className="fw-medium expired-message">{actionStatusContent[RaActionStatus]?.message}</p>
              </div>
            ) : (
              <>
                <div className="form-container-wrapper">
                  <div className="form-container">
                    <div className="row form-field">
                      <div className="col-md-2">
                        <div className="text-start fs-6 fw-bold form-label">
                          Name
                        </div>
                      </div>
                      <div className="col">
                        <div className="text-start form-value">
                          {tokenData?.name || ''}
                        </div>
                      </div>
                    </div>
                    <div className="row form-field">
                      <div className="col-md-2">
                        <div className="text-start fs-6 fw-bold form-label">
                          Email
                        </div>
                      </div>
                      <div className="col">
                        <div className="text-start form-value">
                          {tokenData?.email || ''}
                        </div>
                      </div>
                    </div>
                    <div className="row form-field">
                      <div className="col-md-2">
                        <div className="text-start fs-6 fw-bold form-label">
                          User Role
                        </div>
                      </div>
                      <div className="col">
                        <div className="text-start form-value">
                          {tokenData?.roleName && tokenData?.roleName.length > 0  ? tokenData?.roleName.join(',') : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row form-field">
                      <div className="col-md-2">
                        <div className="text-start fs-6 fw-bold form-label">
                          Location
                        </div>
                      </div>
                      <div className="col">
                        <div className="text-start form-value">
                          {tokenData?.location?.join(", ") || ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="edit d-flex justify-content-start align-item-center" onClick={() => handleEditModalOpen()}>
                        <span>
                          <BsFillPencilFill />
                        </span>
                        <p>Edit</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="action-button-container d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="approve-btn"
                        onClick={handleApproveClick}
                      >Approve</button>
                      <button
                        type="button"
                        className="decline-btn"
                        onClick={handleDeclineModalOpen}
                      >Decline</button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <EditRARequisition
        show={editRARequisitionModal}
        onHide={handleEditModalClose}
        onSubmit={handleEditSubmit}
        onEditChangeHandler={handleEditChange}
        editFormData={editFormData}
        editFormDataError={editFormDataError}
        isMultiLocation={isMultiLocation}
      />
      <DeclineRARequisition
        show={declineRARequisitionModal}
        onHide={hanldeDeclineModalClose}
        onSubmit={handleDeclineSubmit}
        onDeclineChangeHandler={handleDeclineChange}
        declineFormData={declineFormData}
        declineFormDataError={declineFormDataError}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
	masterLocationData: state.getAllUserRoleReducer.masterLocationData
})

export default connect(mapStateToProps)(RaActions)