// External packages import
import React, { useState } from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types'

// Internal components import
import Modal from '../../shared/modals/BaseModal/BaseModal';
import BasicInput from '../../shared/form/Input/Input';
import Button from '../../shared/form/Button/Button';
import Select from '../../shared/form/Select/Select';
import validationService from '../../../service/validationService';
import InformativeModal, { VARIANTS } from '../../shared/modals/InformativeModal/InformativeModel';

// Services Utils and Store import
import { GetDepartmentName, GetDuLocation, createDepartmentUser } from '../../../store/actions/SA_Signup/SA_Signup';

// Style import
import styles from './DuSignup.module.css';


const DuSignUp = ({ email, isOpen, onClose, onSubmit , duDepartmentList, GetDuLocation, duLocationList, GetDepartmentName}) => {

    const [formData, setFormData] = useState({
        name: '',
        customerCode: '',
        departmentName: [],
        location: []
    })

    const [formDataError, setFormDataError] = useState({
        name: '',
        customerCode: '',
        departmentName: '',
        location: ''
    })
    const [informativeModalData, setInformativeModalData] = useState({
        message: '',
        isOpen: false,
        variant: VARIANTS.SUCCESS,
        action: '',
    })

    const fetchData = async () => {
        await GetDepartmentName({
            ci: formData.customerCode,
            searchText: ""
        })
        await GetDuLocation({ ci: formData.customerCode })
    };
 
    const handleDepartmentUserSave = async (payload) => {
        try {
            let response
            const requestData = {
                name: payload.name,
                email,
                ci: payload.customerCode,
                departmentId: payload.departmentName.value,
                locationId: payload.location.value,
            }

            response = await createDepartmentUser(requestData)


            if (response?.data?.status === 200) {
                setInformativeModalData({
                    message: 'Successfully Submitted',
                    isOpen: true,
                    variant: VARIANTS.SUCCESS,

                })
                onSubmit()
                return
            }
        } catch (error) {
            console.log('handleDepartmentSave failed', error)
            setInformativeModalData({
                message: 'Something went wrong!!!',
                isOpen: true,
                variant: VARIANTS.ERROR,
            })
        }
    }
  
    const handleOnChange = ({ name = '', value = '' }) => {
        formData[name] = value
        setFormData({ ...formData })
        if (name === 'name') {
            const nameValidation = /^[a-zA-Z\s]*$/;
            if (!nameValidation.test(value)) {
              const errorMessage = 'Name can only be alphabets';
                setFormDataError({ ...formDataError, name: errorMessage })
                return
                }
            setFormDataError({ ...formDataError, name: '' })
    }
}
    const handleClose = () => {
        setFormData({
            name: '',
            customerCode: '',
            departmentName: '',
            location: ''
        })
        setFormDataError({
            name: '',
            customerCode: '',
            departmentName: '',
            location: ''
        })
        onClose()
    }

    const handleValidation = () => {
        let errorCount = 0;

        const name = validationService.required(formData.name);
        if (!name.isValid) {
            errorCount++
        }
        formDataError['name'] = name.message
        
        const customerCode = validationService.required(formData.customerCode);
        if (!customerCode.isValid) {
            errorCount++
        }
        formDataError['customerCode'] = customerCode.message


        const departmentName = validationService.required(formData.departmentName)
        if (!departmentName.isValid) {
            errorCount++
        }
        formDataError['departmentName'] = departmentName.message

        const location = validationService.required(formData.location)
        if (!location.isValid) {
            errorCount++
        }
        formDataError['location'] = location.message



        setFormDataError(prevState => ({ ...prevState, formDataError }))
        return errorCount
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation() > 0) {
            return;
        }
        handleDepartmentUserSave(formData)   
    }

    const DepartmentNameOptions = duDepartmentList?.map(d => ({ label: d.name, value: d._id }))
    const DepartmentLocation = duLocationList?.map(d => ({ label: d.city, value: d._id }))
    return (
        <>
            <Modal
                isOpen={isOpen}
                title={'Sign Up'}
                onClose={handleClose}
            >
                <div>
                    <BasicInput
                        label='Name'
                        value={formData.name}
                        onChange={(e) => handleOnChange({ name: 'name', value: e.target.value })}
                        error={formDataError.name}
                        placeholder='Enter First and Last name'
                        required={true}
                    />
                    <BasicInput
                        label='Email ID'
                        value={email}
                        error={formDataError.email}
                        placeholder='Enter Email ID'
                        required={true}
                        readOnly={true}
                        type='email'
                    />
                    {
                        <BasicInput
                        label='Customer Code'
                        value={formData.customerCode}
                        onChange={(e) => handleOnChange({ name: 'customerCode', value: e.target.value })}
                        error={formDataError.customerCode}
                        placeholder='Enter the Customer Code'
                        required={true}
                        onBlur={() => {
                            if (formData.customerCode) { 
                                fetchData();
                            }
                        }}
                    />
                    }
                    <Select
                        label={'Department Name'}
                        options={DepartmentNameOptions}
                        value={formData.departmentName}
                        error={formDataError.departmentName}
                        onChange={(data) => handleOnChange({ name: 'departmentName', value: data })}
                        placeholder='Select Department'
                        required={true}
                        isMultiSelect={false}
                        controlStyle={{ width: '410px' }}
                        isDepart={true}

                    />
                    <Select
                        label='Location'
                        options={DepartmentLocation}
                        value={formData.location}
                        error={formDataError.location}
                        onChange={(data) => handleOnChange({ name: 'location', value: data })}
                        placeholder='Select Location'
                        required={true}
                        isMultiSelect={false}
                        controlStyle={{ width: '410px' }}
                        isDusignUp={true}
                    />
                    <Button type='submit' className={styles.submitButton} onClick={handleSubmit} >Submit</Button>
                </div>
            </Modal>

            <InformativeModal
                isOpen={informativeModalData.isOpen}
                message={informativeModalData.message}
                variant={informativeModalData.variant}
                onClose={() => {
                  setInformativeModalData({
                    ...informativeModalData,
                    isOpen: false
                  })  
                }}
            />
        </>
    )
}



DuSignUp.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
}


const mapStateToProps = (state) => ({
    duDepartmentList: state.SA_Signup.duDepartmentList,
    duLocationList: state.SA_Signup.duLocationList,
});

export default connect(mapStateToProps, {
    GetDuLocation,
    GetDepartmentName
})(DuSignUp);
/*conflict-resolution-will be removed later*/