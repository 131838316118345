import ActionTypes from "../actionTypes";
const {GET_ALL_MASTER_TYPES, GET_ALL_MASTER_TYPES_EXCEPTION,
     CREATE_MASTER_TYPE_EXCEPTION, CREATE_MASTER_TYPE_SUCCESS, 
     GET_MASTER_TYPE_BY_ID_SUCCESS, GET_MASTER_TYPE_BY_ID_EXCEPTION,
     UPDATE_MASTER_TYPE_SUCCESS, UPDATE_MASTER_TYPE_EXCEPTION
    } = ActionTypes.MASTERS

const initialState = {
    masters: [],
    masterTypeCreated: false,
    masterTypeDetails:{},
    updateSuccessful: false
}

const masters = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case GET_ALL_MASTER_TYPES:
        return {
            ...state,
            masters: payload
        }
        case GET_ALL_MASTER_TYPES_EXCEPTION:
            return {
                ...state,
                masters: []
            }
        case CREATE_MASTER_TYPE_SUCCESS:
            return {
                ...state,
                masterTypeCreated: true
            }
        case CREATE_MASTER_TYPE_EXCEPTION:
            return {
                ...state,
                masterTypeCreated: false
            }
        case GET_MASTER_TYPE_BY_ID_SUCCESS:
            return {
                ...state,
                masterTypeDetails: payload
            }
        case GET_MASTER_TYPE_BY_ID_EXCEPTION:
            return {
                ...state,
                masterTypeDetails:{}
            }
        case UPDATE_MASTER_TYPE_SUCCESS:
            return {
                ...state,
                updateSuccessful: true
            }
        case UPDATE_MASTER_TYPE_EXCEPTION:
            return {
                ...state,
                updateSuccessful: false
            }

        default:
            return state
    }
}

 export default masters