import { useNavigate} from "react-router-dom"
import { Button } from 'react-bootstrap'

const Missing = () => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    return (
        <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
            <h1>Oops!</h1>
            <p>Page Not Found</p>
            <div className="flexGrow">
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    )
}

export default Missing
