import React, { useState, useCallback, useEffect } from 'react'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  GetAllOnboardingVehicles,
  BulkVehicleOnboardedUpdate,
  DeleteOnboardedVehicleById
} from '../../../../../store/actions/vehicleOnboarding'
import { Table, Form } from 'react-bootstrap'
import Loader from '../../../../helpers/hoc/loader/Loader'
import './UpdateOnboardingModal.css'
import { BsBookmark, BsTrash } from 'react-icons/bs'
import Pagination from '../../../../helpers/hoc/paginator/Pagination'
import {
  VehicleTypes,
  VehicleOemAndModels,
} from '../../../../../utils/constants'
import { countFormData } from '../../../../../utils/validation/form-validator'
import { FaSearch } from 'react-icons/fa'
import {GetUserRoleList } from "../../../../../store/actions/roleAndMenuMapping";
import { addEscapeKeyListener } from '../../../../../utils/common-methods'
import { ChargingTypes } from '../../../../../utils/constants'
const UpdateOnboardingModal = ({
  // actions
  GetAllOnboardingVehicles,
  BulkVehicleOnboardedUpdate,
  DeleteOnboardedVehicleById,
  GetUserRoleList,

  //comp. props
  onboardingId,
  showModal,
  setIsShowModal,

  //state props
  onBoardingVehiclesCount,
  onBoardingVehiclesData,
  vehicleOnboardingApiError,
  isLoading,
  masterLocationData

}) => {
  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, [setIsShowModal]);
  const [currentPage, setCurrentPage] = useState(1)
  const [allSelected, setAllSelected] = useState(false)
  const [vehicles, setVehicles] = useState(onBoardingVehiclesData)
  // eslint-disable-next-line
  const [searchKey, setSearchKey] = useState("")

  const ITEMS_PER_PAGE = 5
  useEffect(() => {
    if (showModal && onboardingId)
      GetAllOnboardingVehicles({
        onboardingId
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetAllOnboardingVehicles, onboardingId])
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const handlePageChange = (page) => {
      setCurrentPage(page);
    }

    const handleCheckboxChange = (id) => {
      setVehicles((prevData) =>
      prevData.map((item) =>
        item._id === id ? 
        { ...item, selected: !item.selected}
        :
        item
        )
      )
    }


  useEffect(() => {
    setAllSelected(false)
    setVehicles(
      onBoardingVehiclesData?.map((Obj) => ({
        ...Obj,
        selected: false,
        purchaseDate: moment(Obj.purchaseDate).format('YYYY-MM-DD'),
      }))
    )
  }, [onBoardingVehiclesData])

  useEffect(() => {
    GetUserRoleList();
   }, [GetUserRoleList]);

  const initialFieldErrors = {
    empty: false,
  }
  const [errorFields, setErrorFields] = useState(initialFieldErrors)
    
  const onChangeValueHandler = (id, value, key) => {    
    setVehicles((prevData) =>
     prevData.map((item) =>
      item._id === id ? 
      { ...item, [key]: value}
      :
      item
      )
    )
  }

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
            handleClose();
    });
  
    return () => {
      removeListener();
    };
  }, [handleClose]);
  
  const onChangeType = useCallback(
    (_id, type) => {
      setVehicles((prevData) => {
        const updatedVehicles = prevData.map((vehicle) =>
          vehicle._id === _id
            ? {
                ...vehicle,
                type,
                oem: null,
                model: null,
              }
            : vehicle
        );
  
        let newErrorFields = { ...errorFields };
        if (typeof newErrorFields[_id] === 'undefined') {
          newErrorFields[_id] = {};
        }
        newErrorFields[_id].oemAndModel = 'Required';
  
        setErrorFields({ ...newErrorFields });
  
        return updatedVehicles;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicles, errorFields]
  );
  
  const onChangeOEMModel = useCallback(
    (_id, id) => {
      setVehicles((prevVehicles) => {
        const updatedVehicles = prevVehicles.map((vehicle) => {
          if (vehicle._id === _id) {
            const foundOEMModel = VehicleOemAndModels.find((i) => i.id === id);
            if (foundOEMModel) {
              // Update the vehicle properties
              vehicle.type = foundOEMModel.typeId;
              vehicle.oem = foundOEMModel.oem;
              vehicle.model = foundOEMModel.model;
  
              // Update the errorFields object
              const newErrorFields = { ...errorFields };
              if (
                newErrorFields &&
                newErrorFields[_id] &&
                newErrorFields[_id].oemAndModel
              ) {
                delete newErrorFields[_id].oemAndModel;
              }
              setErrorFields({ ...newErrorFields });
            }
          }
          return vehicle;
        });
  
        return updatedVehicles;
      });
    },
    [errorFields]
  );
  const toggleSelectAll = useCallback(() => {
    let selectedCount = vehicles.filter((i) => i.selected).length
    if (selectedCount >= 0 && selectedCount < vehicles.length) {
      setVehicles(
        vehicles.map((i) => {
          return { ...i, selected: true }
        })
      )
      setAllSelected(true)
    } else {
      setVehicles(
        vehicles.map((i) => {
          return { ...i, selected: false }
        })
      )
      setAllSelected(false)
    }
  }, [vehicles])

  const isValidForm = useCallback(
    (form) => {
      // fill up all the fields
      if (!(countFormData(form) === Object.keys(form).length)) {
        setErrorFields({
          ...errorFields,
          empty: true,
        })
        setTimeout(() => {
          setErrorFields({
            ...errorFields,
            empty: false,
          })
        }, 2000)
        return false
      }

      if (
        countFormData(form) === Object.keys(form).length &&
        countFormData(errorFields) === 0
      ) {
        return true
      }
    },
    [errorFields]
  )

  const isEditDataValid = (data) => {
    let isValid = true
    data.forEach((item) => {
      if (isValid && !isValidForm(item)) {
        isValid = false
      }
    })
    return isValid
  }

  const handleSaveVehicleOnboard = useCallback(() => {
    let selectedVehicles = vehicles
      .filter((v) => v.selected)
      .map((v) => {
        delete v.__v
        delete v.selected
        return { ...v, oemAndModel: `${v.oem} ${v.model}` }
      })
    if (selectedVehicles.length > 0 && isEditDataValid(selectedVehicles)) {
      BulkVehicleOnboardedUpdate({
        onboardingId,
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        data: { data: selectedVehicles },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, onboardingId])

  const handleDeleteOnboardedVehicles = useCallback(() => {
    let selectedVehicleIds = vehicles
      .filter((v) => v.selected).map((val) => val._id)
    if (selectedVehicleIds.length > 0) {
      DeleteOnboardedVehicleById({
        onboardingId,
        vehicleIds: selectedVehicleIds,
        pageIndex: currentPage - 1,
        pageSize: ITEMS_PER_PAGE
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, onboardingId])

  const totalFilteredPages = Math.ceil(
    vehicles?.filter((e) => {
      const vehicleDetails =
        `${e.registration_number} ${e.oem} ${e.model} ${e.chassis_number} ${e.motor_number}`.toLowerCase();
      return vehicleDetails.includes(searchKey.toLowerCase());
    }).length / ITEMS_PER_PAGE
  )

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="vehicle-onboard-modal-body mt-4">
        <div className="header-container d-flex justify-content-between">
          <div className="title w-100 mr-2">
          <div className="row">
          <div className="col-3 d-inline-flex align-items-center"><h5 style={{fontSize:'18px', fontWeight:'600'}}>Vehicle Onboarded</h5></div>
            <div className="col-6">
                <div style={{
                  marginBottom:"1rem"
                }}
                className={`d-inline-flex align-items-center justify-content-center gap-6 ${!searchKey? 'no-value-selected-input ' : ''}`}
                >
                  <div 
                  className="search-bar-div search-input"
                
                  >
                    <FaSearch className="vob-search-icon" />
                    <input
                      onChange={(e) => {
                        setCurrentPage(1)
                        setSearchKey(e.target.value)
                      }}
                      className="vob-search-input search-input"
                      placeholder="Search"
                      data-testid='update-onboarding-search-input'
                    />
                  </div>
              </div>
              </div>
                </div>
            <div className="d-flex flex-row align-item-center justify-content-start">
             
            </div>
          </div>
          <div className="download-btn">
            <div className="d-flex">
              <button
                className="dwn-btn mx-3"
                onClick={() => handleSaveVehicleOnboard()}
                data-testid='save-vehicle-button'
              >
                <BsBookmark style={{width:'auto',height:'20px'}}/>
              </button>

              <button
                className="dwn-btn"
                onClick={() => {
                  handleDeleteOnboardedVehicles()
                }}
                data-testid='delete-vehicle-button'
              >
                <BsTrash style={{width:'auto',height:'20px'}}/>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white mb-1 position-relative vehicles-onboarding-tc">
          <div className="table-wrapper">
            <Table responsive borderless className="mt-4" id="vehicle-onboarded-model">
              <thead className="table-header">
                <th scope="col">
                  <input
                    checked={allSelected}
                    type="checkbox"
                    className="form-check-input"
                    style={{
                      backgroundColor: allSelected ? '#1f2a5d' : '#FFFFFF',
                      marginTop:"-8px",
                      marginLeft:'10px'

                    }}
                    id="mastercheck"
                    onChange={(e) => toggleSelectAll(e)}
                    data-testid='select-checkbox-all'
                  />
                </th>
                <th className="text-nowrap mw-120"><p className='fw500 vehicle-onboarded-th'>Vehicle No</p></th>
                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>OEM & Model</p></th>
                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>Chassis No</p></th>
                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>Motor No</p></th>
                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>Date of  Purchase</p></th>
                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>Vehicle Type</p></th>
                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>Charging Type</p></th>

                <th className="text-nowrap"><p className='fw500 vehicle-onboarded-th'>Location</p></th>
               
              </thead>
              <tbody>
                {vehicles
                ?.filter((e) => {
                  const vehicleDetails =
                    `${e.registration_number} ${e.oem} ${e.model} ${e.chassis_number} ${e.motor_number}`.toLowerCase();
                  return vehicleDetails.includes(searchKey.toLowerCase());
                })?.slice(startIndex, endIndex)
                ?.map((Obj, index) => (
                  <tr
                    key={'row-' + index.toString()}
                    className={Obj.selected ? 'active' : ''}
                  >
                    <td>
                      <input
                        data-testid={`select-checkbox-${Obj?.registration_number}`}
                        type="checkbox"
                        className="form-check-input"
                        style={{
                          backgroundColor: Obj.selected ? '#1f2a5d' : '#FFFFFF',
                          borderColor: Obj.selected ? '#1f2a5d' :'#707070',
                          height: '15px',
                          width: '15px',
                          marginLeft:"10px"
                        }}
                        id={Obj._id}
                        checked={Obj.selected}
                        onChange={(e) => 
                          handleCheckboxChange(Obj?._id)
                        }
                      />
                    </td>
                    <td>
                      <input
                      style={{width: '115px'}}
                        type="text"
                        data-testid={`resgistration-input-${Obj?.registration_number}`}
                        id={Obj._id}
                        name={'registrationNumber' + index}
                        className={`form-control inputfields ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].registration_number
                            ? 'is-invalid'
                            : ''
                        }`}
                        maxLength={11}
                        value={Obj.registration_number}
                        onChange={(e) =>
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'registration_number'
                          )
                        }
                        onBlur={(e) =>
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'registration_number'
                          )
                        }
                        disabled={!Obj.selected}
                      />
                    </td>
                    <td style={{width:'180px',}}>
                      <Form.Select
                        size="sm"
                        id={Obj._id}
                        name={'oemAndModel' + index}
                        value={
                          Obj.oem && Obj.model
                            ? `${Obj.oem + ' ' + Obj.model}`
                            : ''
                        }
                        onChange={(e) =>
                          onChangeOEMModel(Obj?._id, e.target.value)
                        }
                        className={`vehicle_onboard_select form-control ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].oemAndModel
                            ? 'is-invalid'
                            : ''
                        }`}
                        disabled={!Obj.selected}
                        data-testid={`oem-model-select-${Obj?.registration_number}`}
                      >
                        <option key="oem01" value=""></option>
                        {Obj.type
                          ? VehicleOemAndModels?.filter(
                              (val) => val.typeId === Obj.type
                            )?.map((option) => (
                              <option key={option.id} value={option.id} data-testid={`oem-model-option-${Obj?.registration_number}-${option.label}`}>
                                {option.label}
                              </option>
                            ))
                          : VehicleOemAndModels?.map((option) => (
                              <option key={option.id} value={option.id} data-testid={`oem-model-option-${Obj?.registration_number}-${option.label}`}>
                                {option.label}
                              </option>
                            ))}
                      </Form.Select>
                    </td>
                    <td>
                      <input
                      style={{   width: '181px'}}
                        type="text"
                        id={Obj._id}
                        name={'chassisNumber' + index}
                        className={`form-control inputfields ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].chassis_number
                            ? 'is-invalid'
                            : ''
                        }`}
                        maxLength={17}
                        value={Obj.chassis_number}
                        onChange={(e) =>
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'chassis_number'
                          )
                        }
                        onBlur={(e) =>
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'chassis_number'
                          )
                        }
                        disabled={!Obj.selected}
                        data-testid={`chassis-input-${Obj?.registration_number}`}
                      />
                    </td>
               
                    <td style={{width:'170px'}}>
                      <input
                        type="text"
                        id={Obj._id}
                        name={'motorNumber' + index}
                        className={`form-control inputfields ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].motor_number
                            ? 'is-invalid'
                            : ''
                        }`}
                        maxLength={21}
                        value={Obj.motor_number}
                        onChange={(e) =>
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'motor_number'
                          )
                        }
                        onBlur={(e) =>
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'motor_number'
                          )
                        }
                        disabled={!Obj.selected}
                        data-testid={`motor-input-${Obj?.registration_number}`}
                      />
                    </td>

                    <td  style={{  marginRight: '28px'}}>
                      <input
                      style={{width: '115px',
                        display: 'flex',
                        float: 'right',
                        textAlign:'center'
                      }}
                        type="date"
                        id={'purchaseDate' + index}
                        placeholder=""
                        name="purchaseDate"
                        className={`vehicle_onboard_select form-control ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].purchaseDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={Obj.purchaseDate}
                        onChange={(e) => {
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'purchaseDate'
                          )
                        }}
                        disabled={!Obj.selected}
                        data-testid={`purchase-date-input-${Obj?.registration_number}`}
                      />
                    </td>
                    <td  style={{  width: '135px'}}>
                      <Form.Select
                        size="sm"
                        id={Obj._id}
                        name={'type' + index}
                        className={`vehicle_onboard_select form-control ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].type
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={Obj.type}
                        onChange={(e) => onChangeType(Obj?._id, e.target.value)}
                        disabled={!Obj.selected}
                        data-testid={`type-select-${Obj?.registration_number}`}
                      >
                        {VehicleTypes?.map((item) => (
                          <option value={item.id} data-testid={`type-option-${Obj?.registration_number}-${item.label}`}>{item.label}</option>
                        ))}
                      </Form.Select>
                    </td>
                
                    <td>
                      <Form.Select
                      style={{ width: '118px' }}
                      size="sm"
                      id={Obj._id}
                      name={'chargingtype' + index}
                      value={Obj.charging_type}
                      className={`vehicle_onboard_select form-control ${
                        errorFields[`${Obj?._id}`] &&
                        errorFields[`${Obj?._id}`].charging_type
                          ? 'is-invalid'
                          : ''
                      }`}
                      onChange={(e) => {
                        onChangeValueHandler(
                          Obj?._id,
                          e.target.value,
                          'charging_type'
                        )
                      }}
                      disabled={!Obj.selected}
                      data-testid={`chargingtype-select-${Obj?.registration_number}`}
                    >
                      {ChargingTypes?.map((item) => (
                        <option value={item.id} data-testid={`chargingtype-option-${Obj?.registration_number}-${item.label}`}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                    </td>

                    
                    <td className='location_data_row'>
                      <Form.Select
                          style={{width: '118px'}}
                        size="sm"
                        id={Obj._id}
                        name={'location' + index}
                        value={Obj.location}
                        className={`vehicle_onboard_select form-control ${
                          errorFields[`${Obj?._id}`] &&
                          errorFields[`${Obj?._id}`].location
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={(e) => {
                          onChangeValueHandler(
                            Obj?._id,
                            e.target.value,
                            'location'
                          )
                        }}
                        disabled={!Obj.selected}
                        data-testid={`location-select-${Obj?.registration_number}`}
                      >
                        {masterLocationData?.map((item) => (
                          <option value={item.id} data-testid={`location-option-${Obj?.registration_number}-${item.label}`}>{item.label}</option>
                        ))}
                      </Form.Select>
                    </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {vehicleOnboardingApiError && (
            <div className="error-msg d-flex justify-content-center">
              <label className="text-danger error-message text-wrap">
                {vehicleOnboardingApiError}
              </label>
            </div>
          )}
          <Pagination
          disabled={false}
          totalPages={totalFilteredPages}
          currentPage={currentPage}
          setPage={handlePageChange}
          data-testid='upload-onboarding-pagination'
        />
          <Loader isLoading={isLoading} />
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

UpdateOnboardingModal.propTypes = {  
  isUploadApiSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  vehicleOnboardingApiError: state.vehOnboarding.vehicleOnboardingApiError,
  isUploadApiSuccess: state.vehOnboarding.isUploadApiSuccess,
  onBoardingVehiclesData: state.vehOnboarding.onBoardingVehiclesData,
  onBoardingVehiclesCount: state.vehOnboarding.onBoardingVehiclesCount,
  isLoading: state.loader.isLoading,
  masterLocationData: state.getAllUserRoleReducer.masterLocationData,
})

export default connect(mapStateToProps, {
  GetAllOnboardingVehicles,
  BulkVehicleOnboardedUpdate,
  DeleteOnboardedVehicleById,
  GetUserRoleList
})(UpdateOnboardingModal)