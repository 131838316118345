import React from 'react'
import { VehicleTrackingController } from '../../../../utils/constants';
import './VTController.css'

const VTController = (
  props
) => {

  const handleControllerClick = (controllerAction) => {
    props.onControllerClick(controllerAction);
  }

  return (
    <div className='vt_controller__box'>
      <div className='vt__play-pause-control'>
        {
          (props.controllerConfig.pause || (!props.controllerConfig.play && !props.controllerConfig.pause)) && <button className='vt_controller_buttons' title='Play' data-testid="vt-control-button-PLAY" onClick={() => {
            handleControllerClick(VehicleTrackingController.PLAY)
          }}>
            <img className='vt_controller__image' src="/images/svgicon/play-button.svg" alt="play"></img>
          </button>
        }
        {
          props.controllerConfig.play && <button className='vt_controller_buttons' title='Pause' data-testid="vt-control-button-PAUSE" onClick={() => {
            handleControllerClick(VehicleTrackingController.PAUSE)
          }}>
            <img className='vt_controller__image' src="/images/svgicon/pause-button.svg" alt="pause"></img>
          </button>
        }
        <button className='vt_controller_buttons' title='Reset' data-testid="vt-control-button-STOP" onClick={() => {
          handleControllerClick(VehicleTrackingController.STOP)
        }}>
          <img className='vt_controller__image_reset' src="/images/svgicon/reset.svg" alt="reset"></img>
        </button>
      </div>
      <div className='vt__speed-control'>
        <button className='vt_controller_buttons' title='Decrease Speed' data-testid="vt-control-button-DECREASE_SPEED" onClick={() => {
          handleControllerClick(VehicleTrackingController.BACKWARD)
        }}
          disabled={props.controllerConfig.speed === props.controllerSpeedConfig[0]}
        >
          <img className={props.controllerConfig.speed === props.controllerSpeedConfig[0] ? 'vt_controller__image__disabled' : 'vt_controller__image'} src="/images/svgicon/back-for-button.svg" alt="backward"></img>
        </button>
        <div className='vt_controller_showspeed'>{props.speed}X</div>
        <button className='vt_controller_buttons' title='Increase Speed' data-testid="vt-control-button-INCREASE_SPEED" onClick={() => {
          handleControllerClick(VehicleTrackingController.FORWARD)
        }}
          disabled={props.controllerConfig.speed === props.controllerSpeedConfig[props.controllerSpeedConfig.length - 1]}
        >
          <img className={props.controllerConfig.speed === props.controllerSpeedConfig[props.controllerSpeedConfig.length - 1] ? 'vt_controller__image_rt_180_diabled':'vt_controller__image_rt_180'} src="/images/svgicon/back-for-button.svg" alt="forward"></img>
        </button>
      </div>
    </div>
  )
}

export default VTController