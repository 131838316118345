import { Container } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "./RoleAndMenuMapping.css";
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { connect, useSelector } from "react-redux";
import {
  GetUserRoleList,
  getUserMenuByUserRoleId,
  updateMenuMapping,
} from "../../../../store/actions/roleAndMenuMapping";
import Select from "../../../helpers/hoc/select/Select";
import { ProtectedMenus } from "../../../../utils/constants";
import Loader from "../../../helpers/hoc/loader/Loader";

const UserRoleAndMenuMapping = ({
  GetUserRoleList,
  getUserMenuByUserRoleId,
  roleMenuData,
  masterRoleData,
  updateMenuMapping,
}) => {
  const [selectedUserMenuData, setSelectedUserMenuData] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState([]);
  const isLoading = useSelector((s) => s?.loader?.isLoading)
  useEffect(() => {
    setSelectedUserMenuData(roleMenuData);
  }, [roleMenuData]);

  useEffect(() => {
    GetUserRoleList();
    setSelectedUserMenuData([])
    // eslint-disable-next-line
  }, []);

  const fetchMenuByUserRoleId = useCallback(
    (userRoleId) => {
      if (userRoleId) {
        setSelectedUserMenuData([]);
        getUserMenuByUserRoleId(userRoleId);
      }
    },
    [getUserMenuByUserRoleId]
  );

  useEffect(() => {
    if (selectedUserRole && selectedUserRole[0]?._id) {
      fetchMenuByUserRoleId(selectedUserRole[0]._id);
    }
  }, [fetchMenuByUserRoleId, selectedUserRole]);

  const onChangeSelectUserMenuHandler = (value) => {
    setSelectedUserMenuData(value);
  };

  const onChangeUserRoleHandler = (selected) => {
    setSelectedUserRole(selected);
    setSelectedUserMenuData([])
  };
  const handleUpdateUserMenuMapping = useCallback(() => {
    if (selectedUserMenuData.length > 0 && selectedUserRole.length > 0) {
      updateMenuMapping({
        role: selectedUserRole[0]._id,
        menu: selectedUserMenuData,
      });
    }
  }, [updateMenuMapping, selectedUserRole, selectedUserMenuData]);

  return (
    <Container fluid>
      <div className="col-3 d-inline-flex align-items-center">
        <h5>Role And Menu</h5>
      </div>
      <div className="d-flex flex-row align-item-center justify-content-start">
        <hr className="hr-line-blue hr-line-blue-vobView-width"></hr>
        <hr className="hr-line"></hr>
      </div>
      <div className="d-flex flex-row justify-content-start ">
        <div className="row mt-3">
          <div className="col-12 form-label-group required">
            <Select
              name="userRole"
              id="userRole"
              options={masterRoleData?.filter(item => item.value !== 'ADMIN')}
              selectedOptions={selectedUserRole}
              setSelectedOptions={setSelectedUserRole}
              placeholder="Select Role"
              isMultiSelect={false}
              className="form-control"
              style={{ width: "250px" }}
              value={selectedUserRole}
              onChange={onChangeUserRoleHandler}
            />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="custom-dual-listbox mb-2">
          <div className="d-flex col-12 content-dule-listbox ">
            <div
              className=" col-6 side-heading mt-2 mb-1"
              style={{
                fontWeight: "500",
              }}
            >
              Menu
            </div>
            <div
              className=" col-6 side-heading mt-2 mb-1"
              style={{
                fontWeight: "500",
              }}
            >
              Assigned
            </div>
          </div>
          <DualListBox
            canFilter
            options={ProtectedMenus.filter(x => !x.isPublic && !x?.isAdminOnlyMenu).map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            selected={selectedUserMenuData}
            onChange={onChangeSelectUserMenuHandler}
            className="custom-dual-listbox"
            icons={{
              moveLeft: <FaAngleLeft />,
              moveAllLeft: <FaAngleDoubleLeft />,
              moveRight: <FaAngleRight />,
              moveAllRight: <FaAngleDoubleRight />,
            }}
          />

          <div className="d-flex mt-5 mb-2 justify-content-center">
            <button
              className="btn btn-primary rolesubmit "
              type="submit"
              style={{ fontSize: "14px", fontWeight: "400 !important" }}
              onClick={() => handleUpdateUserMenuMapping()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      { isLoading &&
        (<div className = 'rm__loader'>
          <Loader isLoading={isLoading}  />
        </div>)
      }
    </Container>
  );
};

const mapStateToProps = (state) => ({
  allUserRoleData: state.getAllUserRoleReducer.allUserRoleData,
  roleMenuData: state.getAllUserRoleReducer.roleMenuData,
  masterRoleData: state.getAllUserRoleReducer.masterRoleData,
});

export default connect(mapStateToProps, {
  GetUserRoleList,
  getUserMenuByUserRoleId,
  updateMenuMapping,
})(UserRoleAndMenuMapping);
