import API from "../../../service/api";
import API_BOOK from "../../../service/endpoints";
import ActionTypes from "../../actionTypes";
const {
    DA_FLOW: { 
        GET_ALL_DEPARTMENT_ADMIN_USERS_SUCCESS,
        GET_ALL_DEPARTMENT_ADMIN_USERS_FAILED,
        GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_SUCCESS,
        GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_FAILED,
        GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_SUCCESS,
        GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_FAILED,
        GET_PENDING_USERS_SUCCESS,
        GET_PENDING_USERS_FAILED,
     }
  } = ActionTypes;

const { DEPARTMENT_ADMIN: 
  { GET_ALL_DEPART_ADMIN_USERS, 
    GET_ALL_DEPART_ADMIN_USERS_ACTIVE_COUNT, 
    GET_ALL_DEPART_ADMIN_USERS_DEACTIVATED_COUNT, 
    PENDING_USERS, 
    APPROVE_USER_REQUEST, 
    DECLINE_USER_REQUEST, 
    UPDATE_USER_APPROVAL_REQ,
    ASSIGN_TEMPLATE
  } } = API_BOOK

const departmentAction = {}

const getDepartmentAdminUsersSuccessAD = (payload) => ({
    type: GET_ALL_DEPARTMENT_ADMIN_USERS_SUCCESS,
    payload
})
const getDepartmentAdminUsersFailedAD = () => ({
    type: GET_ALL_DEPARTMENT_ADMIN_USERS_FAILED
})

const getDepartmentAdminUsersActiveCountSuccessAD = (payload) => ({
    type: GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_SUCCESS,
    payload
})
const getDepartmentAdminUsersActiveCountFailedAD = () => ({
    type: GET_ALL_DEPARTMENT_ADMIN_USERS_ACTIVE_COUNT_FAILED
})
const getDepartmentAdminUsersDactivatedCountSuccessAD = (payload) => ({
    type: GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_SUCCESS,
    payload
})
const getDepartmentAdminUsersDactivatedCountFailedAD = () => ({
    type: GET_ALL_DEPARTMENT_ADMIN_USERS_DEACTIVATED_COUNT_FAILED
})

const getPendingUsersSuccessAD = (payload) => ({
    type: GET_PENDING_USERS_SUCCESS,
    payload
})
const getPendingUsersFailedAD = () => ({
    type: GET_PENDING_USERS_FAILED
})



/**
 *  @desc         	Create department and department admin
 *  @author      	Amir Shaikh
 *  @api          	/department/create
 *  @method       	POST
 *  @createdDate  	1-APRIL-2024
 *  @modifiedDate 	1-APRIL-2024
 **/
departmentAction.createDepartment = async ({ adminEmail, adminName, departmentName, assignedModules }) => {
    try {
        const response = await API({
            url: API_BOOK.DEPARTMENT.CREATE_DEPARTMENT.url,
            method: API_BOOK.DEPARTMENT.CREATE_DEPARTMENT.method,
            data: { adminEmail, adminName, departmentName, assignedModules },
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

/**
 *  @desc         	Edit department and department admin
 *  @author      	Amir Shaikh
 *  @api          	/department/update
 *  @method       	POST
 *  @createdDate    1-APRIL-2024
 *  @modifiedDate 	1-APRIL-2024
 **/
departmentAction.editDepartment = async ({ departmentId, adminEmail, adminName, departmentName, assignedModules }) => {
    try {
        const response = await API({
            url: API_BOOK.DEPARTMENT.EDIT_DEPARTMENT.url,
            method: API_BOOK.DEPARTMENT.EDIT_DEPARTMENT.method,
            data: { departmentId, adminEmail, adminName, departmentName, assignedModules },
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

/**
 *  @desc         	Get department module lists
 *  @author      	Amir Shaikh
 *  @api          	/department/get-modules
 *  @method       	GET
 *  @createdDate    1-APRIL-2024
 *  @modifiedDate 	1-APRIL-2024
 **/
departmentAction.getModules = () => async (dispatch) => {
    try {
        const response = await API({
            url: API_BOOK.DEPARTMENT.MODULES_LIST.url,
            method: API_BOOK.DEPARTMENT.MODULES_LIST.method,
        })
        if(response.status === 200) {
          dispatch({ type: ActionTypes.DEPARTMENT.MODULE_LIST_SUCCESS, payload: response.data.data })
        }
        return response
    } catch (error) {
        dispatch({ type: ActionTypes.DEPARTMENT.MODULE_LIST_FAILURE })
        console.log('error', error)
    }
}
/**
 *  @desc     Get Users count for department users
 *  @author   Sanjana Manimaran
 *  @api      /department/users/count
 *  @method   POST
 *  @createdDate  11-Apr-2024
 *  @modifiedDate 11-Apr-2024
 **/
export const getUserCount = () => async (dispatch) => {
    try {
      const response = await API({
        url: API_BOOK.DEPARTMENT.USERS_COUNT.url,
        method: API_BOOK.DEPARTMENT.USERS_COUNT.method
      });
      if(response.status === 200) {
        dispatch({ type: ActionTypes.DEPARTMENT.DEPARTMENT_USER_SUCCESS, payload: response.data.data });
      }
      else {
        dispatch({ type: ActionTypes.DEPARTMENT.DEPARTMENT_USER_FAILURE })
      }
      return response.data;
    } catch (error) {
        dispatch({ type: ActionTypes.DEPARTMENT.DEPARTMENT_USER_FAILURE })
        console.log('error', error)
    }
}
/**
 *  @desc     Get Vehicle count for department users
 *  @author   Sanjana Manimaran
 *  @api      /department/vehicles/count
 *  @method   POST
 *  @createdDate  03-May-2024
 *  @modifiedDate 03-May-2024
 **/
export const getVehicleCount = () => async (dispatch) => {
  try{
     const response = await API({
    url: API_BOOK.DEPARTMENT.VEHICLES_COUNT.url,
    method: API_BOOK.DEPARTMENT.VEHICLES_COUNT.method
  });
  if(response.status === 200) {
    dispatch({ type: ActionTypes.DEPARTMENT.VEHICLES_COUNT_SUCCESS, payload: response.data.data });
  }
  else {
    dispatch({ type: ActionTypes.DEPARTMENT.VEHICLES_COUNT_FAILURE })
  }
      return response.data;
    } catch (error) {
        dispatch({ type: ActionTypes.DEPARTMENT.DEPARTMENT_ACCESS_TEMPLATE_FAILURE })
        console.log('error', error)
    }
}

/**
 *  @desc         	Get Customers list
 *  @author      	Ankit Kushwaha
 *  @api          	/customers/list
 *  @method       	POST
 *  @createdDate  	28-MARCH-2024
 *  @modifiedDate 	28-MARCH-2024
 **/
departmentAction.getDepartmentList = ({
	searchText = "",
	sortBy = "",
	sortOrder = "",
	pageIndex = 1,
	pageSize = 8,
} = {}) =>
	async (dispatch) => {
		try {
			const res = await API({
                url: API_BOOK.DEPARTMENT.DEPARTMENT_LIST.url,
                method: API_BOOK.DEPARTMENT.DEPARTMENT_LIST.method,
				data: {
					searchText,
					sortBy,
					sortOrder,
					pageIndex,
					pageSize,
				},
			});

			if (res.status === 200 && res?.data?.data) {
				const response = res?.data;
                dispatch({ type: ActionTypes.DEPARTMENT.DEPARTMENT_LIST_SUCCESS, payload: response.data })

                return response
			}
		} catch (error) {
            dispatch({ type: ActionTypes.DEPARTMENT.DEPARTMENT_LIST_FAILURE })
			console.error("error", error);
		}
	};

     /**
 *  @desc         Norminc get department admin users API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/du-user-list
 *  @method       POST
 *  @createdDate  16-APRIL-2024
 *  @modifiedDate 16-APRIL-2024
 **/

 export const GetAllDepartmentAdminUsers = ({data}) => async (dispatch) => {
    try {
        const { searchKey, pageNumber, pageSize, sortByColumn, sortOrder  } = data 
      const res = await API({
        url: GET_ALL_DEPART_ADMIN_USERS.url,
        method: GET_ALL_DEPART_ADMIN_USERS.method,
        data: {
            searchKey, 
            pageNumber, 
            pageSize,
            sortByColumn,
            sortOrder
          }
      });
      if(res.status === 200) {
        dispatch(getDepartmentAdminUsersSuccessAD(res?.data?.data))
      }
      else {
        dispatch(getDepartmentAdminUsersFailedAD())
      }
  
    } catch (error) {
      console.log("GetAllDepartmentAdminUsers", error);
    }
  };
 

/**
 *  @desc         Norminc get department admin users active count API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/da-user-active-count'
 *  @method       POST
 *  @createdDate  16-APRIL-2024
 *  @modifiedDate 16-APRIL-2024
 **/

 export const GetAllDepartmentAdminUsersActiveCount = () => async (dispatch) => {
    try {
      const res = await API({
        url: GET_ALL_DEPART_ADMIN_USERS_ACTIVE_COUNT.url,
        method: GET_ALL_DEPART_ADMIN_USERS_ACTIVE_COUNT.method,
      });
      if(res.status === 200) {
        dispatch(getDepartmentAdminUsersActiveCountSuccessAD(res?.data?.data?.activeCount))
      }
      else {
        dispatch(getDepartmentAdminUsersActiveCountFailedAD())
      }
  
    } catch (error) {
      console.log("GetAllDepartmentAdminUsersActiveCount", error);
    }
  };

/**
 *  @desc         Norminc get department admin users active count API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/da-user-deactive-count'
 *  @method       POST
 *  @createdDate  16-APRIL-2024
 *  @modifiedDate 16-APRIL-2024
 **/

 export const GetAllDepartmentAdminUsersDeactivatedCount = () => async (dispatch) => {
    try {
      const res = await API({
        url: GET_ALL_DEPART_ADMIN_USERS_DEACTIVATED_COUNT.url,
        method: GET_ALL_DEPART_ADMIN_USERS_DEACTIVATED_COUNT.method,
      });
      if(res.status === 200) {
        dispatch(getDepartmentAdminUsersDactivatedCountSuccessAD(res?.data?.data?.deactivatedCount))
      }
      else {
        dispatch(getDepartmentAdminUsersDactivatedCountFailedAD())
      }
  
    } catch (error) {
      console.log("GetAllDepartmentAdminUsersDeactivatedCount", error);
    }
  };

/**
 *  @desc         Norminc get department admin users API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/du-user-list
 *  @method       POST
 *  @createdDate  16-APRIL-2024
 *  @modifiedDate 16-APRIL-2024
 **/

     export const PendingDepartmentUsers = ({
      searchText = '',
      sortBy,
      sortOrder,
      pageIndex,
      pageSize,
     }) => async (dispatch) => {
      try {
        const res = await API({
          url: PENDING_USERS.url,
          method: PENDING_USERS.method,
          data: {
            searchText,
            sortBy,
            sortOrder,
            pageIndex,
            pageSize,
            }
        });
        if(res.status === 200) {
          dispatch(getPendingUsersSuccessAD(res?.data?.data))
        }
        else {
          dispatch(getPendingUsersFailedAD())
        }
    
      } catch (error) {
        console.log("PendingDepartmentUsers", error);
      }
    }; 

/**
 *  @desc         Norminc Approve Department User Access Request
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/approve-department-user-request
 *  @method       POST
 *  @createdDate  20-APRIL-2024
 *  @modifiedDate 20-APRIL-2024
 **/

 export const ApproveDepartmentUserRequest = async ({userId}) => {
  try {
    const res = await API({
      url: APPROVE_USER_REQUEST.url,
      method: APPROVE_USER_REQUEST.method,
      data: { userId }
    });
   return res
  } catch (error) {
    console.log("ApproveDepartmentUserRequest", error);
  }
};

/**
 *  @desc         Norminc Decline Department User Access Request
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/decline-department-user-request
 *  @method       POST
 *  @createdDate  20-APRIL-2024
 *  @modifiedDate 20-APRIL-2024
 **/

 export const DeclineDepartmentUserRequest = async ({userId}) =>{
  try {
    const res = await API({
      url: DECLINE_USER_REQUEST.url,
      method: DECLINE_USER_REQUEST.method,
      data: { userId }
    });
    return res;
  } catch (error) {
    console.log("DeclineDepartmentUserRequest", error);
  }
};

/**
 *  @desc         Norminc Update User Approval Request
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/update-approval-request
 *  @method       POST
 *  @createdDate  23-APRIL-2024
 *  @modifiedDate 23-APRIL-2024
 **/

 export const UpdateUserApprovalRequest = async ({ userId, departmentId, location, assignedAccessTemplateId }) =>{
  try {
    const res = await API({
      url: UPDATE_USER_APPROVAL_REQ.url,
      method: UPDATE_USER_APPROVAL_REQ.method,
      data: { userId, departmentId, location, assignedAccessTemplateId }
    });
    return res;
  } catch (error) {
    console.log("UpdateUserApprovalRequest", error);
  }
};

/**
 *  @desc         Norminc Assign Template To User Approval Request
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /department/assign-access-template
 *  @method       POST
 *  @createdDate  29-APRIL-2024
 *  @modifiedDate 29-APRIL-2024
 **/

export const AssignTemplateToUserApprovalRequest = async ({ userId, accessTemplateId }) =>{
  try {
    const res = await API({
      url: ASSIGN_TEMPLATE.url,
      method: ASSIGN_TEMPLATE.method,
      data: { userId, accessTemplateId }
    });
    return res;
  } catch (error) {
    console.log("AssignTemplateToUserApprovalRequest", error);
  }
};



    /**
 *  @desc         	Active Status department
 *  @author      	Ankit Kushwaha
 *  @api          	/department/activate
 *  @method       	POST
 *  @createdDate  	16-APRIL-2024
 *  @modifiedDate 	16-APRIL-2024
 **/
    departmentAction.ActiveStatusDepartment = async ({ data }) => {
	try {
		const resp = await API({
            url: API_BOOK.DEPARTMENT.ACTIVATE_STATUS.url,
            method: API_BOOK.DEPARTMENT.ACTIVATE_STATUS.method,
			data,

		});

		return resp;
	} catch (error) {

		console.error("error", error);

	}
}

    /**
 *  @desc         	DeActive Status department
 *  @author      	Ankit Kushwaha
 *  @api          	/department/deactivate
 *  @method       	POST
 *  @createdDate  	16-APRIL-2024
 *  @modifiedDate 	16-APRIL-2024
 **/
    departmentAction.DeActiveStatusDepartment = async ({ data }) => {
        try {
            const resp = await API({
                url: API_BOOK.DEPARTMENT.DEACTIVATE_STATUS.url,
                method: API_BOOK.DEPARTMENT.DEACTIVATE_STATUS.method,
                data,
    
            });
    
            return resp;
        } catch (error) {
    
            console.error("error", error);
    
        }
    }
    




export default departmentAction
/*conflict-resolution-will be removed later*/