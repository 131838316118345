import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import GPGroupTableRow from './GPGroupTableRow';

const GPGroupTable = (props) => {
    const { groupsList, onGroupExpansionClick, expandedGroups, onVehicleCountClick, onMenuExpansionClick, expandedMenuId, onRenameClick, onDeleteClick, onEditClick, onAddSubgroupClick ,isShowViewModal,toggleViewOnboardingVehiclesModal,toggleViewOnboardingVehiclesModalhandleclose,assigngroupName,groupAssignList} = props;
   
    
    useEffect(() => {
        const handleListRowActionOutsideClick = (event) => {
            if (!event?.target?.classList.contains('groups__view__threedots')) {
                onMenuExpansionClick('')
			}
		}
		document.addEventListener('click', handleListRowActionOutsideClick)
		return () => {
            document.removeEventListener('click', handleListRowActionOutsideClick)
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    return (
        < >
           
                <Table responsive borderless className='mt-4'  id='groups__table__data'>
                    <thead className='table-header'>
                        <tr>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>S.No</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>Group Name</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>Vehicle Count</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>Created Date</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>Modified Date</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>No.of subgroup</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>No.of Assignees</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'>Action</p>
                            </th>
                            <th className='ps-3 text-nowrap'>
                                <p className='fw500'></p>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {groupsList.map((group, groupIndex) => (
                            <GPGroupTableRow
                                key={`group_row_${groupIndex}`}
                                groupIndex={group.index}
                                groupData={group}
                                isOpenPopup={false}
                                onVehicleCountClick={onVehicleCountClick}
                                onGroupExpansionClick={() => onGroupExpansionClick(groupIndex, group?.subGroup?.length)}
                                isGroupExpanded={expandedGroups.includes(groupIndex)}
                                hasSubgroup={group?.subGroup?.length ? true : false}
                                onMenuExpansionClick={onMenuExpansionClick}
                                expandedMenuId={expandedMenuId}
                                onRenameClick={onRenameClick}
                                onDeleteClick={onDeleteClick}
                                onEditClick={onEditClick}
                                onAddSubgroupClick={onAddSubgroupClick}
                                toggleViewOnboardingVehiclesModal={toggleViewOnboardingVehiclesModal}
                                isShowViewModal={isShowViewModal}
                                toggleViewOnboardingVehiclesModalhandleclose={toggleViewOnboardingVehiclesModalhandleclose}
                                assigngroupName={assigngroupName}
                                groupAssignList={groupAssignList}
                            />
                        ))}
                    </tbody>

                </Table>
                  
         
        </>
    );
}

export default GPGroupTable;