import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { processChangeWithDebounce }  from '../../../../utils/common-methods'; 
import inputStyles from './SearchInput.module.css'; 

const SearchInput = ({ setSearchKey, setCurrentPage, placeholder }) => {
  
  return (
    <div className={`${inputStyles.inputWrapper}`}>
      <img src='/images/searchIcon.svg' alt='searchIcon'className={`${inputStyles.searchIcon}`}/>
      <input
        type="text"
        onChange={(e) => {
          processChangeWithDebounce(
            e,
            setSearchKey,
            setCurrentPage
          )
        }}
        className={`${inputStyles.root}`}
        placeholder={placeholder}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  placeholder: 'Search',
  debounceTimeout: 300,
};

SearchInput.propTypes = {
  setSearchKey: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  debounceTimeout: PropTypes.number,
};

export default memo(SearchInput);
/*conflict-resolution-will be removed later*/