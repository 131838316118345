import React, { useState } from "react";
import { useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import dashboardAction from '../../store/actions/dashboard/dashboard';
import { useNavigate } from "react-router-dom";

const TopSearchBar = ({ isDashboard }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const topPanelSearchQuery = useSelector(state => state.dashboardReducer.topPanelSearchQuery)

  useEffect(() => {

    const debounce = setTimeout(() => {
      dispatch(
        dashboardAction.setTopPanelSearchQuery({
          searchQuery: query
        })
      )
      dispatch({
        type:"topSearch",
        payload: query
      })
    }, 1500)

    return () => { clearTimeout(debounce) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  // on reset update field text
  useEffect(() => {
    if (!topPanelSearchQuery) {
      setQuery('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topPanelSearchQuery])

  return (
    <div
    className={`${!query? 'no-value-selected-input ' : ''}`}
    >
      <div
        className="search-input"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 50,
          paddingRight: 150,
          backgroundColor: "#EFF3F8",
          borderRadius: 2,
          paddingTop: 5,
          height: 38,
          width: 550,
          overflow: "hidden",
          marginBottom:10,
          marginLeft:100,
          marginTop:10
        }}
      >
        <FaSearch
          style={{
            marginBottom: 15,
            marginRight: 20,
            marginTop: 10,
            cursor:'pointer',
            color:'gray',
            fontSize:20
          }}
          title="search"
        />
        <input
          data-testid='header-search-input'
          onChange={(e) => { 
            if (!isDashboard) {
              navigate("/")
            }
            setQuery(e?.target?.value)            
           }}
          style={{
            backgroundColor: "#EFF3F8",
            borderRadius: 10,
            border: "none",
            width: 360,
            paddingLeft: 10,
            paddingBottom: 6,
            outline: "none",
            color:'#1F2a5d'
          }}
          className="search-input"
          value={query}
          placeholder="Search"
        />
        {

          query.length > 0 &&
          <AiOutlineClose
          style={{
            cursor:'pointer',
            color:'gray',
            fontSize:28,
            position:'relative',
            left:120
          }}
          data-testid='header-search-clear-button'
          onClick={() => {
            setQuery('')
            
          }}
          title="Clear"
        />

        }
                      
      </div>
    </div>
  );
};

export default TopSearchBar;
