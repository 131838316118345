import ActionTypes from '../../actionTypes'
const { GET_SUBSCRIBED_RN_NUMS_SUCCESS, GET_ALL_VEHICLES_TRACKING_DETAILS, TOP_PANE_STATUS,GET_SUBSCRIBED_RN_NUMS_OEM_MODEL, GET_CI_SUBSCRIBED_VEHICLES_SUCCESS } = ActionTypes.VEHICLE.VEHICLE

const initialState = {
  subscribedVehiclesByCI: [],
  subcribedRnNums: [],
  subcribedRnNumsOemModel:[],
  allVehiclesTrackingData: [],
  topPaneStatus: 'all',
  isSoCFilterApplied: false
}

const vehicleReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_CI_SUBSCRIBED_VEHICLES_SUCCESS: 
      return {
        ...state,
        subscribedVehiclesByCI: payload
      }
    case GET_SUBSCRIBED_RN_NUMS_SUCCESS:
      return {
        ...state,
        subcribedRnNums: payload
    
      }
      case GET_SUBSCRIBED_RN_NUMS_OEM_MODEL:
      return {
        ...state,
        subcribedRnNumsOemModel:payload
      }
      
    case GET_ALL_VEHICLES_TRACKING_DETAILS:
      return {
        ...state,
        allVehiclesTrackingData: payload
      }
    case TOP_PANE_STATUS:
      return {
        ...state,
        topPaneStatus: payload.status
      }
    default:
      return state
  }
}

export default vehicleReducer
