import React from 'react';
import { BsChevronUp } from 'react-icons/bs';
import VehicleStatusRecordsDaywise from './VehicleStatusRecordsDaywise';
import '../css/VehicleStatus.css';
import { secondsToHoursAndMinutesHHMM} from '../../../../utils/common-methods';

const VehicleStatusRecordsTable = (props) => {
	const { recordsData, recordsIndex, isRcVehicleExpanded, onRcVehicleClick, vehicleStatus } = props;
	const [expandedRcVehicleDetails, setExpandedRcVehicleDetails] = React.useState([]);
	const handleRcVehicleDetailsExpansionClick = (subrecordsIndex) => {
		setExpandedRcVehicleDetails((currentValue) => {
			if (currentValue.includes(subrecordsIndex)) {
				return currentValue.filter((value) => value !== subrecordsIndex);
			}
			return [...currentValue, subrecordsIndex];
		});
	};
	const renderStatusBlock = (label, color) => {
		const statusCount = recordsData?.statusTotalCount?.[label];

		return (
			<td className='ps-3 text-nowrap Rc_record_status_block'>
				<div className='rc__status_block_container'>
					<span>{label} </span>
					<p className='fw500 Rc__records__row__td'>
						{statusCount !== undefined ? secondsToHoursAndMinutesHHMM(statusCount) : 0}
					</p>
					<hr className={`rc__border${color}`} />
				</div>
			</td>
		);
	};
	const getStatusBlocks = () => {
		if (vehicleStatus === 'NonComm') {
			const statusCount = recordsData?.statusTotalCount?.NonComm;
			return (
				<>
					<td colSpan={8}>
						<div className='ps-3 text-nowrap rc__status_block_nocom_container'>
							<span>Non Communication </span>
							<p className='fw500 Rc__records__row__td'>
								
								{statusCount !== undefined ? secondsToHoursAndMinutesHHMM(statusCount) : 0}
							</p>
							<hr className={`rc__border_noncommunication`} />
						</div>
					</td>
				</>
			);
		} else {
			return (
				<>
					{renderStatusBlock('Live', 'Green')}
					{/* Note: Merge Idle status to Live status */}
					{/* {renderStatusBlock('Idle', 'Orange')} */}
					{renderStatusBlock('Parked', 'Brown')}
					{renderStatusBlock('Charging', 'Purple')}
				</>
			);
		}
	};

	const concatenatedOemModel = `${recordsData.oem} ${recordsData.model}`;


	return (
		<>
			<tr>
				<td className='ps-3 text-nowrap' style={{ maxWidth: 10, padding: '0 !important' }}></td>

				<td className='ps-3 text-nowrap'>
					<table>
						<tbody>
							<tr className='Rc__records__row'>
								<td className='ps-3 text-nowrap '>
									<div className='d-flex align-items-center'>
										<p className='fw500 Rc__records__row__td Rc_record_border_right'>
											{recordsData.registrationNumber}
										</p>
										<p className='fw500 Rc__records__row__td mt-0'>{concatenatedOemModel}</p>
									</div>
								</td>

								{getStatusBlocks()}
								<td className='ps-3 text-nowrap'>
									<p
										className={`fw500 Rc__records__row__td cursor-pointer`}
										onClick={onRcVehicleClick}
										data-testid={`expansion-button-${recordsData.registrationNumber}`}
									>
										{ vehicleStatus === 'NonComm' || recordsData.dayWiseStatusCount.length === 0 ? (
											''
										) : (
											<BsChevronUp
												style={{
													fontSize: 14,
													fontWeight: '500',
													strokeWidth: 1,
													transform: isRcVehicleExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
													transition: 'all 0.35s linear',
												}}
											/>
										)}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>

			{isRcVehicleExpanded &&
				recordsData.dayWiseStatusCount.map(
					(subrecordsData, subrecordsIndex) =>
						vehicleStatus !== 'NonComm' &&   (
							<VehicleStatusRecordsDaywise
								key={`${recordsIndex}_subrecord_${subrecordsIndex}`}
								onRcVehicleDetailClick={() => handleRcVehicleDetailsExpansionClick(subrecordsIndex)}
								isRcVehicleDetailExpanded={expandedRcVehicleDetails.includes(subrecordsIndex)}
								subrecordsData={subrecordsData}
								subrecordsIndex={subrecordsIndex}
								vehicleStatus = {vehicleStatus}
							/>
						)
				)}
		</>
	);
};

export default VehicleStatusRecordsTable;
