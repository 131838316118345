import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UpdatePassComponent from "../pages/User/Auth/updatepass";
import PropTypes from "prop-types";
import "../../styles/globalStyles.css";
import { connect } from "react-redux";
import { UserProfile } from "../../store/actions/user";
import TopSearchBar from "../topPaneSearchbar/topPaneSearchbar";
import SideNavPanel from "./sideNavPanel";
import axios from "axios";
import VehicleStatus from './VehicleStatus'
import { GetAllNotificationData } from '../../store/actions/notificationPanel'
import NotificationPanel from "./notificationPanel";
import { ShowNotificationPanel } from '../../store/actions/notificationPanel'
import { notificationStatus } from "../../utils/constants";
import { clearLocalStorageExceptclientIP } from "../../utils/common-methods";


const Sidebar = ({ 
  // Action
  UserProfile,
  getNotificationData,
  showNotificationPanel,

  // state
  notificationData,
  checkForNotification
  // Logout
}) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const notificationIntervalRef = useRef(null);

	const route = useLocation();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	let firstName = '';
	let lastName = '';
	let name = '';
	let _id = '';

	if (localStorage && localStorage.user) {
		firstName = JSON.parse(localStorage.user).firstName;
		lastName = JSON.parse(localStorage.user).lastName;
		name = firstName + ' ' + lastName;
		_id = JSON.parse(localStorage.user)._id;
	}

	const logout = async () => {
		try {
			const res = await axios({
				url: 'user/logout',
				method: 'POST',
				headers: {
					clientIp: localStorage.clientIP,
					Authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					_id
				},
			});
			if (res) {
        clearLocalStorageExceptclientIP()
				navigate('/Login');
			}
		} catch (error) {
			console.log('logouttt', error);
		}
	};

	useEffect(() => {
		document.addEventListener('click', (e) => {
			let iconId = e?.target?.id;
			return iconId !== 'profileIcon' ? setIsDropdownOpen(false) : null;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDropdownOpen]);

	const handleProfileClick = () => {
		UserProfile();
		navigate('/profile');
	};

	const handlePasswordClick = () => {
		setShowPopup(true);
	};

	const handleLogout = () => {
		logout();
	};

  useEffect(() => {
    if(notificationIntervalRef.current){
      clearInterval(notificationIntervalRef.current);
    }
    if ((notificationData && notificationData.length > 0 && notificationData?.some(x=>x.status === notificationStatus.inProgress || x.status === notificationStatus.queued))) {
      notificationIntervalRef.current = setInterval(() => {
        getNotificationData()
      }, 60000);
    }
  }, [notificationData,getNotificationData])

  useEffect(() => {
    getNotificationData()
  }, [getNotificationData])


  const Popup = ({ isOpen, togglePopup }) => {
    return (
      <div id="popup1" className={`popup ${isOpen ? "open" : ""}`}>
        <div className="popup-overlay" onClick={togglePopup}></div>
        <div className="popup-content">
          <UpdatePassComponent isOpen={isOpen} toggleModal={togglePopup} />
        </div>
      </div>
    );
  };

	const togglePopup = () => {
		setShowPopup((prevState) => !prevState);
	};

  const handleNoticationIconClick = () => {
    showNotificationPanel();
  }

  return (
    <>
      <header>
        <div className="header-content">
          <div className="d-flex flex-row gap-2 justify-content-between">
            <div className="header-left-container d-flex flex-row gap-2 justify-content-between">
              <div className="ham-menu">
                <div className="bars">
                </div>
              </div>
              <div
                className="header-logo "
                style={{
                  marginRight: 20,
                }}
              >
                <img
                  alt="logo"
                  src="/images/logo/NewHeaderLogo.svg"
                  className="header-magenta-logo"
                  style={{
                    marginTop: route.pathname !== "/" ? 19 : 0,
                    marginBottom: route.pathname === "/" ? 6 : 0,
                    width: "77px",
                    marginLeft: "-19px",
                  }}
                  data-testid='header-logo-link'
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
              {["/", "/old-dashboard"].includes(route.pathname) && <TopSearchBar isDashboard={route.pathname === "/"} />}
            </div>
            <div className="header-right-container d-flex flex-row gap-1 justify-content-between me-3">
              {["/"].includes(route.pathname) ? <VehicleStatus isDashboard={route.pathname === "/"} /> : null}

              <div style={{
                display:"flex"
              }}
              id='nd__notification_icon_div'
              data-testid='header-notification-button'
              onClick={() => handleNoticationIconClick()}
              >
                <button
                  className={`dvs__vehicle-container all-vehicle`}
                  style={{ backgroundColor: "#EFF3F8", borderRadius: "50px" }}
                  id='nd__notification_icon_button'
                  title={"notification"}
                >
                <span id='nd__notification_icon_span'
                className={'bell-icon-text'}>{notificationData && notificationData?.length > 0 && notificationData?.filter(x => !x.isNotified)?.length ? notificationData?.filter(x => !x.isNotified)?.length : ' '}</span>
                  <img id='nd__notification_icon_img' className='dvs__vehicle-img' src='/images/svgicon/notification.svg' height={24} width={24} alt="notification" />
                </button>
              </div>
              <NotificationPanel />

              <div className={"avatar-text-container"}>
                <div className="dropdown">
                  {isDropdownOpen && (
                    <div
                      className="dropdown-content dropdown-custom-style"
                      style={{
                        width: 185,
                      }}
                    >
                      <li
                        style={{
                          fontWeight: 'bold'
                        }}
                      >Hi {name}</li>
                      <li
                        onClick={() => {
                          handleProfileClick();
                          setIsDropdownOpen(false);
                        }}
                      >
                        Profile
                      </li>
                      <li
                        onClick={() => {
                          handlePasswordClick();
                          setIsDropdownOpen(false);
                        }}
                      >
                        Update Password
                      </li>
                      <li
                        onClick={() => {
                          navigate("userActivity");
                          setIsDropdownOpen(false);
                        }}
                      >
                        Activity log
                      </li>
                      <li
                        onClick={() => {
                          handleLogout();
                          setIsDropdownOpen(false);
                        }}
                      >
                        Logout
                      </li>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

			<SideNavPanel></SideNavPanel>

			{showPopup && <Popup isOpen={showPopup} togglePopup={togglePopup} />}
		</>
	);
};

Sidebar.propTypes = {
	// Actions
	UserProfile: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  UserProfile,
  notificationData: state.notificationPanelReducer.notificationData,
  checkForNotification: state.notificationPanelReducer.checkForNotification
  // Logout,
});

export default connect(mapStateToProps, {
  UserProfile,
  getNotificationData: GetAllNotificationData,
  showNotificationPanel :ShowNotificationPanel,
  //  Logout
})(Sidebar);
