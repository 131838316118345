import ActionTypes from "../../actionTypes";

const {
    SA_SIGNUP: { SA_SIGNUP_SUCCESS, SA_SIGNUP_FAILED, SA_VERIFY_OTP_SUCCESS, SA_VERIFY_OTP_FAILED, GET_CUSTOMER_ADDRESS_SUCCESS,
        GET_CUSTOMER_ADDRESS_FAILED },
    AUTH: { GET_DEPARTMENT_FAILURE ,GET_DU_LOCATION_SUCCESS,GET_DU_LOCATION_FAILURE, GET_DU_TEMPLATE_FAILURE ,GET_DU_TEMPLATE_SUCCESS,GET_DU_ASSIGN_TEMPLATE_SUCCESS}

} = ActionTypes;

const initialState = {
    userId: '',
    notVerified: null,
    isOtpVerified: null,
    address: null,
    addressError: null,
    duDepartmentList: [],
    duLocationList: [],
    duTemplateList :[],
    duAssignTemplate:[]

}

const SA_Signup = (state = initialState, action )  => {
    const { type, payload } = action

    switch (type) {
        case SA_SIGNUP_SUCCESS:
            return {
                notVerified: false,
                userId: payload
            }
        case SA_SIGNUP_FAILED:
            return {
                notVerified: true
            }
        case SA_VERIFY_OTP_SUCCESS:
            return {
                isOtpVerified: true
            }
        case SA_VERIFY_OTP_FAILED:
            return {
                isOtpVerified: false
            }
        case GET_CUSTOMER_ADDRESS_SUCCESS:
            return {
                address: payload,
                addressError: null
            };
        case GET_CUSTOMER_ADDRESS_FAILED:
            return {
                address: null,
                addressError: 'Failed to fetch address.'
            };
        
        case ActionTypes.AUTH.GET_DEPARTMENT_SUCCESS: {
            return {
                ...state,
                duDepartmentList: payload,
            }
        }

        case GET_DEPARTMENT_FAILURE: {
            return {
                ...state,
                duDepartmentList: initialState.duDepartmentList,
            }
        }
        case GET_DU_LOCATION_SUCCESS: {
            return {
                ...state,
                duLocationList: payload,
            }
        }

        case GET_DU_LOCATION_FAILURE: {
            return {
                ...state,
                duLocationList: initialState.duLocationList,
            }
        }
        case GET_DU_TEMPLATE_SUCCESS: {
            return {
                ...state,
                duTemplateList: payload,
            }
        }

        case GET_DU_TEMPLATE_FAILURE: {
            return {
                ...state,
                duTemplateList: initialState.duTemplateList,
            }
        }
        case GET_DU_ASSIGN_TEMPLATE_SUCCESS :{
         return {
             ...state,
             duAssignTemplate: initialState.duAssignTemplate,
        } 
        }
        default:
            return state
    }
}

export default SA_Signup
/*conflict-resolution-will be removed later*/