import React from "react";

import { useState } from "react";
import AlarmReport from "./AlarmReport";
import { getAllImmobile, getAllNonCommLogs, getAllAnalyticsAlarms } from "../../../store/actions/reports/alarm";
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from "react-router-dom";
import Loader from "../../helpers/hoc/loader/Loader";
import { useEffect } from "react";
const AlarmAnalytics = ({
  getAllImmobile,
  getAllNonCommLogs,
  getAllAnalyticsAlarms,

  allImmobileData,
  allNonCommLogsData,
  allAnalyticsAlarmsData
}) => {
  // eslint-disable-next-line no-unused-vars
  const [ eventName, setEventName ] = useState('alert_analytics')
  
 
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const registrationNumber = params.get('registration_number')
  const startDate = params.get('start_date')
  const endDate = params.get('end_date')
  const isLoading = useSelector((s) => s?.loader?.isLoading)

  
  useEffect(() => {
    if(registrationNumber && startDate && endDate) {
      setEventName('alert_logs')
    }
  }, [registrationNumber, startDate, endDate])
  
  return (
    <>
      <div>
      <AlarmReport 
              vehicleNumber={registrationNumber}
              alarmReportDate={startDate && endDate ? { fromDate: new Date(startDate), toDate: new Date(endDate)} : {} }
            />
      </div>
      { isLoading &&
        (<div className = 'al__loader'>
          <Loader isLoading={isLoading}  />
        </div>)
      }
    </>
  );
};
AlarmAnalytics.propTypes = {
  allImmobileData: PropTypes.array.isRequired,
  allNonCommLogsData: PropTypes.array.isRequired,
  allAnalyticsAlarmsData: PropTypes.array.isRequired,

  // Action props
  getAllImmobile: PropTypes.func.isRequired,
  getAllNonCommLogs: PropTypes.func.isRequired,
  getAllAnalyticsAlarms: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  allImmobileData: state.alarm.allImmobileData,
  allNonCommLogsData: state.alarm.allNonCommLogsData,
  allAnalyticsAlarmsData: state.alarm.allAnalyticsAlarmsData
})
export default connect(mapStateToProps, {
  getAllImmobile,
  getAllNonCommLogs,
  getAllAnalyticsAlarms
})(AlarmAnalytics)
