import axios from './axios'
import { ToggleLoader } from './interceptor'
import { refreshAuth } from '../store/actions/auth';

let apicount = 0 ;


const API = async ({
  url,
  method,
  data, // API Body
  params, // API Query params
  headers,
  responseType,
  onUploadProgress,
  onDownloadProgress,
  manageLoader = true
}) => {
  //REQUEST JSON

  let axiosRequestObject = {
    method,
    url,
    data,
    headers: {
      ...headers,
      clientIp: localStorage.clientIP,
      Authorization:
        localStorage.token && localStorage.user
          ? `Bearer ${localStorage.token}`
          : '',
    },
    params,
    responseType,
    onUploadProgress,
    onDownloadProgress
  }

  if (manageLoader) {
    apicount += 1;
  }

  //REQUEST
  if (apicount > 0 && manageLoader) {
    ToggleLoader({ setLoading: true });
  }
  let request = await axios
    .request(axiosRequestObject)
    .then(handleSuccessRequest)
    .catch(handleErrorRequest)
    .finally(() => {
      if (manageLoader) {
        apicount -= 1;
      }
      if (apicount === 0 && manageLoader) {
        ToggleLoader({ setLoading: false });
      }
    })

  // added handling for 401 scenario where token can be refetched and same journey should be completed
  if (request.status === 401) {
    // define endpoints in which refresh token should be skipped
    const skippedEndpoints = ['auth/gettoken', 'auth/refresh']
    // define webpages on which refresh token logic should be skipped
    const skippedPathnames = ['/login', '/signup']
    
    // if refresh token is skipped then return failed request as it is
    if (skippedPathnames.includes(window.location.pathname) || skippedEndpoints.includes(url)) {
      return request
    }

    // else refetch tokens with API call
    await refreshAuth();

    // update request payload again with new token and ip details
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      axiosRequestObject.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    } else {
      axiosRequestObject.headers.Authorization = ''
    }

    if (manageLoader) {
      apicount += 1;
    }
  
    if (apicount > 0 && manageLoader) {
      ToggleLoader({ setLoading: true });
    }

    // request failed api again with new request object
    request = await axios
      .request(axiosRequestObject)
      .then(handleSuccessRequest)
      .catch(handleErrorRequest)
      .finally(() => {
        if (manageLoader) {
          apicount -= 1;
        }
        if (apicount === 0 && manageLoader) {
          ToggleLoader({ setLoading: false });
        }
      })
  }
  
  return request
}

const handleSuccessRequest = (response) => {
  return { status: response.status, data: response.data }
}

const handleErrorRequest = (err) => {
  if (err?.response?.config?.responseType === 'arraybuffer') {
    try {
      if ('TextDecoder' in window) {
        // Decode as UTF-8
        const dataView = new DataView(err.response.data)
        const decoder = new TextDecoder('utf8')
        err.response.data = JSON.parse(decoder.decode(dataView))
      } else {
        // Fallback decode as ASCII
        const decodedString = String.fromCharCode.apply(
          null,
          new Uint8Array(err.response.data)
        )
        err.response.data = JSON.parse(decodedString)
      }
    } catch (error) {
      console.error('Error: conversion error occured')
    }
  }

  if (!err.response) {
    return Promise.reject()
  } else return err.response
}

export default API
