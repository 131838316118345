import React from 'react';
import cx from 'classnames'
import { Col, Row } from 'react-bootstrap';

import DashboardKPI from '../../../shared/cards/DashboardKPI/DashboardKPI';
import PageHeader from '../../../shared/ui/PageHeader/PageHeader';
import DashboardFleet from '../../../shared/cards/DashboardFleet/DashboardFleet';
import DashboardStat from '../../../shared/cards/DashboardStat/DashboardStat';
import ArrowToggle from '../../../shared/toggles/arrowToggle/ArrowToggle';

import styles from './SADashboard.module.css'

const usersStatData = [
    {
        title: 'Total Users',
        count: 60000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
    {
        title: 'Active Users',
        count: 50000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
    {
        title: 'Deactivated Users',
        count: 30000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
]

const usageStatData = [
    {
        title: 'Login Count',
        count: 60000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
    {
        title: 'Avg. Daily Session Duration',
        count: 50000,
        unit: 'Hrs',
        vendors : [{
            name: 'Flipkart',
            count: 100,
        },
        {
            name: 'Amazon',
            count: 2000,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
    {
        title: 'Current Active Logins',
        count: 40000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
]

const customerServiceStatData = [
    {
        title: 'Active Support Requests',
        count: 60000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
    {
        title: 'Avg. Resolution TAT',
        count: 40000,
        vendors : [{
            name: 'Flipkart',
            count: 10,
        },
        {
            name: 'Amazon',
            count: 20,
        },
        {
            name: 'DMart',
            count: 30,
        }]
    },
]

const SADashboard = () => {

    return (
        <div className={cx(styles.mainContainer, 'd-flex flex-column h-100')}>
            <PageHeader heading='NorMinc Central Dashboard' />
            
            <div className='mb-4'>
                <Row>
                    <Col md="3">
                        <DashboardKPI
                            heading='Total Customers'
                            count='53000'
                            detailsLink='/sa/dashboard'
                            image='/images/svgicon/totalCustomers.svg'
                        />
                    </Col>

                    <Col md="3">
                        <DashboardKPI
                            heading='Total Users'
                            count='53000'
                            detailsLink='/sa/dashboard'
                            image='/images/svgicon/totalUsers.svg'
                        />
                    </Col>

                    <Col md="3">
                        <DashboardKPI
                            heading='Total Vehicles'
                            count='53000'
                            detailsLink='/sa/dashboard'
                            image='/images/svgicon/totalVehicles.svg'
                        />
                    </Col>

                    <Col md="3">
                        <DashboardKPI
                            heading='Total Cities'
                            count='53000'
                            detailsLink='/sa/dashboard'
                            image='/images/svgicon/totalCities.svg'
                        />
                    </Col>
                </Row>
            </div>

            <div className='flex-1 d-flex justify-content-between gap-4 w-100'>
                <div className={cx(styles.dashboardStatContainer,'h-100 w-75')}>
                    <Row className='mb-3'>
                        <Col md={4}>
                            <p className={cx(styles.dashboardStasHeading)}>Users</p>

                            {usersStatData.map((item,index) => (
                                <div className={cx(styles.dashboardStatCard)} key={`users_${index}`}>
                                    <DashboardStat title={item.title} vendors={item.vendors} units={item.unit} count={item.count} />
                                </div>
                            ))}
                        </Col>

                        <Col md={4}>
                            <p className={cx(styles.dashboardStasHeading)}>Usage</p>

                            {usageStatData.map((item,index) => (
                                <div className={cx(styles.dashboardStatCard)} key={`usage_${index}`}>
                                    <DashboardStat title={item.title} vendors={item.vendors} units={item.unit} count={item.count} />
                                </div>
                            ))}
                        </Col>

                        <Col md={4}>
                            <p className={cx(styles.dashboardStasHeading)}>Customer Service</p>

                            {customerServiceStatData.map((item,index) => (
                                <div className={cx(styles.dashboardStatCard)} key={`customer_service_${index}`}>
                                    <DashboardStat title={item.title} vendors={item.vendors} units={item.unit} count={item.count} />
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>

                <div className='h-100 w-25'>
                    <DashboardFleet
                        total={1000}
                        headerComponent={
                            <ArrowToggle
                                data={[
                                    {
                                        name: 'Flipkart',
                                        count: 300,
                                    },
                                    {
                                        name: 'Amazon',
                                        count: 500,
                                    },
                                    {
                                        name: 'DMart',
                                        count: 200,
                                    }
                                ]}
                            />
                        }
                        data={{
                            TWO: {
                                active: 20,
                                inactive: 30,
                                total: 50,
                            },
                            THREE: {
                                active: 30,
                                inactive: 20,
                                total: 50,
                            },
                            FOUR: {
                                active: 40,
                                inactive: 10,
                                total: 50,
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default SADashboard;
/*conflict-resolution-will be removed later*/