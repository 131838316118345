import ActionTypes from '../../actionTypes'
const {
  CREATE_FENCE,
  CREATE_FENCE_EXCEPTION,
  GET_FENCE_BY_ID,
  GET_FENCE_BY_ID_EXCEPTION,
  UPDATE_FENCE,
  UPDATE_FENCE_EXCEPTION,
  FENCE_API_OPERATION
} = ActionTypes.DIFFERENT_CREATE_FENCE_ACTIONS

const initialState = {
  fenceCreated: false,
  getFenceByIdDetails: {},
  updatedFence: [],
  isFenceApiOperationSuccess: false,
}

const fence = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case CREATE_FENCE:
      return {
        ...state,
        fenceCreated: payload,
      }
    case FENCE_API_OPERATION:
      return {
        ...state,
        isFenceApiOperationSuccess: payload,
      }

    case CREATE_FENCE_EXCEPTION:
      return {
        ...state,
        fenceCreated: false,
      }
    case GET_FENCE_BY_ID:
      return {
        ...state,
        getFenceByIdDetails: payload,
      }
    case GET_FENCE_BY_ID_EXCEPTION:
      return {
        ...state,
        getFenceByIdDetails: {},
      }
    case UPDATE_FENCE:
      return {
        ...state,
        updatedFence: payload,
      }
    case UPDATE_FENCE_EXCEPTION:
      return {
        ...state,
        updatedFence: [],
      }

    default:
      return state
  }
}

export default fence
