import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const Unauthorized = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
        <h1>Unauthorized</h1>
        <br />
        <p>You do not have access to the requested page.</p>
        <div className="flexGrow">
          <Button onClick={goBack}>Go Back</Button>
        </div>
    </div>
  )
}

export default Unauthorized
