import api from "../../service/api";
import API_BOOK from "../../service/endpoints";
import ActionTypes from "../actionTypes";
import { customToast } from '../../utils/toasts'
const otpActionTypes = ActionTypes.OTP;
const smsotpActionTypes = ActionTypes.SMS_OTP;
const otpApiConfig = API_BOOK.OTP;


/* Action dispatchers starts here */ 

const sendOtpDispatcher = (payload) => ({
  type: otpActionTypes.OTP_SEND_SUCCESS,
  payload,
});

const verifyOtpDispatcher = (payload) => ({
    type: otpActionTypes.OTP_VERIFY_SUCCESS,
    payload
  });


  const smsOtpDispatcher = (payload) => ({
    type: smsotpActionTypes.SMS_OTP_SEND_SUCCESS,
    payload,
  });


  
/* Action dispatchers ends here */ 


/* Actions starts here */ 

/**
 *  @desc         Send otp action
 *  @author       Sanjana Manimaran
 *  @contributor   Deepak Prajapati
 *  @api          /otp
 *  @method       POST
 *  @createdDate  May 16, 2023
 *  @modifiedDate May 18, 2023
 **/
export const SendOTP = ({ email, signup }) => async (dispatch) => {
    try {
      const resp = await api({
        method: otpApiConfig.method,
        url: otpApiConfig.url,
        data: {
          service: 'email',
          emailId:email,
          signup: signup,
        },
      });
      if (resp.status === 200) {
        dispatch(sendOtpDispatcher(true))
      }
      if (resp.status === 400 && resp.data.error === 'User already registered' ) {
        const response = resp?.data;
        setTimeout(() => {
          customToast({ message: response.error, svgImageName: '400_status', secondaryMessage: 'Please Login' })
        }, 400)
        console.log("error", response);
      }

      if (resp.status === 400 && resp.data.message === 'Max limit reached try after 1 hour' ) {
        const response = resp?.data;
        setTimeout(() => {
          customToast({ message: response.message, svgImageName: '400_status' })
        }, 400)
        console.log("error", response);
      }
      if (resp.status === 400 && resp.data.error === 'User not found' ) {
        const response = resp?.data;
        setTimeout(() => {
          customToast({ message: response.error, svgImageName: '400_status' })
        }, 400)
        console.log("error", response);
      }


    } catch (error) {
      console.log("Error sending email OTP:", error);
    }
  };
export const SmsOTP = ({ service,mobileno }) => async (dispatch) => {
    try {
      const resp = await api({
        method: otpApiConfig.method,
        url: otpApiConfig.url,
        data: {
          service, mobileno
        },
      });
      if (resp.status === 200) {
        dispatch(smsOtpDispatcher(true))
      }
    } catch (error) {
      console.log("Error sending sms OTP:", error);
    }
  };

  /**
 *  @desc         Verify otp action
 *  @author       Sanjana Manimaran
 *  @contributor   Deepak Prajapati
 *  @api          /otp
 *  @method       POST
 *  @createdDate  May 16, 2023
 *  @modifiedDate May 18, 2023
 **/
export const VerifyOTP = ({ email, otp, mobileno, service ='email' }) => async (dispatch) => {
  try {
    const resp = await api({
      method: otpApiConfig.method,
      url: otpApiConfig.url,
      data: {
        service,
        emailId:email,
        mobileno,
        otp,
      },
    });
    if (resp.status === 200) {
      dispatch(verifyOtpDispatcher(true))
    }
  } catch (error) {
    console.log("Error verifying email OTP:", error);
  }
};

/* Action for resetting isOtpVerified state */
export const SetIsOtpVerifiedState = (bool = false) => async (dispatch) => {
  dispatch(verifyOtpDispatcher(bool))
}

export const SendForgotPasswordOTP = async({ email, channel = 'email', userId }) => {
  try {
    const resp = await api({
      method: API_BOOK.SA_SEND_OTP.method,
      url: API_BOOK.SA_SEND_OTP.url,
      data: {
        purpose: 'forgotPassword',
        data: { email },
        channel,
        userId,
      },
    });
    return resp
  } catch (error) {
    console.log("Error sending email OTP:", error);
  }
};

export const VerifyForgotPasswordOTP = async({ email, otp, channel ='email' }) => {
  try {
    const resp = await api({
      method: API_BOOK.SA_VERIFY_OTP.method,
      url: API_BOOK.SA_VERIFY_OTP.url,
      data: {
        data: { email },
        otp,
        channel,
      },
    });
    return resp;
  } catch (error) {
    console.log("Error verifying email OTP:", error);
  }
};


/* Actions ends here */ 