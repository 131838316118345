import React from 'react'
// import styles from './CALogs.module.css'

const CALogs = () => {
  return (
    <div>CALogs</div>
  )
}

export default CALogs
/*conflict-resolution-will be removed later*/