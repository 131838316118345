import React from 'react'

import './NDTrackingLegendsCard.css'

const vehicleStatus = [
  {
    status: 'Live',
    color: '#15c7a8'
  },
  // Note: Merge Idle status to Live status
  // {
  //   status: 'Idle',
  //   color: '#F29900'
  //   // color: '#E06000'
  // },
  {
    status: 'Parked',
    color: '#E06000'
    // color: '#5491F5'
  },
  {
    status: 'Charging',
    // color: '#22046B'
    color:'#5491F5'
  },
  {
    status: 'Non Communication',
    // color: '#B31E85'
    color: '#DE54E2'
  }
]

const NDTrackingLegendsCard = () => {
  return (
    <div className='nd__tracking-legend-card mb-3'>
      <div className='nd__tlc__ignition-status'>
        <p className='nd__dark-tlc__vehicle-status-text'>Ignition Status</p>
        <div className='d-flex  align-items-baseline  nd__dark-tlc__ignition-on mb-1'>
          <div className='nd__tlc_ignition-box' />
          <p  className='nd__dark-tlc__vehicle-status-text-child'>Ignition On</p>
        </div>
        <div className='d-flex align-items-baseline  nd__dark-tlc__ignition-off mb-1'>
          <div className='nd__tlc_ignition-box' />
          <p className='nd__dark-tlc__vehicle-status-text-child'>Ignition Off</p>
        </div>
        <div className='d-flex align-items-baseline nd__dark-tlc__ignition-no-data'>
          <div className='nd__tlc_ignition-box' />
          <p className='nd__dark-tlc__vehicle-status-text-child'>Non Communication</p>
        </div>
      </div>
      <div className='nd__tlc__divider'>
        <img src="/images/svgicon/trackingScreen/verticleLine.png" style={{minHeight:'130px'}} alt="divider" />
        {/* <img src="/images/svgicon/nd_divider.svg" alt="divider" /> */}
      </div>
      <div className='nd__tlc__vehicle-status'>
        <p className='nd__dark-tlc__vehicle-status' >Vehicle Status</p>
        <div className='nd__tlc__vehicle-status-container'>
          {
            vehicleStatus.map((item, index) => {
              return (
                <div key={`vehicle_status_${index}`} className='tlc__status-container'>
                  <div className='tlc__vehicle-status-icon' style={{ backgroundColor: item.color }}>
                    <span className='tlc__vehicle-status-tip' style={{ borderBottomColor: item.color }}></span>
                  </div>
                  <p>{item.status}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default NDTrackingLegendsCard