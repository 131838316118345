import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import Tabs from '../../shared/ui/Tabs/Tabs' 
import styles from './DADashboard.module.css'
import DepartmentAdminManage from './DepartmentAdminManage/DepartmentAdminManage'
import DADashboard from './DADasboard/DADasboard'
import { useLocation, useNavigate } from 'react-router-dom'

const TAB_HEADERS = [{
    label: 'Dashboard',
    value: 'DASHBOARD',
}, {
    label: 'Manage',
    value: 'MANAGE',
}, {
    label: 'Log',
    value: 'LOG',
}]
const DAAdmin = () => {

    const [activeTab, setActiveTab] = useState(TAB_HEADERS[0].value);

    const location = useLocation()
    const currentPath = location.pathname 

    const navigate = useNavigate()

    const handleTabChange = (value) => {
        switch(value) {
            case 'DASHBOARD':
            navigate('/da/dashboard')
            break;
            case 'LOG':
            navigate('/da/logs')
            break;
            case 'MANAGE':
            navigate('/da/manage')
            break;
            default:
            break
        }
        setActiveTab(value);
    }

    useEffect(() => {

        switch(currentPath) {
            case '/da/dashboard':
            setActiveTab('DASHBOARD');
            navigate('/da/dashboard')
            break;
            case '/da/logs':
            setActiveTab('LOG');
            navigate('/da/logs')
            break;
            case '/da/manage':
            setActiveTab('MANAGE');
            navigate('/da/manage')
            break;
            default:
            break;

        }

    }, [currentPath, navigate])

    return (
        <div className={cx(styles.mainContainer, 'h-100')}>
        <div className='mb-4'>
            <Tabs
                tabHeaders={TAB_HEADERS}
                value={activeTab}
                onChange={handleTabChange}
            />
        </div>
        {
            currentPath === '/da/dashboard'  ? <DADashboard/> : null
        }
        {
            currentPath === '/da/manage' ? <DepartmentAdminManage/> : null
        }
        {
           currentPath === '/da/logs' ? <></> : null
        }
    </div>
    )
}

export default DAAdmin