import React from 'react'

import './VTSeekbar.css'

const VehicleStatusClassNames = {
  "Parked": "vts__vehiclestatus-statusbar--immobile",
  "Live": "vts__vehiclestatus-statusbar--live",
  // Note: Merge Idle status to Live status
  "Idle": "vts__vehiclestatus-statusbar--live",
  "Charging": "vts__vehiclestatus-statusbar--charging",
  "Non Communication": "vts__vehiclestatus-statusbar--ncom"
}

const ignitionStatus = (ig, vs) => {
  // Note: Considering Idle and Live status as Ignition On
  if (['Live', 'Idle'].includes(vs) || ig === 'true') {
    return 'vts__statusbar-slot--on'
  }
  if (ig === 'false') {
    return 'vts__statusbar-slot--off'
  }
  return ''
}

const VTSeekbar = ({ minSliderRange, maxSliderRange, activeSeekbarValue, onSeekbarChange, timelinePointers, routeData = [] }) => {

  const handleSliderChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (onSeekbarChange) {
      onSeekbarChange(value)
    }
  }

  const PlotTimeline = () => {
    return timelinePointers.map((item, index) => {
      let dateTimeLocal = new Date(item)
      let timeArray = dateTimeLocal.toTimeString().split(":")
      let dateArray = dateTimeLocal.toDateString().split(" ")

      const date = `${dateArray[2]}-${dateArray[1]}-${dateArray[3]}`
      const time = `${timeArray[0]}:${timeArray[1]}`
      return (
        <div key={`timeline_${index}`}>
          <p>{time}</p>
          <p>{date}</p>
        </div>
      )
    })
  }
  const seekbarPosition = ((activeSeekbarValue / maxSliderRange) * 100).toFixed(4)
  return (
    <>
      <div className='vts__statusbar-wrapper'>
        <div className='vts__ignition-statusbar'>
          {routeData.map((routeD, index) => (
            <div
              key={`ig_seekbar_${index}`}
              className={`vts__statusbar-slot ${ignitionStatus(routeD?.ig, routeD?.vs)}`}
            ></div>
          ))}
        </div>
        <div className='vts__vehiclestatus-statusbar'>
          {routeData.map((routeD, index) => (
            
              routeD?.nct ? (
                <div key={`seekbar_${index}`} className={`vts__statusbar-slot ${VehicleStatusClassNames['Non Communication']}`}></div>
              ) :
              (
                <div key={`seekbar_${index}`} className={`vts__statusbar-slot ${VehicleStatusClassNames[routeD?.vs]}`}></div>
              )

          ))}
        </div>
      </div>
      <div className='vts__seekbar-wrapper'>
        <div className='vts__range-wrapper'>
          <input type='range'
            className='vts__range-picker'
            min={minSliderRange}
            max={maxSliderRange}
            onChange={handleSliderChange}
            value={activeSeekbarValue}
            id='vehicle-seekbaar-range'
            style={{
              background: `linear-gradient(90deg, rgba(16,32,75,1) ${seekbarPosition}%, rgba(233,232,248,1) ${seekbarPosition}%)`
            }}
            data-testid='vt-seekbar-range'
          />
        </div>
        <div className='vts__seekbar-timeline'>
          <PlotTimeline />
        </div>
      </div>
    </>
  )
}

export default VTSeekbar