import api from "../../../service/api"
import API_BOOK from "../../../service/endpoints";
import { customToast } from '../../../utils/toasts'

const geoFenceGetAllLabelsConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_LABELS
const geoFenceGetAllLabelOwnerConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_LABEL_OWNERS
const geoFenceGetAllFenceOwnerConfig = API_BOOK.GEO_FENCE.MASTERS.GET_ALL_FENCE_OWNERS
const geoFenceSaveLabelConfig = API_BOOK.GEO_FENCE.MASTERS.SAVE_LABEL
const geoFenceSaveFenceOwnerConfig = API_BOOK.GEO_FENCE.MASTERS.SAVE_FENCE_OWNERS
const geoFenceSaveLabelOwnerConfig = API_BOOK.GEO_FENCE.MASTERS.SAVE_LABEL_OWNER


/**
 *  @desc         Telematics API for loading saved labels
 *  @author       Kailash Desiti
 *  @api          geo-fence/configuration/labels
 *  @method       GET
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadSavedLabels = () => async (dispatch) => {
  try {
      const resp = await api({
          method: geoFenceGetAllLabelsConfig.method,
          url: geoFenceGetAllLabelsConfig.url,
        })
      if (resp.status === 200) {
        const response = resp?.data.data;
        dispatch({type: 'loadSavedLabels', payload: response})
      }
    } catch (error) {
      console.log("error:", error);
  }
}


/**
 *  @desc         Telematics API for loading saved label owners
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/label-owners
 *  @method       GET
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadSavedLabelOwners = () => async (dispatch) => {
    try {
        const resp = await api({
            method: geoFenceGetAllLabelOwnerConfig.method,
            url: geoFenceGetAllLabelOwnerConfig.url,
          })
        if (resp.status === 200) {
          const response = resp?.data.data;
          dispatch({type: 'loadSavedLabelOwners', payload: response})
        }
      } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         Telematics API for loading saved fence owners
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/fence-owners
 *  @method       GET
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const loadSavedFenceOwners = () => async (dispatch) => {
    try {
        const resp = await api({
            method: geoFenceGetAllFenceOwnerConfig.method,
            url: geoFenceGetAllFenceOwnerConfig.url,
          })
        if (resp.status === 200) {
          const response = resp?.data.data;
          dispatch({type: 'loadSavedFenceOwners', payload: response})
        }
      } catch (error) {
        console.log("error:", error);
    }
}


/**
 *  @desc         Telematics API for saving labels
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/labels
 *  @method       POST
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const saveLabels = ({
  savedLabels, deletedLabelIds }) => async (dispatch) => {
    try {
      const resp = await api({
        method: geoFenceSaveLabelConfig.method,
        url: geoFenceSaveLabelConfig.url,
        data: {
          savedLabels,
          deletedLabelIds
        }
      });
      if (resp.status === 200) {
          setTimeout(() => {
              customToast({ message: "Successfully Saved", svgImageName: 'promptSuccess' })
          }, resp.status)
          dispatch({type: 'toggleLabelCard', payload: false})
      }
      else{
          setTimeout(() => {
              customToast({ message: resp.data.message, svgImageName: 'decline' })
          }, resp.status)
      }
    } catch (error) {
      console.log("error:", error);
    }
};



/**
 *  @desc         Telematics API for saving Fence owners
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/fence-owners
 *  @method       POST
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/


export const saveFenceOwners = ({
    savedFenceOwners, deletedFenceOwnerIds }) => async (dispatch) => {
      try {
        const resp = await api({
          method: geoFenceSaveFenceOwnerConfig.method,
          url: geoFenceSaveFenceOwnerConfig.url,
          data: {
            savedFenceOwners,
            deletedFenceOwnerIds
          }
        });
        if (resp.status === 200) {
            setTimeout(() => {
                customToast({ message: "Successfully Saved", svgImageName: 'promptSuccess' })
            }, resp.status)
            dispatch({type: 'toggleFenceOwnerCard', payload: false})
        }
        else{
            setTimeout(() => {
                customToast({ message: resp.data.message, svgImageName: 'decline' })
            }, resp.status)
        }
      } catch (error) {
        console.log("error:", error);
      }
};


/**
 *  @desc         Telematics API for saving Label owners
 *  @author       Kailash Desiti
 *  @api          /geo-fence/configuration/label-owners
 *  @method       POST
 *  @createdDate  AUG 26, 2023
 *  @modifiedDate AUG 28, 2023
 **/

export const saveLabelOwners = ({
  savedLabelOwners, deletedLabelOwnerIds }) => async (dispatch) => {
    try {
      const resp = await api({
        method: geoFenceSaveLabelOwnerConfig.method,
        url: geoFenceSaveLabelOwnerConfig.url,
        data: {
          savedLabelOwners,
          deletedLabelOwnerIds
        }
      });
      if (resp.status === 200) {
          setTimeout(() => {
              customToast({ message: "Successfully Saved", svgImageName: 'promptSuccess' })
          }, resp.status)
          dispatch({type: 'toggleLabelOwnerCard', payload: false})
      }
      else{
          setTimeout(() => {
              customToast({ message: resp.data.message, svgImageName: 'decline' })
          }, resp.status)
      }
    } catch (error) {
      console.log("error:", error);
    }
};


export const openLabelCard = (isOpened) => async (dispatch) => {
  dispatch({type: 'toggleLabelCard', payload: isOpened})
}

export const openFenceOwnerCard = (isOpened) => async (dispatch) => {
    dispatch({type: 'toggleFenceOwnerCard', payload: isOpened})
}

export const openLabelOwnerCard = (isOpened) => async (dispatch) => {
  dispatch({type: 'toggleLabelOwnerCard', payload: isOpened})
}

export const deleteLabel = (labelId, key) => async (dispatch) => {
  
}