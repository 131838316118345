import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./RaUserManagement.css";
import UserManagement from "./RaUser/RaUser";
import PendingSignUpUser from "./PendingSignUpUser/PendingSignUpUser";

// Functional Component
const RaUserManagement = () => {
  const [activeTab, setActiveTab] = useState('ra_user');
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };
  return (
    <div className='col-12 mian-container-user-management'>
    <Tabs
      className="ra-user"
      defaultActiveKey="ra_user"
      id="uncontrolled-tab-example"
      activeKey={activeTab}
      onSelect={handleTabSelect}
    >
      <Tab eventKey="ra_user" title="RA User">
        <UserManagement></UserManagement>
      </Tab>
      <Tab eventKey="pending_sign_up" title="Pending Signup">
        <PendingSignUpUser activeTab={activeTab}></PendingSignUpUser>
      </Tab>
    </Tabs>
    </div>
  );
};

export default RaUserManagement;
