import React, {  useEffect, useState, useCallback } from 'react';
import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BsDownload, BsX } from "react-icons/bs";
import './ModalReport.css'
import Pagination from '../../../../../helpers/hoc/paginator/Pagination';
import { BiInfoCircle } from 'react-icons/bi';
import { connect } from 'react-redux';
import { GetAllChargingSessionByOemModelOrCity,ExportAllChargingSessionByOemModelOrCity } from '../../../../../../store/actions/reports/charging-analytics'
import { getFormattedDate, secondsToHoursAndMinutesHHMM,addEscapeKeyListener } from '../../../../../../utils/common-methods';
import { formatter } from '../../../../../../utils/formatter';


const ModalReport = (
  { showModal,
    setIsShowModal,
    chargingReportData,
    reportFilter,
    GetAllChargingSessionByOemModelOrCity,
    ExportAllChargingSessionByOemModelOrCity,
    title=''
  }) => {

  const [currentPage,setCurrentPage] = useState()
  const handleClose = useCallback(() => {
    setIsShowModal(false);
  }, [setIsShowModal]);

  const CustomCloseButton = ({ onClick }) => {
    return (
      <div className="popup-close" onClick={onClick}>
        <BsX size={24} />
      </div>
    );
  };

  useEffect(() => {
    const removeListener = addEscapeKeyListener(() => {
      handleClose();
    });

    return () => {
      removeListener();
    };
  }, [handleClose]);

  function renderTooltipUnresolved(props) {
    return (
      <Tooltip className="tooltip" id="button-tooltip" {...props}>
        {props.parameter1}: {props.start}<br />
        {props.parameter2}: {props.end}
        {props?.unit ? props.unit : ''}
      </Tooltip>
    )
  }

  const exportToExcel = ()=>{
    ExportAllChargingSessionByOemModelOrCity(reportFilter) 
  }

  const setReportCurrentPage = (pageNumber)=>{
    reportFilter.pageIndex = pageNumber - 1
    setCurrentPage(pageNumber);
  }

  useEffect(()=>{
    setCurrentPage(reportFilter.pageIndex+1);
    GetAllChargingSessionByOemModelOrCity(reportFilter)
  },[reportFilter,currentPage,GetAllChargingSessionByOemModelOrCity])
  

  useEffect(()=>{
    setCurrentPage(reportFilter.pageIndex+1);
  },[reportFilter.pageIndex])
  
  return (
    <>
      <Modal
        onHide={handleClose}
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
         <Modal.Header closeButton={false} className="modal-header"
         style={{
          display:'flex',
          flexDirection:'row-reverse'
         }}
         >
          <CustomCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className='oem-trip-report-body'>
          <div className="header-container- d-flex justify-content-between">
            <div className="title w-100 mr-2">
              <div className="row" style={{ height: "85px" }}>
                <div className="col-3 d-inline-flex align-items-center"
                style={{
                  width: 'fit-content'
                }}
                >
                  <h5 style={{marginTop:'24px', marginLeft:'17px'}}>{title}</h5>
                </div>
              </div>
            </div>
            <div className="download-btn">
              <button
              style={{position:'absolute',    top: '24px',
              right:' 34px'}}
                className="dwn-btn"
                onClick={exportToExcel}
              >
                <BsDownload  style={{width:'22px',height:'22px'}}/>
              </button>
            </div>
          </div>
          <div className="oem-trip-report-box mb-4 mt-4 position-relative" style={{margin:'0 17px'}}>
            <Table responsive={true} borderless id="oem-trip-report-model" className="oem-trip-report-table-header">
              <thead className="table-header">
                <th className="ps-3 text-nowrap"><p className='fw500'>S.No</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Vehicle No.</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>OEM & Model</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>City</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Charging Duration</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Energy Charged</p></th>
                <th className="ps-3 text-nowrap"><p className='fw500'>Charging Type</p></th>
              </thead>
              <tbody>
                {chargingReportData?.rows?.map((Obj, index) => (
                  <tr key={"row-" + index.toString()}>
                    <td className="ps-3">
                    {8 * (currentPage - 1) + index + 1}
                    </td>
                    <td>{Obj.registration_number}</td>
                    <td>{Obj.oem + " " + Obj.model}</td>
                    <td>{Obj.city}</td>
                    <td>
                      <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                        {secondsToHoursAndMinutesHHMM(Obj?.duration)}

                        <OverlayTrigger
                          placement="auto-end"
                          delay={{ show: 0, hide: 0 }}
                          overlay={renderTooltipUnresolved({
                            parameter1: 'Start Time',
                            parameter2: 'End Time',
                            start: getFormattedDate(Obj.startDateTime),
                            end: getFormattedDate(Obj.endDateTime),
                          })}
                        >
                          <button className="row-btn">
                            <BiInfoCircle className="row-i-icon-trip-report" />
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-row gap-1 align-items-center overflow-hidden justify-content-center">
                        {`${Obj?.energyUtilized} kWh`}

                        <OverlayTrigger
                          placement="auto-end"
                          delay={{ show: 0, hide: 0 }}
                          overlay={renderTooltipUnresolved({
                            parameter1: 'Start State of Charge',
                            parameter2: 'End State of Charge',
                            start: formatter.withUnitOrDefault({ value: Obj.startSoC }),
                            end: formatter.withUnitOrDefault({ value: Obj.endSoC })
                          })}
                        >
                          <button className="row-btn">
                            <BiInfoCircle className="row-i-icon-trip-report" />
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <td>{Obj.chargingType}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              disabled={false}
              totalPages={chargingReportData?.count ? Math.ceil(chargingReportData?.count / 8)  : 0}
              currentPage={currentPage}
              setPage={setReportCurrentPage}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  chargingReportData: state.chargingAnalytics.modalData
});

export default connect(mapStateToProps, {
  GetAllChargingSessionByOemModelOrCity,
  ExportAllChargingSessionByOemModelOrCity
})(ModalReport);
