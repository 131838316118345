export const setEgressApplicable = (flag) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.egressSettingsApplicable', payload: flag})
}

export const setEgressTimeForFullDay = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressTimeForFullDay'})
}

export const setEgressTimeCustomized = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressTimeCustomized'})
}

export const setEgressStartTimeHr = (hr) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressStartTimeHr', payload: hr})
}

export const setEgressStartTimeMinute = (minute) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressStartTimeMinute', payload: minute})
}

export const setEgressEndTimeHr = (hr) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressEndTimeHr', payload: hr})
}

export const setEgressEndTimeMinute = (minute) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressEndTimeMinute', payload: minute})
}

export const setEgressForAllVehicles = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressForAllVehicles'})
}

export const setEgressForCustomizedVehicles = () => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setEgressForCustomizedVehicles'})
}

export const setSelectedVehiclesForEgress = (selectedVehicles) => (dispatch) =>{
    dispatch({type: 'geofenceAlertConfig.egressSettings.setSelectedVehiclesForEgress', payload: selectedVehicles})
}