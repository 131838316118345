import React, { useState } from 'react';
import './LogoutModalDark.css';

const LogoutModalDark = ({ isVisible, closeModal, logout }) => {

    const [hoverCloseIcon, setHoverCloseIcon] = useState(false);

  return isVisible && (
    <div className="nd-dark nd-dark-logout-modal-container">

      <div className="logout-modal">
      <img className='logout-cross-icon'
      style={{ width: '35px', cursor: "pointer"  ,position: 'absolute',
      right: '0',
      top: '0'}}
      onClick={closeModal}
      src={hoverCloseIcon ? '/images/svgicon/trackingScreen/close_active.svg' : "/images/svgicon/trackingScreen/Close_fad.svg"}
      alt='close-btn'
      onMouseOver={() => setHoverCloseIcon(true)}
      onMouseOut={() => setHoverCloseIcon(false)}
    />
        <img  src='/images/illustration.svg' alt='illustration' width={120} height={120}/>
        <span className="modal-text">Are  you sure ?</span>
        <span className="modal-text-confirm">Please confirm if you want to logout</span>

        <div className="button-container">
          <span className="modal-button no" onClick={closeModal}>No</span>
          <span className="modal-button yes" onClick={logout}>Yes</span>
        </div>
      </div>
    </div>
  );
};

export default LogoutModalDark;
