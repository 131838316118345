const initialState = {
    label: '',
  };
  
  const topFilterLabelReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case "topFilterLabel":
        return {
          ...state,
          label: payload,
        };
      default:
        return state;
    }
  };
  
  export default topFilterLabelReducer;
  