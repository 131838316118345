import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ProtectedMenus, DEPARTMENT_USER, DEPARTMENT_ADMIN } from '../../../utils/constants';
import SubMenuPopupDark from '../subMenuPopup/subMenuPopupDark';
import { useDispatch } from 'react-redux';
import dashboardAction from '../../../store/actions/dashboard/dashboard';

const getInitials = function (name = '') {
  const parts = name.split(' ')
  let initials = ''

  if (parts.length) {
    if (parts.length === 1) {
      initials = parts[0][0]
    } else {
      for (let i = 0; i < 2; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
          initials += parts[i][0]
        }
      }
    }
  }
  
  return initials
}

const DarkSidebar = () => {
  const initialSubMenuState = {
    menuIndex: 0,
    items: [],
    showPopup: false,
    itemId: null,
  };
  const [subMenuState, setSubMenuState] = useState(initialSubMenuState)
  const [menuItems, setMenuItems] = useState([])
  const localUser = JSON.parse(localStorage.getItem('user'))
  const userRole = localUser?.userRole
  const nameInitials = getInitials(localUser?.name)
  const activePagePath = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    let menuItemss = [];
    if (userRole === DEPARTMENT_USER) {
      menuItemss = ProtectedMenus.filter((x) => (x.isPublic || localUser?.menus?.includes(x.id)) && x.name !== 'Admin' )
    }
    else if (userRole === DEPARTMENT_ADMIN) {
      menuItemss = ProtectedMenus.filter((x) => (x.isPublic || localUser?.menus?.includes(x.id)));
   }
    else {
      menuItemss = ProtectedMenus.filter((x) => x.isPublic || localUser?.menus?.includes(x.id));
    }
    setMenuItems(menuItemss);
    // eslint-disable-next-line
  }, [])
  
  const showSubMenu = (id, items, isDisabled, itemId) => {
    if (items && items.length > 0 && !isDisabled) {
      setSubMenuState({
        ...subMenuState,
        menuIndex: id,
        showPopup: true,
        items: items,
        itemId: itemId,
      });
    }
  };

	const openSubMenu = () => {
		setSubMenuState({
			...subMenuState,
			showPopup: true,
		});
	};

  const hideSubMenu = () => {
    setSubMenuState({
      ...subMenuState,
      showPopup: false,
    });
  };
  const calculateSubMenuArg = useCallback((item, index) => {
    let subMenuArg;
   // when taskbar is shown screen size 150%
    if (window.innerWidth >= 1280 && window.innerWidth < 1460) {
      subMenuArg = item.name === 'User Profile' ? 8 : index + 1;
    } 
	// when taskbar is shown screen size 125%
	else if (window.innerWidth >= 1500 && window.innerWidth < 1600) {
      subMenuArg = item.name === 'User Profile' ? 8 : index + 1;
    } 
	// when taskbar is shown screen size 100%
	else if (window.innerWidth >= 1700 && window.innerWidth < 1950) {
      subMenuArg = item.name === 'User Profile' ? 11.7 : index + 1;
    }

    return subMenuArg;
  }, []);

  const handleNavLinkClick = (item) => {
    if (item.name === 'Home') {
      dispatch(dashboardAction.updateHomeClickCounter());
    }
  }

  return (
    <>
      <div className='dark-sidebar-container'>
        <div className='dark-sidebar-logo'>
            <img
              src='/images/darkLayout/Logo.svg'
              alt='Logo'
              data-testid='header-logo-link'
              onClick={() => {
                navigate('/');
              }}
            />
        </div>
        <div className='dark-sidebar'>
            <div className='sidebar-routes'>
            {
              menuItems.map((item, index) => (
                !item.isDarkHidden &&
                <NavLink
                  disabled={item.isDisabled}
                  to={item?.submenu ? item?.submenu?.[0]?.path : item?.path}
                  onClick={() => handleNavLinkClick(item)}
                  key={index}
                  onMouseEnter={() => {
                    const subMenuArg = calculateSubMenuArg(item, index);
                    showSubMenu(subMenuArg, item.submenu?.filter(x => x.isMenu), item.isDisabled, item.id);
                  }}
                  className={`dark_link ${activePagePath.pathname === '/' && item.path === '/' ? 'dark_navlink__active' : ''
                    } ${activePagePath.pathname.includes(item.path) && item.path !== '/'
                      ? 'dark_navlink__active'
                      : ''
                    } ${item.name === 'User Profile' ? 'profile-navlink' : ''}`}
                  onMouseLeave={() => hideSubMenu()}

                  data-testid={`sidebar-menu-link-${item.name}`}
                >
                  {/* <div style={{zIndex:'2'}}> */}
                    <div className='icon popover-trigger'>
                      {item.name === 'User Profile' ? (
                        <>
                          <span className='profile-avatar'>{nameInitials}</span>
                        </>
                      ) : (
                        <img
                          alt={item.name}
                          src={item.darkIcon}
                          className='header-magenta-logo'
                          style={{ width: '75%' }}
                        ></img>
                      )}
                    </div>
                    {item.name !== 'User Profile' && <span className='navlink-text'>{item.name}</span>}
                  {/*
                  </div> 
                   {activePagePath.pathname === '/' && item.path === '/' ? 
                   <img src='/images/rightSideBar/active.svg' alt='activeBg' style={{ position: 'relative', left: '5px', top: '-120px', width: "88px", zIndex:'1' }} />
                  :''
                  } */}

                </NavLink>
              ))
            }
            </div>
        </div>
      </div>
      <SubMenuPopupDark
				onMouseEnter={openSubMenu}
				onMouseLeave={hideSubMenu}
				id={subMenuState.menuIndex}
        menuId={subMenuState.itemId}
				items={subMenuState.items}
				showPopup={subMenuState.showPopup}
			></SubMenuPopupDark>
    </>
  )
}

export default DarkSidebar
/*conflict-resolution-will be removed later*/