import React from "react";
import { Row, Col} from 'react-bootstrap'
import FilterView from "./FilterView";
import DataView from "./DataView";

const FenceSelectDiv = ()=> {
    return (
        <Row>
            <Col className="shadow-sm p-3 mb-5 bg-white">
                <FilterView></FilterView>
                <DataView></DataView>
            </Col>
        </Row>
    )
}

export default FenceSelectDiv