import React, { useState } from "react";

import Tabs from "../../../shared/ui/Tabs/Tabs";
import UserControl from "./UserControl/UserControl";

import DAPendingRequests from "./Pending Requests/PendingRequests";

const DepartmentAdminManage = () => {


    const TAB_HEADERS = [{
        label: 'Pending Request',
        value: 'Pending_Request',
      }, {
        label: 'User Control',
        value: 'User_Control',
      }]

      const [activeTab, setActiveTab] = useState(TAB_HEADERS[0].value);

  const handleTabChange = (value) => {
    setActiveTab(value);
  }
    return (
        <>
          <div className='mb-4 px-4 py-3'
          style={{ 
            width:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between'
          }}
          >

            <div
             style={{
              width: '100%'
          }}
          
            >
            <Tabs
              tabHeaders={TAB_HEADERS}
              value={activeTab}
              onChange={handleTabChange}
              variant='secondary'
            />
            </div>
          

          </div>
          {
            activeTab === TAB_HEADERS[0].value ? <DAPendingRequests /> : null
    
          }
          {
            activeTab === TAB_HEADERS[1].value ? <UserControl /> : null
          }
        </>
      )
}

export default DepartmentAdminManage