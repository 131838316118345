import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./geoFencDashboard.css";
import {
  GetGeoFanceType,
  GetGeoFanceLabel,
  GetGeoFanceLabelOwner,
  GetGeoFanceCity,
  ToggleSelectGeoFenceCard,
} from "../../../store/actions/geo-fence/dashboardGeoFence";
import { connect } from "react-redux";
import {
  FeatureGroup,
  TileLayer,
  MapContainer,
  Circle,
  Rectangle,
  Polygon,
  Marker,
  Tooltip,
} from "react-leaflet";
import { Accordion } from "react-bootstrap";
import DashBoardCard from "./child-geofence/dashboardCard/dashboardCard";
import DashBoardDetailsCard from "./child-geofence/detailsCard/detailsCard";
import filterIcon from "../../../assets/GeoFencingIcons/filter.svg";
import { Icon } from "leaflet";
import { useCallback } from "react";
import Loader from "../../helpers/hoc/loader/Loader";
import { useNavigate } from "react-router-dom";
import VehiclesDetails from "./child-geofence/vehicleDetailsModel/vehicleDetailsModel";
import { useRef } from "react";
import { IoIosCheckbox, IoIosSquareOutline } from "react-icons/io";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const styles = [
  // reset Maps style
  {
    backgroundColor: "#FFFFFF",
    position: "fixed",
    zIndex: 400,
    top: "15%",
    right: "2%",
    padding: 3,
    borderRadius: 4,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
  },
  {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    position: "fixed",
    zIndex: "400",
    top: "22%",
    right: "2%",
    padding: "5px 3px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  {
    backgroundColor: "#FFFFFF",
    position: "fixed",
    zIndex: 400,
    top: "32%",
    right: "2%",
    padding: 3,
    borderRadius: 4,
    paddingTop: 5,
    paddingBottom: 5,
  },
];

const GeofanceDashboard = ({
  GetGeoFanceType,
  GetGeoFanceLabel,
  GetGeoFanceLabelOwner,
  GetGeoFanceCity,
  geoFenceTypeData,
  geoFenceLabelData,
  geoFenceLabelOwnerData,
  geoFenceCityData,
  ToggleSelectGeoFenceCard,
  selectedGeoFenceCard,
  refetchGeoFenceDataCount,
  isLoading,
}) => {
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [selectedLabelName, setSelectedLabelName] = useState([]);
  const [profileModal, setProfileModal] = useState(false);
  const [isVehicleDetialModalOpen, setVehicleDetialModalOpen] = useState(false);
  const [vehicleDetailsCardData, setVehicleDetailsCardData] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("");
  const toggleVehicleDetialModal = (e, data = null) => {
    setVehicleDetialModalOpen((prev) => !prev);
    setVehicleDetailsCardData(data);
  };
  const [mapConfig, setMaConfig] = useState({
    maxZoom: 22,
    minZoom: 5,
    currentZoom: 5,
  });
  const getGeoFenceData = useCallback(
    (skipResetSelected) => {
      GetGeoFanceType({
        data: {
          searchKey: searchKey,
          labelIds: selectedLabel,
          labelOwnerIds: selectedLabelName,
          cities: selectedCity,
        },
      });
      if (!skipResetSelected) {
        setProfileModal(false);
        ToggleSelectGeoFenceCard(null);
      }
    },
    [
      GetGeoFanceType,
      ToggleSelectGeoFenceCard,
      searchKey,
      selectedLabel,
      selectedLabelName,
      selectedCity,
    ]
  );

  const handle = useFullScreenHandle();

  React.useEffect(() => {
    if (refetchGeoFenceDataCount > 0) {
      getGeoFenceData(true);
    }
  }, [refetchGeoFenceDataCount, getGeoFenceData]);

  const toggleCitySelect = (city) => {
    setSelectedCity((prev) =>
      prev.indexOf(city) > -1 ? prev.filter((c) => c !== city) : [...prev, city]
    );
  };
  const togglelabelSelect = (label) => {
    setSelectedLabel((prev) =>
      prev.indexOf(label) > -1
        ? prev.filter((c) => c !== label)
        : [...prev, label]
    );
  };
  const togglelabelSelectName = (labelName) => {
    setSelectedLabelName((prev) =>
      prev.indexOf(labelName) > -1
        ? prev.filter((c) => c !== labelName)
        : [...prev, labelName]
    );
  };

  const handleEdit = (id) => {
    navigate(`/geo-fence/edit/${id}`);
  };

  useEffect(() => {
    getGeoFenceData();
  }, [
    GetGeoFanceType,
    ToggleSelectGeoFenceCard,
    getGeoFenceData,
    searchKey,
    selectedLabel,
    selectedLabelName,
  ]);
  let geoFenceDashboardsideMapRef = useRef(null);

  function resetMapView() {
    geoFenceDashboardsideMapRef?.current.setView([20.5937, 78.9629], 5);
  }

  useEffect(() => {
    if (
      selectedGeoFenceCard?.center_coordinates?.length &&
      geoFenceDashboardsideMapRef?.current
    ) {
      geoFenceDashboardsideMapRef?.current.setView(
        [
          selectedGeoFenceCard?.center_coordinates[0],
          Number(selectedGeoFenceCard?.center_coordinates[1]) - 0.025,
        ],
        13
      );
    }
    if (!profileModal && geoFenceDashboardsideMapRef?.current) {
      geoFenceDashboardsideMapRef?.current.setView([20.5937, 78.9629], 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeoFenceCard]);

  useEffect(() => {
    GetGeoFanceLabel();
  }, [GetGeoFanceLabel]);

  useEffect(() => {
    GetGeoFanceLabelOwner();
  }, [GetGeoFanceLabelOwner]);

  useEffect(() => {
    GetGeoFanceCity();
  }, [GetGeoFanceCity]);

  const toggleProfileModal = (e, data = null) => {
    setProfileModal((prev) => !prev);
    ToggleSelectGeoFenceCard(data);
  };

  const openProfileModal = (e, data = null) => {
    setProfileModal(true);
    ToggleSelectGeoFenceCard(data);
  };

  const customIcon = new Icon({
    iconUrl: require("../../../assets/GeoFencingIcons/shapes/pinPoint.png"),
    iconRetinaUrl: require("../../../assets/GeoFencingIcons/shapes/pinPoint.png"),
    iconSize: [30, 30],
  });

  function GeoShapes({ data }) {
    if (
      !(
        data?.fence_geometry.type &&
        data?.fence_geometry?.coordinates?.length &&
        data?.fence_geometry?.area?.value &&
        data?.center_coordinates?.length &&
        data?.fence_identifiers?.city &&
        data?.fence_name
      )
    ) {
      return <></>;
    }

    switch (data.fence_geometry.type) {
      case "circle":
        return (
          <>
            <Circle
              center={data.fence_geometry.coordinates}
              radius={data?.fence_geometry?.area?.value}
            >
              <Tooltip permanent> {data.fence_name}</Tooltip>
            </Circle>
            <Marker
              position={data.center_coordinates}
              icon={customIcon}
            ></Marker>
          </>
        );
      case "rectangle":
        return (
          <>
            <Rectangle
              bounds={data.fence_geometry.coordinates}
              radius={data?.fence_geometry?.area?.value}
            >
              <Tooltip permanent> {data.fence_name}</Tooltip>
            </Rectangle>
            <Marker
              position={data.center_coordinates}
              icon={customIcon}
            ></Marker>
          </>
        );
      case "polygon":
        return (
          <>
            <Polygon
              positions={data.fence_geometry.coordinates}
              radius={data?.fence_geometry?.area?.value}
            >
              <Tooltip permanent> {data.fence_name}</Tooltip>
            </Polygon>
            <Marker
              position={data.center_coordinates}
              icon={customIcon}
            ></Marker>
          </>
        );
      default:
        return <></>;
    }
  }

  const handleZoomIn = () => {
		if (mapConfig.maxZoom > mapConfig.currentZoom) {
			geoFenceDashboardsideMapRef?.current?.setZoom(geoFenceDashboardsideMapRef?.current.getZoom() + 1);
			setMaConfig({
				...mapConfig,
				currentZoom: mapConfig.currentZoom + 1,
			});
		}
	};

	const handleZoomOut = () => {
		if ( mapConfig.minZoom < mapConfig.currentZoom) {
			geoFenceDashboardsideMapRef?.current?.setZoom(geoFenceDashboardsideMapRef?.current.getZoom() - 1);

			setMaConfig({
				...mapConfig,
				currentZoom: mapConfig.currentZoom - 1,
			});
		}
	};


  return (
    <>
      <div
        className="row geofnece-dashboard-row"
        style={{
          height: "Calc(100vh - 72px)",
          width: "100%",
          overflow: "hidden",
          margin: 0,
        }}
      >
        <div className="col-4 col-md-4 col-lg-4 geofnece-container">
          <div className="drawer-container-geofence ms-2  ">
            <div className="col-12">
              <div className="heading-container mt-3 mb-3">
                <div className="main-title-dashboard">Dashboard</div>
              </div>
            </div>
            <div className="col-md-12 d-flex ">
              <div className={`col-md-10 ${!searchKey? 'no-value-selected-input' : ''}`}>
                <div className="search-bar-div-container-geofence search-input">
                  <FaSearch className="vob-search-icon search-input" />
                  <input
                    type="text"
                    className="vob-search-input search-input"
                    onChange={(e) => {
                      setSearchKey(e.target.value);
                    }}
                    data-testid='dashboard-search-input'
                  />
                </div>
              </div>
              <div className="col-md-2 d-flex justify-content-end">
                <button
                  className="dwn-btn filter-icon"
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                  data-testid='filter-dropdown-button'
                >
                  <img src={filterIcon} alt="" title="" />
                </button>
              </div>
              {isDropdownOpen && (
                <div className="geofence-dropdown-content dropdown-custom-style-geofence">
                  <Accordion className="acc">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header data-testid='city-filter-collapse' className="custom-header-geofence">
                        City
                      </Accordion.Header>
                      <Accordion.Body>
                        {geoFenceCityData.map((cityData, index) => {
                          return (
                            <>
                              <div className=" geofance">
                                {!!selectedCity.includes(cityData) ? (
                                  <IoIosCheckbox
                                    color="#1f2a5d"
                                    size={25}
                                    onClick={() => {
                                      toggleCitySelect(cityData);
                                    }}
                                    data-testid={`city-filter-checkbox-${cityData}`}
                                  />
                                ) : (
                                  <IoIosSquareOutline
                                    color="#707070"
                                    size={25}
                                    onClick={() => {
                                      toggleCitySelect(cityData);
                                    }}
                                    data-testid={`city-filter-checkbox-${cityData}`}
                                  />
                                )}
                                <div className=" geofance_box">
                                  <p className="text-style-accordian">
                                    {cityData}
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr></hr>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header data-testid='label-filter-collapse' className="custom-header-geofence">
                        Label
                      </Accordion.Header>
                      <Accordion.Body>
                        {geoFenceLabelData.map((labelData, index) => (
                          <div className=" geofance">
                            {!!selectedLabel.includes(labelData._id) ? (
                              <IoIosCheckbox
                                color="#1f2a5d"
                                size={25}
                                onClick={() => {
                                  togglelabelSelect(labelData._id);
                                }}
                                data-testid={`label-filter-checkbox-${labelData.label}`}
                              />
                            ) : (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  togglelabelSelect(labelData._id);
                                }}
                                data-testid={`label-filter-checkbox-${labelData.label}`}
                              />
                            )}
                            <div className=" geofance_box">
                              <p className="text-style-accordian">
                                {labelData.label}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr></hr>
                    <Accordion.Item eventKey="3" className="acc">
                      <Accordion.Header data-testid='labelowner-filter-collapse' className="custom-header-geofence">
                        Label Owner
                      </Accordion.Header>
                      <Accordion.Body>
                        {geoFenceLabelOwnerData.map((labelOwnerData, index) => (
                          <div className=" geofance">
                            {!!selectedLabelName.includes(
                              labelOwnerData._id
                            ) ? (
                              <IoIosCheckbox
                                color="#1f2a5d"
                                size={25}
                                onClick={() => {
                                  togglelabelSelectName(labelOwnerData._id);
                                }}
                                data-testid={`labelowner-filter-checkbox-${labelOwnerData.label}`}
                              />
                            ) : (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  togglelabelSelectName(labelOwnerData._id);
                                }}
                                data-testid={`labelowner-filter-checkbox-${labelOwnerData.label}`}
                              />
                            )}
                            <div className=" geofance_box">
                              <p className="text-style-accordian">
                                {labelOwnerData.label}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            </div>
            <div className="overflow-geofence">
              {geoFenceTypeData.map((DetailsCardData, index) => (
                <DashBoardCard
                  key={index}
                  DetailsCardData={DetailsCardData}
                  onCardClick={(e, itemData) => {
                    openProfileModal(e, itemData);
                  }}
                  toggleVehicleDetialModal={toggleVehicleDetialModal}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-8 col-md-8 col-lg-8 p-0">
          {profileModal ? (
            <>
              <DashBoardDetailsCard
                data={selectedGeoFenceCard}
                onClose={(e) => {
                  toggleProfileModal(e);
                  e.stopPropagation();
                }}
                onEdit={(id) => {
                  handleEdit(id);
                }}
                toggleVehicleDetialModal={toggleVehicleDetialModal}
              />
            </>
          ) : (
            <></>
          )}

          {isVehicleDetialModalOpen && (
            <VehiclesDetails
              fenceId={vehicleDetailsCardData._id}
              fenceNameData={vehicleDetailsCardData?.fence_name}
              showMoal={isVehicleDetialModalOpen}
              setIsShowModal={toggleVehicleDetialModal}
            />
          )}
          <FullScreen key={"gsm-map-fs"} handle={handle}>
            <MapContainer
              attributionControl={false}
              center={[20.5937, 78.9629]}
              zoom={5}
              className="map-container-geofence"
              ref={geoFenceDashboardsideMapRef}
            >
              <TileLayer
                        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                        subdomains={['mt0','mt1','mt2','mt3']}                        
                    />
              {geoFenceTypeData.length && (
                <FeatureGroup
                  pathOptions={{
                    color: "#1492E6",
                    fillColor: "#1492E633",
                    opacity: 1,
                    fillOpacity: 1,
                    weight: 1,
                  }}
                >
                  {selectedGeoFenceCard ? (
                    <GeoShapes data={selectedGeoFenceCard} />
                  ) : (
                    geoFenceTypeData.map((data, index) => (
                      <GeoShapes key={index} data={data} />
                    ))
                  )}
                </FeatureGroup>
              )}

              <>
                <span
                  style={styles[0]}
                  onClick={() => {
                    resetMapView();
                  }}
                  data-testid='map-reset-button'
                  data-toggle="tooltip"
                  title="Reset"
                >
                  <img alt="resetBtn" src="/images/svgicon/map-reset.svg" />
                </span>

                <div style={styles[1]}>
                  <img
                    data-toggle="tooltip"
                    title="zoom in"
                    alt="downloadBtn"
                    src="/images/svgicon/map_zoom_in.svg"
                   style={{cursor: mapConfig.currentZoom !== mapConfig.maxZoom ? 'pointer' : 'not-allowed' }}

                    onClick={handleZoomIn}
                    data-testid='map-zoomin-button'
                  />

                  <img
                    data-toggle="tooltip"
                    title="zoom out"
                    alt="downloadBtn"
                    src="/images/svgicon/map_zoom_out.svg"
                    style={{  cursor: mapConfig.currentZoom !== mapConfig.minZoom  ? 'pointer' : 'not-allowed' }}
                    onClick={handleZoomOut}
                    data-testid='map-zoomout-button'
                  />
                </div>

                <span
                  style={styles[2]}
                  onClick={() => {
                    if (handle.active) {
                      handle.exit();
                    } else {
                      handle.enter();
                    }
                  }}
                  data-testid='map-fullscreen-toggle'
                >
                  <img
                    handle={handle}
                    data-toggle="tooltip"
                    title={handle.active ? "exit full screen" : "full screen"}
                    style={{ height: "24px" }}
                    alt="resetBtn"
                    src={
                      "/images/svgicon/" +
                      (handle.active
                        ? "full-screen-exit.svg"
                        : "map-fullscreen.svg")
                    }
                  />
                </span>
              </>
            </MapContainer>
          </FullScreen>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  geoFenceTypeData: state.dashBoardGeoFance.geoFenceTypeData,
  geoFenceLabelData: state.dashBoardGeoFance.geoFenceLabelData,
  geoFenceLabelOwnerData: state.dashBoardGeoFance.geoFenceLabelOwnerData,
  geoFenceCityData: state.dashBoardGeoFance.geoFenceCityData,
  selectedGeoFenceCard: state.dashBoardGeoFance.selectedGeoFenceCard,
  refetchGeoFenceDataCount: state.dashBoardGeoFance.refetchGeoFenceDataCount,
  isLoading: state.loader.isLoading,
});

export default connect(mapStateToProps, {
  GetGeoFanceType,
  GetGeoFanceLabel,
  GetGeoFanceLabelOwner,
  GetGeoFanceCity,
  ToggleSelectGeoFenceCard,
})(GeofanceDashboard);
