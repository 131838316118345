import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import CADashboard from './CADashboard/CADashboard'
import CAManage from './CAManage/CAManage'
import CALogs from './CALogs/CALogs'
import Tabs from '../../shared/ui/Tabs/Tabs'

import styles from './CADashboard/CADashboard.module.css'
import { useLocation, useNavigate } from 'react-router-dom'

const TAB_HEADERS = [{
    label: 'Dashboard',
    value: 'DASHBOARD',
}, {
    label: 'Manage',
    value: 'MANAGE',
}, {
    label: 'Log',
    value: 'LOG',
}]

const CAAdmin = () => {

    const [activeTab, setActiveTab] = useState(TAB_HEADERS[0].value);

    const location = useLocation()
    const currentPath = location.pathname 

    const navigate = useNavigate()

    const handleTabChange = (value) => {
        switch(value) {
            case 'DASHBOARD':
            navigate('/ca/dashboard')
            break;
            case 'LOG':
            navigate('/ca/logs')
            break;
            case 'MANAGE':
            navigate('/ca/manage')
            break;
            default:
            navigate('/ca/dashboard')
            break
        }
        setActiveTab(value);
    }

    useEffect(() => {

        switch(currentPath) {
            case '/ca/dashboard':
            setActiveTab('DASHBOARD');
            navigate('/ca/dashboard')
            break;
            case '/ca/logs':
            setActiveTab('LOG');
            navigate('/ca/logs')
            break;
            case '/ca/manage':
            setActiveTab('MANAGE');
            navigate('/ca/manage')
            break;
            default:
            navigate('/ca/dashboard')
            break;

        }

    }, [currentPath, navigate])

    return (
        <div className={cx(styles.mainContainer, 'h-100')}>
        <div className='mb-4'>
            <Tabs
                tabHeaders={TAB_HEADERS}
                value={activeTab}
                onChange={handleTabChange}
                variant='default'
            />
        </div>
        {
            currentPath === '/ca/dashboard' ? <CADashboard/> : null
        }
        {
            currentPath === '/ca/manage' ? <CAManage/> : null
        }
        {
            currentPath === '/ca/logs' ? <CALogs/> : null
        }
    </div>
    )
}

export default CAAdmin
/*conflict-resolution-will be removed later*/