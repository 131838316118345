import api from "../../service/api";
import API_BOOK from "../../service/endpoints";
import { CipherGenerator } from "../../utils/crypto";
import ActionTypes from "../actionTypes";
import { customToast } from '../../utils/toasts'

const registerUserApiConfig = API_BOOK.REGISTER_USER;
const raActionApiConfig = API_BOOK.RA_ACTION;
const userProfileApiConfig = API_BOOK.GET_USER_PROFILE;
const userProfileUpdateApiConfig = API_BOOK.UPDATE_USER;
const raStatusCheckApiConfig = API_BOOK.RA_STATUS_CHECK;
const userActionTypes = ActionTypes.GET_USER;
const otpActionTypes = ActionTypes.OTP;

const getUserProfileDispatcher = (payload) => ({
  type: userActionTypes.USER_DETAILS_SUCCESS,
  payload,
});

const verifyOtpDispatcher = (payload) => ({
  type: otpActionTypes.OTP_VERIFY_SUCCESS,
  payload
});


/* Actions starts here */

/**
 *  @desc         Register User action
 *  @author       Sanjana Manimaran
 *  @api          /user
 *  @method       POST
 *  @createdDate  16-may-2023
 *  @modifiedDate 16-May-2023
 **/
export const RegisterUser =
  ({
    firstName,
    lastName,
    userRole,
    email,
    mobileNumber,
    password,
    location,
    navigate,
    navigateFrom,
  }) =>
  async (dispatch) => {
    try {
      const hashPassword = CipherGenerator(password);
      const resp = await api({
        method: registerUserApiConfig.method,
        url: registerUserApiConfig.url,
        data: {
          firstName,
          lastName,
          userRole,
          email,
          mobileNumber,
          location,
          password: hashPassword,
        },
      });
      if (resp.status === 200) {
        setTimeout(() => {
          customToast({ message: 'Approval request sent' })
        }, 400)
        if (typeof navigate === "function") {
          navigate(navigateFrom, { replace: true });
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
    // set isOtpVerified flag to false
    dispatch(verifyOtpDispatcher(false))
  };

export const UserProfile = () => async (dispatch) => {
  try {
      const {_id}= JSON.parse(localStorage.getItem('user'))   
    const resp = await api({
      method: userProfileApiConfig.method,
      url: `${userProfileApiConfig.url}/${_id}`,
    });
    if (resp.status === 200) {
      const response = resp?.data.data;
      dispatch(getUserProfileDispatcher(response))
      localStorage.setItem('email',response.email)
      localStorage.setItem('profileImage', response?.profileImage || '')
      localStorage.setItem('userRole',response?.userRole?.[0])
      localStorage.setItem('location',response?.location)
      dispatch({type: 'profileImage', payload: response?.profileImage || ''})
    }
  } catch (error) {
    console.log("error:", error);
  }
};


export const UserProfileUpdate = ({
  userRole,
  mobileNumber, navigate, navigateFrom, defaultNo }) => async (dispatch) => {
    try {
      const {_id}= JSON.parse(localStorage.getItem('user')) 
      const resp = await api({
        method: userProfileUpdateApiConfig.method,
        url: `${userProfileUpdateApiConfig.url}/${_id}`,
        data: {
          userRole: [userRole],
          mobileNumber,
          oldNumber: defaultNo
        }
      });
      if (resp.status === 200) {
        if (typeof (navigate) === 'function') {
          navigate(navigateFrom, { replace: true });
          dispatch(verifyOtpDispatcher(false))
        }
        setTimeout(() => {
          customToast({ message: 'Successfully Submitted' })
        }, 400)
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

/**
 *  @desc         Check RA status whether the link is expired or not
 *  @author       Amir shaikh
 *  @api          /user/ra-action-status-check
 *  @method       POST
 *  @createdDate  25-jul-2023
 *  @modifiedDate 25-jul-2023
 **/
export const RAStatusCheckAPI = async ({ email }) => {
  return await api({
    method: raStatusCheckApiConfig.method,
    url: raStatusCheckApiConfig.url,
    data: { email },
  })
}

/**
 *  @desc         Mark RA action as the user is approved or declined
 *  @author       Amir shaikh
 *  @api          /user/ra-action
 *  @method       POST
 *  @createdDate  25-jul-2023
 *  @modifiedDate 25-jul-2023
 **/
export const RaUserActionAPI = async ({ token, actionType, comments, userRole, location }) => {
  return await api({
    method: raActionApiConfig.method,
    url: raActionApiConfig.url,
    data: { token, actionType, comments, userRole, location }

  })
}


/* Actions ends here */ 
