import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UpdatePasswordUser } from '../../../../store/actions/changepass';
import './updatepass.css';
import PasswordInput from '../../../shared/form/PasswordInput/PasswordInput';

const UpdatePassComponent = ({ isModalOpen, onClose, UpdatePasswordUser}) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorFields] = useState({});
    const [successFields, setSuccessFields] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    });
    const [showResults, setShowResults] = useState(false);

    const handleUpdatePasswordUserFail = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setShowResults(true); // Set showResults to true to display error message
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (errorFields.password || newPassword === oldPassword) {
            return;
        }

        // Make API call to update password
        UpdatePasswordUser({
            oldPassword: oldPassword,
            newPassword: confirmPassword,
            reject: handleUpdatePasswordUserFail,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'oldPassword') {
            setOldPassword(value);
            setSuccessFields({ ...successFields, oldPassword: true });
        } else if (name === 'newPassword') {
            setNewPassword(value);
            setSuccessFields({ ...successFields, newPassword: true });
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
            const isValid = value === newPassword;
            setSuccessFields({ ...successFields, confirmPassword: isValid });
        }
    };

    return (
        <Modal show={isModalOpen} onHide={onClose} backdrop="static" centered>
            <div className='updatepopup-content'>
                <Modal.Header className="d-flex justify-content-center">
                    <h4 className='text-white'>Update password</h4>
                    <button className='popup-close' onClick={onClose}>×</button>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className='passform-label-group'>
                            <label className='text-white'>Old password<span style={{ color: '#C417E0' }}>*</span></label>
                            <PasswordInput
                                value={oldPassword}
                                onChange={(e) => handleChange({ target: { name: 'oldPassword', value: e.target.value } })}
                                error={errorFields.oldPassword}
                                success={successFields.oldPassword}
                            />
                            {showResults && errorFields.oldPassword && (
                                <div className='text-danger'>Incorrect old password</div>
                            )}

                            <label className='text-white'>New password<span style={{ color: '#C417E0' }}>*</span></label>
                            <PasswordInput
                                value={newPassword}
                                onChange={(e) => handleChange({ target: { name: 'newPassword', value: e.target.value } })}
                                error={errorFields.newPassword}
                                success={successFields.newPassword}
                            />

                            <label className='text-white'>Confirm password<span style={{ color: '#C417E0' }}>*</span></label>
                            <PasswordInput
                                value={confirmPassword}
                                onChange={(e) => handleChange({ target: { name: 'confirmPassword', value: e.target.value } })}
                                error={errorFields.confirmPassword}
                                success={successFields.confirmPassword}
                            />
                        </div>
                        <div className='update-save-btn passform-label-group text-center'>
                            <button
                                className='update__pass__btn'
                                type='submit'
                                disabled={
                                    !oldPassword ||
                                    !newPassword ||
                                    !confirmPassword ||
                                    newPassword !== confirmPassword
                                }
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
};

UpdatePassComponent.propTypes = {
    // Actions
    UpdatePasswordUser: PropTypes.func.isRequired,

    // Props
    isModalOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
        isOtpVerified: state.isOtpVerified,
        isOldPassVerified: state.user.isOldPassVerified,
});

export default connect(mapStateToProps, {
    UpdatePasswordUser
})(UpdatePassComponent);