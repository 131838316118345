import React from 'react'
import moment from 'moment';

import './VTCharts.css'

import VTLineChart from './VTLineChart/VTLineChart'

const VTCharts = (props) => {

  const handleChartCloseClick = () => {
    props.onChartCloseClick()
  }

  const chartConfig = {
    width: "100%",
    height: 270,
    lineWidth: 2,
    isDotVisible: false,
    xAxis: {
      dy: 10,
      tickColor: "#FFFFFF",
      dataKey: "x"
    },
    yAxis: {
      dx: [-20,-20,-20,-10,-10,-10],
      isLineVisible: false,
      tickColor: "#FFFFFF",
      tickCount: 3,
      dataKey: "y"
    },
    grid: {
      lineColor: '#565454',
      hlineVisible: true,
      vlineVisible: false,
    },
  }

  const CHARTS_LIST = [{
    id: 'SOC_VS_TIME',
    title: "SoC vs Time",
    themeColor: '#33D69F',
    xHeader: "Time",
    yHeader: "SOC",
    yRange: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    units: "%",
    data: (d) => ({
      x: moment(d.time).format('HH:mm'),
      y: d.soc,
      timestamp: d.time,
    }),
  }, {
    id: 'VBT_VS_TIME',
    title: "Battery Temp vs Time",
    themeColor: '#33D69F',
    xHeader: "Time",
    yHeader: "Battery Temperature",
    yRange: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    units: "°C",
    data: (d) => ({
      x: moment(d.time).format('HH:mm'),
      y: d.vbt,
      timestamp: d.time,
    }),
  }, {
    id: 'VBV_VS_TIME',
    title: "Battery Voltage vs Time",
    themeColor: '#33D69F',
    xHeader: "Time",
    yHeader: "Battery Voltage",
    yRange: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    units: "v",
    data: (d) => ({
      x: moment(d.time).format('HH:mm'),
      y: d.vbv,
      timestamp: d.time,
    }),
  }, {
    id: 'SPEED_VS_TIME',
    title: "Speed vs Time",
    themeColor: '#642AD6',
    xHeader: "Time",
    yHeader: "Speed",
    yRange: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    units: "kms",
    data: (d) => ({
      x: moment(d.time).format('HH:mm'),
      y: d.sp,
      timestamp: d.time,
    }),
  }, {
    id: 'NS_VS_TIME',
    title: "Network Strength vs Time",
    themeColor: '#642AD6',
    xHeader: "Time",
    yHeader: "Network Strength",
    yRange: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    units: "",
    data: (d) => ({
      x: moment(d.time).format('HH:mm'),
      y: d.gss,
      timestamp: d.time,
    }),
  }, {
    id: 'DTE_VS_TIME',
    title: "DTE vs Time",
    themeColor: '#642AD6',
    xHeader: "Time",
    yHeader: "DTE",
    yRange: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    units: "kms",
    data: (d) => ({
      x: moment(d.time).format('HH:mm'),
      y: d.dte,
      timestamp: d.time,
    })
  }]

  return (
    <div className='d-flex'>
      <div className='position-fixed'>
        <img className='vt-chart-close-x' data-testid='vt-chart-close-button' onClick={handleChartCloseClick} src='/images/svgicon/x-close white.svg' alt="Close Chart"/>
        <img className='vt-chart-close-img' data-testid='vt-chart-close2-button' onClick={handleChartCloseClick} src="/images/svgicon/x-close-round.svg" alt="close chart" />
      </div>
      <div className='vt-chart-main'>
        {CHARTS_LIST.map((chart,index) => (
          <VTLineChart
            index = {index}
            chartVariant={chart.id}
            key={`chart_${chart.id}`}
            title={chart.title}
            themeColor={chart.themeColor}
            chartConfig={chartConfig}
            xHeader={chart.xHeader}
            yHeader={chart.yHeader}
            yRange={chart.yRange}
            units={chart.units}
            data={chart.data ? props?.routeData?.map(chart.data) : null}
            onExportToImage={props.onExportToImage}
            onExportToExcel={props.onExportToExcel}
          />
        ))}

      </div>
    </div>

  )
}

export default VTCharts