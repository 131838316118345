import React, { memo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import OTPInput from 'react-otp-input';
import OtpStyles from './OtpInput.module.css';
import styles from '../../../shared/modals/OTPModals/OTPModals.module.css';
import { useSelector } from 'react-redux';


const OtpInput = ({ value, onChange, numInputs, isInputNum, autoFocus, inputStyle, focusStyle, error,handleResendOtp, otpTimer, onPrevoiusPageClick }) => {

  // redux hook
  const isOtpVerified = useSelector(s => s?.SA_Signup?.isOtpVerified)
  return (
    <>
      <OTPInput
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        isInputNum={isInputNum}
        renderInput={(props) => <input {...props} className={OtpStyles.otpInput} />}
        autoFocus={autoFocus}
        inputStyle={inputStyle}
        focusStyle={focusStyle}
      />
      {(isOtpVerified || (value && !error)) && (
        <img src="/images/saAdmin/tick-icon.svg" alt="" className={styles.otpSuccessIcon} />
      )}

      {(isOtpVerified === false || (value && error))  && (
        <img src="/images/saAdmin/cross-icon.svg" alt="" className={styles.otpSuccessIcon} />
      )}
      {(isOtpVerified === false || (value && error)) && <div className={`${styles.otpErrorMessage}`}>Please enter valid OTP </div>}
      
      <div className='d-flex justify-content-between align-items-center mt-1 text-white px-2'>
        <div data-testid='otp-error2-message'>
          <span className={`${styles.otpTimer}`}> 00:{otpTimer}</span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className={cx(styles.resendOtp, { [styles.disabledResendOtp]: otpTimer !== '00' })}
          data-testid='otp-timer'
          onClick={handleResendOtp}
        >
          Resend OTP
        </a>
      </div>
      <div className='my-2 px-1'>
        <span className={cx(OtpStyles.previousPage)}>
            Back to <span className={cx(OtpStyles.previousPageLink)} onClick={onPrevoiusPageClick}>Previous page</span>
        </span>
      </div>
    </>
  );
};

OtpInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  numInputs: PropTypes.number.isRequired,
  isInputNum: PropTypes.bool,
  autoFocus: PropTypes.bool,
  inputStyle: PropTypes.object,
  focusStyle: PropTypes.object,
  error: PropTypes.bool,
  onPrevoiusPageClick: PropTypes.func,
};

OtpInput.defaultProps = {
  isInputNum: true,
  autoFocus: false,
  inputStyle: {},
  error: false,
  numInputs: 6,
  onPrevoiusPageClick: () => {},
};

export default memo(OtpInput);
/*conflict-resolution-will be removed later*/