import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { BsArrowRight } from "react-icons/bs";

import styles from './DashboardKPI.module.css'

const formatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })

const DashboardKPI = (props) => {
    const {
        heading,
        count,
        unit,
        image,
        detailsLink,
        detailsLinkText,
        enableDetailsLink,
    } = props

    return (
        <div className={cx(styles.card)}>
            <div className={cx(styles.cardContent, 'gap-2')}>
                <span className={cx(styles.heading, 'fw-medium')}>{heading}</span>
                <span className={cx('text-white fw-bold')}>
                    {formatter.format(count)}
                    {unit}
                </span>
                {enableDetailsLink && (
                    <div>
                        <a className={cx(styles.cardLink)} href={detailsLink}>
                            {detailsLinkText}
                            {" "}<BsArrowRight size={15} className='mx-2' />
                        </a>
                    </div>
                )}
            </div>
            <div className={cx(styles.cardImage)}>
                <img src={image} alt={heading} />
            </div>
        </div>
    )
}

DashboardKPI.propTypes = {
    heading: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    unit: PropTypes.string,
    image: PropTypes.string.isRequired,
    detailsLink: PropTypes.string.isRequired,
    detailsLinkText: PropTypes.string,
    enableDetailsLink: PropTypes.bool,
}

DashboardKPI.defaultProps = {
    unit: '',
    detailsLinkText: 'See details',
    enableDetailsLink: true,
}

export default React.memo(DashboardKPI);
/*conflict-resolution-will be removed later*/