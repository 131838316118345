import api from '../../../service/api'
import ActionTypes from '../../actionTypes'
import API_BOOK from '../../../service/endpoints'
import { fileDownloader } from '../../../utils/common-methods'
const { USER_ACTIVITIES_FETCHED } = ActionTypes.USERS
const { GET_USER_ACTIVITIES, EXPORT_TO_EXCEL } = API_BOOK.USER_ACTIVITY


const getUserActivities = (payload) => ({
    type: USER_ACTIVITIES_FETCHED,
    payload,
})


/**
 *  @desc         Telematics get user activities by ID API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /user-activity/get-all-activities
 *  @method       POST
 *  @createdDate  05-SEP-2023
 *  @modifiedDate 05-SEP-2023
 **/

export const GetAllActivities = ({ data }) => async (dispatch) => {
    try {
        const { _id, searchKey, pageNumber, pageSize } = data 
      const resp = await api({
          url: GET_USER_ACTIVITIES.url,
          method: GET_USER_ACTIVITIES.method,
          data: {
            _id,
            searchKey, 
            pageNumber,
            pageSize
          }
      })
  
      if (resp.status === 200 ) {
        dispatch(getUserActivities(resp))
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

/**
 *  @desc         Telematics Fleet Summary Export to excel API
 *  @author       Nilesh Bharat Salunkhe
 *  @api          /user-activity/export-to-excel
 *  @method       POST
 *  @createdDate  05-SEP-2023
 *  @modifiedDate 05-SEP-2023
 **/
export const ExportToExcelActivities = ({data}) => async (dispatch) => {  
    try {
      const res = await api({
        url: EXPORT_TO_EXCEL.url,
        method: EXPORT_TO_EXCEL.method,
        responseType: "arraybuffer",
        data : {
          _id: data?._id,
          searchKey: data?.searchKey,
          timezoneOffset: new Date().getTimezoneOffset()
        }
      })
      if (res) {
        fileDownloader({
          arrayBuffer: res?.data,
          fileName: "user_activity.xlsx",
        })
      }
    } catch (error) {
      console.log('get activities error', error)
    }
  }