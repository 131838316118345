import { MasterTypes } from "../../utils/constants";
import ActionTypes from "../actionTypes";
const roleAndMenuMappingActionTypes = ActionTypes.USER_ROLE_AND_MENU_MAPPING;

const initialState = {
  allUserRoleData: [],
  isUserMenuApiSuccess: false,
  masterMenuData: [],
  masterRoleData: [],
  roleMenuData:[],
  geoFenceLocationData:[],
  apiSourceData: []
};
const getAllUserRoleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case roleAndMenuMappingActionTypes.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        allUserRoleData: payload,
        masterRoleData: payload.filter((item) => item.mastertype === MasterTypes.ROLE),
        masterLocationData: payload.filter((item) => item.mastertype === MasterTypes.LOCATION).sort((a, b) => {
          return a.label.localeCompare(b.order);
        }),
        geoFenceLocationData: payload.filter((item) => item.mastertype === MasterTypes.GEO_FENCE_LOCATIONS),
        apiSourceData: payload?.filter(x => x.mastertype === MasterTypes.API_SOURCE)
      };
    case roleAndMenuMappingActionTypes.GET_USER_MENU_SUCCESS:
      return {
        ...state,
        roleMenuData: payload,
      };
      case roleAndMenuMappingActionTypes.UPDATE_USER_MENU_MAPPING_SUCCESS:
        return {
          ...state,
          isUploadApiSuccess: payload,
        }
    default:
      return state;
  }
};

export default getAllUserRoleReducer;
