import api from "../../service/api";
import ActionTypes from "../actionTypes";
import API_BOOK from "../../service/endpoints";

const userFavoriteVehicleActionTypes = ActionTypes.USER_FAVORITE_VEHICLE;
const userFavoriteVehicleApiConfig = API_BOOK.USER_FAVORITE_VEHICLE;

const getFavoriteVehicleDispatcher = (actionType, payload) => ({
    type: actionType,
    payload,
});

/**
 *  @desc         Add favorite vehicle in user
 *  @author       Parvez Siddique
 *  @api          user/favourite-vehicle/:registrationNumber
 *  @method       PUT
 *  @createdDate  11-Sep-2023
 *  @modifiedDate 11-Sep-2023
 **/
export const AddFavoriteVehicle = ({ registration_number }) => async (dispatch) => {
    try {
        const resp = await api({
            method: userFavoriteVehicleApiConfig.ADD_FAVORITE_VEHICLE.method,
            url: `${userFavoriteVehicleApiConfig.ADD_FAVORITE_VEHICLE.url}`.replace(':registrationNumber',registration_number),
        });
        if (resp.status === 200) {
            const response = registration_number
            dispatch(getFavoriteVehicleDispatcher(userFavoriteVehicleActionTypes.ADD_USER_FAVORITE_VEHICLE_SUCCESS, response))
        }
    } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         Delete favorite vehicle from  user
 *  @author       Parvez Siddique
 *  @api          user/favourite-vehicle/:registrationNumber
 *  @method       PUT
 *  @createdDate  11-Sep-2023
 *  @modifiedDate 11-Sep-2023
 **/
export const DeleteFavoriteVehicle = ({ registration_number }) => async (dispatch) => {
    try {
        const resp = await api({
            method: userFavoriteVehicleApiConfig.REMOVE_FAVORITE_VEHICLE.method,
            url: `${userFavoriteVehicleApiConfig.REMOVE_FAVORITE_VEHICLE.url}`.replace(':registrationNumber',registration_number),
        });
        if (resp.status === 200) {
            const response = registration_number
            dispatch(getFavoriteVehicleDispatcher(userFavoriteVehicleActionTypes.DELETE_USER_FAVORITE_VEHICLE_SUCCESS, response))
        }
    } catch (error) {
        console.log("error:", error);
    }
}

/**
 *  @desc         Get all favorite vehicle of  user
 *  @author       Parvez Siddique
 *  @api          user/favourite-vehicle/
 *  @method       GET
 *  @createdDate  11-Sep-2023
 *  @modifiedDate 11-Sep-2023
 **/
export const GetAllFavoriteVehicle = () => async (dispatch) => {
    try {
        const resp = await api({
            method: userFavoriteVehicleApiConfig.GET_ALL_FAVORITE_VEHICLE.method,
            url: `${userFavoriteVehicleApiConfig.GET_ALL_FAVORITE_VEHICLE.url}`,
        });
        if (resp.status === 200) {
            const response = resp.data?.data?.favouriteVehicles
            dispatch(getFavoriteVehicleDispatcher(userFavoriteVehicleActionTypes.GET_USER_FAVORITE_VEHICLE_SUCCESS, response))
        }
    } catch (error) {
        console.log("error:", error);
    }
}
