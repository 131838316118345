import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Spinner } from 'react-bootstrap';
import { MapContainer, TileLayer } from 'react-leaflet';
// import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import VTHeader from './vt-header/VTHeader';
import VTMap from './vt-map/VTMap';
import VTController from './vt-controller/VTController';
import VTLegend from './vt-legend/VTLegend';
import VTVehicleInfo from './vt-vehicleInfo/VTVehicleInfo';
import VTSeekbar from './vt-seekbar/VTSeekbar';
import VTAddress from './vt-address/VTAddress';
import VTCharts from './vt-charts/VTCharts';

import { GetSubsRnNums, GetVehicleTrackingHistoryAPI } from '../../../store/actions/vehicle/vehicle';
import { GetAddressByCoordinates } from '../../../store/actions/map/latlng-to-address';
import { customToast, errorToast } from '../../../utils/toasts';
import vehicleImages from '../../../utils/vehicleImages';
import { VehicleTrackingController } from '../../../utils/constants';
import { captureAndDownloadElementImage } from '../../../utils/capture-element-image';
import { exportToExcel } from '../../../utils/export-to-excel';

import './VehicleTracking.css'
import Loader from '../../helpers/hoc/loader/Loader';

const controllerSpeedConfig = [1, 2, 4, 8];

const defaultControllerConfig = {
    play: false,
    pause: false,
    speed: 1,
    speedInterval: 1200,
};

class VehicleTracking extends Component {
    constructor(props) {
        super(props)
        this.routingInvtervalRef = createRef(null);
        this.mapRef = createRef(null);
        this.state = {
            form: {
                registrationNumber: [],
                startDateTime: "",
                endDateTime: ""
            },
            vehicleModel: '',
            vehicleOEM: '',
            vehicleTrackingHistory: {},
            routeAddress: "",
            routePositionDMS: {},
            isLoading: 0,
            isInitialLoaded: 0,
            isChartActive: 0,
            activeSeekbarValue: 0,
            routePosition: [0, 0],
            controllerConfig: defaultControllerConfig,
            speedCount: 0,
            currentRouteIndex: 0,
            isVehicleInfoOpen: true,
            fetchingRutes: 0,
        }
    }

    componentDidMount() {
        this.props.getRegisteredVehicle()
        const params = new URLSearchParams(window.location.search)
        const vn = params.get('vn')
        const st = params.get('st')
        const et = params.get('et')

        if(vn && !isNaN(Date.parse(st)) && !isNaN(Date.parse(et))) {
            const { form } = this.state;
            form.registrationNumber = [{ label: vn }]
            form.startDateTime = new Date(st)
            form.endDateTime = new Date(et)
            this.setState(form => form)
            this.handleVehicleTrackingSubmit()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.controllerConfig.play && this.routingInvtervalRef.current === null) {
            this.handleRenderRoutes();
        }
        if (this.state.controllerConfig.pause && this.routingInvtervalRef.current) {
            this.clearRoutingInterval();
        }
    }

    // handle form change
    handleVehicleTrackingChange = (name, value) => {
        const { form } = this.state;
        form[name] = value
        this.setState(form)
    }

    // handle form submit
    handleVehicleTrackingSubmit = async () => {
        const { form } = this.state;
        this.handleStop()
        this.setState({ isLoading: 1, fetchingRutes: 1 })
        try {
            const res = await GetVehicleTrackingHistoryAPI({ registrationNumber: form.registrationNumber[0].label, startDateTime: form.startDateTime, endDateTime: form.endDateTime })
            if (res.status === 200 && res.data.data) {
                if(!res.data.data.routeData.length) {
                    customToast({ message: 'No Data Found', svgImageName: 'vtNoDataFound' })
                }
                let vehicleData = this.props.registeredVehicleList.find(d => form.registrationNumber[0].label === d.label)
                if(!vehicleData){
                    const params = new URLSearchParams(window.location.search)
                    vehicleData = {
                        oem: params.get('oem'),
                        model: params.get('model')
                    }
                }
                this.setState({ vehicleTrackingHistory: res.data.data, vehicleOEM: vehicleData.oem, vehicleModel: vehicleData.model }, () => {
                })
            } else {
                res.data.error.forEach(error => {
                    errorToast({ message: error.message })
                });
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setState({ isLoading: 0, isInitialLoaded: 1, fetchingRutes: 0 })
        }
    }

    // handle seekbar input change
    handleSeekbarChange = async (value) => {
        this.handlePause()
        const { vehicleTrackingHistory } = this.state
        const route = vehicleTrackingHistory.routeData[value];
        this.setState({ routePosition: [route.lt, route.lng], activeSeekbarValue: value, currentRouteIndex: value })
        if (route.lt !== this.state.routePosition[0] && route.lng !== this.state.routePosition[1]) {
            this.setState({ routeAddress: '', routePositionDMS: { } });
        }

    }

    // generate timeline pointers for seekbar
    getTimelinePointers = ({ routes = [] }) => {
        if (!routes.length) {
            return [];
        }
        const timelineDivision = Math.floor(routes.length / 4);

        const timelinePointers = [
            routes[0]?.time,
            routes[0 + (timelineDivision * 1)]?.time,
            routes[0 + (timelineDivision * 2)]?.time,
            routes[0 + (timelineDivision * 3)]?.time,
            routes[routes.length - 1]?.time,
        ];

        return timelinePointers;
    }

    // handle vehicle info panel toggle
    handleVehicleInfoToggle = () => {
        this.setState({ isVehicleInfoOpen: !this.state.isVehicleInfoOpen });
    }

    onControllerClick = async (action) => {
        if (action === VehicleTrackingController.PLAY) {
            this.handlePlay()
        }
        if (action === VehicleTrackingController.PAUSE) {
            this.handlePause()
        }
        if (action === VehicleTrackingController.STOP) {
            this.handleStop()
        }
        if (action === VehicleTrackingController.FORWARD) {
            this.handleSpeedControl(this.state.speedCount + 1)
        }
        if (action === VehicleTrackingController.BACKWARD) {
            this.handleSpeedControl(this.state.speedCount - 1)
        }
    }

    // render vehicle history routes
    handleRenderRoutes = () => {
        const { currentRouteIndex, vehicleTrackingHistory, controllerConfig } = this.state;
        let count = currentRouteIndex;
        
        this.routingInvtervalRef.current = setInterval(async () => {
            count++;
            const route = vehicleTrackingHistory.routeData[count];

            if (route && Object.values(route)) {
                this.setState({ routePosition: [route.lt, route.lng], currentRouteIndex: count, activeSeekbarValue: count })
                // get current zoom level
                const zoom = this.mapRef.current.getZoom()

                // center new center and zoom level
                setTimeout(() => {
                    if (this.mapRef.current?.setView) {
                        this.mapRef.current.setView([route.lt, route.lng], zoom <= 14 ? 16 : zoom)
                    }
                }, controllerConfig.speedInterval / 2)
                
                if (route.lt !== this.state.routePosition[0] && route.lng !== this.state.routePosition[1]) {
                    this.setState({ routeAddress: '', routePositionDMS: { } });
                }
            } else {
                this.setState({ controllerConfig: { ...defaultControllerConfig }, currentRouteIndex: 0, activeSeekbarValue: 0 })
                this.clearRoutingInterval();
            }
        }, controllerConfig.speedInterval);
    };

    // handle routes play
    handlePlay = () => {
        const config = JSON.parse(JSON.stringify(this.state.controllerConfig));
        config.play = true;
        config.pause = false;
        this.setState({ controllerConfig: { ...config } })
    };

    // handle routes pause
    handlePause = () => {
        this.clearRoutingInterval();
        const config = JSON.parse(JSON.stringify(this.state.controllerConfig));
        config.play = false;
        config.pause = true;
        this.setState({ controllerConfig: { ...config } })
    };

    // handle routes stop
    handleStop = () => {
        if(this.routingInvtervalRef.current) {
            this.clearRoutingInterval()
        }

        setTimeout(() => {
            if (this.mapRef.current?.setView) {
                const waypoints = this.state.vehicleTrackingHistory?.routeData.map(d => ({ lat: d.lt, lng: d.lng, gpsDirection: d.dir }));
                this.mapRef.current.fitBounds(waypoints);
            }
        }, 100)
        
        this.setState({
            controllerConfig: defaultControllerConfig,
            activeSeekbarValue: 0,
            routePosition: [0, 0],
            speedCount: 0,
            currentRouteIndex: 0,
            isVehicleInfoOpen: true,
        })
    }

    // handle speed control
    handleSpeedControl = (count) => {
        this.clearRoutingInterval();
        this.setState({ speedCount: count })
        let config = JSON.parse(JSON.stringify(this.state.controllerConfig));
        // handle speed
        config.speed = controllerSpeedConfig[count];

        // handle default interval
        if (config.speed === controllerSpeedConfig[0]) config.speedInterval = 1200;

        // handle 2x speed interval
        if (config.speed === controllerSpeedConfig[1]) config.speedInterval = 1000;

        // handle 4x speed interval
        if (config.speed === controllerSpeedConfig[2]) config.speedInterval = 700;

        // handle 8x speed interval
        if (config.speed === controllerSpeedConfig[3]) config.speedInterval = 500;

        this.setState({ controllerConfig: { ...config } })
    };

    // handle route to move 1 route forward
    handleMoveForward = async () => {
        const { vehicleTrackingHistory, currentRouteIndex } = this.state;
        this.handlePause();
        const increaseCurrentRouteIndex = currentRouteIndex + 1;
        const route = vehicleTrackingHistory.routeData[increaseCurrentRouteIndex];
        if (route && Object.values(route)) {
            this.setState({ routePosition: [route.lt, route.lng], currentRouteIndex: increaseCurrentRouteIndex })
            if (route.lt !== this.state.routePosition[0] && route.lng !== this.state.routePosition[1]) {
                this.setState({ routeAddress: '', routePositionDMS: { } });
            }
        }
    };

    // handle route to move 1 route backward
    handleMoveBackward = async () => {
        const { currentRouteIndex, vehicleTrackingHistory } = this.state;
        this.handlePause();
        const decreaseCurrentRouteIndex = currentRouteIndex === 0 ? vehicleTrackingHistory.routeData.length - 1 : currentRouteIndex - 1;
        const route = vehicleTrackingHistory.routeData[decreaseCurrentRouteIndex];
        if (route && Object.values(route)) {
            this.setState({ routePosition: [route.lt, route.lng], currentRouteIndex: decreaseCurrentRouteIndex })
            if (route.lt !== this.state.routePosition[0] && route.lng !== this.state.routePosition[1]) {
                this.setState({ routeAddress: '', routePositionDMS: { } });
            }
        }
    };

    // clear interval and reset routing interval to initial state
    clearRoutingInterval = () => {
        clearInterval(this.routingInvtervalRef.current);
        this.routingInvtervalRef.current = null;
    };

    handleViewChartClick = () => {
        this.handlePause();
        this.setState({ isChartActive: 1 });
    }

    handleCloseChartClick = () => {
        this.setState({ isChartActive: 0 });
    }

    SetAddressAndCoOrdinate = async () => {
        try {
            const { routePosition, vehicleTrackingHistory } = this.state
            const route = {
                lat: routePosition[0],
                lng: routePosition[1],
            }
            if ((!route.lat || !route.lng) && vehicleTrackingHistory?.routeData[0]) {
                route.lat = vehicleTrackingHistory.routeData[0]?.lt
                route.lng = vehicleTrackingHistory.routeData[0]?.lng
            }
            if (Number(route.lat) === 0 || Number(route.lng) === 0) {
                return errorToast({ message: 'Address can not be fetched for required location.' })
            }
            const res = await GetAddressByCoordinates({ lat: route.lat, lng: route.lng });
            if (res.status === 200 && res.data.data) {
                this.setState({ routeAddress: res.data.data.address, routePositionDMS: { lat: res.data.data.dmsLat, lng: res.data.data.dmsLng } });
            } else {
                res.data.error.forEach(error => {
                    errorToast({ message: error.message });
                });
            }
        } catch (error) {
            console.log({error});
        }
    }

    resetState = () => {
        this.clearRoutingInterval();
        this.setState({
            vehicleTrackingHistory: {},
            routeAddress: "",
            routePositionDMS: {},
            isChartActive: 0,
            activeSeekbarValue: 0,
            routePosition: [0, 0],
            speedCount: 0,
            currentRouteIndex: 0,
            isVehicleInfoOpen: true,
        })
    }

    componentWillUnmount() {
        this.clearRoutingInterval();
    }

    renderContent = () => {
        const { vehicleOEM, vehicleModel, isLoading, isInitialLoaded, vehicleTrackingHistory, activeSeekbarValue, isVehicleInfoOpen, currentRouteIndex, controllerConfig } = this.state;

        if (isLoading) {
            return (
                <div className='vt__loading-routes-container'>
                    <Loader isLoading={this.props.isLoading}></Loader>
                </div>
            )
        }
        
        if (isInitialLoaded && vehicleTrackingHistory?.routeData?.length) {
            return (
                <>
                    <div className={`vt__body ${isVehicleInfoOpen ? 'vt__body--vehicle-info-open' : ''}`}>
                        <div className='vt__map'>
                            <VTMap
                                vehicleIcon={vehicleImages(`${vehicleOEM}`.toLowerCase())['vehicleTracking']}
                                routeData={vehicleTrackingHistory?.routeData}
                                currentRouteIndex={currentRouteIndex}
                                enableFitBound={!controllerConfig.play && !controllerConfig.pause}
                                mapRef={this.mapRef}
                            />
                        </div>
                        {/* NOTE: Commented as per current requirement to keep info panel open by default */}
                        {/* <button className='vt__vehicle-info__toggle' data-testid='vt-info-panel-toggle' onClick={this.handleVehicleInfoToggle}>
                            {!isVehicleInfoOpen ? <FaChevronLeft /> : <FaChevronRight />}
                        </button> */}
                        <div className='vt__vehicle-info'>
                            <VTVehicleInfo
                                averageSpeed={vehicleTrackingHistory?.averageSpeed}
                                registrationNumber={vehicleTrackingHistory?.registrationNumber}
                                maxSpeed={vehicleTrackingHistory?.maxSpeed}
                                vehicleModel={vehicleModel}
                                vehicleOEM={vehicleOEM}
                                vehicleRunKM={vehicleTrackingHistory?.runKms}
                                activeIndexInfo={vehicleTrackingHistory?.routeData[currentRouteIndex]}
                                onViewChartClick={this.handleViewChartClick}
                            />
                        </div>
                    </div>
                    <div className='vt__footer'>
                        <div className='vt__legends-controller'>
                            <div className='vt__legends'>
                                <VTLegend />
                            </div>
                            <div className='vt__controller'>
                                <VTController
                                    onControllerClick={this.onControllerClick}
                                    speed={controllerConfig.speed}
                                    controllerConfig={controllerConfig}
                                    controllerSpeedConfig={controllerSpeedConfig}
                                />
                            </div>
                        </div>
                        <div className='vt__address-seekbar'>
                            <div className='vt__address'>
                                <VTAddress address={this.state.routeAddress} lat={this.state.routePositionDMS.lat} lng={this.state.routePositionDMS.lng} getAddress={this.SetAddressAndCoOrdinate} />
                            </div>
                            <div className='vt__seekbar'>
                                <VTSeekbar
                                    minSliderRange={0}
                                    routeData={vehicleTrackingHistory?.routeData}
                                    maxSliderRange={vehicleTrackingHistory.routeData?.length - 1}
                                    activeSeekbarValue={activeSeekbarValue}
                                    onSeekbarChange={this.handleSeekbarChange}
                                    timelinePointers={this.getTimelinePointers({ routes: vehicleTrackingHistory?.routeData })}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <MapContainer
                center={[
                    20.5937,
                    78.9629,
                ]}
                zoom={5}
                zoomControl={false}
                attributionControl={false}
                maxZoom={5}
                minZoom={5}
                maxNativeZoom={5}
                id="mapParent"
            >
                  <TileLayer
                        attribution='&copy; <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors'
                        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                        subdomains={['mt0','mt1','mt2','mt3']}
                    />
            </MapContainer>
        )
    }

    render() {
        const { form, fetchingRutes, isChartActive, vehicleTrackingHistory } = this.state;
        return (
            <div className={`vehicle-tracking ${isChartActive ? 'vehicle-tracking--charts-open' : ''}`}>
                <div className='vt__header'>
                    <VTHeader
                        formData={form}
                        vehicleList={this.props.registeredVehicleList}
                        onVehicleTrackingChange={this.handleVehicleTrackingChange}
                        onVehicleTrackingSubmit={this.handleVehicleTrackingSubmit}
                        fetchingRutes={fetchingRutes}
                    />
                </div>

                {this.renderContent()}

                <div className='vt__charts'>
                    <VTCharts
                        onChartCloseClick={this.handleCloseChartClick}
                        routeData={vehicleTrackingHistory?.routeData}
                        onExportToExcel={exportToExcel}
                        onExportToImage={captureAndDownloadElementImage}
                    >
                    </VTCharts>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    registeredVehicleList: state.vehicle.subcribedRnNums,
    isLoading: state.loader.isLoading,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getRegisteredVehicle: GetSubsRnNums,
    },
    dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTracking)