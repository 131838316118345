import React, { useState, useEffect, useRef, useMemo } from 'react'
import ChargingIcon from '../../../../assets/GeoFencingIcons/ChargingAmenities.svg'
import ParkingIcon from '../../../../assets/GeoFencingIcons/ParkingAmenity.svg'
import ServiceIcon from '../../../../assets/GeoFencingIcons/ServicingIcon.svg'
import StorageIcon from '../../../../assets/GeoFencingIcons/StorageAmenity.svg'
import HiringDeskIcon from '../../../../assets/GeoFencingIcons/HiringDeskAmenity.svg'
import LoadingIcon from '../../../../assets/GeoFencingIcons/LoadingAmenity.svg'
import MultiSelect from '../../../helpers/hoc/mult-select/MultiSelect'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  CreateFence,
  UpdateGeoFence,
} from '../../../../store/actions/geo-fence/createfence'
import { latlongToAddressAction } from '../../../../store/actions/map/latlng-to-address'
import { GetSubsRnNums } from '../../../../store/actions/vehicle/vehicle'
import SingleSelect from '../../../helpers/hoc/single-select/SingleSelect'
import { Row } from 'react-bootstrap'
import './fenceSideCard.css'
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io'
import EmailTagChips from '../../../helpers/hoc/email-tag-chips/EmailTagChips'
import Loader from '../../../helpers/hoc/loader/Loader'
import { useNavigate } from 'react-router-dom'

const Sidecard = ({
  //actions
  CreateFence,
  latlongToAddressAction,
  GetSubsRnNums,
  UpdateGeoFence,

  //states
  shapeDetails,
  markerDrawn,
  address,
  // city,
  dmsLatLng,
  subcribedRnNums,
  isLoading,
  isFenceApiOperationSuccess,

  //props
  geoFanceOwnersData,
  geoFanceLabelOwnersData,
  geoFanceLabelData,
  fenceDetail = null,
  fenceId = null,
  geoFenceLocationData = []
}) => {
  const navigate = useNavigate()
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true)

  const shapeName = shapeDetails?.fenceShape
  const [ingress, setIngress] = useState(false)
  const [egress, setEgress] = useState(false)
  const [dwellTime, setDwellTime] = useState(false)

  //ingress & egress customize radio button enable time range

  const [ingress24Hrs, setIngress24Hrs] = useState(true)
  const [ingressAllVehicles, setIngressAllVehicles] = useState(true)

  const [egress24Hrs, setEgress24Hrs] = useState(true)
  const [egressAllVehicles, setEgressAllVehicles] = useState(true)

  const [dwellAllVehicles, setDwellAllVehicles] = useState(true)

  const [enableCustomizeIngressTime, setEnableCustomizeIngressTime] =
    useState(false)
  const [ingressCustomizeVehicle, setIngressCustomizeVehicle] = useState(false)
  const [egressCustomizeVehicle, setEgressCustomizeVehicle] = useState(false)
  const [enableCustomizeEgressTime, setEnableCustomizeEgressTime] =
    useState(false)
  const [enableDwellTimeCustomizeVehicle, setEnableDwellTimeCustomizeVehicle] =
    useState(false)

  //  multiselect states
  const [selectedOptionsIngressVehicles, setSelectedOptionsIngressVehicles] =
    useState([])
  const [selectedOptionsEgressVehicles, setSelectedOptionsEgressVehicles] =
    useState([])
  const [selectedOptionsDwellVehicles, setSelectedOptionsDwellVehicles] =
    useState([])

  // different states for handling time from and to, ingress and egress
  const [fromTimeValueHH, setFromTimeHHValue] = useState('')
  const [fromTimeValueMM, setFromTimeMMValue] = useState('')
  const [toTimeValueHH, setToTimeHHValue] = useState('')
  const [toTimeValueMM, setToTimeMMValue] = useState('')
  const [egressFromHH, setEgressFromHH] = useState('')
  const [egressFromMM, setEgressFromMM] = useState('')
  const [egressToHH, setEgressToHH] = useState('')
  const [egressToMM, setEgressToMM] = useState('')

  const initialStateFormData = {
    fence_name: '',
    fence_identifiers: {
      label: [],
      label_owner: [],
      fence_owner: [],
      city: [],
      address: '',
    },
    amenities: {
      charging: false,
      parking: false,
      servicing: false,
      storage: false,
      hiring_desk: false,
      loading_unloading: false,
    },
  }
  const initialStateAlertsData = {
    ingress: {
      time: {
        start: '',
        end: '',
      },
      vehicle: [],
    },
    egress: {
      time: {
        start: '',
        end: '',
      },
      vehicle: [],
    },
    dwellTime: {
      time: {
        conditions: {
          isLTE6Hrs: true,
          isIn6To12Hrs: true,
          isIn12To24Hrs: true,
          isIn24To48Hrs: true,
          isGT48Hrs: true,
        },
      },
      vehicle: [],
    },
    notification: {
      type: 'Email',
      emailIds: [],
    },
  }

  // use state to create fence
  const [formData, setFormData] = useState(initialStateFormData)

  const { label, label_owner, fence_owner, city } = formData.fence_identifiers
  const {
    charging,
    parking,
    servicing,
    storage,
    hiring_desk,
    loading_unloading,
  } = formData.amenities

  const [markersLatLong, setMarkersLatLong] = useState([])
  const [emailIds, setEmailIds] = useState([])
  const [isSubmitEnable, setIsSubmitEnable] = useState(false)
  const [calculatedNearestCity, setCalculatedNearestCity] =
  useState('')

  // usestate for handling alert rules

  const [alertRules, setAlertRules] = useState(initialStateAlertsData)

  const { isLTE6Hrs, isIn6To12Hrs, isIn12To24Hrs, isIn24To48Hrs, isGT48Hrs } =
    alertRules.dwellTime.time.conditions

  //   different functions

  function selectHandler(data, type) {
    setFormData({
      ...formData,
      fence_identifiers: {
        ...formData.fence_identifiers,
        [type]: [data],
      },
    })
  }

  // different refs for ingress and egress times
  const ingressFromMM = useRef()
  const ingressToHH = useRef()
  const ingressToMM = useRef()

  const egressFromMMRef = useRef()
  const egressToHHRef = useRef()
  const egressToMMRef = useRef()

  const handleTimeChange = (name, event, alertType, isOnEdit = false) => {
    let newValue = event.target.value
    const regex = /^[0-9]+$/
    if (!newValue.match(regex)) {
      newValue = 0
    }
    const numValue = parseInt(Number(newValue))

    if (alertType === 'ingress') {
      if (name === 'fromHH') {
        if (Number(newValue).toString().length === 2) {
          ingressFromMM.current?.focus()
        }
        setFromTimeHHValue(Math.min(23, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            ingress: {
              ...alertRules.ingress.vehicle,
              time: {
                start: Math.min(23, numValue) + ':' + fromTimeValueMM,
                end: toTimeValueHH + ':' + toTimeValueMM,
              },
            },
          })
        }
      } else if (name === 'fromMM') {
        setFromTimeMMValue(Math.min(59, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            ingress: {
              ...alertRules.ingress.vehicle,
              time: {
                start: fromTimeValueHH + ':' + Math.min(59, numValue),
                end: toTimeValueHH + ':' + toTimeValueMM,
              },
            },
          })
        }
        if (Number(newValue).toString().length === 2) {
          ingressToHH.current?.focus()
        }
      } else if (name === 'toHH') {
        setToTimeHHValue(Math.min(23, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            ingress: {
              ...alertRules.ingress.vehicle,
              time: {
                start: fromTimeValueHH + ':' + fromTimeValueMM,
                end: Math.min(23, numValue) + ':' + toTimeValueMM,
              },
            },
          })
        }
        if (Number(newValue).toString().length === 2) {
          ingressToMM.current?.focus()
        }
      } else if (name === 'toMM') {
        setToTimeMMValue(Math.min(59, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            ingress: {
              ...alertRules.ingress.vehicle,
              time: {
                start: fromTimeValueHH + ':' + fromTimeValueMM,
                end: toTimeValueHH + ':' + Math.min(59, numValue),
              },
            },
          })
        }
      }
    } else if (alertType === 'egress') {
      if (name === 'egressFromHH') {
        if (Number(newValue).toString().length === 2) {
          egressFromMMRef.current?.focus()
        }
        setEgressFromHH(Math.min(23, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            egress: {
              ...alertRules.egress.vehicle,
              time: {
                start: Math.min(23, numValue) + ':' + egressFromMM,
                end: egressToHH + ':' + egressToMM,
              },
            },
          })
        }
      } else if (name === 'egressFromMM') {
        if (Number(newValue).toString().length === 2) {
          egressToHHRef.current?.focus()
        }
        setEgressFromMM(Math.min(59, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            egress: {
              ...alertRules.egress.vehicle,
              time: {
                start: egressFromHH + ':' + Math.min(59, numValue),
                end: egressToHH + ':' + egressToMM,
              },
            },
          })
        }
      } else if (name === 'egressToHH') {
        if (Number(newValue).toString().length === 2) {
          egressToMMRef.current?.focus()
        }
        setEgressToHH(Math.min(23, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            egress: {
              ...alertRules.egress.vehicle,
              time: {
                start: egressFromHH + ':' + egressFromMM,
                end: Math.min(23, numValue) + ':' + egressToMM,
              },
            },
          })
        }
      } else if (name === 'egressToMM') {
        setEgressToMM(Math.min(59, numValue))
        if (!isOnEdit) {
          setAlertRules({
            ...alertRules,
            egress: {
              ...alertRules.egress.vehicle,
              time: {
                start: egressFromHH + ':' + egressFromMM,
                end: egressToHH + ':' + Math.min(59, numValue),
              },
            },
          })
        }
      }
    }
  }

  // function for handling amenities checkbox changes
  function handleCheckBoxChange(field) {
    setFormData({
      ...formData,
      amenities: {
        ...formData.amenities,
        [field]: !formData.amenities[field],
      },
    })
  }

  // function for handling dwell time checkbox changes
  function handleCdnsCheckBoxChange(field) {
    const data = {
      ...alertRules.dwellTime.time.conditions,
      [field]: !alertRules.dwellTime.time.conditions[field]
    }

    if (Object.values(data).filter((val)=> val === true).length < 1){
      return
    }
    setAlertRules({
      ...alertRules,
      dwellTime: {
        ...alertRules.dwellTime.vehicle,
        time: {
          conditions: {
            ...alertRules.dwellTime.time.conditions,
            [field]: !alertRules.dwellTime.time.conditions[field],
          }
        },
      },
    })
  }

  function getSubmittedAlertData(alert) {
    if (alert === 'ingress') {
      if (ingress) {
        const data = {
          time:
            ingress24Hrs && !enableCustomizeIngressTime
              ? {
                  start: '00:00',
                  end: '23:59',
                }
              : !ingress24Hrs && enableCustomizeIngressTime
              ? alertRules.ingress.time
              : undefined,

          vehicle:
            ingressAllVehicles && !ingressCustomizeVehicle
              ? ['all']
              : !ingressAllVehicles && ingressCustomizeVehicle
              ? selectedOptionsIngressVehicles?.length
                ? selectedOptionsIngressVehicles?.map((val) => val.label)
                : []
              : [],
        }
        return data
      }
      return undefined
    } else if (alert === 'egress') {
      if (egress) {
        const data = {
          time:
            egress24Hrs && !enableCustomizeEgressTime
              ? {
                  start: '00:00',
                  end: '23:59',
                }
              : !egress24Hrs && enableCustomizeEgressTime
              ? alertRules.egress.time
              : undefined,

          vehicle:
            egressAllVehicles && !egressCustomizeVehicle
              ? ['all']
              : !egressAllVehicles && egressCustomizeVehicle
              ? selectedOptionsEgressVehicles?.length
                ? selectedOptionsEgressVehicles?.map((val) => val.label)
                : []
              : [],
        }
        return data
      }
      return undefined
    } else if (alert === 'dwellTime') {
      if (dwellTime) {
        const data = {
          time: {
            conditions: alertRules.dwellTime.time?.conditions,
          },
          vehicle:
            dwellAllVehicles && !enableDwellTimeCustomizeVehicle
              ? ['all']
              : !dwellAllVehicles && enableDwellTimeCustomizeVehicle
              ? selectedOptionsDwellVehicles?.length
                ? selectedOptionsDwellVehicles?.map((val) => val.label)
                : []
              : [],
        }
        return data
      }
      return undefined
    }
    return undefined
  }
  
  const submitFenceDetails = () => {
    const modifiedAlertRules = {
      ingress: getSubmittedAlertData('ingress') || { time: {}, vehicle: [] },
      egress: getSubmittedAlertData('egress') || { time: {}, vehicle: [] },
      dwell_time: getSubmittedAlertData('dwellTime') || {
        time: {
          conditions: {
            isLTE6Hrs: false,
            isIn6To12Hrs: false,
            isIn12To24Hrs: false,
            isIn24To48Hrs: false,
            isGT48Hrs: false
          }
        },
        vehicle: [],
      },
      notification: {
        type: 'Email',
        email_ids: emailIds,
      },
    }
    const data = {
      fence_name: formData.fence_name,
      fence_identifiers: {
        label: formData.fence_identifiers?.label[0]?._id,
        label_owner: formData.fence_identifiers?.label_owner[0]?._id,
        fence_owner: formData.fence_identifiers?.fence_owner[0]?._id,
        city: formData?.fence_identifiers?.city[0]?.value,
        address,
      },
      center_coordinates: markersLatLong,
      amenities: {
        ...formData.amenities,
      },
      fence_geometry: {
        type: shapeDetails.fenceShape,
        coordinates: shapeDetails.coordinates,
        area: {
          value: shapeDetails.fenceArea,
          unit: shapeDetails.unit,
        },
      },
      alert_rules: modifiedAlertRules,
    }
    if (fenceId) {
      data._id = fenceId
      data._meta = fenceDetail._meta
      UpdateGeoFence({
        data: data,
      })
    } else {
      CreateFence({
        data: data,
      })
    }
  }

  useEffect(() => {
    const { label, label_owner, fence_owner } = formData.fence_identifiers
    const isAlertDataSubmiited = (type) => {
      const modifiedAlertRules = {
        ingress: getSubmittedAlertData('ingress'),
        egress: getSubmittedAlertData('egress'),
        dwellTime: getSubmittedAlertData('dwellTime'),
      }
      const { time, vehicle } = modifiedAlertRules[type]
      if (
        time?.start &&
        time?.end &&
        vehicle?.length &&
        ['ingress', 'egress'].includes(type)
      ) {
        return true
      } else if (Object.values(time.conditions || {})?.some((val)=> val === true) && vehicle?.length && type === 'dwellTime') {
        return true
      }
      return false
    }
    function getSubmittedAlertData(alert) {
      if (alert === 'ingress') {
        if (ingress) {
          const data = {
            time:
              ingress24Hrs && !enableCustomizeIngressTime
                ? {
                    start: '00:00',
                    end: '23:59',
                  }
                : !ingress24Hrs && enableCustomizeIngressTime
                ? alertRules.ingress.time
                : undefined,

            vehicle:
              ingressAllVehicles && !ingressCustomizeVehicle
                ? ['all']
                : !ingressAllVehicles && ingressCustomizeVehicle
                ? selectedOptionsIngressVehicles?.length
                  ? selectedOptionsIngressVehicles?.map((val) => val.label)
                  : []
                : [],
          }
          return data
        }
        return undefined
      } else if (alert === 'egress') {
        if (egress) {
          const data = {
            time:
              egress24Hrs && !enableCustomizeEgressTime
                ? {
                    start: '00:00',
                    end: '23:59',
                  }
                : !egress24Hrs && enableCustomizeEgressTime
                ? alertRules.egress.time
                : undefined,

            vehicle:
              egressAllVehicles && !egressCustomizeVehicle
                ? ['all']
                : !egressAllVehicles && egressCustomizeVehicle
                ? selectedOptionsEgressVehicles?.length
                  ? selectedOptionsEgressVehicles?.map((val) => val.label)
                  : []
                : [],
          }
          return data
        }
        return undefined
      } else if (alert === 'dwellTime') {
        if (dwellTime) {
          const data = {
            time: {
              conditions: alertRules.dwellTime.time?.conditions,
            },
            vehicle:
              dwellAllVehicles && !enableDwellTimeCustomizeVehicle
                ? ['all']
                : !dwellAllVehicles && enableDwellTimeCustomizeVehicle
                ? selectedOptionsDwellVehicles?.length
                  ? selectedOptionsDwellVehicles?.map((val) => val.label)
                  : []
                : [],
          }
          return data
        }
        return undefined
      }
      return undefined
    }
    const isRequiredFenceIdentifierDataSubmitted =
      formData.fence_name &&
      label?.length &&
      label_owner?.length &&
      fence_owner?.length &&
      city?.length &&
      address &&
      dmsLatLng
        ? true
        : false

    if (
      isRequiredFenceIdentifierDataSubmitted &&
      !ingress &&
      !egress &&
      !dwellTime
    ) {
      setIsSubmitEnable(true)
      return
    }

    if (
      isRequiredFenceIdentifierDataSubmitted &&
      ingress &&
      isAlertDataSubmiited('ingress') &&
      !egress &&
      !dwellTime
    ) {
      setIsSubmitEnable(true)
      return
    }
    if (
      isRequiredFenceIdentifierDataSubmitted &&
      ingress &&
      isAlertDataSubmiited('ingress') &&
      !egress &&
      dwellTime &&
      isAlertDataSubmiited('dwellTime')
    ) {
      setIsSubmitEnable(true)
      return
    }
    if (
      isRequiredFenceIdentifierDataSubmitted &&
      ingress &&
      isAlertDataSubmiited('ingress') &&
      egress &&
      isAlertDataSubmiited('egress') &&
      !dwellTime
    ) {
      setIsSubmitEnable(true)
      return
    }
    if (
      isRequiredFenceIdentifierDataSubmitted &&
      !ingress &&
      egress &&
      isAlertDataSubmiited('egress') &&
      !dwellTime
    ) {
      setIsSubmitEnable(true)
      return
    }
    if (
      isRequiredFenceIdentifierDataSubmitted &&
      !ingress &&
      egress &&
      isAlertDataSubmiited('egress') &&
      dwellTime &&
      isAlertDataSubmiited('dwellTime')
    ) {
      setIsSubmitEnable(true)
      return
    }
    if (
      isRequiredFenceIdentifierDataSubmitted &&
      !ingress &&
      !egress &&
      dwellTime &&
      isAlertDataSubmiited('dwellTime')
    ) {
      setIsSubmitEnable(true)
      return
    }

    if (
      isRequiredFenceIdentifierDataSubmitted &&
      ingress &&
      isAlertDataSubmiited('ingress') &&
      egress &&
      isAlertDataSubmiited('egress') &&
      dwellTime &&
      isAlertDataSubmiited('dwellTime')
    ) {
      setIsSubmitEnable(true)
      return
    }

    setIsSubmitEnable(false)
  }, [
    formData,
    alertRules,
    dmsLatLng,
    ingress,
    egress,
    dwellTime,
    city,
    address,
    dwellAllVehicles,
    egress24Hrs,
    egressAllVehicles,
    egressCustomizeVehicle,
    enableCustomizeEgressTime,
    enableCustomizeIngressTime,
    enableDwellTimeCustomizeVehicle,
    ingress24Hrs,
    ingressAllVehicles,
    ingressCustomizeVehicle,
    selectedOptionsDwellVehicles,
    selectedOptionsEgressVehicles,
    selectedOptionsIngressVehicles,
  ])

  useEffect(() => {
    if (subcribedRnNums.length === 0) {
      GetSubsRnNums()
    }
  }, [GetSubsRnNums, subcribedRnNums])

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false)
    }
  }, [isInitialPageLoad])

  useEffect(() => {
    if (Object.keys(markerDrawn)?.length > 0 && !isInitialPageLoad) {
      const bool = Object.values(markerDrawn).every((e) => {
        return (
          e !== null && e !== undefined && e !== 0 && e !== '' && e.length !== 0
        )
      })
      if (bool) {
        const name = markerDrawn.fenceShape
        const latLongArray = [markerDrawn.latLong[0], markerDrawn.latLong[1]]
        if (name === 'marker') {
          setMarkersLatLong({
            ...markersLatLong,
            ...latLongArray,
          })
          latlongToAddressAction({
            lat: latLongArray[0],
            lng: latLongArray[1],
          })
          const cityBeforeCalculation = calculatedNearestCity
          const nearestCity = getNearestCityByCoordinates({ lat: latLongArray[0], long: latLongArray[1] })
          setCalculatedNearestCity(nearestCity)

          const selectedCity = geoFenceLocationData?.filter(x => x.value === nearestCity)
          if(selectedCity && selectedCity.length > 0 && nearestCity !== cityBeforeCalculation){
            selectHandler({label: selectedCity[0].label, value: selectedCity[0].value}, 'city')
          }

        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerDrawn, latlongToAddressAction, geoFenceLocationData])

  const data = useMemo(() => {
    if (
      fenceId !== null &&
      fenceDetail !== null &&
      geoFanceLabelData?.length &&
      geoFanceLabelOwnersData?.length &&
      geoFanceOwnersData?.length &&
      subcribedRnNums?.length
    ) {
      return { fenceId, fenceDetail }
    }
  }, [
    fenceId,
    fenceDetail,
    geoFanceLabelData,
    geoFanceLabelOwnersData,
    geoFanceOwnersData,
    subcribedRnNums,
  ])

  const isButtonDisabled = useMemo(() => {
    if (fenceDetail !== null) {
      let user = localStorage.getItem('user')
      user = user ? JSON.parse(user) : null
      if (fenceDetail?._meta?.createdBy === user?._id) {
        return false
      }
    }
    return true
  }, [fenceDetail])

  useEffect(() => {
    if (isFenceApiOperationSuccess || fenceId === null) {
      setFormData(initialStateFormData)
      setAlertRules(initialStateAlertsData)
      setIngress(false)
      setEgress(false)
      setDwellTime(false)
      setIngress24Hrs(true)
      setEgress24Hrs(true)
      setIngressAllVehicles(true)
      setEgressAllVehicles(true)
      setDwellAllVehicles(true)
      setFromTimeHHValue('')
      setFromTimeMMValue('')
      setToTimeHHValue('')
      setToTimeMMValue('')
      setEgressFromHH('')
      setEgressFromMM('')
      setEgressToHH('')
      setEgressToMM('')
      setSelectedItemsProps([])

      setEnableCustomizeIngressTime(false)
      setIngressCustomizeVehicle(false)
      setEgressCustomizeVehicle(false)
      setEnableCustomizeEgressTime(false)
      setEnableDwellTimeCustomizeVehicle(false)

      setSelectedOptionsIngressVehicles([])
      setSelectedOptionsEgressVehicles([])
      setSelectedOptionsDwellVehicles([])
      latlongToAddressAction(null)
      setCalculatedNearestCity('')

      if (fenceId) {
        setTimeout(() => {
          navigate(`/geo-fence/dashboard`)
        }, 2500)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFenceApiOperationSuccess, fenceId])

  const [selectedItemsProps, setSelectedItemsProps] = useState([])

  useEffect(() => {
    if (data) {
      /* Set fence identifiers, amenities and fence geometry details */
      setFormData({
        fence_name: data.fenceDetail?.fence_name || '',
        fence_identifiers: {
          label:
            geoFanceLabelData?.filter(
              (val) => val._id === data.fenceDetail?.fence_identifiers?.label
            ) || [],
          label_owner:
            geoFanceLabelOwnersData?.filter(
              (val) =>
                val._id === data.fenceDetail?.fence_identifiers?.label_owner
            ) || [],
          fence_owner:
            geoFanceOwnersData?.filter(
              (val) =>
                val._id === data.fenceDetail?.fence_identifiers?.fence_owner
            ) || [],
          city: data.fenceDetail?.fence_identifiers?.city || '',
          address: data.fenceDetail?.fence_identifiers?.address || '',
        },
        amenities: data.fenceDetail?.amenities || {
          charging: false,
          parking: false,
          servicing: false,
          storage: false,
          hiring_desk: false,
          loading_unloading: false,
        },
      })
      let customizeIngressTime = false
      let customizeEgressTime = false
      /* Set ingress alert */
      if (data.fenceDetail?.alert_rules?.ingress) {
        let isIngressApplicable = false
        if (data.fenceDetail?.alert_rules.ingress?.time) {
          const { start, end } = data.fenceDetail?.alert_rules.ingress?.time
          if (start && end) {
            if (start === '00:00' && end === '23:59') {
              setIngress24Hrs(true)
              setEnableCustomizeIngressTime(false)
            } else {
              customizeIngressTime = true
              setIngress24Hrs(false)
              setEnableCustomizeIngressTime(true)

              handleTimeChange(
                'fromHH',
                { target: { value: start.split(':')[0] } },
                'ingress',
                true
              )
              handleTimeChange(
                'fromMM',
                { target: { value: start.split(':')[1] } },
                'ingress',
                true
              )

              handleTimeChange(
                'toHH',
                { target: { value: end.split(':')[0] } },
                'ingress',
                true
              )
              handleTimeChange(
                'toMM',
                { target: { value: end.split(':')[1] } },
                'ingress',
                true
              )
              isIngressApplicable = true
            }
          }
        }
        if (data.fenceDetail?.alert_rules.ingress?.vehicle?.length) {
          const vehicle = data.fenceDetail?.alert_rules.ingress?.vehicle
          if (vehicle[0] === 'all') {
            setIngressAllVehicles(true)
            setIngressCustomizeVehicle(false)
          } else {
            setIngressAllVehicles(false)
            setIngressCustomizeVehicle(true)
            setSelectedOptionsIngressVehicles(
              subcribedRnNums?.filter((val) => vehicle?.includes(val.label))
            )
          }
          isIngressApplicable = true
        }
        setIngress(isIngressApplicable)
      }

      /* Set egress alert */
      if (data.fenceDetail?.alert_rules?.egress) {
        let isEgressApplicable = false

        if (data.fenceDetail?.alert_rules.egress?.time) {
          const { start, end } = data.fenceDetail?.alert_rules.egress?.time
          if (start && end) {
            if (start === '00:00' && end === '23:59') {
              setEgress24Hrs(true)
              setEnableCustomizeEgressTime(false)
            } else {
              customizeEgressTime = true
              setEgress24Hrs(false)
              setEnableCustomizeEgressTime(true)

              handleTimeChange(
                'egressFromHH',
                { target: { value: start.split(':')[0] } },
                'egress',
                true
              )
              handleTimeChange(
                'egressFromMM',
                { target: { value: start.split(':')[1] } },
                'egress',
                true
              )

              handleTimeChange(
                'egressToHH',
                { target: { value: end.split(':')[0] } },
                'egress',
                true
              )
              handleTimeChange(
                'egressToMM',
                { target: { value: end.split(':')[1] } },
                'egress',
                true
              )
            }
            isEgressApplicable = true
          }
        }
        if (data.fenceDetail?.alert_rules.egress?.vehicle?.length) {
          const vehicle = data.fenceDetail?.alert_rules.egress?.vehicle
          if (vehicle[0] === 'all') {
            setEgressAllVehicles(true)
            setEgressCustomizeVehicle(false)
          } else {
            setEgressAllVehicles(false)
            setEgressCustomizeVehicle(true)
            setSelectedOptionsEgressVehicles(
              subcribedRnNums?.filter((val) => vehicle?.includes(val.label))
            )
          }
          isEgressApplicable = true
        }
        setEgress(isEgressApplicable)
      }

      if (customizeIngressTime && customizeEgressTime) {
        setTimeout(() => {
          setAlertRules({
            ...alertRules,
            ingress: {
              ...alertRules.ingress.vehicle,
              time: data.fenceDetail.alert_rules.ingress.time,
            },
            egress: {
              ...alertRules.egress.vehicle,
              time: data.fenceDetail.alert_rules.egress.time,
            },
          })
        }, 500)
      } else if (customizeIngressTime) {
        setTimeout(() => {
          setAlertRules({
            ...alertRules,
            ingress: {
              ...alertRules.ingress.vehicle,
              time: data.fenceDetail.alert_rules.ingress.time,
            },
          })
        }, 500)
      } else if (customizeEgressTime) {
        setTimeout(() => {
          setAlertRules({
            ...alertRules,
            egress: {
              ...alertRules.egress.vehicle,
              time: data.fenceDetail.alert_rules.egress.time,
            },
          })
        }, 500)
      }

      /* Set dwellTime alert */
      if (data.fenceDetail?.alert_rules?.dwell_time) {
        let isDwellTimeApplicable = false

        if (data.fenceDetail?.alert_rules.dwell_time?.time) {
          const { conditions } = data.fenceDetail?.alert_rules.dwell_time?.time
          if (conditions) {
            if (Object.values(conditions || {})?.some((val)=> val === true)) {
              setAlertRules({
                ...alertRules,
                dwellTime: {
                  ...alertRules.dwellTime.vehicle,
                  time: {
                    ...alertRules.dwellTime.time,
                    conditions: conditions || alertRules.dwellTime.time.conditions,
                  },
                },
              })
              isDwellTimeApplicable = true
            }
          }
        }
        if (data.fenceDetail?.alert_rules.dwell_time?.vehicle?.length) {
          const vehicle = data.fenceDetail?.alert_rules.dwell_time?.vehicle
          if (vehicle[0] === 'all') {
            setDwellAllVehicles(true)
            setEnableDwellTimeCustomizeVehicle(false)
          } else {
            setDwellAllVehicles(false)
            setEnableDwellTimeCustomizeVehicle(true)
            setSelectedOptionsDwellVehicles(
              subcribedRnNums?.filter((val) => vehicle?.includes(val.label))
            )
          }
          isDwellTimeApplicable = true
        }
        setDwellTime(isDwellTimeApplicable)
      }

      /* Set email Ids */
      if (data.fenceDetail?.alert_rules?.notification?.email_ids?.length) {
        const email_ids = data.fenceDetail?.alert_rules?.notification.email_ids
        setSelectedItemsProps(email_ids)
      } else {
        setSelectedItemsProps([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onEmailsChanged = (emails) => {
    setEmailIds(emails)
  }

  const getNearestCityByCoordinates = ({lat, long}) => {
    let nearestCity = null
    let minDistance = Number.MAX_VALUE
    
    if(!lat || !long) {
      return nearestCity
    }
    geoFenceLocationData?.forEach(city => {
      let distance = calculateDistance(lat, long, city.meta.lat, city.meta.long);
      if (distance < minDistance) {
          minDistance = distance;
          nearestCity = city.value;
      }
    })
    return nearestCity
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the Earth in kilometers
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = R * c; // Distance in kilometers
    return distance;
}

  return (
    <div
      className="col-4 col-md-5 col-lg-4 fsc-container"
      style={{
        overflowY: 'scroll',
        height: 'Calc(100vh - 73px)',
      }}
    >
      <div className="drawer-container ms-2">
        <div className="col-12">
          <div className="heading-container-create mt-3 mb-3">
            <div className="fence-heading">
              {fenceId === null ? 'Create' : 'Edit'}
            </div>
          </div>
        </div>

        <div className="col-12 ps-1">
          <Row>
            <div className="col-5 d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-10 indentifiers-label">
                  {' '}
                  Fence Name <span className="asterisk">*</span>{' '}
                </div>
                <div className="col-1 indentifiers-label p-0"> : </div>
              </div>
            </div>
            <div className={`col-7 p-0 pe-2  ${!formData.fence_name[0]? 'no-value-selected-input' : ''}`}>
              <input
                type="text"
                className="fence-name-input"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    fence_name: e.target.value,
                  })
                }}
                value={formData.fence_name}
                data-testid='fence-name-input'
              />
            </div>
          </Row>
          <Row className="mt-2">
            <div className="col-5 d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-10 indentifiers-label">
                  {' '}
                  Label <span className="asterisk">*</span>{' '}
                </div>
                <div className="col-1 indentifiers-label p-0"> : </div>
              </div>
            </div>
            <div className= {`col-7 p-0 pe-2 ${!label[0] ? 'no-value-selected' : ''}`}>
              <SingleSelect
                placeholder=""
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="180px"
                valueContainerMaxWidthInPx="180px"
                optionPosition="ps-2 justify-content-start"
                options={geoFanceLabelData}
                selectedOptions={label}
                onSelectHandler={(data) => {
                  selectHandler(data, 'label')
                }}
                maxMenuHeight={140}
                customBackgroundColor="#f5f7f9"
                data-testid='fence-label-select'
              />
            </div>
          </Row>
          <Row className="mt-2">
            <div className="col-5 d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-10 indentifiers-label">
                  {' '}
                  Label Owner <span className="asterisk">*</span>{' '}
                </div>
                <div className="col-1 indentifiers-label p-0"> : </div>
              </div>
            </div>
            <div className={`col-7 p-0 pe-2 ${!label_owner[0] ? 'no-value-selected' : ''}`} >
              <SingleSelect
                placeholder=""
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="180px"
                valueContainerMaxWidthInPx="180px"
                optionPosition="ps-2 justify-content-start"
                options={geoFanceLabelOwnersData}
                selectedOptions={label_owner}
                onSelectHandler={(data) => {
                  selectHandler(data, 'label_owner')
                }}
                maxMenuHeight={140}
                customBackgroundColor="#f5f7f9"
                data-testid='fence-labelowner-select'
              />
            </div>
          </Row>
          <Row className="mt-2">
            <div className="col-5 d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-10 indentifiers-label">
                  {' '}
                  Fence Owner <span className="asterisk">*</span>{' '}
                </div>
                <div className="col-1 indentifiers-label p-0"> : </div>
              </div>
            </div>
            <div className={`col-7 p-0 pe-2 ${!fence_owner[0] ? 'no-value-selected' : ''}`}>
              <SingleSelect
                placeholder=""
                placeholderOnError=""
                webkitScrollbarWidth="5px"
                singleValueColor="#1F2A5D"
                valueContainerJustifyContent="start"
                valueContainerWidthInPx="180px"
                valueContainerMaxWidthInPx="180px"
                optionPosition="ps-2 justify-content-start"
                options={geoFanceOwnersData}
                selectedOptions={fence_owner}
                onSelectHandler={(data) => {
                  selectHandler(data, 'fence_owner')
                }}
                maxMenuHeight={140}
                customBackgroundColor="#f5f7f9"
                data-testid='fence-fenceowner-select'
              />
            </div>
          </Row>
        </div>

        <div className="amenities-container">
          <div className="amenities-header">Amenities</div>
          <div className="col-12">
            <Row>
              <Row className="col-12 p-0">
                <div className="col-5  ps-4 pe-0 ms-2">
                  {!charging && (
                    <IoIosSquareOutline
                      color="#707070"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('charging')
                      }}
                      data-testid='fence-amenity-checkbox-charging'
                    />
                  )}
                  {charging && (
                    <IoIosCheckbox
                      color="#22046B"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('charging')
                      }}
                      data-testid='fence-amenity-checkbox-charging'
                    />
                  )}
                  <img alt="chargingIcon" src={ChargingIcon} />
                  <label htmlFor="chargingCheckbox">Charging</label>
                </div>
                <div className="col-7 pe-0 ps-4 negative-ml">
                  {!parking && (
                    <IoIosSquareOutline
                      color="#707070"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('parking')
                      }}
                      data-testid='fence-amenity-checkbox-parking'
                    />
                  )}
                  {parking && (
                    <IoIosCheckbox
                      color="#22046B"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('parking')
                      }}
                      data-testid='fence-amenity-checkbox-parking'
                    />
                  )}
                  <img alt="ParkingIcon" src={ParkingIcon} />
                  <label htmlFor="parkingCheckbox">Parking</label>
                </div>
              </Row>
            </Row>
            <Row className="mt-3">
              <Row className="col-12 p-0">
                <div className="col-5  ps-4 pe-0 ms-2">
                  {!servicing && (
                    <IoIosSquareOutline
                      color="#707070"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('servicing')
                      }}
                      data-testid='fence-amenity-checkbox-servicing'
                    />
                  )}
                  {servicing && (
                    <IoIosCheckbox
                      color="#22046B"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('servicing')
                      }}
                      data-testid='fence-amenity-checkbox-servicing'
                    />
                  )}
                  <img alt="ServiceIcon" src={ServiceIcon} />
                  <label htmlFor="servicingCheckbox">Servicing</label>
                </div>
                <div className="col-7 pe-0 ps-4 negative-ml">
                  {!storage && (
                    <IoIosSquareOutline
                      color="#707070"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('storage')
                      }}
                      data-testid='fence-amenity-checkbox-storage'
                    />
                  )}
                  {storage && (
                    <IoIosCheckbox
                      color="#22046B"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('storage')
                      }}
                      data-testid='fence-amenity-checkbox-storage'
                    />
                  )}
                  <img alt="StorageIcon" src={StorageIcon} />
                  <label htmlFor="storageCheckbox">Storage</label>
                </div>
              </Row>
            </Row>
            <Row className="mt-3">
              <Row className="col-12 p-0">
                <div className="col-5  ps-4 pe-0 ms-2">
                  {!hiring_desk && (
                    <IoIosSquareOutline
                      color="#707070"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('hiring_desk')
                      }}
                      data-testid='fence-amenity-checkbox-hiringdesk'
                    />
                  )}
                  {hiring_desk && (
                    <IoIosCheckbox
                      color="#22046B"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('hiring_desk')
                      }}
                      data-testid='fence-amenity-checkbox-hiringdesk'
                    />
                  )}
                  <img alt="HiringDeskIcon" src={HiringDeskIcon} />
                  <label className="pe-0" htmlFor="hiringCheckbox">
                    Hiring Desk
                  </label>
                </div>
                <div className="col-7 pe-0 ps-4 negative-ml">
                  {!loading_unloading && (
                    <IoIosSquareOutline
                      color="#707070"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('loading_unloading')
                      }}
                      data-testid='fence-amenity-checkbox-unloading'
                    />
                  )}
                  {loading_unloading && (
                    <IoIosCheckbox
                      color="#22046B"
                      size={25}
                      onClick={() => {
                        handleCheckBoxChange('loading_unloading')
                      }}
                      data-testid='fence-amenity-checkbox-unloading'
                    />
                  )}
                  <img alt="LoadingIcon" src={LoadingIcon} />
                  <label className="pe-0" htmlFor="loadingCheckbox">
                    Loading/Unloading
                  </label>
                </div>
              </Row>
            </Row>
          </div>
        </div>

        <div className="alerts-container">
          <div className="ms-2 mt-1">
            <div className="alerts-header">Alerts</div>

            <div>
              <div className="d-flex align-items-center flex-row justify-content-between pe-3 ps-1">
                <div className="alerts-operation">Ingress</div>
                <div className="material-switch pe-5">
                  <input
                    id="switch-ingress"
                    name="switch-ingress"
                    type="checkbox"
                    checked={ingress}
                    onChange={() => {
                      setIngress(!ingress)
                    }}
                    data-testid='fence-ingress-switch'
                  />
                  <label htmlFor="switch-ingress"></label>
                </div>
              </div>
              {ingress && (
                <div className="alerts-operation-cdn">
                  <p className="mb-2">Select Time</p>

                  <div className="ps-3">
                    <input
                      type="radio"
                      className="fsc-radio-input"
                      id="ingress-radio-24hrs"
                      checked={ingress24Hrs}
                      onClick={() => {
                        setIngress24Hrs(!ingress24Hrs)
                        setEnableCustomizeIngressTime(false)
                      }}
                      onChange={(e) => {}}
                      data-testid='fence-ingress-24hrs-radio'
                    />
                    <label className="ps-2" htmlFor="anytime">
                      24 Hrs
                    </label>

                    <div className="mt-1">
                      <input
                        type="radio"
                        className="fsc-radio-input"
                        id="ingress-radio-customize"
                        checked={enableCustomizeIngressTime}
                        onClick={() => {
                          setIngress24Hrs(false)
                          setEnableCustomizeIngressTime(
                            !enableCustomizeIngressTime
                          )
                        }}
                        onChange={(e) => {}}
                        data-testid='fence-ingress-customize-radio'
                      />
                      <label className="ps-2" htmlFor="ingresss">
                        Customize
                      </label>
                    </div>

                    {enableCustomizeIngressTime && (
                      <div className="mt-2 ms-4">
                        <div className="d-flex flex-row col-12">
                          <div className="col-2 d-flex flex-column align-items-center">
                            <p className="m-0 customize-time-label">HH</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              value={fromTimeValueHH}
                              maxLength={2}
                              onChange={(e) => {
                                handleTimeChange('fromHH', e, 'ingress')
                              }}
                              data-testid='fence-ingress-from-hrs-input'
                            />
                          </div>

                          <div className="col-2 d-flex flex-column align-items-center ms-1">
                            <p className="m-0 customize-time-label">MM</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              value={fromTimeValueMM}
                              maxLength={2}
                              ref={ingressFromMM}
                              onChange={(e) => {
                                handleTimeChange('fromMM', e, 'ingress')
                              }}
                              data-testid='fence-ingress-from-mins-input'
                            />
                          </div>

                          <div className="col-1 d-flex flex-column align-items-center">
                            <p className="m-0 mt-4 customize-time-label">to</p>
                          </div>

                          <div className="col-2 d-flex flex-column align-items-center">
                            <p className="m-0 customize-time-label">HH</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              value={toTimeValueHH}
                              maxLength={2}
                              ref={ingressToHH}
                              onChange={(e) => {
                                handleTimeChange('toHH', e, 'ingress')
                              }}
                              data-testid='fence-ingress-to-hrs-input'
                            />
                          </div>

                          <div className="col-2 d-flex flex-column align-items-center ms-1">
                            <p className="m-0 customize-time-label">MM</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              value={toTimeValueMM}
                              maxLength={2}
                              ref={ingressToMM}
                              onChange={(e) => {
                                handleTimeChange('toMM', e, 'ingress')
                              }}
                              data-testid='fence-ingress-to-mins-input'
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <p className="mb-2 mt-3">Select Vehicle</p>

                  <div className="ps-3">
                    <input
                      type="radio"
                      className="fsc-radio-input"
                      id="allVehicles"
                      checked={ingressAllVehicles}
                      onClick={() => {
                        setIngressCustomizeVehicle(false)
                        setIngressAllVehicles(!ingressAllVehicles)
                      }}
                      onChange={(e) => {}}
                      data-testid='fence-ingress-all-vehicles-radio'
                    />
                    <label className="ps-2" htmlFor="allVehicles">
                      All Vehicles
                    </label>

                    <div className="mt-1">
                      <input
                        type="radio"
                        className="fsc-radio-input"
                        id="ingressVehicles"
                        checked={ingressCustomizeVehicle}
                        onClick={() => {
                          setIngressAllVehicles(false)
                          setIngressCustomizeVehicle(!ingressCustomizeVehicle)
                        }}
                        onChange={(e) => {}}
                        data-testid='fence-ingress-customize-vehicles-radio'
                      />
                      <label className="ps-2" htmlFor="ingressVehicles">
                        Customize
                      </label>
                    </div>
                    {ingressCustomizeVehicle && (
                      <div className="col-10 mt-2 mb-2 me-4 pe-3">
                        <MultiSelect
                          customBackgroundColorReq={true}
                          placeholder=""
                          fontWeight="500"
                          webkitScrollbarWidth="8px"
                          indicatorSeparatorDisplay="none"
                          customBackgroundColor="#f5f7f9"
                          options={subcribedRnNums}
                          selectedOptions={selectedOptionsIngressVehicles}
                          isMultiSelect={true}
                          setSelectedOptions={setSelectedOptionsIngressVehicles}
                          data-testid='fence-ingress-vehicle-select'
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* egress */}
            <div className="mt-3">
              <div className="d-flex align-items-center flex-row justify-content-between pe-3 ps-1">
                <div className="alerts-operation">Egress</div>
                <div className="material-switch pe-5">
                  <input
                    id="switch-egress"
                    name="switch-egress"
                    type="checkbox"
                    checked={egress}
                    onChange={() => {
                      setEgress(!egress)
                    }}
                    data-testid='fence-egress-switch'
                  />
                  <label htmlFor="switch-egress"></label>
                </div>
              </div>
              {egress && (
                <div className="alerts-operation-cdn">
                  <p className="mb-2">Select Time</p>

                  <div className="ps-3">
                    <input
                      type="radio"
                      className="fsc-radio-input"
                      id="egress24Hrs"
                      onClick={() => {
                        setEnableCustomizeEgressTime(false)
                        setEgress24Hrs(!egress24Hrs)
                      }}
                      checked={egress24Hrs}
                      onChange={(e) => {}}
                      data-testid='fence-egress-24hrs-radio'
                    />
                    <label className="ps-2" htmlFor="egress24Hrs">
                      24 Hrs
                    </label>

                    <div className="mt-1">
                      <input
                        type="radio"
                        id="egressCustomize"
                        className="fsc-radio-input"
                        checked={enableCustomizeEgressTime}
                        onClick={() => {
                          setEgress24Hrs(false)
                          setEnableCustomizeEgressTime(
                            !enableCustomizeEgressTime
                          )
                        }}
                        onChange={(e) => {}}
                        data-testid='fence-egress-customize-radio'
                      />
                      <label className="ps-2" htmlFor="egressCustomize">
                        Customize
                      </label>
                    </div>

                    {enableCustomizeEgressTime && (
                      <div className="mt-2 ms-4">
                        <div className="d-flex flex-row col-12">
                          <div className="col-2 d-flex flex-column align-items-center">
                            <p className="m-0 customize-time-label">HH</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              value={egressFromHH}
                              maxLength={2}
                              onChange={(e) => {
                                handleTimeChange('egressFromHH', e, 'egress')
                              }}
                              data-testid='fence-egress-from-hrs-input'
                            />
                          </div>

                          <div className="col-2 d-flex flex-column align-items-center ms-1">
                            <p className="m-0 customize-time-label">MM</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              maxLength={2}
                              ref={egressFromMMRef}
                              value={egressFromMM}
                              onChange={(e) => {
                                handleTimeChange('egressFromMM', e, 'egress')
                              }}
                              data-testid='fence-egress-from-mins-input'
                            />
                          </div>

                          <div className="col-1 d-flex flex-column align-items-center">
                            <p className="m-0 mt-4 customize-time-label">to</p>
                          </div>

                          <div className="col-2 d-flex flex-column align-items-center">
                            <p className="m-0 customize-time-label">HH</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              maxLength={2}
                              ref={egressToHHRef}
                              value={egressToHH}
                              onChange={(e) => {
                                handleTimeChange('egressToHH', e, 'egress')
                              }}
                              data-testid='fence-egress-to-hrs-input'
                            />
                          </div>

                          <div className="col-2 d-flex flex-column align-items-center ms-1">
                            <p className="m-0 customize-time-label">MM</p>
                            <input
                              className="w-100 customize-time-input"
                              type="text"
                              maxLength={2}
                              ref={egressToMMRef}
                              value={egressToMM}
                              onChange={(e) => {
                                handleTimeChange('egressToMM', e, 'egress')
                              }}
                              data-testid='fence-egress-to-mins-input'
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <p className="mb-2 mt-3">Select Vehicle</p>

                  <div className="ps-3">
                    <input
                      type="radio"
                      id="allEgressVehicles"
                      className="fsc-radio-input"
                      checked={egressAllVehicles}
                      onClick={() => {
                        setEgressAllVehicles(!egressAllVehicles)
                        setEgressCustomizeVehicle(false)
                      }}
                      onChange={(e) => {}}
                      data-testid='fence-egress-all-vehicles-radio'
                    />
                    <label className="ps-2" htmlFor="allEgressVehicles">
                      All Vehicles
                    </label>

                    <div className="mt-1">
                      <input
                        type="radio"
                        id="egressVehicle"
                        className="fsc-radio-input"
                        checked={egressCustomizeVehicle}
                        onClick={() => {
                          setEgressAllVehicles(false)
                          setEgressCustomizeVehicle(!egressCustomizeVehicle)
                        }}
                        onChange={(e) => {}}
                        data-testid='fence-egress-customize-vehicles-radio'
                      />
                      <label className="ps-2" htmlFor="egressVehicle">
                        Customize
                      </label>
                    </div>
                    {egressCustomizeVehicle && (
                      <div className="col-10 mt-2 mb-2 me-4 pe-3">
                        <MultiSelect
                          customBackgroundColorReq={true}
                          placeholder=""
                          fontWeight="500"
                          webkitScrollbarWidth="8px"
                          indicatorSeparatorDisplay="none"
                          customBackgroundColor="#f5f7f9"
                          options={subcribedRnNums}
                          selectedOptions={selectedOptionsEgressVehicles}
                          isMultiSelect={true}
                          setSelectedOptions={setSelectedOptionsEgressVehicles}
                          data-testid='fence-egress-vehicle-select'
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              <div className="d-flex align-items-center flex-row justify-content-between pe-3 ps-1">
                <div className="alerts-operation">Dwell Time</div>
                <div className="material-switch pe-5">
                  <input
                    id="switch-dwell-time"
                    name="switch-dwell-time"
                    type="checkbox"
                    checked={dwellTime}
                    onChange={() => {
                      setDwellTime(!dwellTime)
                    }}
                    data-testid='fence-dwell-switch'
                  />
                  <label htmlFor="switch-dwell-time"></label>
                </div>
              </div>

              {dwellTime && (
                <div className="alerts-operation-cdn">
                  <div className="mt-2">
                    <div className="col-12">
                      <Row>
                        <Row className="col-12 p-0">
                          <div className="col-5  ps-3 pe-0 ms-1">
                            {!isLTE6Hrs && (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isLTE6Hrs')
                                }}
                                data-testid='fence-dwell-lte6hrs-radio'
                              />
                            )}
                            {isLTE6Hrs && (
                              <IoIosCheckbox
                                color="#22046B"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isLTE6Hrs')
                                }}
                                data-testid='fence-dwell-lte6hrs-radio'
                              />
                            )}

                            <label className="dwell-time-cdns-label">
                              {' '}
                              {'<='} 6hrs
                            </label>
                          </div>
                          <div className="col-7 pe-0 ps-3 negative-ml">
                            {!isIn6To12Hrs && (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isIn6To12Hrs')
                                }}
                                data-testid='fence-dwell-in6to12hrs-radio'
                              />
                            )}
                            {isIn6To12Hrs && (
                              <IoIosCheckbox
                                color="#1f2a5d"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isIn6To12Hrs')
                                }}
                                data-testid='fence-dwell-in6to12hrs-radio'
                              />
                            )}

                            <label className="dwell-time-cdns-label">
                            {' '}
                              6-12hrs
                            </label>
                          </div>
                        </Row>
                      </Row>
                      <Row className="mt-2">
                        <Row className="col-12 p-0">
                          <div className="col-5  ps-3 pe-0 ms-1">
                            {!isIn12To24Hrs && (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isIn12To24Hrs')
                                }}
                                data-testid='fence-dwell-in12to24hrs-radio'
                              />
                            )}
                            {isIn12To24Hrs && (
                              <IoIosCheckbox
                                color="#1f2a5d"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isIn12To24Hrs')
                                }}
                                data-testid='fence-dwell-in12to24hrs-radio'
                              />
                            )}
                            <label className="dwell-time-cdns-label">
                            {' '}
                              12-24hrs
                            </label>
                          </div>
                          <div className="col-7 pe-0 ps-3 negative-ml">
                            {!isIn24To48Hrs && (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isIn24To48Hrs')
                                }}
                                data-testid='fence-dwell-in24to48hrs-radio'
                              />
                            )}
                            {isIn24To48Hrs && (
                              <IoIosCheckbox
                                color="#1f2a5d"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isIn24To48Hrs')
                                }}
                                data-testid='fence-dwell-in24to48hrs-radio'
                              />
                            )}
                            <label className="dwell-time-cdns-label">
                            {' '}
                              24-48hrs
                            </label>
                          </div>
                        </Row>
                      </Row>
                      <Row className="mt-2">
                        <Row className="col-12 p-0">
                          <div className="col-5  ps-3 pe-0 ms-1">
                            {!isGT48Hrs && (
                              <IoIosSquareOutline
                                color="#707070"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isGT48Hrs')
                                }}
                                data-testid='fence-dwell-gt48hrs-radio'
                              />
                            )}
                            {isGT48Hrs && (
                              <IoIosCheckbox
                                color="#1f2a5d"
                                size={25}
                                onClick={() => {
                                  handleCdnsCheckBoxChange('isGT48Hrs')
                                }}
                                data-testid='fence-dwell-gt48hrs-radio'
                              />
                            )}
                            <label className="dwell-time-cdns-label">
                              {' '}
                              {'>'} 48hrs
                            </label>
                          </div>
                        </Row>
                      </Row>
                    </div>

                    <p className="mb-2 mt-3">Select Vehicle</p>

                    <div className="ps-3">
                      <input
                        type="radio"
                        id="allDTVehicles"
                        className="fsc-radio-input"
                        onClick={() => {
                          setEnableDwellTimeCustomizeVehicle(false)
                          setDwellAllVehicles(!dwellAllVehicles)
                        }}
                        checked={dwellAllVehicles}
                        onChange={(e) => {}}
                        data-testid='fence-dwell-all-vehicles-radio'
                      />
                      <label className="ps-2" htmlFor="allDTVehicles">
                        All Vehicles
                      </label>

                      <div className="mt-1">
                        <input
                          type="radio"
                          className="fsc-radio-input"
                          id="dwellTimeVehicle"
                          checked={enableDwellTimeCustomizeVehicle}
                          onClick={() => {
                            setDwellAllVehicles(false)
                            setEnableDwellTimeCustomizeVehicle(
                              !enableDwellTimeCustomizeVehicle
                            )
                          }}
                          onChange={(e) => {}}
                          data-testid='fence-dwell-customize-vehicles-radio'
                        />
                        <label className="ps-2" htmlFor="dwellTimeVehicle">
                          Customize
                        </label>
                      </div>
                      {enableDwellTimeCustomizeVehicle && (
                        <div className="col-10 mt-2 mb-2 me-4 pe-3">
                          <MultiSelect
                            customBackgroundColorReq={true}
                            placeholder=""
                            fontWeight="500"
                            webkitScrollbarWidth="8px"
                            indicatorSeparatorDisplay="none"
                            customBackgroundColor="#f5f7f9"
                            options={subcribedRnNums}
                            selectedOptions={selectedOptionsDwellVehicles}
                            isMultiSelect={true}
                            setSelectedOptions={setSelectedOptionsDwellVehicles}
                            data-testid='fence-dwell-vehicle-select'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-3 ms-1 pb-3">
                <p className="notify-user mb-1">Notify Modifications To</p>

                <div className="col-12">
                  <Row className="mt-2">
                    <div className="notify-user-email col-2">Email</div>
                    <div className="notify-user-email col-1">:</div>

                    <div className="col-9 pe-4">
                      <EmailTagChips
                        onItemsChanged={onEmailsChanged}
                        isButtonDisabled={isButtonDisabled}
                        selectedItemsProps={selectedItemsProps}
                        data-testid='fence-email-tags'
                      ></EmailTagChips>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fence-details-container">
          <div className="fence-details-title">Fencing</div>

          <div className="col-12 mt-2 ps-3">
            <Row>
              <div className="col-5 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-10 fence-details-data">
                    {' '}
                    City <span className="asterisk">*</span>{' '}
                  </div>
                  <div className="col-1 fence-details-data p-0"> : </div>
                </div>
              </div>
              <div className="col-7 p-0 pe-2 fence-details-data">{calculatedNearestCity}</div>
            </Row>
            <Row className="mt-2">
              <div className="col-5 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-10 fence-details-data">
                    {' '}
                    Coordinates <span className="asterisk">*</span>
                  </div>
                  <div className="col-1 fence-details-data p-0"> : </div>
                </div>
              </div>
              <div className="col-7 p-0 pe-2 fence-details-data">
                {dmsLatLng?.dmsLat && dmsLatLng?.dmsLng
                  ? dmsLatLng.dmsLat + ', ' + dmsLatLng.dmsLng
                  : ''}
              </div>
            </Row>
            <Row className="mt-2">
              <div className="col-5 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-10 fence-details-data pe-1">
                    {' '}
                    Fence Shape <span className="asterisk">*</span>
                  </div>
                  <div className="col-1 fence-details-data p-0"> : </div>
                </div>
              </div>
              <div className="col-7 p-0 pe-2 fence-details-data">
                {' '}
                {shapeName !== 'marker' ? shapeName : null}
              </div>
            </Row>
            <Row className="mt-2">
              <div className="col-5 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-10 fence-details-data">
                    {' '}
                    Fence Area <span className="asterisk">*</span>
                  </div>
                  <div className="col-1 fence-details-data p-0"> : </div>
                </div>
              </div>
              <div className="col-7 p-0 pe-2 fence-details-data">
                {Object.keys(shapeDetails)?.length > 0 &&
                  shapeName !== 'marker' && (
                    <span>
                      {`${shapeDetails?.fenceArea?.toFixed(2)}  ${
                        shapeDetails?.unit
                      }`}
                    </span>
                  )}
              </div>
            </Row>
          </div>
        </div>
      </div>

      <div className="submit-btn-container">
        <button
          className={isSubmitEnable ? 'submit-enabled' : 'submit-disabled'}
          disabled={!isSubmitEnable}
          onClick={submitFenceDetails}
          data-testid='fence-save-button'
        >
          {fenceId === null ? 'Submit' : ' Save '}
        </button>
      </div>
      <Loader isLoading={isLoading}></Loader>
    </div>
  )
}

Sidecard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFenceApiOperationSuccess: PropTypes.bool.isRequired,
  shapeDetails: PropTypes.object.isRequired,
  markerDrawn: PropTypes.object.isRequired,
  address: PropTypes.string.isRequired,
  dmsLatLng: PropTypes.object.isRequired,

  // Action props
  CreateFence: PropTypes.func.isRequired,
  UpdateGeoFence: PropTypes.func.isRequired,
  latlongToAddressAction: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  address: state.latlongToAddress.address,
  shapeDetails: state.createFenceDifferentActions?.shapesDrawn,
  markerDrawn: state.createFenceDifferentActions?.markerDrawn,
  dmsLatLng: state.latlongToAddress?.dmsLatLng,
  subcribedRnNums: state.vehicle.subcribedRnNums,
  isLoading: state.loader.isLoading,
  isFenceApiOperationSuccess: state.fence.isFenceApiOperationSuccess,
})

export default connect(mapStateToProps, {
  CreateFence,
  latlongToAddressAction,
  GetSubsRnNums,
  UpdateGeoFence,
})(Sidecard)
