import React from 'react'
import CloseButton from 'react-bootstrap/CloseButton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './VTAddress.css'

const VTAddress = ({
  address,
  lat,
  lng,
  getAddress = ()=>{},
}) => {
  const popoverRef = React.useRef(null)
  const [isShowPopover, setIsShowPopover] = React.useState(false)
  const [isCopied, setCopied] = React.useState(false);

  const shareLink = (lat && lng) ? 'http://maps.google.com/?q=' + lat + ',' + lng : null

  const onCopyClick = () => {
    if (shareLink) {
      navigator.clipboard.writeText(shareLink)
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000)
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="vt-button-tooltip"
    bsPrefix='tooltip vt_address-custom-tooltip show'
     {...props}
     >
      Click to get address
    </Tooltip>
  );

  return (
    <div className='vt__address-info-container'>
        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 100 }}
          overlay={renderTooltip}
          >
            <img
            onClick={()=>{getAddress();}}
            className='nd__vehicle-info-headers-icons-address'
            src='/images/svgicon/address-location.svg'
            alt='address'
            data-testid={`vt-location-button`}
          />
      </OverlayTrigger>

      <p className='mx-2'>{address}</p>
      <div>
       { lat && lng && address && (<div className='d-flex ndvh__share-icon' data-testid={`vt-location-toggle`} onClick={() => setIsShowPopover(true)} ref={popoverRef}>
          <img className='cursor-pointer nd__vehicle-info-headers-icons' src='/images/svgicon/share.svg' alt='share' />
          <div style={{ display: isShowPopover ? 'block' : 'none' }}
            id='nd_vehicle-info-header-share-popover'
            className='nd_vehicle-info-header-share-popover'
          >
            <CloseButton data-testid={`vt-location-copy-close`} className='cursor-pointer float-right' onClick={(e) => { e.stopPropagation(); setIsShowPopover(false) }}></CloseButton>
            <div className='nd__vehicle-info-popover-header'>
              Share Location
            </div>
            <div className='nd__vehicle-info-popover-conetent container-fuild pt-2'>
              <div className='row'>
                <div className='col nd__vehicle-info-header-flex'>
                  {shareLink && <a data-testid={`vt-location-link`} href={shareLink} target="_blank" rel="noreferrer">{shareLink}</a>}
                  {!shareLink && <div>NA</div>}
                </div>
                <div className='col-auto'>
                  <div
                    className='cursor-pointer float-end'
                    onClick={onCopyClick}
                    data-testid={`vt-location-copy-button`}
                  >
                    <img className={`na__vehicle-info-copy ${isCopied && 'na__vehicle-info-copied'}`} src='/images/svgicon/copy.svg' alt='copy' />
                  </div>
                </div>
              </div>
              <div className='container-fuild container-fuild-hr'>
                <div className='row row-hr'></div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
      <div style={{ flex: 1 }} />
      <p className='vt__address-info-lat-lon'>{lat}, {lng}</p>
    </div>
  )
}


export default VTAddress