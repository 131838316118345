import { Button, Row, Col, Form } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { loadSavedLabelOwners, saveLabelOwners, openLabelOwnerCard } from '../../../../store/actions/geo-fence/parameterConfigurationActions'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LabelOwnerEditRow from './LabelOwnerEditRow';
import { customToast } from '../../../../utils/toasts'
import { sentenceCompare } from '../../../../utils/common-methods';
import  API_BOOK  from '../../../../service/endpoints'
import api from '../../../../service/api';

const geoFenceCheckIfLabelOwnerIsUsedConfig = API_BOOK.GEO_FENCE.MASTERS.CHECK_IF_LABEL_OWNER_IS_USED

const AddLabelOwner = (
    {
        loadSavedLabelOwners,
        openLabelOwnerCard,
        saveLabelOwners,
        labelOwnersSaved,
        labelOwnerCardOpened
    }
)=> {
    const [labelOwnerName, setLabelOwnerName] = useState('')
    const [labelOwnersEditableData, setLabelOwnersEditableData] = useState([])
    const [labelOwnerNameValidationMessage, setLabelOwnerNameValidationMessage] = useState('')
    const [isLabelOwnerNameValid, setLabelOwnerNameValid] = useState(true)
    const [deletedLabelOwnerIds, setDeletedLabelOwnerIds] = useState([])
    const [isChanged, setIsChanged] = useState(false)

 
    useEffect(()=>{
        if(labelOwnerCardOpened)
            loadSavedLabelOwners()
    },[labelOwnerCardOpened, loadSavedLabelOwners])

    useEffect(()=>{
        if(labelOwnersSaved)
        {
            const labelOwnersData = labelOwnersSaved.map((x, index)=> {
                return {
                    _id: x._id,
                    labelOwnerName: x.label,
                    isEditable: false,
                    isLabelOwnerNameValid: true,
                    labelOwnerNameValidationMessage: ''
                }
            })
            setLabelOwnersEditableData(labelOwnersData)
        }
        
    }, [labelOwnersSaved])

    const handleLabelOwnerNameChange = (e)=>{
        setLabelOwnerName(e.target.value)
        setLabelOwnerNameValid(true)
    }

    const addNewLabelOwner = ()=>{
        if(labelOwnerName === ''){
            setLabelOwnerNameValid(false)
            setLabelOwnerNameValidationMessage('Label owner name is required')
            return
        }

        const existing = labelOwnersEditableData
            .find(x=>sentenceCompare(x.labelOwnerName, labelOwnerName))

        if(existing){
            setLabelOwnerNameValid(false)
            setLabelOwnerNameValidationMessage('Label owner already present')
            return
        }

        const newLabelOwner = {
            _id: '',
            labelOwnerName: labelOwnerName
        }
        setLabelOwnersEditableData([...labelOwnersEditableData, newLabelOwner])
        setLabelOwnerName('')
        setIsChanged(true)
    }

    const save = ()=> {
        let labelOwnersEditableDataCopy = [...labelOwnersEditableData]
        let isValid = true

        if(labelOwnersEditableDataCopy.length===0){
            setTimeout(() => {
                customToast({ message: 'Add atleast one label owner to save', svgImageName: 'decline' })
            }, 400)
            return
        }
        labelOwnersEditableDataCopy = labelOwnersEditableDataCopy.map((item)=> {
            const labelOwner = {...item}
            if(!labelOwner.isEditable){
                return {
                    ...labelOwner
                }
            }
            if(labelOwner.labelOwnerName === ''){
                labelOwner.isValid = false
                labelOwner.validationMessage = 'Label owner name is required'
                isValid = false
            }
            const existing = labelOwnersEditableDataCopy
                .filter(x=>sentenceCompare(x.labelOwnerName, labelOwner.labelOwnerName))
            if(existing && existing.length>1){
                labelOwner.isValid = false
                labelOwner.validationMessage = 'Duplicate labels are not allowed'
                isValid = false
            }

            return {
                ...labelOwner
            }
        })
         
        if(!isValid){
            setLabelOwnersEditableData([...labelOwnersEditableDataCopy])
            return
        }

        saveLabelOwners({
            savedLabelOwners: labelOwnersEditableData.map(label => ({ ...label, labelOwnerName: label.labelOwnerName.trim() })),
            deletedLabelOwnerIds: deletedLabelOwnerIds
        })
    }

    const checkIfLabelOwnerIsUsed = async (labelOwner, key)=> {
        if(labelOwner._id === ''){
            return false
        }
        try {
            const resp = await api({
                method: geoFenceCheckIfLabelOwnerIsUsedConfig.method,
                url: geoFenceCheckIfLabelOwnerIsUsedConfig.url + `/${labelOwner._id}`
              });
            if (resp.status === 200) {
                const response = resp?.data.data;
                return response
            }
          } catch (error) {
            console.log("error:", error);
        }
    }

    return (

        <div className={`toggle-container-label-owner shadow-sm p-3 mb-3 bg-white ${labelOwnerCardOpened ? 'open' : ''}`}>
            <div className='add-label-font-configure-label-owner' data-testid='labelowner-collapse-toggle' onClick={() => openLabelOwnerCard(!labelOwnerCardOpened)}>Add Label Owner</div>
            <div className="toggle-button" data-testid='labelowner-collapse-toggle' onClick={() => openLabelOwnerCard(!labelOwnerCardOpened)}>
                {
                    labelOwnerCardOpened ?
                    <img
                    alt={'add'}
                    src='/images/svgicon/arrow-up.svg'
                    style={{width:17, height:17}}
                    ></img>
                    :
                    <img
                    className='configure-arrow-down'
                    alt={'add'}
                    src='/images/svgicon/arrow-down.png'
                    ></img>
                }
            </div>
            <div className="tab-content" style= {{marginBottom:67}}>
                <Row className=''>
                    <Col>
                    {
                    labelOwnersEditableData && labelOwnersEditableData.map((labelOwner, index) => (
                        <LabelOwnerEditRow 
                            rowKey={index}
                            labelOwnerName={labelOwner.labelOwnerName}
                            isEditable={labelOwner.isEditable}
                            isValid={labelOwner.isValid}
                            validationMessage={labelOwner.validationMessage}
                            onDelete = {async (key)=> {
                                const newItems = [...labelOwnersEditableData];
                                const deleted = newItems.splice(key, 1)
                                const isUsed = await checkIfLabelOwnerIsUsed(deleted[0], key)
                                if(isUsed){
                                    setTimeout(() => {
                                        customToast({ message: 'Label owner is already used', svgImageName: 'decline' })
                                    }, 400)
                                    return
                                }
                                setLabelOwnersEditableData([...newItems])
                                setDeletedLabelOwnerIds([...deletedLabelOwnerIds, deleted[0]])
                                setIsChanged(true)
                            }}
                            labelOwnerNameOnChange={(newLabelOwnerName, key)=> {
                                const labelOwnersEditableDataCopy = labelOwnersEditableData
                                const labelOwner = {...labelOwnersEditableDataCopy[key]}
                                labelOwner.labelOwnerName = newLabelOwnerName
                                labelOwner.isValid = true
                                labelOwnersEditableDataCopy[key] = labelOwner
                                setLabelOwnersEditableData([...labelOwnersEditableDataCopy])
                            }}
                            setEdit={(key)=>{
                                const labelOwnersEditableDataCopy = labelOwnersEditableData
                                const labelOwner = {...labelOwnersEditableDataCopy[key]}
                                labelOwner.isEditable = true
                                labelOwnersEditableDataCopy[key] = labelOwner
                                setLabelOwnersEditableData([...labelOwnersEditableDataCopy])
                                setIsChanged(true)
                            }}
                        >
                        </LabelOwnerEditRow>
                    ))
                }


        <Row>
            <Col className='geofence-configure-sub-text-label-owner-text' xs={2}>
                Label Owner Name
            </Col>
            <Col xs={2}>
                <Form.Group controlId="textBox-label-owner">
                    <Form.Control 
                    data-testid='labelowner-add-name-input'
                    value={labelOwnerName}
                    onChange={handleLabelOwnerNameChange}
                    type="text" 
                    placeholder="Enter text" />
                </Form.Group>
                {
                    !isLabelOwnerNameValid &&
                    <div className='text-danger error-message'>{labelOwnerNameValidationMessage}</div>
                }
            </Col>
            <Col xs={2} className="d-flex">
                <div className='geofence-configure-sub-text-label-owner ' data-testid='labelowner-add-button' onClick={addNewLabelOwner}>
                    <img
                    alt={'add'}
                    src='/images/svgicon/add_button.svg'
                    style={{width:"30%", marginLeft:10, marginRight:5}}
                    ></img>
                    Add          
                </div>
            </Col>
        </Row>
                    </Col>
                </Row>
                
            

        
                
            </div>
            <Row style={{marginBottom: 15}}>
            <Col className="d-flex flex-row justify-content-center">
                <Button 
                disabled={!isChanged}
                className='save-btn-configure-label-owner save-action-button'
                onClick={save}
                data-testid='labelowner-save-button'
                >
                    Save
                </Button>
            </Col>
        
        </Row>
        </div>
    )
}

AddLabelOwner.propTypes = {
	// Actions
	loadSavedLabelOwners: PropTypes.func.isRequired,
    saveLabelOwners: PropTypes.func.isRequired,
    openLabelOwnerCard: PropTypes.func.isRequired,
	// States
    labelOwnersSaved: PropTypes.array.isRequired,
    labelOwnerCardOpened: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	loadSavedLabelOwners,
    openLabelOwnerCard,
    saveLabelOwners,
    labelOwnersSaved: state.parameterConfiguration.labelOwnersSaved,
    labelOwnerCardOpened: state.parameterConfiguration.labelOwnerCardOpened
});

export default connect(mapStateToProps, {
	loadSavedLabelOwners,
    saveLabelOwners,
    openLabelOwnerCard,
})(AddLabelOwner);
