import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import "./AssignModel.css";
import Loader from "../../../../helpers/hoc/loader/Loader";
import { FaSearch } from "react-icons/fa";
//import Pagination from '../../../../helpers/hoc/paginator/Pagination';

const AssignModel = ({
  showModal,
  isLoading,
  handleclose,
  assigngroupName,
  groupAssignList
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const ITEMS_PER_PAGE = 8;

  
  const filteredList = groupAssignList.filter((item) => {
    const searchValue = searchKey.toLowerCase();
    return (
      item.departmentName?.toLowerCase().includes(searchValue) ||
      item.name?.toLowerCase().includes(searchValue) ||
      item.email?.toLowerCase().includes(searchValue)
    );
  });


 // const totalPages = Math.ceil(filteredList.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
 // const endIndex = startIndex + ITEMS_PER_PAGE;
  
  
  //const paginatedList = filteredList.slice(startIndex, endIndex);

  return (
    <>
      <Modal
        backdropClassName="backdrop_bg"
        show={showModal}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body
          className="vehicle-onboard-modal-body mt-4"
          style={{ margin: "0 19px" }}
        >
          <div className="header-container d-flex justify-content-between">
            <div className="title w-100 mr-2">
              <div>
                <div className="col-3 d-inline-flex align-items-center">
                  <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                    {assigngroupName}
                  </h5>
                </div>
                <div className="col-6">
                  <div
                    className={`d-inline-flex align-items-center justify-content-center gap-6 ${
                      !searchKey ? "no-value-selected-input" : ""
                    }`}
                  >
                    <div className="search-bar-div search-input">
                      <FaSearch className="vob-search-icon" />
                      <input
                        onChange={(e) => {
                          setCurrentPage(1); 
                          setSearchKey(e.target.value);
                        }}
                        className="vob-search-input search-input"
                        placeholder="Search"
                        data-testid="view-vehicle-search-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="bg-white mb-1 position-relative"
            style={{
              maxHeight: "calc(80vh - 200px)",
              overflowY: "auto",
            }}
          >
            <Table
              responsive={true}
              borderless
              className="mt-2"
              id="assign-onboarded-model"
            >
              <thead className="table-header">
                <tr>
                  <th className="ps-3 text-nowrap">
                    <p className="fw500 vehicle-onboarded-th">S.No</p>
                  </th>
                  <th className="ps-3 text-nowrap">
                    <p className="fw500 vehicle-onboarded-th">Department Name</p>
                  </th>
                  <th className="ps-3 text-nowrap">
                    <p className="fw500 vehicle-onboarded-th">User Name</p>
                  </th>
                  <th className="ps-3 text-nowrap">
                    <p className="fw500 vehicle-onboarded-th">User Email</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((obj, index) => (
                  <tr key={"row-" + index.toString()}>
                    <td className="ps-3">
                      {startIndex + index + 1}
                    </td>
                    <td>{obj.departmentName ? obj.departmentName  : "-" }</td>
                    <td>{obj.name}</td>
                    <td>{obj.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
{/* 
            <Pagination
              disabled={false}
              totalPages={totalPages}
              currentPage={currentPage}
              setPage={setCurrentPage}
              data-testid="group-vehicle"
            /> */}

            <Loader isLoading={isLoading} />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.loader.isLoading,
});

export default connect(mapStateToProps, {})(AssignModel);
