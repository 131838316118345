import React, { useState } from 'react';
import PropTypes from "prop-types";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import styles from './ArrowToggle.module.css';

const formatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })

const ArrowToggle = ({ data, onChange, unit }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnChange = (name) => {
        if (name === 'increment') {
            setActiveIndex(activeIndex + 1)
            if(onChange){
                onChange(data[activeIndex + 1])
            }
        }
        if (name === 'decrement') {
            setActiveIndex(activeIndex - 1)
            if(onChange){
                onChange(data[activeIndex - 1])
            }
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center gap-3">
            <BsChevronLeft
                color={activeIndex !== 0 ? "#00DAEA" : "#56577A"}
                size={20}
                strokeWidth={0.8}
                cursor={activeIndex !== 0 ? "pointer" : "not-allowed"}
                onClick={() =>
                    activeIndex !== 0 ? handleOnChange('decrement') : null
                }
            />
            <div className="d-flex flex-column flex- text-center">
                <span className={styles.title}>
                    {data[activeIndex].name}
                </span>
                <span className={styles.count}>
                    {formatter.format(data[activeIndex].count)}
                    {unit && <span className={styles.unit}>{unit}</span>}
                </span>
            </div>
            <BsChevronRight
                color={activeIndex !== data.length - 1 ? "#00DAEA" : "#56577A"}
                size={20}
                strokeWidth={0.8}
                cursor={
                    activeIndex !== data.length - 1 ? "pointer" : "not-allowed"
                }
                onClick={() =>
                    activeIndex !== data.length - 1
                        ? handleOnChange('increment')
                        : null
                }
            />
        </div>
    )
}

ArrowToggle.defaultProps = {
    data: [
        {
            name: "",
            count: 0,
        },
    ],
    onChange: () => {},
    unit: "",
};

ArrowToggle.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    unit: PropTypes.string,
};

export default ArrowToggle
/*conflict-resolution-will be removed later*/