import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { DownloadFile, MarkNotificationAsRead } from '../../store/actions/notificationPanel'
import { ShowNotificationPanel } from '../../store/actions/notificationPanel'
import { useDispatch } from 'react-redux'
import './notificationPanel.css'
import { ProtectedMenus } from '../../utils/constants'


const NotificationPanel = (
    {
        //State
        showNotifications,
        notificationData,

        //Actions
        downloadFile,
        markNotificationAsRead
    }
) => {
    const [wait, SetWait] = useState()
    const notificationPanelref = useRef()
    const dispatch = useDispatch()
    const location = useLocation();

    const getBackGround = (status) => {
        if (status === "In Progress") {
            return "#f7b300"
        }
        if (status === "Failed") {
            return "#B1232D"
        }
        if (status === "Completed") {
            return "#55BD71"
        }
    }

    const downloadCompletedFile = (fileKey, fileName) => {
        downloadFile({ fileKey, fileName })
    }

    const handleScroll = (event) => {
        let element = event?.target;
        if(!element){
            element = event?.current
        }
        const parentPosition = element?.getBoundingClientRect();
        const Ids = []

        if (parentPosition) {
            element?.childNodes?.forEach((childElement) => {
                const childPosition = childElement?.getBoundingClientRect();
                if ((childPosition.top < parentPosition.top) || (childPosition.top < parentPosition.bottom) ||
                    (childPosition.top > parentPosition.top && childPosition.bottom < parentPosition.bottom)) {
                    if (!childElement.dataset.notified || childElement.dataset.notified === 'false') {
                        Ids.push(childElement.dataset.id)
                        childElement.dataset.notified = 'true'
                    }
                }
            })
            throttle(markAsRead, Ids, 1000)()
        }
    }

    const markAsRead = (Ids) => {
        if(Ids && Ids.length){
            markNotificationAsRead({ids:Ids})
        }
    }

    const throttle = (callbackFn, args, limit) => {
        return function () {
            if (!wait) {
                callbackFn(args);
                SetWait(true)
                setTimeout(function () {
                    SetWait(false)
                }, limit);
            }
        }
    }

    // Handle clicks outside the component to hide it
    const handleClickOutside = (event) => {
        if (notificationPanelref.current && !notificationPanelref.current.contains(event.target) &&
        !['nd__notification_icon_div', 'nd__notification_icon_button','nd__notification_icon_span', 'nd__notification_icon_img'].includes(event?.target?.id)) {
            dispatch(ShowNotificationPanel())
        }
    };

    useEffect(() => {
        if (showNotifications) {
        // Attach an event listener to the document to detect clicks outside
        document.addEventListener('mousedown', handleClickOutside);
        } else {
        // Remove the event listener when the component is hidden
        document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
        // Clean up the event listener when the component unmounts
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showNotifications]); //eslint-disable-line react-hooks/exhaustive-deps


    const notificationIconClick = useCallback(()=>{
        if(showNotifications && notificationPanelref?.current){
            const element = notificationPanelref?.current
            const parentPosition = element?.getBoundingClientRect();
            const Ids = []
    
            if (parentPosition) {
                element?.childNodes?.forEach((childElement) => {
                    const childPosition = childElement?.getBoundingClientRect();
                    if ((childPosition.top < parentPosition.top) || (childPosition.top < parentPosition.bottom) ||
                        (childPosition.top > parentPosition.top && childPosition.bottom < parentPosition.bottom)) {
                        if (!childElement.dataset.notified || childElement.dataset.notified === 'false') {
                            Ids.push(childElement.dataset.id)
                            childElement.dataset.notified = 'true'
                        }
                    }
                })
                if(Ids && Ids.length){
                    markNotificationAsRead({ids:Ids})
                }
            }
        }
    },[showNotifications,markNotificationAsRead])

    useEffect(()=>{
        notificationIconClick()
    },[notificationIconClick])

    
    const isDarkTheme = ProtectedMenus.some(menu => menu.path === location.pathname && menu.isDarkTheme);


    return (
        <>
            {showNotifications &&
                <div  className={isDarkTheme  === true ? 'notification-dark-panel' : 'notification-panel'} ref={notificationPanelref}
                 onScrollCapture={(event)=>{
                    throttle(handleScroll,event,100)();
                }}
                >
                    {
                        (!notificationData || !notificationData?.length) &&
                        <p style={{textAlign:"center", marginTop:"10px"}}>No Notifications</p>
                    }
                    {
                        notificationData && notificationData?.length > 0 && notificationData?.map(x => {
                            return <div className={'notification-bar'} style={{background : !x.isNotified ? "#e1eef3" :"#FFFFFF"}} key={x._id} data-id={x._id} data-notified={x.isNotified}>
                                        <div className={'bar-row1'}>
                                            <div className={'bar-title-div'}>
                                                <p className='bar-title'>{x.name}</p>
                                                <p className='bar-date'>{`From : ${x.formattedStartDateTime}`}</p>
                                                <p className='bar-date'>{`To : ${x.formattedEndDateTime}`}</p>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                <p className='bar-date'>{x?.formattedUpdatedDateTime ? x?.formattedUpdatedDateTime : x?.formattedCreatedDateTime}</p>
                                                <div className='bar-status' style={{ background: getBackGround(x.status) }}>
                                                    <p>{x.status}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bar-separartor'></div>
                                        <div className={'bar-row2'}>
                                            <span className='bar-message'>{x.description}.
                                                {x.status === "Completed" && <text> <text className='bar-click-here' onClick={() => downloadCompletedFile(x.fileKey, x.name)}>click here</text> to download file.</text>}
                                            </span>
                                        </div>
                                    </div>
                        })
                    }
                </div>}
        </>
    )
}

const mapStateToProps = (state) => ({
    showNotifications: state.notificationPanelReducer.showNotificationPanel,
    notificationData: state.notificationPanelReducer.notificationData,
});

export default connect(mapStateToProps, {
    downloadFile: DownloadFile,
    markNotificationAsRead : MarkNotificationAsRead
})(NotificationPanel)