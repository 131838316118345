import React, { useEffect, useState, memo } from "react";
import BaseModal from "../../../../shared/modals/BaseModal/BaseModal";
import cx from "classnames";
import styles from "./CACreateLocation.module.css";
import {
  CreateCustomerAdminLocation,
  GetAllMasterLocations,
} from "../../../../../store/actions/CAFlow/CAFlowActions";
import Proptypes from 'prop-types'
import InformativeModal from "../../../../shared/modals/InformativeModal/InformativeModel";
import { useDispatch, useSelector } from "react-redux";
import Select from '../../../../shared/form/Select/Select'
import Button from "../../../../shared/form/Button/Button";

const CACreateLocation = ({ isOpen, onClose }) => {
  const [selectedState, setSelectedState] = useState({});

  const [checkBoxListData, setCheckBoxListData] = useState([]);

  const [selectedCity, setSelectedCity] = useState({});

  // redux hook
  const dispatch = useDispatch();
  const locationsData = useSelector((s) => s?.customerAdmin?.masterLocations);

  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: "",
    variant: "",
  });


  const handleCreateLocation = async () => {
    if(selectedState.label && selectedCity.label) {
      try {
        const res = await CreateCustomerAdminLocation({
          state: selectedState?.label,
          city: selectedCity?.label,
        });
        if (res.status === 200) {
          setApiResponse({
            ...apiResponse,
            isOpen: true,
            message: "Successfully Created",
            variant: "success",
          });
          setTimeout(() => {
            onClose();
            setFormData({
              ...formData,
              stateValue: {},
              cityValue: {}
            })
            setCheckBoxListData([])
          }, 2001);
        } else {
          setApiResponse({
            ...apiResponse,
            isOpen: true,
            message: res.data.error,
            variant: "error",
          });
        }
      } catch (error) {
        console.log("handleCreateLocation error:", error);
      }
    }
    else {
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: "Please select all details!",
        variant: "error",
      });
    }
  };

const [formData, setFormData] = useState({
  stateValue: {},
  cityValue: {}

})

const handleOnChange = ({ name = '', value = '' }) => {
  formData[name] = value
  name === 'cityValue' ? setFormData({ ...formData }) : setFormData({ ...formData, cityValue: {} })
}

const preparedStateData = locationsData?.map((data) => {
  return {
    value: data?.state?.toLowerCase(),
    label: data?.state
  };
});

  useEffect(() => {
    dispatch(GetAllMasterLocations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedState?.label?.length) {
      const data = locationsData.filter(
        (locations) => locations?.state === selectedState?.label
      );         
      const preparedCityData = data[0]?.city?.map(city => {
        return {
          value: city?.toLowerCase(),
          label: city
        }
      })

      setCheckBoxListData(preparedCityData);
    }
  }, [selectedState?.label, locationsData]);

  return (
    <div>
      <div>
        <BaseModal isOpen={isOpen} onClose={() => {
          onClose()
          setFormData({
            ...formData,
            stateValue: {},
            cityValue: {}
          })
          setCheckBoxListData([])
          }} title={"Create Location"}>
          
          
          <div className={cx(styles.dropdownLabelStyle)}>
            Select State
          </div>
          <Select
          options={preparedStateData}
          isMultiSelect={false}
          onChange={(data) => {
            handleOnChange({ name: 'stateValue', value: data })
            setSelectedState({
              ...selectedState,
              ...data
            })
          }}
          value={formData['stateValue']}
          controlStyle={{width: '480px'}}
          placeholder='Search'
          menuMaxheight='138px'
          />
        <div className={cx(styles.dropdownLabelStyle)}>
          Select City
        </div>
        <Select 
          options={checkBoxListData}
          isMultiSelect={false}
          onChange={(data) => {
            handleOnChange({ name: 'cityValue', value: data })
            setSelectedCity(data)
          }}
          value={formData['cityValue']}
          controlStyle={{width: '480px', fontWeight: 600}}
          placeholder='Search'
          menuMaxheight='138px'
          />
            <div className={styles.modalCTAWrapper}>
              <Button variant="PINK" onClick={handleCreateLocation}>Submit</Button>
            </div>
        </BaseModal>
      </div>
      <InformativeModal
        isOpen={apiResponse.isOpen}
        onClose={() =>
          setApiResponse({
            ...apiResponse,
            isOpen: false,
          })
        }
        message={apiResponse.message}
        variant={apiResponse.variant}
      />
    </div>
  );
};

CACreateLocation.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired
}

export default memo(CACreateLocation);
/*conflict-resolution-will be removed later*/